type UnitPx = 'px'
type UnitRem = 'rem'
type UnitEm = 'em'
type UnitVh = 'vh'
type UnitVw = 'vw'
type UnitCm = 'cm'
type UnitMm = 'mm'
type UnitQ = 'Q'
type UnitIn = 'in'
type UnitPc = 'pc'
type UnitPt = 'pt'

type UnitValueType =
  | UnitPx
  | UnitRem
  | UnitEm
  | UnitVh
  | UnitVw
  | UnitCm
  | UnitMm
  | UnitQ
  | UnitIn
  | UnitPc
  | UnitPt

export class StylingUnit {
  unit_value: number
  unit_type: UnitValueType

  constructor(unit_value: number, unit_type: UnitValueType) {
    this.unit_value = unit_value
    this.unit_type = unit_type
  }

  unit(): string {
    return `${this.unit_value}${this.unit_type}`
  }
}
