import React from 'react'

import { Icon } from 'stardust'
import styled from 'styled-components'

export const Button = styled.span`
  cursor: pointer;
  color: ${(props) => (props.active ? 'textPrimary' : 'surfacePrimaryBorder')};
`

const fill = (active, disabled) => {
  if (disabled) return 'textPrimaryLow'
  if (active) return 'primary'
  return 'textPrimaryMedium'
}

const ToolbarButton = ({ active, action, iconName, disabled }) => (
  <Button active={active} onClick={action} disabled={disabled}>
    <Icon name={iconName} color={fill(active, disabled)} />
  </Button>
)

ToolbarButton.displayName = 'ToolbarButton'

export default ToolbarButton
