import gql from 'graphql-tag'

export const ARCHIVE_COMMENT = gql`
  mutation deleteComment($id: Int!) {
    deleteComment(id: $id) {
      content
      id
      author {
        fkey
        type
      }
    }
  }
`

export const CREATE_LEARNING_PLAN_COMMENT = gql`
  mutation createLearningPlanComment($id: Int!, $content: String) {
    createLearningPlanComment(comment: { articleId: $id, content: $content }) {
      id
      author {
        fkey
        name
        type
      }
      content
      insertedAt
    }
  }
`

export const CREATE_LEARNING_STORY_COMMENT = gql`
  mutation createLearningStoryComment($id: Int!, $content: String) {
    createLearningStoryComment(comment: { articleId: $id, content: $content }) {
      id
      author {
        fkey
        name
        type
      }
      content
      insertedAt
    }
  }
`

export const CREATE_LEARNING_TABLE_COMMENT = gql`
  mutation createLearningTableComment($id: Int!, $content: String) {
    createLearningTableComment(comment: { articleId: $id, content: $content }) {
      id
      author {
        fkey
        name
        type
      }
      content
      insertedAt
    }
  }
`

export const CREATE_MOMENT_COMMENT = gql`
  mutation createMomentComment($id: Int!, $content: String) {
    createMomentComment(comment: { momentId: $id, content: $content }) {
      id
      author {
        fkey
        name
        type
      }
      content
      insertedAt
    }
  }
`

export const CREATE_OBSERVATION_COMMENT = gql`
  mutation createObservationComment($id: Int!, $content: String) {
    createObservationComment(comment: { observationId: $id, content: $content, createLegacyComment: false }) {
      id
      author {
        fkey
        image
        name
        type
      }
      content
      insertedAt
      updatedAt
    }
  }
`

export const CREATE_POST_COMMENT = gql`
  mutation createPostComment($id: Int!, $content: String, $replyToCommentId: Int) {
    createPostComment(comment: { postId: $id, content: $content, replyToCommentId: $replyToCommentId }) {
      id
      author {
        fkey
        image
        name
        type
      }
      content
      insertedAt
      updatedAt
      replyToCommentId
    }
  }
`

export const UPDATE_COMMENT = gql`
  mutation updateComment($id: Int!, $content: String) {
    updateComment(id: $id, content: $content) {
      id
    }
  }
`

export const UPDATE_COMMENT_LIKE = gql`
  mutation updateCommentLike($id: Int!) {
    updateCommentLike(commentId: $id) {
      deletedAt
      entityId
      entityType
      type
      updatedAt
      userDisplayName
      userType
    }
  }
`

export const UPDATE_COMMENT_VISIBILITY = gql`
  mutation updateCommentVisibility($id: Int!) {
    updateCommentVisibility(comment: { id: $id }) {
      id
      isHidden
    }
  }
`
