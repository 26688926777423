import React from 'react'

const ChevronLeftDouble = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7245 6C19.986 6 20.0778 6.19258 19.9281 6.42678L16.6684 11.5716C16.5188 11.8336 16.5188 12.1664 16.6684 12.4284L19.9281 17.5732C20.0778 17.809 19.986 18 19.7245 18L18.1209 18C17.8276 17.9824 17.5554 17.8269 17.374 17.5732L14.1119 12.43C13.9627 12.1679 13.9627 11.8353 14.1119 11.5732L17.3721 6.42838C17.5535 6.17474 17.8257 6.01922 18.119 6.0016L19.7245 6Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.72452 6C9.98596 6 10.0778 6.19258 9.92813 6.42678L6.66845 11.5716C6.51876 11.8336 6.51876 12.1664 6.66845 12.4284L9.92813 17.5732C10.0778 17.809 9.98596 18 9.72452 18L8.12092 18C7.82761 17.9824 7.55544 17.8269 7.37403 17.5732L4.11192 12.43C3.96269 12.1679 3.96269 11.8353 4.11192 11.5732L7.37209 6.42838C7.55349 6.17474 7.82566 6.01922 8.11898 6.0016L9.72452 6Z"
      fill="currentColor"
    />
  </svg>
)

export default ChevronLeftDouble
