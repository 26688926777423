import React, { ReactNode } from 'react'

import { Box } from 'stardust'

export const STACKED_PREVIEW_HEIGHT = '400px'
export const EXPANDED_PREVIEW_HEIGHT = 'calc(80vh - 81px)'

interface Props {
  background?: string
  children: ReactNode
}

const DocumentModalPreview = ({ background = 'black', children }: Props) => {
  return (
    <Box
      bg={background}
      height="100%"
      maxHeight={[STACKED_PREVIEW_HEIGHT, EXPANDED_PREVIEW_HEIGHT]}
      overflow="auto"
      width="100%">
      {children}
    </Box>
  )
}

DocumentModalPreview.displayName = 'DocumentModalPreview'

export default React.memo(DocumentModalPreview)
