import React from 'react'

const ObservationRemoved = () => (
  <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20,38.8235C30.396,38.8235 38.8236,30.3959 38.8236,20C38.8236,9.604 30.396,1.1765 20,1.1765C9.6041,1.1765 1.1765,9.604 1.1765,20C1.1765,30.3959 9.6041,38.8235 20,38.8235Z"
      fill="#ffffff"
      fillRule="evenodd"
    />
    <path
      d="M19.9999,35.2941C28.4466,35.2941 35.294,28.4467 35.294,20C35.294,11.5533 28.4466,4.7059 19.9999,4.7059C11.5532,4.7059 4.7058,11.5533 4.7058,20C4.7058,28.4467 11.5532,35.2941 19.9999,35.2941Z"
      fill="#E64766"
      fillRule="evenodd"
    />
    <path
      d="M28.675,11.3249C29.1344,11.7844 29.1344,12.5293 28.675,12.9887L12.9887,28.675C12.5293,29.1344 11.7844,29.1344 11.3249,28.675C10.8655,28.2156 10.8655,27.4707 11.3249,27.0112L27.0112,11.3249C27.4706,10.8655 28.2155,10.8655 28.675,11.3249Z"
      fill="#ffffff"
      fillRule="evenodd"
    />
    <path
      d="M11.3249,11.3249C11.7844,10.8655 12.5293,10.8655 12.9887,11.3249L28.675,27.0112C29.1344,27.4707 29.1344,28.2156 28.675,28.675C28.2155,29.1344 27.4706,29.1344 27.0112,28.675L11.3249,12.9887C10.8655,12.5293 10.8655,11.7844 11.3249,11.3249Z"
      fill="#ffffff"
      fillRule="evenodd"
    />
    <path
      d="M0,20C0,8.9543 8.9543,0 20,0C31.0457,0 40,8.9543 40,20C40,31.0457 31.0457,40 20,40C8.9543,40 0,31.0457 0,20ZM20,2.3529C10.2538,2.3529 2.3529,10.2538 2.3529,20C2.3529,29.7462 10.2538,37.6471 20,37.6471C29.7462,37.6471 37.6471,29.7462 37.6471,20C37.6471,10.2538 29.7462,2.3529 20,2.3529Z"
      fill="#444B54"
      fillRule="evenodd"
    />
  </svg>
)

export default ObservationRemoved
