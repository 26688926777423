import React from 'react'

import { navigate } from '@reach/router'

import { Flex, FlexItem } from 'stardust'

import { isWebView } from '~/config'

import LinksPill from './LinksPill'

interface Props {
  links?: Playground.Document[]
  tagType?: string
}

const LinksGrid = ({ links, tagType }: Props) => {
  const handleTitleClick = (link: Playground.Document) => {
    let url
    switch (link.type) {
      case 'learning_table':
        url = `/tables/${link.typeId}/webview`
        break
      case 'learning_story':
        url = `/stories/${isWebView ? '' : 'display/'}${link.typeId}/webview`
        break
      case 'observation':
        url = `/observations/${link.typeId}/webview`
        break
      default:
        url = '/'
    }

    // if not inside webview then open tagged doc in a new tab without /webview appended in the URL
    if (!isWebView) {
      url = url.replace('/webview', '')
      window.open(url, '_blank')
    } else {
      navigate(url)
    }
  }

  return tagType === 'Links' ? (
    <Flex mt="10px" ml={3} mr={3} flexWrap="wrap">
      {links?.map((link) => (
        <FlexItem mt={2} key={link.id}>
          <LinksPill title={link.title} type={link.type} onTitleClick={() => handleTitleClick(link)} />
        </FlexItem>
      ))}
    </Flex>
  ) : null
}

LinksGrid.displayName = 'LinksGrid'

export default React.memo(LinksGrid)
