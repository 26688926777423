/*eslint-disable react/display-name */
import React from 'react'

import isUrl from 'is-url'
import * as R from 'ramda'

import { RenderInlineProps } from 'slate-react'

import LoadedLink from '~/components/LoadedLink'

import { Next, SlateReactEditor, getEventTransfer } from '../../types'

import { hasLinks, insertLink, removeLink, unwrapLink, wrapLink } from './link'
import { LINK_NODE_TYPE } from './types'

export default {
  serialize: ({ href, object: objType }: any, children: any) =>
    objType === 'inline' && <LoadedLink href={href} selection={children} />,

  renderNode: ({ attributes, children, node }: RenderInlineProps, next: Next) => {
    if (node.type === LINK_NODE_TYPE) {
      const { data } = node
      const href = data.get('href')
      return <LoadedLink {...attributes} href={href} selection={children} />
    }

    return next()
  },

  onPaste: (event: Event, editor: SlateReactEditor, next: Next) => {
    const { type, text } = getEventTransfer(event)
    const { editor: slateEditor, value } = editor
    const isCollapsed = value.selection.isCollapsed
    const inlines = value.inlines.toJS()

    const insideInlineLink = !R.isEmpty(inlines) && inlines[0].type === LINK_NODE_TYPE

    if (type !== 'text' && type !== 'html') {
      return next()
    }

    if (!isUrl(text)) {
      return next()
    }

    if (isCollapsed && isUrl(text) && insideInlineLink) {
      removeLink(slateEditor, value)
    }

    if (isCollapsed && isUrl(text)) {
      return insertLink(slateEditor, text)
    }

    if (hasLinks(slateEditor)) {
      unwrapLink(slateEditor)
    }

    wrapLink(slateEditor, text)
  },
}
