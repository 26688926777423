import React from 'react'

interface AttachmentDocumentProps {
  pillFill?: string
}

export const AttachmentDocument = (props: AttachmentDocumentProps) => {
  return (
    <svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_29694_113264)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.33203 0C1.77975 0 1.33203 0.447715 1.33203 1V27C1.33203 27.5523 1.77975 28 2.33203 28H23.6654C24.2176 28 24.6654 27.5523 24.6654 27V4.66667L19.9987 0H2.33203Z"
          fill="#E2E2E2"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M19.998 0V4.66667H24.6647L19.998 0Z" fill="#BDBDBD" />
      </g>
      <rect
        x="0.166016"
        y="11.6667"
        width="25.6667"
        height="11.6667"
        rx="1"
        fill={props.pillFill || '#F14848'}
      />
    </svg>
  )
}
