import React from 'react'

const LikesValid = () => (
  <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.22681 0C3.06368 0 0.5 2.39721 0.5 5.35494C0.5 10.5033 6.8866 15.4226 9.87036 17.6133C9.8732 17.6154 9.87605 17.6176 9.87891 17.6197C9.89935 17.6347 9.92715 17.6561 9.94727 17.6708L9.94897 17.6692C10.2517 17.8831 10.6206 17.9992 11 18C11.38 17.9995 11.7495 17.8834 12.0527 17.6692V17.6708C12.0586 17.6665 12.0673 17.6608 12.0732 17.6564C12.0777 17.6531 12.0841 17.6486 12.0886 17.6452C12.0944 17.6405 12.1 17.6357 12.1057 17.6309C15.0802 15.448 21.5 10.5155 21.5 5.35494C21.5 2.39721 18.9363 0 15.7732 0C12.7501 0 11 2.45455 11 2.45455C11 2.45455 9.24993 0 6.22681 0Z"
      fill="currentColor"
    />
  </svg>
)

export default LikesValid
