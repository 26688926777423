import RIsNil from 'ramda/src/isNil'
import RNot from 'ramda/src/not'
import ROmit from 'ramda/src/omit'
import RPickBy from 'ramda/src/pickBy'
import RPipe from 'ramda/src/pipe'

import { getFkeyFromObject } from '~/utils'

import { PostSaveState, PostState } from './types'

export const STORE_DUPLICATE_LOCAL_KEY = 'duplicate'

export const decodeChildOutcome = (inputValue: string) => {
  const [childFkey, outcomeId] = inputValue.split('|')
  if (!childFkey || !outcomeId) return null
  return { childFkey, outcomeId: Number(outcomeId) }
}

export const encodeChildOutcome = (
  child: Playground.SimpleChild | string,
  outcome: Playground.LearningFrameworkOutcome | number
) => {
  const childFkey = typeof child === 'object' ? child.fkey : child
  const outcomeId = typeof outcome === 'object' ? outcome.id : outcome
  return `${childFkey}|${outcomeId}`
}

export const isDuplicateObservation = (id: number | string) => {
  return id === STORE_DUPLICATE_LOCAL_KEY
}

const toChildFkeys = (state: PostState) => {
  const taggedChildFkeys = (state.children || []).map((child) => getFkeyFromObject(child))
  return { ...state, childFkeys: taggedChildFkeys }
}

const toLearningOutcomeIds = (state: PostState) => {
  const learningOutcomeIds = (state.learningOutcomes || []).map((learningOutcome) => learningOutcome.id)
  return { ...state, learningOutcomeIds }
}

const toMediaIds = (state: PostState) => {
  const mediaIds = (state.media || []).map((mediaItem) => mediaItem.id)

  return { ...state, mediaIds: [...mediaIds] }
}

const toRoomFkeys = (state: PostState) => {
  const roomFkeys = (state.rooms || []).map((room) => room.roomFkey)
  return { ...state, roomFkeys }
}

const toLearningContentAssetIds = (state: PostState) => {
  const learningContentAssetIds = (state.learningContentAssets || []).map(
    (learningContentAsset) => learningContentAsset.id
  )
  return { ...state, learningContentAssetIds }
}

const toLearningRecords = (state: PostState) => {
  const learningRecords = Object.entries(state.childRankings || {}).map(([childRankingId, value]) => {
    const childOutcome = decodeChildOutcome(childRankingId)
    return {
      rankId: value.rankId ? Number(value.rankId) : null,
      ageGroupId: value.ageGroupId ? Number(value.ageGroupId) : null,
      childFkey: childOutcome?.childFkey,
      outcomeId: childOutcome?.outcomeId,
    }
  })
  return { ...state, learningRecords }
}

export function convertToSave(state: PostState): PostSaveState {
  return RPipe(
    toChildFkeys,
    toLearningOutcomeIds,
    toMediaIds,
    toRoomFkeys,
    toLearningContentAssetIds,
    toLearningRecords,
    ROmit([
      'availableRooms',
      'children',
      'learningOutcomes',
      'media',
      'rooms',
      'updatedAt',
      'uploadedMedia',
      'childRankings',
      'learningContentAssets',
    ]),
    RPickBy(RPipe(RIsNil, RNot))
  )(state) as PostSaveState
}

export default { convertToSave }
