import React from 'react'

const Reflections = () => (
  <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3334 1.66663H2.50002C2.04169 1.66663 1.66669 2.04163 1.66669 2.49996V14.1666L5.00002 10.8333H13.3334C13.7917 10.8333 14.1667 10.4583 14.1667 9.99996V2.49996C14.1667 2.04163 13.7917 1.66663 13.3334 1.66663ZM12.5 3.3333V9.16663H4.30836L3.8167 9.6583L3.33336 10.1416V3.3333H12.5ZM15.8333 4.99996H17.5C17.9583 4.99996 18.3333 5.37496 18.3333 5.83329V18.3333L15 15H5.83334C5.37501 15 5.00001 14.625 5.00001 14.1666V12.5H15.8333V4.99996Z"
      fill="currentColor"
    />
  </svg>
)

export default Reflections
