import React from 'react'

const ObservationsNav = () => (
  <svg viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.625 6.375C0.625 4.71815 1.96815 3.375 3.625 3.375H16.375C18.0319 3.375 19.375 4.71815 19.375 6.375V14.125C19.375 15.7819 18.0319 17.125 16.375 17.125H3.625C1.96815 17.125 0.625 15.7819 0.625 14.125V6.375Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 14.625C12.0711 14.625 13.75 12.9461 13.75 10.875C13.75 8.80393 12.0711 7.125 10 7.125C7.92893 7.125 6.25 8.80393 6.25 10.875C6.25 12.9461 7.92893 14.625 10 14.625Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5366 12.125H19.375"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.625 12.125H6.46417"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.625 5.5625C15.7976 5.5625 15.9375 5.70241 15.9375 5.875C15.9375 6.04759 15.7976 6.1875 15.625 6.1875C15.4524 6.1875 15.3125 6.04759 15.3125 5.875C15.3125 5.70241 15.4524 5.5625 15.625 5.5625"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.75 5.875H5.625"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 0.875H12.5C12.1548 0.875 11.875 1.15482 11.875 1.5V3.375H15.625V1.5C15.625 1.15482 15.3452 0.875 15 0.875Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ObservationsNav
