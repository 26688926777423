import { articleContentState } from '~/utils'

export interface ContentStatusIndicatorProps {
  isPublished?: boolean
  hasUnpublishedChanges?: boolean
  status?: Playground.Status
}

const contentStatusIndicator = ({
  isPublished,
  hasUnpublishedChanges,
  status,
}: ContentStatusIndicatorProps) => {
  const contentState =
    isPublished && hasUnpublishedChanges ? articleContentState({ isPublished, hasUnpublishedChanges }) : null

  if (contentState === 'UNPUBLISHED_CHANGES') {
    return 'UNSHARED_CHANGES'
  } else if (status) {
    return status
  } else {
    return null
  }
}

export default contentStatusIndicator
