import React from 'react'

import { Box, Flex, Text } from 'stardust'

import DocumentIcon from '~/components/DocumentIcon'
import { isHomeWebview } from '~/config'
import colors from '~/ui-components/tokens/colors'

interface Props {
  title?: string
  type?: string
  id?: string
  onTitleClick?: () => void
}

const LinksPill = ({ title, type, onTitleClick }: Props) => {
  return (
    <Box
      bg={isHomeWebview ? colors.cosmicShade10 : colors.cosmicShade4}
      p={1}
      width={'max-content'}
      mr={3}
      borderRadius="16px"
      border={1}
      borderColor={isHomeWebview ? colors.cosmicShade11 : colors.cosmicShade6}>
      <Flex alignItems="center" flexGrow={1} p={2}>
        {type && <DocumentIcon type={type} />}
        <Text color="primary" fontSize={1} ml={2} onClick={onTitleClick} style={{ cursor: 'pointer' }}>
          {title}
        </Text>
      </Flex>
    </Box>
  )
}

LinksPill.displayName = 'LinksPill'

export default React.memo(LinksPill)
