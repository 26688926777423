import React from 'react'

import FullScreen from '~/components/Layouts/FullScreen'

import Search from '../Search'

interface Props {
  serviceFkey: string
}

const SearchWebView = ({ serviceFkey }: Props) => {
  return (
    <FullScreen background="inherit">
      <Search serviceFkey={serviceFkey} />
    </FullScreen>
  )
}

SearchWebView.displayName = 'Search'

export default SearchWebView
