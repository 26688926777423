import React from 'react'

const FolderAlt = () => (
  <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.25 8.25V5.25C20.25 4.42157 19.5784 3.75 18.75 3.75H8.25V2.25C8.25 1.42157 7.57843 0.75 6.75 0.75H2.25C1.42157 0.75 0.75 1.42157 0.75 2.25V18.55C0.761668 19.3943 1.39131 20.1021 2.22854 20.212C3.06576 20.322 3.85677 19.8007 4.086 18.988L6.437 9.331C6.62351 8.68989 7.21131 8.24924 7.879 8.25H21.75C22.216 8.24984 22.6556 8.46629 22.9397 8.83574C23.2237 9.20519 23.3199 9.68567 23.2 10.136L21 19.136C20.825 19.7931 20.23 20.2502 19.55 20.25H2.447"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default FolderAlt
