import React from 'react'

import { Box, Flex, Icon, Divider, Text, SearchField, Dropdown } from 'stardust'
import styled from 'styled-components'

import colors from '~/ui-components/tokens/colors'

const StyledSearchField = styled(SearchField)`
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: ${colors.cosmicShade0};
  }
  .mdc-text-field--filled .mdc-floating-label--float-above {
    transform: translateY(-96%) scale(0.75);
  }
  .mdc-text-field__input {
    align-self: end;
    padding-top: 0px;
    height: 32px;
  }
  label {
    height: 44px;
  }
  .mdc-line-ripple {
    display: none;
  }
`

interface Props {
  selectedSorting: string
  handleSortingChange: (option: string) => void
  isDropdownVisible: boolean
  handleDropdownToggle: () => void
  onQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const LinksDocumentsFilterWeb = ({
  selectedSorting,
  isDropdownVisible,
  handleSortingChange,
  handleDropdownToggle,
  onQueryChange,
}: Props) => {
  return (
    <Flex mt={3} mx={24}>
      <Box flex={1} pr={3}>
        <StyledSearchField
          label="Search by Document’s name"
          name="search"
          autoComplete="off"
          onChange={onQueryChange}
          border={`1px solid ${colors.cosmicShade6}`}
          borderRadius="16px"
        />
      </Box>
      <Box>
        <Dropdown
          renderButton={({ handleClose }) => (
            <Flex
              alignItems="center"
              border={`1px solid ${colors.cosmicShade6}`}
              borderRadius="16px"
              py={11}
              px={12}
              onClick={() => {
                handleDropdownToggle()
                handleClose()
              }}>
              <Icon name="chevronSort" />
              <Text ml={2}>{selectedSorting}</Text>
            </Flex>
          )}
          render={({ handleClose }) => (
            <Box>
              {['Last modified', 'Last created', 'File name: A-Z', 'File name: Z-A'].map(
                (option, index, array) => (
                  <Box
                    width="100%"
                    minWidth={220}
                    cursor="pointer"
                    z-index="1"
                    key={option}
                    onClick={() => {
                      handleSortingChange(option)
                      handleClose()
                    }}>
                    <Flex py={14} px={20} alignItems="center">
                      <Box> {selectedSorting === option && <Icon width={24} name="check" />} </Box>
                      <Text.p lineHeight="34px" pl={selectedSorting !== option ? 40 : 3}>
                        {option}
                      </Text.p>
                    </Flex>
                    {index !== array.length - 1 && <Divider />}
                  </Box>
                )
              )}
            </Box>
          )}
          show={isDropdownVisible}
        />
      </Box>
    </Flex>
  )
}
LinksDocumentsFilterWeb.displayName = 'LinksDocumentsFilterWeb'

export default React.memo(LinksDocumentsFilterWeb)
