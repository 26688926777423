import gql from 'graphql-tag'

export default gql`
  fragment ObservationTaggedChildrenWithInfo on Observation {
    children {
      fkey
      fullName
      image
      otherDetails {
        consents
      }
    }
  }
`
