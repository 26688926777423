import React, { useState, useEffect } from 'react'

import { Flex, Text, Icon } from 'stardust'

import styled from 'styled-components'

import colors from '~/ui-components/tokens/colors'

import Categories from './Categories'

interface Props {
  framework: Playground.LearningFramework
  clickedOutcomes: Playground.LearningFrameworkOutcome[]
  onOutcomeClick: (outcome: Playground.LearningFrameworkOutcome) => void
  selectedCount?: number | null
  expandAll: boolean
}

const S = {
  Flex: styled(Flex)`
    border: 1px solid;
    border-color: ${colors.cosmicShade6};
    background-color: ${colors.cosmicShade1};
    border-radius: 16px;
  `,
}

const FrameworksList = ({ framework, onOutcomeClick, clickedOutcomes, selectedCount, expandAll }: Props) => {
  const [showCategories, setShowCategories] = useState(false)

  useEffect(() => {
    setShowCategories(expandAll)
  }, [expandAll])

  return (
    <S.Flex bg={colors.cosmicShade2} mb={2} flexDirection="column" minHeight="100%">
      <Flex
        alignItems="center"
        cursor="pointer"
        p={3}
        onClick={() => setShowCategories(!showCategories)}
        justifyContent="space-between">
        <Flex alignItems="center">
          <Icon name="frameworks" mr={2} />
          <Text bold>{framework.fullName}</Text>
        </Flex>
        <Flex>
          <Text fontWeight={700} color="grey" mr={3}>
            {selectedCount && selectedCount > 0
              ? `${selectedCount} selected tag${selectedCount > 1 ? 's' : ''}`
              : null}{' '}
          </Text>
          <Icon name={showCategories ? 'arrowUp' : 'arrowDown'} size={12} mr={3} />
        </Flex>
      </Flex>
      <Categories
        showCategories={showCategories}
        outcomes={framework.outcomes}
        categories={framework.categories}
        onOutcomeClick={onOutcomeClick}
        clickedOutcomes={clickedOutcomes}
        expandAll={expandAll}
      />
    </S.Flex>
  )
}
FrameworksList.displayName = 'FrameworksList'
export default React.memo(FrameworksList)
