import * as R from 'ramda'

import * as factories from '../factories'
import * as legacySelectors from '../legacySelectors'

export default (node) => {
  const sticker = R.path(['data', 'type'], node)
  const point = R.view(legacySelectors.layoutLens, node)

  return factories.stickerFactory({ properties: { kind: sticker, point } })
}
