import React from 'react'

import Box from './Box'
import Flex from './Flex'
import Icon from './Icon'
import Text from './Text'

import colors from './tokens/colors'

export interface ErrorMessageProps {
  error: Playground.Error
  setDisplayUploadErrors: (displayUploadErrors: boolean) => void
}

const ErrorMessageMediaUpload = ({ error, setDisplayUploadErrors }: ErrorMessageProps) => {
  return (
    <Box
      border={1}
      borderColor={colors.superGiantRed5}
      bg={colors.superGiantRed6}
      borderRadius={1}
      p={3}
      style={{ boxSizing: 'border-box' }}
      width={1}>
      <Flex alignItems="flex-start" justifyContent="flex-start">
        <Icon bg="negative" color={colors.superGiantRed5} name="warning" />
        <Flex flexDirection="column" ml={1} mt={1}>
          <Text fontWeight={700} fontSize="14px">
            Attachment Error
          </Text>
          <Flex alignItems="center" mt={2}>
            <Text fontWeight={700} fontSize="14px" mr={1}>
              {error.type}
            </Text>
            <Text fontWeight={400} fontSize="14px">
              {error.errorMessage}
            </Text>
          </Flex>
        </Flex>
        <Icon name="close" ml="auto" onClick={() => setDisplayUploadErrors(false)} />
      </Flex>
    </Box>
  )
}

ErrorMessageMediaUpload.displayName = 'ErrorMessageMediaUpload'

export default React.memo(ErrorMessageMediaUpload)
