import React, { useCallback, useContext, useMemo, useState } from 'react'

import { eachDay, max as maxDate, min as minDate } from 'date-fns'

import { Box, Card, Flex } from 'stardust'
import oranges from 'stardust/tokens/colors/hullOrange'
import blues from 'stardust/tokens/colors/nebulaBlue'

import LineChart, { LineChartDatum } from '~/components/Charts/LineChart'
import FilterDropdown, { FkeyOrId, Id, ProfileGroup, ProfileItem } from '~/components/FilterDropdown'
import useLocalStorage from '~/hooks/useLocalStorage'
import { LAYERS } from '~/theme'
import { toISODate } from '~/utils'

import CategoryFilterMenu from '../Common/CategoryFilterMenu'
import { LearningAnalysisContext } from '../Context'

interface Props {
  defaultPrimaryFilter?: ProfileItem<FkeyOrId> | null
  defaultSecondaryFilter?: ProfileItem<FkeyOrId> | null
  filters: ProfileGroup<FkeyOrId>[]
  filterDropdownText?: string
  localStoragePrimaryKey?: string
  localStorageSecondaryKey?: string
  records: Playground.LearningRecord[]
}

const averageRankValue = (
  records: Playground.LearningRecord[],
  findRank: (rankId: number) => Playground.LearningFrameworkRank | undefined
) => {
  const values = records.map((record) => {
    const rank = findRank(record.rankId!)
    return rank?.value || 0
  })

  const sum = values.reduce((acc, value) => acc + value, 0)
  return records.length > 0 ? sum / records.length : 0
}

const generateDateRange = (records: Playground.LearningRecord[]) => {
  const dates = records.map((record) => toISODate(record.achievedAt))
  return eachDay(minDate(...dates), maxDate(...dates)).map(toISODate)
}

const groupByDate = (records: Playground.LearningRecord[]) => {
  return records.reduce((acc, record) => {
    const date = toISODate(record.achievedAt)
    const entries = acc[date] || []
    acc[date] = [...entries, record]
    return acc
  }, {} as Record<string, Playground.LearningRecord[]>)
}

const calculateValue = (
  cohortsEnabled: boolean,
  records: Playground.LearningRecord[],
  findRank: (rankId: number) => Playground.LearningFrameworkRank | undefined
) => {
  if (records.length === 0) return null
  return cohortsEnabled ? averageRankValue(records, findRank) : records.length
}

const ProgressOverTime = ({
  defaultPrimaryFilter,
  defaultSecondaryFilter,
  localStoragePrimaryKey,
  localStorageSecondaryKey,
  filters,
  filterDropdownText,
  records,
}: Props) => {
  const { cohortsEnabled, ranks, filterRecords, findRank } = useContext(LearningAnalysisContext)

  const initialPrimaryFilter = defaultPrimaryFilter ? defaultPrimaryFilter : null
  const initialSecondaryFilter = defaultSecondaryFilter ? defaultSecondaryFilter : null

  const [categoryFilter, setCategoryFilter] = useState<Nullable<ProfileItem<Id>>>(null)
  const [primaryFilter, setPrimaryFilter] = useLocalStorage<Nullable<ProfileItem<FkeyOrId>>>(
    initialPrimaryFilter,
    localStoragePrimaryKey
  )
  const [secondaryFilter, setSecondaryFilter] = useLocalStorage<Nullable<ProfileItem<FkeyOrId>>>(
    initialSecondaryFilter,
    localStorageSecondaryKey
  )

  const primaryColors = [blues.nebulaBlue7, oranges.hullOrange9]
  const data = useMemo(() => {
    const filteredRecords = filterRecords(records, categoryFilter)
    const primaryRecords = groupByDate(filterRecords(filteredRecords, primaryFilter))
    const secondaryRecords = groupByDate(filterRecords(filteredRecords, secondaryFilter))

    const dateRange = generateDateRange(records)
    return dateRange.reduce((acc, date) => {
      const primaryForDate = primaryRecords[date] || []
      const secondaryForDate = secondaryRecords[date] || []

      const datapoint = {
        category: date,
        values: [
          { name: 'Primary', value: calculateValue(cohortsEnabled, primaryForDate, findRank) },
          { name: 'Secondary', value: calculateValue(cohortsEnabled, secondaryForDate, findRank) },
        ],
      }

      return [...acc, datapoint]
    }, [] as LineChartDatum[])
  }, [categoryFilter, cohortsEnabled, filterRecords, findRank, primaryFilter, records, secondaryFilter])

  const onFormatY = useCallback(
    (value: number) => {
      const roundedValue = Math.round(value)
      const rank = ranks.find((rank) => rank.value === roundedValue)
      return rank?.name || 'Unranked'
    },
    [ranks]
  )

  const rankValues = ranks.map((rank) => rank.value)
  const chartMin = cohortsEnabled ? Math.min(...rankValues) : 0
  const chartMax = cohortsEnabled ? Math.max(...rankValues) : undefined

  return (
    <Card>
      <Flex flexDirection="column" height={340} width={1}>
        <Flex justifyContent="space-between" p={3}>
          <Flex alignItems="center" justifyContent="space-between" maxWidth="50%" px={2}>
            <Flex
              background={primaryColors[0]}
              borderRadius={'6px'}
              float="left"
              m={1}
              minHeight={'12px'}
              minWidth={'12px'}
              p={1}
            />
            <Flex width={1}>
              <FilterDropdown
                buttonDefaultText={filterDropdownText}
                filters={filters}
                portal
                selection={primaryFilter}
                zIndex={LAYERS.SectionHeader}
                onSelect={setPrimaryFilter}
              />
            </Flex>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between" maxWidth="50%" px={2}>
            <Flex
              background={primaryColors[1]}
              borderRadius={'6px'}
              float="left"
              m={1}
              minHeight={'12px'}
              minWidth={'12px'}
              p={1}
            />
            <Flex width={1}>
              <FilterDropdown
                filters={filters}
                portal
                selection={secondaryFilter}
                zIndex={LAYERS.SectionHeader}
                onSelect={setSecondaryFilter}
              />
            </Flex>
          </Flex>
        </Flex>

        <Flex flexGrow={1} width={1}>
          <Box px={3} width={1}>
            <LineChart
              colors={primaryColors}
              data={data}
              height={220}
              min={chartMin}
              max={chartMax}
              onFormatY={onFormatY}
            />
          </Box>
        </Flex>

        <Flex borderTop="1px solid" borderColor="surfacePrimaryBorder" mx={3} py={2}>
          <Flex flexDirection="column">
            <CategoryFilterMenu value={categoryFilter} onChange={setCategoryFilter} />
          </Flex>
        </Flex>
      </Flex>
    </Card>
  )
}

ProgressOverTime.displayName = 'ProgressOverTime'

export default React.memo(ProgressOverTime)
