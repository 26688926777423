import React, { ReactNode } from 'react'

import { Carousel as ReactResponsiveCarousel } from 'react-responsive-carousel'
import styled from 'styled-components'
import { borderRadius } from 'styled-system'

const StyledCarousel = styled(ReactResponsiveCarousel)`
  .carousel-slider {
    display: flex;
    height: 100%;
    width: 100%;
  }

  .carousel .slider {
    align-items: center;
    display: flex;
  }

  .carousel .slide {
    background: ${(props) => props.theme.backgroundColorSurfacePrimary};
    ${borderRadius};

    img {
      max-width: 100%;
      width: auto;
    }
  }

  .carousel .control-next.control-arrow:before {
    border-left: 8px solid ${(props) => props.theme.backgroundColorSecondary};
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid ${(props) => props.theme.backgroundColorSecondary};
  }

  .carousel-slider .control-arrow {
    max-height: 64px;
    top: 50%;
    transform: translateY(-50%);
  }

  .carousel .control-dots {
    padding: 0;
  }

  .carousel .control-dots .dot {
    background: ${(props) => props.theme.backgroundColorSecondary};
    margin: 4px 8px;
  }

  ${borderRadius};
`

interface Props {
  children: ReactNode[]
  showIndicators?: boolean
}

const Carousel = ({ children, showIndicators = true }: Props) => (
  <StyledCarousel
    infiniteLoop
    showStatus={false}
    showThumbs={false}
    useKeyboardArrows
    width="100%"
    showIndicators={showIndicators}>
    {children.map((child, index) => (
      <div key={index}>{child}</div>
    ))}
  </StyledCarousel>
)

Carousel.displayName = 'Carousel'

export default Carousel
