import React, { useContext } from 'react'

import { Box, Flex, Icon, Image } from 'stardust'

import xplorImage from '~/assets/images/xplor.svg'
import Can from '~/components/Authorisation/Can'
import { isHome } from '~/config'
import { ServiceContext } from '~/contexts/Service'
import * as perms from '~/modules/permissions'

import { LAYERS } from '~/theme'

import NavigationItem from './NavigationItem'

type Props = {
  hasFrameworks: boolean
  hasReports: 'true' | 'false'
}

const Navigation = ({ hasFrameworks, hasReports }: Props) => {
  const selectionColor = 'textActive'

  const {
    state: { selectedServiceLinks },
  } = useContext(ServiceContext)

  return (
    <Flex
      screenOnly
      bg="surfacePrimary"
      borderColor="surfacePrimaryBorder"
      borderRight="1px solid"
      flex="1 1 auto"
      flexDirection="column"
      overflowY="auto"
      overflowX="hidden"
      zIndex={LAYERS.Sidebar}
      data-test="nav-menu">
      <Flex flexGrow="1" flex="1 1 auto" flexDirection="column" pt={2}>
        <Can
          perform={perms.dashboard.read}
          yes={() => (
            <NavigationItem data-tour="nav-dashboard" location="/">
              <Flex alignItems="center">
                <Icon name="dashboardNav" size={18} stroke={selectionColor} mr={2} />
                Dashboard
              </Flex>
            </NavigationItem>
          )}
        />
        {isHome && (
          <Can
            perform={perms.commsCenter.read}
            requiredHomeFeature="comms"
            unpermittedUserOrigins={['qk']}
            yes={() => (
              <NavigationItem data-tour="nav-comm-center" location="/messages">
                <Flex alignItems="center">
                  <Icon name="commsCenter" size={18} mr={2} />
                  Messages
                </Flex>
              </NavigationItem>
            )}
          />
        )}
        <Can
          perform={perms.moments.read}
          requiredHomeFeature="learning_events"
          yes={() => (
            <NavigationItem data-tour="nav-moments" location="/moments">
              <Flex alignItems="center">
                <Icon name="momentsNav" size={18} stroke={selectionColor} mr={2} />
                Moments
              </Flex>
            </NavigationItem>
          )}
        />
        <Can
          perform={perms.observations.read}
          requiredHomeFeature="learning_events"
          yes={() => (
            <NavigationItem data-tour="nav-posts" location="/observations">
              <Flex alignItems="center">
                <Icon name="postsNav" size={18} mr={2} fill={selectionColor} />
                Posts
              </Flex>
            </NavigationItem>
          )}
        />
        <Can
          perform={perms.learningStories.read}
          requiredHomeFeature="learning_events"
          yes={() => (
            <NavigationItem data-tour="nav-stories" location="/stories">
              <Flex alignItems="center">
                <Icon name="stories" size={18} stroke={selectionColor} mr={2} />
                Documents
              </Flex>
            </NavigationItem>
          )}
        />
        <Can
          perform={perms.learningPlans.read}
          requiredHomeFeature="learning_events"
          yes={() => (
            <NavigationItem data-tour="nav-planning" location="/planning">
              <Flex alignItems="center">
                <Icon name="canvasNav" size={18} stroke={selectionColor} mr={2} />
                Canvas
              </Flex>
            </NavigationItem>
          )}
        />
        <Can
          perform={perms.learningTables.read}
          requiredHomeFeature="learning_events"
          yes={() => (
            <NavigationItem data-tour="nav-tables" location="/tables">
              <Flex alignItems="center">
                <Icon name="tablesNav" size={18} stroke={selectionColor} mr={2} />
                Tables
              </Flex>
            </NavigationItem>
          )}
        />
        <Can
          perform={perms.childrenProfiles.read}
          yes={() => (
            <NavigationItem data-tour="nav-children" location="/children">
              <Flex alignItems="center">
                <Icon name="childrenNav" size={18} stroke={selectionColor} mr={2} />
                Children
              </Flex>
            </NavigationItem>
          )}
        />
        <Can
          perform={perms.parentFinance.read}
          requiredHomeFeature="finance"
          yes={() => (
            <NavigationItem data-tour="nav-parent-finance" location="/parent-finance">
              <Flex alignItems="center">
                <Icon name="financeNav" size={18} stroke={selectionColor} mr={2} />
                Finance
              </Flex>
            </NavigationItem>
          )}
        />
        <Can
          perform={perms.educatorProfiles.read}
          yes={() => (
            <NavigationItem data-tour="nav-educators" data-test="navigate-educators" location="/educators">
              <Flex alignItems="center">
                <Icon name="educatorsNav" size={18} stroke={selectionColor} mr={2} />
                Educators
              </Flex>
            </NavigationItem>
          )}
        />
        <Can
          perform={perms.roomProfiles.read}
          yes={() => (
            <NavigationItem location="/rooms">
              <Flex alignItems="center">
                <Icon name="roomsNav" size={18} stroke={selectionColor} mr={2} />
                Rooms
              </Flex>
            </NavigationItem>
          )}
        />
        {hasFrameworks ? (
          <Can
            perform={perms.learningAnalysis.read}
            yes={() => (
              <NavigationItem
                data-tour="nav-cohort-tracking"
                data-test="navigate-cohort-tracking"
                location="/learning-analysis">
                <Flex alignItems="center">
                  <Icon name="cohortTrackingNav" size={18} stroke={selectionColor} mr={2} />
                  Learning Analysis
                </Flex>
              </NavigationItem>
            )}
          />
        ) : null}
        <Can
          perform={perms.learningFrameworks.writeProvider}
          yes={() => (
            <NavigationItem
              data-tour="nav-educators"
              data-test="navigate-educators"
              location="/learning-frameworks">
              <Flex alignItems="center">
                <Icon name="learningFrameworksNav" size={18} stroke={selectionColor} mr={2} />
                Learning Frameworks
              </Flex>
            </NavigationItem>
          )}
        />
        <Can
          perform={hasReports}
          permissions={['true']}
          yes={() => (
            <NavigationItem location="/reports">
              <Flex alignItems="center">
                <Icon name="reportsNav" size={18} stroke={selectionColor} mr={2} />
                Reports
              </Flex>
            </NavigationItem>
          )}
        />
      </Flex>
      <Flex flex="0 1 auto" alignSelf="center">
        <Box pb={4}>
          <a href={selectedServiceLinks?.homeUrl}>
            <Image src={xplorImage} m={2} width="24" />
          </a>
        </Box>
      </Flex>
    </Flex>
  )
}

Navigation.displayName = 'Navigation'

export default Navigation
