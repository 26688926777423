import React from 'react'

import { Card, Flex } from 'stardust'

import Loading from '~/components/Loading/Observation'

const ContentPlaceholder = () => (
  <Card>
    <Flex
      width={[500, 800, 1000, 'auto']}
      maxHeight={['auto', 380]}
      flexDirection={['column-reverse', 'row']}
      justifyContent={['flex-start', 'space-between']}>
      <Loading />
    </Flex>
  </Card>
)

export default ContentPlaceholder
