import gql from 'graphql-tag'

export const GET_PLAN = gql`
  query LearningPlan($id: Int!, $serviceFkey: String, $contentStatus: ArticleContentStatus!) {
    context(fkey: $serviceFkey) {
      fkey
      learningPlan(id: $id) {
        id
        hasUnpublishedChanges
        isPublished
        status
        content(status: $contentStatus) {
          id
          title
          data
          status
          updatedAt
          generatedWith
          taggedEntities {
            id
            type
            typeFkey
            displayText
          }
        }
        contributors {
          id
          fullName
          userId
          userType
          at
          action
        }
      }
    }
  }
`

export const GET_PLAN_CANVAS = gql`
  query LearningPlan($id: Int!, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      learningPlan(id: $id) {
        id
        serviceFkey
        updatedAt
        children {
          id
          fkey
          fullName
          image
        }
        learningOutcomes {
          id
          name
        }
        content(status: PUBLISHED) {
          id
          title
        }
      }
    }
  }
`

export const GET_PLAN_DETAILED = gql`
  query LearningPlan($id: Int!, $serviceFkey: String, $contentStatus: ArticleContentStatus) {
    context(fkey: $serviceFkey) {
      fkey
      learningPlan(id: $id) {
        id
        hasUnpublishedChanges
        isPublished
        status

        children {
          fkey
          fullName
          image
        }

        rooms {
          fkey
          name
          ageGroup
        }

        personnel {
          id
          fkey
          fullName
          image
        }

        learningOutcomes {
          id
          name
        }

        contributors {
          id
          action
          fullName
          updatedAt
        }

        content(status: $contentStatus) {
          data
          generatedWith
          id
          status
          title
          updatedAt
          taggedEntities {
            id
            displayText
            type
            typeFkey
          }
        }
        insertedAt
        updatedAt
      }
    }
  }
`

export const GET_LEARNING_PLAN_SUMMARY = gql`
  query LearningPlanSummary($id: Int!, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      learningPlan(id: $id) {
        id
        content(status: PUBLISHED) {
          id
          title
        }
      }
    }
  }
`

export const GET_PLAN_STATUS = gql`
  query LearningPlanStatus($id: Int!, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      learningPlan(id: $id) {
        isPublished
      }
    }
  }
`
export const GET_LEARNING_PLANS = gql`
  query LearningPlans($cursor: String, $serviceFkey: String, $folderId: Int) {
    context(fkey: $serviceFkey) {
      fkey
      learningPlans(first: 20, after: $cursor, folderId: $folderId) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            commentCount
            hasUnpublishedChanges
            id
            isPublished
            status
            title
            updatedAt
            contributors {
              fullName
              action
            }
          }
        }
      }
    }
  }
`
export const GET_DRAFT = gql`
  query LearningPlansDraft($cursor: String, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      learningPlans(first: 20, after: $cursor, status: DRAFT_NOT_PUBLISHED) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            hasUnpublishedChanges
            isPublished
            status
            title
            updatedAt
            contributors {
              fullName
              action
            }
          }
        }
      }
    }
  }
`

export const GET_PUBLISHED = gql`
  query LearningPlansPublished($cursor: String, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      learningPlans(first: 20, after: $cursor, status: PUBLISHED) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            hasUnpublishedChanges
            isPublished
            status
            title
            updatedAt
            contributors {
              fullName
              action
            }
          }
        }
      }
    }
  }
`

export const GET_ARCHIVED = gql`
  query LearningPlansArchived($cursor: String, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      learningPlans(first: 20, after: $cursor, status: ARCHIVED) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            hasUnpublishedChanges
            isPublished
            status
            title
            updatedAt
            contributors {
              fullName
              action
            }
          }
        }
      }
    }
  }
`
export const GET_LAYOUT = gql`
  query LearningPlanLayout($id: ID, $serviceFkey: String!) {
    service(fkey: $serviceFkey) {
      fkey
      template(id: $id) {
        id
        title
        data
        generatedWith
      }
    }
  }
`

export const GET_LAYOUTS = gql`
  query Layouts($serviceFkey: String!) {
    service(fkey: $serviceFkey) {
      fkey
      templates(type: LEARNING_PLAN) {
        id
        title
        data
        global
        providerFkey
      }
    }
  }
`
export const GET_LEARNING_PLAN_WITH_REFLECTIONS = gql`
  query LearningPlanDetailWithReflections(
    $id: Int!
    $serviceFkey: String
    $contentStatus: ArticleContentStatus
  ) {
    context(fkey: $serviceFkey) {
      fkey
      learningPlan(id: $id) {
        hasUnpublishedChanges
        id
        isPublished
        status
        children {
          fkey
          fullName
          image
        }

        rooms {
          fkey
          name
          ageGroup
        }

        personnel {
          id
          fkey
          fullName
          image
        }

        learningOutcomes {
          id
          name
        }

        contributors {
          action
          fullName
          id
          updatedAt
        }

        content(status: $contentStatus) {
          data
          generatedWith
          id
          status
          title
          updatedAt
          reflections {
            id
            content
            insertedAt
            author {
              id
              userRef
              type
              name
            }
            replies {
              id
              content
              insertedAt
              author {
                id
                userRef
                type
                name
              }
            }
          }
          taggedEntities {
            id
            displayText
            type
            typeFkey
          }
        }
        insertedAt
        updatedAt
      }
    }
  }
`
export const GET_PLAN_CONTRIBUTORS = gql`
  query LearningPlanContributors($id: Int!, $serviceFkey: String, $contentStatus: ArticleContentStatus!) {
    context(fkey: $serviceFkey) {
      fkey
      learningPlan(id: $id) {
        content(status: $contentStatus) {
          id
        }
        contributors {
          id
          action
          at
          fullName
          userRef
        }
      }
    }
  }
`
