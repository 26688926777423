import React from 'react'

const Folder = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#00675C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 14.375C11.25 13.3395 12.0895 12.5 13.125 12.5H16.25C16.8402 12.5 17.3959 12.7779 17.75 13.25L19.0625 15H26.875C27.9105 15 28.75 15.8395 28.75 16.875V25.625C28.75 26.6605 27.9105 27.5 26.875 27.5H13.125C12.0895 27.5 11.25 26.6605 11.25 25.625V14.375ZM13.125 13.75C12.7798 13.75 12.5 14.0298 12.5 14.375V25.625C12.5 25.9702 12.7798 26.25 13.125 26.25H26.875C27.2202 26.25 27.5 25.9702 27.5 25.625V16.875C27.5 16.5298 27.2202 16.25 26.875 16.25H18.75C18.5533 16.25 18.368 16.1574 18.25 16L16.75 14C16.632 13.8426 16.4467 13.75 16.25 13.75H13.125Z"
        fill="white"
      />
    </svg>
  )
}

export default Folder
