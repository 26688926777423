import React from 'react'

import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

import { Box, Text } from 'stardust'

import { isHome } from '~/config'
import { sortObjectsBy } from '~/utils'

const GET_PARENT_CHILDREN = gql`
  query ParentChildren {
    parentGuardian {
      fkey
      children {
        id
        fkey
      }
    }
  }
`
interface Props {
  childrenList: Playground.SimpleChild[]
  renderChild: (child: Playground.SimpleChild) => JSX.Element
}

const SortedChildrenList = ({ childrenList, renderChild }: Props) => {
  const sortedChildren = sortObjectsBy(childrenList, 'fullName')
  return <Box>{sortedChildren.map(renderChild)}</Box>
}

const HomeChildrenList = ({ childrenList, renderChild }: Props) => {
  const { data, error, loading } = useQuery(GET_PARENT_CHILDREN)

  if (error) {
    throw error
  }

  if (loading) {
    return (
      <Text color="textPrimaryLow" p={2}>
        Loading...
      </Text>
    )
  }

  const {
    parentGuardian: { children: childrenOfParent },
  } = data

  const childrenToShow = childrenOfParent.map((child: { fkey: string }) => child.fkey)
  const filteredChildren = childrenList.filter((child) => childrenToShow.includes(child.fkey))
  const hiddenChildrenCount = childrenList.length - filteredChildren.length

  return (
    <Box>
      <SortedChildrenList childrenList={filteredChildren} renderChild={renderChild} />
      {hiddenChildrenCount !== 0 && (
        <Text color="textPrimaryLow" fontSize={0} pt={3}>{`${hiddenChildrenCount} other ${
          hiddenChildrenCount === 1 ? 'child' : 'children'
        }`}</Text>
      )}
    </Box>
  )
}

const PrivacyAwareChildList = (props: Props) =>
  isHome ? <HomeChildrenList {...props} /> : <SortedChildrenList {...props} />

export default React.memo(PrivacyAwareChildList)
