import React, { useRef, useState } from 'react'

import { Location, navigate } from '@reach/router'
import { Box, Flex, Link, Popover, SearchField } from 'stardust'
import styled from 'styled-components'

import Can from '~/components/Authorisation/Can'
import SearchResults from '~/components/Search/'
import { resultLink } from '~/components/Search/core'
import { useSelectedServiceFkey } from '~/contexts/Service'
import useDebounce from '~/hooks/useDebounce'
import { search as searchPerms } from '~/modules/permissions'
import { HEADER_HEIGHT } from '~/theme'
import colors from '~/ui-components/tokens/colors'

import SearchResult from './SearchResult'

const buildQuery = (query) => '/search?query=' + query

const extractSearchItems = (searchData) => {
  const documentSearchItems = searchData.context.documents.edges
    .map((edge) => edge.node)
    .map((doc) => mapDocumentToSearchResult(doc))

  const entitySearchItems = searchData.context.taggableEntities.map((entity) =>
    mapEntityToSearchResult(entity)
  )

  return [...entitySearchItems, ...documentSearchItems]
}

// TODO.HT: Change this file to use TS, have explicit types for documents and
// tagged entities. Then the display components should handle each type distinctly.
const mapDocumentToSearchResult = (document) => ({
  author: document.author,
  date: document.documentDate,
  displayText: document.title,
  imageUrl: document.coverImage,
  status: document.status,
  type: document.type,
  typeIdOrFkey: document.typeId.toString(),
})

const mapEntityToSearchResult = (entity) => ({
  author: null,
  date: null,
  displayText: entity.displayText,
  imageUrl: entity.imageUrl,
  status: null,
  type: entity.type,
  typeIdOrFkey: entity.typeFkey,
})

const S = {
  Link: styled(Link)`
    color: ${(props) => props.theme.colors.textLink};
  `,
}

const SeeMore = ({ query }) => (
  <Flex justifyContent="center" p={3}>
    <S.Link to={buildQuery(query)}>See More</S.Link>
  </Flex>
)

const StyledSearchField = styled(SearchField)`
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: ${colors.cosmicShade0};
  }
  .mdc-text-field--filled .mdc-floating-label--float-above {
    transform: translateY(-96%) scale(0.75);
  }
  .mdc-text-field__input {
    align-self: end;
    padding-top: 0px;
    height: 32px;
  }
  .mdc-line-ripple {
    display: none;
  }
  .mdc-text-field--filled {
    height: calc(${HEADER_HEIGHT} - 18px);
  }
  border: black;
  max-width: 500px;
  width: 100%;
  border-style: solid;
  border-color: #e2e2e2;
  border-width: 1px;
  background-color: white;
`

// Number of results to return in dropdown list
const MAX_RESULTS = 7
const MIN_QUERY_LENGTH = 2

const Search = () => {
  const [query, changeQuery] = useState('')
  const [selectedResultIdx, changeSelectedResult] = useState(-1)
  const [showResults, changeShowResults] = useState(false)

  const selectedServiceFkey = useSelectedServiceFkey()

  const searchQuery = useDebounce(query, 300)

  const searchRef = useRef(null)
  const selectedRef = useRef(null)

  const onSearchChange = (e) => {
    const val = e.target.value
    changeQuery(val)
    changeShowResults(val.length > 2)
  }

  const onSearchClear = () => {
    changeQuery('')
    changeShowResults(false)
  }

  const onSearchClick = () => changeShowResults(query.length > MIN_QUERY_LENGTH)

  const onSearchKeyPress = (e) => {
    if (query.length < MIN_QUERY_LENGTH) return

    if (e.key === 'Enter' && selectedResultIdx < 0) {
      changeShowResults(false)
      navigate(buildQuery(query))
    } else if (e.key === 'Enter') {
      changeSelectedResult(-1)
      selectedRef && selectedRef.current && selectedRef.current.click()
    } else if (e.key === 'ArrowUp') {
      changeSelectedResult(selectedResultIdx - 1 < 0 ? MAX_RESULTS - 1 : selectedResultIdx - 1)
    } else if (e.key === 'ArrowDown') {
      changeSelectedResult(selectedResultIdx + 1 > MAX_RESULTS - 1 ? 0 : selectedResultIdx + 1)
    }
  }

  return (
    <Can
      perform={searchPerms.read}
      yes={() => (
        <>
          <StyledSearchField
            borderRadius={20}
            ref={searchRef}
            label="Search"
            name="query"
            value={query}
            onChange={onSearchChange}
            onClick={onSearchClick}
            onKeyDown={onSearchKeyPress}></StyledSearchField>

          <Location>
            {({ location }) =>
              location.pathname !== ('/search' || '/webview') &&
              showResults && (
                <Popover
                  anchor={searchRef}
                  placement="bottom-start"
                  show={showResults}
                  onToggle={changeShowResults}>
                  <Box
                    p={0}
                    width="460px"
                    onMouseEnter={() => changeSelectedResult(null)}
                    data-test="search-results">
                    <SearchResults
                      query={searchQuery}
                      serviceFkey={selectedServiceFkey}
                      numberResults={MAX_RESULTS}>
                      {(data) =>
                        extractSearchItems(data)
                          .map((item, idx) => (
                            <SearchResult
                              author={item.author}
                              imageUrl={item.imageUrl}
                              date={item.date}
                              displayText={item.displayText}
                              type={item.type}
                              key={item.typeIdOrFkey}
                              resultLink={resultLink(item.type, item.typeIdOrFkey, query)}
                              ref={idx === selectedResultIdx ? selectedRef : null}
                              query={query}
                              isSelected={idx === selectedResultIdx}
                              clearSearch={onSearchClear}
                            />
                          ))
                          .slice(0, MAX_RESULTS)
                          .concat(<SeeMore query={query} key="see-more" />)
                      }
                    </SearchResults>
                  </Box>
                </Popover>
              )
            }
          </Location>
        </>
      )}
    />
  )
}

Search.displayName = 'Search'

export default Search
