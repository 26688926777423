import * as R from 'ramda'

import tokens from 'stardust/tokens'

import { jsonSerializer } from '~/modules/textEditor'
import { generateUuid } from '~/utils'

import { initialValue } from '../Editor'

import * as kinds from './nodeTypes'
import * as refKinds from './refKinds'
import * as selectors from './selectors'
import shapeKinds from './shapeTypes'

/**
 * Factory functions to create nodes with each type's appropriate
 * defaults.
 *
 * *** 🚨🚨🚨 Convention 🚨🚨🚨***
 * `ref` and `refId` are a reference to an external resource.
 *
 * E.g. in a shape
 * {
 *   ref: 'child',
 *   refId: 1234
 * }
 *
 * Refers to a child resource with the primary key `1234`
 */

/**
 * Create shape with child
 */
export const childFactory = (props = {}) => {
  const id = generateUuid()

  return R.mergeDeepRight(
    {
      id,
      data: {
        ref: refKinds.child,
        refData: null,
        refId: null,
      },
      kind: kinds.shape,
      properties: {
        background: tokens.colors.hullOrange1,
        dimensions: {
          width: 96,
          height: 96,
        },
        kind: shapeKinds.circle,
        point: {
          x: 0,
          y: 0,
        },
        z: 150,
      },
      tagged: [],
    },
    props
  )
}

/**
 * Create rich text that is not bound to a shape.
 * A content node does not have dimensions set, as we let the
 * dimensions grow with the content, UNLESS it is explicity resized.
 * Once resized, the dimensions will be set and respected.
 */
export const contentFactory = (props = {}) => {
  const id = generateUuid()

  return R.mergeDeepRight(
    {
      id,
      data: {
        data: null,
        generatedWith: null,
      },
      kind: kinds.content,
      properties: {
        dimensions: {
          width: null,
          height: null,
        },
        point: {
          x: 0,
          y: 0,
        },
        z: 150,
      },
    },
    props
  )
}

/**
 * Create shape with learning document
 * (Observation, Plan, Story).
 */
export const documentFactory = (props = {}) => {
  const id = generateUuid()

  return R.mergeDeepRight(
    {
      id,
      data: {
        ref: null,
        refData: null,
        refId: null,
      },
      kind: kinds.shape,
      properties: {
        background: tokens.colors.hullOrange1,
        dimensions: {
          width: 200,
          height: 200,
        },
        kind: shapeKinds.square,
        point: {
          x: 0,
          y: 0,
        },
        z: 150,
      },
      tagged: [],
    },
    props
  )
}

/**
 * Create a media node
 *
 * Some media objects will have a url prop. This is for backwards
 * compatibility as we were not storing the media id in older
 * versions of the planner.
 */
export const mediaFactory = (props = {}) => {
  const id = generateUuid()

  return R.mergeDeepRight(
    {
      id,
      data: {
        refId: null,
        ref: null,
        // url: **LEGACY**
      },
      kind: kinds.media,
      properties: {
        dimensions: {
          width: 200,
          height: 200,
        },
        point: {
          x: 0,
          y: 0,
        },
        z: 150,
      },
      tagged: [],
    },
    props
  )
}

/**
 * Create slate editor content inside a shape
 */
export const shapeContentFactory = (props = {}) => {
  return R.mergeDeepRight(
    {
      refData: jsonSerializer.serialize(initialValue),
      ref: refKinds.content,
    },
    props
  )
}

/**
 * Create empty shape
 */
export const shapeFactory = (props = {}) => {
  const id = generateUuid()

  return R.mergeDeepRight(
    {
      id,
      data: {
        ref: null,
        refData: null,
        refId: null,
      },
      kind: kinds.shape,
      properties: {
        background: tokens.colors.hullOrange1,
        dimensions: {
          width: 200,
          height: 200,
        },
        kind: null,
        point: {
          x: 0,
          y: 0,
        },
        z: 150,
      },
      tagged: [],
    },
    props
  )
}

/**
 * Create sticker
 */
export const stickerFactory = (props = {}) => {
  const id = generateUuid()

  return R.mergeDeepRight(
    {
      id,
      data: {
        ref: null,
        refId: null,
      },
      kind: kinds.sticker,
      properties: {
        dimensions: {
          width: 48,
          height: 48,
        },
        point: {
          x: 0,
          y: 0,
        },
        z: 150,
      },
    },
    props
  )
}

/**
 * Clone a node
 */
export const cloneFactory = (node, direction) => {
  const { x, y } = selectors.point.get(node)
  const { width, height } = selectors.dimensions.get(node)

  let newPoint = null

  switch (direction) {
    case 'nw': {
      newPoint = { x: x - width, y: y - height }
      break
    }

    case 'n': {
      newPoint = { x, y: y - height }
      break
    }

    case 'ne': {
      newPoint = { x: x + width, y: y - height }
      break
    }

    case 'e': {
      newPoint = { x: x + width, y }
      break
    }

    case 'se': {
      newPoint = { x: x + width, y: y + height }
      break
    }

    case 's': {
      newPoint = { x, y: y + height }
      break
    }

    case 'sw': {
      newPoint = { x: x - width, y: y + height }
      break
    }

    case 'w': {
      newPoint = { x: x - width, y }
      break
    }

    default: {
      newPoint = { x, y }
    }
  }

  const kind = selectors.kind.get(node)

  let fn = selectors.data.set({ ref: null, refId: null, refData: null })

  if (kinds.isSticker(kind) || kinds.isMedia(kind)) {
    fn = R.identity
  }

  return R.pipe(
    selectors.id.set(generateUuid()),
    selectors.point.set(newPoint),
    fn,
    selectors.tagged.set([])
  )(node)
}
