import React from 'react'

const Alert = () => (
  <svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 18.75C11.7929 18.75 11.625 18.9179 11.625 19.125C11.625 19.3321 11.7929 19.5 12 19.5C12.2071 19.5 12.375 19.3321 12.375 19.125C12.375 18.9179 12.2071 18.75 12 18.75V18.75"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12 15.75V8.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.621 1.76001C13.3174 1.14189 12.6887 0.750244 12 0.750244C11.3113 0.750244 10.6826 1.14189 10.379 1.76001L0.905996 21.058C0.67417 21.5296 0.702197 22.0875 0.980124 22.5334C1.25805 22.9794 1.7465 23.2504 2.272 23.25H21.728C22.2535 23.2504 22.7419 22.9794 23.0199 22.5334C23.2978 22.0875 23.3258 21.5296 23.094 21.058L13.621 1.76001Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Alert
