import format from 'date-fns/format'

// Converts 15:30:30 to 3:30pm
export const toTimeString12hr = (time: Nullable<string>) => {
  if (!time) {
    return ''
  }
  return format(new Date('1970-01-01T' + time + 'Z'), 'h:mm a')
}

export const toTimeHHMM = (time: Nullable<string>) => {
  if (!time) {
    return ''
  }
  return format(new Date('1970-01-01T' + time + 'Z'), 'hh:mm')
}
