import React from 'react'

import Truncate from 'react-truncate'
import { Card, Flex, Icon, Text } from 'stardust'
import styled from 'styled-components'

interface Props {
  description?: string
  title?: string
  onOpen?(): void
}

const Span = styled(Text.span)`
  color: ${(props) => props.theme.colors.textPrimaryMedium};
`

const DocumentPreviewCard = ({ description, title, onOpen }: Props) => {
  return (
    <Card bg="surfacePrimary" border="1px solid" borderColor="nebulaBlue" cursor="pointer" onClick={onOpen}>
      <Flex flexDirection="column" p={3} position="relative">
        <Flex justifyContent="space-between">
          <Text.span bold color="nebulaBlue" mb={2}>
            {title || 'Document'}
          </Text.span>
          {onOpen && <Icon color="primary" name="open" ml={2} size={20} />}
        </Flex>
        <Span fontSize={1}>{description && <Truncate lines={3}>{description}</Truncate>}</Span>
      </Flex>
    </Card>
  )
}

DocumentPreviewCard.displayName = 'DocumentPreviewCard'

export default React.memo(DocumentPreviewCard)
