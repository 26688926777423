import { useContext, useEffect } from 'react'

import { isPlayground } from '~/config'
import { isInPlaygroundApp } from '~/config'
import { ServiceContext } from '~/contexts/Service'
import { UserContext } from '~/contexts/User'
import useIsViewingDashboard from '~/hooks/useIsViewingDashboard'

import useScript from './useScript'

interface AskNicelyConfig {
  config: {
    alignment: string
    domain_key: string
    template_name: string
    name: string
    email: string
    email_hash: string
  }
  customData: {
    product: string
    service_name: Nullable<string>
    user_type: string
    service_fkey: Nullable<string>
    bms: string
  }
}

declare global {
  interface Window {
    askNicelyConversation: (config: AskNicelyConfig) => void
  }
}

const useAddAskNicelySurvey = () => {
  const scriptStatus = useScript({
    url: 'https://static.asknice.ly/dist/standalone/asknicely-in-app-conversation.js',
    element: 'script',
    attributes: {
      type: 'text/javascript',
      src: 'https://static.asknice.ly/dist/standalone/asknicely-in-app-conversation.js',
    },
  })
  const linkStatus = useScript({
    url: 'https://static.asknice.ly/dist/standalone/asknicely-in-app-conversation.css',
    element: 'link',
    attributes: {
      type: 'text/css',
      href: 'https://static.asknice.ly/dist/standalone/asknicely-in-app-conversation.css',
      rel: 'stylesheet',
      charset: 'utf-8',
    },
  })

  const {
    state: { selectedServiceFkey, selectedServiceName },
  } = useContext(ServiceContext)

  const { state: user } = useContext(UserContext)
  const { displayName, roles, origin, email, emailHash } = user as Playground.User
  const isViewingDashboard = useIsViewingDashboard()

  useEffect(() => {
    const askNicelyLibLoaded = linkStatus === 'ready' && scriptStatus === 'ready'
    const userAndServiceLoaded = email && selectedServiceFkey

    if (isViewingDashboard && !isInPlaygroundApp && askNicelyLibLoaded && userAndServiceLoaded) {
      window.askNicelyConversation({
        config: {
          alignment: 'right',
          domain_key: 'xplor',
          template_name: 'Playground',
          name: displayName,
          email,
          email_hash: emailHash,
        },
        customData: {
          product: isPlayground ? 'Playground Web' : 'Home Web',
          service_name: selectedServiceName,
          user_type: `${roles.join(',')}`,
          service_fkey: selectedServiceFkey,
          bms: origin,
        },
      })
    }
  }, [
    isViewingDashboard,
    linkStatus,
    scriptStatus,
    origin,
    roles,
    displayName,
    selectedServiceName,
    selectedServiceFkey,
    email,
    emailHash,
  ])
}

export default useAddAskNicelySurvey
