import React from 'react'

import { Card } from 'stardust'

import ContentPlaceholder from '~/components/ContentPlaceholder'

import MomentRenderer from '~/pages/Moments/MomentRenderer'

interface Props {
  id: Nullable<number>
}

const MomentDetailCard = ({ id }: Props) => {
  if (!id) {
    return <ContentPlaceholder />
  }

  return (
    <Card bg="surfaceSecondary" overflow="hidden">
      <MomentRenderer id={id} onLoadComponent={<ContentPlaceholder />} />
    </Card>
  )
}

MomentDetailCard.displayName = 'MomentDetailCard'

export default React.memo(MomentDetailCard)
