import React from 'react'

const LikesIcon = () => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="32" height="32" rx="16" fill="#D21D3E" />
    <path
      d="M11.9087 8.78577C9.19744 8.78577 7 10.8405 7 13.3757C7 17.7886 12.4742 22.0051 15.0317 23.8829C15.0342 23.8847 15.0366 23.8865 15.0391 23.8883C15.0566 23.9012 15.0804 23.9195 15.0977 23.9322L15.0991 23.9308C15.3586 24.1141 15.6748 24.2136 16 24.2143C16.3257 24.2139 16.6425 24.1144 16.9023 23.9308V23.9322C16.9074 23.9285 16.9149 23.9236 16.9199 23.9198C16.9238 23.917 16.9292 23.9131 16.9331 23.9103C16.938 23.9062 16.9429 23.9021 16.9478 23.8979C19.4973 22.0269 25 17.7991 25 13.3757C25 10.8405 22.8026 8.78577 20.0913 8.78577C17.5001 8.78577 16 10.8897 16 10.8897C16 10.8897 14.4999 8.78577 11.9087 8.78577Z"
      fill="white"
    />
  </svg>
)

export default LikesIcon
