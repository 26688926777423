import React, { ReactNode } from 'react'

import { Redirect, RouteComponentProps } from '@reach/router'

import Can from './Can'

interface Props extends RouteComponentProps {
  perform: string
  permissions?: string[]
  requiredHomeFeature?: Playground.ChildPermission
  unpermittedUserOrigins?: Playground.UserOrigin[]
  no?(props: any): ReactNode
  yes(props: any): ReactNode
}

const CanRoute = (props: Props) => {
  return (
    <Can
      perform={props.perform}
      permissions={props.permissions}
      unpermittedUserOrigins={props.unpermittedUserOrigins}
      yes={() => <>{props.yes(props)}</>}
      no={() => (props.no ? <>{props.no(props)}</> : <Redirect to="/" noThrow />)}
    />
  )
}

export default CanRoute
