/* eslint react/display-name: 0 */
import React from 'react'

import * as R from 'ramda'
import { Change } from 'slate'
import { RenderMarkProps } from 'slate-react'
import styled from 'styled-components'

import { ToolbarButton } from '~/components/Editors/components/Toolbar'

import { Next, RenderToolbarItemProps, SerializeProps } from '../types'

interface PluginProps {
  alignmentType: AlignmentTypes
  iconName: string
}

const AlignText = styled.span<{ alignment: string }>`
  display: block;
  text-align: ${(props) => `${props.alignment}`};
`

export enum AlignmentTypes {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
}

const handleChange = (type: AlignmentTypes, change: Change) => {
  return change
    .removeMark(AlignmentTypes.LEFT)
    .removeMark(AlignmentTypes.RIGHT)
    .removeMark(AlignmentTypes.CENTER)
    .toggleMark(type)
}

export const buildPlugin = ({ alignmentType, iconName }: PluginProps) => ({
  serialize: (obj: SerializeProps, children: any) => {
    if (obj.object === 'mark' && obj.type === alignmentType) {
      return <span className={`${alignmentType}`}>{children}</span>
    }
  },

  renderToolbarItem: ({ editor, disabled = false }: RenderToolbarItemProps) => {
    const activeMarks = editor?.value.activeMarks || []
    const isActive = activeMarks.some((node: any) => node.type == alignmentType)

    const toggleMark = (e: Event) => {
      e.preventDefault()
      if (editor) {
        editor.change(R.partial(handleChange, [alignmentType]))
      }
    }

    return <ToolbarButton active={isActive} action={toggleMark} iconName={iconName} disabled={disabled} />
  },

  renderMark: ({ children, mark, attributes }: RenderMarkProps, next: Next) => {
    if (mark.type !== alignmentType) return next()

    return (
      <AlignText {...attributes} alignment={alignmentType}>
        {children}
      </AlignText>
    )
  },
})

export default [
  { alignmentType: AlignmentTypes.LEFT, iconName: 'alignLeft' },
  { alignmentType: AlignmentTypes.CENTER, iconName: 'alignCenter' },
  { alignmentType: AlignmentTypes.RIGHT, iconName: 'alignRight' },
].map((e) => buildPlugin(e))
