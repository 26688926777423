// TO.DO: AP - Refactor this whole component. We don't need all three doc types, just one and pass some params
import React, { memo, useContext, useEffect } from 'react'

import { useQuery } from '@apollo/client'
import * as R from 'ramda'
import { Absolute, BackgroundImage, Flex, Relative, Truncate } from 'stardust'
import styled from 'styled-components'

import { useSelectedServiceFkey } from '~/contexts/Service'
import { GET_MOMENT_CANVAS } from '~/pages/Moments/queries'
import { GET_OBSERVATION_CANVAS } from '~/pages/Observations/queries'
import { GET_PLAN_CANVAS } from '~/pages/Planning/queries'
import { GET_STORY_CANVAS } from '~/pages/Stories/queries'
import { GET_TABLE_CANVAS } from '~/pages/Tables/queries'
import { hasAtLeastOne, isNotNilOrEmpty, toFkey } from '~/utils'

import { nodeBatchTaggedAdd } from '../core/actions'
import * as nodeKinds from '../core/nodeTypes'
import { dataDropLens, dataRefIdLens, dataRefLens } from '../core/selectors'
import { EditContext } from '../Nodes/Edit'

const S = {
  Absolute: styled(Absolute)`
    background: rgba(0, 0, 0, 0.25);
    ${({ hover }) => hover && 'cursor: pointer'};
  `,
  Truncate: styled(Truncate)`
    ${({ underline }) => underline && 'text-decoration: underline'};
    user-select: none;
  `,
}

const updateTaggedEntities = (data, nodeId, category) => {
  const children = R.path(['context', category, 'children'], data)
  const learningOutcomes =
    category === 'moment' ? [] : R.path(['context', category, 'learningOutcomes'], data)

  const transformChild = (child) => ({
    data: { fkey: child.fkey, fullName: child.fullName },
    nodeId: nodeId,
    type: nodeKinds.child,
  })

  const transformLearningOutcome = (learningOutcome) => ({
    data: { fkey: toFkey(learningOutcome.id), name: learningOutcome.name },
    nodeId: nodeId,
    type: nodeKinds.learningOutcome,
  })

  if (hasAtLeastOne(children) || hasAtLeastOne(learningOutcomes)) {
    const transformedChildren = children.map((child) => transformChild(child))
    const transformedLearningOutcomes = learningOutcomes.map((lo) => transformLearningOutcome(lo))
    const entities = R.concat(transformedChildren, transformedLearningOutcomes)

    return nodeBatchTaggedAdd(entities)
  }

  return []
}

const useDocumentTaggedEntities = (dispatch, path, node, data, readOnly) => {
  useEffect(() => {
    if (!readOnly) {
      const isFreshlyDropped = R.view(dataDropLens, node)

      if (isNotNilOrEmpty(data) && isFreshlyDropped) {
        dispatch(updateTaggedEntities(data, node.id, path))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
}

const Observation = ({ handleSelectObservation, hover, readOnly = false, serviceFkey, ...props }) => {
  const refId = R.view(dataRefIdLens, props)
  const { dispatch } = useContext(EditContext)

  const { data, loading, error } = useQuery(GET_OBSERVATION_CANVAS, {
    variables: {
      id: Number(refId),
      serviceFkey,
    },
  })

  useDocumentTaggedEntities(dispatch, 'observation', props, data, readOnly)

  if (loading || error) {
    return null
  }

  const observation = R.path(['context', 'observation'], data)
  const imgUrl = R.path(['media', 0, 'coverUrl'], observation)
  const { title, status } = observation
  const articleType = data.context.observation.__typename

  if (status === 'deleted') return null

  return (
    <ShapePreview
      id={props.id}
      kind={props.kind}
      readOnly={readOnly}
      refId={refId}
      title={title}
      backgroundImg={imgUrl}
      handleSelectPreview={handleSelectObservation}
      hover={hover}
      articleType={articleType}
    />
  )
}

Observation.displayName = 'Observation'

const LearningPlan = ({ handleSelectPlan, hover, readOnly = false, serviceFkey, ...props }) => {
  const refId = R.view(dataRefIdLens, props)
  const { dispatch } = useContext(EditContext)

  const { data, loading, error } = useQuery(GET_PLAN_CANVAS, {
    variables: {
      id: Number(refId),
      serviceFkey,
    },
  })

  useDocumentTaggedEntities(dispatch, 'learningPlan', props, data, readOnly)

  if (loading || error) {
    return null
  }

  const title = R.path(['context', 'learningPlan', 'content', 0, 'title'], data)

  return (
    <ShapePreview
      id={props.id}
      kind={props.kind}
      readOnly={readOnly}
      refId={refId}
      title={title}
      handleSelectPreview={handleSelectPlan}
      hover={hover}
    />
  )
}

LearningPlan.displayName = 'LearningPlan'

const LearningStory = ({ handleSelectStory, hover, readOnly = false, serviceFkey, ...props }) => {
  const refId = R.view(dataRefIdLens, props)
  const { dispatch } = useContext(EditContext)

  const { data, loading, error } = useQuery(GET_STORY_CANVAS, {
    variables: {
      id: Number(refId),
      serviceFkey,
    },
  })

  useDocumentTaggedEntities(dispatch, 'learningStory', props, data, readOnly)

  if (loading || error) {
    return null
  }

  const title = R.path(['context', 'learningStory', 'content', 0, 'title'], data)
  const articleType = data.context.learningStory.__typename

  return (
    <ShapePreview
      id={props.id}
      kind={props.kind}
      readOnly={readOnly}
      refId={refId}
      title={title}
      handleSelectPreview={handleSelectStory}
      hover={hover}
      articleType={articleType}
    />
  )
}

LearningStory.displayName = 'LearningStory'

const LearningTable = ({ handleSelectTable, hover, readOnly = false, serviceFkey, ...props }) => {
  const refId = R.view(dataRefIdLens, props)
  const { dispatch } = useContext(EditContext)

  const { data, loading, error } = useQuery(GET_TABLE_CANVAS, {
    variables: {
      id: Number(refId),
      serviceFkey,
    },
  })

  useDocumentTaggedEntities(dispatch, 'learningTable', props, data, readOnly)

  if (loading || error) {
    return null
  }

  const title = R.path(['context', 'learningTable', 'content', 0, 'title'], data)
  const articleType = data.context.learningTable.__typename

  return (
    <ShapePreview
      id={props.id}
      kind={props.kind}
      readOnly={readOnly}
      refId={refId}
      title={title}
      handleSelectPreview={handleSelectTable}
      hover={hover}
      articleType={articleType}
    />
  )
}

LearningTable.displayName = 'LearningTable'

const Moment = ({ handleSelectMoment, hover, readOnly = false, serviceFkey, ...props }) => {
  const refId = R.view(dataRefIdLens, props)
  const { dispatch } = useContext(EditContext)

  const { data, loading, error } = useQuery(GET_MOMENT_CANVAS, {
    variables: {
      momentId: Number(refId),
      serviceFkey,
    },
  })

  useDocumentTaggedEntities(dispatch, 'moment', props, data, readOnly)

  if (loading || error) {
    return null
  }

  const imgUrl = R.path(['context', 'moment', 'media', 0, 'coverUrl'], data)
  const title = R.path(['context', 'moment', 'caption'], data)
  const articleType = data.context.moment.__typename

  return (
    <ShapePreview
      id={props.id}
      kind={props.kind}
      readOnly={readOnly}
      refId={refId}
      title={title}
      backgroundImg={imgUrl}
      handleSelectPreview={handleSelectMoment}
      hover={hover}
      articleType={articleType}
    />
  )
}

Moment.displayName = 'Moment'

const ShapePreview = ({
  id,
  kind,
  readOnly,
  refId,
  title,
  backgroundImg,
  handleSelectPreview,
  hover,
  articleType,
}) => {
  return (
    <Relative height="100%" width={1}>
      {backgroundImg && <BackgroundImage height="100%" image={backgroundImg} width={1} />}
      <S.Absolute
        bottom={0}
        hover={hover}
        left={0}
        onClick={readOnly ? () => handleSelectPreview(refId) : R.identity}
        right={0}
        top={0}>
        <Flex
          alignItems="center"
          color="white"
          data-drop-id={id}
          data-drop={kind}
          data-test={`${articleType}-${title}`}
          flexDirection="column"
          height="100%"
          justifyContent="center"
          width={1}>
          <S.Truncate bold color="white" data-drop-id={id} data-drop={kind} underline={hover}>
            {title}
          </S.Truncate>
        </Flex>
      </S.Absolute>
    </Relative>
  )
}

ShapePreview.displayName = 'ShapePreview'

const ShapeDocument = memo((props) => {
  const ref = R.view(dataRefLens, props)

  const serviceFkey = useSelectedServiceFkey()

  const Component = R.cond([
    [R.equals(nodeKinds.observation), () => Observation],
    [R.equals(nodeKinds.learningStory), () => LearningStory],
    [R.equals(nodeKinds.learningPlan), () => LearningPlan],
    [R.equals(nodeKinds.moment), () => Moment],
    [R.equals(nodeKinds.learningTable), () => LearningTable],
  ])(ref)

  return <Component serviceFkey={serviceFkey} {...props} />
})

ShapeDocument.displayName = 'ShapeDocument'

export default ShapeDocument
