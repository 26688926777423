import React from 'react'

const CalendarAlt2 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.751953 5.75C0.751953 4.92157 1.42353 4.25 2.25195 4.25H21.752C22.5804 4.25 23.252 4.92157 23.252 5.75V22.25C23.252 23.0784 22.5804 23.75 21.752 23.75H2.25195C1.42353 23.75 0.751953 23.0784 0.751953 22.25V5.75Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.751953 10.25H23.252"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.75195 6.5V1.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.252 6.5V1.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CalendarAlt2
