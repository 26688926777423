import React, { useContext, useState, useEffect, useCallback } from 'react'

import { useMutation, useQuery, useLazyQuery, ApolloQueryResult } from '@apollo/client'
import { useLocation } from '@reach/router'

import { format } from 'date-fns'

import Modal from '~/components/Modal'
import { generateToastMessage } from '~/components/ToastMessages/GenerateToastMessage'
import { ServiceContext, useSelectedServiceFkey } from '~/contexts/Service'
import * as ObservationAnalytics from '~/modules/analytics/observations'

import {
  UPDATE_POST_AS_PUBLISHED,
  UPDATE_POST_AS_DRAFT,
  CREATE_DRAFT_POST,
  CREATE_POST_LOCK,
  RELEASE_POST_LOCK,
  PUBLISH_POST,
} from '../mutations'
import { GET_SERVICE_DETAILS, GET_POST_UPDATED_AT } from '../queries'

import { useNeedsReviewDocuments } from '../useNeedsReviewDocuments'

import AddNewPostModalLoader from './Form/AddNewPostModalLoader'

import LoadingPostModal from './LoadingPostModal'

interface CreateProps {
  availableRooms: Playground.Room[]
  learningFrameworks?: Playground.LearningFramework[]
  observation?: any
  onSave?(): void
  handleCloseModal: () => void
  handleCloseModalOnSubmit: () => void
  setPostIdForDeletion: (id: number) => void
  refreshPostList?: () => Promise<ApolloQueryResult<any>>
}

export interface LoaderProps {
  observation?: Playground.Observation
  setPostIdForDeletion: (id: number) => void
  onSave?(): void
  handleCloseModal: () => void
  handleCloseModalOnSubmit: () => void
  refreshPostList?: () => Promise<ApolloQueryResult<any>>
}

const Create = React.memo(
  ({
    availableRooms,
    onSave = () => {},
    learningFrameworks,
    observation,
    setPostIdForDeletion,
    handleCloseModalOnSubmit,
    handleCloseModal,
    refreshPostList,
  }: CreateProps) => {
    const location = useLocation()
    const [updatedAt, setUpdatedAt] = useState()
    const [postId, setPostId] = useState<number | null>(null)
    const serviceFkey = useSelectedServiceFkey()
    const [createPostLock] = useMutation(CREATE_POST_LOCK)
    const [createDraftPost] = useMutation(CREATE_DRAFT_POST)
    const [updateDraftPost] = useMutation(UPDATE_POST_AS_DRAFT)
    const [updatePublishedPost] = useMutation(UPDATE_POST_AS_PUBLISHED)
    const [publishPost] = useMutation(PUBLISH_POST, {
      onCompleted: (data) => {
        const { id, status } = data.publishPost
        generateToastMessage(`publish${id}`, {
          message:
            status === 'NEEDS_REVIEW' ? 'Post is under review' : "Post 'Shared with Families' successfully",
        })
      },
    })
    const [releasePostLock] = useMutation(RELEASE_POST_LOCK)
    const [getPostUpdatedAt, { data: postUpdatedAtData }] = useLazyQuery(GET_POST_UPDATED_AT, {
      fetchPolicy: 'no-cache',
    })
    const updatedPost = postUpdatedAtData?.service?.post || {}

    const {
      state: { selectedServiceTimeZone },
    } = useContext(ServiceContext)

    useEffect(() => {
      if (postId) {
        setPostIdForDeletion(postId)
      }
    }, [postId, setPostIdForDeletion])

    useEffect(() => {
      if (updatedPost.updatedAt) {
        setUpdatedAt(updatedPost.updatedAt)
      }
    }, [updatedPost.updatedAt, updatedPost.id, updatedAt, postUpdatedAtData])

    useEffect(() => {
      if (postId) {
        createPostLock({ variables: { id: Number(postId) } })
      }
    }, [postId, createPostLock])

    useEffect(() => {
      const handleCreateLoad = () => {
        createDraftPost({
          variables: {
            serviceFkey,
            title: 'Title',
            description: 'Description',
            childFkeys: [],
            postDate: format(new Date(), 'YYYY-MM-DD'),
            postTime: format(new Date(), 'HH:mm:ss'),
            timezone: selectedServiceTimeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        })
          .then((response) => {
            if (response.data.createPost) {
              setPostId(response.data.createPost.id)
              setUpdatedAt(response.data.createPost.updatedAt)
            }
          })
          .catch((error) => {
            console.error('Error creating draft post:', error)
          })
      }

      handleCreateLoad()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { refetchNeedsReviewCount } = useNeedsReviewDocuments()

    const handleOnSubmit = useCallback(
      async (variables: any, statusToSave: string, setLoadingFalse: () => void) => {
        const query = statusToSave === 'PUBLISHED' ? updatePublishedPost : updateDraftPost
        try {
          await query({
            variables: {
              ...variables,
              id: postId,
              updatedAt: updatedAt,
              postDate: variables.postDate || format(new Date(), 'YYYY-MM-DD'),
              postTime: variables.postTime || format(new Date(), 'HH:mm:ss'),
              timezone: selectedServiceTimeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          }).then(() => {
            if (refetchNeedsReviewCount) refetchNeedsReviewCount()
          })

          if (statusToSave === 'PUBLISHED') {
            await publishPost({
              variables: {
                ...variables,
                id: postId,
              },
            })
          }

          await releasePostLock({
            awaitRefetchQueries: true,
            variables: {
              id: postId,
            },
          })

          if (statusToSave === 'DRAFT') {
            generateToastMessage(`draft${postId}`, {
              message: "Post 'Saved as Draft' successfully",
            })
          }

          refreshPostList && (await refreshPostList())

          setLoadingFalse()
          handleCloseModalOnSubmit()
          ObservationAnalytics.createObservation()
          onSave()

          // refresh the page if user is on /observations/ page and refreshPostList is not available
          const isPostsPage = location.pathname === '/observations/' || location.pathname === '/observations'
          if (isPostsPage && !refreshPostList) window.location.reload()
        } catch (err) {
          console.log('error', err)
          setLoadingFalse()
        }
      },
      [
        postId,
        updatedAt,
        refreshPostList,
        updateDraftPost,
        updatePublishedPost,
        releasePostLock,
        publishPost,
        handleCloseModalOnSubmit,
        onSave,
        location.pathname,
        refetchNeedsReviewCount,
        selectedServiceTimeZone,
      ]
    )

    const handleAutoSave = useCallback(
      async (variables: any) => {
        try {
          await updateDraftPost({
            variables: {
              ...variables,
              id: postId,
              updatedAt: updatedAt,
              title: variables.title || 'Title',
              description: variables.description || 'Description',
              childFkeys: variables.childFkeys || [],
              postDate: variables.postDate || format(new Date(), 'YYYY-MM-DD'),
              postTime: variables.postTime || format(new Date(), 'HH:mm:ss'),
              timezone: selectedServiceTimeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
              serviceFkey: serviceFkey,
            },
          })

          getPostUpdatedAt({ variables: { id: Number(postId), serviceFkey } })
        } catch (err) {
          console.log('error', err)
        }
      },
      [updatedAt, serviceFkey, postId, updateDraftPost, getPostUpdatedAt, selectedServiceTimeZone]
    )

    return (
      <Modal open onClose={handleCloseModal} dontRenderCloseButton>
        <AddNewPostModalLoader
          createPostId={postId}
          handleCloseModal={handleCloseModal}
          {...observation}
          availableRooms={availableRooms}
          isCreating={true}
          learningFrameworks={learningFrameworks}
          onSubmit={handleOnSubmit}
          onAutoSave={handleAutoSave}
        />
      </Modal>
    )
  }
)

Create.displayName = 'Create'

const ServiceDetailsLoader = ({
  observation,
  handleCloseModal,
  handleCloseModalOnSubmit,
  setPostIdForDeletion,
  ...props
}: LoaderProps) => {
  const serviceFkey = useSelectedServiceFkey()
  const { data, error, loading } = useQuery(GET_SERVICE_DETAILS, {
    variables: { serviceFkey },
  })

  if (error) {
    throw Error()
  }

  if (loading) {
    return (
      <Modal open={loading} onClose={handleCloseModal} dontRenderCloseButton>
        <LoadingPostModal />
      </Modal>
    )
  }

  const learningFrameworks = data?.service?.learningFrameworks
  const availableRooms = data?.service?.rooms

  return (
    <Create
      setPostIdForDeletion={setPostIdForDeletion}
      availableRooms={availableRooms}
      learningFrameworks={learningFrameworks}
      observation={observation}
      handleCloseModal={handleCloseModal}
      handleCloseModalOnSubmit={handleCloseModalOnSubmit}
      {...props}
    />
  )
}

ServiceDetailsLoader.displayName = 'ServiceDetailsLoader'

export default React.memo(ServiceDetailsLoader)
