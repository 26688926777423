import React from 'react'

const DashboardNav = () => (
  <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.87167 16.25L10.18 12.9417C10.2972 12.8244 10.4563 12.7585 10.6221 12.7585C10.7879 12.7585 10.9469 12.8244 11.0642 12.9417L12.68 14.5575C12.7972 14.6748 12.9563 14.7407 13.1221 14.7407C13.2879 14.7407 13.4469 14.6748 13.5642 14.5575L16.8717 11.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.621667 2.125C0.621667 1.29657 1.29324 0.625 2.12167 0.625H17.8717C18.7001 0.625 19.3717 1.29657 19.3717 2.125V17.875C19.3717 18.7034 18.7001 19.375 17.8717 19.375H2.12167C1.29324 19.375 0.621667 18.7034 0.621667 17.875V2.125Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.87167 10.625C8.94273 10.625 10.6217 8.94607 10.6217 6.875C10.6217 4.80393 8.94273 3.125 6.87167 3.125C4.8006 3.125 3.12167 4.80393 3.12167 6.875C3.12167 8.94607 4.8006 10.625 6.87167 10.625Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.87167 3.125V6.875L9.52333 9.52667"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7467 4.375H16.2467"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7467 6.875H16.2467"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default DashboardNav
