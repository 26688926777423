import gql from 'graphql-tag'

export const SEARCH_QUERY = gql`
  query Search($cursor: String, $serviceFkey: String!, $query: String!, $first: Int!, $types: [String]) {
    context(fkey: $serviceFkey) {
      fkey
      documents(first: $first, query: $query, after: $cursor, types: $types) {
        edges {
          node {
            author
            documentDate
            id
            insertedAt
            status
            title
            coverImage
            type
            typeId
            updatedAt
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      taggableEntities(query: $query) {
        displayText
        typeFkey
        imageUrl
        type
      }
    }
  }
`
