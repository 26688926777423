import React from 'react'

const Tick = () => (
  <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1705 1.25752C18.3815 1.46879 18.5 1.7552 18.5 2.05381C18.5 2.35242 18.3815 2.63881 18.1705 2.8501L7.2779 13.7427C7.06662 13.9537 6.78022 14.0722 6.48162 14.0722C6.18301 14.0722 5.8966 13.9537 5.68533 13.7427L0.802433 8.85981C0.603392 8.6462 0.495036 8.36368 0.500175 8.07175C0.505328 7.77982 0.623584 7.5013 0.830048 7.29484C1.0365 7.08839 1.31503 6.97013 1.60695 6.96498C1.89888 6.95983 2.18139 7.0682 2.39501 7.26724L6.48162 11.3538L16.5779 1.25752C16.7892 1.0465 17.0755 0.927979 17.3742 0.927979C17.6728 0.927979 17.9593 1.0465 18.1705 1.25752Z"
      fill="currentColor"
    />
  </svg>
)

export default Tick
