const palette = {
  hullOrange0: '#FEF2E0',
  hullOrange1: '#FCDEB1',
  hullOrange2: '#FAC87F',
  hullOrange3: '#F8B24C',
  hullOrange4: '#F7A126',
  hullOrange5: '#F69202',
  hullOrange6: '#F28602',
  hullOrange7: '#EC7702',
  hullOrange8: '#E66702',
  hullOrange9: '#DC4C02',
}

export default {
  ...palette,
  hullOrange: palette.hullOrange6,
}
