import React, { useContext, useState } from 'react'

import distanceInWords from 'date-fns/distance_in_words'
import * as R from 'ramda'
import { Absolute, Avatar, Box, Flex, Icon, Relative, Text } from 'stardust'
import styled from 'styled-components'

import { UserContext } from '~/contexts/User'
import { initials } from '~/utils'

const CommentText = styled(Text)`
  word-break: break-word;

  p {
    margin: 0;
    margin-bottom: 1em;
  }

  p:last-child {
    margin-bottom: 0;
  }
`
const DeleteButton = styled(Box)`
  transition: opacity 0.5s;
`
const S = {
  Box: styled(Box)`
    @media print {
      div {
        page-break-inside: avoid;
      }
    }
  `,
}

const postedAt = R.ifElse(R.isNil, R.always(null), (d) => `${distanceInWords(d, new Date())} ago`)

interface Props {
  comment: Playground.Comment
  onDeleteComment?: (id: number) => void
  xxsmall?: boolean
  xsmall?: boolean
  small?: boolean
  large?: boolean
}

const Comment = ({ comment, onDeleteComment, large, small, xsmall, xxsmall, ...props }: Props) => {
  const [deleteButton, setDeleteButton] = useState(false)
  const author = comment?.author
  const background = author?.type === 'parent' ? 'hullOrange0' : 'nebulaBlue0'
  const border = author?.type === 'parent' ? 'hullOrange' : 'nebulaBlue'
  const context = author?.type === 'parent' ? 'Parent' : 'Educator'
  const textBlocks = comment?.content.split(/[\n\r]+/g)

  const user = useContext(UserContext)
  const userRef = user.state?.reference

  const isUserAuthor = userRef === author?.userRef

  return (
    <S.Box {...props}>
      <Flex alignItems="center" justifyContent="flex-start" mb={1}>
        <Avatar
          alt={R.prop('name', author)}
          context={context}
          large={large}
          small={small}
          xsmall={xsmall}
          xxsmall={xxsmall}
          src={R.prop('image', author)}
          text={initials(R.propOr('', 'name', author))}
        />
        <Flex alignItems="flex-end" ml={2}>
          <Text bold fontSize={1}>
            {R.prop('name', author)}
          </Text>
          <Text bold fontSize={1} mx={1}>
            &bull;
          </Text>
          <Text color="textPrimaryMedium" fontSize={0}>
            {postedAt(comment.insertedAt)}
          </Text>
        </Flex>
      </Flex>

      <Relative
        bg={background}
        border="1px solid"
        borderColor={border}
        borderRadius="16px"
        onMouseEnter={() => setDeleteButton(true)}
        onMouseLeave={() => setDeleteButton(false)}>
        {isUserAuthor && (
          <Absolute top={-12} right={1}>
            <DeleteButton
              bg="negative"
              borderRadius={3}
              boxShadow="2dp"
              cursor="pointer"
              opacity={deleteButton ? 1 : 0}
              onClick={onDeleteComment ? () => onDeleteComment(comment.id) : null}>
              <Icon name="close" fill="white" />
            </DeleteButton>
          </Absolute>
        )}
        <CommentText p={3}>
          {textBlocks.map((text, index) => (
            <Text.p key={index} lineHeight={1.4}>
              {text}
            </Text.p>
          ))}
        </CommentText>
      </Relative>
    </S.Box>
  )
}

Comment.displayName = 'Comment'

export default Comment
