import React from 'react'

// Same as AddCircle but "fill=none" and "fill=currentColor" removed so we can grey it out
const AddCircleGreyable = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1.66669C5.40002 1.66669 1.66669 5.40002 1.66669 10C1.66669 14.6 5.40002 18.3334 10 18.3334C14.6 18.3334 18.3334 14.6 18.3334 10C18.3334 5.40002 14.6 1.66669 10 1.66669ZM10.8334 9.16669V5.83336H9.16669V9.16669H5.83335V10.8334H9.16669V14.1667H10.8334V10.8334H14.1667V9.16669H10.8334ZM3.33335 10C3.33335 13.675 6.32502 16.6667 10 16.6667C13.675 16.6667 16.6667 13.675 16.6667 10C16.6667 6.32502 13.675 3.33336 10 3.33336C6.32502 3.33336 3.33335 6.32502 3.33335 10Z"
    />
  </svg>
)

export default AddCircleGreyable
