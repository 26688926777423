import gql from 'graphql-tag'

export default gql`
  fragment ObservationMedia on Observation {
    media {
      id
      coverUrl
      mimeType
      url
    }
  }
`
