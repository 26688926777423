import React from 'react'

import { Link, Match } from '@reach/router'

import { Box, Flex, Text } from 'stardust'
import styled from 'styled-components'

import CanRoute from '~/components/Authorisation/CanRoute'
import { isWebView } from '~/config'

const Tab = styled(Link)`
  align-items: center;
  display: flex;
  height: 100%;
  max-width: 152px;
  justify-content: center;
  position: relative;
  text-decoration: none;
  transition: background-color 0.2s;
  white-space: wrap;
  text-align: center;
  flex-grow: 1;
  padding: 0 8px;
  ${isWebView ? 'min-width: 152px;' : ''}
  &:hover {
    background-color: ${(props) => props.theme.colors.surfaceSecondary};
  }
`

const Indicator = styled(Box)`
  bottom: 0;
  position: absolute;
  transition: background-color 0.2s;
`

const StyledText = styled(Text)`
  font-weight: 500;
  transition: color 0.2s;
  line-height: 21px;
  @media print {
    display: none;
  }
`

export interface Tab {
  label: string
  key: string
  path: string
  permission: {
    action: string
    permissions?: string[]
    unpermittedUserOrigins?: Playground.UserOrigin[]
  }
}

interface Props {
  tabs: Tab[]
}

const Tabs = ({ tabs }: Props) => {
  return (
    <Flex
      alignItems="center"
      width="100%"
      height="72px"
      mx="auto"
      overflowX={isWebView ? 'auto' : 'unset'}
      data-test="tabs">
      {tabs.map(({ path, label, key, permission }) => (
        <CanRoute
          key={key}
          path={path}
          perform={permission.action}
          permissions={permission.permissions}
          unpermittedUserOrigins={permission.unpermittedUserOrigins}
          no={() => null}
          yes={({ path }: { path: string }) => (
            <Match path={path}>
              {(props) => (
                <Tab to={path} data-test={key}>
                  <StyledText caps color={props.match ? 'primary' : 'cosmicShade7'} fontSize={1}>
                    {label}
                  </StyledText>
                  <Indicator
                    bg={props.match ? 'primary' : 'transparent'}
                    borderRadius="2px"
                    height="2px"
                    width="100%"
                  />
                </Tab>
              )}
            </Match>
          )}
        />
      ))}
    </Flex>
  )
}

Tabs.displayName = 'Tabs'

export default React.memo(Tabs)
