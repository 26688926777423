import React from 'react'

import { Box, Flex, MainButton, SecondaryButton, Divider, Text } from 'stardust'
import styled from 'styled-components'

import colors from '~/ui-components/tokens/colors'

const S = {
  MainButton: styled(MainButton)`
    height: 48px;
    padding: 12px 24px 12px 24px;
  `,
  SecondaryButton: styled(SecondaryButton)`
    height: 48px;
    padding: 12px 24px 12px 24px;
  `,
  Bottom: styled(Flex)`
    background-color: white;
    z-index: 100;
    position: sticky;
    bottom: 0;
    overflow-y: 0;
  `,
}

interface Props {
  onCancelClick: () => void
  onConfirmClick: () => void
  totalTaggedDocs: number
}

const TaggedDocumentsBottomWeb = ({ onCancelClick, onConfirmClick, totalTaggedDocs }: Props) => {
  return (
    <S.Bottom pb={2} bg="white">
      <Box width="100%">
        <Divider borderColor="lightGrey" />
        <Flex justifyContent="right" mr={5} mt={3}>
          <Text color={colors.nebulaBlue4}>
            <strong>{`Total ${totalTaggedDocs} file selected`}</strong>
          </Text>
        </Flex>
        <Flex width="100%" alignItems="center" p={3}>
          <Box flex={1}>
            <S.SecondaryButton outline onClick={onCancelClick}>
              Cancel
            </S.SecondaryButton>
          </Box>

          <Box flex={1} ml={2}>
            <S.MainButton outline onClick={onConfirmClick}>
              Link
            </S.MainButton>
          </Box>
        </Flex>
      </Box>
    </S.Bottom>
  )
}

TaggedDocumentsBottomWeb.displayName = 'TaggedDocumentsBottomWeb'

export default TaggedDocumentsBottomWeb
