import React, { useState } from 'react'

import { Box, Flex, MainButton, SecondaryButton, Icon, Text, Divider, RadioButton } from 'stardust'

import styled from 'styled-components'

import Modal from '~/components/Modal'
import colors from '~/ui-components/tokens/colors'

import { sortObjectsBy } from '~/utils'

import { FieldProps } from '../../types'

const S = {
  MainButton: styled(MainButton)`
    height: 48px;
    padding: 12px 24px 12px 24px;
  `,
  SecondaryButton: styled(SecondaryButton)`
    height: 48px;
    padding: 12px 24px 12px 24px;
  `,
  Icon: styled(Icon)`
    border-radius: 50%;
    padding: 10px;
    background-color: ${colors.cosmicShade4};
    &:hover {
      background-color: ${colors.cosmicShade6};
      transition: background-color 0.2s;
    }
  `,
  RoomIcon: styled(Icon)`
    border-radius: 50%;
    padding: 10px;
    background-color: 'avatarBackground';
  `,
  Top: styled(Flex)`
    background-color: white;
    position: sticky;
    z-index: 100;
    top: 0;
    overflow-y: hidden;
  `,
  Bottom: styled(Flex)`
    background-color: white;
    position: sticky;
    bottom: 0;
    overflow-y: 0;
  `,
}

interface RoomsProps extends FieldProps {
  onCloseRoomModal: () => void
  availableRooms: Playground.Room[]
  rooms: Playground.ObservationRoom[]
}

type RoomArray = [{ roomFkey: string }]

const Rooms = ({ onCloseRoomModal, availableRooms = [], rooms, onChange }: RoomsProps) => {
  const initialSelectedRoom = (): RoomArray | undefined => {
    if (rooms.length === 0) return undefined
    return [{ roomFkey: rooms[0].roomFkey }]
  }

  const [selectedOption, setSelectedOption] = useState<RoomArray | undefined>(initialSelectedRoom())

  const handleRadioButtonChange = (optionValue: string) => {
    setSelectedOption([{ roomFkey: optionValue }])
  }

  const handleDone = () => {
    onChange({
      name: 'rooms',
      value: selectedOption,
    })
    onCloseRoomModal()
  }

  const handleClear = () => {
    onChange({
      name: 'rooms',
      value: [],
    })
    onCloseRoomModal()
  }

  const roomOptions = sortObjectsBy(availableRooms, 'name').map(({ name, fkey }: Playground.Room) => ({
    value: fkey,
    name: name,
  }))

  const modalHeight = roomOptions.length < 10 ? `calc(${roomOptions.length * 8}vh + 80px)` : 80

  return (
    <Modal open dontRenderCloseButton borderRadius="16px" onClose={onCloseRoomModal}>
      <Box height={`${modalHeight}vh`} width="40vw">
        <Flex width="40vw" flexDirection="column">
          <S.Top>
            <Flex justifyContent="space-between" alignItems="center" width="100%" m={3}>
              <Flex alignItems="center">
                <S.RoomIcon
                  size={40}
                  name="selectRoom"
                  bg="avatarBackground"
                  fill="textPrimaryMedium"
                  data-test="sidebar-close"
                />
                <Text ml={2} fontSize={3} fontWeight="bold">
                  Select Room
                </Text>
              </Flex>
              <S.Icon size={40} name="close" onClick={onCloseRoomModal} />
            </Flex>
          </S.Top>

          <Box>
            {roomOptions.map((room, index) => (
              <Box key={room.value}>
                {index === 0 ? null : <Divider mx={3} borderColor={colors.cosmicShade14} />}
                <Box ml={3}>
                  <RadioButton
                    checked={room.value === (selectedOption && selectedOption[0].roomFkey)}
                    label={room.name}
                    onChange={() => handleRadioButtonChange(room.value)}
                  />
                </Box>
                {index === roomOptions.length - 1 ? (
                  <Divider borderColor={colors.cosmicShade14} />
                ) : (
                  <Divider mx={3} borderColor={colors.cosmicShade14} />
                )}
              </Box>
            ))}
          </Box>
          <S.Bottom>
            <Divider borderColor={colors.cosmicShade14} />
            <Flex width="100%" alignItems="center" p={3}>
              <Box flex={1}>
                <S.SecondaryButton outline onClick={handleClear}>
                  Clear
                </S.SecondaryButton>
              </Box>
              <Box flex={1} ml={2}>
                <S.MainButton outline onClick={handleDone}>
                  Done
                </S.MainButton>
              </Box>
            </Flex>
          </S.Bottom>
        </Flex>
      </Box>
    </Modal>
  )
}

Rooms.displayName = 'Rooms'
export default React.memo(Rooms)
