import React from 'react'

const Paperclip = () => (
  <svg viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M14.7421 6.99993V17.3989C14.7421 19.0689 13.6699 20.5889 12.1162 20.9239C9.98039 21.3849 8.09432 19.6699 8.09432 17.4999V5.11293C8.09432 4.11693 8.7382 3.19093 9.67175 3.02793C10.8608 2.82093 11.8931 3.78593 11.8931 4.99993V15.4999C11.8931 15.7759 11.6794 15.9999 11.4182 15.9999C11.1571 15.9999 10.9434 15.7759 10.9434 15.4999V6.99993C10.9434 6.44793 10.5179 5.99993 9.99369 5.99993C9.46946 5.99993 9.044 6.44793 9.044 6.99993V15.3529C9.044 16.6609 9.93576 17.8549 11.1713 17.9869C12.5911 18.1389 13.7924 16.9639 13.7924 15.4999V5.17793C13.7924 3.08993 12.3461 1.22193 10.3726 1.01993C8.10761 0.787931 6.19495 2.66293 6.19495 4.99993V17.2549C6.19495 20.1259 8.18264 22.6939 10.8949 22.9729C14.009 23.2939 16.6415 20.7139 16.6415 17.4999V6.99993C16.6415 6.44793 16.216 5.99993 15.6918 5.99993C15.1676 5.99993 14.7421 6.44793 14.7421 6.99993Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16.209" height="24" fill="white" transform="translate(0.384155)" />
      </clipPath>
    </defs>
  </svg>
)

export default Paperclip
