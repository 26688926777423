import React from 'react'

const NoPhotos = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.62631 5.07414C8.5966 4.7996 8.79508 4.55295 9.06961 4.52324C11.2659 4.28556 13.2391 5.87334 13.4768 8.06965C13.5065 8.34419 13.308 8.59084 13.0335 8.62055C12.7589 8.65026 12.5123 8.45179 12.4826 8.17725C12.3043 6.53001 10.8244 5.33917 9.17721 5.51744C8.90267 5.54715 8.65602 5.34868 8.62631 5.07414ZM6.20771 7.20866C6.47646 7.27214 6.64287 7.54146 6.57939 7.81021C6.50329 8.13241 6.47958 8.47331 6.5174 8.8228C6.69566 10.47 8.17553 11.6609 9.82276 11.4826C10.0973 11.4529 10.3439 11.6514 10.3737 11.9259C10.4034 12.2004 10.2049 12.4471 9.93035 12.4768C7.73404 12.7145 5.76089 11.1267 5.5232 8.93039C5.473 8.46648 5.50433 8.01148 5.60617 7.58034C5.66964 7.31159 5.93896 7.14519 6.20771 7.20866Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.618 1.5C7.04981 1.49989 6.53032 1.82082 6.27615 2.329L7.618 1.5ZM11.382 1.5H7.618H11.382ZM11.382 1.5C11.9502 1.49989 12.4697 1.82082 12.7239 2.329L13.3091 3.5H14.5C15.3284 3.5 16 4.17157 16 5V11C16 11.2761 15.7761 11.5 15.5 11.5C15.2239 11.5 15 11.2761 15 11V5C15 4.72386 14.7761 4.5 14.5 4.5H13C12.8106 4.5 12.6374 4.39296 12.5527 4.22352L11.8295 2.77633C11.7447 2.60701 11.5715 2.49994 11.3822 2.5H7.618C7.42858 2.49994 7.25525 2.60693 7.17052 2.77633C7.04699 3.02331 6.74664 3.12338 6.49967 2.99985C6.25269 2.87632 6.15262 2.57597 6.27615 2.329M0 5C0 4.17157 0.671573 3.5 1.5 3.5H2.625C2.90114 3.5 3.125 3.72386 3.125 4C3.125 4.27614 2.90114 4.5 2.625 4.5H1.5C1.22386 4.5 1 4.72386 1 5V13C1 13.2761 1.22386 13.5 1.5 13.5H12C12.2761 13.5 12.5 13.7239 12.5 14C12.5 14.2761 12.2761 14.5 12 14.5H1.5C0.671573 14.5 0 13.8284 0 13V5Z"
      fill="currentColor"
    />
    <path
      d="M6.27613 2.329C6.53031 1.82082 7.04979 1.49989 7.61798 1.5H11.382L11.3821 2.5H7.61798C7.42857 2.49994 7.25523 2.60693 7.1705 2.77633C7.04697 3.02331 6.74662 3.12338 6.49965 2.99985C6.25268 2.87632 6.1526 2.57597 6.27613 2.329Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.75 5.5C2.33579 5.5 2 5.83579 2 6.25C2 6.66421 2.33579 7 2.75 7C3.16421 7 3.5 6.66421 3.5 6.25C3.5 5.83579 3.16421 5.5 2.75 5.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 2.5C1.77614 2.5 2 2.72386 2 3V4C2 4.27614 1.77614 4.5 1.5 4.5C1.22386 4.5 1 4.27614 1 4V3C1 2.72386 1.22386 2.5 1.5 2.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6465 14.3536C15.4512 14.5489 15.1346 14.5489 14.9394 14.3536L1.64646 1.06071C1.4512 0.865445 1.4512 0.548862 1.64646 0.3536C1.84172 0.158338 2.15831 0.158338 2.35357 0.3536L15.6465 13.6465C15.8417 13.8418 15.8417 14.1583 15.6465 14.3536Z"
      fill="currentColor"
    />
  </svg>
)

export default NoPhotos
