import React, { useEffect, useRef } from 'react'

import { Box, FlexItem, Text } from 'stardust'

import Comment from '~/components/Comment/Comment'

interface Props {
  comments: Playground.Comment[]
  onDeleteComment: (id: number) => void
}

const Comments = ({ comments, onDeleteComment }: Props) => {
  const containerRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    containerRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    })
  }, [comments])

  return (
    <FlexItem ref={containerRef} flexGrow={2} overflowY="auto" data-test="document-comments">
      <Text bold fontSize={2} mb={3}>
        Comments
      </Text>

      {comments.map((comment) => (
        <Box key={comment.id} mb={3}>
          <Comment comment={comment} onDeleteComment={onDeleteComment} xxsmall />
        </Box>
      ))}
    </FlexItem>
  )
}

Comments.displayName = 'DocumentRendererComments'

export default React.memo(Comments)
