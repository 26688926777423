import React from 'react'

import styled from 'styled-components'

import Box from './Box'
import Flex, { FlexProps } from './Flex'

import Circle from './StencilCircle'
import Line from './StencilLine'

const opacity = (props: Props) => (props.opacity ? { opacity: props.opacity } : null)

const OpaqueFlex = styled(Flex)`
  ${opacity};
`

interface Props extends FlexProps {
  opacity?: number
}

const StencilListItem = (props: Props) => (
  <OpaqueFlex flexDirection="column" alignItems="center" {...props}>
    <Box border={0} borderColor="rgb(245, 245, 245)" borderRadius="6px" width={1} p={3}>
      <Flex alignItems="center">
        <Circle width="80px" height="80px" />
        <Flex flexDirection="column" width={3 / 5}>
          <Line width={1 / 5} m={2} />
          <Line width={1 / 10} m={2} />
        </Flex>
      </Flex>
    </Box>
  </OpaqueFlex>
)

StencilListItem.displayName = 'StencilListItem'

export default React.memo(StencilListItem)
