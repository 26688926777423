import React from 'react'

import RComplement from 'ramda/src/complement'
import RIsNil from 'ramda/src/isNil'
import styled from 'styled-components'
import { SpaceProps, WidthProps, ZIndexProps, space, width, zIndex } from 'styled-system'

const isNotNil = RComplement(RIsNil)

type BaseProps = SpaceProps & WidthProps & ZIndexProps

export interface AbsoluteProps extends BaseProps {
  bottom?: string | number
  left?: string | number
  right?: string | number
  top?: string | number
}

const bottom = (props: AbsoluteProps) => (isNotNil(props.bottom) ? { bottom: props.bottom } : null)
const left = (props: AbsoluteProps) => (isNotNil(props.left) ? { left: props.left } : null)
const right = (props: AbsoluteProps) => (isNotNil(props.right) ? { right: props.right } : null)
const top = (props: AbsoluteProps) => (isNotNil(props.top) ? { top: props.top } : null)

const Absolute = styled.div<AbsoluteProps>`
  position: absolute;
  ${bottom};
  ${left};
  ${right};
  ${top};
  ${space};
  ${width};
  ${zIndex}
`

Absolute.displayName = 'Absolute'

export default React.memo(Absolute)
