import React from 'react'

const FrameworkCategory = () => {
  return (
    <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.75 3.125C0.75 1.67525 1.92525 0.5 3.375 0.5H7.75C8.57624 0.5 9.35426 0.88901 9.85 1.55L11.6875 4H22.625C24.0747 4 25.25 5.17525 25.25 6.625V18.875C25.25 20.3247 24.0747 21.5 22.625 21.5H3.375C1.92525 21.5 0.75 20.3247 0.75 18.875V3.125ZM3.375 2.25C2.89175 2.25 2.5 2.64175 2.5 3.125V18.875C2.5 19.3582 2.89175 19.75 3.375 19.75H22.625C23.1082 19.75 23.5 19.3582 23.5 18.875V6.625C23.5 6.14175 23.1082 5.75 22.625 5.75H11.25C10.9746 5.75 10.7152 5.62033 10.55 5.4L8.45 2.6C8.28475 2.37967 8.02541 2.25 7.75 2.25H3.375Z"
        fill="#00776B"
      />
    </svg>
  )
}

export default FrameworkCategory
