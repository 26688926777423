import { QueryResult, useQuery } from '@apollo/client'

import { CreateGridContext } from '~/components/Documents/GridProvider'

import { useSelectedServiceFkey } from '~/contexts/Service'
import usePermission from '~/hooks/usePermission'
import { observations as obs } from '~/modules/permissions'

import {
  MenuFactoryItems,
  buildMenu,
  useMenuItemBookmark,
  useMenuItemApprove,
  useMenuItemView,
  useMenuItemArchiveDuplicate,
} from '../../components/Documents/Menu'

import { GET_NEEDS_REVIEW_POSTS, GET_NEEDS_REVIEW_POSTS_COUNT } from './queries'
import { NEEDS_REVIEW_COUNT_MAX } from './useNeedsReviewDocumentsCount'
import { useObservation } from './useObservation'

export const useNeedsReviewDocuments: CreateGridContext = () => {
  const query = useObservation(GET_NEEDS_REVIEW_POSTS)
  const { refetch: refetchNeedsReviewCount } = useNeedsReviewDocumentsCountQuery(NEEDS_REVIEW_COUNT_MAX)

  const canWrite = usePermission(obs.write)
  const refetchQueries = () => {
    query.refetch()
    refetchNeedsReviewCount()
  }
  const menuItemView = useMenuItemView()
  const menuItemsArchiveDuplicate = useMenuItemArchiveDuplicate(refetchQueries)
  const menuItemApprove = useMenuItemApprove(refetchQueries)
  const menuItemsBookmark = useMenuItemBookmark(refetchQueries)
  const inputMenuFactoryItems = [menuItemView, menuItemsBookmark, menuItemsArchiveDuplicate, menuItemApprove]

  const menuItems: MenuFactoryItems = buildMenu(inputMenuFactoryItems, canWrite)

  return {
    query,
    menuItems,
    modalComponent: undefined,
    beforeComponent: undefined,
    beforeFirstGroup: undefined,
    refetchNeedsReviewCount,
    tabLocation: 'Needs Review',
  }
}

export const useNeedsReviewDocumentsCountQuery = (maxCount: number): QueryResult => {
  const serviceFkey = useSelectedServiceFkey()
  const query = useQuery(GET_NEEDS_REVIEW_POSTS_COUNT, {
    variables: { serviceFkey, first: maxCount },
  })

  return query
}
