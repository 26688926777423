import { useEffect, useState } from 'react'

import config from '~/config'
import { getAuthToken } from '~/modules/auth'
import { useFetch } from '~/utils'

function useStargate() {
  const [strategies, setStrategies] = useState<Nullable<Stargate.UserContext[]>>(null)

  const [{ data, error, loading }, doFetch] = useFetch()

  useEffect(() => {
    if (data) setStrategies(data.data.contexts as Stargate.UserContext[])
    if (error) setStrategies([])
  }, [data, error])

  useEffect(() => {
    const getStrategies = () => {
      doFetch({
        url: `${config.STARGATE_API}/api/v1/contexts`,
        options: {
          method: 'GET',
          headers: new Headers({
            Authorization: `Bearer ${getAuthToken()}`,
            'Content-Type': 'application/json',
          }),
        },
      })
    }

    getStrategies()
  }, [doFetch])

  return { loading: loading || strategies === null, strategies }
}

export default useStargate
