import React from 'react'

const Feedback = () => (
  <svg width="25" height="15" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5003 0.750963C8.46932 0.682963 4.30032 3.49996 1.67932 6.38496C1.1095 7.01757 1.1095 7.97836 1.67932 8.61096C4.24332 11.435 8.40032 14.317 12.5003 14.248C16.6003 14.317 20.7583 11.435 23.3243 8.61096C23.8941 7.97836 23.8941 7.01757 23.3243 6.38496C20.7003 3.49996 16.5313 0.682963 12.5003 0.750963Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.25 7.50002C16.2494 9.57096 14.5703 11.2494 12.4993 11.249C10.4284 11.2487 8.74982 9.56963 8.75 7.49869C8.75018 5.42775 10.4291 3.74902 12.5 3.74902C13.4948 3.74876 14.4489 4.1439 15.1523 4.84743C15.8556 5.55097 16.2505 6.50521 16.25 7.50002V7.50002Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Feedback
