import gql from 'graphql-tag'

export const CONSENTS: Record<string, Playground.Consent> = {
  activitiesAndExcursions: 'consents_activities_and_excursions',
  administerVentolin: 'consents_administer_ventolin',
  agreeToAccuratelyRecord: 'consents_agree_to_accurately_record',
  agreeToNotifyTheService: 'consents_agree_to_notify_the_service',
  excursions: 'consents_excursions',
  originalMedicationPackaging: 'consents_original_medication_packaging',
  permissionForEducators: 'consents_permission_for_educators',
  permissionForEducatorsAndTeachers: 'consents_permission_for_educators_and_teachers',
  photographVideos: 'consents_photograph_videos',
  sunscreen: 'consents_sunscreen',
  technology: 'consents_technology',
  toSeekMedicalTreatment: 'consents_to_seek_medical_treatment',
}

export const CHILD_INFO_FRAGMENT = gql`
  fragment ChildInfo on Child {
    about
    dob
    firstName
    fullName
    image
    lastName
  }
`

export const CHILD_METRICS_FRAGMENT = gql`
  fragment ChildMetrics on Child {
    dob
    metrics(dateRange: $dateRange) {
      observations
    }
  }
`

export function hasConsent(consent: Playground.Consent, child: Playground.SimpleChild) {
  const consents = child?.otherDetails?.consents || []
  return consents.includes(consent)
}
