import React from 'react'

import * as R from 'ramda'

import { Avatar, Box, Container, Divider, Flex, Interactive, Link, Text, hoverMixin } from 'stardust'

import styled from 'styled-components'

import { initials } from '~/components/Profiles/core'
import { resultLink, resultType } from '~/components/Search/core'

const S = {
  Interactive: styled(Interactive)`
    ${hoverMixin};
  `,
}

const ProfilesList = ({ profiles }) =>
  R.map((profile) => {
    const type = resultType(profile.type, profile.typeFkey)
    const link = resultLink(profile.type, profile.typeFkey)
    return (
      <Link key={profile.id} to={link} style={{ textDecoration: 'none' }} width="100%">
        <S.Interactive>
          <Container>
            <Flex px={5} w={1} py={3} alignItems="center">
              <Box pr={3}>
                <Avatar
                  alt={profile.displayText}
                  src={profile.imageUrl}
                  text={initials(profile.displayText)}
                  small
                />
              </Box>
              <Flex flexDirection="column" flex="1 1 auto" data-test="list-item">
                <Text.span caps fontSize={2} mb={1}>
                  {profile.displayText}
                </Text.span>
                <Text.span caps fontSize={1} medium>
                  {type}
                </Text.span>
              </Flex>
            </Flex>
            <Divider mx={5} my="0" borderColor="surfacePrimaryBorder" />
          </Container>
        </S.Interactive>
      </Link>
    )
  })(profiles)

ProfilesList.displayName = 'ProfilesList'

export default ProfilesList
