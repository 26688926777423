import React, { ReactNode } from 'react'

import { Editor as BaseEditor, Block, Change } from 'slate'
import {
  Editor as ReactEditor,
  getEventTransfer as ReactGetEventTransfer,
  RenderAttributes,
} from 'slate-react'
// ¯\_(ツ)_/¯ @types/slate(s) doesn't match up with our version of Slate/SlateReact apparently
export interface Editor extends BaseEditor {
  change: (callback: any) => void
}

// We have to extend slate's BaseEditor with the ReactEditor. See https://docs.slatejs.org/concepts/12-typescript
export interface SlateReactEditor extends ReactEditor {
  editor: BaseEditor
}

export interface ValueData extends Partial<Playground.ArticleContent> {
  redo: Change
  undo: Change

  children?: Playground.SimpleChild[]
  learningOutcomes?: Playground.LearningFrameworkOutcome[]
  rooms?: Playground.Room[]
}

export type Next = () => void

export type Query = {
  type: string
}

export interface OnDropProps {
  editor: Editor
}

export interface SerializeProps {
  type: string
  object: string
}

export interface SerializeMediaProps {
  media: Playground.MediaItem
  size: any
}

export interface RenderToolbarItemProps {
  editor: Nullable<BaseEditor>
  disabled?: boolean
}

export interface RenderNodeProps {
  attributes: RenderAttributes
  children: ReactNode
  node: Block
}

export interface GetEventTransfer extends ReturnType<typeof ReactGetEventTransfer> {
  text: string
}

// getEventTransfer: Extended the return type as slate-react@0.19.8 returns additional fields that are not covered in @types/slate-react@0.22
export function getEventTransfer(event: Event | React.SyntheticEvent): GetEventTransfer {
  return ReactGetEventTransfer(event) as GetEventTransfer
}

export type MentionTokens = '@' | '#'
