import React, { ReactNode } from 'react'

import { Avatar, Box, Container, Flex, Heading } from 'stardust'
import styled from 'styled-components'
import { display } from 'styled-system'

import * as profiles from '~/components/Profiles/core'

interface AvatarContainerProps {
  description?: string
  fullName: string
  image?: string
}

interface MetricsContainerProps {
  children: ReactNode
}

interface SummaryProps extends AvatarContainerProps {
  children: ReactNode
}

const S = {
  Box: styled(Box)`
    ${display};
  `,
  Flex: styled(Flex)`
    ${display};
  `,
}

const AvatarContainer = ({ image, description, fullName }: AvatarContainerProps) => (
  <>
    <S.Flex display={['none', 'inherit']} alignItems="center">
      <Avatar src={image} text={profiles.initials(fullName)} large />

      <Box width={1} ml={4}>
        <Heading.h2 bold={false} lineHeight={1} mb={0} mt={0} caps>
          {fullName}
        </Heading.h2>
        {description && (
          <Heading.h6 bold={false} lineHeight={1} my={3} caps>
            {description}
          </Heading.h6>
        )}
      </Box>
    </S.Flex>

    <S.Flex display={['inherit', 'none']} alignItems="center">
      <Avatar src={image} text={profiles.initials(fullName)} medium />

      <Box width={1} ml={3}>
        <Heading.h4 bold={false} mb={0} mt={1} caps>
          {fullName}
        </Heading.h4>
        {description && (
          <Heading.h6 bold={false} mb={0} mt={2} caps>
            {description}
          </Heading.h6>
        )}
      </Box>
    </S.Flex>
  </>
)

AvatarContainer.displayName = 'AvatarContainer'

const MetricsContainer = ({ children }: MetricsContainerProps) => (
  <>
    <S.Box display={['none', 'inherit']}>
      <Flex flexDirection="column" justifyContent="center" ml={4} width={1 / 2}>
        <Box width={1} ml={120} mb={3}>
          {children}
        </Box>
      </Flex>
    </S.Box>

    <S.Box display={['inherit', 'none']}>
      <Flex flexDirection="column" justifyContent="center" ml={3} width={1 / 2}>
        <Box width={1} mt={3} mb={3}>
          {children}
        </Box>
      </Flex>
    </S.Box>
  </>
)

MetricsContainer.displayName = 'MetricsContainer'

const Summary = ({ fullName, image, description, children }: SummaryProps) => (
  <Container px={4}>
    <Flex mt={2} width={1} flexDirection="column">
      <AvatarContainer description={description} fullName={fullName} image={image} />
      <MetricsContainer>{children}</MetricsContainer>
    </Flex>
  </Container>
)

Summary.displayName = 'Summary'

export default Summary
