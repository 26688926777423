import gql from 'graphql-tag'

export const CREATE_FOLDER = gql`
  mutation createDocumentFolder(
    $folderName: String!
    $type: String!
    $documents: [FolderDocument]!
    $serviceFkey: String!
  ) {
    createDocumentFolder(
      documentFolder: { name: $folderName, type: $type, documents: $documents, serviceFkey: $serviceFkey }
    ) {
      id
      name
      type
    }
  }
`

export const DUPLICATE_FOLDER = gql`
  mutation createDuplicateDocumentFolder($folderId: Int) {
    createDuplicateDocumentFolder(id: $folderId) {
      id
    }
  }
`

export const RENAME_FOLDER = gql`
  mutation renameDocumentFolder($folderId: Int!, $folderName: String!) {
    updateDocumentFolder(documentFolder: { id: $folderId, name: $folderName }) {
      id
      name
    }
  }
`

export const ARCHIVE_FOLDER = gql`
  mutation archiveDocumentFolder($folderId: Int) {
    archiveDocumentFolder(id: $folderId) {
      id
    }
  }
`

export const RESTORE_FOLDER = gql`
  mutation restoreDocumentFolder($folderId: Int) {
    restoreDocumentFolder(id: $folderId) {
      id
    }
  }
`

export const UPDATE_FOLDER_BOOKMARK = gql`
  mutation updateBookmarkDocumentFolder($folderId: Int) {
    updateBookmarkDocumentFolder(id: $folderId) {
      id
      bookmarked
    }
  }
`

export const ADD_DOCUMENT_TO_FOLDER = gql`
  mutation addDocumentToFolder($folderId: Int!, $document: FolderDocument!) {
    addDocumentToFolder(id: $folderId, document: $document) {
      id
      name
      type
    }
  }
`

export const REMOVE_DOCUMENT_FROM_FOLDER = gql`
  mutation removeDocumentFromFolder($folderId: Int!, $document: FolderDocument!) {
    removeDocumentFromFolder(id: $folderId, document: $document) {
      id
      name
    }
  }
`
