import React from 'react'

const ChildrenSimple = () => (
  <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 19.375C15.1777 19.375 19.375 15.1777 19.375 10C19.375 4.82233 15.1777 0.625 10 0.625C4.82233 0.625 0.625 4.82233 0.625 10C0.625 15.1777 4.82233 19.375 10 19.375Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.69498 11.875C4.4921 11.875 4.30186 11.9735 4.18474 12.1392C4.06761 12.3049 4.03819 12.5171 4.10582 12.7083C4.98736 15.2079 7.34956 16.8796 9.99998 16.8796C12.6504 16.8796 15.0126 15.2079 15.8941 12.7083C15.9618 12.5171 15.9324 12.3049 15.8152 12.1392C15.6981 11.9735 15.5079 11.875 15.305 11.875L4.69498 11.875Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.75 6.5625C13.9226 6.5625 14.0625 6.70241 14.0625 6.875C14.0625 7.04759 13.9226 7.1875 13.75 7.1875C13.5774 7.1875 13.4375 7.04759 13.4375 6.875C13.4375 6.70241 13.5774 6.5625 13.75 6.5625"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.25 6.5625C6.42259 6.5625 6.5625 6.70241 6.5625 6.875C6.5625 7.04759 6.42259 7.1875 6.25 7.1875C6.07741 7.1875 5.9375 7.04759 5.9375 6.875C5.9375 6.70241 6.07741 6.5625 6.25 6.5625"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ChildrenSimple
