import { useCallback, useEffect, useMemo, useState } from 'react'

function usePagination<T>(collection: T[], defaultPageSize = 10) {
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(defaultPageSize)

  const onPageSizeChange = useCallback((newPageSize: number) => {
    setPage(0)
    setPageSize(newPageSize)
  }, [])

  const pageCount = Math.ceil(collection.length / pageSize)

  const pagedData = useMemo(() => {
    return collection.slice(page * pageSize, (page + 1) * pageSize)
  }, [collection, page, pageSize])

  useEffect(() => setPage(0), [collection])

  return { page, pageCount, pageSize, pagedData, onPageChange: setPage, onPageSizeChange }
}

export default usePagination
