import React from 'react'

import DocumentModal from '~/components/DocumentModal'
import DocumentModalPlaceholder from '~/components/DocumentModalPlaceholder'

import MomentRenderer from './MomentRenderer'

interface MomentModalProps {
  id: Nullable<number>
  show: boolean
  onClose(): void
}

const MomentModal = ({ id, show, onClose }: MomentModalProps) => {
  if (!id || !show) {
    return null
  }

  return (
    <DocumentModal open onClose={onClose}>
      <MomentRenderer id={id} onLoadComponent={<DocumentModalPlaceholder onClose={onClose} />} />
    </DocumentModal>
  )
}

MomentModal.displayName = 'MomentModal'

export default React.memo(MomentModal)
