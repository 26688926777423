import gql from 'graphql-tag'

export const GET_LEARNING_TABLES = gql`
  query LearningTables($cursor: String, $serviceFkey: String, $folderId: Int) {
    context(fkey: $serviceFkey) {
      fkey
      learningTables(first: 20, after: $cursor, folderId: $folderId) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            commentCount
            hasUnpublishedChanges
            id
            isPublished
            status
            title
            updatedAt
            contributors {
              fullName
              action
            }
          }
        }
      }
    }
  }
`

export const GET_LEARNING_TABLES_DRAFT = gql`
  query LearningTablesDraft($cursor: String, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      learningTables(first: 20, after: $cursor, status: DRAFT_NOT_PUBLISHED) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            hasUnpublishedChanges
            isPublished
            status
            title
            updatedAt
            contributors {
              fullName
              action
            }
          }
        }
      }
    }
  }
`

export const GET_LEARNING_TABLES_PUBLISHED = gql`
  query LearningTablesPublished($cursor: String, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      learningTables(first: 20, after: $cursor, status: PUBLISHED) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            hasUnpublishedChanges
            isPublished
            status
            title
            updatedAt
            contributors {
              fullName
              action
            }
          }
        }
      }
    }
  }
`

export const GET_LEARNING_TABLES_ARCHIVED = gql`
  query LearningTablesArchived($cursor: String, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      learningTables(first: 20, after: $cursor, status: ARCHIVED) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            hasUnpublishedChanges
            isPublished
            status
            title
            updatedAt
            contributors {
              fullName
              action
            }
          }
        }
      }
    }
  }
`

export const GET_LEARNING_TABLE = gql`
  query LearningTable($id: Int!, $serviceFkey: String, $contentStatus: ArticleContentStatus) {
    context(fkey: $serviceFkey) {
      fkey
      learningTable(id: $id) {
        id
        serviceFkey
        hasUnpublishedChanges
        isPublished
        status
        updatedAt
        children {
          id
          fkey
          fullName
          image
        }
        rooms {
          id
          fkey
          name
          ageGroup
        }
        personnel {
          id
          fkey
          fullName
        }
        learningOutcomes {
          id
          name
        }
        content(status: $contentStatus) {
          id
          title
          data
          status
          updatedAt
          generatedWith
        }
        contributors {
          id
          fullName
          userId
          userType
          at
          action
        }
        insertedAt
      }
    }
  }
`

export const GET_TABLE_CANVAS = gql`
  query LearningTable($id: Int!, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      learningTable(id: $id) {
        id
        serviceFkey
        updatedAt
        children {
          id
          fkey
          fullName
          image
        }
        learningOutcomes {
          id
          name
        }
        content(status: PUBLISHED) {
          id
          title
        }
      }
    }
  }
`

export const GET_TABLE_CONTRIBUTORS = gql`
  query LearningTableContributors($id: Int!, $serviceFkey: String, $contentStatus: ArticleContentStatus) {
    context(fkey: $serviceFkey) {
      fkey
      learningTable(id: $id) {
        contributors {
          id
          action
          at
          fullName
          userRef
        }
        content(status: $contentStatus) {
          id
        }
      }
    }
  }
`

export const GET_LEARNING_TABLE_DETAIL = gql`
  query LearningTableDetail($id: Int!, $serviceFkey: String, $contentStatus: ArticleContentStatus) {
    context(fkey: $serviceFkey) {
      fkey
      learningTable(id: $id) {
        id
        serviceFkey
        hasUnpublishedChanges
        isPublished
        status
        insertedAt
        updatedAt
        children {
          id
          fkey
          fullName
          image
        }
        rooms {
          fkey
          name
          ageGroup
        }
        personnel {
          id
          fkey
          fullName
          image
        }
        learningOutcomes {
          id
          name
        }
        contributors {
          id
          fullName
          userId
          userType
          at
          action
        }
        content(status: $contentStatus) {
          data
          generatedWith
          id
          status
          title
          updatedAt
          taggedEntities {
            id
            displayText
            type
            typeFkey
          }
        }
      }
    }
  }
`

export const GET_LEARNING_TABLE_LAYOUT = gql`
  query LearningTableLayout($id: ID, $serviceFkey: String!) {
    service(fkey: $serviceFkey) {
      fkey
      template(id: $id) {
        id
        data
        generatedWith
      }
    }
  }
`

export const GET_LEARNING_TABLE_LAYOUTS = gql`
  query Layouts($serviceFkey: String!) {
    service(fkey: $serviceFkey) {
      fkey
      templates(type: LEARNING_TABLE) {
        id
        title
        data
        global
        providerFkey
      }
    }
  }
`

export const GET_LEARNING_TABLE_REFLECTIONS = gql`
  query Reflections($articleId: Int!, $serviceFkey: String!) {
    service(fkey: $serviceFkey) {
      fkey
      learningTable(id: $articleId) {
        id
        content(status: DRAFT) {
          id
          reflections {
            id
            content
            insertedAt
            author {
              id
              name
              type
            }
            replies {
              id
              content
              insertedAt
              author {
                id
                name
                type
              }
            }
          }
        }
      }
    }
  }
`
