import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import { useQuery } from '@apollo/client'

import RAscend from 'ramda/src/ascend'
import RDescend from 'ramda/src/descend'
import RHead from 'ramda/src/head'
import RJoin from 'ramda/src/join'
import RMap from 'ramda/src/map'
import RPipe from 'ramda/src/pipe'
import RProp from 'ramda/src/prop'
import RProps from 'ramda/src/props'
import RSortWith from 'ramda/src/sortWith'
import RSplit from 'ramda/src/split'
import RTrim from 'ramda/src/trim'

import { Anchor, Avatar, Box, Flex, Heading, Interactive, SearchField, Text, hoverMixin } from 'stardust'
import styled from 'styled-components'

import Loading from '~/components/Loading/Selector'
import { activeServices } from '~/components/Profiles/core'
import { ServiceContext, useSelectedServiceFkey } from '~/contexts/Service'
import { GET_USER_SERVICES } from '~/modules/users'

import { filterBy, stringLikeness } from '~/utils'

const InteractiveHover = styled(Interactive)`
  ${hoverMixin};
  background-color: ${(props: any) => (props.selected ? props.theme.colors.primaryLight : 'initial')};
`

const initial = RPipe(RSplit('-'), RMap(RPipe(RTrim as any, RHead)), RJoin(''))
const initials = RPipe(RSplit(' '), RMap(initial), RJoin(''))

const filterByServiceNameOrId = filterBy(RPipe(RProps(['name', 'id']) as any, RJoin(' ')))

interface LoaderProps {
  enableSearch: boolean
}
interface Props {
  enableSearch: boolean
  initialServices: Playground.SimpleService[]
}

const Loader = ({ enableSearch }: LoaderProps) => {
  const { data, loading, error } = useQuery(GET_USER_SERVICES)

  if (!data || loading || error) {
    return null
  }

  const services: Playground.SimpleService[] = data?.user?.services || []

  return <ServiceSelector enableSearch={enableSearch} initialServices={services} />
}

const ServiceSelector = ({ enableSearch, initialServices }: Props) => {
  const debounceRef = useRef<any>(null)
  const [query, setQuery] = useState('')
  const [search, setSearch] = useState('')

  const { data, loading } = useQuery(GET_USER_SERVICES, {
    skip: !enableSearch || query.length === 0,
    variables: { search },
  })

  const {
    selectService,
    setServices,
    state: { services: storedServices },
  } = useContext(ServiceContext)
  const serviceFkey = useSelectedServiceFkey()

  const searchedServices = useMemo(
    () => activeServices(data?.user?.services || initialServices),
    [data, initialServices]
  )

  const services = useMemo(() => {
    const contextualServices = enableSearch ? searchedServices : storedServices
    const filteredServices = enableSearch
      ? contextualServices
      : filterByServiceNameOrId(query, contextualServices)

    const sorter = enableSearch
      ? RDescend((x: Playground.SimpleService) => stringLikeness(query, x.name))
      : RAscend(RProp('name'))

    return RSortWith([sorter])(filteredServices) as Playground.SimpleService[]
  }, [enableSearch, query, searchedServices, storedServices])

  const noResults = enableSearch
    ? query === search && !loading && data?.user?.services && services.length === 0
    : services.length === 0

  // Updates the query with a debounce to prevent spamming the server while typing
  const onQueryChange = (event: any) => {
    const value = event.target.value

    setQuery(value)

    clearTimeout(debounceRef.current)
    debounceRef.current = setTimeout(() => setSearch(value), 500)
  }

  const addSearchedServicesToContext = (searchedServices: Playground.SimpleService[]) => {
    const storedServiceFkeys = storedServices.map((service) => service.fkey)

    const combinedServices = searchedServices
      .filter((service) => !storedServiceFkeys.includes(service.fkey))
      .concat(storedServices)

    setServices(combinedServices)
  }

  const onSelect = (serviceFkey: string) => {
    if (enableSearch) addSearchedServicesToContext(searchedServices)
    selectService(serviceFkey)
  }

  // Clear debounce timer on unmount
  useEffect(() => () => clearTimeout(debounceRef.current), [])

  return (
    <>
      <Heading.h4 lineHeight={1} mb={0} mt={0} pt={3} px={3}>
        {enableSearch ? 'Find a service' : 'Choose service'}
      </Heading.h4>
      <Box borderBottom="1px solid" borderColor="surfacePrimaryBorder" p={3}>
        <SearchField
          label={enableSearch ? 'Type to search services' : 'Filter services'}
          name="query"
          value={query}
          onChange={onQueryChange}
        />
      </Box>
      <Box height={enableSearch ? '400px' : 'auto'} maxHeight="400px" overflowY="auto" pb={3}>
        {loading ? (
          <>
            <Loading />
            <Loading />
            <Loading />
          </>
        ) : (
          <>
            <Flex flexDirection="column">
              {services.map((service) => (
                <InteractiveHover
                  key={service.fkey}
                  selected={serviceFkey === service.fkey}
                  onClick={() => onSelect(service.fkey!)}>
                  <Flex w={1} p={2} px={3} alignItems="center">
                    <Avatar small src={null} text={initials(service.name)} />
                    <Box pl={3}>
                      <Text.span>{service.name}</Text.span>
                    </Box>
                  </Flex>
                </InteractiveHover>
              ))}

              {enableSearch && query.length === 0 && (
                <Flex alignItems="center" flexDirection="column-reverse" p={3}>
                  <Text.span medium>{"Can't see your centre? Try using the search bar above"}</Text.span>
                </Flex>
              )}
            </Flex>
          </>
        )}

        {noResults && (
          <Flex alignItems="center" flexDirection="column" height="100%" p={3}>
            <Text.p lineHeight="1.4" mb={2} mt={0}>
              No results for <Text.span bold>{`"${query}"`}</Text.span>
            </Text.p>
            <Text.p lineHeight="1.4" mb={0} mt={0}>
              <Anchor onClick={() => setQuery('')}>Clear your search</Anchor>
              <Text.span medium>&nbsp;to see all results or, try another search.</Text.span>
            </Text.p>
          </Flex>
        )}
      </Box>
    </>
  )
}

ServiceSelector.displayName = 'ServiceSelector'

export default React.memo(Loader)
