import React, { useEffect } from 'react'

import { Box, Heading } from 'stardust'

import Modal from '~/components/Modal'
import { sendAppSignalError } from '~/logging'

const ErrorModal = ({ errors, onClose }) => {
  useEffect(() => {
    if (errors) {
      sendAppSignalError(errors)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal open={!!errors} onClose={onClose}>
      <Box p={4}>
        <Heading.h4 mt={0}>Something went wrong</Heading.h4>
        {errors && errors.graphQLErrors.map(({ message }, i) => <p key={i}>{message}</p>)}
      </Box>
    </Modal>
  )
}

ErrorModal.displayName = 'ErrorModal'

export default ErrorModal
