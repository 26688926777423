import React from 'react'

import format from 'date-fns/format'

import { Box, Text } from 'stardust'

interface Props {
  futurePlanning: string
  planningDate?: string
}

const FuturePlanning = ({ futurePlanning, planningDate }: Props) => (
  <Box data-test="document-future-planning">
    <Text bold mb={3} fontSize={2}>
      {planningDate ? `Future Planning - ${format(planningDate, 'dddd, D MMMM')}` : 'Future Planning'}
    </Text>
    <Text fontSize={2} medium>
      {futurePlanning ? futurePlanning : 'There are currently no future plans'}
    </Text>
  </Box>
)

FuturePlanning.displayName = 'DocumentRendererFuturePlanning'

export default React.memo(FuturePlanning)
