import React, { DragEvent } from 'react'

import RPath from 'ramda/src/path'
import { Block, Change } from 'slate'
import { getEventRange } from 'slate-react'

import TableCard from '~/components/TableCard'

import { Next, OnDropProps, RenderNodeProps, SerializeProps } from '../types'

export default {
  serialize: (obj: SerializeProps) => {
    if (obj.object === 'block' && obj.type === 'table') {
      return <p>Table needs more finnese</p>
    }
  },

  onDrop: (e: DragEvent, { editor }: OnDropProps, next: Next) => {
    const droppedData = e.dataTransfer.getData('data')
    const parsedData = JSON.parse(droppedData)
    if (!droppedData || parsedData.type !== 'table') {
      return next()
    }

    const range = getEventRange(e, editor)
    if (!range) return next()

    return editor.change((change: Change) =>
      change.insertBlockAtRange(
        range,
        Block.create({
          type: 'table',
          data: parsedData,
        })
      )
    )
  },

  renderNode: ({ attributes, node }: RenderNodeProps, next: Next) => {
    if (node.type !== 'table') return next()

    const data = node.data.toJS()
    const tableId = RPath<number>(['data', 'typeId'], data)
    if (!tableId) return next()

    return <TableCard {...attributes} id={tableId} />
  },
}
