import React from 'react'

import { useQuery } from '@apollo/client'

import ContentPlaceholder from '~/components/ContentPlaceholder'
import DocumentPreviewCard from '~/components/DocumentPreviewCard'
import ObservationWebViewCard from '~/components/ObservationWebViewCard'
import { isWebView } from '~/config'

import { useSelectedServiceFkey } from '~/contexts/Service'
import { is404 } from '~/modules/api'
import { GET_POST, GET_POST_TEXT } from '~/pages/Observations/queries'

interface LoaderProps {
  id: number
}
interface Props {
  post: Playground.Post
}

const ObservationPreviewCard = React.memo(({ post }: Props) => {
  return (
    <>
      {isWebView ? (
        <ObservationWebViewCard post={post} />
      ) : (
        <DocumentPreviewCard
          description={post?.description}
          title={post?.title}
          onOpen={() => window.open(`/observations/${post.id}`)}
        />
      )}
    </>
  )
})

const ObservationLoader = ({ id }: LoaderProps) => {
  const serviceFkey = useSelectedServiceFkey()

  const query = isWebView ? GET_POST : GET_POST_TEXT

  const { data, error, loading } = useQuery(query, {
    variables: { id, serviceFkey },
    skip: !id,
  })

  if (error) {
    // 404 is expected behaviour if we're embedding an observation, but the
    // parent doesn't have a child tagged in it
    if (is404(error)) {
      return (
        <DocumentPreviewCard
          title="Post"
          description="You do not have permission to view this content. Your child is not tagged in the post."
        />
      )
    }

    throw error
  }

  const post = data?.context?.post as Playground.Post

  if (loading || !post) return <ContentPlaceholder />
  if (post.status === 'DELETED') return null

  return <ObservationPreviewCard post={post} />
}

ObservationPreviewCard.displayName = 'ObservationPreviewCard'
ObservationLoader.displayName = 'ObservationLoader'

export default React.memo(ObservationLoader)
