import React from 'react'

const LikesIcon = ({ fill = '#686868' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.92372 13.0721L7.95869 13.0968C7.97079 13.1053 7.98542 13.1101 8.00055 13.1105C8.01569 13.1101 8.03034 13.1053 8.04245 13.0968L8.04731 13.0934L8.06188 13.0827C9.04526 12.361 10.5389 11.2265 11.7826 9.87313C13.052 8.49188 13.9062 7.04755 13.9062 5.70326C13.9062 4.29628 12.6868 3.15603 11.1821 3.15603C10.5046 3.15603 9.94423 3.42975 9.52104 3.74822C9.31207 3.90549 9.15074 4.06357 9.04361 4.18023C8.99056 4.238 8.95228 4.28401 8.92951 4.31244C8.91816 4.32661 8.91079 4.33625 8.90749 4.34063L8.90835 4.33935L8.90664 4.34176C8.90589 4.34276 8.90552 4.34327 8.90552 4.34327L8.90749 4.34063C8.90717 4.34106 8.90688 4.34144 8.90664 4.34176L8 5.61336L7.09336 4.34176C7.09411 4.34276 7.09448 4.34327 7.09448 4.34327L7.09336 4.34176L7.09251 4.34063L7.09165 4.33935L7.09336 4.34176L7.09251 4.34063C7.08921 4.33625 7.08184 4.32661 7.07049 4.31244C7.04772 4.28401 7.00944 4.238 6.95639 4.18023C6.84926 4.06357 6.68793 3.90549 6.47896 3.74822C6.05577 3.42975 5.49539 3.15603 4.81787 3.15603C3.31318 3.15603 2.09375 4.29628 2.09375 5.70326C2.09375 7.04429 2.94344 8.48467 4.20771 9.86293C5.44663 11.2136 6.93632 12.3471 7.92238 13.0711L7.92372 13.0721ZM9.79557 13.0884C9.40415 13.3942 9.04328 13.6625 8.73714 13.8872L8.72575 13.8968L8.71549 13.9043L8.70237 13.9135C8.70218 13.9136 8.70145 13.913 8.70126 13.9132C8.70094 13.9134 8.70063 13.9136 8.70031 13.9138C8.49847 14.056 8.25271 14.133 8 14.1333C7.86551 14.133 7.73301 14.111 7.60807 14.069C7.49802 14.032 7.39383 13.9796 7.29932 13.9128L7.29818 13.9138C7.29141 13.9089 7.28335 13.9028 7.27527 13.8967C7.26732 13.8907 7.25936 13.8847 7.2526 13.8797L7.24691 13.8755C5.25774 12.415 1 9.13548 1 5.70326C1 3.73144 2.70912 2.1333 4.81787 2.1333C5.87383 2.1333 6.69681 2.58251 7.24107 3.01021C7.73558 3.39882 8 3.76966 8 3.76966C8 3.76966 8.26442 3.39882 8.75893 3.01021C9.30319 2.58251 10.1262 2.1333 11.1821 2.1333C13.2909 2.1333 15 3.73144 15 5.70326C15 8.61254 11.9396 11.4131 9.79557 13.0884Z"
      fill={fill}
    />
  </svg>
)

export default LikesIcon
