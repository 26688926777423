import React from 'react'

const EvacuationReport = () => (
  <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.5833,34.7834H9.4C8.7509,34.7858 8.1094,34.6441 7.5217,34.3684C6.9341,34.0927 6.415,33.6899 6.002,33.1892C5.589,32.6885 5.2923,32.1023 5.1335,31.4729C4.9746,30.8436 4.9575,30.1868 5.0833,29.55L7.6333,16.2167C7.8303,15.2121 8.3702,14.3072 9.1607,13.6566C9.9511,13.006 10.9429,12.6502 11.9667,12.65H26.9667C27.9455,12.6513 28.8958,12.9802 29.6661,13.5843C30.4363,14.1884 30.9822,15.033 31.2167,15.9834L34.8,29.3167C34.9578,29.9609 34.9684,30.6323 34.8312,31.2812C34.694,31.93 34.4124,32.5397 34.0073,33.0648C33.6022,33.59 33.0841,34.0171 32.4913,34.3147C31.8986,34.6122 31.2465,34.7724 30.5833,34.7834Z"
      fill="#FFB0B0"
      fillRule="evenodd"
    />
    <path
      d="M20,34.7834H9.4C8.7509,34.7858 8.1094,34.6441 7.5217,34.3684C6.9341,34.0927 6.415,33.6899 6.002,33.1892C5.589,32.6885 5.2923,32.1023 5.1335,31.4729C4.9746,30.8436 4.9575,30.1868 5.0833,29.55L7.6333,16.2167C7.8303,15.2121 8.3702,14.3072 9.1607,13.6566C9.9511,13.006 10.9429,12.6502 11.9667,12.65H19.6833"
      fill="#FF8080"
      fillRule="evenodd"
    />
    <path
      d="M27.0167,36.0333H9.4001C8.5736,36.0293 7.7579,35.8445 7.0104,35.4919C6.2629,35.1393 5.6015,34.6275 5.0727,33.9923C4.5439,33.3571 4.1605,32.6139 3.9493,31.8149C3.738,31.0158 3.7042,30.1802 3.85,29.3667L6.4001,16.0333C6.655,14.745 7.3495,13.5852 8.3649,12.7523C9.3802,11.9193 10.6534,11.4649 11.9667,11.4667H26.9667C28.2108,11.4672 29.4201,11.8771 30.4081,12.6332C31.396,13.3892 32.1077,14.4493 32.4334,15.65L35.5334,27.1833C35.6196,27.5038 35.5749,27.8454 35.4093,28.133C35.2436,28.4205 34.9705,28.6305 34.6501,28.7167C34.3296,28.8029 33.988,28.7582 33.7004,28.5926C33.4129,28.4269 33.2029,28.1538 33.1167,27.8333L30.0001,16.3C29.8226,15.6278 29.4266,15.0338 28.8744,14.6113C28.3222,14.1889 27.6453,13.9621 26.9501,13.9667H11.9501C11.2203,13.9688 10.5139,14.2242 9.9515,14.6893C9.3892,15.1543 9.0057,15.8003 8.8667,16.5167L6.3167,29.85C6.2236,30.3044 6.2337,30.7739 6.3462,31.2239C6.4587,31.6739 6.6708,32.0929 6.9667,32.45C7.2622,32.8095 7.6336,33.0991 8.0544,33.298C8.4751,33.4968 8.9347,33.6 9.4001,33.6H27.0167C27.3482,33.6 27.6662,33.7317 27.9006,33.9661C28.135,34.2005 28.2667,34.5185 28.2667,34.85C28.2667,35.1815 28.135,35.4995 27.9006,35.7339C27.6662,35.9683 27.3482,36.1 27.0167,36.1V36.0333Z"
      fill="#444B54"
    />
    <path
      d="M25.2999,36.0332H11.9666C10.8228,36.0312 9.6934,35.7771 8.659,35.289C7.6245,34.8009 6.7104,34.0908 5.9816,33.2092C5.2527,32.3276 4.7272,31.2962 4.4423,30.1885C4.1574,29.0807 4.1202,27.9237 4.3333,26.7999L5.9999,17.7666C6.3497,15.9814 7.3124,14.3746 8.7215,13.2242C10.1307,12.0738 11.8976,11.452 13.7166,11.4666H25.3833C27.0904,11.4722 28.7485,12.0377 30.1032,13.0764C31.458,14.115 32.4346,15.5695 32.8833,17.2166L35.7166,27.6999C35.7986,28.0202 35.7522,28.3599 35.5871,28.6464C35.4221,28.9329 35.1515,29.1435 34.8333,29.2332C34.5152,29.3092 34.18,29.2576 33.8996,29.0893C33.6192,28.9211 33.4159,28.6496 33.3333,28.3332L30.4999,17.8499C30.2032,16.745 29.5548,15.7667 28.6527,15.0631C27.7506,14.3594 26.6439,13.9687 25.4999,13.9499H13.8333C12.6079,13.947 11.4198,14.3715 10.4737,15.1503C9.5275,15.9291 8.8826,17.0134 8.65,18.2166L6.9833,27.2499C6.8328,28.0122 6.8534,28.7984 7.0436,29.5517C7.2338,30.3051 7.5889,31.0068 8.0831,31.6063C8.5774,32.2058 9.1986,32.6881 9.9019,33.0185C10.6051,33.3488 11.373,33.5189 12.1499,33.5166H25.4833C25.8148,33.5166 26.1327,33.6483 26.3672,33.8827C26.6016,34.1171 26.7333,34.435 26.7333,34.7666C26.7333,35.0981 26.6016,35.416 26.3672,35.6504C26.1327,35.8849 25.8148,36.0166 25.4833,36.0166L25.2999,36.0332Z"
      fill="#444B54"
    />
    <path
      d="M7.7333,10.6333C7.4026,10.6295 7.0861,10.4982 6.8499,10.2667L4.1166,7.55C3.9938,7.4356 3.8953,7.2976 3.827,7.1442C3.7586,6.9909 3.7219,6.8254 3.7189,6.6575C3.716,6.4897 3.7469,6.323 3.8097,6.1673C3.8726,6.0117 3.9662,5.8703 4.0849,5.7516C4.2036,5.6329 4.345,5.5393 4.5006,5.4765C4.6563,5.4136 4.823,5.3827 4.9908,5.3857C5.1586,5.3886 5.3242,5.4254 5.4775,5.4937C5.6308,5.562 5.7688,5.6605 5.8833,5.7833L8.6166,8.4833C8.7889,8.6603 8.9057,8.8838 8.9526,9.1264C8.9995,9.3689 8.9745,9.6198 8.8806,9.8483C8.7867,10.0768 8.6281,10.2728 8.4243,10.4123C8.2204,10.5518 7.9802,10.6286 7.7333,10.6333Z"
      fill="#FF8080"
    />
    <path
      d="M30.6001,10.6333C30.3531,10.6286 30.1129,10.5518 29.909,10.4123C29.7052,10.2728 29.5466,10.0768 29.4527,9.8483C29.3588,9.6198 29.3338,9.3689 29.3807,9.1264C29.4276,8.8838 29.5444,8.6603 29.7167,8.4833L32.4501,5.7833C32.5645,5.6605 32.7025,5.562 32.8558,5.4937C33.0092,5.4254 33.1747,5.3886 33.3425,5.3857C33.5104,5.3827 33.6771,5.4136 33.8327,5.4765C33.9884,5.5393 34.1298,5.6329 34.2485,5.7516C34.3672,5.8703 34.4607,6.0117 34.5236,6.1673C34.5865,6.323 34.6173,6.4897 34.6144,6.6575C34.6114,6.8254 34.5747,6.9909 34.5064,7.1442C34.438,7.2976 34.3395,7.4356 34.2167,7.55L31.4834,10.2667C31.2472,10.4982 30.9307,10.6295 30.6001,10.6333Z"
      fill="#FF8080"
    />
    <path
      d="M35.9833,31.6666H4.0166C2.7188,31.6666 1.6666,32.7188 1.6666,34.0166V35.9833C1.6666,37.2812 2.7188,38.3333 4.0166,38.3333H35.9833C37.2812,38.3333 38.3333,37.2812 38.3333,35.9833V34.0166C38.3333,32.7188 37.2812,31.6666 35.9833,31.6666Z"
      fill="#444B54"
    />
    <path
      d="M19.0166,8.3334C18.8547,8.3356 18.6938,8.3059 18.5434,8.2459C18.3929,8.186 18.2557,8.0969 18.1396,7.984C18.0235,7.871 17.9309,7.7362 17.8669,7.5874C17.8029,7.4386 17.7688,7.2787 17.7666,7.1167L17.6666,3.3334C17.6666,3.0018 17.7983,2.6839 18.0327,2.4495C18.2672,2.2151 18.5851,2.0834 18.9166,2.0834C19.2481,2.0834 19.5661,2.2151 19.8005,2.4495C20.0349,2.6839 20.1666,3.0018 20.1666,3.3334L20.2666,7.1C20.2711,7.2656 20.2425,7.4304 20.1828,7.5848C20.123,7.7392 20.0331,7.8802 19.9183,7.9996C19.8036,8.119 19.6663,8.2144 19.5143,8.2803C19.3624,8.3462 19.1989,8.3812 19.0333,8.3834L19.0166,8.3334Z"
      fill="#FF8080"
    />
    <path
      d="M11.6666,21.25C11.3364,21.2456 11.021,21.1126 10.7875,20.8791C10.554,20.6456 10.4209,20.3301 10.4166,20C10.4166,18.7844 10.8995,17.6186 11.7591,16.7591C12.6186,15.8995 13.7844,15.4166 15,15.4166C15.3315,15.4166 15.6494,15.5483 15.8838,15.7827C16.1183,16.0172 16.25,16.3351 16.25,16.6666C16.25,16.9981 16.1183,17.3161 15.8838,17.5505C15.6494,17.7849 15.3315,17.9166 15,17.9166C14.4474,17.9166 13.9175,18.1361 13.5268,18.5268C13.1361,18.9175 12.9166,19.4474 12.9166,20C12.9123,20.3301 12.7792,20.6456 12.5457,20.8791C12.3122,21.1126 11.9968,21.2456 11.6666,21.25Z"
      fill="#FFF3F3"
    />
  </svg>
)

export default EvacuationReport
