import * as R from 'ramda'

import toLowerAndTrim from './toLowerAndTrim'

export default (prop) => {
  const propFn = typeof prop === 'function' ? prop : R.prop(prop)

  return R.memoize(
    R.curry((query, data) =>
      R.filter(R.pipe(propFn, toLowerAndTrim, R.contains(toLowerAndTrim(query))))(data)
    )
  )
}
