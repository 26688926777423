import React from 'react'

import styled from 'styled-components'
import {
  BorderProps,
  BorderRadiusProps,
  ColorProps,
  SpaceProps,
  WidthProps,
  border,
  borderRadius,
  color,
  space,
  width,
} from 'styled-system'

type Props = BorderProps & BorderRadiusProps & ColorProps & SpaceProps & WidthProps

const StencilLine = styled.div<Props>`
  height: 8px;
  background-color: rgb(225, 225, 225);
  ${color};
  ${space};
  ${width};
  ${border};
  ${borderRadius};
`

StencilLine.defaultProps = {
  borderRadius: '32px',
}

StencilLine.displayName = 'StencilLine'

export default React.memo(StencilLine)
