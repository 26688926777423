/* eslint react/display-name: 0 */
import React, { DragEvent } from 'react'

import * as R from 'ramda'
import { Block, BlockProperties, Change, Document, Editor, Inline, NodeJSON, Text, Value } from 'slate'
import { RenderBlockProps, getEventRange } from 'slate-react'

import { Link } from 'stardust'

import ProfileChip from '~/components/Profiles/Chip'
import { isWebView } from '~/config'
import { toFkey } from '~/utils'

import { Next, OnDropProps, Query, ValueData } from '../../types'

import BulkChildrenTag from './BulkChildrenTag'
import removeChildrenFromNode from './removeChildrenFromNode'

// Structure of 'data' for a node of type 'children'. This is set in the Children drag drop from sidebar
export interface ChildrenNode {
  object: 'block'
  type: 'children'
  data: ChildrenNodeData
  nodes: NodeJSON[]
}

export interface ChildrenNodeData {
  title: string
  children: Playground.SimpleChild[]
}

// Structure of 'data' for a node of type 'child'. This is set in the Children drag drop from sidebar
export interface ChildNodeData {
  object: 'block'
  type: 'child'
  data: ChildNodeData
  nodes: NodeJSON[]
}

export interface ChildNodeData {
  title: string
  child: Playground.SimpleChild
}

const scrubPrivateChildData = (child: Playground.SimpleChild) => {
  return {
    fkey: child.fkey,
    fullName: child.fullName.split(' ')[0],
    grantedPermissions: [],
  } as Playground.SimpleChild
}

/* 
  Given list of article children from the server, check if user has access to embedded child in the node.
   - If so, hydrate child entity with extra props from matching resource in request
   - If not, then scrub private chil data
*/
const hydrateChild = ({ children }: ValueData, embeddedChild: Playground.SimpleChild) => {
  const safeChildren = children || []
  const matchedChild = safeChildren.find(
    (safeChild) => safeChild.fkey === (embeddedChild.fkey ? embeddedChild.fkey : toFkey(embeddedChild.id!))
  )

  return matchedChild ? matchedChild : scrubPrivateChildData(embeddedChild)
}

const getChildrenData = (value: Value) => {
  const allChildNodes = value.document.filterDescendants(R.propEq<any>('type', 'child')).toJS()
  const childNodeData = allChildNodes.map(({ data }: { data: Playground.SimpleChild }) => {
    return {
      type: 'child',
      typeFkey: data.fkey ? data.fkey : toFkey(data.id!),
      displayText: data.fullName,
    }
  })

  const separateChildren = R.uniq(childNodeData)

  const allChildrenNodes = value.document.filterDescendants(R.propEq<any>('type', 'children')).toJS()
  const childrenNodeData = allChildrenNodes.map(
    ({ data: { children } }: { data: { children: Playground.SimpleChild[] } }) => {
      return children.map((child: Playground.SimpleChild) => {
        return {
          type: 'child',
          typeFkey: child.fkey ? child.fkey : toFkey(child.id!),
          displayText: child.fullName,
        }
      })
    }
  )

  const aggregatedChildren = R.flatten(childrenNodeData)

  return R.uniq(aggregatedChildren.concat(separateChildren))
}

export const removeChildren = (childFkeys: string[], editor: Editor) => {
  const nodeTypes = {
    document: Document,
    text: Text,
    inline: Inline,
    block: Block,
  }

  editor.value.document.nodes.forEach((node) => {
    // AP: Pretty sure this always comes back as a Block
    const replacementNode = removeChildrenFromNode(node!.toJS(), childFkeys)

    editor.change((change) => {
      // Default to a Block node type if the object field is undefined
      const NodeType = nodeTypes[replacementNode.object!] || Block
      return change.replaceNodeByKey(node!.key, NodeType.create(replacementNode as BlockProperties))
    })
  })
}

export default {
  serialize: (obj: any) => {
    if (obj.object === 'block' && obj.type === 'child') {
      const { data } = obj.toJS()

      return (
        <Link to={`/children/${toFkey(data.id)}`} target="_blank">
          <ProfileChip
            xxsmall
            fullName={data.fullName}
            image={data.image}
            additionalText={undefined}
            large={undefined}
            small={undefined}
            xsmall={undefined}
          />
        </Link>
      )
    }
  },

  onQuery: ({ type }: Query, { value }: Editor, next: Next) => {
    if (type === 'children') {
      return getChildrenData(value)
    }

    return next()
  },

  onDrop: (e: DragEvent, { editor }: OnDropProps, next: Next) => {
    /*
      This data comes from -
      single child drag/drop: src/components/Editors/components/Menu/components/ChildItem.tsx
      bulk children drag/drop: src/components/Editors/components/Shortcuts/ChildrenShortcuts.tsx
    */
    const droppedData = e.dataTransfer.getData('data')

    if (!droppedData) {
      return
    }

    const data = JSON.parse(droppedData)

    if (data.type === 'untagChildren') {
      /* 
        We should only get here when untagging from stories.
        Untagging in tables is handled at the table level
        where we can access the full table state.
       */
      const {
        data: { childFkeys },
      } = data

      /* 
        The child removal is delayed so other
        callbacks triggered by the untag shortcut
        (e.g. a google analytics call) can run.
      */
      setTimeout(() => removeChildren(childFkeys, editor), 50)
      return
    }

    if (data.type !== 'child' && data.type !== 'children') {
      return next()
    }

    return editor.change((change: Change) => {
      const eventRange = getEventRange(e, editor)
      eventRange && change.insertBlockAtRange(eventRange, Block.create(data))
    })
  },

  renderNode: ({ attributes, children, node, editor }: RenderBlockProps, next: Next) => {
    if (node.type !== 'child' && node.type !== 'children') {
      return next()
    }

    // valueData is article data from the server
    const valueData: ValueData = R.pipe(
      R.path(['value', 'data']),
      R.ifElse(R.isNil, R.always({}), (x) => x.toJS())
    )(editor)

    if (node.type === 'children') {
      const nodeData = node.data.toJS() as ChildrenNodeData
      const hydratedChildren = nodeData.children.map((child) => hydrateChild(valueData, child))
      const childrenCount = hydratedChildren.length
      const subtitle = childrenCount === 1 ? '1 child' : `${childrenCount} children`

      return (
        <>
          <BulkChildrenTag
            title={nodeData.title}
            subtitle={subtitle}
            childList={hydratedChildren}
            attributes={attributes}
          />
          {children}
        </>
      )
    }

    const child = hydrateChild(valueData, node.data.toJS())

    if (isWebView) {
      return null
    }

    return (
      <>
        <ProfileChip
          additionalText={undefined}
          large={undefined}
          small={undefined}
          xsmall={undefined}
          xxsmall
          fullName={child.fullName}
          image={child.image}
          {...attributes}
        />
        {children}
      </>
    )
  },
}
