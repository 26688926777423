import React, { useState } from 'react'

import { Mutation } from '@apollo/client/react/components'
import gql from 'graphql-tag'

import { Box, ErrorMessage, Flex, Upload } from 'stardust'

import { useSelectedServiceFkey } from '~/contexts/Service'
import { sendAppSignalError } from '~/logging'
import { uploadMultipleFiles } from '~/utils'

const MediaUploader = ({ refetchMedia }) => {
  const serviceFkey = useSelectedServiceFkey()
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(false)

  const MAX_FILE_COUNT = 16
  const MAX_VIDEO_COUNT = 1

  const isVideoFile = (file) => file.type.includes('video/')

  const handleOnChange = (mutate) => (payload) => {
    const {
      target: { validity, files: fileList },
    } = payload
    const files = [...fileList]

    const videoFiles = () => files.filter((file) => isVideoFile(file))

    if (!validity.valid) {
      return setErrors([{ name: 'Error', type: 'File validation failed' }])
    }

    if (files.length > MAX_FILE_COUNT) {
      return setErrors([
        {
          name: 'Error',
          type: `You may only upload ${MAX_FILE_COUNT} files at once.
            ${files.length} file(s) selected.`,
        },
      ])
    }

    if (videoFiles().length > MAX_VIDEO_COUNT) {
      return setErrors([
        {
          name: 'Error',
          type: `You may upload ${MAX_VIDEO_COUNT} video at once.
            ${videoFiles().length} video(s) selected.`,
        },
      ])
    }

    setLoading(true)

    uploadMultipleFiles(files, serviceFkey, mutate)
      .then((uploadErrors) => {
        setErrors(uploadErrors)
        setLoading(false)
      })
      .catch((err) => {
        sendAppSignalError(err)
        setErrors(err)
        setLoading(false)
      })
  }

  return (
    <Mutation
      onCompleted={refetchMedia}
      mutation={gql`
        mutation uploadMedia($file: Upload!, $serviceFkey: String!) {
          uploadMedia(media: $file, serviceFkey: $serviceFkey, type: "full", await: true) {
            id
          }
        }
      `}>
      {(mutate) => (
        <Flex flexDirection="column" justifyContent="center">
          <Upload
            text={loading ? 'Uploading...' : '+ Upload'}
            isLoading={loading}
            accept="image/*, video/*, application/*, text/*"
            multiple
            onChange={handleOnChange(mutate)}
          />

          {errors && errors.length > 0 && (
            <ErrorMessage mt={3}>
              {errors.map(({ name, type }, i) => (
                <Box py={1} key={i}>
                  <strong>{name}</strong> : {type}
                </Box>
              ))}
            </ErrorMessage>
          )}
        </Flex>
      )}
    </Mutation>
  )
}

MediaUploader.displayName = 'MediaUploader'

export default React.memo(MediaUploader)
