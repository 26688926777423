import React from 'react'

const File = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5 21.75C22.5 22.5784 21.8284 23.25 21 23.25H3C2.17157 23.25 1.5 22.5784 1.5 21.75V2.25C1.5 1.42157 2.17157 0.75 3 0.75H18.045C18.4365 0.749779 18.8127 0.902663 19.093 1.176L22.047 4.059C22.3366 4.34129 22.5 4.72857 22.5 5.133V21.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.04498 8.25H18.045"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.04498 12.75H18.045"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.04498 17.25H12.045"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default File
