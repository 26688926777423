import * as R from 'ramda'

import * as factories from '../factories'

export default (node) => {
  const content = R.path(['data', 'content'], node)
  const doc = R.prop('document', node)
  const point = R.prop('layout', node)
  const shape = R.path(['data', 'type'], node)

  let data = {}

  if (content) {
    data = factories.shapeContentFactory(content)
  }

  if (doc) {
    const id = R.prop('id', doc)
    const ref = R.prop('type', doc)
    const refId = R.prop('typeId', doc)
    const displayText = R.prop('displayText', doc)
    data = { ref, refData: { id, displayText }, refId }
  }

  const newNode = factories.shapeFactory({
    data,
    properties: { kind: shape, point },
  })

  return newNode
}
