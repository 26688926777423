import React from 'react'

import { Flex, Icon, Text, Box, SecondaryButton, MainButton } from 'stardust'

import styled from 'styled-components'

import Modal from '~/components/Modal'

import { CONSENTS } from '~/modules/children'

import { FieldProps } from '~/pages/Observations/types'

import colors from '~/ui-components/tokens/colors'

const S = {
  Icon: styled(Icon)`
    border-radius: 50%;
    padding: 10px;
    background-color: ${(props) => props.backgroundColor};
    &:hover {
      background-color: ${(props) => props.hoverColor};
      transition: background-color 0.2s;
    }
  `,
}

interface Props extends FieldProps {
  selectedChildrenToTag?: Playground.SimpleChild[]
  photoVideoConsent: boolean
  noPhotoVideoConsentChildren: number
  setSelectedChildrenToTag: React.Dispatch<React.SetStateAction<any[] | never[]>>
  setPhotoVideoConsent: React.Dispatch<React.SetStateAction<boolean>>
  onCloseChildrenModal: () => void
}

const ChildPhotoVideoConsent = ({
  photoVideoConsent,
  noPhotoVideoConsentChildren,
  selectedChildrenToTag,
  onChange,
  setPhotoVideoConsent,
  onCloseChildrenModal,
}: Props) => {
  return (
    <Modal
      open={photoVideoConsent}
      dontRenderCloseButton
      onClose={() => setPhotoVideoConsent(false)}
      borderRadius="16px">
      <Flex p="24px" width="610px" flexDirection="column">
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <Icon color="" name="noPhotosAlt" ml={1} size={40} />
            <Text fontSize={4} fontWeight={600} ml={2}>
              Attention: Child photo/video consent
            </Text>
          </Flex>
          <S.Icon
            size={40}
            height={40}
            onClick={() => setPhotoVideoConsent(false)}
            name="close"
            backgroundColor={colors.cosmicShade4}
            hoverColor={colors.cosmicShade6}
          />
        </Flex>
        <Text fontSize={2} fontWeight={400} lineHeight="24px" mt={2}>
          You&apos;ve tagged {noPhotoVideoConsentChildren} children who have opted-out of photos and videos.
        </Text>
        <Box mt={3}>
          <SecondaryButton
            height={48}
            outline
            onClick={() => {
              // Untag children without consent and continue tagging
              const remainingChildren =
                selectedChildrenToTag &&
                selectedChildrenToTag.filter((child) =>
                  child.otherDetails?.consents?.includes(CONSENTS.activitiesAndExcursions)
                )
              setPhotoVideoConsent(false)
              onChange({
                name: 'children',
                value: remainingChildren,
              })
              onCloseChildrenModal()
            }}>
            Untag {noPhotoVideoConsentChildren} Children & Continue
          </SecondaryButton>
        </Box>
        <Box mt={2}>
          <MainButton
            height={48}
            outline
            onClick={() => {
              setPhotoVideoConsent(false)
              onChange({
                name: 'children',
                value: selectedChildrenToTag,
              })
              onCloseChildrenModal()
            }}>
            Continue Tagging
          </MainButton>
        </Box>
        <Box mt={2}>
          <SecondaryButton height={48} outline onClick={() => setPhotoVideoConsent(false)}>
            Cancel
          </SecondaryButton>
        </Box>
      </Flex>
    </Modal>
  )
}

export default ChildPhotoVideoConsent
