import React from 'react'

const Observations = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.025 4.16667H16.6667C17.5834 4.16667 18.3334 4.91667 18.3334 5.83333V15.8333C18.3334 16.75 17.5834 17.5 16.6667 17.5H3.33335C2.41669 17.5 1.66669 16.75 1.66669 15.8333V5.83333C1.66669 4.91667 2.41669 4.16667 3.33335 4.16667H5.97502L7.50002 2.5H12.5L14.025 4.16667ZM3.33335 15.8333H16.6667V5.83334H13.2917L11.7667 4.16667H8.23335L6.70835 5.83334H3.33335V15.8333ZM10 6.66667C7.70001 6.66667 5.83335 8.53334 5.83335 10.8333C5.83335 13.1333 7.70001 15 10 15C12.3 15 14.1667 13.1333 14.1667 10.8333C14.1667 8.53334 12.3 6.66667 10 6.66667ZM7.50003 10.8333C7.50003 12.2083 8.62503 13.3333 10 13.3333C11.375 13.3333 12.5 12.2083 12.5 10.8333C12.5 9.45833 11.375 8.33333 10 8.33333C8.62503 8.33333 7.50003 9.45833 7.50003 10.8333Z"
      fill="currentColor"
    />
  </svg>
)

export default Observations
