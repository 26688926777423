import React, { useEffect } from 'react'

import { format, getYear } from 'date-fns'

import { Box, Flex, Text, SecondaryButton } from 'stardust'

import { DatePicker, defaultPresetDates, presetEmptyDate } from '~/components/DatePicker'

import FlexDropdown from '~/ui-components/FlexDropdown'

interface TagChildrenFiltersProps {
  roomFkey: string
  rooms?: Playground.Room[]
  selectedRoom?: Nullable<Playground.Room>
  filteringByGroup: string
  selectedDate: string
  setSelectedDate: React.Dispatch<React.SetStateAction<string>>
  setRoomFkey: React.Dispatch<React.SetStateAction<string>>
  setFilteringByGroup: React.Dispatch<React.SetStateAction<string>>
  resetFilters: () => void
}

const TagChildrenFilters: React.FC<TagChildrenFiltersProps> = ({
  roomFkey,
  rooms,
  selectedRoom,
  filteringByGroup,
  selectedDate,
  setSelectedDate,
  setRoomFkey,
  setFilteringByGroup,
  resetFilters,
}) => {
  const selectedDateForFilter = selectedDate ? new Date(selectedDate) : undefined

  const groupOptions: { label: string; value: string }[] = [{ label: 'All children', value: 'All children' }]

  if (selectedDate) {
    groupOptions.push({ label: 'Booked in', value: 'Booked in' })
    groupOptions.push({ label: 'Signed in', value: 'Signed in' })
  }

  if (selectedDate === '' || roomFkey !== '') {
    groupOptions.push({ label: 'Default room', value: 'Default room' })
  }

  useEffect(() => {
    if (!roomFkey && selectedDate === '') {
      setFilteringByGroup('All children')
    }
  }, [roomFkey, selectedDate, setFilteringByGroup])

  return (
    <Flex flexDirection="column" alignItems="stretch" flex="1">
      <Flex mb={2} height={24} justifyContent="space-between" alignItems="center">
        <Text fontWeight={700} fontSize={2}>
          Filters
        </Text>
        {(roomFkey !== '' || selectedDate !== '') && (
          <Box>
            <SecondaryButton width="auto" onClick={() => resetFilters()}>
              Reset filters
            </SecondaryButton>
          </Box>
        )}
      </Flex>
      <Flex mb={3} gap="12px">
        <Box flex="1">
          <Flex height={21} mb={2} justifyContent="space-between" alignItems="center">
            <Text fontWeight={400} fontSize={2}>
              Room
            </Text>
            {roomFkey && (
              <SecondaryButton width="auto" mr="15px" onClick={() => setRoomFkey('')}>
                Clear
              </SecondaryButton>
            )}
          </Flex>
          <Box>
            <FlexDropdown
              defalutPlaceHolder={'No room selected'}
              options={(rooms || []).map((room) => ({ label: room.name, value: room }))}
              selectedOption={selectedRoom ? { label: selectedRoom.name, value: selectedRoom } : null}
              onSelect={(option) => setRoomFkey(option ? option.value.fkey : '')}
              renderOption={(option) => option.label}
            />
          </Box>
        </Box>
        <Box flex="1">
          <Flex flexDirection="column" height={77} alignSelf="stretch">
            <Text mb={2} ml={1} fontWeight={400} fontSize={2} lineHeight="21px">
              Date
            </Text>
            <Flex width="100%">
              <DatePicker
                br="16px"
                heightAdjust="48px"
                pbottom="12px"
                alignItems="center"
                data-test="DatePicker"
                width="100%"
                borderWidth="1px"
                emptyPresetPeriod={presetEmptyDate}
                readOnly={false}
                initialDisplayMonth={selectedDateForFilter}
                showStyledCalander
                presetPeriods={defaultPresetDates}
                selected={selectedDateForFilter}
                setSelection={(date) => {
                  const formattedDate = date ? format(date, 'YYYY-MM-DD') : undefined
                  setSelectedDate(formattedDate || '')
                }}
                startYear={2000}
                endYear={getYear(new Date())}
                showTextBox={true}
                label={''}
              />
            </Flex>
          </Flex>
        </Box>
        <Box flex="1">
          <Flex height={21} mb={2} justifyContent="space-between" alignItems="center">
            <Text ml={1} fontWeight={400} fontSize={2}>
              Group
            </Text>
          </Flex>

          <Box>
            <FlexDropdown
              options={groupOptions}
              selectedOption={filteringByGroup ? { label: filteringByGroup, value: filteringByGroup } : null}
              onSelect={(option) => setFilteringByGroup(option ? option.value : '')}
              renderOption={(option) => option.label}
              dropdownPosition="left"
            />
          </Box>
        </Box>
      </Flex>
    </Flex>
  )
}

TagChildrenFilters.displayName = 'LinksDocumentsFilter'

export default React.memo(TagChildrenFilters)
