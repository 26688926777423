import React, { ReactNode, useCallback, useState } from 'react'

import { ListDivider as RMWCListDivider } from '@rmwc/list'
import {
  Menu as RMWCMenu,
  MenuItem as RMWCMenuItem,
  MenuSurfaceAnchor as RMWCMenuSurfaceAnchor,
} from '@rmwc/menu'
import styled from 'styled-components'

import '@material/list/dist/mdc.list.css'
import '@material/menu/dist/mdc.menu.css'
import '@material/menu-surface/dist/mdc.menu-surface.css'
import '@material/ripple/dist/mdc.ripple.css'
import '@material/list/dist/mdc.list.css'

import Box from './Box'
import { AlignX, Theme } from './theme/types'

const sizes = {
  small: '24px',
  medium: '36px',
  large: '48px',
} as { [key: string]: string }

const Container = styled.div<{ align: AlignX; height: string; theme: Theme }>`
  .mdc-menu-surface {
    border-color: ${({ theme }) => theme.colors[theme.components.button.color]};
    background: ${({ theme }) => theme.colors.foreground};
    border-radius: ${({ theme }) => theme.radii[1]}};
  }

  .mdc-list {
    padding: 0;
    background: ${({ theme }) => theme.colors.foreground};
  }

  .mdc-list-item {
    white-space: nowrap !important;
    font-size: ${({ theme }) => theme.fontSizes['subtitle']};
    font-weight: ${({ theme }) => theme.fontSizes['medium']};
    letter-spacing: 0.75px;
    color: ${({ theme }) => theme.colors[theme.components.text.color]};
    border-color: ${({ theme }) => theme.colors[theme.components.button.color]};
    max-height: ${({ height }) => height};
    justify-content: ${({ align }) => {
      switch (align) {
        case 'left':
          return 'flex-start'
        case 'center':
          return 'center'
        case 'right':
          return 'flex-end'
      }
    }};
  }

  .mdc-list-item:hover {
    background-color: ${({ theme }) => theme.colors['lowestEmphasis']};
  }

  .mdc-list-item:focus {
    background-color: ${({ theme }) => theme.colors['lowestEmphasis']};
  }

  .mdc-list-item:focus::before {
    opacity: 0 !important;
  }

  .mdc-list-item:hover::before {
    opacity: 0 !important;
  }

  .mdc-list-item:active::after {
    opacity: 0 !important;
  }

  .mdc-list-divider {
    background: ${({ theme }) => theme.colors.background};
    margin: 0;
  }
`
export interface MenuProps {
  anchorCorner?:
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'topStart'
    | 'topEnd'
    | 'bottomStart'
    | 'bottomEnd'
  size?: 'small' | 'medium' | 'large'
  align?: AlignX
  fixed?: boolean
  items: string[]
  children: ReactNode
  onSelect?(index: number): void
  onOpen?(): void
  onClose?(): void
}

export const Menu = ({
  anchorCorner,
  size,
  align,
  fixed,
  items,
  children,
  onSelect,
  onOpen,
  onClose,
}: MenuProps) => {
  const [isOpen, setOpen] = useState(false)

  const onToggle = useCallback(() => {
    setOpen((isOpen) => !isOpen)
  }, [])

  return (
    <Container height={sizes[size || 'medium']} align={align || 'left'}>
      <RMWCMenuSurfaceAnchor>
        <RMWCMenu
          anchorCorner={anchorCorner}
          fixed={fixed}
          open={isOpen}
          onSelect={(evt) => onSelect && onSelect(evt.detail.index)}
          onOpen={() => {
            onOpen && onOpen()
          }}
          onClose={() => {
            setOpen(false)
            onClose && onClose()
          }}>
          {items.map((item, index) => {
            return (
              <Box key={index}>
                <RMWCMenuItem ripple={false}>{item}</RMWCMenuItem>
                {index !== items.length && <RMWCListDivider />}
              </Box>
            )
          })}
        </RMWCMenu>
        <Box onClick={onToggle}>{children}</Box>
      </RMWCMenuSurfaceAnchor>
    </Container>
  )
}

Menu.defaultProps = {
  size: 'medium',
  align: 'left',
}

Menu.displayName = 'Menu'

export default React.memo(Menu)
