import React from 'react'

import format from 'date-fns/format'
import { Flex, Icon, Text } from 'stardust'
import styled from 'styled-components'

import { isHomeWebview } from '~/config'
import colors from '~/ui-components/tokens/colors'

const S = {
  FuturePlanNote: styled(Text)`
    white-space: pre-wrap;
    overflow-wrap: anywhere;
  `,
}

interface Props {
  futurePlans?: string
  planningDate?: string
  tagType?: string
}

const FuturePlans = ({ tagType, futurePlans, planningDate }: Props) => {
  const formattedDate = planningDate ? format(new Date(planningDate), 'D MMM YYYY') : ''
  const textColor = isHomeWebview ? colors.cosmicShade0 : 'black'
  return tagType === 'Future Plans' ? (
    <Flex mt="10px" flexWrap="wrap" flexDirection="column" ml={3} mr={3}>
      <Flex alignItems="center">
        <Icon
          size={15}
          name="calendarAlt"
          mr={2}
          fill={isHomeWebview ? colors.cosmicShade0 : colors.cosmicShade10}
        />
        <Text fontSize="12px" mr={3} color={textColor}>
          Future Plan date
        </Text>
        <Text fontSize="12px" fontWeight="bold" color={textColor}>
          {formattedDate}
        </Text>
      </Flex>
      <Flex alignItems="flex-start" mt={3}>
        <Icon
          size={15}
          name="futurePlans"
          mr={2}
          fill={isHomeWebview ? colors.cosmicShade0 : colors.cosmicShade10}
        />
        <Text mt="1px" minWidth="max-content" fontSize="12px" mr={3} color={textColor}>
          Future Plan note
        </Text>
        <S.FuturePlanNote fontSize="12px" fontWeight="bold" lineHeight="16px" color={textColor}>
          {futurePlans}
        </S.FuturePlanNote>
      </Flex>
    </Flex>
  ) : null
}

FuturePlans.displayName = 'FuturePlans'

export default React.memo(FuturePlans)
