import React from 'react'

import { Box, MainButton } from 'stardust'

import ErrorPage from '~/components/ErrorPage'
import { logout, redirectToLogin } from '~/modules/auth'

const SessionExpired = () => {
  const onReauthenticate = () => {
    logout().then(redirectToLogin)
  }

  return (
    <ErrorPage
      heading="Session Expired"
      message="Your existing session has expired. Please login again to continue.">
      <Box width="200px">
        <MainButton onClick={onReauthenticate}>Login</MainButton>
      </Box>
    </ErrorPage>
  )
}

SessionExpired.displayName = 'SessionExpiredPage'

export default React.memo(SessionExpired)
