import React from 'react'

const CommentsAlt = () => (
  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.86494 4.05574C4.20112 1.92615 6.63589 0.746366 9.1353 1.0174C11.6347 1.28843 13.76 2.96269 14.6086 5.32919C14.7018 5.58913 14.5667 5.87541 14.3067 5.96862C14.0468 6.06183 13.7605 5.92668 13.6673 5.66675C12.9479 3.66063 11.1463 2.24132 9.02749 2.01157C6.9087 1.78181 4.84471 2.78193 3.71201 4.58722C2.57932 6.3925 2.57676 8.68604 3.70542 10.4938C3.79581 10.6386 3.80611 10.8195 3.73274 10.9736L2.55705 13.4429L5.02636 12.2672C5.18032 12.1939 5.36102 12.2041 5.50574 12.2943C6.13766 12.6881 6.84233 12.9508 7.57783 13.0667C7.8506 13.1097 8.03687 13.3657 7.99386 13.6385C7.95086 13.9113 7.69488 14.0975 7.4221 14.0545C6.64562 13.9321 5.89825 13.6711 5.21543 13.2847L1.71491 14.9514C1.28982 15.1538 0.84613 14.7101 1.04852 14.285L2.71507 10.7848C1.52779 8.69174 1.57813 6.10664 2.86494 4.05574Z"
      fill="currentColor"
    />
    <path
      d="M5.49996 5.99997C5.22382 5.99997 4.99996 5.77611 4.99996 5.49997C4.99996 5.22383 5.22382 4.99997 5.49996 4.99997H10.5C10.7761 4.99997 11 5.22383 11 5.49997C11 5.77611 10.7761 5.99997 10.5 5.99997H5.49996Z"
      fill="currentColor"
    />
    <path
      d="M5.49996 8.99997C5.22382 8.99997 4.99996 8.77611 4.99996 8.49997C4.99996 8.22383 5.22382 7.99997 5.49996 7.99997H7.49996C7.77611 7.99997 7.99996 8.22383 7.99996 8.49997C7.99996 8.77611 7.77611 8.99997 7.49996 8.99997H5.49996Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1374 14.0659C17.0654 12.8803 17.2651 11.2697 16.6382 9.88598C15.9772 8.42709 14.5208 7.49269 12.9191 7.49997C11.3529 7.49998 9.92513 8.39747 9.24611 9.80891C8.56706 11.2204 8.75691 12.8962 9.73452 14.12C10.6682 15.2887 12.1625 15.8478 13.6248 15.5904L15.7902 16.9256C16.157 17.1517 16.619 16.839 16.5453 16.4144L16.1374 14.0659ZM15.2309 13.5926C15.1302 13.7064 15.0867 13.8598 15.1127 14.0095L15.3701 15.4917L13.9817 14.6357C13.8699 14.5668 13.7354 14.5446 13.6074 14.574C12.4553 14.8385 11.2536 14.4194 10.5158 13.4958C9.77807 12.5723 9.63479 11.3076 10.1472 10.2424C10.6597 9.17724 11.7372 8.49992 12.9236 8.49996C14.131 8.49443 15.229 9.19884 15.7273 10.2987C16.2256 11.3985 16.0312 12.6884 15.2309 13.5926Z"
      fill="currentColor"
    />
    <path
      d="M14 9.99997C14.2761 9.99997 14.5 10.2238 14.5 10.5C14.5 10.7761 14.2761 11 14 11H12C11.7238 11 11.5 10.7761 11.5 10.5C11.5 10.2238 11.7238 9.99997 12 9.99997H14Z"
      fill="currentColor"
    />
    <path
      d="M14 12C14.2761 12 14.5 12.2238 14.5 12.5C14.5 12.7761 14.2761 13 14 13H12C11.7238 13 11.5 12.7761 11.5 12.5C11.5 12.2238 11.7238 12 12 12H14Z"
      fill="currentColor"
    />
  </svg>
)

export default CommentsAlt
