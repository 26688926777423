import React, { ReactNode } from 'react'

import styled from 'styled-components'

import Text, { Props as TextProps } from './Text'

interface TruncateMultilineProps extends TextProps {
  children: ReactNode | ReactNode[]
  maxLines: number
}

const StyledTruncateMultiline = styled(Text)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${(props) => props.maxLines};
  overflow: hidden;
  line-height: 1.2em;
`

const TruncateMultiline = ({ children, maxLines = 1, ...props }: TruncateMultilineProps) => {
  return (
    <StyledTruncateMultiline maxLines={maxLines} {...props}>
      {children}
    </StyledTruncateMultiline>
  )
}

TruncateMultiline.displayName = 'TruncateMultiline'
export default TruncateMultiline
