import React from 'react'

const ArrowDown = () => (
  <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-2.50227e-07 0.275476C-2.61655e-07 0.0140395 0.192585 -0.0778031 0.42678 0.071866L5.57162 3.33155C5.83357 3.48124 6.16644 3.48124 6.42838 3.33155L11.5732 0.0718655C11.809 -0.0778037 12 0.014039 12 0.275475L12 1.87908C11.9824 2.17239 11.8269 2.44456 11.5732 2.62597L6.42998 5.88808C6.16789 6.03731 5.83532 6.03731 5.57322 5.88808L0.428381 2.62791C0.174737 2.4465 0.0192155 2.17433 0.00160025 1.88102L-2.50227e-07 0.275476Z"
      fill="currentColor"
    />
  </svg>
)

export default ArrowDown
