import React from 'react'

import styled from 'styled-components'

const Interactive = styled.div`
  cursor: pointer;
`

Interactive.displayName = 'Interactive'

export default React.memo(Interactive)
