import { ApolloClient, ApolloError, InMemoryCache, defaultDataIdFromObject, from } from '@apollo/client'
import { createLink } from 'apollo-absinthe-upload-link'
import { NextLink, Observable, Operation } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'

import { isDev } from '~/config'
import Authentication, { getAuthToken, setAuthToken } from '~/modules/auth'
import { toFkey } from '~/utils'

// Internal

const generateCacheIDForMaybeFkey = (responseObject: any) => {
  return responseObject.fkey || toFkey(responseObject.id)
}

const generateCacheIdForNonFkeyObject = (responseObject: any) => {
  if (!responseObject.fkey || !isDev) {
    return defaultDataIdFromObject(responseObject)
  } else {
    throw Error(`Fkey returned in type ${responseObject.__typename} when non-fkey object expected`)
  }
}

const cacheConfig = {
  typePolicies: {
    Author: {
      keyFields: ['fkey', 'firstName', 'lastName'],
    },
    Post: {
      keyFields: ['id', 'status'],
    },
  },
  dataIdFromObject(responseObject: any) {
    switch (responseObject.__typename) {
      case 'Child':
        return `Child:${generateCacheIDForMaybeFkey(responseObject)}`
      case 'ChildRoom':
        return `ChildRoom:${generateCacheIDForMaybeFkey(responseObject)}`
      case 'CommentAuthor':
        return `CommentAuthor:${generateCacheIDForMaybeFkey(responseObject)}`
      case 'ParentGuardian':
        return `ParentGuardian:${generateCacheIDForMaybeFkey(responseObject)}`
      case 'Person':
        return `Person:${generateCacheIDForMaybeFkey(responseObject)}`
      case 'Room':
        return `Room:${generateCacheIDForMaybeFkey(responseObject)}`
      case 'Service':
        return `Service:${generateCacheIDForMaybeFkey(responseObject)}`
      case 'SimpleChild':
        return `SimpleChild:${generateCacheIDForMaybeFkey(responseObject)}`
      case 'SimpleRoom':
        return `SimpleRoom:${generateCacheIDForMaybeFkey(responseObject)}`
      case 'SimpleService':
        return `SimpleService:${generateCacheIDForMaybeFkey(responseObject)}`
      case 'SimpleParentGuardian':
        return `SimpleParentGuardian:${generateCacheIDForMaybeFkey(responseObject)}`
      case 'TransportListChild':
        return `TransportListChild:${generateCacheIDForMaybeFkey(responseObject)}`
      case 'User':
        return `User:${generateCacheIDForMaybeFkey(responseObject)}`
      default:
        return generateCacheIdForNonFkeyObject(responseObject)
    }
  },
}

// https://github.com/apollographql/apollo-link/issues/646#issuecomment-423279220
function promiseToObservable<T>(promise: Promise<T>) {
  return new Observable<T>((subscriber: any) => {
    promise.then(
      (value) => {
        if (subscriber.closed) {
          return
        }

        subscriber.next(value)
        subscriber.complete()
      },
      (err) => subscriber.error(err)
    )
    return subscriber
  })
}

function refreshToken(
  uri: string,
  forward: (operation: Operation) => NextLink,
  operation: Operation,
  onAuthExpire: () => void
) {
  const refreshObservable = promiseToObservable(Authentication(uri).refresh())
  refreshObservable.subscribe({ error: onAuthExpire })
  return refreshObservable.flatMap((token: string): any => {
    const oldHeaders = operation.getContext().headers

    operation.setContext({
      headers: {
        ...oldHeaders,
        authorization: token ? `Bearer ${token}` : '',
      },
    })

    setAuthToken(token)
    return forward(operation)
  })
}

// Links

function authLink() {
  return setContext((_, { headers }) => {
    const authToken = getAuthToken()
    return {
      headers: {
        ...headers,
        authorization: authToken ? `Bearer ${authToken}` : '',
      },
    }
  })
}

function errorLink(uri: string, onAuthExpire: () => void) {
  return onError(({ forward, networkError, operation }: any) => {
    if (networkError && networkError.statusCode === 401) {
      return refreshToken(uri, forward, operation, onAuthExpire)
    }

    return forward(operation)
  })
}

// Creating the upload link internally creates the HttpLink as well, so it
// no longer needs to be explicitly created
function uploadEnabledHttpLink(uri: string) {
  return createLink({ uri: `${uri}/graph` })
}

/**
 * is404, accepts an error and checks if it has a not found message.
 * The graph API doesn't return a 404 status code.
 */
export const is404 = (error: Nullable<ApolloError>) => errorIncludesMessage(error, 'not_found')

/**
 * is401, accepts an error and checks if it has an unauthorized message.
 * The graph API doesn't return a 401 status code.
 */
export const is401 = (error: Nullable<ApolloError>) => errorIncludesMessage(error, 'unauthorized')

const errorIncludesMessage = (error: Nullable<ApolloError>, message: string) => {
  const errorMessages = error?.graphQLErrors.map((error) => error.message)
  return errorMessages?.includes(message)
}

export const createApolloClient = (apiUri: string, authUri: string, onAuthExpire: () => void) => {
  return new ApolloClient({
    cache: new InMemoryCache(cacheConfig),
    link: from([authLink(), errorLink(authUri, onAuthExpire), uploadEnabledHttpLink(apiUri)]),
    connectToDevTools: isDev,
  })
}
