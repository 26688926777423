import React, { useState } from 'react'

import { useQuery } from '@apollo/client'
import { Location, RouteComponentProps } from '@reach/router'
import * as R from 'ramda'

import { Box, Empty } from 'stardust'

import styled from 'styled-components'

import empty from '~/assets/images/empty.svg'
import DocumentCard from '~/components/DocumentCard'
import DocumentModalSelector from '~/components/DocumentModalSelector'
import { groupWithInsertedAt } from '~/components/Documents/core'

import List from '~/components/Documents/List'
import SectionHeading from '~/components/Documents/SectionHeading'
import Cards from '~/components/Loading/Cards'
import { ScrollUp, getCoverImageUrl, hasAtLeastOneOf, isNilOrEmpty } from '~/utils'

import { GET_ROOM_DOCUMENTS } from '../queries'

const GridContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
`

interface Props extends RouteComponentProps {
  roomFkey: string
  serviceFkey: string
}

const Documents = ({ roomFkey, serviceFkey }: Props) => {
  const [selectedDoc, setSelectedDoc] = useState<Nullable<Playground.Document>>(null)

  const { data, error, fetchMore, loading } = useQuery(GET_ROOM_DOCUMENTS, {
    variables: {
      roomFkey,
      serviceFkey,
    },
  })

  if (error) {
    return null
  }

  if (isNilOrEmpty(data) && loading) {
    return <Cards />
  }

  const path = ['service', 'room', 'documents']

  return hasAtLeastOneOf('edges', R.path(path, data)) ? (
    <ScrollUp>
      <Location>
        {({ location }) => (
          <List
            data={data}
            fetchMore={fetchMore}
            path={path}
            pause={`/rooms/${roomFkey}` !== location.pathname}
            groupWith={groupWithInsertedAt}>
            {({ documents }: any) => {
              const { insertedAt, typeId }: any = R.pipe(R.head, R.pick(['insertedAt', 'typeId']))(documents)

              return (
                <Box px={3} key={`${insertedAt}_${typeId}`} mt={4}>
                  <SectionHeading date={insertedAt} />
                  <GridContainer gap="16px" p={3}>
                    {documents.map((doc: Playground.Document) => (
                      <DocumentCard
                        key={`${doc.id}`}
                        author={doc.author ?? 'No Author'}
                        coverImage={getCoverImageUrl(doc)}
                        hasUnpublishedChanges={doc.hasUnpublishedChanges}
                        id={doc.typeId}
                        isPublished={doc.status === 'PUBLISHED' || doc.hasUnpublishedChanges}
                        title={doc.title}
                        type={doc.type}
                        onClick={() => setSelectedDoc(doc)}
                        updatedAt={doc.updatedAt}
                        status={doc.status}
                      />
                    ))}
                  </GridContainer>
                </Box>
              )
            }}
          </List>
        )}
      </Location>
      <DocumentModalSelector document={selectedDoc} onClose={() => setSelectedDoc(null)} />
    </ScrollUp>
  ) : (
    <Box mt={5}>
      <Empty heading="documents" image={empty} />
    </Box>
  )
}

Documents.displayName = 'Documents'

export default Documents
