import React from 'react'

import styled from 'styled-components'
import {
  HeightProps,
  MaxHeightProps,
  MaxWidthProps,
  SpaceProps,
  WidthProps,
  height,
  maxHeight,
  maxWidth,
  space,
  width,
} from 'styled-system'

type Props = HeightProps & MaxHeightProps & MaxWidthProps & SpaceProps & WidthProps

const Video = styled.video<Props>`
  ${height};
  ${maxWidth};
  ${maxHeight};
  ${space};
  ${width};
`

Video.defaultProps = {
  autoPlay: false,
  controls: true,
}

Video.displayName = 'Video'

export default React.memo(Video)
