const XPLOR_VALID_INPUT = /^xplor-\d+$/
const QK_VALID_INPUT = /^qk\d{4,5}-\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/
const DISCOVER_VALID_INPUT = /^discover-\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/

const isValidServiceFkey = (inputServiceFkey: string): boolean => {
  return (
    XPLOR_VALID_INPUT.test(inputServiceFkey) ||
    QK_VALID_INPUT.test(inputServiceFkey) ||
    DISCOVER_VALID_INPUT.test(inputServiceFkey)
  )
}

export default isValidServiceFkey
