import React from 'react'

import TruncateMarkup from 'react-truncate-markup'
import { Absolute, BackgroundImage, Box, Card, Flex, Icon, Text } from 'stardust'
import * as borderRadius from 'stardust/utils/borderRadius'
import styled from 'styled-components'

import Placeholder from '~/components/DocumentCardPlaceholder'
import DocumentIcon from '~/components/DocumentIcon'
import type { DropdownMenuItemProps } from '~/components/DropdownMenu'

import { isHome, isHomeWebview, isWebView } from '~/config'

import colors from '~/ui-components/tokens/colors'

import { contentStatusIndicator } from '~/utils'

import DocumentCardFooter from './DocumentCardFooter'
import DocumentIconsBar from './DocumentIconsBar'
import StatusPill from './StatusPill'

const BoxContainer = styled(Box)`
  position: relative;
`

const Container = styled(Card)`
  outline: ${(props) => (props.isSelected && props.isLinksCard ? '2px solid ' + colors.nebulaBlue2 : 'none')};
  cursor: pointer;
  transition: box-shadow 0.2s;
  border-radius: 16px;

  &:hover {
    outline: 2px solid
      ${(props) => (props.isSelected && props.isLinksCard ? colors.nebulaBlue2 : colors.cosmicShade16)};
  }
`

const HiddenBox = styled(Box)`
  ${borderRadius.topLeft}
  ${borderRadius.topRight}

  overflow: hidden;
`

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;

  @media only screen and (max-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
  }
`

interface Props {
  author?: string
  bookmarked?: boolean
  commentCount?: number
  viewCount?: number
  likeCount?: number
  coverImage?: string
  hasUnpublishedChanges?: boolean
  id: number
  isPublished?: boolean
  menuItems?: DropdownMenuItemProps[]
  status?: Playground.Status
  title: string
  thumbImage?: string
  type: Playground.DocumentType
  mimeType?: string
  updatedAt: string | Date
  isSelected?: boolean
  isLinksCard?: boolean
  onClick?(): void
}

const DocumentCard = ({
  author,
  bookmarked,
  commentCount,
  viewCount,
  likeCount,
  coverImage,
  hasUnpublishedChanges,
  id,
  isPublished,
  menuItems,
  updatedAt,
  status,
  title,
  type,
  isLinksCard,
  isSelected,
  onClick,
}: Props) => {
  const cardImageheight = '116px'

  return (
    <BoxContainer>
      <Container
        borderRadius={0}
        height="100%"
        minHeight={isLinksCard && !isWebView ? '265px' : ['290px', '310px']}
        id={`${type}-${id}`}
        bg={
          isSelected && isLinksCard
            ? colors.nebulaBlue0
            : isHomeWebview
            ? 'surfaceSecondary'
            : 'surfacePrimary'
        }
        boxShadow="none"
        width="100%"
        onClick={onClick}
        isSelected={isSelected}
        isLinksCard={isLinksCard}
        data-test="document-card">
        {coverImage ? (
          <HiddenBox brTopLeft={16} brTopRight={16}>
            <BackgroundImage image={coverImage} width={1} height={cardImageheight} />
          </HiddenBox>
        ) : (
          <Placeholder type={type} />
        )}

        <Absolute top="100px" left="12px">
          <Box
            borderRadius="50%"
            border={`3px solid ${isHomeWebview ? colors.cosmicShade10 : colors.cosmicShade0}`}>
            <DocumentIcon type={type} size={24} />
          </Box>
        </Absolute>

        {isLinksCard ? (
          <Flex
            mx={3}
            height={`calc(100% - ${cardImageheight})`}
            flexDirection="column"
            justifyContent="space-between">
            <Box height="48px" mt="20px">
              <Flex alignItems="center" justifyContent="space-between">
                <Text.span fontSize={2} lineHeight="16px" maxWidth={143}>
                  <TruncateMarkup lines={3} ellipsis="...">
                    <strong>{title}</strong>
                  </TruncateMarkup>
                </Text.span>
                {bookmarked && <Icon name="bookmarkAlt" />}
              </Flex>
            </Box>
            <Box mb="14px">
              <DocumentCardFooter authorFullName={author} updatedAt={updatedAt} menuItems={menuItems} />
            </Box>
          </Flex>
        ) : (
          <Box mx="12px">
            <Box height="48px" mt="20px">
              <Flex alignItems="center" justifyContent="space-between">
                <Text.span fontSize={2} lineHeight="16px" maxWidth="100%">
                  <TruncateMarkup lines={3} ellipsis="...">
                    <strong>{title}</strong>
                  </TruncateMarkup>
                </Text.span>

                {bookmarked && status !== 'ARCHIVED' && <Icon name="bookmarkAlt" />}
              </Flex>
            </Box>
            <Layout>
              <Box height="25px">
                {!isHome && (
                  <StatusPill
                    status={contentStatusIndicator({ isPublished, hasUnpublishedChanges, status })}
                  />
                )}
              </Box>
              <Box height="20px">
                {type == 'observation' && (
                  <DocumentIconsBar commentCount={commentCount} viewCount={viewCount} likeCount={likeCount} />
                )}
              </Box>
            </Layout>
            <DocumentCardFooter authorFullName={author} updatedAt={updatedAt} menuItems={menuItems} />
          </Box>
        )}
      </Container>
    </BoxContainer>
  )
}

DocumentCard.displayName = 'DocumentCard'

export default React.memo(DocumentCard)
