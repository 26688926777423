import React, { useState } from 'react'

import { Box, Card } from 'stardust'

import ObservationDetailCard from '~/components/ObservationDetailCard'
import ObservationPreviewCard from '~/components/ObservationPreviewCard'

const STACK_WIDTH = 768
const SUMMARY_WIDTH = 400

interface Props {
  id: number
}

const ObservationResponsiveCard = ({ id }: Props) => {
  const [width, setWidth] = useState<Nullable<number>>(null)

  const measure = (node: HTMLDivElement) => {
    setWidth(node ? node.clientWidth : null)
  }

  const renderObservation = () => {
    if (!width) return <Card p={3}>Loading...</Card>
    return width > SUMMARY_WIDTH ? (
      <ObservationDetailCard id={id} stacked={width < STACK_WIDTH} />
    ) : (
      <ObservationPreviewCard id={id} />
    )
  }

  return <Box ref={measure}>{renderObservation()}</Box>
}

ObservationResponsiveCard.displayName = 'ObservationResponsiveCard'

export default React.memo(ObservationResponsiveCard)
