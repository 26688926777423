import React from 'react'

const QuestionMark = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8271 7.57939C12.613 6.65026 11.7357 6.02978 10.7883 6.13744C9.84091 6.2451 9.12518 7.04661 9.125 8.00009C9.12493 8.34527 8.84506 8.62504 8.49988 8.62497C8.1547 8.62491 7.87493 8.34503 7.875 7.99985C7.8753 6.41072 9.06818 5.07486 10.6472 4.89543C12.2261 4.716 13.6883 5.75014 14.0452 7.29869C14.402 8.84724 13.5399 10.417 12.0417 10.9467C11.7918 11.0351 11.6249 11.2713 11.625 11.5363V12.375C11.625 12.7201 11.3452 13 11 13C10.6548 13 10.375 12.7201 10.375 12.375V11.537C10.375 11.5369 10.375 11.5371 10.375 11.537C10.3747 10.7421 10.8756 10.0332 11.625 9.76822C12.524 9.45039 13.0412 8.50852 12.8271 7.57939Z"
      fill="#A0A0A0"
    />
    <path
      d="M10.375 11.537V12.375C10.375 12.7201 10.6548 13 11 13C11.3452 13 11.625 12.7201 11.625 12.375V11.5363C11.6249 11.2713 11.7918 11.0351 12.0417 10.9467C13.5399 10.417 14.402 8.84724 14.0452 7.29869C13.6883 5.75014 12.2261 4.716 10.6472 4.89543C9.06818 5.07486 7.8753 6.41072 7.875 7.99985C7.87493 8.34503 8.1547 8.62491 8.49988 8.62497C8.84506 8.62504 9.12493 8.34527 9.125 8.00009C9.12518 7.04661 9.84091 6.2451 10.7883 6.13744C11.7357 6.02978 12.613 6.65026 12.8271 7.57939C13.0412 8.50852 12.524 9.45039 11.625 9.76822C10.8756 10.0332 10.3747 10.7421 10.375 11.537ZM10.375 11.537C10.375 11.5371 10.375 11.5369 10.375 11.537Z"
      stroke="#A0A0A0"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0625 15.1875C10.0625 14.6697 10.4822 14.25 11 14.25C11.5178 14.25 11.9375 14.6697 11.9375 15.1875C11.9375 15.7053 11.5178 16.125 11 16.125C10.4822 16.125 10.0625 15.7053 10.0625 15.1875Z"
      fill="#A0A0A0"
      stroke="#A0A0A0"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 10.5C1 4.97715 5.47715 0.5 11 0.5C16.5228 0.5 21 4.97715 21 10.5C21 16.0228 16.5228 20.5 11 20.5C5.47715 20.5 1 16.0228 1 10.5ZM11 1.75C6.16751 1.75 2.25 5.66751 2.25 10.5C2.25 15.3325 6.16751 19.25 11 19.25C15.8325 19.25 19.75 15.3325 19.75 10.5C19.75 5.66751 15.8325 1.75 11 1.75Z"
      fill="#A0A0A0"
      stroke="#A0A0A0"
      strokeWidth="0.5"
    />
  </svg>
)

export default QuestionMark
