import React, { useContext } from 'react'

import { Router } from '@reach/router'

import CanRoute from '~/components/Authorisation/CanRoute'
import ErrorPage from '~/components/ErrorPage'
import { isHome, isPlayground } from '~/config'
import { ServiceContext, useSelectedServiceFkey, useSelectedServiceId } from '~/contexts/Service'

import * as perms from '~/modules/permissions'

import ChildGroups from '../ChildGroups'
import Children from '../Children'
import CommsCenter from '../CommsCenter'
import Dashboard from '../Dashboard'
import Educators from '../Educators'
import LearningAnalysis from '../LearningAnalysis'
import LearningFrameworks from '../LearningFrameworks'
import LiveLearning from '../LiveLearning'
import Moments from '../Moments'
import Observations from '../Observations'
import ParentFinance from '../ParentFinance'
import Planning from '../Planning'
import Reports from '../Reports'
import Rooms from '../Rooms'
import Search from '../Search'
import SearchWebView from '../Search/SearchWebView'
import Stories from '../Stories'
import Tables from '../Tables'

import TaggedDocumentsWebview from './components/TaggedDocumentsWebview'

const hasCohortTrackingFeature = (serviceFkey: string, services: Playground.SimpleService[]): boolean => {
  const selectedService = services.find((x) => x.fkey === serviceFkey)
  if (!selectedService) return false

  return perms.cohortTrackingEnabled(selectedService.features)
}

const hasTransportListFeature = (serviceFkey: string, services: Playground.SimpleService[]): boolean => {
  const selectedService = services.find((x) => x.fkey === serviceFkey)
  if (!selectedService) return false

  return perms.transportListsEnabled(selectedService.features)
}

interface Props {
  hasReports: 'true' | 'false'
  hasFrameworks: boolean
}

const RootRouter = ({ hasFrameworks, hasReports }: Props) => {
  const {
    state: { providerFkey, services },
  } = useContext(ServiceContext)
  const serviceFkey = useSelectedServiceFkey()
  const serviceId = useSelectedServiceId()

  if (!serviceFkey || (!providerFkey && isPlayground))
    return <ErrorPage heading="Error" message="Something went wrong" />

  return (
    <Router>
      <CanRoute path="/" perform={perms.dashboard.read} yes={() => <Dashboard />} />
      {isHome && (
        <CanRoute
          path="messages/*"
          perform={perms.commsCenter.read}
          requiredHomeFeature="comms"
          yes={() => <CommsCenter serviceId={serviceId!} />}
        />
      )}
      <CanRoute path="moments/*" perform={perms.moments.read} yes={() => <Moments />} />
      <CanRoute path="observations/*" perform={perms.observations.read} yes={() => <Observations />} />
      <CanRoute
        path="stories/*"
        perform={perms.learningStories.read}
        requiredHomeFeature="learning_events"
        yes={() => <Stories providerFkey={providerFkey!} serviceFkey={serviceFkey} />}
      />
      <CanRoute
        path="planning/*"
        perform={perms.learningPlans.read}
        requiredHomeFeature="learning_events"
        yes={() => <Planning providerFkey={providerFkey!} serviceFkey={serviceFkey} />}
      />
      <CanRoute
        path="tables/*"
        perform={perms.learningTables.read}
        requiredHomeFeature="learning_events"
        yes={() => <Tables providerFkey={providerFkey} serviceFkey={serviceFkey} />}
      />
      {isPlayground && (
        <CanRoute
          path="educators/*"
          perform={perms.educatorProfiles.read}
          yes={() => <Educators serviceFkey={serviceFkey} />}
        />
      )}
      <CanRoute
        path="children/*"
        perform={perms.childrenProfiles.read}
        yes={() => <Children hasFrameworks={hasFrameworks} />}
      />
      {isPlayground && <CanRoute path="rooms/*" perform={perms.roomProfiles.read} yes={() => <Rooms />} />}
      <CanRoute path="search" perform={'search:read'} yes={() => <Search serviceFkey={serviceFkey} />} />
      <CanRoute
        path="search/webview"
        perform={'search:read'}
        yes={() => <SearchWebView serviceFkey={serviceFkey} />}
      />
      <CanRoute
        path="tagged-documents/webview"
        perform={'search:read'}
        yes={() => <TaggedDocumentsWebview serviceFkey={serviceFkey} />}
      />
      {isHome && (
        <CanRoute
          path="parent-finance/*"
          perform={perms.parentFinance.read}
          requiredHomeFeature="finance"
          yes={() => <ParentFinance />}
        />
      )}
      {isPlayground && (
        <CanRoute
          path="reports/*"
          perform={hasReports}
          permissions={['true']}
          yes={() => (
            <Reports
              serviceFkey={serviceFkey}
              hasCohortTracking={hasCohortTrackingFeature(serviceFkey, services)}
              hasTransportLists={hasTransportListFeature(serviceFkey, services)}
            />
          )}
        />
      )}
      <CanRoute
        path="live-learning/*"
        perform={perms.liveLearning.read}
        yes={() => <LiveLearning serviceId={serviceId!} />}
      />
      <CanRoute
        path="child-groups/*"
        perform={perms.childGroups.read}
        yes={() => <ChildGroups serviceFkey={serviceFkey} />}
      />
      {hasFrameworks ? (
        <CanRoute
          path="learning-analysis/*"
          perform={perms.learningAnalysis.read}
          yes={() => <LearningAnalysis serviceFkey={serviceFkey} />}
        />
      ) : null}
      {isPlayground && providerFkey && (
        <CanRoute
          path="learning-frameworks/*"
          perform={perms.learningFrameworks.writeProvider}
          yes={() => <LearningFrameworks providerFkey={providerFkey} />}
        />
      )}
    </Router>
  )
}

RootRouter.displayName = 'RootRouter'

export default RootRouter
