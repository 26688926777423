import React, { useState } from 'react'

import { useMutation } from '@apollo/client'

import { Box, Divider, Dropdown, Flex, hoverMixin, Icon, Image, MainButton, Text } from 'stardust'
import styled from 'styled-components'

import union from '~/assets/images/union.svg'
import Can from '~/components/Authorisation/Can'

import ListItem from '~/components/ListItem'

import { useSelectedServiceFkey } from '~/contexts/Service'

import * as ObservationAnalytics from '~/modules/analytics/observations'
import { learningStories, learningPlans, learningTables, observations } from '~/modules/permissions'
import Create from '~/pages/Observations/components/Create'
import { DELETE_POST } from '~/pages/Observations/mutations'
import { GET_ALL_POSTS } from '~/pages/Observations/queries'
import { HEADER_HEIGHT } from '~/theme'

import ButtonAndLayoutModal from './ButtonAndLayoutModal'

const StyledIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.textActive};
`

const HoverFlex = styled(Flex)`
  cursor: pointer;
  ${hoverMixin};
`

const CreateButton = () => {
  const [isNewPost, setIsNewPost] = useState(false)
  const [postId, setPostId] = useState<number | null>(null)
  const [deletePost] = useMutation(DELETE_POST)
  const serviceFkey = useSelectedServiceFkey()

  const handlePostCreateClick = () => {
    setIsNewPost(true)
    ObservationAnalytics.createObservation()
  }

  const handleCloseModal = () => {
    deletePost({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_ALL_POSTS,
          variables: { serviceFkey },
        },
      ],
      variables: { id: postId },
    })

    setIsNewPost(false)
  }

  const handleSetPostId = (id: number) => {
    if (id) {
      setPostId(id)
    }
  }

  return (
    <>
      <Dropdown
        placement="bottom-start"
        renderButton={({ handleClose }) => (
          <MainButton width={150} onClick={handleClose} height={`calc(${HEADER_HEIGHT} - 16px)`}>
            <Flex justifyContent="center" alignItems="center">
              <Image src={union} m={2} width="15" />
              <Text color="white" fontSize={2} ml={1} fontWeight={500}>
                Create
              </Text>
            </Flex>
          </MainButton>
        )}
        render={() => (
          <Box width={300} maxHeight="max-content">
            <Can perform={observations.write}>
              {(canWrite) =>
                canWrite && (
                  <HoverFlex alignItems="center" onClick={handlePostCreateClick}>
                    <ListItem
                      renderIcon={() => <StyledIcon name="postsNav" />}
                      renderText={() => (
                        <Box>
                          <Text bold mb={1} fontSize={2}>
                            New Post
                            <Text
                              fontSize={1}
                              fontWeight={'lighter'}
                              color={'mediumEmphasis'}
                              mt={2}
                              lineHeight="130%">
                              {`Share updates with peers & families. (Previously known as
                        'Observation')`}
                            </Text>
                          </Text>
                        </Box>
                      )}
                    />
                  </HoverFlex>
                )
              }
            </Can>
            <Divider borderColor="surfacePrimaryBorder" mb={0} />

            <ButtonAndLayoutModal
              documentType={'LEARNING_STORY'}
              iconName={'stories'}
              permission={learningStories.write}
            />
            <ButtonAndLayoutModal
              documentType={'LEARNING_PLAN'}
              iconName={'canvasNav'}
              permission={learningPlans.write}
            />
            <ButtonAndLayoutModal
              documentType={'LEARNING_TABLE'}
              iconName={'tablesNav'}
              permission={learningTables.write}
            />
          </Box>
        )}
      />
      {isNewPost && (
        <Create
          handleCloseModal={handleCloseModal}
          handleCloseModalOnSubmit={() => setIsNewPost(false)}
          setPostIdForDeletion={handleSetPostId}
        />
      )}
    </>
  )
}

CreateButton.displayName = 'CreateButton'

export default CreateButton
