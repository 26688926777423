type ValueOf<T> = T[keyof T]

export interface SpaceDescriptorMap {
  none: string
  xxxsmall: string
  xxsmall: string
  xsmall: string
  small: string
  medium: string
  large: string
  xlarge: string
  xxlarge: string
  xxxlarge: string
}

export type SpaceDescriptor = keyof SpaceDescriptorMap

export type AlignX = 'left' | 'center' | 'right'
export type AlignY = 'top' | 'center' | 'bottom'

export enum Theme {
  home = 'home',
  playground = 'playground',
  webview = 'webview',
  homeWebview = 'homeWebview',
}

export type ThemeName = ValueOf<Theme>

export interface AppTheme {
  name: ValueOf<Theme>
  colors: Record<string, string>
  maxWidths: string[]
  opacity: number[]
  radii: string[]
  shadows: Record<string, string>
  space: number[]
  zIndicies: number[]
  breakpoints: string[]
  fontFamily: string
  fontWeights: {
    regular: number
    bold: number
  }
  fontSizes: number[]
}
