import tokens from '../tokens'

import common from './common'
import { Theme } from './types'

const themeColors = {
  // Theme base
  primary: tokens.colors.nebulaBlue5,
  primaryDark: tokens.colors.nebulaBlue9,
  primaryLight: tokens.colors.nebulaBlue0,

  // Text
  textPrimary: tokens.colors.cosmicShade0,
  textPrimaryLow: tokens.colors.cosmicShade1,
  textPrimaryMedium: tokens.colors.cosmicShade3,

  surfacePrimary: tokens.colors.cosmicShade7,
  surfacePrimaryBorder: tokens.colors.cosmicShade7,
  surfaceSecondary: tokens.colors.cosmicShade5,

  fileEmbedBtnColor: tokens.colors.hullOrange6,
  fileEmbedTxtColor: tokens.colors.hullOrange6,

  //Components
  avatarBackground: tokens.colors.nebulaBlue0,
  avatarBorder: tokens.colors.nebulaBlue,
  avatarText: tokens.colors.nebulaBlue9,
  borderColor: tokens.colors.nebulaBlue,
  dividerBackground: tokens.colors.nebulaBlue0,
  hoverBackground: tokens.colors.nebulaBlue0,

  mentionedText: tokens.colors.cosmicShade5,

  selection: tokens.colors.hullOrange0,
  textActive: tokens.colors.hullOrange,
  textLink: tokens.colors.hullOrange,

  loadingBackground: tokens.colors.cosmicShade9,
  iconText: tokens.colors.cosmicShade10,
}

export default {
  name: Theme.webview,
  ...tokens,
  colors: {
    ...tokens.colors,
    ...common.colors,
    ...themeColors,
  },
}
