import React, { useState, useEffect } from 'react'

import { useQuery } from '@apollo/client'
import { Divider, MainButton, SecondaryButton, Box, Flex, Icon, Text, FlexItem } from 'stardust'

import styled from 'styled-components'

import Modal from '~/components/Modal'
import { useSelectedServiceFkey } from '~/contexts/Service'

import colors from '~/ui-components/tokens/colors'

import { GET_FRAMEWORKS } from '../../queries'

import { FieldProps } from '../../types'

import FrameworkSelector from './LearningOutcomes/FrameworkSelector'
import FrameworksList from './LearningOutcomes/FrameworksList'
import OutcomeSearch from './LearningOutcomes/OutcomeSearch'

import PostLinksNoSearchResults from './PostLinksNoSearchResults'

const PAGE_HEIGHT = '100vh'
const MODAL_VERTICAL_SPACING = '40px'
const MODAL_HEADER_HEIGHT = '100px'
const MODAL_FOOTER_HEIGHT = '121px'
const MODAL_BODY_HEIGHT = `calc(${PAGE_HEIGHT} - ${MODAL_VERTICAL_SPACING} - ${MODAL_HEADER_HEIGHT} - ${MODAL_FOOTER_HEIGHT})`
const FILTER_CONTAINER_HEIGHT = '100px'

const S = {
  Icon: styled(Icon)`
    border-radius: 50%;
    padding: 10px;
  `,
  CloseIcon: styled(Icon)`
    border-radius: 50%;
    padding: 10px;
    background-color: ${colors.cosmicShade4};
    &:hover {
      background-color: ${colors.cosmicShade6};
      transition: background-color 0.2s;
    }
  `,
  MainButton: styled(MainButton)`
    height: 48px;
    padding: 12px 24px 12px 24px;
  `,
  SecondaryButton: styled(SecondaryButton)`
    height: 48px;
    padding: 12px 24px 12px 24px;
  `,
  ExpandButtons: styled(Flex)`
    border: 1px solid;
    border-color: ${colors.cosmicShade6};
    background-color: ${colors.cosmicShade0};
    border-radius: 16px;
    cursor: pointer;
    margin-left: 8px;
    padding-left: 12px;
    padding-right: 14px;
    height: 50px;
    align-items: center;
  `,
  ScrollableFrameworkList: styled(Box)`
    max-height: calc(${MODAL_BODY_HEIGHT} - ${FILTER_CONTAINER_HEIGHT});
  `,
  Top: styled(Flex)`
    background-color: ${colors.cosmicShade0};
    z-index: 100;
    position: sticky;
    top: 0;
    overflow-y: 0;
  `,
  Bottom: styled(Box)`
    background-color: ${colors.cosmicShade0};
    z-index: 100;
    position: sticky;
    bottom: 0;
    overflow-y: 0;
  `,
}

interface LearningOutcomesProps extends FieldProps {
  learningOutcomes: Playground.LearningFrameworkOutcome[]
  onCloseLearningOutcomesModal: () => void
}

interface filteredFrameworksProps {
  searchTerm?: string | null
  selectedFramework?: Playground.LearningFramework | null
  frameworks: Playground.LearningFramework[]
}

interface filterProps {
  searchTerm?: string | null
  selectedFramework?: Playground.LearningFramework | null
}

const LearningOutcomesModal = ({
  learningOutcomes,
  onCloseLearningOutcomesModal,
  onChange,
}: LearningOutcomesProps) => {
  const serviceFkey = useSelectedServiceFkey()
  const [expandAll, setExpandAll] = useState(false)
  const [showSelectedOnly, setShowSelectedOnly] = useState(false)
  const [clickedOutcomes, setClickedOutcomes] = useState<Playground.LearningFrameworkOutcome[]>([])
  const [selectedFramework, setSelectedFramework] = useState<Nullable<Playground.LearningFramework>>(null)
  const [searchTerm, setSearchTerm] = useState<string>('')

  const handleDone = () => {
    onChange({
      name: 'learningOutcomes',
      value: clickedOutcomes,
    })
    onCloseLearningOutcomesModal()
  }

  useEffect(() => {
    setClickedOutcomes(learningOutcomes)
  }, [learningOutcomes])

  const handleSearchOutcome = (searchTerm: string) => {
    setSearchTerm(searchTerm)
  }

  const handleSelectFramework = (framework: Playground.LearningFramework | null) => {
    setSelectedFramework(framework)
  }

  const handleOutcomeClick = (outcome: Playground.LearningFrameworkOutcome) => {
    setClickedOutcomes((prevOutcome) => {
      const isAlreadySelected = prevOutcome.some((selectedOutcome) => selectedOutcome.id === outcome.id)
      return isAlreadySelected
        ? prevOutcome.filter((selectedOutcome) => selectedOutcome.id !== outcome.id)
        : [...prevOutcome, outcome]
    })
  }
  const { data, error, loading } = useQuery(GET_FRAMEWORKS, {
    variables: { serviceFkey },
  })

  if (error) {
    throw error
  }

  if (loading) {
    return null
  }

  const allFrameworks = data.service.learningFrameworks.filter(
    (framework: Playground.LearningFramework) => framework.archivedAt == null
  )

  const filterFrameworksCombined = ({
    frameworks,
    searchTerm,
    selectedFramework,
  }: filteredFrameworksProps & filterProps) => {
    const filterByOutcomes = (frameworksToFilter: Playground.LearningFramework[]) => {
      const outcomeIdsSet = new Set(clickedOutcomes.map((outcome) => outcome.id))

      return frameworksToFilter
        .map((framework) => ({
          ...framework,
          outcomes: framework.outcomes.filter((outcome) => outcomeIdsSet.has(outcome.id)),
        }))
        .filter((framework) => framework.outcomes.length > 0)
    }

    const filterById = (framework: Playground.LearningFramework) =>
      selectedFramework ? framework.id === selectedFramework.id : true

    let filteredFrameworks: Playground.LearningFramework[] = showSelectedOnly
      ? filterByOutcomes(frameworks)
      : frameworks

    filteredFrameworks = filteredFrameworks
      .filter(filterById)
      .map((framework) => ({
        ...framework,
        outcomes: framework.outcomes.filter(
          (outcome) => !searchTerm || outcome.name.toLowerCase().includes(searchTerm.toLowerCase())
        ),
      }))
      .filter((framework) => framework.outcomes.length > 0)

    return filteredFrameworks
  }
  const checkSelectedOutcomeCountInFramework = () => {
    const outcomeCountMap: Record<number, number> = {}

    allFrameworks.forEach((framework: Playground.LearningFramework) => {
      const selectedOutcomesCount = framework.outcomes.reduce(
        (count: number, outcome: Playground.LearningFrameworkOutcome) =>
          clickedOutcomes.some((learningOutcome) => learningOutcome.id === outcome.id) ? count + 1 : count,
        0
      )

      outcomeCountMap[framework.id] = selectedOutcomesCount
    })

    return outcomeCountMap
  }

  const checkCount = (id: number) => {
    const outcomesSelectedPerFramework = checkSelectedOutcomeCountInFramework()
    return outcomesSelectedPerFramework[id]
  }

  const filterFrameworkOptions = filterFrameworksCombined({
    frameworks: allFrameworks,
    searchTerm: searchTerm,
  })
  const filteredFrameworks = filterFrameworksCombined({
    frameworks: allFrameworks,
    searchTerm: searchTerm,
    selectedFramework: selectedFramework,
  })

  return (
    <Modal open dontRenderCloseButton onClose={onCloseLearningOutcomesModal}>
      <Box width="922px" bg={colors.cosmicShade2}>
        <Flex width="922px" flexDirection="column">
          <S.Top
            p="24px"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            bg={colors.cosmicShade0}>
            <Flex alignItems="center">
              <Icon size={45} name="AddOutcomes" />
              <Flex flexDirection="column">
                <Text mt={1} ml={2} fontSize="24px" fontWeight="bold">
                  Tag Learning Outcomes
                </Text>
                <Text fontSize={2} fontWeight={400} mt={2} ml={2}>
                  {"Select a curriculum framework that suits your program's goals."}
                </Text>
              </Flex>
            </Flex>
            <S.CloseIcon size={40} name="close" onClick={onCloseLearningOutcomesModal} />
          </S.Top>
          <Flex flexDirection="column" px="24px" pb="24px" overflowY="auto">
            <>
              <Flex px="24px" pt="24px" flexDirection="column" bg={colors.cosmicShade12} borderRadius="16px">
                <Text fontSize={2} fontWeight={700} mb={3} ml={1}>
                  Search
                </Text>
                <OutcomeSearch handleSearchOutcome={handleSearchOutcome} searchTerm={searchTerm} />
                <Divider borderColor={colors.cosmicShade6} mb={3} />
                <Text fontSize={2} fontWeight={700} mb={3} ml={1}>
                  Framework
                </Text>
                <Box position="relative">
                  <FrameworkSelector
                    frameworks={filterFrameworkOptions}
                    handleSelectFramework={handleSelectFramework}
                    selectedFramework={selectedFramework}
                  />
                </Box>
                <Text fontSize={2} fontWeight={700} ml={1}>
                  Learning Outcomes
                </Text>
                <FlexItem mb={2} ml="auto">
                  <Flex>
                    {clickedOutcomes.length > 0 ? (
                      <S.ExpandButtons
                        onClick={() => {
                          setShowSelectedOnly(!showSelectedOnly)
                          setExpandAll(!showSelectedOnly)
                        }}>
                        <Icon
                          color={colors.cosmicShade15}
                          name={showSelectedOnly ? 'arrowDown' : 'eye'}
                          size={12}
                          mr={2}
                        />
                        <Text fontSize="14px">{showSelectedOnly ? 'View All' : 'View selected only'}</Text>
                      </S.ExpandButtons>
                    ) : null}
                    <S.ExpandButtons
                      onClick={() => {
                        setExpandAll(!expandAll)
                      }}>
                      <Icon
                        color={colors.cosmicShade15}
                        name={expandAll ? 'arrowUp' : 'arrowDown'}
                        size={12}
                        mr={2}
                      />
                      <Text fontSize="14px">{expandAll ? 'Collapse All' : 'Expand All'}</Text>
                    </S.ExpandButtons>
                  </Flex>
                </FlexItem>
                <S.ScrollableFrameworkList pb={3} overflowY="auto">
                  {filteredFrameworks?.map((framework: Playground.LearningFramework) => {
                    return (
                      <FrameworksList
                        expandAll={expandAll}
                        framework={framework}
                        selectedCount={checkCount(framework.id)}
                        key={framework.id}
                        onOutcomeClick={handleOutcomeClick}
                        clickedOutcomes={clickedOutcomes}
                      />
                    )
                  })}
                  {filteredFrameworks?.length == 0 && (
                    <PostLinksNoSearchResults displayText="There are no Learning Outcomes" />
                  )}
                </S.ScrollableFrameworkList>
              </Flex>
            </>
          </Flex>
          <S.Bottom>
            <Divider borderColor="lightGrey" />
            <Flex alignItems="flex-end" px="24px" pb="24px" pt={2} width="100%">
              <Box flex={1}>
                <S.SecondaryButton outline onClick={() => onCloseLearningOutcomesModal()}>
                  Back
                </S.SecondaryButton>
              </Box>
              <Flex flex={1} ml={2} flexDirection="column" alignItems="flex-end">
                <Text mt={2} mb={3} mr={5} fontSize={2} bold color={colors.nebulaBlue4}>
                  {`Total ${clickedOutcomes.length} Selected`}
                </Text>
                <S.MainButton outline onClick={handleDone}>
                  Add Learning Outcomes
                </S.MainButton>
              </Flex>
            </Flex>
          </S.Bottom>
        </Flex>
      </Box>
    </Modal>
  )
}

LearningOutcomesModal.displayName = 'LearningOutcomesModal'
export default React.memo(LearningOutcomesModal)
