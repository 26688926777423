export interface PostAttachmentFile {
  id: string
  path: string
  url: string
  coverUrl: string
  mimeType: string
  thumbUrl?: string
}

export interface PostAttachmentFileProps {
  file: Playground.MediaItem
  forPostCreation?: boolean
}

export interface PostAttachmentFileWithDetachProps extends PostAttachmentFileProps {
  onDetachClickCallback: (id: number) => void
}

export enum AttachmentType {
  DOCUMENT,
  AUDIO,
  MEDIA,
}
