import gql from 'graphql-tag'

export const GET_LAYOUTS = gql`
  query Layouts($type: TemplateType!, $serviceFkey: String!) {
    service(fkey: $serviceFkey) {
      id
      templates(type: $type) {
        id
        data
        global
        providerFkey
        serviceFkey
        title
      }
    }
  }
`
