// Adapted from https://docs.mux.com/guides/video/play-your-videos
import React, { useEffect, useRef } from 'react'

import Hls from 'hls.js'

interface HlsVideoProps {
  src: string
  coverUrl?: string
}

const HlsVideo = ({ src, coverUrl }: HlsVideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    let hls: Hls | undefined
    if (videoRef.current) {
      const video = videoRef.current

      if (video && video.canPlayType('application/vnd.apple.mpegurl')) {
        // Some browers (safari and ie edge) support HLS natively
        video.src = src
      } else if (Hls.isSupported()) {
        // This will run in all other modern browsers
        hls = new Hls()
        hls.loadSource(src)
        hls.attachMedia(video)
      } else {
        console.error("This is a legacy browser that doesn't support MSE")
      }
    }

    return () => {
      if (hls) {
        hls.destroy()
      }
    }
  }, [videoRef, src])

  // eslint-disable-next-line jsx-a11y/media-has-caption
  return <video controls poster={coverUrl} ref={videoRef} style={{ width: '100%' }} />
}

export default React.memo(HlsVideo)
