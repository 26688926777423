import React from 'react'

import { Box, StencilCircle as Circle, Flex, StencilLine as Line } from 'stardust'

const Loading = () => (
  <Flex alignItems="center" px={3} py={2}>
    <Circle width="60px" height="60px" />
    <Box flexDirection="column" width={3 / 5} pl={3}>
      <Line width={3 / 5} mb={2} />
      <Line width={2 / 5} />
    </Box>
  </Flex>
)

export default React.memo(Loading)
