function groupObjectsBy<T extends { [key: string]: any }>(collection: T[], key: string) {
  return collection.reduce((acc, item) => {
    const itemKey = item[key]
    const match = acc[itemKey] || []
    acc[itemKey] = [...match, item]
    return acc
  }, {} as Record<any, T[]>)
}

export default groupObjectsBy
