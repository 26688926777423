import * as R from 'ramda'

import { isNotNilOrEmpty } from '~/utils'

import { VERSION } from '../version'

import transformNodes from './transformNodes'

const transformData = R.pick(['id', 'nodeIds', 'nodes', 'properties', 'title'])

/* 
  Handles conversion of plan-editor-v-0 to plan-editor-v-2
  'value' is Playground.ArticleContent 
*/
const transformFromSave = ({ value, defaultTitle }) => {
  const title = R.propOr(defaultTitle, 'title', value)

  if (isNotNilOrEmpty(value)) {
    const { id, generatedWith } = value
    const data = JSON.parse(value.data)

    if (isNotNilOrEmpty(data)) {
      const result = generatedWith === VERSION ? data : transformNodes(R.propOr([], 'nodes', data))

      return transformData({ ...result, id, title })
    }
  }

  return {
    id: null,
    nodeIds: [],
    nodes: {},
    title,
    properties: { background: '#FFFFFFFF' },
  }
}

export default transformFromSave
