import { StyledComponent } from 'styled-components'
import { LineHeightProps } from 'styled-system'

import { Props, TextBase } from './Text'

const lineHeight = (x = 1) => `${24 * x}px`
const anotherLineHeight = (x = 0) => `${24 + x}px`

type BaseProps = LineHeightProps & Props

const Heading = TextBase as typeof TextBase & {
  h1: StyledComponent<'h1', any, BaseProps, never>
  h2: StyledComponent<'h2', any, BaseProps, never>
  h3: StyledComponent<'h3', any, BaseProps, never>
  h4: StyledComponent<'h4', any, BaseProps, never>
  h5: StyledComponent<'h5', any, BaseProps, never>
  h6: StyledComponent<'h6', any, BaseProps, never>
}

const defaultHeadingProps = {
  color: 'textPrimary',
  lineHeight: lineHeight(2.67),
}

Heading.displayName = 'Heading'
Heading.defaultProps = {
  bold: false,
  color: 'textPrimary',
  m: 0,
  // regular: true,
}

Heading.h1 = Heading.withComponent('h1')
Heading.h1.defaultProps = {
  bold: true,
  fontSize: 7,
  mb: lineHeight(1.35),
  mt: lineHeight(2),
}

Heading.h2 = Heading.withComponent('h2')
Heading.h2.defaultProps = {
  ...defaultHeadingProps,
  bold: true,
  fontSize: 6,
  mb: lineHeight(1),
  mt: lineHeight(1.35),
}

Heading.h3 = Heading.withComponent('h3')
Heading.h3.defaultProps = {
  ...defaultHeadingProps,
  fontSize: 5,
  mb: lineHeight(1),
  mt: anotherLineHeight(8),
  // regular: true,
}

Heading.h4 = Heading.withComponent('h4')
Heading.h4.defaultProps = {
  ...defaultHeadingProps,
  fontSize: 4,
  mb: lineHeight(1),
  mt: lineHeight(1.35),
  // regular: true,
}

Heading.h5 = Heading.withComponent('h5')
Heading.h5.defaultProps = {
  ...defaultHeadingProps,
  fontSize: 3,
  mb: lineHeight(0.85),
  mt: lineHeight(1.15),
  // regular: true,
}

Heading.h6 = Heading.withComponent('h6')
Heading.h6.defaultProps = {
  ...defaultHeadingProps,
  fontSize: 2,
  mb: lineHeight(0.85),
  mt: lineHeight(1.15),
  // regular: true,
}

export default Heading
