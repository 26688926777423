/**
 * Splits the incoming media list into two types; one for media tiles and one for
 * attachment types and returns those two as an array containing two arrays in their
 * respective order.
 *
 * @param media The list of media to be split up
 * @returns an array of two containing the media type files and the attachment
 * type files.
 */
export const splitMediaTypes = (media: Playground.MediaItem[]) => {
  const mediaTypes: Playground.MediaItem[] = []
  const attachmentTypes: Playground.MediaItem[] = []

  for (let i = 0; i < media.length; i++) {
    const currentMimeType = media[i].mimeType
    // The file type is a media for display within the tiles.
    if (currentMimeType.includes('image/') || currentMimeType.includes('video/')) {
      mediaTypes.push(media[i])
    }
    // The file type is for the attachment list on preview.
    else {
      attachmentTypes.push(media[i])
    }
  }

  return [mediaTypes, attachmentTypes]
}

export const bytesToKilobytes = (bytes: number) => Math.round((bytes / 1024) * 100) / 100
export const bytesToMegabytes = (bytes: number) => Math.round((bytes / 1024 / 1024) * 100) / 100
