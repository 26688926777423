import React from 'react'

import { Box, Text } from 'stardust'
import styled from 'styled-components'

import { isHomeWebview } from '~/config'
import colors from '~/ui-components/tokens/colors'

interface Props {
  learningOutcome?: Playground.LearningFrameworkOutcome
}

const Container = styled(Box)`
  white-space: wrap;
`

const LearningOutcomePill = ({ learningOutcome }: Props) => {
  const background = isHomeWebview ? colors.cosmicShade10 : colors.nebulaBlue0
  const border = isHomeWebview ? colors.hullOrange3 : colors.nebulaBlue3
  const textColor = isHomeWebview ? colors.cosmicShade0 : 'black'

  return (
    <Container width="100%" bg={background} border={`2px solid ${border}`} borderRadius="16px" p={3}>
      <Text fontSize={1} fontWeight="bold" color={textColor}>
        {learningOutcome?.frameworkName}: {learningOutcome?.categoryName}
      </Text>
      <Text fontSize={1} mt={1} lineHeight="150%" color={textColor}>
        {learningOutcome?.name}
      </Text>
    </Container>
  )
}

LearningOutcomePill.displayName = 'LearningOutcomePill'

export default React.memo(LearningOutcomePill)
