import React, { useState } from 'react'

import { Box, Divider, Flex, FlexItem, Icon, Text } from 'stardust'
import styled from 'styled-components'

import { isHomeWebview, useIsPostReflectionsEnabled } from '~/config'
import { isHome } from '~/config'
import colors from '~/ui-components/tokens/colors'

import ChildGrid from './Tags/ChildGrid'
import FuturePlans from './Tags/FuturePlans'
import LearningOutcomeGrid from './Tags/LearningOutcomeGrid'
import LinksGrid from './Tags/LinksGrid'
import Reflections from './Tags/Reflections'

const S = {
  Box: styled(Box)`
    background-color: ${(props) => props.backgroundColor};
    border-radius: 40px;
    padding: 12px 8px;
    &:hover {
      background-color: ${(props) => props.hoverColor};
      transition: background-color 0.2s;
    }
  `,
  Divider: styled(Divider)`
    border-bottom-width: 2px;
  `,
}

const HorizontalScroll = styled(Box)`
  overflow-x: auto;
  white-space: nowrap;
`

interface TagProps {
  taggedChildren?: Playground.SimpleChild[]
  learningOutcomes?: Playground.LearningFrameworkOutcome[]
  links?: Playground.Document[]
  futurePlans?: string
  planningDate?: string
  reflections?: Playground.Reflection[]
  postId?: Nullable<number>
}

interface ButtonProps {
  amount?: number
  tagType: string
}

const Tags = ({
  taggedChildren,
  learningOutcomes,
  links,
  futurePlans,
  planningDate,
  reflections,
  postId,
}: TagProps) => {
  const [selectedTag, setSelectedTag] = useState<string>('')
  const isPostReglectionsEnabled = useIsPostReflectionsEnabled()
  const homeOrPlaygroundColors = isHome ? colors.hullOrange6 : colors.nebulaBlue6
  const isHomeWebviewBackground = isHomeWebview ? colors.cosmicShade10 : colors.cosmicShade4
  const isHomeWebviewText = isHomeWebview ? colors.cosmicShade0 : colors.cosmicShade10

  const TagButtons = ({ amount, tagType }: ButtonProps) => {
    const checkTagType = selectedTag === tagType
    const backgroundColor = checkTagType ? homeOrPlaygroundColors : isHomeWebviewBackground
    const hoverColor = checkTagType ? homeOrPlaygroundColors : colors.cosmicShade18
    const textColor = checkTagType ? colors.cosmicShade0 : isHomeWebviewText
    const dividerColor = checkTagType ? 'primary' : colors.cosmicShade4
    return (
      <FlexItem
        onClick={() => {
          setSelectedTag(tagType)
        }}>
        <S.Box
          backgroundColor={backgroundColor}
          hoverColor={hoverColor}
          width="max-content"
          cursor="pointer"
          ml={1}
          mr={1}>
          <Flex>
            <Text fontSize={1} color={textColor} fontWeight="bold" mr={1}>
              {amount}
            </Text>
            <Text fontSize={1} color={textColor}>
              {tagType}
            </Text>
          </Flex>
        </S.Box>
        {selectedTag ? <S.Divider borderColor={dividerColor} mt={2} width="120%" /> : null}
      </FlexItem>
    )
  }

  const HideButton = () => (
    <Flex justifyContent="flex-end">
      <Box
        cursor="pointer"
        onClick={() => {
          setSelectedTag('')
        }}>
        <Text color="primary" fontSize="12px" lineHeight="16px">
          Hide details
        </Text>
      </Box>
    </Flex>
  )

  return (
    <Box mt={2}>
      {selectedTag ? <HideButton /> : null}
      <HorizontalScroll>
        <Flex mt={2} alignItems="flex-end">
          <FlexItem>
            <Icon mr={2} mb={selectedTag ? 3 : 2} name="tag" color="primary" />
            {selectedTag ? <S.Divider borderColor={colors.cosmicShade4} mt={2} width="120%" /> : null}
          </FlexItem>
          {taggedChildren && taggedChildren.length > 0 && (
            <TagButtons amount={taggedChildren?.length} tagType="Children" />
          )}
          {learningOutcomes && learningOutcomes.length > 0 && (
            <TagButtons amount={learningOutcomes?.length} tagType="Learning Outcomes" />
          )}
          {links && links.length > 0 && <TagButtons amount={links?.length} tagType="Links" />}
          {(futurePlans || planningDate) && <TagButtons tagType="Future Plans" />}
          {isPostReglectionsEnabled ? <TagButtons tagType="Reflections" /> : null}

          {selectedTag ? (
            <FlexItem flexGrow={1}>
              <S.Divider borderColor={colors.cosmicShade4} />
            </FlexItem>
          ) : null}
        </Flex>
      </HorizontalScroll>
      <ChildGrid tagType={selectedTag} taggedChildren={taggedChildren} />
      <LearningOutcomeGrid tagType={selectedTag} learningOutcomes={learningOutcomes} />
      <LinksGrid tagType={selectedTag} links={links} />
      <FuturePlans tagType={selectedTag} futurePlans={futurePlans} planningDate={planningDate} />
      <Reflections tagType={selectedTag} reflections={reflections} postId={postId} />
    </Box>
  )
}

Tags.displayName = 'Tags'

export default React.memo(Tags)
