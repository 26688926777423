import React from 'react'

import * as R from 'ramda'
import { Absolute, Box } from 'stardust'
import styled from 'styled-components'

import { isChild, isChildren, isContent, isLearningDocument } from '../core/nodeTypes'
import * as selectors from '../core/selectors'
import types from '../core/shapeTypes'
import Read from '../Editor/Read'
import * as shapes from '../shapes'

const propFrom = R.flip(R.prop)

const path = (props) => `${props.path}`

const Clip = styled(Box)`
  ${path};
  cursor: initial;
`

Clip.defaultProps = {
  width: '100%',
  height: '100%',
}

import Child from '../shapes/Child'
import Children from '../shapes/Children'
import Document from '../shapes/Document'

// AP: props is the node we are attempting to render
const ShapeContainer = ({ background, path, ...props }) => {
  const ref = selectors.ref.get(props) // get the "ref" type from the "data" object

  let Component = () => <></>

  if (isLearningDocument(ref)) {
    Component = Document
  } else if (isContent(ref)) {
    Component = Read
  } else if (isChild(ref)) {
    Component = Child
  } else if (isChildren(ref)) {
    Component = Children
  }

  return (
    <Clip path={path}>
      <Absolute style={{ background }} bottom={0} height="100%" width={1} left={0} right={0} top={0} />

      <Component {...props} readOnly={true} />
    </Clip>
  )
}

const Shape = (props) => {
  const kind = selectors.propKind.get(props) // get the 'kind' of shape i.e. square, circle, etc.
  const background = selectors.background.get(props) // get the bg color of shape

  /**
   * *** 🚨🚨🚨 Handle legacy shapes  🚨🚨🚨***
   */
  const path = R.pipe(
    R.cond([
      [R.equals(types['leaf-left']), R.always(shapes.leafLeft)],
      [R.equals(types['leaf-right']), R.always(shapes.leafRight)],
      [R.T, propFrom(shapes)],
    ])
  )(kind)

  return <ShapeContainer background={background} path={path} {...props} />
}

Shape.displayName = 'Shape'
export default Shape
