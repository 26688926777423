import React from 'react'

import { Box, Text } from 'stardust'

interface Props {
  caption: string
}

const Caption = ({ caption }: Props) => {
  const texts = caption.split('\n')
  return (
    <Box borderBottom={1} borderColor="surfacePrimaryBorder" pr={3} py={3} data-test="document-caption">
      {texts.map((text, idx) => (
        <Text key={`desc_${idx}`} lineHeight={1.3} medium>
          {text}
          <br />
        </Text>
      ))}
    </Box>
  )
}

Caption.displayName = 'DocumentRendererCaption'

export default React.memo(Caption)
