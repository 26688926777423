const palette = {
  cosmicShade0: 'white',
  cosmicShade1: 'rgba(255, 255, 255, 0.65)',
  cosmicShade2: 'rgba(255, 255, 255, 0.38)',
  cosmicShade3: 'rgb(250, 250, 250)',
  cosmicShade4: '#F1F1F1',
  cosmicShade5: '#3B3C3F',
  cosmicShade6: 'rgba(0, 0, 0, .12)',
  cosmicShade7: 'rgba(0, 0, 0, .38)',
  cosmicShade8: 'rgba(0, 0, 0, .65)',
  cosmicShade9: 'rgba(0, 0, 0, .8)',
  cosmicShade10: '#424242',
  cosmicShade11: '#686868',
  cosmicShade12: '#FAFAFA',
  cosmicShade13: '#2C2C2D',
  cosmicShade14: '#E2E2E2',
  cosmicShade15: '#252525',
  cosmicShade16: '#D0D0D0',
  cosmicShade17: '#BDBDBD',
  cosmicShade18: '#FCFCFC',
  cosmicShade19: '#f6f6f6',
  cosmicShade20: '#A0A0A0',
}

export default {
  ...palette,
  cosmicShade: palette.cosmicShade9,
}
