import React from 'react'

import { Absolute, Box, Fab, Flex, Icon, Text } from 'stardust'
import styled from 'styled-components'

interface Props {
  scale: string
  zoomIn: () => void
  zoomMax: () => void
  zoomOut: () => void
}

const Container = styled(Absolute)`
  position: fixed;
  z-index: 20;
`

const ZoomControls = ({ scale, zoomIn, zoomMax, zoomOut }: Props) => {
  return (
    <Container bottom="25px" right="25px">
      <Flex screenOnly>
        <Fab bg="white" onClick={zoomMax}>
          <Text bold fontSize={0}>
            {'100%'}
          </Text>
        </Fab>

        <Box bg="white" borderRadius="360px" boxShadow="2dp" ml={2}>
          <Flex alignItems="center" height="42px" p={1}>
            <Fab bg="cosmicShade6" boxShadow="none" height={32} width={32} onClick={zoomOut}>
              <Icon color="black" name="zoomOut" />
            </Fab>

            <Text fontSize={0} mx={2} minWidth="32px" textAlign="center">
              {scale}
            </Text>

            <Fab bg="cosmicShade6" boxShadow="none" height={32} width={32} onClick={zoomIn}>
              <Icon color="black" name="zoomIn" />
            </Fab>
          </Flex>
        </Box>
      </Flex>
    </Container>
  )
}

ZoomControls.displayName = 'ZoomControls'

export default React.memo(ZoomControls)
