import * as R from 'ramda'

import * as refKinds from './refKinds'

export const actions = {
  NODE: {
    BATCH_TAGGED_ADD: 'BATCH_TAGGED_ADD',
    DATA_CHANGE: 'DATA_CHANGE',
    DRAG_END: 'DRAG_END',
    PROPERTIES_CHANGE: 'PROPERTIES_CHANGE',
    SHAPE_CHANGE: 'SHAPE_CHANGE',
    SHAPE: {
      KIND_CHANGE: 'KIND_CHANGE',
      REF_CHANGE: 'REF_CHANGE',
    },
    STICKER_CHANGE: 'STICKER_CHANGE',
    TAGGED_ADD: 'TAGGED_ADD',
    TAGGED_REMOVE: 'TAGGED_REMOVE',
  },
  PLANNER: {
    BACKGROUND_CHANGE: 'BACKGROUND_CHANGE',
    CLONING_CHANGE: 'CLONING_CHANGE',
    DRAGGING_CHANGE: 'DRAGGING_CHANGE',
    NODES_ADD: 'NODES_ADD',
    NODES_CLONE: 'NODES_CLONE',
    NODES_PROPERTIES_CHANGE: 'NODES_PROPERTIES_CHANGE',
    NODES_REMOVE: 'NODES_REMOVE',
    PREVIEW_CHANGE: 'PREVIEW_CHANGE',
    RESIZING_CHANGE: 'RESIZING_CHANGE',
    SELECTION_ADD: 'SELECTION_ADD',
    SELECTION_REMOVE: 'SELECTION_REMOVE',
    TITLE_CHANGE: 'TITLE_CHANGE',
    TRANSFORM_CHANGE: 'TRANSFORM_CHANGE',
    UNTAG_CHILDREN: 'UNTAG_CHILDREN',
  },
  EDIT: {
    NODE_CHANGE: 'NODE_CHANGE',
    VALUE_CHANGE: 'VALUE_CHANGE',
  },
  PLANNER_CHANGE: 'PLANNER_CHANGE',
  REDO: 'REDO',
  UNDO: 'UNDO',
}

const action = (type, payload) => ({ type, payload })

export const undo = () => action(actions.UNDO)
export const redo = () => action(actions.REDO)

export const nodeBatchTaggedAdd = (data) => action(actions.NODE.BATCH_TAGGED_ADD, data)

export const nodePropertiesChange = (properties) => action(actions.NODE.PROPERTIES_CHANGE, properties)

export const nodeDataChange = (data) => action(actions.NODE.DATA_CHANGE, data)

export const nodeShapeChange = (kind) => nodePropertiesChange({ kind })

export const nodeStickerChange = (refId) => action(actions.NODE.STICKER_CHANGE, { refId })

export const nodeShapeRefChange = (data) => action(actions.NODE.SHAPE.REF_CHANGE, data)

export const nodeShapeChildChange = (refId, refData) => {
  const ref = refKinds.child
  return nodeDataChange({ refId, refData, ref })
}

export const nodeShapeDocumentChange = (data) => nodeDataChange(data)

export const nodeDragEnd = (id, point) => action(actions.NODE.DRAG_END, { id, point, isDragging: false })

export const nodeTaggedAdd = (entity) => action(actions.NODE.TAGGED_ADD, { entity })

export const nodeTaggedRemove = (entity) => action(actions.NODE.TAGGED_REMOVE, { entity })

export const plannerChange = (planner) => action(actions.PLANNER_CHANGE, planner)

export const plannerNodesPropertiesChange = (properties) =>
  action(actions.PLANNER.NODES_PROPERTIES_CHANGE, properties)

export const plannerNodesAdd = (node) => action(actions.PLANNER.NODES_ADD, { node })

export const plannerNodesRemove = (selection) => action(actions.PLANNER.NODES_REMOVE, { selection })

export const plannerDropNode = R.curry((data, node) => R.pipe(R.mergeDeepLeft(data), plannerNodesAdd)(node))

export const plannerUntagChildren = (childFkeys) => action(actions.PLANNER.UNTAG_CHILDREN, { childFkeys })

export const plannerPreviewChange = (preview) => action(actions.PLANNER.PREVIEW_CHANGE, { preview })

export const plannerSelectionAdd = (selection) => action(actions.PLANNER.SELECTION_ADD, { selection })

export const plannerSelectionRemove = (selection) => action(actions.PLANNER.SELECTION_REMOVE, { selection })

export const plannerBackgroundChange = (background) =>
  action(actions.PLANNER.BACKGROUND_CHANGE, { background })

export const plannerCloningChange = (isCloning) => action(actions.PLANNER.CLONING_CHANGE, { isCloning })

export const plannerDraggingChange = (id, isDragging) =>
  action(actions.PLANNER.DRAGGING_CHANGE, { id, isDragging })

export const plannerResizingChange = (isResizing) => action(actions.PLANNER.RESIZING_CHANGE, { isResizing })

export const plannerTransformChange = (transform) => action(actions.PLANNER.TRANSFORM_CHANGE, transform)

export const plannerNodesClone = (direction) => action(actions.PLANNER.NODES_CLONE, direction)

export const plannerTitleChange = (title) => action(actions.PLANNER.TITLE_CHANGE, title)

export const editNodeChange = (id) => action(actions.EDIT.NODE_CHANGE, id)

export const editValueChange = (value) => action(actions.EDIT.VALUE_CHANGE, value)
