import * as R from 'ramda'
import { Block } from 'slate'

import alignmentsPlugin from '../plugins/alignments'
import childrenPlugin from '../plugins/children'
import contentPlugin from '../plugins/content'
import formattingPlugin from '../plugins/formatting'
import headingsPlugin from '../plugins/headings'
import { linksPlugin, linksSchema } from '../plugins/links'
import listsPlugin from '../plugins/lists'
import { mentionsPlugin, mentionsSchema } from '../plugins/mentions'
import momentsPlugin from '../plugins/moments'
import observationsPlugin from '../plugins/observations'
import { outcomesPlugin, outcomesSchema } from '../plugins/outcomes'
import paragraphsPlugin from '../plugins/paragraphs'
import plansPlugin from '../plugins/plans'
import roomsPlugin from '../plugins/rooms'
import storiesPlugin from '../plugins/stories'
import tablesPlugin from '../plugins/tables'
import versionPlugin from '../plugins/version'

const normalizeCustomBlockTypes = (editor, { code, node, index }) => {
  if (code === 'next_sibling_type_invalid') {
    const block = Block.create('paragraph')

    editor.insertNodeByKey(node.key, index + 1, block)
  }
}

const inlineSchema = {
  isVoid: true,
  next: {
    type: (type) => type === 'paragraph',
  },
  normalize: normalizeCustomBlockTypes,
}

const normal = {
  serialize: (obj) => {
    if (obj.type === 'normal') {
      return null
    }
  },
}

export const schema = {
  ...linksSchema,
  ...mentionsSchema,
  ...outcomesSchema,
  inlines: {
    child: { ...inlineSchema },
  },
  blocks: {
    document: { ...inlineSchema },
    media: { ...inlineSchema },
    moment: { ...inlineSchema },
    observation: { ...inlineSchema },
    story: { ...inlineSchema },
    plan: { ...inlineSchema },
    table: { ...inlineSchema },
  },
  document: {
    last: [
      { type: 'bulleted-list' },
      { type: 'heading-four' },
      { type: 'heading-one' },
      { type: 'heading-three' },
      { type: 'heading-two' },
      { type: 'list-item' },
      { type: 'numbered-list' },
      { type: 'paragraph' },
    ],
    normalize: (editor, error) => {
      if (error.code == 'last_child_type_invalid') {
        const paragraph = Block.create('paragraph')

        return editor.insertNodeByKey(error.node.key, error.node.nodes.size, paragraph)
      }
    },
  },
}

export const toolbarSections = [[headingsPlugin], formattingPlugin, alignmentsPlugin, listsPlugin]
// TODO: clean this up
export const plugins = R.pipe(
  R.concat([
    childrenPlugin,
    contentPlugin,
    linksPlugin,
    mentionsPlugin,
    momentsPlugin,
    observationsPlugin,
    outcomesPlugin,
    paragraphsPlugin,
    plansPlugin,
    roomsPlugin,
    storiesPlugin,
    tablesPlugin,
    versionPlugin,
  ])
)(R.flatten(toolbarSections))

export const rules = R.append(normal, R.map(R.pick(['serialize']), plugins))
