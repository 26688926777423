import React from 'react'

import distanceInWords from 'date-fns/distance_in_words'

import { Box, Flex, Icon, Link, SecondaryButton, Text } from 'stardust'
import styled from 'styled-components'

import ArticleStatusBanner from '~/components/ArticleStatusBanner'
import DocumentModalPreview, { STACKED_PREVIEW_HEIGHT } from '~/components/DocumentModalPreview'
import DocumentRenderer from '~/components/DocumentRenderer'
import Canvas from '~/components/Editors/CanvasEditor/NewCanvas/View'
import { isPlayground } from '~/config'

interface Props {
  article: Playground.Article
  articleContent: Playground.ArticleContent
}

const StyledLink = styled(Link)`
  width: 100%;
  margin: 0px 2px;
`

const PlanView = ({ article, articleContent }: Props) => {
  const creator = article.contributors.find((x) => x.action === 'created')

  const timeAgo = (dt: Date | string) => distanceInWords(dt, new Date())
  const lastEditorName = article.contributors[0].fullName

  const title = articleContent.title

  const footer = (
    <Flex justifyContent="space-between" alignItems="center" width={1}>
      <Text.p fontSize={1} lineHeight={1.4} ml={3} medium>
        {`Created ${timeAgo(article.insertedAt)} ago, last edited ${timeAgo(
          article.updatedAt
        )} ago by ${lastEditorName}`}
      </Text.p>
      <Box minWidth={160}>
        <Flex flexDirection="row" justifyContent="space-between" width="100%">
          <StyledLink mx={1} to={`/planning/display/${article.id}`}>
            <SecondaryButton>
              <Flex alignItems="center" justifyContent="center">
                <Icon name="fullscreen" mr={1} fill="primary" />
                Full Screen
              </Flex>
            </SecondaryButton>
          </StyledLink>
        </Flex>
      </Box>
    </Flex>
  )

  return (
    <DocumentRenderer
      documentId={article.id}
      documentType="learning_plan"
      educators={article.personnel}
      footer={footer}
      learningOutcomes={article.learningOutcomes}
      heading={title}
      overline={creator?.fullName || ''}
      rooms={article.rooms}
      statusMessage={isPlayground && <ArticleStatusBanner article={article} path="plans" />}
      taggedChildren={article.children}>
      <DocumentModalPreview background="surfacePrimary">
        <Box height={[STACKED_PREVIEW_HEIGHT, '100%']} overflow="hidden" position="relative" width="100%">
          <Canvas modal value={articleContent} />
        </Box>
      </DocumentModalPreview>
    </DocumentRenderer>
  )
}

PlanView.dispalyName = 'ModalPlanView'

export default React.memo(PlanView)
