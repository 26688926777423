import { useState } from 'react'

//This hook saves defaultValue to the local storage only when key is supplied. Otherwise acts as normal useState hook.

function useLocalStorage<T>(defaultValue: T | null, key?: string) {
  const [value, setValue] = useState<T>(() => {
    if (key) {
      const stickyValue = localStorage.getItem(key)
      return stickyValue === null ? defaultValue : JSON.parse(stickyValue)
    }
    return defaultValue
  })
  if (key) {
    window.localStorage.setItem(key, JSON.stringify(value))
  }
  return [value, setValue] as const
}

export default useLocalStorage
