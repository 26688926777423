import React from 'react'

import { Box, Flex } from 'stardust'
import styled from 'styled-components'

import { EditorTools } from '~/components/Editors/components/Toolbar'

const EditorContent = styled(Box)`
  box-sizing: border-box;
  height: 200px;
  overflow-y: auto;
`

const SlimEditor = ({ editor }, next) => {
  const children = next()

  return (
    <Box w={1}>
      <Box borderColor="surfacePrimaryBorder" borderBottom={1} py={2}>
        <Flex justifyContent="center" alignItems="center">
          <EditorTools editor={editor} />
        </Flex>
      </Box>
      <EditorContent bg="surfaceSecondary" w={1}>
        {children}
      </EditorContent>
    </Box>
  )
}

SlimEditor.displayName = 'SlimEditor'

export default SlimEditor
