import React, { useState } from 'react'

import { Absolute, Box, Icon, Relative } from 'stardust'
import styled from 'styled-components'

import MediaImage from './MediaImage'

const MediaSelectionButton = styled(Relative)`
  margin-right: 15px;
  margin-top: 15px;
  padding: 0;
  cursor: pointer;
`

const DeleteButton = styled(Box)`
  transition: opacity 0.2s;
`

interface Props {
  media: Playground.MediaItem
  type: string
  onDelete(e: React.ChangeEvent<HTMLInputElement>, id: number, type: string): void
  onMediaSelect(mediaItem: Playground.MediaItem): void
}

const MediaItem = ({ media, onDelete, onMediaSelect, type }: Props) => {
  const [deleteButton, setDeleteButton] = useState(false)

  const onClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    onMediaSelect(media)
  }

  return (
    <MediaSelectionButton
      onMouseEnter={() => setDeleteButton(true)}
      onMouseLeave={() => setDeleteButton(false)}
      onClick={onClick}>
      <Absolute top={-12} right={-12}>
        <DeleteButton
          bg="negative"
          borderRadius={3}
          boxShadow="2dp"
          cursor="pointer"
          opacity={deleteButton ? 1 : 0}
          onClick={(e: React.ChangeEvent<HTMLInputElement>) => onDelete(e, media.id, type)}>
          <Icon name="close" fill="white" />
        </DeleteButton>
      </Absolute>
      <MediaImage type={type} media={media} />
    </MediaSelectionButton>
  )
}

export default MediaItem
