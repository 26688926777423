import React from 'react'

const EditOutlined = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0726 0.750084C17.0495 0.743471 17.9885 1.12753 18.6807 1.81681L18.6828 1.81888C19.3723 2.51089 19.7565 3.44987 19.7499 4.42675C19.7433 5.40209 19.3477 6.33437 18.6509 7.01669L18.325 7.34256L18.3218 7.34573L18.3187 7.34887L7.0597 18.608C7.05935 18.6084 7.059 18.6087 7.05864 18.6091C7.05798 18.6098 7.05732 18.6104 7.05665 18.6111L7.05418 18.6135C6.95916 18.7086 6.84035 18.7763 6.71018 18.8097L1.18635 20.2265C0.929601 20.2923 0.657162 20.2178 0.469724 20.0304C0.282287 19.843 0.20769 19.5705 0.273505 19.3138L1.68946 13.7899C1.72284 13.6597 1.79059 13.5408 1.88564 13.4458L1.88599 13.4454L13.4825 1.84875C14.165 1.15219 15.0973 0.756687 16.0726 0.750084ZM2.8165 15.434L2.04119 18.4587L5.06856 17.6822L2.8165 15.434ZM6.52739 17.019L3.47789 13.9748L13.6841 3.76855L16.7309 6.81539L6.52739 17.019ZM17.7817 5.74487C18.0802 5.36894 18.2467 4.90181 18.25 4.4166C18.2539 3.84058 18.0275 3.28689 17.6212 2.87862C17.2127 2.47244 16.6589 2.24615 16.0828 2.25005C15.5977 2.25333 15.1307 2.41959 14.7546 2.7178L17.7817 5.74487Z"
      fill="currentColor"
    />
  </svg>
)

export default EditOutlined
