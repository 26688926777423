export const generateHeader = (documentType: Playground.DocumentType) => {
  switch (documentType) {
    case 'observation':
      return 'observations'
    case 'learning_story':
      return 'stories'
    case 'learning_plan':
      return 'planning'
    case 'learning_table':
      return 'tables'
    default:
      return ''
  }
}
