import { useReducer } from 'react'

import * as R from 'ramda'

import { actions } from './actions'

const undoable = (reducer) => {
  return (state, action) => {
    const { currentIndex, history } = state

    switch (action.type) {
      case actions.UNDO: {
        return {
          ...state,
          currentIndex: currentIndex - 1,
        }
      }

      case actions.REDO: {
        return {
          ...state,
          currentIndex: currentIndex + 1,
        }
      }

      default: {
        // If it's not a history action, then we can safely
        // delegate handling the action to the passed reducer.
        const present = history[currentIndex]
        const newPresent = reducer(present, action)

        // Return early if there is no change, this way we don't
        // create a new history.
        if (present === newPresent) {
          return state
        }

        // Blacklist actions that we don't care about creating a new
        // history for, e.g., selection changes. I.e., we don't want
        // to hit undo and all the nodes are selected.
        const blacklist = R.any(R.equals(action.type), [
          'bail',
          actions.EDIT.NODE_CHANGE,
          actions.EDIT.VALUE_CHANGE,
          actions.NODE.TAGGED_ADD,
          actions.NODE.TAGGED_REMOVE,
          actions.PLANNER.CLONING_CHANGE,
          actions.PLANNER.SELECTION_ADD,
          actions.PLANNER.SELECTION_REMOVE,
          actions.PLANNER.TRANSFORM_CHANGE,
        ])

        if (blacklist) {
          const newHistory = R.pipe(R.take(currentIndex), R.append(newPresent))(history)

          return {
            currentIndex,
            history: newHistory,
          }
        } else {
          const newIndex = currentIndex + 1

          const newHistory = R.pipe(R.take(newIndex), R.append(newPresent))(history)

          return {
            currentIndex: newIndex,
            history: newHistory,
          }
        }
      }
    }
  }
}

const useHistoryReducer = (reducer, initial) => {
  const initialState = {
    history: [initial],
    currentIndex: 0,
  }

  const [state, dispatch] = useReducer(undoable(reducer), initialState)
  const { currentIndex, history } = state
  const canUndo = currentIndex > 0
  const canRedo = currentIndex < history.length - 1

  return { canRedo, canUndo, dispatch, history, state: history[currentIndex] }
}

export default useHistoryReducer
