const palette = {
  neonPink0: '#FFE3FE',
  neonPink1: '#FFB8FD',
  neonPink2: '#FF81FD',
  neonPink3: '#F63FF5',
  neonPink4: '#E900E8',
  neonPink5: '#DC00DD',
  neonPink6: '#CB00D8',
  neonPink7: '#B300D3',
  neonPink8: '#9F00CD',
  neonPink9: '#7700C3',
}

export default {
  ...palette,
  neonPink: palette.neonPink2,
}
