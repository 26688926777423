/* eslint react/display-name: 0 */
import React from 'react'

import * as R from 'ramda'

import { Block, Change, Editor } from 'slate'
import { Box } from 'stardust'

import DropdownMenu from '~/components/DropdownMenu'

import { Next, RenderToolbarItemProps, SerializeProps } from '../types'
import { RenderNodeProps } from '../types'

const headingTypes = {
  Normal: 'normal',
  H1: 'heading-one',
  H2: 'heading-two',
  H3: 'heading-three',
  H4: 'heading-four',
  H5: 'heading-five',
  H6: 'heading-six',
} as const

type Headings = typeof headingTypes[keyof typeof headingTypes]

const headingTypeValues = Object.values(headingTypes)

const displayName = (type: string) => {
  switch (type) {
    case headingTypes.H1:
      return 'Heading 1'
    case headingTypes.H2:
      return 'Heading 2'
    case headingTypes.H3:
      return 'Heading 3'
    case headingTypes.H4:
      return 'Heading 4'
    case headingTypes.H5:
      return 'Heading 5'
    case headingTypes.H6:
      return 'Heading 6'
    default:
      return 'Normal'
  }
}

const renderHeading = (type: string, children: any, attributes = {}) => {
  switch (type) {
    case headingTypes.H1:
      return <h1 {...attributes}>{children}</h1>
    case headingTypes.H2:
      return <h2 {...attributes}>{children}</h2>
    case headingTypes.H3:
      return <h3 {...attributes}>{children}</h3>
    case headingTypes.H4:
      return <h4 {...attributes}>{children}</h4>
    case headingTypes.H5:
      return <h5 {...attributes}>{children}</h5>
    case headingTypes.H6:
      return <h6 {...attributes}>{children}</h6>
    default:
      return null
  }
}

const selectedValue = (editor: Nullable<Editor>) => {
  if (!editor?.value) {
    return headingTypes.Normal
  }

  const blocks = editor.value.blocks.toJS() as Block[]
  const filteredBlocks = blocks.filter((block) => {
    const types = headingTypeValues as string[]
    return types.includes(block.type)
  })

  const headingNodes = R.uniq(filteredBlocks)

  if (headingNodes.length != 1) {
    return headingTypes.Normal
  }

  return R.head(headingNodes)!.type as Headings
}

const handleChange = (type: Headings, change: Change) => change.setBlocks(type)

export default {
  serialize: (obj: SerializeProps, children: any) => {
    if (obj.object !== 'block') {
      return
    }

    return renderHeading(obj.type as Headings, children)
  },

  renderToolbarItem: ({ editor }: RenderToolbarItemProps) => {
    const headingItems = headingTypeValues.map((item) => ({
      label: displayName(item),
      onClick: () => {
        if (!editor) return

        editor.change(R.partial(handleChange, [item]))
      },
    }))

    return (
      <Box width={120}>
        <DropdownMenu label={displayName(selectedValue(editor))} items={headingItems || []} />
      </Box>
    )
  },

  renderNode: ({ attributes, children, node }: RenderNodeProps, next: Next) => {
    const validTypes = headingTypeValues as string[]
    if (!validTypes.includes(node.type)) return next()

    return renderHeading(node.type, children, attributes) || next()
  },
}
