import React from 'react'

const MultiplePeopleAdd = () => (
  <svg viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 22C19.5376 22 22 19.5376 22 16.5C22 13.4624 19.5376 11 16.5 11C13.4624 11 11 13.4624 11 16.5C11 19.5376 13.4624 22 16.5 22Z"
      stroke="#5D0E7F"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M16.5 14V19" stroke="#5D0E7F" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14 16.5H19" stroke="#5D0E7F" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 7C7.88071 7 9 5.88071 9 4.5C9 3.11929 7.88071 2 6.5 2C5.11929 2 4 3.11929 4 4.5C4 5.88071 5.11929 7 6.5 7Z"
      stroke="#595959"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10.2857C8.91301 8.4978 6.68895 7.62309 4.57916 8.15379C2.46939 8.68447 1.00092 10.488 1 12.5496V14.4997H3.06527L3.7537 21H7.88424L8.18072 18.1979"
      stroke="#595959"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 6C16.8807 6 18 4.88071 18 3.5C18 2.11929 16.8807 1 15.5 1C14.1193 1 13 2.11929 13 3.5C13 4.88071 14.1193 6 15.5 6Z"
      stroke="#595959"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 9C18.4017 7.88188 17.3406 7.14416 16.1503 7.0189C14.96 6.89364 13.787 7.39624 13 8.3686"
      stroke="#595959"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default MultiplePeopleAdd
