import React from 'react'

import { Flex, Image } from 'stardust'
import styled from 'styled-components'

import homeIcon from '~/assets/images/home.svg'
import playgroundIcon from '~/assets/images/playground.png'
import { isHome } from '~/config'

const Container = styled(Flex)`
  background-color: ${(props) => props.theme.colors.loadingBackground};
`

const Loading = () => {
  return (
    <Container alignItems="center" height="100vh" justifyContent="center" width="100vw">
      <Image height={80} width={80} src={isHome ? homeIcon : playgroundIcon} />
    </Container>
  )
}

export default Loading
