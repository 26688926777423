import * as R from 'ramda'
import { Block, Decoration, Editor, Inline, Mark, Point, Range, Selection, Value } from 'slate'

import { tokenize, typesFromToken } from '~/components/Mentions'

import { MentionTokens } from '../../types'

import { CONTEXT_MARK, MENTION_NODE } from './types'

const getInput = (value: Value) => {
  const startOffset: Nullable<number> = R.path(['selection', 'start', 'offset'], value)
  const startText: Nullable<string> = R.path(['startText', 'text'], value)

  if (!startOffset || !startText) {
    return tokenize(null)
  }

  return tokenize(R.slice(0, startOffset, startText))
}

const hasValidAncestors = (value: Value) => {
  const { document, selection } = value

  const invalidParent = document.getClosest(
    selection.start.key!,
    // We want mentions to be able to exist in any node that isn't a link.
    // This check can be adjusted for more complex rich text implementations.
    (node) => {
      const blockOrInlineNode = node as Block | Inline
      return blockOrInlineNode.type === 'link'
    }
  )

  return !invalidParent
}

const createMarkDecoration = (
  markType: CONTEXT_MARK,
  token: MentionTokens,
  terms: string,
  selection: Selection
) => {
  const anchor = Point.create({
    key: selection.start.key!,
    offset: selection.start.offset - (terms.length + 1),
  })

  const focus = Point.create({
    key: selection.start.key,
    offset: selection.start.offset,
  })

  const mark = Mark.create({
    type: markType,
    data: {
      types: typesFromToken(token),
      query: terms,
    },
  })

  return Decoration.create({
    anchor: anchor,
    focus: focus,
    mark: mark,
  })
}

const insertMention = (editor: Editor, nodeType: MENTION_NODE, entity: Playground.TaggableEntity) => {
  const { terms } = getInput(editor.value)

  editor.change((change) => {
    return change
      .deleteBackward(((terms && terms.length) || 0) + 1)
      .insertInlineAtRange(
        new Range(change.value.selection),
        Inline.create({
          data: entity,
          type: nodeType,
        })
      )
      .focus()
  })
}

export { insertMention, createMarkDecoration, hasValidAncestors, getInput }
