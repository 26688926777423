import React, { ReactNode } from 'react'

import styled from 'styled-components'
import { MaxWidthProps, SpaceProps, WidthProps, maxWidth, space, width } from 'styled-system'

type BaseProps = MaxWidthProps & SpaceProps & WidthProps

export interface ContainerProps extends BaseProps {
  children: ReactNode
}

const StyledContainer = styled.div<ContainerProps>`
  margin-left: auto;
  margin-right: auto;
  ${maxWidth};
  ${space};
  ${width};
`

StyledContainer.defaultProps = {
  maxWidth: 0,
}

const Container = (props: ContainerProps) => (
  <StyledContainer mx={['3%', '6%', 'auto']} width={['auto', 'auto', '100%']} {...props}>
    {props.children}
  </StyledContainer>
)

Container.displayName = 'Container'

export default React.memo(Container)
