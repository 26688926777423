import ReactGA from 'react-ga4'

//Playground Web
export async function tablesCreateBlankTable() {
  ReactGA.event({
    category: 'Tables',
    action: 'Create',
    label: 'Create Blank Table',
  })
}

//Playground Web
export async function tablesCreateWeeklyPlanTable() {
  ReactGA.event({
    category: 'Tables',
    action: 'Create',
    label: 'Create Weekly Plan Table',
  })
}

//Playground Web
export async function tablesCreateMonthlyPlanTable() {
  ReactGA.event({
    category: 'Tables',
    action: 'Create',
    label: 'Create Monthly Plan Table',
  })
}

//Playground Web
export async function tablesCreateQIPTable() {
  ReactGA.event({
    category: 'Tables',
    action: 'Create',
    label: 'Create QIP Table',
  })
}

//Playground Web
export async function tablesSaveDraftTable() {
  ReactGA.event({
    category: 'Tables',
    action: 'Save',
    label: 'Save Draft Table',
  })
}

//Playground Web
export async function tablesPublishToParents() {
  ReactGA.event({
    category: 'Tables',
    action: 'Publish',
    label: 'Publish to Parents',
  })
}

//Playground Web
export async function tablesEditTable() {
  ReactGA.event({
    category: 'Tables',
    action: 'Edit',
    label: 'Edit Table',
  })
}

//Playground Web
export async function tablesArchiveTable() {
  ReactGA.event({
    category: 'Tables',
    action: 'Archive',
    label: 'Archive Table',
  })
}

//Playground Web
export async function tablesTagAllBulkAction() {
  ReactGA.event({
    category: 'Tables',
    action: 'Bulk Action',
    label: 'Tag All',
  })
}

//Playground Web
export async function tablesUntagAllBulkAction() {
  ReactGA.event({
    category: 'Tables',
    action: 'Bulk Action',
    label: 'Untag All',
  })
}

//Playground Web
export async function tablesTagBookedBulkAction() {
  ReactGA.event({
    category: 'Tables',
    action: 'Bulk Action',
    label: 'Tag Booked',
  })
}

//Playground Web
export async function duplicateTable() {
  ReactGA.event({
    category: 'Tables',
    action: 'Duplicate',
    label: 'Duplicate Table',
  })
}

//Home web
export async function openTable() {
  ReactGA.event({
    category: 'Tables',
    action: 'Learning Click',
    label: 'Table',
  })
}

//Home web
export async function tableAddComment() {
  ReactGA.event({
    category: 'Tables',
    action: 'Learning Comment',
    label: 'Table',
  })
}
