import gql from 'graphql-tag'

export interface Notification {
  body: string
  category: string
  id: number
  insertedAt: string
  metadata: any
  status: string
  subcategory: string
  title: string
}
export interface ServiceNotifications {
  service: {
    fkey: string
    notifications: {
      pageInfo: {
        endCursor: string
        hasNextPage: boolean
      }
      edges: {
        node: Notification
      }[]
    }
  }
}

export const GET_NOTIFICATIONS = gql`
  query GetNotifications($cursor: String, $serviceFkey: String, $exclude: NotificationFilters) {
    service(fkey: $serviceFkey) {
      fkey
      notifications(first: 10, after: $cursor, exclude: $exclude) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            body
            category
            id
            insertedAt
            metadata
            status
            subcategory
            title
          }
        }
      }
    }
  }
`

export const GET_UNREAD_NOTIFICATIONS = gql`
  query GetUnreadNotifications($cursor: String, $serviceFkey: String, $exclude: NotificationFilters) {
    service(fkey: $serviceFkey) {
      fkey
      notifications(first: 10, after: $cursor, include: { status: unread }, exclude: $exclude) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            body
            category
            id
            insertedAt
            metadata
            status
            subcategory
            title
          }
        }
      }
    }
  }
`

export const VIEW_FEATURE_FLAGS = gql`
  query viewFeatureFlags {
    systemFeatures(applicationName: "playground") {
      category
      enabled
      name
    }
  }
`
