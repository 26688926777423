import React, { forwardRef } from 'react'

import styled from 'styled-components'

import TextField, { TextFieldProps } from './TextField'
import colors from './tokens/colors'

interface StyledTextFieldProps extends TextFieldProps {
  borderRadius?: string | number
  borderColor?: string
  heightAdjust?: string | number
  borderWidth?: string | number
  pb?: string | number
  valid?: boolean
}

const StyledTextField = styled(TextField)`
  width: 100%;
  border-style: solid;
  border-width: ${(props) => props.borderWidth || '2px'};
  background-color: white;
  height: ${(props) => props.height || 'auto'};
  display: ${(props) => (props.textarea ? 'flex' : 'block')};
  flex-direction: column;

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: white;
  }

  .mdc-text-field--textarea {
    flex-grow: ${(props) => (props.textarea ? 1 : 'unset')};
  }

  .mdc-text-field--with-trailing-icon {
    padding-bottom: ${(props) => props.pb || ''};
  }

  .mdc-text-field--with-trailing-icon {
    padding-bottom: ${(props) => props.pb || ''};
  }

  .mdc-text-field__input {
    padding-top: 0px;
    background-color: white;
  }

  .mdc-text-field-helper-line {
    justify-content: flex-end;
  }
`

const PostTextField = forwardRef<HTMLInputElement | HTMLTextAreaElement, StyledTextFieldProps>(
  ({ borderRadius, borderColor, valid, borderWidth, heightAdjust, ...props }, ref) => {
    const getBorderColor = () => {
      if (borderColor) return borderColor

      if (!valid) {
        return colors.superGiantRed5
      } else return 'surfacePrimaryBorder'
    }
    return (
      <StyledTextField
        borderRadius={borderRadius}
        height={heightAdjust}
        borderWidth={borderWidth}
        borderColor={getBorderColor()}
        border="2px solid"
        overflow="hidden"
        outlined={false}
        type="text"
        autoComplete="off"
        ref={ref}
        {...props}
      />
    )
  }
)

PostTextField.displayName = 'PostTextField'

export default React.memo(PostTextField)
