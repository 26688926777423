import React, { useState } from 'react'

import { useQuery } from '@apollo/client'

import { navigate } from '@reach/router'

import DocumentPreviewCard from '~/components/DocumentPreviewCard'
import { isWebView } from '~/config'
import { useSelectedServiceFkey } from '~/contexts/Service'
import { is404 } from '~/modules/api'
import StoryModal from '~/pages/Stories/Modal'
import { GET_LEARNING_STORY_SUMMARY } from '~/pages/Stories/queries'

interface Props {
  id: number
}

const StoryPreviewCard = ({ id }: Props) => {
  const serviceFkey = useSelectedServiceFkey()
  const [open, setOpen] = useState(false)

  const { data, error, loading } = useQuery(GET_LEARNING_STORY_SUMMARY, {
    variables: { id, serviceFkey },
  })

  if (error) {
    // 404 is expected behaviour if we're embedding an observation, but the
    // parent doesn't have a child tagged in it
    if (is404(error)) {
      return (
        <DocumentPreviewCard
          title="Document"
          description="You do not have permission to view this content. Your child is not tagged in the document."
        />
      )
    }

    throw error
  }

  if (loading) return null

  const story = data?.context?.learningStory as Playground.Article
  const content = story?.content[0]

  return (
    <>
      <DocumentPreviewCard
        description="Document"
        title={content.title}
        onOpen={() => (isWebView ? navigate(`/stories/${story.id}/webview`) : setOpen(true))}
      />

      {open ? (
        <StoryModal contentStatus="PUBLISHED" id={story.id} show onClose={() => setOpen(false)} />
      ) : null}
    </>
  )
}

StoryPreviewCard.displayName = 'StoryPreviewCard'

export default React.memo(StoryPreviewCard)
