import gql from 'graphql-tag'

export default gql`
  fragment PostReflections on Post {
    reflections {
      id
      content
      insertedAt
      author {
        id
        type
        name
      }
      replies {
        id
        content
        insertedAt
        author {
          id
          type
          name
        }
      }
    }
  }
`
