import React from 'react'

const Expand = () => (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.50098 12.5H16.501"
      stroke="#252525"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.50098 3.5L8.64764 8.64667C8.74131 8.74046 8.86842 8.79317 9.00098 8.79317C9.13353 8.79317 9.26065 8.74046 9.35431 8.64667L14.501 3.5"
      stroke="#252525"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Expand
