import React, { useCallback, useContext, useState } from 'react'

import { useMutation } from '@apollo/client'
import { navigate } from '@reach/router'

import { Icon } from 'stardust'

import CopyToFolderModal from '~/components/DocumentFolders/CopyToFolderModal'
import { DropdownMenuItemProps } from '~/components/DropdownMenu'
import { HeaderContext } from '~/components/Header/HeaderProvider'
import { generateToastMessage } from '~/components/ToastMessages/GenerateToastMessage'
import { isWebView } from '~/config'
import usePermission from '~/hooks/usePermission'

import * as ObservationAnalytics from '~/modules/analytics/observations'
import { REMOVE_DOCUMENT_FROM_FOLDER } from '~/modules/documentFolders'
import {
  documentFolders as documentFolderPermissions,
  observations as observationPermissions,
} from '~/modules/permissions'

import {
  ARCHIVE_POST,
  DELETE_UNSHARED_CHANGES_POST,
  DUPLICATE_POST,
  RESTORE_POST,
  PUBLISH_POST,
  UPDATE_POST_BOOKMARK,
} from '../../pages/Observations/mutations'

// Usage:
// Use the required hook to generate the menu items you want to use
// Then build the menu Items with the buildMenu function
//
// const { MoveToFolder, moveToFolderProps, menuItemMoveToFolder } = useMoveToFolder()
// const menuItemsEditDeleteDuplicate = useMenuItemEditArchiveDuplicate(refetch)
// const menuItemsBookmark = useMenuItemBookmark(refetch)
// const inputMenuFactoryItems = [menuItemsBookmark, menuItemsEditDeleteDuplicate,  menuItemMoveToFolder]

// const menuItems: MenuFactoryItems = buildMenu(inputMenuFactoryItems, canWrite)
// Then, to create menu items for a specific document:
// generateMenuFromFactory(menuItems, doc.id)
// Function filterMenuItemsByStatus can be used to filter menu items based on document status
// This is required for the All tab and View tab

export type MenuFactoryItems = DropdownMenuItemFactoryProps[]

export interface DropdownMenuItemFactoryProps {
  labelFactory: (bookmarked?: boolean) => string
  icon?: JSX.Element
  onClickFactory(id: number, bookmarked?: boolean, status?: Playground.Status): () => void
}

export const buildMenu = (menuFactoryItems: MenuFactoryItems[], canUseMenu: boolean): MenuFactoryItems => {
  return canUseMenu ? menuFactoryItems.flat() : []
}

export const useMenuItemArchiveDuplicate = (onRefetch: any): MenuFactoryItems => {
  const [restorePost] = useMutation(RESTORE_POST, {
    onCompleted: (_data) => {
      onRefetch()
    },
  })

  const [archivePost] = useMutation(ARCHIVE_POST, {
    onCompleted: (data) => {
      const { id } = data.archivePost
      onRefetch()
      generateToastMessage(`archived${id}`, {
        message: 'Moved to Archive',
        buttonText: 'Undo',
        buttonAction: () =>
          restorePost({
            variables: { id },
          }),
      })
    },
  })

  const [deleteUnsharedChanges] = useMutation(DELETE_UNSHARED_CHANGES_POST, {
    onCompleted: (data) => {
      const { id } = data.deleteDraftPost
      onRefetch()
      generateToastMessage(`deleteDraftPost${id}`, {
        message: 'Deleted Unshared Changes Post',
      })
    },
  })

  const [duplicatePost] = useMutation(DUPLICATE_POST, {
    onCompleted: (data) => {
      const { id, title } = data.createDuplicatePost
      onRefetch()
      generateToastMessage(`duplicate${id}`, {
        message: `Duplicated, "${title}"`,
      })
    },
  })

  const archivePostCallback = useCallback(
    (id: number, _bookmarked, status: Playground.Status) => () => {
      if (status === 'UNSHARED_CHANGES') {
        deleteUnsharedChanges({
          variables: { id },
        })
      } else {
        archivePost({
          variables: { id },
        })
      }
    },
    [deleteUnsharedChanges, archivePost]
  )

  return [
    {
      labelFactory: () => 'Duplicate',
      icon: <Icon name="duplicate" size={20} />,
      onClickFactory: useCallback(
        (id: number) => () => {
          ObservationAnalytics.duplicateObservation()
          return duplicatePost({
            variables: { id },
          })
        },
        [duplicatePost]
      ),
    },
    {
      labelFactory: () => 'Archive Post',
      icon: <Icon name="archive" size={20} />,
      onClickFactory: archivePostCallback,
    },
  ]
}

export const useMenuItemView = (): MenuFactoryItems => {
  const canViewPublished = usePermission(observationPermissions.readPublished)
  const viewPost = useCallback(
    (id: number, _bookmarked, status?: Playground.Status) => () =>
      isWebView
        ? navigate(`xplor://posts/view/${id}?status=${status?.toLowerCase()}`)
        : navigate(`/observations/${id}`),
    []
  )

  if (!canViewPublished) {
    return []
  }
  return [
    {
      labelFactory: () => 'View Post',
      icon: <Icon name="eye" size={22} />,
      onClickFactory: viewPost,
    },
  ]
}

export const useMenuItemBookmark = (onRefetch: any): MenuFactoryItems => {
  const canViewBookmark = usePermission(observationPermissions.readBookmarked)
  const [updatePostBookmark] = useMutation(UPDATE_POST_BOOKMARK, {
    onCompleted: (data) => {
      const { id, status, bookmarked } = data.updateBookmarkPost
      const message = bookmarked ? 'Saved in Bookmarks' : 'Removed from Bookmarks'

      onRefetch()

      generateToastMessage(`bookmark${id}`, {
        message: message,
        buttonText: 'View',
        buttonAction: () => navigate(`/observations/${id}?status=${status?.toLowerCase()}`),
      })
    },
  })
  const bookmarkPost = useCallback(
    (id: number) => () =>
      updatePostBookmark({
        variables: { id: id },
      }),
    [updatePostBookmark]
  )

  if (!canViewBookmark) {
    return []
  }

  return [
    {
      labelFactory: (bookmarked) => (bookmarked ? 'Remove Bookmark' : 'Bookmark'),
      icon: <Icon name="bookmarkAlt2" size={15} />,
      onClickFactory: bookmarkPost,
    },
  ]
}

export const useMenuItemApprove = (onRefetch: any): MenuFactoryItems => {
  const canApprove = usePermission(observationPermissions.approve)
  const [approve] = useMutation(PUBLISH_POST, {
    onCompleted: (data) => {
      const { id, status, title } = data.publishPost
      onRefetch()

      generateToastMessage(`approve${id}`, {
        message: `Approved "${title}"`,
        buttonText: 'View',
        buttonAction: () => navigate(`/observations/${id}?status=${status?.toLowerCase()}`),
      })
    },
  })
  const approveDocument = useCallback((id: number) => () => approve({ variables: { id: id } }), [approve])

  if (!canApprove) {
    return []
  }
  return [
    {
      labelFactory: () => 'Approve',
      icon: <Icon name="tick" size={18} />,
      onClickFactory: approveDocument,
    },
  ]
}

export const useMenuItemRestore = (onRefetch: any): MenuFactoryItems => {
  const canRestore = usePermission(observationPermissions.write)
  const [restore] = useMutation(RESTORE_POST, {
    onCompleted: (data) => {
      const { id, status, title } = data.restorePost
      onRefetch()

      generateToastMessage(`restore${id}`, {
        message: `Restored "${title}"`,
        icon: 'restore',
        iconSize: 25,
        buttonText: 'View',
        buttonAction: () => navigate(`/observations/${id}?status=${status?.toLowerCase()}`),
      })
    },
  })
  const restoreDocument = useCallback((id: number) => () => restore({ variables: { id: id } }), [restore])

  if (!canRestore) {
    return []
  }
  return [
    {
      labelFactory: () => 'Restore',
      icon: <Icon name="restore" size={22} />,
      onClickFactory: restoreDocument,
    },
  ]
}

export const useMenuItemPrint = (): MenuFactoryItems => {
  const printDocument = useCallback((id: number) => () => navigate(`/observations/print/${id}`), [])
  if (isWebView) {
    return []
  }

  return [
    {
      labelFactory: () => 'Print',
      icon: <Icon name="print" size={20} />,
      onClickFactory: printDocument,
    },
  ]
}

export const useMenuItemsAll = (onRefetch: any) => {
  return [
    useMenuItemView(),
    useMenuItemBookmark(onRefetch),
    useMenuItemApprove(onRefetch),
    useMenuItemArchiveDuplicate(onRefetch),
    useMenuItemRestore(onRefetch),
  ].flat()
}

export const useMenuItemsForPostView = (onRefetch: any) => {
  return [
    useMenuItemArchiveDuplicate(onRefetch),
    useMenuItemBookmark(onRefetch),
    useMenuItemApprove(onRefetch),
    useMenuItemRestore(onRefetch),
    useMenuItemPrint(),
  ].flat()
}

export const useMenuItemRemoveFromFolder = (folderId: number, onRefetch: any): MenuFactoryItems => {
  const [removeDocumentFromFolder] = useMutation(REMOVE_DOCUMENT_FROM_FOLDER, {
    onCompleted: onRefetch,
  })

  const { articleType } = useContext(HeaderContext)
  const removeDocument = useCallback(
    (id: number) => () =>
      removeDocumentFromFolder({
        variables: {
          folderId: folderId,
          document: { type: articleType, type_id: id },
        },
      }),
    [removeDocumentFromFolder, folderId, articleType]
  )
  const canEditFolders = usePermission(documentFolderPermissions.write)
  if (!canEditFolders) {
    return []
  }
  return [
    {
      labelFactory: () => 'Remove from Folder',
      onClickFactory: removeDocument,
    },
  ]
}

const useMenuItemMoveToFolder = (
  setMoveDocumentId: React.Dispatch<React.SetStateAction<Nullable<number>>>
): MenuFactoryItems => {
  const beginCopyMove = useCallback((id: number) => () => setMoveDocumentId(id), [setMoveDocumentId])

  const canEditFolders = usePermission(documentFolderPermissions.write)
  if (!canEditFolders) {
    return []
  }
  return [
    {
      labelFactory: () => 'Move to folder',
      icon: <Icon name="folderAlt" size={20} />,
      onClickFactory: beginCopyMove,
    },
  ]
}

export const useMoveToFolder = () => {
  const [moveDocumentId, setMoveDocumentId] = useState<Nullable<number>>(null)
  const { articleType } = useContext(HeaderContext)

  const moveToFolderProps = {
    documentId: moveDocumentId,
    documentType: articleType,
    onClose: useCallback(() => setMoveDocumentId(null), [setMoveDocumentId]),
  }
  return {
    MoveToFolder: CopyToFolderModal,
    moveToFolderProps,
    menuItemMoveToFolder: useMenuItemMoveToFolder(setMoveDocumentId),
  }
}

const createMenuItemFromFactory = (
  item: DropdownMenuItemFactoryProps,
  documentId: number,
  bookmarked?: boolean,
  status?: Playground.Status
): DropdownMenuItemProps => ({
  ...item,
  label: item.labelFactory(bookmarked),
  onClick: item.onClickFactory(documentId, bookmarked, status),
})

export const generateMenuFromFactory = (
  menuFactoryItems: MenuFactoryItems,
  documentId: number,
  bookmarked?: boolean,
  status?: Playground.Status
): DropdownMenuItemProps[] => {
  return menuFactoryItems.map((item) => createMenuItemFromFactory(item, documentId, bookmarked, status))
}

export const filterMenuItemsByStatus = (
  menuFactoryItems: MenuFactoryItems,
  documentStatus: Playground.Status
) => {
  if (!documentStatus) {
    return menuFactoryItems
  }

  const conditions: { [key in string]?: string[] } = {
    DRAFT: ['Approve', 'Restore', 'Print'],
    UNSHARED_CHANGES: ['Bookmark', 'Duplicate', 'Approve', 'Move to folder', 'Print', 'Restore'],
    PUBLISHED: ['Approve', 'Restore'],
    NEEDS_REVIEW: ['Move to folder', 'Restore'],
    ARCHIVED: ['View', 'Edit', 'Bookmark', 'Duplicate', 'Approve', 'Move to folder', 'Archive Post', 'Print'],
  }
  const labelsToRemove = conditions[documentStatus] || []

  const filteredItems = menuFactoryItems.filter((item) => !labelsToRemove.includes(item.labelFactory()))

  return filteredItems
}
