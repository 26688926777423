import React, { useCallback, useState } from 'react'

import { endOfDay, format, startOfDay } from 'date-fns'

import { FlexProps } from 'stardust/Flex'

import { DateRange } from '~/dateRange'

import CalendarWithButtons from './CalendarWithButtons'
import { PresetPeriod, presetEmptyDateRange } from './utils'

interface Props {
  allowEmptyDateRange?: boolean
  emptyPresetDateRange?: PresetPeriod<DateRange>
  endYear: number
  initialDisplayMonth?: Date
  label: string
  presetPeriods: PresetPeriod<DateRange>[]
  readOnly?: boolean
  selected?: DateRange
  showTextBox?: boolean
  startYear?: number
  setSelection: (newSelection?: DateRange) => void
}

const DateRangePicker = ({
  allowEmptyDateRange = true,
  emptyPresetDateRange = presetEmptyDateRange,
  endYear,
  initialDisplayMonth,
  label,
  presetPeriods,
  readOnly,
  selected,
  showTextBox,
  startYear,
  setSelection,
  ...other
}: Props & FlexProps) => {
  const [hoveredValue, setHoveredValue] = useState<Undefinedable<DateRange>>(undefined)

  const calculateRange = useCallback(
    (value) => {
      if (!selected || format(selected.from, 'YYYY-MM-DD') !== format(selected.to, 'YYYY-MM-DD'))
        return buildRange(value, value)
      return value < selected.from ? buildRange(value, selected.to) : buildRange(selected.from, value)
    },
    [selected]
  )

  const onHover = (date: Date) => setHoveredValue(calculateRange(date))

  const onSelectDate = (date: Date) => setSelection(calculateRange(date))

  const onSelectPeriod = useCallback((period) => setSelection(period), [setSelection])

  const buildRange = (start: Date, end: Date) => ({ from: startOfDay(start), to: endOfDay(end) })

  const formatPeriod = useCallback(
    (period) =>
      period
        ? `${format(period.from, 'D MMM YYYY')} - ${format(period.to, 'D MMM YYYY')}`
        : emptyPresetDateRange?.label || '',
    [emptyPresetDateRange]
  )

  const isPeriodSelected = useCallback(
    (period) => {
      if (period === undefined && selected === undefined) return true
      return (
        period?.from.getTime() === selected?.from.getTime() && period?.to.getTime() === selected?.to.getTime()
      )
    },
    [selected]
  )

  const buildPresetList = () =>
    allowEmptyDateRange ? [emptyPresetDateRange, ...presetPeriods] : presetPeriods

  return (
    <CalendarWithButtons
      endYear={endYear}
      hoveredValue={hoveredValue}
      initialDisplayMonth={initialDisplayMonth}
      label={label}
      presetPeriods={buildPresetList()}
      readOnly={readOnly}
      selected={selected}
      showTextBox={showTextBox}
      startYear={startYear}
      formatPeriod={formatPeriod}
      isPeriodSelected={isPeriodSelected}
      onSelectDate={onSelectDate}
      onSelectPeriod={onSelectPeriod}
      setHoveredValue={onHover}
      {...other}
    />
  )
}

export default DateRangePicker
