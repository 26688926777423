import React from 'react'

import { StencilCard as Card, Container, Flex } from 'stardust'
import styled from 'styled-components'

const opacity = (props: { opacity?: number }) => (props.opacity ? { opacity: props.opacity } : null)

const OpaqueFlex = styled(Flex)`
  ${opacity};
`

const Cards = () => {
  return (
    <Container pt={4}>
      <OpaqueFlex opacity={6 / 10}>
        <Card />
        <Card />
        <Card />
      </OpaqueFlex>
      <OpaqueFlex opacity={4 / 10}>
        <Card />
      </OpaqueFlex>
      <OpaqueFlex opacity={2 / 10}>
        <Card />
        <Card />
      </OpaqueFlex>
    </Container>
  )
}

export default Cards
