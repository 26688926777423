import React, { ReactNode, useContext } from 'react'

import { Box, Divider, Flex, Text } from 'stardust'

import CommentPanel from '~/components/Comment/CommentPanel'
import { ServiceContext, useSelectedServiceFkey } from '~/contexts/Service'
import { showLearningContentAssets } from '~/modules/permissions'

import Caption from './DocumentRenderer/Caption'
import Educators from './DocumentRenderer/Educators'
import FuturePlanning from './DocumentRenderer/FuturePlanning'
import LearningContentAssets from './DocumentRenderer/LearningContentAssets'
import LearningOutcomes from './DocumentRenderer/LearningOutcomes'
import Location from './DocumentRenderer/Location'
import Rooms from './DocumentRenderer/Rooms'
import TaggedChildren from './DocumentRenderer/TaggedChildren'

const renderSection = (children: ReactNode, divider = true) => (
  <>
    <Box pr={3} pt={3} pb={divider ? 3 : 0}>
      {children}
    </Box>
    {divider && <Divider borderColor="surfacePrimaryBorder" m={0} width={1} />}
  </>
)

interface Props {
  caption?: string
  children: ReactNode
  documentId?: Nullable<number>
  documentType: Playground.DocumentType
  educators?: Playground.Educator[]
  footer: ReactNode
  futurePlanning?: string
  heading: ReactNode
  learningOutcomes?: Playground.LearningFrameworkOutcome[]
  location?: string
  overline?: string
  planningDate?: string
  rooms?: Playground.Room[]
  stacked?: boolean
  statusMessage?: ReactNode
  taggedChildren?: Playground.SimpleChild[]
  learningContentAssets?: Playground.LearningContentAsset[]
}

const DocumentRenderer = ({
  caption,
  children,
  documentId,
  documentType,
  educators,
  footer,
  futurePlanning,
  heading,
  learningOutcomes,
  location,
  overline,
  planningDate,
  rooms,
  stacked,
  statusMessage,
  taggedChildren,
  learningContentAssets,
}: Props) => {
  const {
    selectedServiceFeatures,
    state: { services },
  } = useContext(ServiceContext)
  const serviceFkey = useSelectedServiceFkey()
  const hasLearningContentEnabled = showLearningContentAssets(serviceFkey, selectedServiceFeatures, services)

  return (
    <Flex flexDirection={stacked ? 'column' : ['column', 'row']} width={1}>
      <Flex flexDirection="column" width={stacked ? 1 : [1, 3 / 5]}>
        <Flex bg="surfacePrimary" flexGrow={1} overflow="hidden" position="relative">
          {children}
        </Flex>
        <Flex
          alignItems="center"
          borderBottom={stacked ? 1 : [1, 0]}
          borderColor="surfacePrimaryBorder"
          borderTop={1}
          height="80px"
          minHeight="80px">
          {footer}
        </Flex>
      </Flex>

      <Flex
        borderColor="surfacePrimaryBorder"
        borderLeft="1px solid"
        flexDirection="column"
        pl={3}
        width={stacked ? undefined : [undefined, 2 / 5]}>
        <Box p={3} pl={0}>
          <Text fontSize={0} medium mb={1} upper data-test="document-overline">
            {overline}
          </Text>
          <Text fontSize={3} bold data-test="document-heading">
            {heading}
          </Text>
        </Box>

        <Divider borderColor="surfacePrimaryBorder" m={0} width={1} />

        <Flex flexDirection="column" flexGrow={1} overflowY="auto" width={1}>
          {statusMessage && (
            <Box pr={3} pt={3}>
              {statusMessage}
            </Box>
          )}

          {caption && <Caption caption={caption} />}
          {location && renderSection(<Location location={location} />)}

          {rooms && rooms.length > 0 && renderSection(<Rooms rooms={rooms} />)}
          {educators && educators.length > 0 && renderSection(<Educators educators={educators} />)}

          {futurePlanning &&
            renderSection(<FuturePlanning futurePlanning={futurePlanning} planningDate={planningDate} />)}

          {learningOutcomes &&
            learningOutcomes.length > 0 &&
            renderSection(<LearningOutcomes learningOutcomes={learningOutcomes} />)}

          {taggedChildren &&
            taggedChildren.length > 0 &&
            renderSection(<TaggedChildren taggedChildren={taggedChildren} />)}

          {hasLearningContentEnabled &&
            learningContentAssets &&
            learningContentAssets.length > 0 &&
            renderSection(<LearningContentAssets learningContentAssets={learningContentAssets} />)}

          <Flex flexDirection="column" flexGrow={1} py={3} pr={3}>
            {documentType != 'observation' && (
              <CommentPanel documentId={documentId} documentType={documentType} serviceFkey={serviceFkey} />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

DocumentRenderer.displayName = 'DocumentRenderer'

export default DocumentRenderer
