import React from 'react'

import { Box, Text } from 'stardust'

import { formatDate } from '~/utils'

const SectionHeading = ({ date }) => (
  <Box bg="transparent">
    <Text.p medium m={0} fontSize={2} pl={3}>
      {formatDate(date)}
    </Text.p>
  </Box>
)

SectionHeading.displayName = 'SectionHeading'
export default SectionHeading
