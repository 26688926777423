import React from 'react'

const Attachments = () => (
  <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.375 8.75022L11.2275 17.8986C9.03082 20.0953 5.46925 20.0953 3.27253 17.8986C1.07582 15.7018 1.07582 12.1403 3.27253 9.94356L10.7859 2.43106C11.7274 1.45622 13.1217 1.06526 14.4328 1.40844C15.7439 1.75163 16.7678 2.77554 17.111 4.08665C17.4542 5.39776 17.0632 6.79203 16.0884 7.73356L8.57587 15.2502C7.84027 15.9609 6.67082 15.9509 5.94745 15.2278C5.22409 14.5047 5.21374 13.3352 5.9242 12.5994L13.4375 5.08356"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Attachments
