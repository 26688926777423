import React from 'react'

import { isWebView } from '~/config'
import MomentModal from '~/pages/Moments/Modal'
import PlanModal from '~/pages/Planning/Modal'
import StoryModal from '~/pages/Stories/Modal'
import TableModal from '~/pages/Tables/Modal'

interface Props {
  document: Nullable<Playground.SimpleDocument>
  onClose(): void
}

const DocumentModalSelector = ({ document, onClose }: Props) => {
  if (!document) {
    return null
  }

  const handleOpenInNewTab = (url: string) => {
    window.open(url, '_blank')
  }

  return (
    <>
      {!isWebView && document.type === 'moment' && (
        <MomentModal id={document.typeId} show onClose={onClose} />
      )}
      {document.type === 'observation' &&
        handleOpenInNewTab(`/observations/${document.typeId}?status=${document.status?.toLowerCase()}`)}
      {document.type === 'learning_plan' && (
        <PlanModal id={document.typeId} show contentStatus={document.status} onClose={onClose} />
      )}
      {document.type === 'learning_story' && (
        <StoryModal id={document.typeId} show contentStatus={document.status} onClose={onClose} />
      )}
      {document.type === 'learning_table' && (
        <TableModal id={document.typeId} show contentStatus={document.status} onClose={onClose} />
      )}
    </>
  )
}

DocumentModalSelector.displayName = 'DocumentModalSelector'

export default DocumentModalSelector
