import React from 'react'

const Warning = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9688 16.9688C10.9688 16.3992 11.4305 15.9375 12 15.9375C12.5695 15.9375 13.0312 16.3992 13.0312 16.9688C13.0312 17.5383 12.5695 18 12 18C11.4305 18 10.9688 17.5383 10.9688 16.9688Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5.625C12.3797 5.625 12.6875 5.9328 12.6875 6.3125V13.875C12.6875 14.2547 12.3797 14.5625 12 14.5625C11.6203 14.5625 11.3125 14.2547 11.3125 13.875V6.3125C11.3125 5.9328 11.6203 5.625 12 5.625Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 12.5C1 6.42487 5.92487 1.5 12 1.5C18.0751 1.5 23 6.42487 23 12.5C23 18.5751 18.0751 23.5 12 23.5C5.92487 23.5 1 18.5751 1 12.5ZM12 2.875C6.68426 2.875 2.375 7.18426 2.375 12.5C2.375 17.8157 6.68426 22.125 12 22.125C17.3157 22.125 21.625 17.8157 21.625 12.5C21.625 7.18426 17.3157 2.875 12 2.875Z"
      fill="currentColor"
    />
  </svg>
)

export default Warning
