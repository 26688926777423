import gql from 'graphql-tag'

export const CREATE_LEARNING_RECORD = gql`
  mutation createLearningRecord(
    $serviceFkey: String!
    $childFkey: String!
    $outcomeId: Int!
    $ageGroupId: Int
    $rankId: Int
  ) {
    createLearningRecord(
      learningRecord: {
        serviceFkey: $serviceFkey
        childFkey: $childFkey
        outcomeId: $outcomeId
        ageGroupId: $ageGroupId
        rankId: $rankId
      }
    ) {
      id
      entityId
      entityType
      childFkey
      ageGroupId
      outcomeId
      rankId
      contributorId
      contributorRef
      contributorType
      achievedAt
    }
  }
`
