import { css } from 'styled-components'
import { BordersProps, borders } from 'styled-system'

export default css<BordersProps>`
  ${borders};

  background: ${(props) => props.theme.colors.surfacePrimary};
  border-color: ${(props) => props.theme.colors.surfacePrimaryBorder};
  color: ${(props) => props.theme.colors.textPrimary};
  outline: 0;
  transition: background 0.25s ease;
`
