import { css } from 'styled-components'

export default css`
  color: ${(props) => props.theme.colors.textPrimaryMedium};
  transition: background 0.25s ease;

  &:hover {
    background: ${(props) => props.theme.colors.hoverBackground};
  }

  text-decoration: none;
`
