import { BlockJSON, InlineJSON, NodeJSON, TextJSON } from 'slate'

import { toFkey } from '~/utils'

const isChildInFkeys = (child: Playground.Child, fkeys: string[]) => {
  return fkeys ? fkeys.includes(child.fkey ? child.fkey : toFkey(child.id || '')) : []
}

const isTaggedEntityInFkeys = (taggedEntity: Playground.TaggedEntity, fkeys: string[]) => {
  return fkeys.includes(taggedEntity.typeId ? toFkey(taggedEntity.typeId) : taggedEntity.typeFkey)
}

const filterChildrenByFkeys = (children: Playground.SimpleChild[], fkeys: string[]) => {
  return children.filter((child) => !isChildInFkeys(child, fkeys))
}

const removeChildrenFromChildrenNode = (
  node: BlockJSON,
  internalNodes: BlockJSON['nodes'],
  childFkeys: string[]
): BlockJSON => {
  const filteredChildrenNode: BlockJSON = {
    ...node,
    nodes: internalNodes,
    data: {
      ...node.data,
      children: filterChildrenByFkeys(node.data?.children, childFkeys),
    },
  }
  return filteredChildrenNode.data?.children.length === 0
    ? { ...node, nodes: internalNodes, data: {}, type: 'paragraph' }
    : filteredChildrenNode
}

const removeChildFromChildNode = (
  node: BlockJSON,
  internalNodes: BlockJSON['nodes'],
  childFkeys: string[]
): BlockJSON => {
  const childData = node.data as Playground.SimpleChild
  return isChildInFkeys(childData, childFkeys)
    ? { ...node, nodes: internalNodes, data: {}, type: 'paragraph' }
    : { ...node, nodes: internalNodes }
}

const removeChildrenFromMention = (
  node: InlineJSON,
  internalNodes: InlineJSON['nodes'],
  childFkeys: string[]
): TextJSON | InlineJSON => {
  return node.data?.type === 'child' &&
    isTaggedEntityInFkeys(node.data as Playground.TaggedEntity, childFkeys)
    ? { object: 'text', leaves: [{ text: '', marks: [] }] }
    : { ...node, nodes: internalNodes }
}

const removeChildrenFromNode = (node: NodeJSON, childFkeys: string[]): NodeJSON => {
  if (node.object === 'block') {
    const internalNodes = node.nodes?.map((n) => removeChildrenFromNode(n, childFkeys)) as BlockJSON['nodes']
    if (node?.type === 'children') {
      return removeChildrenFromChildrenNode(node, internalNodes, childFkeys)
    } else if (node?.type === 'child') {
      return removeChildFromChildNode(node, internalNodes, childFkeys)
    } else {
      return { ...node, nodes: internalNodes }
    }
  } else if (node.object === 'inline') {
    const internalNodes = node.nodes?.map((n) => removeChildrenFromNode(n, childFkeys)) as InlineJSON['nodes']
    if (node.type === 'mention') {
      return removeChildrenFromMention(node, internalNodes, childFkeys)
    } else {
      return { ...node, nodes: internalNodes }
    }
  } else if (node.object === 'document') {
    const internalNodes = (node.nodes?.map((n) => removeChildrenFromNode(n, childFkeys)) || []) as NodeJSON[]
    return { ...node, nodes: internalNodes }
  } else {
    return node
  }
}

export default removeChildrenFromNode
