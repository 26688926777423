import { SpaceDescriptor, SpaceDescriptorMap } from '../theme/types'
import space from '../tokens/space'

const stardustSpaceConversions: SpaceDescriptorMap = {
  none: `${space[0]}px`, // '0px',
  xxxsmall: `${space[1]}px`, // '4px',
  xxsmall: `${space[2]}px`, //'8px',
  xsmall: `${space[2]}px`, // '12px' in stardust but 8px in playground,
  small: `${space[3]}px`, // '16px',
  medium: `${space[3]}px`, // '24px' in stardust but 16px in playground,
  large: `${space[4]}px`, // '32px',
  xlarge: `${space[4]}px`, // '48px' in stardust but 32px in playground,
  xxlarge: `${space[5]}px`, // '64px',
  xxxlarge: `${space[6]}px`, // '128px',
}

function useSpace(value?: SpaceDescriptor | SpaceDescriptor[]) {
  if (value === undefined || value === null) return undefined
  return Array.isArray(value)
    ? value.map((v) => stardustSpaceConversions[v])
    : stardustSpaceConversions[value]
}

export default useSpace
