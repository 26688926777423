import React, { useEffect } from 'react'

import { Box } from 'stardust'
import styled from 'styled-components'

import DocumentCard from '~/components/DocumentCard'

import { groupWithInsertedAt } from '~/components/Documents/core'

import List from '~/components/Documents/List'
import SectionHeading from '~/components/Documents/SectionHeading'
import Cards from '~/components/Loading/Cards'

import { getCoverImageUrl, isNilOrEmpty } from '~/utils'

import PostLinksNoSearchResults from '../PostLinksNoSearchResults'

const GridContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
`

interface LinksDocumentsProps {
  handleCardClick: (doc: Playground.SimpleDocument) => void
  refetch: () => void
  fetchMore: (props: any) => void
  selectedTaggedDocs: string[]
  selectedSorting: string
  data: any
  isLoading: boolean
  isError: boolean
  scrollElementRef?: React.RefObject<HTMLDivElement>
}

const LinksDocuments: React.FC<LinksDocumentsProps> = ({
  handleCardClick,
  refetch,
  fetchMore,
  selectedTaggedDocs,
  data,
  selectedSorting,
  isLoading,
  isError,
  scrollElementRef,
}: LinksDocumentsProps) => {
  const DATA_PATH = ['context', 'documents']

  useEffect(() => {
    // Refetch to get the latest documents
    refetch()
  }, [refetch])

  if (isError) {
    return null
  }

  if (isNilOrEmpty(data) && isLoading) {
    return <Cards />
  }

  if (data?.context.documents.edges.length === 0) {
    return <PostLinksNoSearchResults displayText="There are no Learning Documents" />
  }

  const sortDocuments = (documents: Playground.SimpleDocument[]) => {
    switch (selectedSorting) {
      case 'Last created':
        return documents.sort((a, b) => {
          const dateA = new Date(a.insertedAt).getTime()
          const dateB = new Date(b.insertedAt).getTime()
          return dateB - dateA
        })

      case 'File name: A-Z':
        return documents.sort((a, b) => {
          const titleA = a.title || ''
          const titleB = b.title || ''
          return titleA.localeCompare(titleB)
        })

      case 'File name: Z-A':
        return documents.sort((a, b) => {
          const titleA = a.title || ''
          const titleB = b.title || ''
          return titleB.localeCompare(titleA)
        })

      default:
        // Default to sorting by Last modified if the selectedSorting is not recognized
        return documents.sort((a, b) => {
          const dateA = new Date(a.updatedAt).getTime()
          const dateB = new Date(b.updatedAt).getTime()
          return dateB - dateA
        })
    }
  }

  return (
    <List
      data={data}
      fetchMore={fetchMore}
      path={DATA_PATH}
      groupWith={groupWithInsertedAt}
      scrollElementRef={scrollElementRef}>
      {({ documents }: { documents: NonEmptyArray<Playground.Document> }) => {
        const sortedDocuments = sortDocuments(documents)
        const firstDocument = documents[0] as Nullable<Playground.SimpleDocument>
        const insertedAt = firstDocument?.insertedAt
        const typeId = firstDocument?.typeId

        return (
          <Box key={`${insertedAt}_${typeId}`} mt={4}>
            <SectionHeading date={insertedAt} />
            <GridContainer gap="16px" p={3}>
              {sortedDocuments.map((doc) => {
                return (
                  <DocumentCard
                    author={doc.author}
                    key={doc.typeId}
                    commentCount={doc.commentCount}
                    coverImage={getCoverImageUrl(doc)}
                    hasUnpublishedChanges={doc.hasUnpublishedChanges}
                    id={doc.typeId}
                    isPublished={doc.status === 'PUBLISHED' || doc.hasUnpublishedChanges}
                    title={doc.title}
                    type={doc.type}
                    isLinksCard
                    isSelected={selectedTaggedDocs.includes(doc.id)}
                    onClick={() => {
                      handleCardClick(doc)
                    }}
                    updatedAt={doc.updatedAt}
                  />
                )
              })}
            </GridContainer>
          </Box>
        )
      }}
    </List>
  )
}

export default LinksDocuments
