import React from 'react'

import { Flex, Icon, Text, FlexItem, SecondaryButton } from '~/ui-components'
import colors from '~/ui-components/tokens/colors'

export interface CustomToastProps {
  message: string
  icon?: string
  iconSize?: number
  buttonText?: string
  buttonAction?: () => void
}

const CustomToast = ({ message, icon, iconSize, buttonText, buttonAction }: CustomToastProps) => {
  return (
    <Flex alignItems="center">
      <Icon name={icon ? icon : 'tick'} size={iconSize ? iconSize : 20} mr={2} color={colors.nebulaBlue6} />
      <FlexItem flexGrow={1}>
        <Text bold fontSize={1} color="black" mr={4}>
          {message}
        </Text>
      </FlexItem>
      {buttonText && buttonAction && (
        <SecondaryButton
          borderRadius={16}
          outline
          height={2}
          width={4}
          p="17px 42px 17px 42px"
          onClick={buttonAction}>
          <Text fontSize="16px" fontWeight={500} color={colors.nebulaBlue6}>
            {buttonText}
          </Text>
        </SecondaryButton>
      )}
    </Flex>
  )
}

CustomToast.displayName = 'CustomToast'

export default React.memo(CustomToast)
