import React from 'react'

import * as R from 'ramda'

import { Box, Flex } from 'stardust'

import { toolbarSections } from '~/modules/textEditor/configs/advanced'

const mapIndexed = R.addIndex(R.map)

const EditorTools = ({ editor }) => (
  <Box screenOnly>
    {mapIndexed(
      (section, sectionKey) => (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          borderLeft={sectionKey == 0 ? 0 : 1}
          borderColor="surfacePrimaryBorder"
          px={2}
          key={sectionKey}>
          {R.pipe(
            R.reject(R.pipe(R.prop('renderToolbarItem'), R.isNil)),
            mapIndexed(({ renderToolbarItem }, sectionItemKey) => (
              <Box px={1} key={sectionItemKey}>
                {renderToolbarItem({ editor })}
              </Box>
            ))
          )(section)}
        </Flex>
      ),
      toolbarSections
    )}
  </Box>
)

EditorTools.displayName = 'EditorTools'

export default EditorTools
