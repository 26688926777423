import React from 'react'

import colors from '../tokens/colors'

const ChildrenAlt = ({ fill = colors.cosmicShade15 }) => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 6.16667C18.8333 5.66667 18.5 5.16667 18.1667 4.66667C16.3333 2.16667 13.3333 0.5 10 0.5C8.5 0.5 7.16667 0.833333 5.83333 1.5C5.83333 1.5 4.5 2.16667 4 2.5C1.66667 4.33333 0 7.16667 0 10.5C0 11.1667 0 11.6667 0.166667 12.3333C1 17 5.16667 20.5 10 20.5C15.5 20.5 20 16 20 10.5C20 9 19.6667 7.5 19 6.16667ZM10 1.75C12.8333 1.75 15.375 3.33333 16.875 5.5C15.875 6 14.2917 6.75 13.125 6.75C10.2917 6.75 7.125 5.16667 5.625 3C6.25 2.375 8.83333 1.75 10 1.75ZM3.75 4.25C3.41667 5.91667 2.41667 8 1.25 9.16667C1.58333 7.33333 2.58333 5.41667 3.75 4.25ZM10 19.25C5.66667 19.25 1.25 16.125 1.25 10.5C3.41667 8.83333 4.66667 7.08333 5 4.25C6.66667 6.41667 10.3333 8 13.3333 8C14.8333 8 16.5833 7.41667 17.75 6.75C18.25 7.91667 18.75 9.33333 18.75 10.5C18.75 15.1667 14.6667 19.25 10 19.25ZM8.75 11.125C8.75 11.4702 8.47018 11.75 8.125 11.75C7.77982 11.75 7.5 11.4702 7.5 11.125C7.5 10.7798 7.77982 10.5 8.125 10.5C8.47018 10.5 8.75 10.7798 8.75 11.125ZM11.875 11.75C12.2202 11.75 12.5 11.4702 12.5 11.125C12.5 10.7798 12.2202 10.5 11.875 10.5C11.5298 10.5 11.25 10.7798 11.25 11.125C11.25 11.4702 11.5298 11.75 11.875 11.75Z"
      fill={fill}
    />
  </svg>
)

export default ChildrenAlt
