import React, { useState } from 'react'

import { Location } from '@reach/router'
import * as queryString from 'query-string'

import { Box, Container, Divider, Empty, Flex, Heading, SelectField, Sticky, Text } from 'stardust'

import EmptyImage from '~/assets/images/empty.svg'
import SearchResults from '~/components/Search'
import { isWebView } from '~/config'
import { InfiniteScroll, Scroll, ScrollUp, hasAtLeastOne, isNilOrEmpty } from '~/utils'

import DocumentResults from './DocumentResults'
import ProfileResults from './ProfileResults'

const path = ['context', 'documents']

const filterTypes = (type) =>
  type === 'all'
    ? ['MOMENT', 'OBSERVATION', 'LEARNING_PLAN', 'LEARNING_STORY', 'LEARNING_TABLE']
    : type.toUpperCase()

const Search = (props) => {
  const [docTypeFilter, changeDocTypeFilter] = useState('all')
  const filterOptions = [
    { value: 'moment', label: 'Moments' },
    { value: 'observation', label: 'Posts' },
    {
      value: 'learning_story',
      label: 'Documents',
    },
    {
      value: 'learning_plan',
      label: 'Canvases',
    },
    { value: 'learning_table', label: 'Tables' },
    { value: 'all', label: 'All' },
  ]
  return (
    <Location>
      {({ location }) => {
        const { query, documentId, type } = queryString.parse(location.search)

        return (
          <>
            {!isWebView && (
              <Sticky zIndex={1} top="94px" left={0}>
                <Box height="120px" bg="surfacePrimary">
                  <Container>
                    <Box px={5}>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Heading.h2 px="0" py={4} my="0" bold={false} regular={true}>
                          Search Results
                        </Heading.h2>
                        <Flex justifyContent="flex-end" alignItems="center">
                          <Box width="200px">
                            <SelectField
                              label="File Types"
                              onChange={(e) => changeDocTypeFilter(e.target.value)}
                              options={filterOptions}
                              value={docTypeFilter}
                            />
                          </Box>
                        </Flex>
                      </Flex>
                    </Box>
                  </Container>
                  <Divider mt="-1px;" mb="0" borderColor="dividerBackground" />
                </Box>
              </Sticky>
            )}

            <SearchResults
              query={query}
              serviceFkey={props.serviceFkey}
              numberResults={50}
              types={type ? [type] : filterTypes(docTypeFilter)}>
              {(data, fetchMore) => {
                const profiles = data.context.taggableEntities
                const documents = data.context.documents.edges.map((edge) => edge.node)
                if (isNilOrEmpty(profiles) && isNilOrEmpty(documents)) {
                  return (
                    <Box my={5}>
                      <Empty heading="Results Found" image={EmptyImage} />
                    </Box>
                  )
                }

                return (
                  <ScrollUp>
                    {!isWebView && hasAtLeastOne(profiles) && (
                      <>
                        <Container>
                          <Box pt={3} pb={3} px={5}>
                            <Text caps fontSize={2} light color="textPrimaryMedium">
                              Profiles
                            </Text>
                          </Box>
                        </Container>
                        <Box bg="surfacePrimary" width="100%">
                          <ProfileResults profiles={profiles} />
                        </Box>
                      </>
                    )}
                    {hasAtLeastOne(documents) && (
                      <>
                        {!isWebView && (
                          <Container>
                            <Box pt={3} pb={2}>
                              <Text pt={2} pb="0" caps fontSize={2} light color="textPrimaryMedium">
                                Files
                              </Text>
                            </Box>
                          </Container>
                        )}
                        <Scroll
                          render={({ pageYOffset, innerHeight }) => (
                            <InfiniteScroll
                              data={data}
                              fetchMore={fetchMore}
                              innerHeight={innerHeight}
                              pageYOffset={pageYOffset}
                              path={path}>
                              <DocumentResults documents={documents} selectedDocId={documentId} />
                            </InfiniteScroll>
                          )}
                        />
                      </>
                    )}
                  </ScrollUp>
                )
              }}
            </SearchResults>
          </>
        )
      }}
    </Location>
  )
}

Search.displayName = 'Search'

export default Search
