import React from 'react'

import { Box, Container, Flex, StencilLine } from 'stardust'

const Loading = () => (
  <Flex flexDirection="column">
    <Box bg="rgba(225, 225, 225, 0.7)" height={96} width={1} />
    <Container>
      <Flex flexDirection="column" mb={4} mt={5} width={1}>
        <StencilLine mb={2} py={2} width={1 / 3} />
        <Box bg="rgba(225, 225, 225, 0.7)" borderRadius={1} height={340} width={1} />
      </Flex>
      <Flex width={1}>
        <Flex flexDirection="column" mr={4} width={[3 / 5]}>
          <StencilLine mb={2} py={2} width={1 / 3} />
          <Box bg="rgba(225, 225, 225, 0.7)" borderRadius={1} height={340} />
        </Flex>
        <Flex flexDirection="column" width={[2 / 5]}>
          <StencilLine mb={2} py={2} width={2 / 3} />
          <Box bg="rgba(225, 225, 225, 0.7)" borderRadius={1} height={340} />
        </Flex>
      </Flex>
    </Container>
  </Flex>
)

export default React.memo(Loading)
