import React from 'react'

import { Link } from '@reach/router'
import { Box, Flex, Icon, Text } from 'stardust'
import styled from 'styled-components'

import * as CanvasAnalytics from '~/modules/analytics/canvas'
import * as DocumentAnalytics from '~/modules/analytics/documents'

const StyledLink = styled(Link)`
  text-decoration: none;
`

const StyledCard = styled(Flex)`
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: ${(props) => props.theme.shadows['1dp']};
  }
`

interface Props {
  addText: string
  type: string
  to: string
  onTileClick?: () => void
}

const callGoogleAnalytics = (type: string): void => {
  if (type === 'LEARNING_PLAN') {
    CanvasAnalytics.blankCanvasCreated()
  }
  if (type === 'LEARNING_STORY') {
    DocumentAnalytics.createNewDocument()
  }
}

const LayoutAdd = ({ addText, to, type, onTileClick }: Props) => {
  return (
    <StyledLink
      to={to}
      onClick={() => {
        callGoogleAnalytics(type)
        if (onTileClick) onTileClick()
      }}>
      <StyledCard
        alignItems="center"
        border="1px solid"
        borderColor="cosmicShade6"
        borderRadius="16px"
        flexDirection="column"
        height="140px"
        justifyContent="center"
        width="140px"
        data-test="layout-new">
        <Box color="primary" mb={3}>
          <Icon name="add" fill="primary" />
        </Box>
        <Text.span caps color="primary" fontSize={1}>
          {addText}
        </Text.span>
      </StyledCard>
    </StyledLink>
  )
}

LayoutAdd.displayName = 'LayoutAdd'

export default LayoutAdd
