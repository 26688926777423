import React, { forwardRef } from 'react'

import { Absolute } from 'stardust'
import styled from 'styled-components'
import { color } from 'styled-system'

const Container = styled(Absolute)`
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: grab;
  ${color};
`

const ZoomLayer = forwardRef(({ background, ...rest }, ref) => (
  <Container ref={ref} bg={background} {...rest} />
))

ZoomLayer.displayName = 'ZoomLayer'
export default ZoomLayer
