/* eslint react/display-name: 0 */

import React from 'react'

import * as R from 'ramda'

import { Block } from 'slate'
import { getEventRange } from 'slate-react'

import { Link } from 'stardust'
import styled from 'styled-components'

import ProfileChip from '~/components/Profiles/Chip'
import { toFkey } from '~/utils'

const StyledProfileChip = styled(ProfileChip)`
  margin-bottom: -8px;
`

// hydrating entity with extra props from matching resource in request
const hydrateRoom = ({ rooms }, room) => {
  const safeRooms = rooms || []
  const matchedRoom = safeRooms.find((safeRoom) => safeRoom.fkey == (room.fkey ? room.fkey : toFkey(room.id)))

  return matchedRoom ? matchedRoom : room
}

const getRoomData = R.pipe(
  (v) => v.document.filterDescendants(R.propEq('type', 'room')).toJS(),
  R.map(({ data: { id, fkey, name } }) => ({
    type: 'room',
    typeFkey: fkey ? fkey : toFkey(id),
    displayText: name,
  })),
  R.uniq
)

export default {
  serialize: (obj) => {
    if (obj.object === 'block' && obj.type === 'room') {
      const { data } = obj.toJS()

      const roomFkey = data.fkey ? data.fkey : toFkey(data.id)

      return (
        <Link to={`/rooms/${roomFkey}`} target="_blank">
          <ProfileChip xxsmall fullName={data.name} />
        </Link>
      )
    }
  },

  onQuery: ({ type }, { value }, next) => {
    if (type === 'rooms') {
      return getRoomData(value)
    }

    return next()
  },

  // TO.DO:AP None of the article types have the ability to drag/drop rooms anymore (that feature was removed at some stage)
  onDrop: (e, { editor }, next) => {
    const droppedData = e.dataTransfer.getData('data')

    if (!droppedData) {
      return
    }

    const data = JSON.parse(droppedData)

    if (data.type !== 'room') {
      return next()
    }

    return editor.change((change) => {
      change.insertBlockAtRange(getEventRange(e, editor), Block.create(data))
    })
  },

  renderNode: ({ attributes, children, node, editor }, next) => {
    if (node.type !== 'room') {
      return next()
    }

    const valueData = R.pipe(
      R.path(['value', 'data']),
      R.ifElse(R.isNil, R.always({}), (x) => x.toJS())
    )(editor)

    const room = hydrateRoom(valueData, node.data.toJS())

    return (
      <>
        <StyledProfileChip xxsmall fullName={room.name} {...attributes} />
        {children}
      </>
    )
  },
}
