import gql from 'graphql-tag'

import { ROOM_INFO_FRAGMENT, ROOM_METRICS_FRAGMENT } from '~/modules/rooms'

export const GET_ROOM_DOCUMENTS = gql`
  query GetRoomDocuments($cursor: String, $roomFkey: String!, $serviceFkey: String!) {
    service(fkey: $serviceFkey) {
      fkey
      room(fkey: $roomFkey) {
        fkey
        documents(first: 20, after: $cursor) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              author
              updatedAt
              coverImage
              insertedAt
              thumbImage
              title
              type
              typeId
              status
              hasUnpublishedChanges
              media {
                mimeType
                coverUrl
              }
            }
          }
        }
      }
    }
  }
`

export const GET_ROOM_SUMMARY = gql`
  query GetRoomSummary($serviceFkey: String!, $roomFkey: String!, $dateRange: DateRange!) {
    service(fkey: $serviceFkey) {
      fkey
      room(fkey: $roomFkey) {
        fkey
        ...RoomInfo
        ...RoomMetrics
      }
    }
  }
  ${ROOM_INFO_FRAGMENT}
  ${ROOM_METRICS_FRAGMENT}
`

export const GET_ROOM = gql`
  query GetRoomSummary($serviceFkey: String!, $roomFkey: String!) {
    service(fkey: $serviceFkey) {
      fkey
      room(fkey: $roomFkey) {
        fkey
        name
      }
    }
  }
`

export const LIST_ROOMS = gql`
  query ListRooms($serviceFkey: String!, $dateRange: DateRange!) {
    service(fkey: $serviceFkey) {
      fkey
      rooms {
        fkey
        name
        metrics(dateRange: $dateRange) {
          healthEvents
          observations
        }
        type
      }
    }
  }
`

export const LIST_ROOMS_INFO = gql`
  query ListRooms($serviceFkey: String!) {
    service(fkey: $serviceFkey) {
      fkey
      rooms {
        fkey
        name
        type
      }
    }
  }
`
