import React from 'react'

import Read from '../Editor/Read'

const Content = (props) => {
  return <Read {...props} />
}

Content.displayName = 'Content'

export default Content
