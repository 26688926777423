import React from 'react'

const Numbered = () => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M2.5 17h2v.5h-1v1h1v.5h-2v1h3v-4h-3v1zm1-9h1V4h-2v1h1v3zm-1 3h1.8l-1.8 2.1v.9h3v-1H3.7l1.8-2.1V10h-3v1zm5-6v2h14V5h-14zm0 14h14v-2h-14v2zm0-6h14v-2h-14v2z"
    />
  </svg>
)

export default Numbered
