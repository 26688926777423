import React, { useEffect } from 'react'

import { Box } from 'stardust'

import styled from 'styled-components'

import colors from '~/ui-components/tokens/colors'

import BookedInAndSignedInChildren from './BookedInAndSignedInChildren'

import ChildBox from './ChildBox'

import DisplayAllChildren from './DisplayAllChildren'

const TagChildrenResultContainer = styled(Box)`
  max-height: 35vh;
  overflow-y: auto;
`

interface TagChildrenResultsProps {
  roomFkey: string
  serviceFkey: string
  searchTerm: string
  rooms?: Playground.Room[]
  childrenList?: Playground.SimpleChild[]
  selectedChildrenToTag?: Playground.SimpleChild[]
  childrenInCurrentView?: Playground.SimpleChild[]
  isTagged?: boolean
  expandAll?: boolean
  sortChildren: boolean
  learningFrameworks?: Playground.LearningFramework[]
  learningOutcomes?: Playground.LearningFrameworkOutcome[]
  viewSelected: boolean
  selectedDate: string
  filteringByGroup: string
  bookedInAndSignInChildren?: Playground.SimpleChild[]
  setSelectedChildrenToTag: React.Dispatch<React.SetStateAction<any[] | never[]>>
  setChildrenInCurrentView: React.Dispatch<React.SetStateAction<any[] | never[]>>
  setBookedInAndSignInChildren: React.Dispatch<React.SetStateAction<any[] | never[]>>
}

const TagChildrenResult = ({
  rooms,
  roomFkey,
  expandAll,
  serviceFkey,
  selectedDate,
  sortChildren,
  childrenList,
  selectedChildrenToTag,
  childrenInCurrentView,
  viewSelected,
  searchTerm,
  filteringByGroup,
  bookedInAndSignInChildren,
  setSelectedChildrenToTag,
  setChildrenInCurrentView,
  setBookedInAndSignInChildren,
}: TagChildrenResultsProps) => {
  useEffect(() => {
    if (
      ((roomFkey === '' && selectedDate === '') ||
        (roomFkey === 'All room' && (selectedDate === '' || selectedDate !== ''))) &&
      (filteringByGroup === 'Default room' || filteringByGroup === 'All children')
    ) {
      setChildrenInCurrentView(viewSelected ? selectedChildrenToTag || [] : childrenList || [])
    }

    if (
      roomFkey !== '' &&
      roomFkey !== 'All room' &&
      (filteringByGroup === 'Default room' || filteringByGroup === 'All children')
    ) {
      const displayChildrenForRoom =
        childrenList && childrenList.filter((child) => (roomFkey ? child?.room?.fkey === roomFkey : true))
      const displayChildren = viewSelected
        ? displayChildrenForRoom &&
          displayChildrenForRoom.filter(
            (child) =>
              selectedChildrenToTag &&
              selectedChildrenToTag.some((selectedChild) => selectedChild.fkey === child.fkey)
          )
        : displayChildrenForRoom

      setChildrenInCurrentView(displayChildren || [])
    }

    if (selectedDate !== '' && (filteringByGroup === 'All children' || filteringByGroup === 'Default room')) {
      let allChild = childrenList || []

      if (roomFkey !== 'All room' && roomFkey !== '') {
        allChild = allChild.filter((child) => child?.room?.fkey === roomFkey)
      }

      const includeSignedInandBookedInChildren =
        bookedInAndSignInChildren
          ?.filter((child) => child.roomFkey === roomFkey)
          .map((bookedInChild) => childrenList?.find((child) => child.fkey === bookedInChild.childFkey))
          .filter((child): child is Playground.SimpleChild => !!child)
          .filter((child) => !allChild.some((existingChild) => existingChild.fkey === child.fkey)) || []

      const displayChildren = viewSelected
        ? selectedChildrenToTag
        : [...allChild, ...(filteringByGroup === 'Default room' ? [] : includeSignedInandBookedInChildren)]

      setChildrenInCurrentView(displayChildren || [])
    }

    if (
      (roomFkey === '' || roomFkey === 'All room') &&
      (filteringByGroup === 'Booked in' || filteringByGroup === 'Signed in')
    ) {
      const filterChildrens =
        bookedInAndSignInChildren &&
        bookedInAndSignInChildren
          .filter((child) =>
            filteringByGroup === 'Signed in'
              ? child && child.attendances && child.attendances.length > 0
              : child && child.attendances && child.attendances.length === 0
          )
          .map((bookedInChild) => childrenList?.find((child) => child.fkey === bookedInChild.childFkey))
          .filter((child): child is Playground.SimpleChild => !!child)

      const displayChildren = viewSelected
        ? filterChildrens &&
          filterChildrens.filter(
            (child) =>
              selectedChildrenToTag &&
              selectedChildrenToTag.some((selectedChild) => selectedChild.fkey === child.fkey)
          )
        : filterChildrens

      setChildrenInCurrentView(displayChildren || [])
    }

    if (
      (filteringByGroup === 'Booked in' || filteringByGroup === 'Signed in') &&
      roomFkey !== 'All room' &&
      roomFkey !== ''
    ) {
      const filterChildrens =
        bookedInAndSignInChildren &&
        bookedInAndSignInChildren
          .filter((child) =>
            filteringByGroup === 'Signed in'
              ? child && child.attendances && child.attendances.length > 0
              : child && child.attendances && child.attendances.length === 0
          )
          .map((bookedInChild) => childrenList?.find((child) => child.fkey === bookedInChild.childFkey))
          .filter((child): child is Playground.SimpleChild => !!child)

      const displayChildren = viewSelected
        ? filterChildrens &&
          filterChildrens.filter(
            (child) =>
              selectedChildrenToTag &&
              selectedChildrenToTag.some((selectedChild) => selectedChild.fkey === child.fkey)
          )
        : filterChildrens

      const AssignedRoomToChilds =
        displayChildren &&
        displayChildren.map((child) => {
          const roomFkey = bookedInAndSignInChildren?.find(
            (bookedChild) => bookedChild.childFkey === child.fkey
          )?.roomFkey

          const updatedChild = { ...child, roomAssigned: { fkey: roomFkey } }
          return updatedChild
        })

      const finalizedChildrensToDisplay =
        AssignedRoomToChilds && AssignedRoomToChilds.filter((child) => child?.roomAssigned?.fkey === roomFkey)
      setChildrenInCurrentView(finalizedChildrensToDisplay || [])
    }

    if (searchTerm) {
      // If searchTerm is present, filter based on it
      const filteredChildren = (childrenList || []).filter(
        (child) =>
          (!roomFkey || child?.room?.fkey === roomFkey) &&
          (child.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            child.fkey.toLowerCase().includes(searchTerm.toLowerCase()))
      )

      const displayChildren = viewSelected
        ? filteredChildren &&
          filteredChildren.filter(
            (child) =>
              selectedChildrenToTag &&
              selectedChildrenToTag.some((selectedChild) => selectedChild.fkey === child.fkey)
          )
        : filteredChildren

      setChildrenInCurrentView(displayChildren || [])
    }
  }, [
    roomFkey,
    searchTerm,
    selectedDate,
    viewSelected,
    childrenList,
    filteringByGroup,
    selectedChildrenToTag,
    bookedInAndSignInChildren,
    setChildrenInCurrentView,
  ])

  const isChecked = (fkey: string | undefined) => {
    return selectedChildrenToTag?.some((child) => child.fkey === fkey) ?? false
  }

  const getStyles = (fkey: string | undefined) => {
    const isSelected = selectedChildrenToTag?.some((child) => child.fkey === fkey) ?? false

    if (roomFkey === 'All room' && !isSelected) {
      return {
        border: `1px solid ${colors.cosmicShade6}`,
        background: colors.cosmicShade0,
      }
    }

    return {
      border: isSelected ? `1px solid ${colors.nebulaBlue4}` : 'none',
      background: isSelected ? colors.nebulaBlue0 : colors.cosmicShade0,
    }
  }

  const handleChildClicked = (childFkey: string | undefined) => {
    setSelectedChildrenToTag((prevChildIds) => {
      const isSelected = prevChildIds.some((fkey) =>
        typeof fkey === 'object' ? fkey.fkey === childFkey : fkey === childFkey
      )
      const childObject = childrenList?.find((child) => child.fkey === childFkey)

      return isSelected
        ? prevChildIds.filter((fkey) =>
            typeof fkey === 'object' ? fkey.fkey !== childFkey : fkey !== childFkey
          )
        : childObject
        ? [...prevChildIds, childObject]
        : prevChildIds
    })
  }

  if (searchTerm) {
    const searchResult =
      searchTerm && roomFkey !== 'All room' && roomFkey !== ''
        ? searchTerm &&
          childrenInCurrentView &&
          childrenInCurrentView.filter(
            (child) =>
              child.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
              child.fkey.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : childrenInCurrentView &&
          childrenInCurrentView.filter(
            (child) =>
              child.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
              child.fkey.toLowerCase().includes(searchTerm.toLowerCase())
          )

    return (
      <TagChildrenResultContainer pl={3} pt={3} pr={3}>
        {searchResult &&
          searchResult.map((child) => {
            return (
              <ChildBox
                key={child.fkey}
                child={child}
                border={getStyles(child.fkey).border}
                background={getStyles(child.fkey).background}
                handleChildClicked={() => handleChildClicked(child.fkey)}
                isChecked={() => isChecked(child.fkey)}
              />
            )
          })}
      </TagChildrenResultContainer>
    )
  }

  if (
    roomFkey === 'All room' &&
    (selectedDate !== '' || selectedDate === '') &&
    (filteringByGroup === 'All children' || filteringByGroup === 'Default room')
  ) {
    return (
      <DisplayAllChildren
        expandAll={expandAll}
        childrenList={childrenList}
        selectedChildrenToTag={selectedChildrenToTag}
        viewSelected={viewSelected}
        sortChildren={sortChildren}
        handleChildClicked={handleChildClicked}
        isChecked={isChecked}
        getStyles={getStyles}
        setSelectedChildrenToTag={setSelectedChildrenToTag}
      />
    )
  }

  if ((roomFkey === '' || roomFkey !== '') && selectedDate !== '') {
    return (
      <BookedInAndSignedInChildren
        rooms={rooms}
        roomFkey={roomFkey}
        serviceFkey={serviceFkey}
        selectedDate={selectedDate}
        viewSelected={viewSelected}
        childrenList={childrenList}
        sortChildren={sortChildren}
        selectedChildrenToTag={selectedChildrenToTag}
        filteringByGroup={filteringByGroup}
        bookedInAndSignInChildren={bookedInAndSignInChildren}
        isChecked={isChecked}
        getStyles={getStyles}
        handleChildClicked={handleChildClicked}
        setSelectedChildrenToTag={setSelectedChildrenToTag}
        setBookedInAndSignInChildren={setBookedInAndSignInChildren}
      />
    )
  }

  const displayChildren = viewSelected ? selectedChildrenToTag : childrenList

  return (
    <TagChildrenResultContainer pt={3}>
      {displayChildren &&
        displayChildren
          .filter((child) => (roomFkey ? child?.room?.fkey === roomFkey : true))
          .map((child) => {
            return (
              <ChildBox
                key={child.fkey}
                child={child}
                border={getStyles(child.fkey).border}
                background={getStyles(child.fkey).background}
                handleChildClicked={() => handleChildClicked(child.fkey)}
                isChecked={() => isChecked(child.fkey)}
              />
            )
          })}
    </TagChildrenResultContainer>
  )
}

TagChildrenResult.displayName = 'TagChildrenResult'

export default React.memo(TagChildrenResult)
