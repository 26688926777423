import { ChildAgeRange } from '~/pages/LearningAnalysis/Context'

const getMinLowerAge = (childAgeRanges: ChildAgeRange[]): number => {
  return Math.min(...childAgeRanges.map((range) => range.lower))
}

const getMaxLowerAge = (childAgeRanges: ChildAgeRange[]): number => {
  return Math.max(...childAgeRanges.map((range) => range.lower))
}

const getMaxUpperAge = (childAgeRanges: ChildAgeRange[]): number => {
  return Math.max(...childAgeRanges.map((range) => range.upper))
}

const getLowerAges = (childAgeRanges: ChildAgeRange[]): number[] => {
  const lowerAges = childAgeRanges.map((range) => range.lower)
  lowerAges.sort((a, b) => a - b)
  return lowerAges
}

const generateRangeArray = (lower: number, upper: number) => {
  const len = upper - lower + 1
  return Array.from(new Array(len), (_num, idx) => idx + lower)
}

const checkConsecutiveRanges = (childAgeRanges: ChildAgeRange[]): boolean => {
  return (
    generateRangeArray(getMinLowerAge(childAgeRanges), getMaxLowerAge(childAgeRanges)).toString() ==
    getLowerAges(childAgeRanges).toString()
  )
}

const getChildAgesLabel = (childAgeRanges: ChildAgeRange[], allChildAgeRanges: ChildAgeRange[]) => {
  if (childAgeRanges.length === allChildAgeRanges.length) {
    return 'All Child Ages'
  } else if (childAgeRanges.length === 0) {
    return 'No Child Ages'
  } else if (checkConsecutiveRanges(childAgeRanges) && getMaxUpperAge(childAgeRanges) !== Infinity) {
    return `Ages ${getMinLowerAge(childAgeRanges)} to ${getMaxUpperAge(childAgeRanges)}`
  } else if (checkConsecutiveRanges(childAgeRanges)) {
    return `Ages ${getMinLowerAge(childAgeRanges)}+`
  } else {
    childAgeRanges.sort((a, b) => a.lower - b.lower)

    const outputRanges: string[] = []
    childAgeRanges.forEach((range) => {
      if (range.upper !== Infinity) {
        outputRanges.push(`${range.lower}-${range.upper}`)
      } else {
        outputRanges.push(`${range.lower}+`)
      }
    })
    return 'Ages ' + outputRanges.join(', ')
  }
}

export default getChildAgesLabel
