import { useEffect } from 'react'

interface Rectangle {
  bottom: number
  height: number
  left: number
  right: number
  top: number
  width: number
}

const getRectangle = (element: HTMLElement): Rectangle => {
  const offsetX = window.scrollX
  const offsetY = window.scrollY

  const { top, right, bottom, left, width, height } = element.getBoundingClientRect()

  return {
    top: top + offsetY,
    right: right + offsetX,
    bottom: bottom + offsetY,
    left: left + offsetX,
    width,
    height,
  }
}

const findScrollParent = (node: any): any => {
  if (node == null) {
    return null
  }

  if (node.scrollHeight > node.clientHeight) {
    return node
  }

  return findScrollParent(node.parentNode)
}

type Handler = (rectangle: Rectangle) => void

function useScroll(elementRef: any, handlePosChange: Nullable<Handler>) {
  useEffect(() => {
    if (!elementRef || !elementRef.current || !handlePosChange) {
      return
    }

    handlePosChange(getRectangle(elementRef.current))

    const handleScroll = () => handlePosChange(getRectangle(elementRef.current))
    const scrollableParent = findScrollParent(elementRef.current)

    if (!scrollableParent) {
      return
    }

    scrollableParent.addEventListener('scroll', handleScroll)

    return () => scrollableParent.removeEventListener('scroll', handleScroll)
  })
}

export default useScroll
