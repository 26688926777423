import React from 'react'

const Reports = () => (
  <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.875 5.62417V4.37417C16.875 3.68381 16.3153 3.12417 15.625 3.12417H12.2916"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.20833 3.12417H1.875C1.18464 3.12417 0.625 3.68381 0.625 4.37417V18.1242C0.625 18.8145 1.18464 19.3742 1.875 19.3742H5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0175 3.94667C11.9323 4.20163 11.6938 4.37371 11.425 4.37417H6.07584C5.80672 4.37407 5.5678 4.20192 5.48251 3.94667L4.64918 1.44667C4.58528 1.25601 4.61715 1.04625 4.73479 0.883172C4.85243 0.720096 5.04143 0.623682 5.24251 0.62417H12.2575C12.4586 0.623682 12.6476 0.720096 12.7652 0.883172C12.8829 1.04625 12.9147 1.25601 12.8508 1.44667L12.0175 3.94667Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.625 16.8742H3.75C3.40482 16.8742 3.125 16.5943 3.125 16.2492V5.62417"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1267 6.24917V8.12417"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.625 8.12417H9.375"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.625 11.8742H6.875"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8708 18.9325L7.77747 19.3742L8.21913 16.2808L16.1741 8.32584C16.9064 7.5936 18.0936 7.5936 18.8258 8.32584C19.558 9.05807 19.558 10.2453 18.8258 10.9775L10.8708 18.9325Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Reports
