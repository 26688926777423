import React, { useCallback, useContext, useState } from 'react'

import { OutlineButton } from 'stardust'

import { shortAge } from '~/components/Profiles/core'
import { sortObjectsBy } from '~/utils'

import { LearningAnalysisContext } from '../Context'

interface Props {
  records: Playground.LearningRecord[]
}

type RecordLookup = Record<number, Playground.LearningRecord[]>
type AgeGroupLookup = Record<number, RecordLookup>
type ChildCategoryLookup = Record<string, AgeGroupLookup>

const ExportButton = ({ records }: Props) => {
  const { ageGroups, categories, children, cohortsEnabled, findAgeGroup, findOutcome, findRank } =
    useContext(LearningAnalysisContext)
  const [processing, setProcessing] = useState(false)

  // https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
  const onExport = useCallback(() => {
    setProcessing(true)

    const buildUKExport = (rawRecordLookup: ChildCategoryLookup): string[][] => {
      const header = ['', 'DOB', 'Age', 'Age Group', ...categories.primary.map((category) => category.name)]

      const rows = children
        .map((child: Playground.SimpleChild) =>
          ageGroups.map((ageGroup) => {
            const categoryRanks = categories.primary.map((category) => {
              const records = rawRecordLookup?.[child.fkey]?.[ageGroup.id]?.[category.id] || []
              const sortedRecords = sortObjectsBy(records, 'achievedAt').reverse()
              const recordsWithRanks = sortedRecords.filter(
                (record: any) => record.rankId !== null && record.rankId !== undefined
              ) as Playground.LearningRecord[]
              return recordsWithRanks.length > 0 ? findRank(recordsWithRanks[0].rankId!)?.name || '' : ''
            })
            const validRanks = categoryRanks.filter((rank) => rank !== '')
            return validRanks.length > 0
              ? [child.fullName, child.dob || '', shortAge(child.dob), ageGroup.name, ...categoryRanks]
              : [child.fullName, child.dob || '', shortAge(child.dob), ageGroup.name]
          })
        )
        .flat()
      return [header, ...rows]
    }

    const buildAUExport = (rawRecordLookup: ChildCategoryLookup): (string | number)[][] => {
      const header = ['', 'DOB', 'Age', ...categories.primary.map((category) => category.name)]
      const rows = children.map((child: Playground.SimpleChild) => {
        const categoryCounts = categories.primary.map((category) =>
          rawRecordLookup[child.fkey]
            ? Object.values(rawRecordLookup[child.fkey]).reduce((total, ageGroupCategoryRecords) => {
                const records = ageGroupCategoryRecords[category.id]
                return records ? total + records.length : total
              }, 0)
            : 0
        )
        return [child.fullName, child.dob || '', shortAge(child.dob), ...categoryCounts]
      })
      return [header, ...rows]
    }

    // Put the records into a lookup map by their child, ageGroup and category
    const rawRecordLookup: ChildCategoryLookup = records.reduce((acc, record) => {
      const outcome = findOutcome(record.outcomeId)
      const ageGroup = record.ageGroupId && findAgeGroup(record.ageGroupId)
      if (!outcome || (!ageGroup && cohortsEnabled)) return acc

      const childRecords = acc[record.childFkey!] || {}
      const ageGroupRecords = childRecords[record.ageGroupId!] || {}
      const categoryRecords = ageGroupRecords[outcome.primaryId] || []

      ageGroupRecords[outcome.primaryId] = [...categoryRecords, record]
      childRecords[record.ageGroupId!] = ageGroupRecords
      acc[record.childFkey!] = childRecords

      return acc
    }, {} as ChildCategoryLookup)

    const content = cohortsEnabled ? buildUKExport(rawRecordLookup) : buildAUExport(rawRecordLookup)

    const csvContent = 'data:text/csv;charset=utf-8,' + content.map((e) => e.join(',')).join('\n')
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')

    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'learning-outcomes.csv')
    document.body.appendChild(link)

    link.click()

    setProcessing(false)
  }, [ageGroups, categories, children, cohortsEnabled, findAgeGroup, findOutcome, findRank, records])

  return (
    <OutlineButton disabled={processing} width="100px" onClick={onExport}>
      {processing ? 'Generating...' : 'Export'}
    </OutlineButton>
  )
}

ExportButton.displayName = 'ExportButton'

export default React.memo(ExportButton)
