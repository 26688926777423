import React, { useMemo } from 'react'

import Chart from 'react-apexcharts'
import colors from 'stardust/tokens/colors/nebulaBlue'
import styled from 'styled-components'

export interface DonutChartDatum {
  label: string
  value: number
}

interface Props {
  colors?: string[]
  data: DonutChartDatum[]
  height?: number | string
  width?: number | string
}

export const primaryColors = [
  colors.nebulaBlue8,
  colors.nebulaBlue6,
  colors.nebulaBlue5,
  colors.nebulaBlue4,
  colors.nebulaBlue3,
  colors.nebulaBlue2,
  colors.nebulaBlue1,
]

const StyledChart = styled(Chart)`
  .apexcharts-tooltip {
    white-space: pre-wrap !important;
    max-width: 250px;
  }
`

const DonutChart = ({ data, height, width = '100%', colors = primaryColors }: Props) => {
  const options = useMemo(() => {
    return {
      chart: {
        toolbar: {
          show: false,
        },
      },
      colors: colors,
      dataLabels: {
        enabled: false,
      },
      labels: data.map((datum) => datum.label),
      legend: {
        show: true,
        fontSize: '12px',
        offsetY: -14,
        markers: {
          height: 16,
          radius: 4,
          offsetX: -6,
          offsetY: 3,
          width: 16,
        },
        itemMargin: {
          vertical: 4,
        },
        formatter: function (seriesName: string) {
          return seriesName.length > 40 ? [seriesName.substr(0, 40), '...'] : seriesName
        },
      },
      noData: {
        text: 'No data available for this range',
        align: 'center',
        verticalAlign: 'middle',
        style: {
          fontSize: '14px',
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: '70%',
          },
        },
      },
      tooltip: {
        y: {
          formatter: (value: any) => `${(value * 100).toFixed(1)}%`,
        },
      },
    }
  }, [data, colors])

  const series = useMemo(() => data.map((datum) => datum.value), [data])

  return <StyledChart type="donut" options={options} series={series} height={height} width={width} />
}

DonutChart.displayName = 'DonutChart'

export default React.memo(DonutChart)
