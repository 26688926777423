/* eslint react/display-name: 0 */
import React from 'react'

import { Mark } from 'slate'
import { RenderMarkProps } from 'slate-react'

import ToolbarButton from '~/components/Editors/components/Toolbar/components/ToolbarButton'

import { Next, RenderToolbarItemProps, SerializeProps } from '../types'

interface PluginProps {
  markType: string
  elementType: keyof JSX.IntrinsicElements
  iconName: string
}

enum ElementTypes {
  STRONG = 'strong',
  EM = 'em',
}

const buildPlugin = (options: PluginProps) => ({
  serialize: (obj: SerializeProps, children: any) => {
    if (obj.object === 'mark' && obj.type === options.markType) {
      return <options.elementType>{children}</options.elementType>
    }
  },

  renderToolbarItem: ({ editor, ...props }: RenderToolbarItemProps) => {
    if (!options.iconName) return null

    const activeMarks = editor?.value.activeMarks || []
    const isActive = activeMarks.some((mark: Nullable<Mark>) => mark?.type == options.markType)

    const toggleMark = (e: Event) => {
      e.preventDefault()
      if (editor) {
        editor.change((c) => c.toggleMark(options.markType))
      }
    }

    return (
      <ToolbarButton action={toggleMark} active={isActive} iconName={options.iconName} disabled={false} />
    )
  },

  renderMark: ({ children, mark, attributes }: RenderMarkProps, next: Next) => {
    if (mark.type !== options.markType) {
      return next()
    }

    return <options.elementType {...attributes}>{children}</options.elementType>
  },
})

export default [
  {
    markType: 'bold',
    elementType: ElementTypes.STRONG,
    iconName: 'bold',
  },
  {
    markType: 'italic',
    elementType: ElementTypes.EM,
    iconName: 'italic',
  },
].map((e) => buildPlugin(e))
