import React, { memo } from 'react'

import * as R from 'ramda'
import { Value } from 'slate'
import { Editor as SlateEditor } from 'slate-react'

import { Absolute, Box, Flex } from 'stardust'
import styled from 'styled-components'

import { jsonSerializer } from '~/modules/textEditor'
import { plugins, schema } from '~/modules/textEditor/configs/advanced'
import textStyle from '~/modules/textEditor/style'

import * as selectors from '../core/selectors'

const S = {
  SlateEditor: styled(SlateEditor)`
    height: 100%;
    width: 100%;
    ${({ canEdit }) => canEdit && 'pointer-events: none'};

    ${textStyle};
  `,
}

const EditorContent = styled(Box)`
  align-items: center;
  background: transparent;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow-y: scroll;
  padding: 0 12px;
  width: 100%;
`

const ReadEditor = (props, next) => {
  const children = next()

  const id = props.id
  const kind = props.kind

  return (
    <EditorContent data-drop={kind} data-drop-id={id}>
      {children}
    </EditorContent>
  )
}

const Read = memo(({ canEdit = true, ...props }) => {
  const value = R.pipe(selectors.refData.get, jsonSerializer.deserialize, Value.fromJSON)(props)

  return (
    <Absolute bottom={0} left={0} right={0} top={0}>
      <Flex alignItems="center" height="100%" justifyContent="center">
        <S.SlateEditor
          canEdit={canEdit}
          id={props.id}
          kind={props.kind}
          plugins={plugins}
          readOnly
          renderEditor={ReadEditor}
          schema={schema}
          value={value}
        />
      </Flex>
    </Absolute>
  )
})

Read.displayName = 'Read'
export default Read
