// This function helps determine the state of a learning story or learning plan.
// There are three states:

// DRAFT (not published, cannot be seen by parents)
// PUBLISHED (with no changes)
// UNPUBLISHED CHANGES (published with changes pending)

const articleContentState = ({
  isPublished,
  hasUnpublishedChanges,
}: {
  isPublished: boolean
  hasUnpublishedChanges: boolean
}) => {
  if (isPublished && hasUnpublishedChanges) {
    return 'UNPUBLISHED_CHANGES'
  } else if (isPublished && !hasUnpublishedChanges) {
    return 'PUBLISHED'
  } else if (!isPublished) {
    return 'DRAFT'
  }
}

export default articleContentState
