import { useNeedsReviewDocumentsCountQuery } from './useNeedsReviewDocuments'

export const NEEDS_REVIEW_COUNT_MAX = 100

export const useNeedsReviewDocumentsCount = (): string => {
  const { data, error, loading } = useNeedsReviewDocumentsCountQuery(NEEDS_REVIEW_COUNT_MAX)

  if (loading || error) return ''

  const postsPayload: RelayConnection<Playground.Observation> = data.context.posts
  const posts = postsPayload.edges

  if (posts.length <= 0) {
    return ''
  }
  return posts.length < NEEDS_REVIEW_COUNT_MAX
    ? `(${posts.length.toString()})`
    : `(${NEEDS_REVIEW_COUNT_MAX - 1}+)`
}
