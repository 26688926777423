import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Link } from '@reach/router'
import * as R from 'ramda'

import { Avatar, Text } from 'stardust'

import * as profiles from '~/components/Profiles/core'
import { Group, Item, List } from '~/components/Profiles/List'
import Metrics from '~/components/Profiles/Metrics/RoomMetrics'
import { useSelectedServiceFkey } from '~/contexts/Service'

import { ScrollUp, sortObjectsBy } from '~/utils'
import { startToEndMonth } from '~/utils/dateRange'

import { LIST_ROOMS } from './queries'

const Rooms = (_props: RouteComponentProps) => {
  const serviceFkey = useSelectedServiceFkey()
  return (
    <ScrollUp>
      <List
        data-test="rooms-heading"
        heading="rooms"
        path={['service', 'rooms']}
        variables={{
          dateRange: startToEndMonth(new Date()),
          serviceFkey,
        }}
        query={LIST_ROOMS}
        render={({ data }: any) => {
          const sortedData = sortObjectsBy(data.service.rooms, 'name')
          const grouped = profiles.groupByInitial('name')(sortedData)

          if (R.keys(grouped).length < 1) {
            return <Text.span>There are no rooms</Text.span>
          }

          return R.pipe(
            R.keys,
            R.map((group) => (
              <Group key={group} group={group}>
                {R.map(
                  (room) => (
                    <Link key={room.fkey} to={`${room.fkey}`} style={{ textDecoration: 'none' }}>
                      <Item
                        graphic={() => (
                          <Avatar
                            alt={room.name}
                            src={room.image}
                            text={profiles.initials(room.name)}
                            small
                          />
                        )}
                        metrics={() => <Metrics inline room={room} />}>
                        <Text.span caps fontSize={2} mb={1}>
                          {room.name}
                        </Text.span>
                        <Text.span caps fontSize={1} medium>
                          {room.type}
                        </Text.span>
                      </Item>
                    </Link>
                  ),
                  grouped[group]
                )}
              </Group>
            ))
          )(grouped)
        }}
      />
    </ScrollUp>
  )
}

Rooms.displayName = 'Rooms'

export default Rooms
