import React from 'react'

const LearningContentPodcast = () => (
  <svg width="24" height="24" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 22.125V4.71204C12 4.71204 9.54502 1.98504 1.26802 1.87504C1.13072 1.87331 0.998612 1.92745 0.902018 2.02504C0.804477 2.12305 0.74981 2.25576 0.750018 2.39404V18.769C0.747722 19.0493 0.969905 19.2799 1.25002 19.288C9.54302 19.4 12 22.125 12 22.125"
      stroke="#007AFF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.99999 11.18C7.29824 10.6076 5.53084 10.2531 3.73999 10.125"
      stroke="#007AFF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.99999 15.3751C7.29824 14.8027 5.53084 14.4482 3.73999 14.3201"
      stroke="#007AFF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 11.18C16.7017 10.6076 18.4692 10.2531 20.26 10.125"
      stroke="#007AFF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 15.3751C16.7017 14.8027 18.4692 14.4482 20.26 14.3201"
      stroke="#007AFF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 22.125V4.71204C12 4.71204 14.455 1.98504 22.732 1.87504C22.8693 1.87331 23.0014 1.92745 23.098 2.02504C23.1955 2.12305 23.2502 2.25576 23.25 2.39404V18.769C23.2523 19.0493 23.0301 19.2799 22.75 19.288C14.457 19.4 12 22.125 12 22.125"
      stroke="#007AFF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default LearningContentPodcast
