import React, { useContext, useMemo, useState } from 'react'

import { Avatar, Card, Flex, Text } from 'stardust'
import styled from 'styled-components'

import FilterDropdown, { FkeyOrId, Id, ProfileGroup, ProfileItem } from '~/components/FilterDropdown'
import { initials } from '~/components/Profiles/core'
import useLocalStorage from '~/hooks/useLocalStorage'
import { LAYERS } from '~/theme'
import { groupObjectsBy, sortObjectsBy } from '~/utils'

import CategoryFilterMenu from '../Common/CategoryFilterMenu'
import { LearningAnalysisContext } from '../Context'

import LimitedList from './LimitedList'

interface Props {
  filters: ProfileGroup<FkeyOrId>[]
  localStorageKey?: string
  records: Playground.LearningRecord[]
}

type DataRow = {
  fkey: string
  image: string | undefined
  name: string
  score: number
}
const ScrollableFlex = styled(Flex)`
  overflow-y: auto;
`

const TopEmergingChildrenTile = ({ filters, localStorageKey, records }: Props) => {
  const { children, ranks, filterChildren, filterRecords } = useContext(LearningAnalysisContext)

  const [categoryFilter, setCategoryFilter] = useState<Nullable<ProfileItem<Id>>>(null)
  const [childFilter, setChildFilter] = useLocalStorage<Nullable<ProfileItem<FkeyOrId>>>(
    null,
    localStorageKey
  )

  const data = useMemo((): DataRow[] => {
    if (ranks.length === 0) return []
    const lowestRank = ranks[0]

    const filteredChildren = filterChildren(children, childFilter)
    const filteredRecords = filterRecords(records, categoryFilter)
    const recordsByChild = groupObjectsBy(filteredRecords, 'childFkey')

    const sortedChildren = sortObjectsBy(filteredChildren, 'fullName').reverse()
    const childrenWithScores = sortedChildren.map((child) => {
      const childRecords = recordsByChild[child.fkey] || []
      const emergingRecords = childRecords.filter((record) => record.rankId === lowestRank.id)
      return {
        fkey: child.fkey,
        image: child.image,
        name: child.fullName,
        score: childRecords.length > 0 ? emergingRecords.length / childRecords.length : 0,
      }
    })

    return sortObjectsBy(childrenWithScores, 'score').reverse()
  }, [categoryFilter, childFilter, children, filterChildren, filterRecords, ranks, records])

  const childFilters = useMemo(() => {
    const whitelist = ['group', 'room']
    return filters.filter((filter) => whitelist.includes(filter.type))
  }, [filters])

  return (
    <Card>
      <Flex flexDirection="column" height={340}>
        <Flex
          alignItems={['flex-start', 'center']}
          flexDirection={['column', 'row']}
          justifyContent="space-between"
          p={3}
          width={1}>
          <Flex mr={3} width={[1, '60%']}>
            <FilterDropdown
              filters={childFilters}
              portal
              selection={childFilter}
              onSelect={setChildFilter}
              zIndex={LAYERS.SectionHeader}
            />
          </Flex>
          <Flex justifyContent="flex-end">
            <Text.span fontSize={0}>Emerging Outcomes</Text.span>
          </Flex>
        </Flex>

        <ScrollableFlex flexDirection="column" flexGrow={1} width={1}>
          <LimitedList<DataRow> data={data}>
            {(datum: DataRow) => (
              <Flex key={datum.fkey} alignItems="center" justifyContent="space-between" px={3} py={2}>
                <Flex alignItems="center">
                  <Avatar alt={datum.name} src={datum.image} xxsmall text={initials(datum.name)} />
                  <Text.span fontSize={0} ml={2}>
                    {datum.name}
                  </Text.span>
                </Flex>
                <Text.span fontSize={0}>{`${Math.round(datum.score * 100)}%`}</Text.span>
              </Flex>
            )}
          </LimitedList>
        </ScrollableFlex>

        <Flex borderTop="1px solid" borderColor="surfacePrimaryBorder" mx={3} py={2}>
          <Flex flexDirection="column">
            <CategoryFilterMenu value={categoryFilter} onChange={setCategoryFilter} />
          </Flex>
        </Flex>
      </Flex>
    </Card>
  )
}

TopEmergingChildrenTile.displayName = 'TopEmergingChildrenTile'

export default React.memo(TopEmergingChildrenTile)
