import React, { useEffect } from 'react'

import { Box, ErrorMessageMediaUpload } from '~/ui-components'

interface UploadErrorsProps {
  uploadErrors: Playground.Error[]
  displayUploadErrors: boolean
  setDisplayUploadErrors: (displayUploadErrors: boolean) => void
}

const UploadErrors = ({ uploadErrors, displayUploadErrors, setDisplayUploadErrors }: UploadErrorsProps) => {
  useEffect(() => {
    if (uploadErrors) setDisplayUploadErrors(true)
  }, [uploadErrors, setDisplayUploadErrors])

  if (!uploadErrors) {
    return null
  }

  return displayUploadErrors ? (
    <Box>
      {uploadErrors.map((error: any, index: number) => (
        <ErrorMessageMediaUpload key={index} error={error} setDisplayUploadErrors={setDisplayUploadErrors} />
      ))}
    </Box>
  ) : null
}

UploadErrors.displayName = 'UploadErrors'
export default React.memo(UploadErrors)
