import * as R from 'ramda'

const CAPTURE_REGEX = /([@#])([\S ]*)$/
const matchNone = () => ({ token: null, terms: null })
const matchSome = (result) => ({ token: result[1], terms: result[2] })

export const tokenize = (text) => {
  return R.ifElse(
    R.isNil,
    matchNone,
    R.pipe(R.match(CAPTURE_REGEX), R.ifElse(R.isEmpty, matchNone, matchSome))
  )(text)
}

export const typesFromToken = (token) => {
  switch (token) {
    case '@':
      return ['CHILD', 'EDUCATOR', 'ROOM']
    case '#':
      return ['LEARNING_OUTCOME']
    default:
      return null
  }
}
