import React from 'react'

import { Box, Divider, Flex, StencilImage as Image, StencilLine as Line } from 'stardust'
import styled from 'styled-components'

const opacity = (props: { opacity?: number }) => (props.opacity ? { opacity: props.opacity } : null)

const OpaqueFlex = styled(Flex)`
  ${opacity};
`

const Observation = () => (
  <Flex width={[800, 1000, 'auto']}>
    <OpaqueFlex
      opacity={6 / 10}
      flexDirection="column"
      justifyContent="space-between"
      width={[500, 600, 600]}>
      <Image width="100%" height={600} color="grey" />
      <Box width="100%">
        <Flex p={3} justifyContent="space-between">
          <Line py={1} width={1 / 30} />
          <Line py={1} width={1 / 30} />
        </Flex>
      </Box>
    </OpaqueFlex>
    <Box width={[300, 400, 400]}>
      <Box py={2} px={3}>
        <Line py={1} width={1 / 3} />
        <Line my={3} py={1} width={5 / 6} />
        <Divider borderColor="lightGrey" width="100%" mr={0} ml={'auto'} mb={4} />
        <Line my={3} py={1} width={5 / 6} />
        <Line my={3} py={1} width={3 / 4} />
        <Line my={3} py={1} width={3 / 4} />
        <Divider borderColor="lightGrey" width="100%" mr={0} ml={'auto'} mb={4} />
        <Line my={3} py={1} width={5 / 6} />
        <Divider borderColor="lightGrey" width="100%" mr={0} ml={'auto'} mb={4} />
      </Box>
    </Box>
  </Flex>
)

export default Observation
