import React from 'react'

const CheckCircleAlt2 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12.5C0 5.876 5.376 0.5 12 0.5C18.624 0.5 24 5.876 24 12.5C24 19.124 18.624 24.5 12 24.5C5.376 24.5 0 19.124 0 12.5ZM4.80134 12.7074L9.57685 17.6429L18.1728 8.75893L16.8261 7.35723L9.57685 14.8494L6.14803 11.3155L4.80134 12.7074Z"
      fill="#01A39D"
    />
  </svg>
)

export default CheckCircleAlt2
