import React from 'react'

import distanceInWords from 'date-fns/distance_in_words'
import * as R from 'ramda'
import { Box, Flex, Icon, Link, SecondaryButton, Text } from 'stardust'
import styled from 'styled-components'

import ArticleStatusBanner from '~/components/ArticleStatusBanner'
import DocumentModalPreview from '~/components/DocumentModalPreview'
import DocumentRenderer from '~/components/DocumentRenderer'
import TextEditor from '~/components/Editors/TextEditor'
import { isPlayground } from '~/config'

import { getEditorValue } from '~/modules/textEditor'

interface Props {
  article: Playground.Article
  articleContent: Playground.ArticleContent
}

const StyledLink = styled(Link)`
  width: 100%;
  margin: 0px 2px;
`

const StoryView = ({ article, articleContent }: Props) => {
  const associations = R.pick(['children', 'rooms', 'personnel', 'learningOutcomes'], article)
  const creator = article.contributors.find((x) => x.action === 'created')
  const content = getEditorValue(R.merge(articleContent, associations))
  const timeAgo = (dt: Date | string) => distanceInWords(dt, new Date())
  const lastEditorName = article.contributors[0].fullName

  const title = articleContent.title

  const footer = (
    <Flex justifyContent="space-between" alignItems="center" width={1}>
      <Text.p fontSize={1} lineHeight={1.4} ml={3} medium>
        {`Created ${timeAgo(article.insertedAt)} ago, last edited ${timeAgo(
          article.updatedAt
        )} ago by ${lastEditorName}`}
      </Text.p>
      <Box minWidth={160}>
        <Flex flexDirection="row" justifyContent="space-between" width="100%">
          <StyledLink mx={1} to={`/stories/display/${article.id}`}>
            <SecondaryButton data-test="story-full-screen-button">
              <Flex alignItems="center" justifyContent="center">
                <Icon name="fullscreen" mr={1} fill="primary" />
                Full Screen
              </Flex>
            </SecondaryButton>
          </StyledLink>
        </Flex>
      </Box>
    </Flex>
  )

  return (
    <DocumentRenderer
      documentId={article.id}
      documentType="learning_story"
      educators={article.personnel}
      footer={footer}
      learningOutcomes={article.learningOutcomes}
      heading={title}
      overline={creator?.fullName || ''}
      rooms={article.rooms}
      statusMessage={isPlayground && <ArticleStatusBanner article={article} path="stories" />}
      taggedChildren={article.children}>
      <DocumentModalPreview background="surfacePrimary">
        <TextEditor value={content} readOnly />
      </DocumentModalPreview>
    </DocumentRenderer>
  )
}

export default React.memo(StoryView)
