import React from 'react'

const ChevronRightDouble = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.27548 18C4.01404 18 3.9222 17.8074 4.07187 17.5732L7.33155 12.4284C7.48124 12.1664 7.48124 11.8336 7.33155 11.5716L4.07187 6.42679C3.9222 6.19099 4.01404 6.00001 4.27548 6.00001L5.87908 6.00001C6.17239 6.01762 6.44456 6.17314 6.62597 6.42679L9.88808 11.57C10.0373 11.8321 10.0373 12.1647 9.88808 12.4268L6.62791 17.5716C6.44651 17.8253 6.17434 17.9808 5.88102 17.9984L4.27548 18Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2755 18C14.014 18 13.9222 17.8074 14.0719 17.5732L17.3316 12.4284C17.4812 12.1664 17.4812 11.8336 17.3316 11.5716L14.0719 6.42679C13.9222 6.19099 14.014 6.00001 14.2755 6.00001L15.8791 6.00001C16.1724 6.01762 16.4446 6.17314 16.626 6.42679L19.8881 11.57C20.0373 11.8321 20.0373 12.1647 19.8881 12.4268L16.6279 17.5716C16.4465 17.8253 16.1743 17.9808 15.881 17.9984L14.2755 18Z"
      fill="currentColor"
    />
  </svg>
)

export default ChevronRightDouble
