import React from 'react'

import Empty from '~/components/Empty'

const NoLearningData = () => {
  return (
    <Empty
      heading="No Learning Data"
      subheading="Tag children in this group with learning outcomes to get started"
    />
  )
}

export default NoLearningData
