import React from 'react'

const OutcomesAlt = () => (
  <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.87333 19.875H3.49917C2.46396 19.8745 1.625 19.0352 1.625 18"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.625 8V1.75C16.625 1.5841 16.559 1.425 16.4416 1.30776C16.3243 1.19053 16.1651 1.12478 15.9992 1.125H4.125C2.74429 1.125 1.625 2.24429 1.625 3.625V18C1.625 16.9648 2.46396 16.1255 3.49917 16.125H8.49917"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8575 12.8915L13.5 19.2498L10.375 19.8748L11 16.7498L17.3583 10.3915C17.6888 10.0605 18.1373 9.87451 18.605 9.87451C19.0727 9.87451 19.5212 10.0605 19.8517 10.3915L19.8583 10.3981C20.5465 11.0869 20.5461 12.2031 19.8575 12.8915Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default OutcomesAlt
