import React, { useEffect, useRef, useState } from 'react'

import { useQuery } from '@apollo/client'
import { Box, Flex } from 'stardust'

import styled from 'styled-components'

import Modal from '~/components/Modal'

import { useSelectedServiceFkey } from '~/contexts/Service'
import { GetDocuments } from '~/pages/Dashboard/queries'

import { GET_DOCUMENTS_BY_QUERY_TYPES_AND_CHILDREN } from '~/pages/Observations/queries'
import TaggedDocumentsBottomWeb from '~/pages/Root/components/TaggedDocumentsBottomWeb'
import TaggedDocumentsHeaderWeb from '~/pages/Root/components/TaggedDocumentsHeaderWeb'

import colors from '~/ui-components/tokens/colors'

import { FieldProps } from '../../types'

import LinksDocuments from './Links/LinksDocuments'
import LinksDocumentsFilterWeb from './Links/LinksDocumentsFilterWeb'
import LinksSelectedDocumentPreview from './Links/LinksSelectedDocumentPreview'

const StyledBox = styled(Box)`
  padding: 20px 24px 0;
  > div:first-child {
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 24px;
    border-radius: 16px 16px 0 0;
    background-color: ${colors.cosmicShade12};
    max-width: none;
    > div:first-child {
      margin-top: 0px;
    }
  }
`
const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${colors.cosmicShade0};
`

interface Props extends FieldProps {
  childList: Playground.SimpleChild[]
  documentIds: string[]
  taggedDocuments: Playground.Document[]
  onCloseLinksModal: () => void
}

const PostLinksModal = ({ taggedDocuments, documentIds, childList, onChange, onCloseLinksModal }: Props) => {
  const [selectedTaggedDocs, setSelectedTaggedDocs] = useState<string[]>(documentIds)
  const [taggedDocsDetails, setTaggedDocsDetails] = useState<Playground.SimpleDocument[]>(taggedDocuments)
  const [selectedDocument, setSelectedDocument] = useState<Nullable<Playground.SimpleDocument>>(null)
  const [showMorePreviewCard, setShowMorePreviewCard] = useState(false)
  const [selectedSorting, setSelectedSorting] = useState<string>('Last modified')
  const [isError, setIsError] = useState<boolean>(false)
  const [searchString, setSearchString] = useState<Nullable<string>>(null)
  const scrollRef = useRef<HTMLDivElement>(null)
  const [isDropdownVisible, setDropdownVisible] = useState(false)

  const types = ['learning_story', 'learning_table', 'observation']
  const serviceFkey = useSelectedServiceFkey()

  useEffect(() => {
    if (searchString == '') {
      setSearchString(null)
    }
  }, [searchString])

  const handleOnQueryChange = (query: string) => {
    setSearchString(query)
    refetch()
  }

  const { data, error, loading, fetchMore, refetch } = useQuery<GetDocuments>(
    GET_DOCUMENTS_BY_QUERY_TYPES_AND_CHILDREN,
    {
      variables: { serviceFkey, types: types, childList, query: searchString },
    }
  )

  const handleSortingChange = (option: string) => {
    setSelectedSorting(option)
  }

  useEffect(() => {
    error && setIsError(true)
  }, [error])

  const handleCardClick = (doc: Playground.SimpleDocument) => {
    const currentSelectedDocs = [...selectedTaggedDocs]

    if (currentSelectedDocs.includes(doc.id)) {
      const filteredDocs = currentSelectedDocs.filter((docStringId) => doc.id !== docStringId)

      const updatedSelectedDocuments = taggedDocsDetails.filter((currentDoc) => currentDoc.id !== doc.id)
      setTaggedDocsDetails(updatedSelectedDocuments)

      setSelectedTaggedDocs(filteredDocs)
      setSelectedDocument(null)
      return
    }

    setTaggedDocsDetails([...taggedDocsDetails, doc])
    setSelectedTaggedDocs(currentSelectedDocs.concat([doc.id]))
    setSelectedDocument(doc)
  }

  const handleCancel = () => {
    setSelectedTaggedDocs([])
    setTaggedDocsDetails([])
    setSelectedDocument(null)
    onCloseLinksModal()
  }

  const handleDone = () => {
    onChange({
      name: 'documentIds',
      value: selectedTaggedDocs,
    })

    const deepClone = (obj: Playground.SimpleDocument) => JSON.parse(JSON.stringify(obj))
    const newDocumentsArray = taggedDocsDetails.map(deepClone)
    onChange({
      name: 'documents',
      value: newDocumentsArray,
    })
    onCloseLinksModal()
  }

  const handleDropdownToggle = () => {
    setDropdownVisible(!isDropdownVisible)
  }

  return (
    <Box>
      <Modal open onClose={() => handleCancel()} dontRenderCloseButton ref={scrollRef}>
        <Flex bg="surfacePrimary" borderRadius="16px" height="max-content" minWidth="0">
          <Box width="55vw">
            <Flex flexDirection="column" width={1}>
              <StickyHeader>
                <TaggedDocumentsHeaderWeb onCancelClick={handleCancel} />
              </StickyHeader>
              <Box>
                <LinksDocumentsFilterWeb
                  selectedSorting={selectedSorting}
                  isDropdownVisible={isDropdownVisible}
                  handleSortingChange={handleSortingChange}
                  handleDropdownToggle={handleDropdownToggle}
                  onQueryChange={(event) => handleOnQueryChange(event.currentTarget.value)}
                />
              </Box>
              <StyledBox>
                <LinksDocuments
                  handleCardClick={(doc) => handleCardClick(doc)}
                  refetch={refetch}
                  fetchMore={fetchMore}
                  selectedTaggedDocs={selectedTaggedDocs}
                  selectedSorting={selectedSorting}
                  data={data}
                  isLoading={loading}
                  isError={isError}
                  scrollElementRef={scrollRef}
                />
              </StyledBox>
              <TaggedDocumentsBottomWeb
                onConfirmClick={handleDone}
                onCancelClick={handleCancel}
                totalTaggedDocs={selectedTaggedDocs.length}
              />
            </Flex>
          </Box>
          <LinksSelectedDocumentPreview
            selectedDocument={selectedDocument}
            showMorePreviewCard={showMorePreviewCard}
            setShowMorePreviewCard={setShowMorePreviewCard}
          />
        </Flex>
      </Modal>
    </Box>
  )
}

PostLinksModal.displayName = 'PostLinksModal'
export default React.memo(PostLinksModal)
