import React from 'react'

import { Router } from '@reach/router'

import RoomList from './List'
import RoomView from './View'

const RoomsRouter = () => (
  <Router>
    <RoomList path="/" />
    <RoomView path=":fkey/*" />
  </Router>
)

RoomsRouter.displayName = 'RoomsRouter'

export default RoomsRouter
