import React from 'react'

import { Box, Flex, Text, Icon } from 'stardust'

import styled from 'styled-components'

import colors from '~/ui-components/tokens/colors'

interface Props {
  handleSearchOutcome: (searchTerm: string) => void
  searchTerm: string
}

const S = {
  Input: styled.input`
    height: 48px;
    padding: 8px;
    width: 90%;
    font-size: 16px;
  `,
  Flex: styled(Flex)`
    border: 1px solid;
    border-color: grey;
    background-color: white;
    border-radius: 16px;
  `,
  Text: styled(Text)`
    cursor: pointer;
  `,
}
const OutcomeSearch = ({ handleSearchOutcome, searchTerm }: Props) => {
  return (
    <Box mb={3}>
      <Flex alignItems="center">
        <S.Flex alignItems="center" width="95%">
          <Icon name="search" ml={2} mr={2} />
          <S.Input
            placeholder="Search by Learning Outcomes"
            value={searchTerm}
            name="outcome_query"
            onChange={(e) => handleSearchOutcome(e.target.value)}
          />
        </S.Flex>
        {searchTerm === '' ? null : (
          <S.Text onClick={() => handleSearchOutcome('')} ml={2} fontSize={1} color={colors.nebulaBlue4}>
            Cancel
          </S.Text>
        )}
      </Flex>
    </Box>
  )
}

OutcomeSearch.displayName = 'OutcomeSearch'
export default React.memo(OutcomeSearch)
