import React from 'react'

const CohortTracking = () => (
  <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4983 18.6024H7.49829L8.12329 15.0024H11.8733L12.4983 18.6024V18.6024Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.62329 18.6024H14.3733"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.623291 3.6024C0.623291 1.94555 1.96644 0.602402 3.62329 0.602402H16.3733C18.0301 0.602402 19.3733 1.94555 19.3733 3.6024V12.0024C19.3733 13.6593 18.0301 15.0024 16.3733 15.0024H3.62329C1.96644 15.0024 0.623291 13.6593 0.623291 12.0024V3.6024Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.623291 9.0024L3.93162 5.8272C4.04885 5.7146 4.20788 5.65133 4.37371 5.65133C4.53954 5.65133 4.69857 5.7146 4.81579 5.8272L6.43162 7.3784C6.54885 7.49101 6.70788 7.55427 6.87371 7.55427C7.03954 7.55427 7.19857 7.49101 7.31579 7.3784L10.6233 4.2024"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.623291 12.6024H19.3733"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8733 7.8024V9.6024"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.8733 7.8024V9.6024"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3733 6.0024V9.6024"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CohortTracking
