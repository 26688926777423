import React from 'react'

import { Flex, FlexItem, MainButton, TextField } from 'stardust'

const BlockComment = ({ onChange, buttonText, commentValue, placeholder, disabled, error }) => (
  <Flex flexDirection="column" justifyContent="flex-end">
    <FlexItem>
      <TextField
        autoComplete="off"
        data-test="comment-field"
        textarea
        invalid={error}
        my={3}
        rows="2"
        name="comment"
        value={commentValue}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </FlexItem>
    <FlexItem alignSelf="flex-end">
      <MainButton width={100} disabled={disabled} type="submit" data-test="comment-submit">
        {buttonText}
      </MainButton>
    </FlexItem>
  </Flex>
)

export default BlockComment
