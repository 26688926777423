import React from 'react'

import { Box, Flex, Icon, Text } from 'stardust'

import { sortObjectsBy } from '~/utils'

interface Props {
  learningOutcomes: Playground.LearningFrameworkOutcome[]
}

const LearningOutcomes = ({ learningOutcomes }: Props) => {
  return (
    <Box data-test="document-learning-outcomes">
      <Text bold mb={3} fontSize={2}>
        Learning Outcomes
      </Text>

      {sortObjectsBy(learningOutcomes, 'name').map((outcome) => (
        <Flex key={outcome.id} alignItems="center" mt={2}>
          <Icon cursorPointer={false} name="checkCircle" color="positive" mr={2} />
          <Text fontSize={1} lineHeight={1.2}>
            {outcome.name}
          </Text>
        </Flex>
      ))}
    </Box>
  )
}

LearningOutcomes.displayName = 'DocumentRendererLearningOutcomes'

export default React.memo(LearningOutcomes)
