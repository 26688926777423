import React from 'react'

import { Box, Flex, Icon, Text } from 'stardust'

const DEFAULT_COLOR = 'rgba(0, 0, 0, 0.04)'

interface PillProps {
  color?: string
  icon?: string
  textColor?: string
  text: string
}

const Pill = ({ color, icon, text, textColor }: PillProps) => (
  <Box
    bg={color || DEFAULT_COLOR}
    borderRadius={1}
    borderColor="surfacePrimaryBorder"
    p={2}
    mr={2}
    width="auto">
    <Flex alignItems="center">
      {icon && <Icon name={icon} size={16} mr={2} />}
      <Text.span color={textColor || 'cosmicShade8'} fontSize={0} medium>
        {text}
      </Text.span>
    </Flex>
  </Box>
)

Pill.displayName = 'Pill'

export default React.memo(Pill)
