import React from 'react'

import { RouteComponentProps } from '@reach/router'

import { Redirect, Router } from '@reach/router'

import CanRoute from '~/components/Authorisation/CanRoute'
import { HeaderSection } from '~/components/Profiles/Detail'
import Tabs from '~/components/Tabs'
import { useSelectedServiceFkey } from '~/contexts/Service'
import { roomProfiles as rProf } from '~/modules/permissions'
import { ScrollUp } from '~/utils'
import toFkey from '~/utils/toFkey'

import Documents from './Documents'
import Summary from './Summary'

const Room = ({ fkey: fkeyOrId }: RouteComponentProps<{ fkey: string | number }>) => {
  const serviceFkey = useSelectedServiceFkey()
  if (!fkeyOrId) return null
  const roomFkey = toFkey(fkeyOrId)
  return (
    <ScrollUp>
      <HeaderSection>
        <Summary roomFkey={roomFkey} serviceFkey={serviceFkey} />
        <Tabs
          tabs={[
            {
              key: 'documents-tab',
              path: '',
              label: 'Learning',
              permission: { action: rProf.readDocuments },
            },
          ]}
        />
      </HeaderSection>
      <Router>
        <CanRoute
          path="/*"
          perform={rProf.readDocuments}
          no={() => <Redirect to="/rooms" noThrow />}
          yes={() => <Documents roomFkey={roomFkey} path="/*" serviceFkey={serviceFkey} />}
        />
      </Router>
    </ScrollUp>
  )
}

Room.displayName = 'Room'

export default Room
