import gql from 'graphql-tag'

export const GET_MOMENTS = gql`
  query Moments($cursor: String, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      documents(first: 20, types: "moment", after: $cursor) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            author
            commentCount
            coverImage
            typeId
            thumbImage
            title
            type
            insertedAt
            updatedAt
          }
        }
      }
    }
  }
`

export const GET_MOMENT = gql`
  query Moment($momentId: Int!, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      moment(id: $momentId) {
        id
        insertedAt
        updatedAt
        location
        caption
        media {
          id
          coverUrl
          mimeType
          url
        }
        contributors {
          id
          action
          fullName
        }
        children {
          fkey
          fullName
          image
        }
      }
    }
  }
`

export const GET_MOMENT_CANVAS = gql`
  query Moment($id: Int!, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      moment(id: $id) {
        id
        caption
        media {
          id
          coverUrl
          mimeType
          url
        }
        children {
          id
          fkey
          fullName
          image
        }
      }
    }
  }
`
