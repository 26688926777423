import React, { useState } from 'react'

import { navigate } from '@reach/router'

import { Flex } from 'stardust'

import { EXPANDED_PREVIEW_HEIGHT, STACKED_PREVIEW_HEIGHT } from '~/components/DocumentModalPreview'
import MediaRenderer from '~/components/MediaRenderer'
import Modal from '~/components/Modal'

import { currentHomeAppVersion, isHomeWebview } from '~/config'

import FiveMedia from './Media/FiveMedia'
import FourMedia from './Media/FourMedia'
import OneMedia from './Media/OneMedia'
import ThreeMedia from './Media/ThreeMedia'
import TwoMedia from './Media/TwoMedia'

interface Props {
  postMedia: Playground.MediaItem[]
  title: string
  id?: number
}

const MediaView = ({ postMedia, title, id }: Props) => {
  const [openMediaModal, setOpenMediaModal] = useState<boolean>(false)

  const handleMediaClick = (mediaId: number) => {
    if (isHomeWebview) navigate(`xplor://post-gallery?postId=${id}&mediaId=${mediaId}`)

    const homeAppVersion = currentHomeAppVersion()
    if (homeAppVersion) {
      const targetVersion = 2.5
      const bypassWebModal = homeAppVersion >= targetVersion
      if (bypassWebModal) return
    }
    setOpenMediaModal(true)
  }

  const renderImages = () => {
    switch (postMedia.length) {
      case 1:
        return <OneMedia postMedia={postMedia} onMediaClick={handleMediaClick} />
      case 2:
        return <TwoMedia postMedia={postMedia} onMediaClick={handleMediaClick} />
      case 3:
        return <ThreeMedia postMedia={postMedia} onMediaClick={handleMediaClick} />
      case 4:
        return <FourMedia postMedia={postMedia} onMediaClick={handleMediaClick} />
      default:
        return <FiveMedia postMedia={postMedia} onMediaClick={handleMediaClick} />
    }
  }

  return (
    <>
      <Flex justifyContent="center" flexWrap="wrap" cursor="pointer" mt={3} onClick={() => handleMediaClick}>
        {renderImages()}
      </Flex>
      <Modal open={openMediaModal} onClose={() => setOpenMediaModal(false)} backgroundColor="black">
        <MediaRenderer
          height={[STACKED_PREVIEW_HEIGHT, EXPANDED_PREVIEW_HEIGHT]}
          mediaItems={postMedia!}
          title={title}
        />
      </Modal>
    </>
  )
}

MediaView.displayName = 'MediaView'

export default React.memo(MediaView)
