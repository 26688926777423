/* eslint react/display-name: 0 */
import { DragEvent } from 'react'

import { Block, Range } from 'slate'
import { RenderBlockProps, getEventRange } from 'slate-react'

import { Next, OnDropProps } from '../../types'

import collage from './collage'
import media from './media'

export default {
  serialize: (obj: any) => {
    if (obj.object === 'block' && obj.type === 'content') {
      const { data: data } = obj.toJS()

      switch (data.type) {
        case 'collage':
          return collage.serialize(data)
        case 'image':
          return media.serialize(data)
        case 'file':
          return media.serialize(data)
        case 'video':
          return media.serialize(data)
        default:
          return null
      }
    }
  },

  onDrop: (e: DragEvent, { editor }: OnDropProps, next: Next) => {
    const droppedData = e.dataTransfer.getData('data')

    if (!droppedData) {
      return
    }

    const data = JSON.parse(droppedData)

    if (data.type !== 'content') {
      return next()
    }

    return editor.change((change: { insertBlockAtRange: (arg0: Range, arg1: Block) => void }) => {
      const range = getEventRange(e, editor)
      if (range) {
        change.insertBlockAtRange(range, Block.create(data))
      }
    })
  },

  renderNode: (props: RenderBlockProps, next: Next) => {
    const { node } = props
    if (node.type !== 'content') {
      return next()
    }

    const {
      data: { type },
    } = node.toJS()

    switch (type) {
      case 'collage':
        return collage.renderNode(props)
      case 'image':
        return media.renderNode(props)
      case 'file':
        return media.renderNode(props)
      case 'video':
        return media.renderNode(props)
      default:
        return next()
    }
  },
}
