import React, { useContext, useEffect, useMemo } from 'react'

import { useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'

import Splash from '~/components/Loading/Splash'
import { activeServices } from '~/components/Profiles/core'
import UserStrategyPrompt from '~/components/UserStrategyPrompt'
import { isHome, isPlayground } from '~/config'

import ServiceProvider from '~/contexts/Service'
import StrategyProvider, { StrategyContext } from '~/contexts/Strategy'
import UserProvider from '~/contexts/User'

import useStargate from '~/hooks/useStargate'

import * as perms from '~/modules/permissions'
import { GET_USER_DETAILS, GET_USER_SERVICES } from '~/modules/users'

import UnauthorizedPage from '~/pages/Errors/Unauthorized'

import { VIEW_FEATURE_FLAGS } from './queries'
import Root from './Root'

const findStrategy = (strategies: Stargate.UserContext[], strategyType: string) => {
  return strategies.find((strategy) => strategy.strategy_type.includes(strategyType))
}

function useServices(user: Playground.User) {
  const isSuperAdmin = perms.isSuperAdmin(user)
  const { data, loading } = useQuery(GET_USER_SERVICES, { skip: isSuperAdmin })
  const { loading: loadingFF, data: dataFF } = useQuery(VIEW_FEATURE_FLAGS)

  const services = useMemo(() => {
    if (isSuperAdmin) {
      return []
    }

    if (data && dataFF) {
      const allServices = data.user.services as Playground.SimpleService[]
      return isHome ? allServices : activeServices(allServices, dataFF.systemFeatures)
    }
  }, [isSuperAdmin, data, dataFF])

  return { loading: loading || loadingFF || services === null, services }
}

function useUser() {
  const { data, error, loading } = useQuery(GET_USER_DETAILS)

  const user = useMemo(() => {
    return data ? (data.user as Playground.User) : null
  }, [data])

  return { user, error, loading }
}

const AppRoot = React.memo(
  ({
    services,
    strategies,
    user,
  }: {
    services: Playground.SimpleService[]
    strategies: Nullable<Stargate.UserContext[]>
    user: Playground.User
  }) => {
    const { clearStrategy, showStrategyPrompt } = useContext(StrategyContext)

    const isSuperAdmin = perms.isSuperAdmin(user)
    const noHomeAccess = isHome && perms.isPersonnel(user)
    const noPlaygroundAccess = isPlayground && perms.isParent(user)
    const noPlaygroundEnabledService = isPlayground && services?.length === 0 && !isSuperAdmin
    const unauthorized = noHomeAccess || noPlaygroundAccess || noPlaygroundEnabledService

    useEffect(() => {
      if (strategies && unauthorized) {
        const educatorStrategy = findStrategy(strategies, 'Educator')
        const parentStrategy = findStrategy(strategies, 'Guardian') || findStrategy(strategies, 'Parent')

        const hasHomeStrategy = (noPlaygroundAccess || noPlaygroundEnabledService) && !!parentStrategy
        const hasPlaygroundStrategy = noHomeAccess && !!educatorStrategy

        if (hasHomeStrategy || hasPlaygroundStrategy) {
          clearStrategy()
          showStrategyPrompt()
        }
      }
    }, [
      clearStrategy,
      noHomeAccess,
      noPlaygroundAccess,
      noPlaygroundEnabledService,
      showStrategyPrompt,
      strategies,
      unauthorized,
    ])

    if (unauthorized) {
      return <UnauthorizedPage />
    }

    return <Root />
  }
)

const UserContextLoader = React.memo(({ user }: { user: Playground.User }) => {
  const { services, loading: servicesLoading } = useServices(user)
  const { strategies, loading: strategiesLoading } = useStargate()

  if (servicesLoading) return <Splash />
  if (strategiesLoading) return <Splash />

  return (
    <UserProvider user={user}>
      <ServiceProvider services={services!}>
        <StrategyProvider strategies={strategies!}>
          <AppRoot services={services!} strategies={strategies} user={user} />
          <UserStrategyPrompt />
        </StrategyProvider>
      </ServiceProvider>
    </UserProvider>
  )
})

const UserLoader = React.memo((_props: RouteComponentProps) => {
  const { user, error } = useUser()

  if (error) return <UnauthorizedPage />

  return user ? <UserContextLoader user={user} /> : <Splash />
})

UserContextLoader.displayName = 'UserContextLoader'
UserLoader.displayName = 'UserLoader'

export default UserLoader
