import React from 'react'

import styled from 'styled-components'

import Box, { BoxProps } from './Box'

export type FlexProps = BoxProps

const Flex = styled(Box)`
  display: flex;
`

Flex.displayName = 'Flex'

export default React.memo(Flex)
