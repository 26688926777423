import React from 'react'

import * as R from 'ramda'

import styled from 'styled-components'
import { height, width } from 'styled-system'

import Box from './Box'
import Flex from './Flex'
import Heading from './Heading'

import { large, small, xsmall, xxsmall } from './utils/avatarSize'

const AvatarBase = styled.div`
  ${height} ${width};
  ${large} ${small} ${xsmall} ${xxsmall};
  vertical-align: middle;
`

AvatarBase.defaultProps = {
  height: '80px',
  width: '80px',
}

AvatarBase.text = styled(AvatarBase)`
  background: ${(props) => props.theme.colors[props.backgroundColor]};
`

const TextAvatar = (props) => {
  const { large, small, xsmall, xxsmall, text, context } = props

  let textColor = 'avatarText'
  let backgroundColor = 'avatarBackground'

  if (context === 'Parent') {
    textColor = 'parentDark'
    backgroundColor = 'parentLight'
  } else if (context === 'Educator') {
    textColor = 'educatorDark'
    backgroundColor = 'educatorLight'
  }

  return (
    <AvatarBase.text
      large={large}
      small={small}
      xsmall={xsmall}
      xxsmall={xxsmall}
      backgroundColor={backgroundColor}>
      <Flex justifyContent="center" alignItems="center" height="100%">
        <Box>
          {R.cond([
            [
              R.prop('large'),
              R.always(
                <Heading.h2 bold={false} my={0} upper color={textColor}>
                  {text}
                </Heading.h2>
              ),
            ],
            [
              R.prop('small'),
              R.always(
                <Heading.h5 my={0} upper color={textColor}>
                  {text}
                </Heading.h5>
              ),
            ],
            [
              R.prop('xsmall'),
              R.always(
                <Heading.h6 my={0} upper color={textColor}>
                  {text}
                </Heading.h6>
              ),
            ],
            [
              R.prop('xxsmall'),
              R.always(
                <Heading.h6 fontSize={0} my={0} upper color={textColor}>
                  {text}
                </Heading.h6>
              ),
            ],
            [
              R.T,
              R.always(
                <Heading.h4 my={0} upper color={textColor}>
                  {text}
                </Heading.h4>
              ),
            ],
          ])(props)}
        </Box>
      </Flex>
    </AvatarBase.text>
  )
}

TextAvatar.displayName = 'TextAvatar'

export default TextAvatar
