import React from 'react'

const MomentCreated = () => (
  <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.9091,36.1458H4.0909C2.8182,36.1458 1.8182,35.1528 1.8182,33.8889V11.3194C1.8182,10.0556 2.8182,9.0625 4.0909,9.0625H35.9091C37.1818,9.0625 38.1818,10.0556 38.1818,11.3194V33.8889C38.1818,35.1528 37.1818,36.1458 35.9091,36.1458Z"
      fill="#ffffff"
      fillRule="evenodd"
    />
    <path
      d="M1.8182,30.2778V6.8514C1.8182,5.2896 2.8182,4.0625 4.0909,4.0625H35.9091C37.1818,4.0625 38.1818,5.2896 38.1818,6.8514V30.2778H1.8182Z"
      fill="#444B54"
      fillRule="evenodd"
    />
    <path
      d="M20,31.6319C25.0208,31.6319 29.0909,27.5901 29.0909,22.6042C29.0909,17.6183 25.0208,13.5764 20,13.5764C14.9793,13.5764 10.9091,17.6183 10.9091,22.6042C10.9091,27.5901 14.9793,31.6319 20,31.6319Z"
      fill="#ffffff"
      fillRule="evenodd"
    />
    <path
      d="M20,28.0208C23.0125,28.0208 25.4546,25.5957 25.4546,22.6042C25.4546,19.6126 23.0125,17.1875 20,17.1875C16.9876,17.1875 14.5455,19.6126 14.5455,22.6042C14.5455,25.5957 16.9876,28.0208 20,28.0208Z"
      fill="#7BCAC5"
      fillRule="evenodd"
    />
    <path
      d="M14.4186,3.3333H36.2791C38.3256,3.3333 40,5.3333 40,7.7778V35.5556C40,38 38.3256,40 36.2791,40H3.7209C1.6744,40 0,38 0,35.5556V7.7778C0,5.3333 1.6744,3.3333 3.7209,3.3333H6.9767V1.6667C6.9767,0.7222 7.5814,0 8.3721,0H13.0233C13.814,0 14.4186,0.7222 14.4186,1.6667V3.3333ZM36.6216,37.5C37.1047,37.5 37.5,36.9583 37.5,36.2963V6.2037C37.5,5.5417 37.1047,5 36.6216,5H5.8784C5.3953,5 5,5.5417 5,6.2037V36.2963C5,36.9583 5.3953,37.5 5.8784,37.5H36.6216Z"
      fill="#444B54"
      fillRule="evenodd"
    />
    <path
      d="M9.5455,22.6042C9.5455,28.3368 14.2273,32.9861 20,32.9861C25.7727,32.9861 30.4546,28.3368 30.4546,22.6042C30.4546,16.8715 25.7727,12.2222 20,12.2222C14.2273,12.2222 9.5455,16.8715 9.5455,22.6042ZM12.2727,22.6042C12.2727,18.3611 15.7273,14.9306 20,14.9306C24.2727,14.9306 27.7273,18.3611 27.7273,22.6042C27.7273,26.8472 24.2727,30.2778 20,30.2778C15.7273,30.2778 12.2727,26.8472 12.2727,22.6042Z"
      fill="#444B54"
      fillRule="evenodd"
    />
    <path
      d="M32.7273,18.0903C33.9824,18.0903 35,17.0798 35,15.8333C35,14.5869 33.9824,13.5764 32.7273,13.5764C31.4721,13.5764 30.4545,14.5869 30.4545,15.8333C30.4545,17.0798 31.4721,18.0903 32.7273,18.0903Z"
      fill="#ffffff"
      fillRule="evenodd"
    />
    <path
      d="M9.0909,28.4722C9.8441,28.4722 10.4546,27.8659 10.4546,27.1181C10.4546,26.3702 9.8441,25.7639 9.0909,25.7639C8.3378,25.7639 7.7273,26.3702 7.7273,27.1181C7.7273,27.8659 8.3378,28.4722 9.0909,28.4722Z"
      fill="#ffffff"
      fillRule="evenodd"
    />
    <path
      d="M15.9091,21.7014C17.1643,21.7014 18.1818,20.6909 18.1818,19.4444C18.1818,18.198 17.1643,17.1875 15.9091,17.1875C14.6539,17.1875 13.6364,18.198 13.6364,19.4444C13.6364,20.6909 14.6539,21.7014 15.9091,21.7014Z"
      fill="#ffffff"
      fillRule="evenodd"
    />
  </svg>
)

export default MomentCreated
