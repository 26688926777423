import React from 'react'

import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { snakeCase } from 'lodash'
import * as R from 'ramda'
import { Image } from 'stardust'

import * as selectors from '../core/selectors'

const GET_STICKER_PACKS = gql`
  query StickerPacks {
    stickerPacks {
      id
      stickers {
        id
        name
        url
      }
    }
  }
`

const Sticker = (props) => {
  const { data, error, loading } = useQuery(GET_STICKER_PACKS)

  if (error || loading) {
    return null
  }

  const id = selectors.refId.get(props)
  const kind = selectors.propKind.get(props)

  const sticker = R.pipe(
    R.prop('stickerPacks'),
    R.map(R.prop('stickers')),
    R.flatten,
    R.find(R.propEq('id', id))
  )(data)

  if (sticker) {
    return <Image height="100%" alt={sticker.name} src={sticker.url} width={1} />
  } else {
    // Handle the legacy case, where we are only storing the name
    const legacySticker = R.pipe(
      R.prop('stickerPacks'),
      R.map(R.prop('stickers')),
      R.flatten,
      R.find(R.propEq('name', snakeCase(kind)))
    )(data)

    return <Image height="100%" alt={legacySticker.name} src={legacySticker.url} width={1} />
  }
}

Sticker.displayName = 'Sticker'
export default Sticker
