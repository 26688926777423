/* eslint react/display-name: 0 */
import React from 'react'

import { RenderBlockProps } from 'slate-react'

import { Next } from '../../types'

export default {
  serialize: (
    obj: { object: string; type: string },
    children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined
  ) => {
    if (obj.object === 'block' && obj.type === 'list-item') {
      return <li>{children}</li>
    }
  },

  renderNode: ({ attributes, children, node }: RenderBlockProps, next: Next) => {
    if (node.type !== 'list-item') return next()

    return <li {...attributes}>{children}</li>
  },
}
