import React from 'react'

const Educators = () => (
  <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.75 11.9233L8.125 14.2392L13.75 16.2983L19.375 14.2392L13.75 11.9233V11.9233Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.125 14.2217V17.3642"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.625 15.1542V17.75C10.6237 18.0066 10.7793 18.2379 11.0175 18.3333L13.5175 19.3333C13.6667 19.3933 13.8333 19.3933 13.9825 19.3333L16.4825 18.3333C16.7207 18.2379 16.8763 18.0066 16.875 17.75V15.1542"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 5.625C6.38071 5.625 7.5 4.50571 7.5 3.125C7.5 1.74429 6.38071 0.625 5 0.625C3.61929 0.625 2.5 1.74429 2.5 3.125C2.5 4.50571 3.61929 5.625 5 5.625Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.79583 9.07333C7.80899 7.35424 5.78982 6.51322 3.87442 7.02347C1.95901 7.53372 0.625833 9.2678 0.625 11.25V13.125H2.5L3.125 19.375H6.875"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.75 5C14.9581 5 15.9375 4.02062 15.9375 2.8125C15.9375 1.60438 14.9581 0.625 13.75 0.625C12.5419 0.625 11.5625 1.60438 11.5625 2.8125C11.5625 4.02062 12.5419 5 13.75 5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 7.5C16.3947 6.45225 15.321 5.76096 14.1167 5.64359C12.9124 5.52621 11.7254 5.99718 10.9292 6.90833"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Educators
