import { useQuery } from '@apollo/client'

import { PaginatedResult } from '~/components/Documents/PaginatedResult'
import { useSelectedServiceFkey } from '~/contexts/Service'

import { GET_ALL_POSTS } from './queries'

export const useObservation = (
  queryByStatus = GET_ALL_POSTS,
  filteredVariables: any = null
): PaginatedResult => {
  const DATA_PATH = ['context', 'posts']
  const serviceFkey = useSelectedServiceFkey()
  const variables = filteredVariables ? { serviceFkey, ...filteredVariables } : { serviceFkey }

  const query = useQuery(queryByStatus, {
    variables: variables,
  })

  return { ...query, dataPath: DATA_PATH }
}
