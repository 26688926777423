import React from 'react'

const Open = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.3759 7.24835V1.62335H13.7509"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.3759 1.62335L6.87585 14.1234"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.37585 5.37335H1.87585C1.1855 5.37335 0.625854 5.933 0.625854 6.62335V19.1234C0.625854 19.8137 1.1855 20.3734 1.87585 20.3734H14.3759C15.0662 20.3734 15.6259 19.8137 15.6259 19.1234V11.6234"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Open
