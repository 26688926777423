import React, { useContext, useEffect, useState } from 'react'

import { Flex, FlexItem } from 'stardust'
import styled from 'styled-components'

import { ChildRankings, PostSaveState, PostState } from '../../types'

import { encodeChildOutcome, convertToSave } from '../../utils'

import { PostContext, PostProvider } from '../PostProvider'

import MediaList from './MediaList'
import MoreDetails from './MoreDetails'
import TagChildren from './PostChildrenModal'
import LearningOutcomes from './PostLearningOutcomesModal'
import PostLinksModal from './PostLinksModal'
import ModalDescription from './PostModalDescription'
import PostModalDescriptionLengthValidation from './PostModalDescriptionLengthValidation'
import ModalFooter from './PostModalFooter'
import ModalHeader from './PostModalHeader'
import ModalTitle from './PostModalTitle'
import PostPreview from './PostPreview'
import Rooms from './PostRoomModal'

interface AddNewPostModalLoaderProps {
  allowComments: boolean
  availableRooms: Playground.Room[]
  children: Playground.SimpleChild[]
  childRankings?: ChildRankings
  description?: string
  documentIds: string[]
  documents: Playground.Document[]
  futurePlanning?: string
  id?: number
  isEditing: boolean
  isCreating: boolean
  isReadOnly: boolean
  learningFrameworks: Playground.LearningFramework[]
  learningOutcomes: Playground.LearningFrameworkOutcome[]
  learningContentAssets: Playground.LearningContentAsset[]
  learningRecords: Playground.LearningRecord[]
  media: Playground.MediaItem[]
  planningDate?: string
  postDate?: string
  postTime?: string
  rooms: Playground.ObservationRoom[]
  reflections: Playground.Reflection[]
  showLearningContentAssets: boolean
  status?: Playground.Status
  title?: string
  updatedAt?: string
  onSubmit(state: PostSaveState, statusToSave: string, setLoadingFalse: () => void): void
  onAutoSave: (state: PostSaveState, status: string) => void
  handleCloseModal: () => void
}

interface AddNewPostModalProps {
  handleCloseModal: () => void
  isEditing: boolean
  isCreating: boolean
  isReadOnly: boolean
  onSubmit: (state: PostSaveState, statusToSave: string, setLoadingFalse: () => void) => void
  onAutoSave: (state: PostSaveState, status: string) => void
}

const MODAL_HEADER = '60px'
const MODAL_INTARNAL_SPACE = '32px'
const MODAL_SNACKBAR_HEIGHT = '70px'
const MODAL_MAX_HEIGHT = '872px'
const MODAL_OUTER_SPACE = '40px'
const MODAL_BODY_MAX_HEIGHT = `calc(${MODAL_MAX_HEIGHT} - ${MODAL_HEADER} - ${MODAL_INTARNAL_SPACE})`
const MODAL_BODY_MIN_HEIGHT = (isEditing: boolean, viewportHeight: string) =>
  `calc(${viewportHeight} - ${MODAL_HEADER} - ${MODAL_INTARNAL_SPACE} - ${MODAL_OUTER_SPACE} - ${
    isEditing ? MODAL_SNACKBAR_HEIGHT : '0px'
  })`
const VIEWPORT_HEIGHT = '100vh'
const VIEWPORT_HEIGHT_BREAKPOINT = '744px'

const S = {
  NewPostModal: styled(FlexItem)`
    width: 1290px;
    max-width: min(calc(100vw - 40px), calc((100vh - 40px) * 2));
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  `,
}

const toChildRankingsFromLearningRecords = (learningRecords: Playground.LearningRecord[]) => {
  return learningRecords.reduce((acc, { childFkey, outcomeId, ageGroupId, rankId }) => {
    const childRankingId = encodeChildOutcome(childFkey, outcomeId)
    return Object.assign(acc, {
      [childRankingId]: { ageGroupId: ageGroupId, rankId: rankId },
    })
  }, {})
}

const AddNewPostModal = ({
  handleCloseModal,
  onSubmit,
  onAutoSave,
  isEditing,
  isCreating,
  isReadOnly,
}: AddNewPostModalProps) => {
  const { state, handlers } = useContext(PostContext)
  const [isRoomModalOpen, setIsRoomModalOpen] = useState(false)
  const [isLearningOutcomesModalOpen, setIsLearningOutcomesModalOpen] = useState(false)
  const [isLinksModalOpen, setIsLinksModalOpen] = useState(false)
  const [isChildrenModalOpen, setIsChildrenModalOpen] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isPreviewOpen, setIsPreviewOpen] = useState(false)
  const [uploadErrors, setUploadErrors] = useState<Playground.Error[]>([])

  const MODAL_HEIGHT = (viewportHeight: string) =>
    `min(${MODAL_BODY_MAX_HEIGHT}, ${MODAL_BODY_MIN_HEIGHT(isEditing, viewportHeight)})`
  const MODAL_MIN_HEIGHT = MODAL_HEIGHT(VIEWPORT_HEIGHT)
  const MODAL_MAX_HEIGHT = `max(${MODAL_HEIGHT(VIEWPORT_HEIGHT_BREAKPOINT)}, ${MODAL_MIN_HEIGHT})`

  const handleOnSubmit = async (statusToSave: string, setLoadingFalse: () => void) => {
    const newState = convertToSave(state)
    onSubmit(newState, statusToSave, setLoadingFalse)
  }

  const handelOnSaveClick = () => {
    setIsSaving(true)
  }

  useEffect(() => {
    let autoSaveInterval: NodeJS.Timeout
    if (isEditing || isCreating) {
      autoSaveInterval = setInterval(() => {
        const status = state.status || 'DRAFT'
        const newState = convertToSave(state)

        onAutoSave(newState, status)
      }, 30000)
    }

    return () => {
      clearInterval(autoSaveInterval)
    }
  }, [isEditing, isCreating, state, onAutoSave])

  if (isPreviewOpen) {
    return (
      <S.NewPostModal p={3} data-test="layout-selector">
        <ModalHeader
          title={state.title}
          rooms={state.rooms}
          isEditing={isEditing}
          isPreviewOpen={isPreviewOpen}
          TaggedChildren={state.children}
          onSubmit={handleOnSubmit}
          onSaveClick={handelOnSaveClick}
          handleCloseModal={handleCloseModal}
          setIsPreviewOpen={setIsPreviewOpen}
        />
        <PostPreview
          title={state.title}
          rooms={state.rooms}
          date={state.postDate}
          time={state.postTime}
          description={state.description}
          taggedChildren={state.children}
          planningDate={state.planningDate}
          media={state.media}
          taggedDocuments={state.documents}
          futurePlanning={state.futurePlanning}
          availableRooms={state.availableRooms}
          learningOutcomes={state.learningOutcomes}
          isSaving={isSaving}
        />
      </S.NewPostModal>
    )
  }

  return (
    <S.NewPostModal p={3} data-test="layout-selector">
      <ModalHeader
        isEditing={isEditing}
        onSaveClick={handelOnSaveClick}
        onSubmit={handleOnSubmit}
        handleCloseModal={handleCloseModal}
        postId={state.id}
        status={state.status}
        title={state.title}
        rooms={state.rooms}
        TaggedChildren={state.children}
        isPreviewOpen={isPreviewOpen}
        setIsPreviewOpen={setIsPreviewOpen}
      />
      <Flex minHeight={MODAL_MIN_HEIGHT} maxHeight={MODAL_MAX_HEIGHT} flex={1}>
        <Flex flexDirection="column" flex={1}>
          <ModalTitle
            title={state.title}
            onChange={handlers.onChange}
            isReadOnly={isReadOnly}
            isSaving={isSaving}
          />
          <ModalDescription
            description={state.description}
            onChange={handlers.onChange}
            isReadOnly={isReadOnly}
          />
          <PostModalDescriptionLengthValidation description={state.description} />
          <MediaList
            uploadErrors={uploadErrors}
            isReadOnly={isReadOnly}
            media={state.media}
            uploadedMedia={state.uploadedMedia}
            handleRemoveMedia={handlers.removeMedia}
            onChange={handlers.onChange}
          />
          <ModalFooter
            isReadOnly={isReadOnly}
            rooms={state.rooms}
            setUploadErrors={setUploadErrors}
            taggedChildren={state.children}
            learningOutcomes={state.learningOutcomes}
            taggedDocuments={state.documents}
            onOpenRoomModal={() => setIsRoomModalOpen(true)}
            onOpenChildrenModal={() => setIsChildrenModalOpen(true)}
            onOpenLinksModal={() => setIsLinksModalOpen(true)}
            onOpenLearningOutcomesModal={() => setIsLearningOutcomesModalOpen(true)}
          />
        </Flex>
        <MoreDetails
          isSaving={isSaving}
          futurePlanning={state.futurePlanning}
          planningDate={state.planningDate}
          reflections={state.reflections}
          date={state.postDate}
          time={state.postTime}
          isReadOnly={isReadOnly}
          onChange={handlers.onChange}
          rooms={state.rooms}
          taggedChildren={state.children}
          learningOutcomes={state.learningOutcomes}
          availableRooms={state.availableRooms}
          documentIds={state.documentIds}
          taggedDocuments={state.documents}
          onOpenRoomModal={() => setIsRoomModalOpen(true)}
          onOpenChildrenModal={() => setIsChildrenModalOpen(true)}
          onOpenLearningOutcomesModal={() => setIsLearningOutcomesModalOpen(true)}
          onOpenLinksModal={() => setIsLinksModalOpen(true)}
        />
      </Flex>
      {isRoomModalOpen && (
        <Rooms
          rooms={state.rooms}
          availableRooms={state.availableRooms}
          onChange={handlers.onChange}
          isReadOnly={isReadOnly}
          onCloseRoomModal={() => setIsRoomModalOpen(false)}
        />
      )}
      {isChildrenModalOpen && (
        <TagChildren
          taggedChildren={state.children}
          onChange={handlers.onChange}
          onCloseChildrenModal={() => setIsChildrenModalOpen(false)}
          isReadOnly={false}
        />
      )}
      {isLearningOutcomesModalOpen && (
        <LearningOutcomes
          learningOutcomes={state.learningOutcomes}
          onChange={handlers.onChange}
          isReadOnly={isReadOnly}
          onCloseLearningOutcomesModal={() => setIsLearningOutcomesModalOpen(false)}
        />
      )}
      {isLinksModalOpen && (
        <PostLinksModal
          childList={state.children}
          documentIds={state.documentIds}
          onChange={handlers.onChange}
          taggedDocuments={state.documents}
          onCloseLinksModal={() => setIsLinksModalOpen(false)}
          isReadOnly={false}
        />
      )}
    </S.NewPostModal>
  )
}

const AddNewPostModalLoader = ({
  allowComments = true,
  availableRooms,
  children = [],
  childRankings,
  description = '',
  documentIds = [],
  documents = [],
  futurePlanning,
  id,
  learningOutcomes = [],
  learningRecords = [],
  learningContentAssets = [],
  media = [],
  onSubmit,
  onAutoSave,
  planningDate,
  postDate,
  postTime,
  rooms = [],
  reflections = [],
  status,
  title = '',
  updatedAt,
  handleCloseModal,
  isReadOnly = false,
  isCreating = false,
  isEditing = false,
}: AddNewPostModalLoaderProps) => {
  const childRankingsFromLearningEvidence = toChildRankingsFromLearningRecords(learningRecords)
  const existingChildRankings = Object.assign(childRankings || {}, childRankingsFromLearningEvidence)

  const postInitialState: PostState = {
    allowComments,
    availableRooms,
    childRankings: existingChildRankings,
    children,
    description,
    documentIds,
    documents,
    futurePlanning,
    id,
    learningOutcomes,
    learningContentAssets,
    media,
    planningDate,
    postDate,
    postTime,
    rooms,
    reflections,
    status,
    title,
    updatedAt,
    uploadedMedia: [],
    canAddLearningContentAssets: true,
  }

  return (
    <PostProvider state={postInitialState}>
      <AddNewPostModal
        isCreating={isCreating}
        onAutoSave={onAutoSave}
        handleCloseModal={handleCloseModal}
        isReadOnly={isReadOnly}
        onSubmit={onSubmit}
        isEditing={isEditing}
      />
    </PostProvider>
  )
}

AddNewPostModalLoader.displayName = 'AddNewPostModalLoader'

export default React.memo(AddNewPostModalLoader)
