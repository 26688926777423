import React from 'react'

import { Avatar, Box, Flex, Text, Truncate } from 'stardust'

import * as profiles from '~/components/Profiles/core'

interface Props {
  educators: Playground.Educator[]
}

const Educators = ({ educators }: Props) => (
  <Box data-test="document-educators">
    <Text bold mb={3} fontSize={2}>
      Educators
    </Text>

    {educators.map((educator) => (
      <Flex key={educator.fkey} alignItems="center" mt={2} width={1}>
        <Box pr={2}>
          <Avatar
            alt={educator.fullName}
            src={educator.image}
            xxsmall
            text={profiles.initials(educator.fullName)}
          />
        </Box>
        <Box width={1}>
          <Truncate caps fontSize={1} mb={1}>
            {educator.fullName}
          </Truncate>
        </Box>
      </Flex>
    ))}
  </Box>
)

Educators.displayName = 'DocumentRendererEducators'

export default React.memo(Educators)
