import React, { useEffect, useRef, useState } from 'react'

import { Icon, Box, Divider, Flex, Text, SecondaryButton, MainButton } from 'stardust'

import DocumentIcon from '~/components/DocumentIcon'
import Modal from '~/components/Modal'
import { sendAppSignalError } from '~/logging'
import DropdownSelector from '~/ui-components/DropdownSelector'
import InputField from '~/ui-components/InputField'

interface OptionProps {
  label: string
  value: any
  icon?: JSX.Element
}
interface Props {
  open: boolean
  name?: string
  location: string
  selectedFolder?: Nullable<Playground.DocumentFolder>
  title: string
  onSubmit: (name: string, location: string) => Promise<any>
  onClose: () => void
  onError?: (errors: any[]) => void
}

const SCREEN_WIDTH = '100vw'
const MODAL_OUTER_HORIZONTAL_SPACING = '40px'
const MODAL_INNER_SIDE_SPACING = '24px'

const EditFolderModal = ({
  open,
  name,
  location,
  selectedFolder,
  title,
  onSubmit,
  onClose,
  onError,
}: Props) => {
  const folderOptions = [
    {
      label: 'Post',
      value: 'observation',
      icon: <DocumentIcon type="observation" />,
    },
    {
      label: 'Document',
      value: 'learning_story',
      icon: <DocumentIcon type="learning_story" />,
    },
    {
      label: 'Table',
      value: 'learning_table',
      icon: <DocumentIcon type="learning_table" />,
    },
    {
      label: 'Canvas',
      value: 'learning_plan',
      icon: <DocumentIcon type="learning_plan" />,
    },
  ]

  const findFolderOptionByLocation = (location: string): OptionProps | undefined => {
    return folderOptions.find((option) => option.value === location)
  }

  const initialFolderLocation = findFolderOptionByLocation(location)

  const [folderName, setFolderName] = useState(name || '')
  const textFieldRef = useRef<HTMLInputElement>(null)
  const debounceRef = useRef<any>(null)
  const [folderLocation, setFolderLocation] = useState<OptionProps | undefined>(initialFolderLocation)

  useEffect(() => {
    setFolderName(name || '')
  }, [name])

  const createFolder = () => {
    clearTimeout(debounceRef.current)
    debounceRef.current = setTimeout(() => {
      if (!folderLocation) {
        return
      }

      if (folderName.trim() === '') {
        return
      }
      return onSubmit(folderName, folderLocation.value)
        .then(onClose)
        .then(() => setFolderName(''))
        .catch((error) => {
          sendAppSignalError(error)
          onError && onError(error)
        })
    }, 300)
  }

  return (
    <Modal open={open} onClose={onClose} dontRenderCloseButton>
      <Box width="680px" maxWidth={`calc(${SCREEN_WIDTH} - ${MODAL_OUTER_HORIZONTAL_SPACING})`}>
        <Flex flexGrow="1" alignItems="center" flexWrap="wrap" pt={3} px={MODAL_INNER_SIDE_SPACING}>
          <Box>
            <Icon size={30} name="folder" />
          </Box>
          <Box ml={2}>
            <strong>{title}</strong>
          </Box>
        </Flex>
        <Box px={MODAL_INNER_SIDE_SPACING} pb={MODAL_INNER_SIDE_SPACING} pt={3}>
          <Text fontSize={1} mr={2} lineHeight={2} bold>
            Folder Name
          </Text>
          <InputField
            height={48}
            borderRadius={1}
            ref={textFieldRef}
            data-test="new-folder-name"
            name="name"
            outlined={false}
            placeholder={'Untitled Folder'}
            type="text"
            value={folderName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFolderName(event.target.value.trimStart())
            }}
          />
          {selectedFolder ? null : (
            <Box mt={3}>
              <Text fontSize={1} mr={2} lineHeight={2} bold>
                Location
              </Text>
              <DropdownSelector
                placeHolder="Select a location"
                selectedOption={folderLocation}
                onSelect={(option) => setFolderLocation(option ? option : folderLocation)}
                options={folderOptions}
                renderOption={(option) => (
                  <>
                    <Box ml={2} mr={3}>
                      {option.icon}
                    </Box>
                    <Text>{option.label}</Text>
                  </>
                )}
              />
            </Box>
          )}
          <Box mb={3} mt={4}>
            <Divider width="100%" borderColor="surfacePrimaryBorder" m={0} />
          </Box>
          <Box mb={2} mt={2}>
            <SecondaryButton height={48} p="12px 24px 12px 24px" outline onClick={onClose}>
              Cancel
            </SecondaryButton>
          </Box>
          <Box>
            <MainButton height={48} p="12px 24px 12px 24px" onClick={() => createFolder()}>
              {selectedFolder ? 'Rename' : 'Create'}
            </MainButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default React.memo(EditFolderModal)
