import React from 'react'

import { Flex, FlexItem } from 'stardust'

import LearningOutcomePill from './LearningOutcomePill'

interface Props {
  learningOutcomes?: Playground.LearningFrameworkOutcome[]
  tagType?: string
}

const LearningOutcomeGrid = ({ learningOutcomes, tagType }: Props) => {
  return tagType === 'Learning Outcomes' ? (
    <Flex alignItems="space-between" mt="10px" flexDirection="column" ml={3} mr={3}>
      {learningOutcomes?.map((learningOutcome) => (
        <FlexItem mt={2} key={learningOutcome.id}>
          <LearningOutcomePill learningOutcome={learningOutcome} />
        </FlexItem>
      ))}
    </Flex>
  ) : null
}

LearningOutcomeGrid.displayName = 'LearningOutcomeGrid'

export default React.memo(LearningOutcomeGrid)
