import React from 'react'

import { Box, Flex, Icon, Text } from 'stardust'

interface Props {
  commentCount?: Nullable<number>
  viewCount?: Nullable<number>
  likeCount?: Nullable<number>
}

const DocumentIconsBar = ({ commentCount, viewCount, likeCount }: Props) => {
  return (
    // TODO: Will need to make likes and views dynamic once the BE is ready.
    <Box>
      <Flex alignItems="center" gap="8px">
        <Flex alignItems="center" justifyContent="space-between">
          <Box pr={1}>
            <Icon size={20} name="likes" />
          </Box>
          <Text fontSize={0}>{likeCount}</Text>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between">
          <Box pr={1}>
            <Icon size={20} name="commentsAlt2" />
          </Box>
          <Text fontSize={0}>{commentCount}</Text>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between">
          <Box pr={1}>
            <Icon size={20} name="views" />
          </Box>
          <Text fontSize={0}>{viewCount}</Text>
        </Flex>
      </Flex>
    </Box>
  )
}

DocumentIconsBar.displayName = 'DocumentIconsBar'

export default React.memo(DocumentIconsBar)
