import { OUTCOME_NODE_TYPE } from './types'

export default {
  inlines: {
    [OUTCOME_NODE_TYPE]: {
      // It's important that we mark the learning-outcome as void nodes so that entities
      // can't edit the text of the learning-outcome.
      isVoid: true,
    },
  },
}
