import React from 'react'

const BookmarkActive = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.6667 4H9.33342C7.86675 4 6.66675 5.2 6.66675 6.66667V28L16.0001 24L25.3334 28V6.66667C25.3334 5.2 24.1334 4 22.6667 4Z"
      fill="currentColor"
    />
  </svg>
)

export default BookmarkActive
