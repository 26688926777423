import React from 'react'

import { Avatar, Box, Flex, Icon, Text } from 'stardust'

import { FieldProps } from '~/pages/Observations/types'

import colors from '~/ui-components/tokens/colors'

interface Props extends FieldProps {
  taggedChildren: Playground.SimpleChild[]
}

const DisplayTaggedChildrenInMoreDetails = ({ taggedChildren, onChange }: Props) => {
  const removeTaggedChildren = (childfkey: string | undefined) => {
    const removeChild = taggedChildren.filter((fkey) =>
      typeof fkey === 'object' ? fkey.fkey !== childfkey : fkey !== childfkey
    )

    onChange({
      name: 'children',
      value: removeChild,
    })
  }

  return (
    <Box mb={3} pl={3} pt={3} pr={3} width="100%">
      {taggedChildren.map((child) => (
        <Flex
          height="48px"
          bg={colors.cosmicShade4}
          border="1px solid"
          borderColor={colors.cosmicShade6}
          borderRadius="16px"
          alignItems="center"
          justifyContent="space-between"
          p="10px"
          key={child.fkey}
          mb={taggedChildren.length > 1 ? 1 : 0}>
          <Flex alignItems="center" cursor="pointer">
            <Avatar src={child.image} xxsmall />
            <Text ml={1} fontSize="14px" fontWeight={400} color={colors.nebulaBlue4}>
              {child.fullName}
            </Text>
          </Flex>
          <Icon
            cursor="pointer"
            size={24}
            onClick={() => removeTaggedChildren(child.fkey)}
            name="closeAlt"
            bg={colors.cosmicShade4}
            hoverColor={colors.cosmicShade5}
          />
        </Flex>
      ))}
    </Box>
  )
}
DisplayTaggedChildrenInMoreDetails.displayName = 'DisplayTaggedChildrenInMoreDetails'

export default React.memo(DisplayTaggedChildrenInMoreDetails)
