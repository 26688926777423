import React from 'react'

const ObservationApproved = () => (
  <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20,38.2857C30.0989,38.2857 38.2857,30.0989 38.2857,20C38.2857,9.9011 30.0989,1.7143 20,1.7143C9.9011,1.7143 1.7143,9.9011 1.7143,20C1.7143,30.0989 9.9011,38.2857 20,38.2857Z"
      fill="#ffffff"
    />
    <path
      d="M20,34.8571C28.2054,34.8571 34.8572,28.2054 34.8572,20C34.8572,11.7946 28.2054,5.1428 20,5.1428C11.7947,5.1428 5.1429,11.7946 5.1429,20C5.1429,28.2054 11.7947,34.8571 20,34.8571Z"
      fill="#8CCFB9"
    />
    <path
      d="M0,20C0,8.9543 8.9543,0 20,0C31.0457,0 40,8.9543 40,20C40,31.0457 31.0457,40 20,40C8.9543,40 0,31.0457 0,20ZM20,3.4286C10.8479,3.4286 3.4286,10.8479 3.4286,20C3.4286,29.1521 10.8479,36.5714 20,36.5714C29.1521,36.5714 36.5714,29.1521 36.5714,20C36.5714,10.8479 29.1521,3.4286 20,3.4286Z"
      fill="#515151"
      fillRule="evenodd"
    />
    <path
      d="M29.5061,11.8495C30.2204,12.4709 30.2957,13.5537 29.6743,14.268L16.9391,28.9061L10.4825,23.1881C9.7737,22.5604 9.708,21.477 10.3357,20.7682C10.9634,20.0594 12.0468,19.9937 12.7556,20.6214L16.6228,24.0462L27.0876,12.0176C27.709,11.3033 28.7918,11.2281 29.5061,11.8495Z"
      fill="#ffffff"
      fillRule="evenodd"
    />
    <path
      d="M20,3.75C11.0254,3.75 3.75,11.0254 3.75,20C3.75,28.9746 11.0254,36.25 20,36.25C28.9746,36.25 36.25,28.9746 36.25,20C36.25,11.0254 28.9746,3.75 20,3.75ZM2.5,20C2.5,10.335 10.335,2.5 20,2.5C29.665,2.5 37.5,10.335 37.5,20C37.5,29.665 29.665,37.5 20,37.5C10.335,37.5 2.5,29.665 2.5,20Z"
      fill="#ffffff"
      fillRule="evenodd"
    />
  </svg>
)

export default ObservationApproved
