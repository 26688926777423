import React from 'react'

const MultiplePeopleRemove = () => (
  <svg viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2727 22C19.4358 22 22 19.4358 22 16.2727C22 13.1096 19.4358 10.5454 16.2727 10.5454C13.1096 10.5454 10.5454 13.1096 10.5454 16.2727C10.5454 19.4358 13.1096 22 16.2727 22Z"
      stroke="#930000"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.1363 14.3636L14.3635 19.1364"
      stroke="#930000"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3635 14.3636L19.1363 19.1364"
      stroke="#930000"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.77282 6.72727C7.35435 6.72727 8.63645 5.44517 8.63645 3.86364C8.63645 2.2821 7.35435 1 5.77282 1C4.19128 1 2.90918 2.2821 2.90918 3.86364C2.90918 5.44517 4.19128 6.72727 5.77282 6.72727Z"
      stroke="#595959"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5455 10.1993C9.39259 8.23004 7.03373 7.26664 4.79608 7.85114C2.55845 8.43564 1.00097 10.4221 1 12.6927V14.8405H3.19044L3.92059 21.9999H8.30147L8.61592 18.9137"
      stroke="#595959"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7955 5.77273C17.1135 5.77273 18.1819 4.70432 18.1819 3.38636C18.1819 2.06841 17.1135 1 15.7955 1C14.4776 1 13.4092 2.06841 13.4092 3.38636C13.4092 4.70432 14.4776 5.77273 15.7955 5.77273Z"
      stroke="#595959"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.1364 8.63639C18.4702 7.56909 17.2884 6.8649 15.9629 6.74534C14.6373 6.62577 13.331 7.10553 12.4546 8.03368"
      stroke="#595959"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default MultiplePeopleRemove
