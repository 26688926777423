import React from 'react'

import styled from 'styled-components'
import { HeightProps, SpaceProps, WidthProps, height, space, width } from 'styled-system'

type BaseProps = HeightProps & SpaceProps & WidthProps

interface Props extends BaseProps {
  color?: string
}

const StencilImage = styled.div<Props>`
  background-color: ${(props) => (props.color ? props.color : 'rgb(255, 255, 255)')};
  ${space};
  ${height};
  ${width};
`

StencilImage.displayName = 'StencilImage'

export default React.memo(StencilImage)
