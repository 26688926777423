import React from 'react'

import { Flex } from 'stardust'
import { FlexProps } from 'stardust/Flex'
import styled from 'styled-components'
import { color, space } from 'styled-system'

export const iconLookup: Record<string, React.ElementType> = {
  add: require('./icons/Add').default,
  addAlt: require('./icons/AddAlt').default,
  addCircle: require('./icons/AddCircle').default,
  AddCircleGreyable: require('./icons/AddCircleGreyable').default,
  AddOutcomes: require('./icons/AddOutcomes').default,
  alert: require('./icons/Alert').default,
  alignCenter: require('./icons/AlignCenter').default,
  alignLeft: require('./icons/AlignLeft').default,
  alignRight: require('./icons/AlignRight').default,
  archive: require('./icons/Archive').default,
  arrowRight: require('./icons/ArrowRight').default,
  arrowDown: require('./icons/ArrowDown').default,
  arrowUp: require('./icons/ArrowUp').default,
  attachments: require('./icons/Attachments').default,
  bold: require('./icons/Bold').default,
  bookmark: require('./icons/Bookmark').default,
  bookmarkActive: require('./icons/BookmarkActive').default,
  bookmarkAlt: require('./icons/BookmarkAlt').default,
  bookmarkAlt2: require('./icons/BookmarkAlt2').default,
  bullet: require('./icons/Bullet').default,
  calendar: require('./icons/Calendar').default,
  calendarAlt: require('./icons/CalendarAlt').default,
  calendarAlt2: require('./icons/CalendarAlt2').default,
  canvasNav: require('./icons/CanvasNav').default,
  check: require('./icons/Check').default,
  checkCircle: require('./icons/CheckCircle').default,
  checkCircleAlt: require('./icons/CheckCircleAlt').default,
  checkCircleAlt2: require('./icons/CheckCircleAlt2').default,
  chevronDown: require('./icons/ChevronDown').default,
  chevronLeft: require('./icons/ChevronLeft').default,
  chevronLeftDouble: require('./icons/ChevronLeftDouble').default,
  chevronSort: require('./icons/ChevronSort').default,
  chevronRight: require('./icons/ChevronRight').default,
  chevronRightDouble: require('./icons/ChevronRightDouble').default,
  chevronUp: require('./icons/ChevronUp').default,
  chevronUpTransparent: require('./icons/ChevronUpTransparent').default,
  children: require('./icons/Children').default,
  childrenAlt: require('./icons/ChildrenAlt').default,
  childrenNav: require('./icons/ChildrenNav').default,
  clipboard: require('./icons/Clipboard').default,
  clock: require('./icons/Clock').default,
  clockAlt: require('./icons/ClockAlt').default,
  clone: require('./icons/Clone').default,
  close: require('./icons/Close').default,
  closeAlt: require('./icons/CloseAlt').default,
  collapse: require('./icons/Collapse').default,
  cog: require('./icons/Cog').default,
  cohortTrackingNav: require('./icons/CohortTrackingNav').default,
  collage: require('./icons/Collage').default,
  color: require('./icons/Color').default,
  comments: require('./icons/Comments').default,
  commentsAlt: require('./icons/CommentsAlt').default,
  commentsAlt2: require('./icons/CommentsAlt2').default,
  commentsAlt3: require('./icons/CommentsAlt3').default,
  commsCenter: require('./icons/CommsCenter').default,
  content: require('./icons/Content').default,
  copy: require('./icons/Copy').default,
  dashboardNav: require('./icons/DashboardNav').default,
  delete: require('./icons/Delete').default,
  detach: require('./icons/Detach').default,
  draft: require('./icons/Draft').default,
  duplicate: require('./icons/Duplicate').default,
  edit: require('./icons/Edit').default,
  editOutlined: require('./icons/EditOutlined').default,
  educatorsNav: require('./icons/EducatorsNav').default,
  error: require('./icons/Error').default,
  evacuationReport: require('./icons/notifications/playground/EvacuationReport').default,
  eye: require('./icons/Eye').default,
  expand: require('./icons/Expand').default,
  feedback: require('./icons/Feedback').default,
  file: require('./icons/File').default,
  fileDownload: require('./icons/FileDownload').default,
  financeNav: require('./icons/FinanceNav').default,
  folder: require('./icons/Folder').default,
  folderAlt: require('./icons/FolderAlt').default,
  folderPlus: require('./icons/FolderPlus').default,
  fullscreen: require('./icons/Fullscreen').default,
  futurePlans: require('./icons/FuturePlans').default,
  frameworks: require('./icons/Frameworks').default,
  frameworkCategory: require('./icons/FrameworkCategory').default,
  frameworkCategoryOpen: require('./icons/FrameworkCategoryOpen').default,
  hand: require('./icons/Hand').default,
  happy: require('./icons/Happy').default,
  hamburgerMenu: require('./icons/HamburgerMenu').default,
  headCountTick: require('./icons/notifications/playground/HeadCountTick').default,
  help: require('./icons/Help').default,
  hightlightOff: require('./icons/HighlightOff').default,
  image: require('./icons/Image').default,
  imagePlaceholder: require('./icons/ImagePlaceholder').default,
  incidentReport: require('./icons/notifications/playground/IncidentReport').default,
  italic: require('./icons/Italic').default,
  learningContent: require('./icons/LearningContent').default,
  learningContentArticle: require('./icons/LearningContentArticle').default,
  learningContentVideo: require('./icons/LearningContentVideo').default,
  learningContentPodcast: require('./icons/LearningContentPodcast').default,
  learningFramework: require('./icons/LearningFramework').default,
  learningFrameworksNav: require('./icons/LearningFrameworksNav').default,
  learningOutcome: require('./icons/LearningOutcome').default,
  lock: require('./icons/Lock').default,
  likes: require('./icons/Likes').default,
  likesAlt: require('./icons/LikesAlt').default,
  likesValid: require('./icons/LikesValid').default,
  links: require('./icons/Links').default,
  medicalReport: require('./icons/notifications/playground/MedicalReport').default,
  momentCreated: require('./icons/notifications/playground/MomentCreated').default,
  moments: require('./icons/Moments').default,
  momentsNav: require('./icons/MomentsNav').default,
  multiplePeopleAdd: require('./icons/MultiplePeopleAdd').default,
  multiplePeopleRemove: require('./icons/MultiplePeopleRemove').default,
  multiplePeopleTime: require('./icons/MultiplePeopleTime').default,
  notification: require('./icons/Notification').default,
  noChildrenFound: require('./icons/NoChildrenFound').default,
  noPhotos: require('./icons/NoPhotos').default,
  noPhotosAlt: require('./icons/NoPhotosAlt').default,
  noSearchResults: require('./icons/NoSearchResults').default,
  numbered: require('./icons/Numbered').default,
  observationApproved: require('./icons/notifications/playground/ObservationApproved').default,
  observationGeneral: require('./icons/notifications/playground/ObservationGeneral').default,
  observationModified: require('./icons/notifications/playground/ObservationModified').default,
  observationRemoved: require('./icons/notifications/playground/ObservationRemoved').default,
  observations: require('./icons/Observations').default,
  observationsNav: require('./icons/ObservationsNav').default,
  open: require('./icons/Open').default,
  outcome: require('./icons/Outcome').default,
  outcomes: require('./icons/Outcomes').default,
  outcomesAlt: require('./icons/OutcomesAlt').default,
  overflow: require('./icons/Overflow').default,
  paperclip: require('./icons/Paperclip').default,
  pin: require('./icons/Pin').default,
  plans: require('./icons/Plans').default,
  play: require('./icons/Play').default,
  postsNav: require('./icons/PostsNav').default,
  print: require('./icons/Print').default,
  printer: require('./icons/Printer').default,
  questionMark: require('./icons/QuestionMark').default,
  redo: require('./icons/Redo').default,
  reportsNav: require('./icons/ReportsNav').default,
  reflections: require('./icons/Reflections').default,
  removeCircle: require('./icons/RemoveCircle').default,
  rocket: require('./icons/Rocket').default,
  room: require('./icons/Room').default,
  selectRoom: require('./icons/SelectRoom').default,
  roomsNav: require('./icons/RoomsNav').default,
  restore: require('./icons/Restore').default,
  save: require('./icons/Save').default,
  search: require('./icons/Search').default,
  send: require('./icons/Send').default,
  service: require('./icons/Service').default,
  shapes: require('./icons/Shapes').default,
  stickers: require('./icons/Stickers').default,
  stop: require('./icons/Stop').default,
  stories: require('./icons/StoriesNav').default,
  tag: require('./icons/Tag').default,
  tables: require('./icons/Tables').default,
  tablesNav: require('./icons/TablesNav').default,
  text: require('./icons/Text').default,
  tick: require('./icons/Tick').default,
  triangleDown: require('./icons/TriangleDown').default,
  triangleUp: require('./icons/TriangleUp').default,
  undo: require('./icons/Undo').default,
  user: require('./icons/User').default,
  userAlt: require('./icons/UserAlt').default,
  warning: require('./icons/Warning').default,
  wheelchair: require('./icons/Wheelchair').default,
  video: require('./icons/Video').default,
  views: require('./icons/Views').default,
  viewsAlt: require('./icons/ViewsAlt').default,
  zoomIn: require('./icons/ZoomIn').default,
  zoomOut: require('./icons/ZoomOut').default,
}

const fill = (props: any) =>
  props.fill
    ? {
        fill: props.theme.colors[props.fill] ? props.theme.colors[props.fill] : props.fill,
      }
    : null

const stroke = (props: any) =>
  props.stroke
    ? {
        stroke: props.theme.colors[props.stroke] ? props.theme.colors[props.stroke] : props.stroke,
      }
    : null

interface Props extends FlexProps {
  disabled?: boolean
  cursorPointer?: boolean
  name: keyof typeof iconLookup
  size?: number
  fill?: string
}

const Base = ({ name, fill, size = 24, ...props }: Props) => {
  const IconComponent = iconLookup[name]

  if (!IconComponent) {
    console.warn(`No icon found: ${name}`)
    return null
  }

  return (
    <Flex {...props} alignItems="center" width={size}>
      <IconComponent fill={fill} />
    </Flex>
  )
}

const Icon = styled(Base)`
  ${({ cursorPointer }) => !(cursorPointer === false) && { cursor: 'pointer' }};
  flex: none;
  pointer-events: auto;

  ${space};
  ${color};
  ${fill};
  ${stroke};

  ${({ disabled }) => disabled && { fill: 'rgb(0, 0, 0, 0.35)' }};
  transition: fill 0.25s ease;

  svg {
    height: auto;
    width: 100%;
  }
`

Icon.displayName = 'Icon'

export default React.memo(Icon)
