import React, { useState, useContext, useEffect } from 'react'

import { Box, Icon, Flex, FlexItem, Avatar, Sticky, Truncate, Text, IconButton } from 'stardust'

import styled from 'styled-components'

import * as profiles from '~/components/Profiles/core'

import { UserContext } from '~/contexts/User'
import colors from '~/ui-components/tokens/colors'

import { FieldProps } from '../../types'

import Backdating from './PostModalBackdating'
import FuturePlanning from './PostModalFuturePlanning'
import Reflections from './PostReflections'
import TagComponentInSidebar from './TagComponentInSidebar'

const SIDEBAR_HEADER_HEIGHT = '64px'

const S = {
  Flex: styled(Flex)`
    width: 400px;
    overflow-y: auto;
    height: calc(100% - ${SIDEBAR_HEADER_HEIGHT});
  `,
  SideBar: styled(Flex)`
    background-color: ${colors.cosmicShade4};
    margin-left: 8px;
    border: '2px solid';
    align-items: flex-end;
    border-radius: 16px;
  `,
}

interface MoreDetails extends FieldProps {
  date?: string
  time?: string
  futurePlanning?: string
  planningDate?: string
  reflections?: Playground.Reflection[]
  isReadOnly: boolean
  documentIds?: string[]
  taggedDocuments?: Playground.Document[]
  availableRooms: Playground.Room[]
  rooms: Playground.ObservationRoom[]
  taggedChildren?: Playground.SimpleChild[]
  learningOutcomes: Playground.LearningFrameworkOutcome[]
  isSaving?: boolean
  onOpenRoomModal: () => void
  onOpenChildrenModal: () => void
  onOpenLearningOutcomesModal: () => void
  onOpenLinksModal: () => void
}

const Hr = () => <Box borderBottom="2px solid" borderColor={colors.cosmicShade6} m={2} mt={3} mb={3}></Box>

const MoreDetails = ({
  date,
  time,
  futurePlanning,
  reflections,
  planningDate,
  isReadOnly,
  documentIds,
  taggedDocuments,
  rooms,
  taggedChildren,
  learningOutcomes,
  availableRooms,
  isSaving,
  onOpenLearningOutcomesModal,
  onOpenRoomModal,
  onOpenChildrenModal,
  onOpenLinksModal,
  onChange,
}: MoreDetails) => {
  const [showMore, setShowMore] = useState(false)
  const { state: user } = useContext(UserContext)

  useEffect(() => {
    if (isSaving && rooms.length === 0) {
      setShowMore(true)
    }
  }, [isSaving, rooms])

  if (!user) {
    return null
  }

  if (showMore) {
    return (
      <Flex
        bg={colors.cosmicShade4}
        flexDirection="column"
        borderRadius={1}
        ml={2}
        borderColor="surfacePrimaryBorder"
        border="2px solid">
        <Sticky p={2}>
          <Flex alignItems="center" justifyContent="space-between">
            <Text fontSize="19px" fontWeight={700} ml={2}>
              About This Post
            </Text>
            <Flex alignItems="center" cursor="pointer">
              <Text onClick={() => setShowMore(!showMore)} fontSize={1}>
                Close
              </Text>
              <IconButton
                onClick={() => setShowMore(!showMore)}
                icon={<Icon name="chevronRight" fill="textPrimaryMedium" data-test="sidebar-close" />}
              />
            </Flex>
          </Flex>
        </Sticky>
        <S.Flex p={2} flexDirection="column">
          <FlexItem>
            <Flex flexDirection="column" m={2}>
              <Text ml={1} fontSize="17px" fontWeight={700}>
                Author
              </Text>
              <Flex mt={2} alignItems="center">
                <Avatar
                  small
                  alt={`${user.displayName}'s Profile`}
                  src={user.image}
                  text={profiles.initials(user.displayName)}
                />
                <Flex flexDirection="column" ml={2}>
                  <Text.span fontWeight={700} caps mb={1}>
                    {user.displayName || 'Profile'}
                  </Text.span>
                  <Text.span caps medium fontSize={0}>
                    <Truncate mt={1} id="user-id" fontWeight={400} fontSize="14px">
                      {user.roles ? user.roles.map((role) => role.replace(/_/g, ' ')) : []}
                    </Truncate>
                  </Text.span>
                </Flex>
              </Flex>
              <Backdating isReadOnly={isReadOnly} date={date} time={time} onChange={onChange} />
            </Flex>
            <Hr />
            <TagComponentInSidebar
              title="Room"
              helperTextTitle="(Post location)*"
              addTagButton="Select Room"
              defaultMessage="No room selected"
              onOpenRoomModal={onOpenRoomModal}
              rooms={rooms}
              availableRooms={availableRooms}
              isSaving={isSaving}
            />
            <Hr />
            <TagComponentInSidebar
              title="Children"
              addTagButton="Add Children"
              defaultMessage="No children selected"
              onOpenChildrenModal={onOpenChildrenModal}
              taggedChildren={taggedChildren}
            />
            <Hr />
            <TagComponentInSidebar
              title="Learning Outcomes"
              addTagButton="Add Outcome"
              defaultMessage="None"
              learningOutcomes={learningOutcomes}
              onOpenLearningOutcomesModal={onOpenLearningOutcomesModal}
            />
            <Hr />
            <TagComponentInSidebar
              title="Links"
              addTagButton="Add link"
              defaultMessage="None"
              documentIds={documentIds}
              taggedDocuments={taggedDocuments}
              onOpenLinksModal={onOpenLinksModal}
            />
            <Hr />
            <FuturePlanning
              isReadOnly={isReadOnly}
              futurePlanning={futurePlanning}
              planningDate={planningDate}
              onChange={onChange}
            />
            <Hr />
            {reflections && reflections?.length > 0 && (
              <Reflections isReadOnly={isReadOnly} reflections={reflections} onChange={onChange} />
            )}
          </FlexItem>
        </S.Flex>
      </Flex>
    )
  }

  return (
    <S.SideBar>
      <Flex flexDirection="column" width="64px" alignItems="center" m={2} mb={4}>
        <IconButton onClick={() => setShowMore(!showMore)} icon={<Icon name="hamburgerMenu" />} />
        <Text mt={2} fontWeight={500} fontSize={1}>
          More
        </Text>
      </Flex>
    </S.SideBar>
  )
}

MoreDetails.displayName = 'MoreDetails'
export default React.memo(MoreDetails)
