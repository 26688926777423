import React from 'react'

import format from 'date-fns/format'

import { Text } from 'stardust'

interface Props {
  updatedAt: string
}

const CommentsDateAndTime = ({ updatedAt }: Props) => {
  const parsedDate = new Date(updatedAt)

  const time = format(parsedDate, 'h:mm a')
  const date = format(parsedDate, 'ddd MMM D YYYY')
  return <Text fontSize="12px" color="#838383" minWidth={'max-content'} mt={2}>{`${date} • ${time}`}</Text>
}

CommentsDateAndTime.displayName = 'CommentsDateAndTime'

export default React.memo(CommentsDateAndTime)
