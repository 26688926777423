import React from 'react'

const FileDownload = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1972 23.25C21.5008 23.25 24.1788 20.5637 24.1788 17.25C24.1788 13.9363 21.5008 11.25 18.1972 11.25C14.8936 11.25 12.2156 13.9363 12.2156 17.25C12.2156 20.5637 14.8936 23.25 18.1972 23.25Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.1973 14.25V20.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.1972 20.25L15.9541 18"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.1973 20.25L20.4404 18"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.73853 6.75H15.2064"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.73853 11.25H10.7202"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.73853 15.75H8.47705"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.47714 20.25H3.24321C2.41732 20.25 1.7478 19.5784 1.7478 18.75V2.25C1.7478 1.42157 2.41732 0.75 3.24321 0.75H13.8397C14.236 0.750085 14.6161 0.907982 14.8964 1.189L17.7596 4.061C18.0398 4.3422 18.1972 4.72345 18.1973 5.121V7.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default FileDownload
