import { useEffect, useState } from 'react'

interface PropType {
  url: string
  element: string
  attributes: { [key: string]: string }
}

const useScript = (props: PropType) => {
  const { url, element, attributes } = props
  const [status, setStatus] = useState<string | null>(url ? 'loading' : 'error')

  useEffect(() => {
    if (!url) {
      setStatus('error')
      return
    }

    let scriptEl = document.querySelector(`script[src="${url}"]`)

    const setDataStatus = (event: Event) => {
      const status = event.type === 'load' ? 'ready' : 'error'
      scriptEl?.setAttribute('data-status', status)
      setStatus(status)
    }

    if (scriptEl) {
      setStatus(scriptEl.getAttribute('data-status'))
    } else {
      scriptEl = document.createElement(element)
      scriptEl.id = url + 'id'

      Object.keys(attributes).map((attribute) => {
        scriptEl?.setAttribute(attribute, attributes[attribute] as string)
      })
      document.body.appendChild(scriptEl)

      scriptEl.addEventListener('load', setDataStatus)
      scriptEl.addEventListener('error', setDataStatus)
    }

    return () => {
      if (scriptEl) {
        document.body.removeChild(scriptEl)
      }
    }
  }, [url, attributes, element])
  return status
}

export default useScript
