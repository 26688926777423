import React from 'react'

const Pin = () => (
  <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.42348 13.1745C6.82203 13.5274 6.4182 14.1392 6.33011 14.8309C6.24203 15.5227 6.47966 16.2162 6.97348 16.7085L19.293 29.028C19.7853 29.5218 20.4788 29.7595 21.1706 29.6714C21.8623 29.5833 22.4741 29.1795 22.827 28.578C24.2862 26.0676 24.7214 23.0912 24.042 20.268L33.8355 14.0355C34.4113 13.6693 34.7898 13.0615 34.8645 12.3831C34.9391 11.7048 34.7019 11.0292 34.2195 10.5465L25.455 1.78201C24.9725 1.29947 24.2971 1.06197 23.6187 1.13634C22.9404 1.2107 22.3325 1.58889 21.966 2.16451L15.7335 11.9595C12.9103 11.2801 9.93394 11.7153 7.42348 13.1745Z"
      fill="#DB3B03"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1295 22.869L1.125 34.8735"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Pin
