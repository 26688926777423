import React from 'react'

const Cog = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.03067 1.62541C7.27807 1.90004 7.63036 2.05684 8 2.05684C8.36964 2.05684 8.72193 1.90004 8.96933 1.62541L9.6 0.933411C9.97037 0.523506 10.5586 0.390984 11.069 0.602465C11.5793 0.813946 11.9014 1.32368 11.8733 1.87541L11.826 2.80874C11.8075 3.17693 11.9455 3.53577 12.206 3.79663C12.4665 4.0575 12.8251 4.19605 13.1933 4.17808L14.1267 4.13074C14.678 4.10356 15.1869 4.42598 15.3979 4.93612C15.6088 5.44626 15.4762 6.03393 15.0667 6.40408L14.372 7.03074C14.0977 7.27849 13.9412 7.63081 13.9412 8.00041C13.9412 8.37002 14.0977 8.72233 14.372 8.97008L15.0667 9.59674C15.4766 9.96712 15.6091 10.5553 15.3976 11.0657C15.1861 11.5761 14.6764 11.8982 14.1247 11.8701L13.1913 11.8227C12.8223 11.8038 12.4625 11.9421 12.2013 12.2033C11.94 12.4646 11.8017 12.8244 11.8207 13.1934L11.868 14.1267C11.8925 14.6756 11.5708 15.181 11.0633 15.3914C10.5558 15.6017 9.97096 15.4719 9.6 15.0667L8.97267 14.3727C8.72504 14.0985 8.37283 13.942 8.00333 13.942C7.63383 13.942 7.28162 14.0985 7.034 14.3727L6.404 15.0667C6.0336 15.4739 5.4477 15.6051 4.93903 15.3948C4.43036 15.1845 4.10813 14.6779 4.13333 14.1281L4.18133 13.1947C4.20031 12.8257 4.06201 12.466 3.80073 12.2047C3.53945 11.9434 3.17968 11.8051 2.81067 11.8241L1.87733 11.8714C1.32582 11.9002 0.815873 11.5788 0.603922 11.0688C0.39197 10.5589 0.523885 9.97068 0.933333 9.60008L1.62733 8.97341C1.90161 8.72567 2.05814 8.37335 2.05814 8.00374C2.05814 7.63414 1.90161 7.28182 1.62733 7.03408L0.933333 6.40408C0.525259 6.03391 0.393539 5.44738 0.604148 4.93827C0.814758 4.42916 1.32234 4.10711 1.87267 4.13341L2.806 4.18074C3.17573 4.20018 3.53632 4.0617 3.79799 3.79977C4.05966 3.53785 4.1978 3.17712 4.178 2.80741L4.13333 1.87341C4.10753 1.32332 4.42954 0.816157 4.93836 0.60552C5.44718 0.394883 6.03344 0.526042 6.404 0.933411L7.03067 1.62541Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 11.0007C9.65685 11.0007 11 9.65759 11 8.00073C11 6.34388 9.65685 5.00073 8 5.00073C6.34315 5.00073 5 6.34388 5 8.00073C5 9.65759 6.34315 11.0007 8 11.0007Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Cog
