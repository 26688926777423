import React from 'react'

import { Absolute, Flex, Image, Link, Text, Truncate } from 'stardust'
import styled from 'styled-components'

import plansIcon from '~/assets/images/plans.svg'
import storiesIcon from '~/assets/images/stories.svg'
import tablesIcon from '~/assets/images/tables.svg'
import type { DropdownMenuItemProps } from '~/components/DropdownMenu'
import type { LearningTemplateTypes } from '~/components/Layouts/enums'
import OverflowMenu from '~/components/OverflowMenu'

import * as TableAnalytics from '~/modules/analytics/tables'

type TemplateTypes = keyof typeof LearningTemplateTypes

interface LayoutCardPros {
  isGlobal?: boolean
  menuItems?: DropdownMenuItemProps[]
  title: string
  to: string
  type: TemplateTypes
  onTileClick?: () => void
}

const StyledLink = styled(Link)`
  text-decoration: none;
`

const StyledCard = styled(Flex)`
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: ${(props) => props.theme.shadows['1dp']};
  }
`

const getImage = (type: TemplateTypes) => {
  switch (type) {
    case 'LEARNING_PLAN':
      return plansIcon
    case 'LEARNING_STORY':
      return storiesIcon
    case 'LEARNING_TABLE':
      return tablesIcon
  }
}

const LayoutCard = ({ isGlobal, menuItems, title, to, type, onTileClick }: LayoutCardPros) => {
  const onClick = () => {
    if (type === 'LEARNING_TABLE' && isGlobal) {
      switch (title) {
        case 'Monthly Plan':
          TableAnalytics.tablesCreateMonthlyPlanTable()
          break
        case 'Quality Improvement Plan (QIP)':
          TableAnalytics.tablesCreateQIPTable()
          break
        case 'Weekly Plan':
          TableAnalytics.tablesCreateWeeklyPlanTable()
          break
      }
    }
    if (onTileClick) onTileClick()
  }

  return (
    <StyledLink to={to} onClick={onClick}>
      <Flex flexDirection="column" width="140px" data-test="layout-tile">
        <StyledCard
          alignItems="center"
          border="1px solid"
          borderColor="cosmicShade6"
          borderRadius="16px"
          height="140px"
          justifyContent="center"
          mb={2}
          position="relative">
          <Image src={getImage(type)} height="64px" width="64px" />

          {menuItems && (
            <Absolute right={-6} top={0}>
              <OverflowMenu items={menuItems} />
            </Absolute>
          )}
        </StyledCard>

        <Flex justifyContent="center" width={1}>
          <Text.span fontSize={1} maxWidth="140px" textAlign="center">
            <Truncate>{title}</Truncate>
          </Text.span>
        </Flex>
      </Flex>
    </StyledLink>
  )
}

LayoutCard.displayName = 'LayoutCard'

export default LayoutCard
