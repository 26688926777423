import React from 'react'

import { Box, Flex } from 'stardust'

interface listItemProps {
  renderIcon: any
  renderText: any
}

const ListItem = ({ renderIcon, renderText }: listItemProps) => (
  <Flex alignItems="center" width={1}>
    <Box px={3}>{renderIcon()}</Box>
    <Box borderBottom={1} borderColor="surfacePrimaryBorder" py={3} width={1}>
      {renderText()}
    </Box>
  </Flex>
)

ListItem.displayName = 'ListItem'

export default ListItem
