import React, { useCallback } from 'react'

import { Radio as LibRadio } from '@rmwc/radio'

import { Flex } from 'stardust'

import styled from 'styled-components'

import colors from '~/ui-components/tokens/colors'

interface Props {
  checked: boolean
  label?: string
  onChange(checked: boolean): void
}

const Container = styled(Flex)`
  .mdc-radio {
    padding: 5px;
    border: 2px solid ${({ checked }) => (checked ? `${colors.nebulaBlue7}` : `${colors.cosmicShade7}`)};

    border-radius: 50%;
    transition: border-color 0.3s;
  }

  .mdc-radio .mdc-radio__background {
    border-radius: 50%;
    padding: 5px;
    background-color: ${({ checked }) => (checked ? colors.nebulaBlue7 : 'transparent')};
  }

  .mdc-form-field {
    width: 100%;
    padding: 14px 20px 14px 20px;
    margin-right: 16px;
    background-color: ${({ checked }) => (checked ? colors.cosmicShade19 : colors.cosmicShade0)};
    transition: background-color 0.3s;
  }

  .mdc-form-field:hover {
    background-color: ${colors.cosmicShade19};
  }

  .mdc-ripple-upgraded--unbounded.mdc-ripple-upgraded.mdc-ripple-surface.mdc-ripple-surface--accent.mdc-radio {
    order: 2;
    padding: 3px;
  }
`

const RadioButton = ({ checked, label, onChange }: Props) => {
  const onToggle = useCallback(() => onChange(!checked), [checked, onChange])

  return (
    <Container checked={checked} onClick={onToggle} alignItems="center">
      <LibRadio
        onClick={(event) => event.stopPropagation()}
        label={label}
        checked={checked}
        onChange={onToggle}
      />
    </Container>
  )
}

RadioButton.displayName = 'RadioButton'

export default React.memo(RadioButton)
