import React from 'react'

const Links = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.24144 15.5143C3.44462 14.3426 3.69237 12.7422 4.69676 11.7378L6.65867 9.77592C7.00686 9.42773 7.00686 8.85188 6.65867 8.50369C6.31048 8.1555 5.73463 8.1555 5.38644 8.50369L3.49149 10.3986C1.74385 12.1463 1.46262 14.9854 3.03617 16.8937C4.83738 19.0833 8.08491 19.1971 10.0401 17.2419L12.0824 15.1996C12.4306 14.8514 12.4306 14.2756 12.0824 13.9274C11.7342 13.5792 11.1583 13.5792 10.8102 13.9274L8.7679 15.9697C7.48897 17.2486 5.31279 17.0946 4.24144 15.5143ZM8.70094 13.2243L12.7185 9.20677C13.0868 8.83849 13.0868 8.23585 12.7185 7.86758C12.3502 7.4993 11.7476 7.4993 11.3793 7.86758L7.36175 11.8851C6.99347 12.2534 6.99347 12.8561 7.36175 13.2243C7.73002 13.5926 8.33266 13.5926 8.70094 13.2243ZM9.89282 3.99732L7.99786 5.89227C7.64967 6.24046 7.64967 6.81631 7.99786 7.1645C8.34605 7.51269 8.9219 7.51269 9.27009 7.1645L11.232 5.20259C12.2364 4.1982 13.8367 3.95045 15.0085 4.74726C16.5888 5.81862 16.7428 7.9948 15.4638 9.27373L13.4216 11.316C13.0734 11.6642 13.0734 12.24 13.4216 12.5882C13.7698 12.9364 14.3456 12.9364 14.6938 12.5882L16.7361 10.546C18.6913 8.59074 18.5775 5.34321 16.3946 3.5353C14.4862 1.96175 11.6405 2.24968 9.89282 3.99732Z"
      fill="currentColor"
    />
  </svg>
)

export default Links
