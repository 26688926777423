import React from 'react'

import * as R from 'ramda'

import { Box, StencilCircle as Circle, Container, Flex, StencilLine as Line } from 'stardust'

const Loading = () => (
  <Container>
    {R.pipe(
      R.range(5),
      R.reverse,
      R.map((i) => (
        <Flex key={i} alignItems="center" ml={2} my={2}>
          <Circle width="40px" height="40px" />
          <Box
            flexDirection="column"
            width={3 / 5}
            py={3}
            borderBottom={1}
            borderColor="surfacePrimaryBorder">
            <Line width={1} m={2} />
            <Line width={3 / 4} m={2} />
          </Box>
        </Flex>
      ))
    )(10)}
  </Container>
)

export default Loading
