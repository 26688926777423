import { useContext } from 'react'

import { UserContext } from '~/contexts/User'

/*
 * requestedPermission: The required permission(s) to perform this action
 * assignedPermissions: Optionally provide the permissions to check, otherwise get from user context
 */
function usePermission(requestedPermission: string, assignedPermissions?: string[]) {
  const { state: user } = useContext(UserContext)
  const permissions = assignedPermissions || user?.featurePermissions || []

  // Converts a permission 'observations:read' to 'observations:*' to check if a user
  // has wildcard permissions for this resource
  const requestedPermissionWildcard = requestedPermission.replace(/:.*$/, ':*')

  return permissions.includes(requestedPermission) || permissions.includes(requestedPermissionWildcard)
}

export default usePermission
