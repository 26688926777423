import React from 'react'

const AlignRight = () => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M3 21h18v-2H3v2zm6-4h12v-2H9v2zm-6-4h18v-2H3v2zm6-4h12V7H9v2zM3 3v2h18V3H3z"
    />
  </svg>
)

export default AlignRight
