import React from 'react'

const Outcomes = () => (
  <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.49998 2.91663H17.5C18.4166 2.91663 19.1666 3.66663 19.1666 4.58329V15.4166C19.1666 16.3333 18.4166 17.0833 17.5 17.0833H2.49998C1.58331 17.0833 0.833313 16.3333 0.833313 15.4166V4.58329C0.833313 3.66663 1.58331 2.91663 2.49998 2.91663ZM2.49999 4.58329V15.4166H9.16666V4.58329H2.49999ZM17.5 15.4166H10.8333V4.58329H17.5V15.4166ZM16.6666 7.49996H11.6666V8.74996H16.6666V7.49996ZM11.6666 9.58329H16.6666V10.8333H11.6666V9.58329ZM16.6666 11.6666H11.6666V12.9166H16.6666V11.6666Z"
      fill="currentColor"
    />
  </svg>
)

export default Outcomes
