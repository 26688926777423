import React, { useContext, useMemo, useState } from 'react'

import { Box, Card, Flex } from 'stardust'
import styled from 'styled-components'

import DonutChart, { DonutChartDatum } from '~/components/Charts/DonutChart'
import FilterDropdown, { FkeyOrId, Id, ProfileGroup, ProfileItem } from '~/components/FilterDropdown'
import useLocalStorage from '~/hooks/useLocalStorage'
import { LAYERS } from '~/theme'
import { objectKeys, sortObjectsBy } from '~/utils'

import CategoryFilterMenu from '../Common/CategoryFilterMenu'
import { LearningAnalysisContext } from '../Context'

interface Props {
  defaultFilter?: ProfileItem<FkeyOrId>
  filters: ProfileGroup<FkeyOrId>[]
  filterDropdownText?: string
  localStorageKey?: string
  records: Playground.LearningRecord[]
}

const ChartContainer = styled(Box)`
  height: 240px;
  position: relative;
`

const OutcomesAreaTile = ({
  defaultFilter,
  filters,
  filterDropdownText,
  localStorageKey,
  records,
}: Props) => {
  const { frameworkDepth, filterRecords, findCategory, groupRecordsByCategory } =
    useContext(LearningAnalysisContext)
  const initialFilter = defaultFilter ? defaultFilter : null
  const [categoryFilter, setCategoryFilter] = useState<Nullable<ProfileItem<Id>>>(null)
  const [childFilter, setChildFilter] = useLocalStorage<Nullable<ProfileItem<FkeyOrId>>>(
    initialFilter,
    localStorageKey
  )

  const data = useMemo(() => {
    if (records.length === 0) return []

    const baseRecords = filterRecords(records, categoryFilter)
    const filteredRecords = filterRecords(baseRecords, childFilter)
    const groupField = categoryFilter ? 'secondaryId' : 'primaryId'
    const groupedRecords = groupRecordsByCategory(filteredRecords, groupField)
    const chartData: DonutChartDatum[] = []
    const recordCount = filteredRecords.length

    let matchedRecordCount = 0

    objectKeys(groupedRecords).map((categoryId) => {
      const category = findCategory(categoryId)!
      const categoryRecords = groupedRecords[categoryId]

      matchedRecordCount += categoryRecords.length

      chartData.push({
        label: category.name,
        value: categoryRecords.length / recordCount,
      })
    })

    // This shouldn't be needed but it handles some bad staging data
    if (matchedRecordCount < recordCount) {
      chartData.push({
        label: 'Invalid Data',
        value: (recordCount - matchedRecordCount) / recordCount,
      })
    }

    return sortObjectsBy(chartData, 'value').reverse().slice(0, 7)
  }, [categoryFilter, childFilter, filterRecords, findCategory, groupRecordsByCategory, records])

  return (
    <Card>
      <Flex flexDirection="column" height={340}>
        <Flex alignItems="center" p={3} width={1}>
          <FilterDropdown
            buttonDefaultText={filterDropdownText}
            filters={filters}
            portal
            selection={childFilter}
            onSelect={setChildFilter}
            zIndex={LAYERS.SectionHeader}
          />
        </Flex>

        <Flex alignItems="center" flexGrow={1} width={1}>
          <ChartContainer width={1}>
            <DonutChart data={data} height={240} />
          </ChartContainer>
        </Flex>

        {frameworkDepth > 1 && (
          <Flex borderTop="1px solid" borderColor="surfacePrimaryBorder" mx={3} py={2}>
            <Flex flexDirection="column">
              <CategoryFilterMenu value={categoryFilter} onChange={setCategoryFilter} />
            </Flex>
          </Flex>
        )}
      </Flex>
    </Card>
  )
}

OutcomesAreaTile.displayName = 'OutcomesAreaTile'

export default React.memo(OutcomesAreaTile)
