import gql from 'graphql-tag'

export default gql`
  fragment ObservationRooms on Observation {
    rooms {
      name
      roomId
      roomFkey
    }
  }
`
