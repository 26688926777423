import gql from 'graphql-tag'

export default gql`
  fragment DraftPostInfo on DraftPost {
    title
    status
    planningDate
    publishedAt
    updatedAt
    futurePlanning
    description
    bookmarked
  }
`
