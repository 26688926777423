import React from 'react'

const CloseAlt = ({ fill = '#838383' }) => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.2452 6.429C15.5696 6.10454 15.5696 5.57847 15.2452 5.254C14.9207 4.92954 14.3946 4.92954 14.0702 5.254L9.99935 9.32484L5.92852 5.254C5.60405 4.92954 5.07798 4.92954 4.75352 5.254C4.42905 5.57847 4.42905 6.10454 4.75352 6.429L8.82435 10.4998L4.75352 14.5707C4.42905 14.8951 4.42905 15.4212 4.75352 15.7457C5.07798 16.0701 5.60405 16.0701 5.92852 15.7457L9.99935 11.6748L14.0702 15.7457C14.3946 16.0701 14.9207 16.0701 15.2452 15.7457C15.5696 15.4212 15.5696 14.8951 15.2452 14.5707L11.1743 10.4998L15.2452 6.429Z"
      fill={fill}
    />
  </svg>
)

export default CloseAlt
