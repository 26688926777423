import React from 'react'

const Stop = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7331 7.47483L14.2331 6.30817C13.1656 5.95179 11.9919 6.13047 11.0788 6.78836C10.1657 7.44625 9.62465 8.50308 9.6248 9.6285V14.8785L7.5353 13.2032C7.24684 12.9721 6.87457 12.8728 6.50936 12.9296C6.14415 12.9864 5.81961 13.1941 5.61496 13.5018V13.5018C5.31975 13.9444 5.31975 14.5211 5.61496 14.9637L8.61213 19.46C9.61822 20.9686 11.3115 21.8748 13.1248 21.875H15.737C16.9006 21.8754 18.0168 21.4138 18.8403 20.5917V20.5917C19.6622 19.7685 20.1237 18.6527 20.1236 17.4895V10.7952C20.124 9.2894 19.1612 7.95216 17.7331 7.47483Z"
      stroke="currentColor"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.875 27.125H20.125L27.125 20.125V7.875L20.125 0.875H7.875L0.875 7.875V20.125L7.875 27.125V27.125Z"
      stroke="currentColor"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Stop
