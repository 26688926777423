import React from 'react'

import { Absolute, Flex, Heading, Truncate } from 'stardust'

import * as selectors from '../core/selectors'

const ShapeBulkChildren = (props) => {
  const label = selectors.refData.get(props)
  return (
    <Absolute bottom={0} left={0} right={0} top={0}>
      <Flex alignItems="center" color="rgb(0,0,0,0.65)" height="100%" justifyContent="center" width={1}>
        <Truncate style={{ maxWidth: '80%', textAlign: 'center' }}>
          <Heading.h3>{label}</Heading.h3>
        </Truncate>
      </Flex>
    </Absolute>
  )
}

export default ShapeBulkChildren
