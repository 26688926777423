import React, { ReactNode, useState } from 'react'

import { Avatar, Box, Divider, Flex, Heading, Popover, Text } from 'stardust'

import * as profiles from '~/components/Profiles/core'

import MentionedLink from './MentionedLink'
import MentionedText from './MentionedText'

interface Props {
  children?: ReactNode
  icon?: string
  image?: string
  link?: string
  readOnly: boolean
  title: string
}

const MentionedTooltip = ({ children, icon, image, link, readOnly, title }: Props) => {
  const anchorRef = React.createRef<any>()
  const [hover, setHover] = useState(false)

  return (
    <>
      <Text.span ref={anchorRef} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        {readOnly && link ? (
          <MentionedLink link={link}>{title}</MentionedLink>
        ) : (
          <MentionedText>{title}</MentionedText>
        )}
      </Text.span>

      <Popover
        anchor={anchorRef}
        placement="bottom-start"
        show={hover}
        showArrow
        onToggle={() => setHover(false)}>
        <Flex flexDirection="column" p={3}>
          <Flex alignItems="center">
            <Box mr={2}>
              <Avatar xxsmall icon={icon} src={image} text={profiles.initials(title || '')} />
            </Box>
            <Heading>{title}</Heading>
          </Flex>
          {children && <Divider borderColor="surfacePrimaryBorder" my={3} />}
          {children}
        </Flex>
      </Popover>
    </>
  )
}

MentionedTooltip.displayName = 'MentionedTooltip'

export default React.memo(MentionedTooltip)
