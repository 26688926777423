import React from 'react'

const MomentsNav = () => (
  <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.9375 7.5C6.11009 7.5 6.25 7.63991 6.25 7.8125C6.25 7.98509 6.11009 8.125 5.9375 8.125C5.76491 8.125 5.625 7.98509 5.625 7.8125C5.625 7.63991 5.76491 7.5 5.9375 7.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.59416 12.8533C5.65175 14.8589 7.73269 16.1141 9.99999 16.1142C10.14 16.1142 10.2783 16.1092 10.4167 16.1C10.5317 16.0922 10.6458 16.0814 10.7592 16.0675"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.3658 9.60251C19.1485 4.48104 14.858 0.483709 9.73397 0.628765C4.60994 0.77382 0.552464 5.00747 0.6252 10.133C0.697936 15.2586 4.87391 19.3754 9.99999 19.375C10.14 19.375 10.2783 19.375 10.4167 19.365"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0383 8.05C12.3899 7.48817 13.006 7.14694 13.6687 7.14694C14.3315 7.14694 14.9476 7.48817 15.2992 8.05"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.3658 9.60251L10.4167 19.365"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4075 19.375V18.56C10.4075 13.6074 14.4224 9.5925 19.375 9.5925"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default MomentsNav
