import React from 'react'

import * as R from 'ramda'
import { Text, hoverMixin } from 'stardust'
import styled from 'styled-components'

import { hasAtLeastOne } from '~/utils'

import Suggestion from './Suggestion'

const List = styled.ul`
  list-style: none;
  max-width: 400px;
  margin: 0;
  padding: 0;
`

const ListItem = styled.li`
  cursor: pointer;
  ${hoverMixin};
`

const SuggestionList = ({ entities, getKey, onSelect }) => (
  <List>
    {R.ifElse(
      hasAtLeastOne,
      R.map((entity) => (
        <ListItem key={getKey(entity)} onClick={() => onSelect(entity)}>
          <Suggestion entity={entity} />
        </ListItem>
      )),
      R.always(
        <ListItem>
          <Text p={2}>None Found</Text>
        </ListItem>
      )
    )(entities)}
  </List>
)

SuggestionList.displayName = 'SuggestionList'
export default SuggestionList
