import gql from 'graphql-tag'

export default gql`
  fragment DraftPostRooms on DraftPost {
    rooms {
      name
      roomId
      roomFkey
    }
  }
`
