import React from 'react'

import { Flex, Text } from 'stardust'

const ListEnd = () => (
  <Flex justifyContent="center" mt={4} mb={4}>
    <Text medium>There are no more items to load</Text>
  </Flex>
)

ListEnd.displayName = 'ListEnd'

export default ListEnd
