/*
Controls styling rules for content contained within a cell
*/

import React from 'react'

import styled from 'styled-components'

import textStyle from '~/modules/textEditor/style'

import { CELL_MIN_HEIGHT, CELL_MIN_WIDTH } from '../constants'

interface Props {
  background: string
}

const CellContent = styled.div.attrs<Props>((props) => ({
  style: {
    backgroundColor: props.background,
  },
}))`
  display: flex;
  flex-grow: 1;
  min-height: ${CELL_MIN_HEIGHT}px;
  min-width: ${CELL_MIN_WIDTH}px;

  ${textStyle};

  /* Removes leading and trailing margins on text */
  > div {
    > :first-child {
      margin-top: 0;
    }
    > :last-child {
      margin-bottom: 0;
    }
  }

  /* Don't force a height on the slate editor */
  div[data-slate-editor] {
    min-height: 0;
    width: 100%;
  }

  /* Ensures slate content doesn't get wider than cell */
  div[data-slate-editor] > div {
    max-width: 100%;
  }

  div[data-slate-editor] > p {
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 20px;
    margin: 0;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  /* Constrain media width to the cell */
  img,
  video {
    max-width: 100%;
  }
`

export default React.memo(CellContent)
