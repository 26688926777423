import React from 'react'

const NoSearchResults = () => (
  <svg width="226" height="183" viewBox="0 0 226 183" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M91.2398 182.28C141.376 182.28 182.019 141.637 182.019 91.5015C182.019 41.3657 141.376 0.722656 91.2398 0.722656C41.104 0.722656 0.460938 41.3657 0.460938 91.5015C0.460938 141.637 41.104 182.28 91.2398 182.28Z"
      fill="#FEF2E0"
    />
    <g filter="url(#filter0_d_5878_802351)">
      <path
        d="M50.091 42.8789H51.091H213.493C214.833 42.8789 216.118 43.4112 217.065 44.3586C218.013 45.306 218.545 46.591 218.545 47.9308V78.1904C218.545 79.5303 218.013 80.8153 217.065 81.7627C216.118 82.7101 214.833 83.2424 213.493 83.2424H50.091C48.7511 83.2424 47.4662 82.7101 46.5187 81.7627C45.5713 80.8153 45.0391 79.5303 45.0391 78.1904V47.9308C45.0391 46.591 45.5713 45.306 46.5187 44.3586C47.4662 43.4112 48.7511 42.8789 50.091 42.8789Z"
        fill="white"
        stroke="#686868"
        strokeWidth="2"
      />
    </g>
    <path
      d="M127.554 51.5586H96.0843C94.0788 51.5586 92.4531 53.1843 92.4531 55.1897C92.4531 57.1952 94.0788 58.8209 96.0843 58.8209H127.554C129.56 58.8209 131.185 57.1952 131.185 55.1897C131.185 53.1843 129.56 51.5586 127.554 51.5586Z"
      fill="#7BCAC5"
    />
    <path
      d="M149.341 67.293H96.0843C94.0788 67.293 92.4531 68.9187 92.4531 70.9241C92.4531 72.9296 94.0788 74.5553 96.0843 74.5553H149.341C151.347 74.5553 152.972 72.9296 152.972 70.9241C152.972 68.9187 151.347 67.293 149.341 67.293Z"
      fill="#DFF2F1"
    />
    <path
      d="M84 63C84 54.7157 77.2843 48 69 48C60.7157 48 54 54.7157 54 63C54 71.2843 60.7157 78 69 78C77.2843 78 84 71.2843 84 63Z"
      fill="#01A39D"
    />
    <path
      d="M68.0652 67.8585C69.3494 67.8583 70.5966 67.4286 71.6083 66.6378L74.7894 69.8188L75.8125 68.7957L72.6304 65.6137C73.4299 64.58 73.8557 63.3061 73.8385 61.9994C73.8212 60.6928 73.3619 59.4305 72.5355 58.4183C71.709 57.4061 70.5641 56.7037 69.2873 56.4255C68.0105 56.1473 66.6771 56.3098 65.5045 56.8864C64.3318 57.463 63.3891 58.4198 62.8298 59.6009C62.2705 60.7819 62.1278 62.1175 62.4248 63.3901C62.7218 64.6626 63.441 65.797 64.4653 66.6085C65.4896 67.4199 66.7585 67.8605 68.0652 67.8585ZM68.0652 57.7268C68.9246 57.7268 69.7646 57.9816 70.4791 58.459C71.1936 58.9364 71.7505 59.615 72.0793 60.4089C72.4082 61.2028 72.4942 62.0764 72.3266 62.9192C72.1589 63.762 71.7451 64.5362 71.1375 65.1438C70.5299 65.7515 69.7557 66.1653 68.9129 66.3329C68.0701 66.5006 67.1965 66.4145 66.4025 66.0857C65.6086 65.7568 64.9301 65.2 64.4526 64.4854C63.9752 63.7709 63.7204 62.9309 63.7204 62.0716C63.7204 60.9193 64.1782 59.8141 64.993 58.9993C65.8078 58.1845 66.9129 57.7268 68.0652 57.7268Z"
      fill="white"
    />
    <g filter="url(#filter1_d_5878_802351)">
      <path
        d="M31.9425 97.3477H195.344C198.135 97.3477 200.396 99.6095 200.396 102.4V132.659C200.396 135.449 198.135 137.711 195.344 137.711H31.9425C29.1524 137.711 26.8906 135.449 26.8906 132.659V102.4C26.8906 99.6095 29.1524 97.3477 31.9425 97.3477Z"
        fill="white"
        stroke="#BDBDBD"
        strokeWidth="2"
      />
    </g>
    <path
      d="M109.398 106.027H77.928C75.9226 106.027 74.2969 107.653 74.2969 109.658C74.2969 111.664 75.9226 113.29 77.928 113.29H109.398C111.403 113.29 113.029 111.664 113.029 109.658C113.029 107.653 111.403 106.027 109.398 106.027Z"
      fill="#AFDEDC"
    />
    <path
      d="M131.185 121.762H77.928C75.9226 121.762 74.2969 123.387 74.2969 125.393C74.2969 127.398 75.9226 129.024 77.928 129.024H131.185C133.19 129.024 134.816 127.398 134.816 125.393C134.816 123.387 133.19 121.762 131.185 121.762Z"
      fill="#DFF2F1"
    />
    <path
      d="M65 118C65 109.716 58.2843 103 50 103C41.7157 103 35 109.716 35 118C35 126.284 41.7157 133 50 133C58.2843 133 65 126.284 65 118Z"
      fill="#42B4AD"
    />
    <path
      d="M49.0731 122.855C50.3572 122.854 51.6044 122.425 52.6162 121.634L55.7972 124.815L56.8203 123.792L53.6382 120.61C54.4377 119.576 54.8635 118.302 54.8463 116.996C54.829 115.689 54.3697 114.427 53.5433 113.414C52.7168 112.402 51.5719 111.7 50.2951 111.422C49.0183 111.143 47.6849 111.306 46.5123 111.883C45.3396 112.459 44.3969 113.416 43.8376 114.597C43.2784 115.778 43.1356 117.114 43.4326 118.386C43.7296 119.659 44.4488 120.793 45.4731 121.605C46.4974 122.416 47.7663 122.857 49.0731 122.855ZM49.0731 112.723C49.9324 112.723 50.7724 112.978 51.4869 113.455C52.2014 113.933 52.7583 114.611 53.0871 115.405C53.416 116.199 53.502 117.073 53.3344 117.915C53.1667 118.758 52.7529 119.532 52.1453 120.14C51.5377 120.748 50.7635 121.161 49.9207 121.329C49.0779 121.497 48.2043 121.411 47.4104 121.082C46.6164 120.753 45.9379 120.196 45.4605 119.482C44.983 118.767 44.7282 117.927 44.7282 117.068C44.7282 115.915 45.186 114.81 46.0008 113.995C46.8156 113.181 47.9207 112.723 49.0731 112.723Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d_5878_802351"
        x="38.0391"
        y="38.8789"
        width="187.508"
        height="54.3633"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5878_802351" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5878_802351" result="shape" />
      </filter>
      <filter
        id="filter1_d_5878_802351"
        x="19.8906"
        y="93.3477"
        width="187.508"
        height="54.3633"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5878_802351" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5878_802351" result="shape" />
      </filter>
    </defs>
  </svg>
)

export default NoSearchResults
