import React, { createContext, useMemo } from 'react'

import * as R from 'ramda'

import { isDev } from '~/config'
import { jsonSerializer } from '~/modules/textEditor'
import { toFkey } from '~/utils'

import { actions } from '../core/actions'
import { cloneFactory } from '../core/factories'
import plannerPosition from '../core/plannerPosition'
import * as selectors from '../core/selectors'
import useHistoryReducer from '../core/useHistory'
import { initialValue } from '../Editor'

export const EditContext = createContext({ dispatch: (arg) => null, state: null })

const initialState = {
  id: null,
  isCloning: false,
  isDirty: false,
  isDragging: false,
  isResizing: false,
  nodeIds: [],
  nodes: {},
  preview: true,
  properties: {
    background: '#FFFFFF',
  },
  selection: [],
  transform: { x: 0, y: 0, k: 1 },
  updatedAt: null,
  edit: {
    id: null,
    value: initialValue,
  },
}

/*
 * builds the TaggedEntity data structure from an entity.
 * An entity can be either a child, document or learning outcome from Graph
 */

const buildTagData = R.cond([
  [
    R.propEq('type', 'child'),
    ({ data }) => ({
      type: 'child',
      typeFkey: data.fkey,
      displayText: data.fullName,
    }),
  ],
  [
    R.propEq('type', 'document'), // TO.DO: AP - Pretty sure 'document' isn't used (was it ever? idk probs before my time). Doesn't exist in table article_tagged_entities
    ({ data }) => ({
      type: 'document',
      typeId: data.id,
      // typeFkey: data.fkey,
      displayText: data.title,
    }),
  ],
  [
    R.propEq('type', 'learning_outcome'),
    ({ data }) => ({
      type: 'learning_outcome',
      typeFkey: data.fkey,
      displayText: data.name,
    }),
  ],
  [R.T, R.always(null)],
])

/* 
  Populate the "tagged" field of the "node" object.
  i.e. A Story is dragged onto the canvas. The story has tagged children/outcomes, so those
  tagged entities will populate this field
*/
const addTaggedEntityToNode = R.curry((tagData, state, nodeId) => {
  const node = selectors.node.get(nodeId, state)

  const tagged = R.pipe(
    selectors.tagged.get,
    R.append(tagData),
    R.flatten,
    R.uniqBy(({ type, typeFkey }) => `${type}${typeFkey}`)
  )(node)

  return {
    ...node,
    tagged,
  }
})

/* 
  Remove Tagged Entity from given node id - {"nodeId": { "tagged" : [{"type" : _, "typeId": _, "typeFkey":_}]}}
  Supporting removing typeId and typeFkey for pre-migration plans
*/
const removeTaggedEntityToNode = R.curry(({ type, typeFkey }, state, nodeId) => {
  const node = selectors.node.get(nodeId, state)
  const isFkeyMatch = (entity) => (toFkey(entity.typeId) || entity.typeFkey) == typeFkey
  const tagged = R.reject((entity) => entity.type == type && isFkeyMatch(entity), node.tagged || [])

  return {
    ...node,
    tagged,
  }
})

const reducer = (state, action) => {
  if (isDev) {
    console.group('🔊action::', action.type)
    console.log('🚚 payload::', action.payload)
    console.groupEnd('🔊action::', action.type)
  }

  switch (action.type) {
    case actions.NODE.BATCH_TAGGED_ADD: {
      const { payload: entities } = action

      const nodeId = entities[0].nodeId
      const tagData = R.map((entity) => buildTagData(entity), entities)
      const newNode = addTaggedEntityToNode(tagData, state, nodeId)

      console.log('isDropped', {
        [newNode.id]: {
          ...newNode,
          data: R.omit(['isDropped'], newNode.data),
        },
      })

      const result = {
        ...state,
        nodes: {
          ...state.nodes,
          [newNode.id]: {
            ...newNode,
            data: R.omit(['isDropped'], newNode.data),
          },
        },
      }

      return result
    }

    case 'bail': {
      return R.pipe(selectors.editId.set(undefined), selectors.selection.set([]))(state)
    }

    case actions.PLANNER_CHANGE: {
      const { payload } = action

      return R.pipe(R.mergeDeepRight(initialState), selectors.isDirty.set(true))(payload)
    }

    case actions.PLANNER.BACKGROUND_CHANGE: {
      const {
        payload: { background },
      } = action

      return R.pipe(selectors.background.set(background), selectors.isDirty.set(true))(state)
    }

    case actions.PLANNER.CLONING_CHANGE: {
      const {
        payload: { isCloning },
      } = action

      return selectors.isCloning.set(isCloning, state)
    }

    case actions.PLANNER.DRAGGING_CHANGE: {
      const {
        payload: { id, isDragging },
      } = action

      return R.pipe(selectors.isDragging.set(isDragging), selectors.selection.set([id]))(state)
    }

    case actions.EDIT.NODE_CHANGE: {
      const { payload: id } = action

      return R.pipe(selectors.editId.set(id))(state)
    }

    case actions.EDIT.VALUE_CHANGE: {
      const { payload: value } = action

      const serialized = jsonSerializer.serialize(value.data)

      const id = selectors.editId.get(state)
      const node = selectors.node.get(id, state)
      const nodes = selectors.nodes.get(state)

      const newNode = {
        ...node,
        data: {
          ...node.data,
          refData: serialized,
          ref: 'content',
        },
      }

      const newNodes = R.mergeDeepLeft({ [node.id]: newNode }, nodes)

      return R.pipe(
        selectors.editValue.set(null),
        selectors.editId.set(null),
        selectors.nodes.set(newNodes)
      )(state)
    }

    case actions.PLANNER.RESIZING_CHANGE: {
      const {
        payload: { isResizing },
      } = action

      return R.pipe(selectors.isResizing.set(isResizing))(state)
    }

    case actions.PLANNER.NODES_CLONE: {
      const { payload: direction } = action

      const [selectedId] = selectors.selection.get(state)
      const node = selectors.node.get(selectedId, state)
      const newNode = cloneFactory(node, direction)
      const { id } = newNode

      return R.pipe(
        selectors.nodeIds.append(id),
        selectors.nodes.set({ [id]: newNode }),
        selectors.selection.set([id]),
        selectors.isDirty.set(true)
      )(state)
    }

    /* 
      Support both ID's and Fkeys here. We need to take existing id's and parse as fkeys if possible
    */
    case actions.PLANNER.UNTAG_CHILDREN: {
      const {
        payload: { childFkeys },
      } = action
      const nodes = selectors.nodes.get(state)
      const removedNodeIds = []
      const newNodes = {}

      const removeTag = (tag) =>
        tag.type === 'child' && childFkeys.includes(toFkey(tag.typeId) || tag.typeFkey)

      Object.entries(nodes).forEach(([k, v]) => {
        if (v.data.ref === 'child' && childFkeys.includes(toFkey(v.data.refId))) {
          removedNodeIds.push(k)
        } else if (v.kind === 'shape') {
          const newTaggedEntities = v.tagged.filter((tag) => !removeTag(tag))

          if (v.data.ref === 'children' && newTaggedEntities.length === 0) {
            removedNodeIds.push(k)
          } else {
            newNodes[k] = {
              ...v,
              tagged: newTaggedEntities,
            }
          }
        } else {
          newNodes[k] = v
        }
      })
      return R.pipe(
        // Remove child and children nodes from the node ids - this ensures they are not displayed
        selectors.nodeIds.remove(removedNodeIds),
        // Remove child and children nodes - this ensures we don't have unused content increasing the content size
        selectors.nodes.remove(removedNodeIds),
        // Override old nodes with versions of the nodes with no tagged children
        selectors.nodes.set(newNodes),
        selectors.isDirty.set(true)
      )(state)
    }

    case actions.PLANNER.NODES_ADD: {
      const {
        payload: { node },
      } = action

      const { id } = node

      return R.pipe(
        selectors.nodeIds.append(id),
        selectors.nodes.set({ [id]: node }),
        selectors.selection.set([id]),
        selectors.isDirty.set(true)
      )(state)
    }

    case actions.PLANNER.NODES_REMOVE: {
      const {
        payload: { selection },
      } = action

      return R.pipe(
        selectors.editId.set(null),
        selectors.editValue.set(null),
        selectors.isDirty.set(true),
        selectors.nodeIds.remove(selection),
        selectors.nodes.remove(selection),
        selectors.selection.remove(selection)
      )(state)
    }

    case actions.PLANNER.SELECTION_ADD: {
      const {
        payload: { selection },
      } = action

      return selectors.selection.set(R.flatten([selection]), state)
    }

    case actions.PLANNER.SELECTION_REMOVE: {
      const {
        payload: { selection },
      } = action

      return selectors.selection.remove(R.flatten([selection]), state)
    }

    case actions.PLANNER.TRANSFORM_CHANGE: {
      const { payload: transform } = action
      return selectors.transform.set(transform, state)
    }

    case actions.PLANNER.TITLE_CHANGE: {
      const { payload: title } = action
      return R.pipe(selectors.title.set(title), selectors.isDirty.set(true))(state)
    }

    case actions.NODE.PROPERTIES_CHANGE: {
      const {
        payload: { ...properties },
      } = action

      const s = selectors.selection.get(state)

      const newNodes = R.reduce(
        (acc, a) => {
          const node = selectors.node.get(a, state)
          return R.mergeDeepLeft(acc, {
            [node.id]: selectors.properties.set(properties, node),
          })
        },
        {},
        s
      )

      return R.pipe(selectors.nodes.set(newNodes), selectors.isDirty.set(true))(state)
    }

    case actions.NODE.DATA_CHANGE: {
      const {
        payload: { nodeId, data },
      } = action

      let s = []
      if (nodeId) {
        s = [nodeId]
      } else {
        s = selectors.selection.get(state)
      }

      if (s.length > 0) {
        const newNodes = R.reduce(
          (acc, a) => {
            const node = selectors.node.get(a, state)
            return R.mergeDeepLeft(acc, {
              [node.id]: selectors.data.set(data, node),
            })
          },
          {},
          s
        )

        return R.pipe(selectors.nodes.set(newNodes), selectors.isDirty.set(true))(state)
      }

      return state
    }

    case actions.PLANNER.NODES_PROPERTIES_CHANGE: {
      const { payload: properties } = action

      const newNodes = R.reduce(
        (acc, [a, b]) => {
          const node = selectors.node.get(a, state)
          return R.mergeDeepLeft(acc, {
            [node.id]: selectors.properties.set(b, node),
          })
        },
        {},
        properties
      )

      return R.pipe(selectors.nodes.set(newNodes), selectors.isDirty.set(true))(state)
    }

    // AP: Called when drag/drop single child or outcome
    case actions.NODE.TAGGED_ADD: {
      const {
        payload: { entity },
      } = action

      let nodeId = null
      const tagData = buildTagData(entity)

      if (entity.nodeId) {
        nodeId = entity.nodeId
      } else {
        const { selection } = state

        if (R.isEmpty(selection) || !tagData) {
          return state
        }

        nodeId = R.head(selection)
      }

      const newNode = addTaggedEntityToNode(tagData, state, nodeId)

      const result = R.mergeDeepRight(state, {
        nodes: {
          [newNode.id]: newNode,
        },
      })

      return result
    }

    case actions.NODE.TAGGED_REMOVE: {
      const {
        payload: { entity },
      } = action

      const { selection } = state

      if (R.isEmpty(selection)) {
        return state
      }

      const newNode = removeTaggedEntityToNode(entity, state, selection)

      const result = R.mergeDeepRight(state, {
        nodes: {
          [newNode.id]: newNode,
        },
      })

      return result
    }

    /***
     *
     *
     *
     * HERE YOU GO
     * RIGHT HERE...
     * case actions.NODE.TAGGED_ADD: {
      const {
        payload: { entity }
      } = action



      const { selection } = state
      const tagData = buildTagData(entity)

      if (R.isEmpty(selection) || !tagData) {
        return state
      }

      return R.reduce(addTaggedEntityToNode(tagData), state, selection)
    }

    case actions.NODE.TAGGED_REMOVE: {
      const {
        payload: { entity }
      } = action

      const { selection } = state

      if (R.isEmpty(selection)) {
        return state
      }

      return R.reduce(removeTaggedEntityToNode(entity), state, selection)
    }
     *
     *
     *
     *
     */

    // case actions.PLANNER.PREVIEW_CHANGE: {
    //   const {
    //     payload: { preview }
    //   } = action

    //   return { ...state, preview }
    // }

    // case actions.PLANNER.SELECTION_CHANGE: {
    //   const {
    //     payload: { selection }
    //   } = action

    //   return { ...state, selection }
    // }

    // case actions.NODE.STICKER_CHANGE:
    // case actions.NODE.SHAPE_CHANGE: {
    //   const {
    //     payload: { kind }
    //   } = action

    //   const { selection } = state

    //   const nodeIndex = findNodeIndex(state, selection[0])
    //   const node = state.nodes[nodeIndex]

    //   const newData = {
    //     data: {
    //       ...node.data,
    //       kind
    //     }
    //   }

    //   return updateNode(nodeIndex, newData, state)
    // }

    // case actions.NODE.PROPERTIES_CHANGE: {
    //   return state

    //   const { payload: properties } = action

    //   const { selection } = state

    //   const nodeIndex = findNodeIndex(state, selection[0])
    //   const node = state.nodes[nodeIndex]

    //   const newProperties = {
    //     properties: {
    //       ...node.properties,
    //       ...properties
    //     }
    //   }

    //   return updateNode(nodeIndex, newProperties, state)
    // }

    // // case actions.PLANNER.NODES_ADD: {
    // //   const {
    // //     payload: { node }
    // //   } = action

    // //   const nodes = R.view(nodesLens, state) || []
    // //   return { ...state, nodes: [...nodes, node] }
    // // }

    // // case actions.PLANNER.NODES_REMOVE: {
    // //   const {
    // //     payload: { nodeIds }
    // //   } = action

    // //   const nodes = R.reject(
    // //     ({ data: { id } }) => R.contains(id, nodeIds),
    // //     state.nodes
    // //   )

    // //   return {
    // //     ...state,
    // //     nodes
    // //   }
    // // }

    // case actions.PLANNER.BACKGROUND_CHANGE: {
    //   const {
    //     payload: { background }
    //   } = action

    //   return R.set(backgroundLens, background, state)
    // }

    // case actions.NODE.SHAPE.REF_CHANGE: {
    //   const { payload: properties } = action

    //   const { selection } = state

    //   const nodeIndex = findNodeIndex(state, selection[0])
    //   const node = state.nodes[nodeIndex]

    //   const newProperties = {
    //     data: {
    //       ...node.data,
    //       ...properties
    //     }
    //   }

    //   return updateNode(nodeIndex, newProperties, state)
    // }

    // case 'CANVAS/UPDATE': {
    //   const {
    //     payload: { meta }
    //   } = action

    //   return { ...state, meta }
    // }

    // case 'RESIZING': {
    //   const {
    //     payload: { isResizing }
    //   } = action

    //   return { ...state, isResizing }
    // }

    // case 'DRAGGING': {
    //   const {
    //     payload: { id, isDragging }
    //   } = action

    //   const selection = isDragging ? [id] : []

    //   return { ...state, selection, isDragging }
    // }

    // case 'NODE/CREATE': {
    //   const { payload } = action
    //   const id = generateUuid()
    //   const newNode = { ...payload, data: { ...payload.data, id } }
    //   return { ...state, nodes: [...state.nodes, newNode] }
    // }

    // case 'NODE/CLONE': {
    //   const { payload } = action
    //   const { direction } = payload

    //   const id = generateUuid()
    //   const [selectedNodeId] = state.selection
    //   const nodeIndex = findNodeIndex(state, selectedNodeId)
    //   const node = state.nodes[nodeIndex]

    //   const width = R.pathOr(200, ['dimensions', 'width'], node)
    //   const height = R.pathOr(200, ['dimensions', 'width'], node)
    //   const x = R.path(['layout', 'x'], node)
    //   const y = R.path(['layout', 'y'], node)

    //   let point = { x, y }

    //   switch (direction) {
    //     case 'nw': {
    //       point = R.merge(point, { x: x - width, y: y - height })
    //       break
    //     }

    //     case 'n': {
    //       point = { ...point, y: y - height }
    //       break
    //     }

    //     case 'ne': {
    //       point = { ...point, y: y - height, x: x + width }
    //       break
    //     }

    //     case 'e': {
    //       point = { ...point, x: x + width }
    //       break
    //     }

    //     case 'se': {
    //       point = { ...point, y: y + height, x: x + width }
    //       break
    //     }

    //     case 's': {
    //       point = { ...point, y: y + height }
    //       break
    //     }

    //     case 'sw': {
    //       point = { ...point, x: x - width, y: y + height }
    //       break
    //     }

    //     case 'w': {
    //       point = R.merge(point, { x: x - width })
    //       break
    //     }
    //   }

    //   const newNode = {
    //     ...node,
    //     data: { ...node.data, id },
    //     layout: point
    //   }
    //   return { ...state, nodes: [...state.nodes, newNode] }
    // }

    // case 'NODE/SHAPE': {
    //   const {
    //     payload: { type }
    //   } = action

    //   const { selection } = state

    //   const nodeIndex = findNodeIndex(state, selection[0])
    //   const node = state.nodes[nodeIndex]

    //   const newData = {
    //     data: {
    //       ...node.data,
    //       type
    //     }
    //   }

    //   return updateNode(nodeIndex, newData, state)
    // }

    // case 'NODE/CHANGE': {
    //   const {
    //     payload: { id, ...rest }
    //   } = action

    //   const nodeIndex = findNodeIndex(state, id)
    //   return updateNode(nodeIndex, rest, state)
    // }

    // case 'NODE/CHANGE/DIMENSIONS': {
    //   const {
    //     payload: { id, dimensions }
    //   } = action

    //   const dimensionsLens = R.lensPath(['dimensions'])
    //   const nodeIndex = R.findIndex(R.pathEq(['data', 'id'], id), state.nodes)

    //   if (nodeIndex > -1) {
    //     const newNode = R.set(
    //       dimensionsLens,
    //       dimensions,
    //       state.nodes[nodeIndex]
    //     )

    //     const newNodes = R.update(nodeIndex, newNode, state.nodes)
    //     return { ...state, nodes: newNodes }
    //   }

    //   return state
    // }

    // case 'NODE/CHANGE/POINT': {
    //   const {
    //     payload: { id, point }
    //   } = action

    //   const pointLens = R.lensPath(['layout'])
    //   const nodeIndex = R.findIndex(R.pathEq(['data', 'id'], id), state.nodes)

    //   if (nodeIndex > -1) {
    //     const newNode = R.set(pointLens, point, state.nodes[nodeIndex])
    //     const newNodes = R.update(nodeIndex, newNode, state.nodes)
    //     return { ...state, nodes: newNodes }
    //   }

    //   return state
    // }

    // case 'SELECTION/CLEAR': {
    //   return { ...state, selection: [] }
    // }

    // case 'SELECTION/CHANGE': {
    //   const {
    //     payload: { id }
    //   } = action

    //   const { editorData, editNode } = state

    //   if (editNode) {
    //     const nodeIndex = findNodeIndex(state, editNode)
    //     const node = state.nodes[nodeIndex]

    //     const newData = {
    //       ...node.data,
    //       content: {
    //         ...node.data.content,
    //         data: jsonSerializer.serialize(editorData)
    //       }
    //     }

    //     const newState = updateNode(nodeIndex, { data: newData }, state)

    //     return {
    //       ...newState,
    //       selection: [id],
    //       editNode: null,
    //       editContent: null
    //     }
    //   } else {
    //     return { ...state, selection: [id] }
    //   }
    // }

    // case 'EDITOR/CHANGE': {
    //   const {
    //     payload: { editorData }
    //   } = action

    //   return { ...state, editorData }
    // }

    // case 'EDITOR/SELECT': {
    //   const {
    //     payload: { id }
    //   } = action

    //   const nodeIndex = findNodeIndex(state, id)
    //   const selectedNode = state.nodes[nodeIndex]
    //   const data = R.path(['data', 'content', 'data'], selectedNode)

    //   const editorData = R.ifElse(
    //     isNotNilOrEmpty,
    //     R.pipe(
    //       jsonSerializer.deserialize,
    //       Value.fromJSON
    //     ),
    //     R.always(initialValue)
    //   )(data)

    //   return { ...state, editorData, editNode: id, selection: [id] }
    // }

    default: {
      return state
      // throw Error(`Unknown Action: ${action.type}`)
    }
  }
}

const Edit = ({ children, id, nodeIds = [], nodes = [], selection = [], updatedAt, properties, title }) => {
  const initialState = useMemo(() => {
    const { xdiff, ydiff, xmid, ymid } = plannerPosition(nodes)
    return {
      id,
      isCloning: false,
      isDirty: false,
      isDragging: false,
      isResizing: false,
      nodeIds,
      nodes,
      preview: true,
      properties,
      selection,
      title,
      plannerPositions: { xmid, ymid, xdiff, ydiff },
      transform: { x: 0, y: 0, k: 1 },
      updatedAt,
      edit: {
        id: null,
        value: initialValue,
      },
    }
  }, [id, nodeIds, nodes, properties, selection, title, updatedAt])

  const value = useHistoryReducer(reducer, initialState)

  return <EditContext.Provider value={value}>{children}</EditContext.Provider>
}

Edit.displayName = 'Edit'
export default Edit
