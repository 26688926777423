import React from 'react'

const ChevronSort = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 6.81635C4 6.99064 4.12839 7.05187 4.28452 6.95209L7.71441 4.77897C7.88905 4.67917 8.11096 4.67917 8.28559 4.77897L11.7155 6.95209C11.8727 7.05187 12 6.99064 12 6.81635L12 5.74728C11.9883 5.55174 11.8846 5.37029 11.7155 5.24936L8.28666 3.07461C8.11193 2.97513 7.89021 2.97513 7.71548 3.07461L4.28559 5.24806C4.11649 5.369 4.01281 5.55044 4.00107 5.74599L4 6.81635Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 9.18365C4 9.00936 4.12839 8.94813 4.28452 9.04791L7.71441 11.221C7.88905 11.3208 8.11096 11.3208 8.28559 11.221L11.7155 9.04791C11.8727 8.94813 12 9.00936 12 9.18365L12 10.2527C11.9883 10.4483 11.8846 10.6297 11.7155 10.7506L8.28666 12.9254C8.11193 13.0249 7.89021 13.0249 7.71548 12.9254L4.28559 10.7519C4.11649 10.631 4.01281 10.4496 4.00107 10.254L4 9.18365Z"
      fill="currentColor"
    />
  </svg>
)

export default ChevronSort
