import React, { ReactNode, createContext } from 'react'

import { PostState } from '../types'

import { PostContextProps, initialContextState, usePostEditor } from './PostState'

export const PostContext = createContext<PostContextProps>(initialContextState)

interface Props {
  children: ReactNode
  state: PostState
}

export const PostProvider = ({ children, state: initialState }: Props) => {
  return <PostContext.Provider value={usePostEditor(initialState)}>{children}</PostContext.Provider>
}
