import React from 'react'

const ArrowUp = () => (
  <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5.72452C12 5.98596 11.8074 6.0778 11.5732 5.92813L6.42838 2.66845C6.16643 2.51876 5.83356 2.51876 5.57162 2.66845L0.426776 5.92813C0.190979 6.0778 -5.03003e-06 5.98596 -5.0186e-06 5.72452L-4.9485e-06 4.12092C0.0176104 3.82761 0.173132 3.55544 0.426776 3.37403L5.57002 0.111919C5.83211 -0.0373067 6.16468 -0.0373066 6.42678 0.111919L11.5716 3.37209C11.8253 3.5535 11.9808 3.82567 11.9984 4.11898L12 5.72452Z"
      fill="black"
    />
  </svg>
)

export default ArrowUp
