import React from 'react'

import styled from 'styled-components'
import { BorderRadiusProps, HeightProps, WidthProps, borderRadius, height, width } from 'styled-system'

type BaseProps = BorderRadiusProps & HeightProps & WidthProps

export interface BackgroundImageProps extends BaseProps {
  image?: string
}

const image = (props: BackgroundImageProps) =>
  props.image ? { backgroundImage: `url("${props.image}")` } : null

const BackgroundImage = styled.div<BackgroundImageProps>`
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${(props) => props.theme.backgroundColorSurface};
  ${image};
  ${height};
  ${width};
  ${borderRadius};
  overflow: hidden;
`

BackgroundImage.displayName = 'BackgroundImage'

export default React.memo(BackgroundImage)
