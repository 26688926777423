import React, { useReducer } from 'react'

import { ErrorMessage } from 'stardust'
import styled from 'styled-components'

import BlockComment from './BlockComment'
import InlineComment from './InlineComment'

const Form = styled.form`
  margin: 0;
`

const initialState = {
  commentValue: '',
  data: null,
  error: false,
  errorMessage: null,
  invalid: false,
  disabled: true,
}

const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case 'loading':
      return {
        ...state,
        error: false,
        errorMessage: null,
        disabled: true,
      }
    case 'error':
      return {
        ...state,
        error: true,
        errorMessage: payload,
        disabled: true,
      }
    case 'change': {
      return {
        ...state,
        error: false,
        errorMessage: null,
        disabled: false,
        commentValue: payload,
      }
    }
    case 'success':
      return {
        ...state,
        error: false,
        errorMessage: null,
        disabled: true,
        commentValue: '',
      }
    default: {
      return state
    }
  }
}

const CommentInput = ({ addComment, placeholder, buttonText, inline = false }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const handleOnSubmit = async (event) => {
    event.preventDefault()
    event.target.comment.focus()

    if (state.commentValue.length == 0) {
      dispatch({ type: 'error', payload: 'Comment is required' })
      return
    }

    dispatch({ type: 'loading' })
    try {
      const data = await addComment(state.commentValue)
      dispatch({ type: 'success', payload: data })
    } catch (error) {
      console.error(error)
      dispatch({ type: 'error', payload: 'Something went wrong' })
    }
  }

  return (
    <Form onSubmit={handleOnSubmit}>
      {state.error && <ErrorMessage>{state.errorMessage}</ErrorMessage>}

      {inline ? (
        <InlineComment
          onChange={(value) => dispatch({ type: 'change', payload: value })}
          buttonText={buttonText}
          commentValue={state.commentValue}
          placeholder={placeholder}
          disabled={state.disabled}
          error={state.error}
        />
      ) : (
        <BlockComment
          onChange={(value) => dispatch({ type: 'change', payload: value })}
          buttonText={buttonText}
          commentValue={state.commentValue}
          placeholder={placeholder}
          disabled={state.disabled}
          error={state.error}
        />
      )}
    </Form>
  )
}

CommentInput.displayName = 'CommentInput'
export default CommentInput
