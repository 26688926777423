import React from 'react'

import { StencilCircle as Circle, Container, Flex, StencilLine as Line } from 'stardust'
import styled from 'styled-components'

const opacity = (props: { opacity?: number }) => (props.opacity ? { opacity: props.opacity } : null)

const OpaqueFlex = styled(Flex)`
  ${opacity};
`

const Profile = () => {
  return (
    <Container>
      <OpaqueFlex justifyContent="flex-start" opacity={1 / 2} mt={4} px={3}>
        <Flex width={1} flex="0">
          <Circle width="120px" height="120px" />
        </Flex>
        <Flex flexDirection="column" justifyContent="center" py={4} width={1} ml={3}>
          <Line py={2} width={1 / 3} />
          <Line mt={2} py={1} width={1 / 5} />
          <Line mt={2} py={1} width={1 / 4} />
        </Flex>
      </OpaqueFlex>
    </Container>
  )
}

export default Profile
