import * as R from 'ramda'
import { Block, Change, Value } from 'slate'

const ITEM_BLOCK = 'list-item'
const DEFAULT_BLOCK = 'normal'

const listTypes = {
  BULLETED_LIST: 'bulleted-list',
  NUMBERED_LIST: 'numbered-list',
}

const hasNonListItems = R.pipe(R.propOr([], 'blocks'), (blocks: Block[]) =>
  blocks.some((block) => block.type != ITEM_BLOCK)
)

const hasParentOfSameType = (value: Value, type: string) => {
  const document = R.prop('document', value)

  if (!document) {
    return false
  }

  return R.pipe(R.propOr([], 'blocks'), (blocks: Block[]) =>
    blocks.some(
      (block) =>
        !!document.getClosest(block.key, (parent) => {
          // cast to Block because a.) slate typescript doesn't share the 'type' property in all Node union types
          // and b.) we know it is a Block
          const blockParent = parent as Block
          return blockParent.type == type
        })
    )
  )(value)
}

const insert = (change: Change, type: string) => change.setBlocks(ITEM_BLOCK).wrapBlock(type)

const remove = (change: Change, type: string) => change.setBlocks(DEFAULT_BLOCK).unwrapBlock(type)

const replace = (change: Change, type: string) =>
  change
    .unwrapBlock(listTypes.NUMBERED_LIST)
    .unwrapBlock(listTypes.BULLETED_LIST)
    .setBlocks(ITEM_BLOCK)
    .wrapBlock(type)

const hasActiveBlocks = (value: Value, type: string) =>
  hasParentOfSameType(value, type) && !hasNonListItems(value)

const handleChange = (change: Change, type: string) => {
  const { value } = change
  const parentLists = value.blocks.map((block) =>
    value.document.getClosest(block!.key, (parent) => {
      // cast to Block because a.) slate typescript doesn't share the 'type' property in all Node union types
      // and b.) we know it is a Block
      const blockParent = parent as Block
      return R.values(listTypes).includes(blockParent.type)
    })
  )

  const hasParentOfSameType = parentLists.some((parent) => {
    if (!parent) {
      return false
    }
    // cast to Block because a.) slate typescript doesn't share the 'type' property in all Node union types
    // and b.) we know it is a Block
    const blockParent = parent as Block
    return blockParent.type == type
  })

  if (!parentLists) {
    return insert(change, type)
  }

  if (!hasNonListItems(value) && hasParentOfSameType) {
    return remove(change, type)
  }

  return replace(change, type)
}

export { handleChange, hasActiveBlocks, listTypes }
