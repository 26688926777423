import React from 'react'

import { Box } from 'stardust'

const HeaderSection = ({ children }) => (
  <Box bg="surfacePrimary" pt={3}>
    {children}
  </Box>
)

HeaderSection.displayName = 'HeaderSection'

export default React.memo(HeaderSection)
