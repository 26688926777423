import React, { PureComponent } from 'react'

import { getLocalService } from '~/contexts/Service'
import { sendAppSignalError } from '~/logging'

import UnexpectedErrorPage from '~/pages/Errors/Unexpected'

interface State {
  hasError: boolean
}

class ErrorBoundary extends PureComponent<any, State> {
  static displayName = 'AppError'

  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, info: any) {
    const serviceFkey = getLocalService() || ''
    // eslint-disable-next-line no-console
    console.error(serviceFkey, error, info)
    sendAppSignalError(error)
  }

  render() {
    if (this.state.hasError) {
      return <UnexpectedErrorPage />
    }

    return this.props.children
  }
}

export default ErrorBoundary
