import React from 'react'

import { Container, Flex, StencilLine as Line } from 'stardust'
import styled from 'styled-components'

const opacity = (props: { opacity?: number }) => (props.opacity ? { opacity: props.opacity } : null)

const OpaqueFlex = styled(Flex)`
  ${opacity};
`

const Loading = () => {
  return (
    <Container>
      <OpaqueFlex justifyContent="flex-start" opacity={1 / 2}>
        <Flex flexDirection="column" justifyContent="center" py={4} width={1} ml={3}>
          <Line py={1} width={1 / 3} />
          <Line my={3} py={1} width={1} />
          <Line my={3} py={1} width={3 / 4} />
          <Line my={3} py={1} width={3 / 4} />
          <Line my={3} py={1} width={1 / 2} />
        </Flex>
      </OpaqueFlex>
    </Container>
  )
}

export default Loading
