import React, { ReactNode } from 'react'

import { Ripple } from '@rmwc/ripple'
import styled from 'styled-components'
import {
  BackgroundProps,
  BorderRadiusProps,
  BoxShadowProps,
  ColorProps,
  HeightProps,
  SpaceProps,
  WidthProps,
  background,
  borderRadius,
  boxShadow,
  color,
  height,
  space,
  width,
} from 'styled-system'

type BaseProps = BackgroundProps &
  BorderRadiusProps &
  BoxShadowProps &
  ColorProps &
  HeightProps &
  SpaceProps &
  WidthProps

interface FabProps extends BaseProps {
  children: ReactNode
  disabled?: boolean
  onClick?(): void
}

const Button = styled.button<BaseProps>`
  align-items: center;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: inline-flex;
  fill: currentColor;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transition: opacity 15ms linear 30ms, -webkit-box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 0.27s cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1), opacity 15ms linear 30ms,
    transform 0.27s cubic-bezier(0, 0, 0.2, 1) 0ms;
  user-select: none;

  ${background};
  ${borderRadius};
  ${boxShadow};
  ${color};
  ${height};
  ${space};
  ${width};
`

const Fab = ({
  bg = 'white',
  boxShadow = '2dp',
  children,
  color = 'textPrimary',
  height = 42,
  width = 42,
  ...props
}: FabProps) => (
  <Ripple>
    <Button bg={bg} boxShadow={boxShadow} color={color as any} height={height} width={width} {...props}>
      {children}
    </Button>
  </Ripple>
)

export default React.memo(Fab)
