import React from 'react'

import { Box, MainButton, SecondaryButton } from 'stardust'

import ErrorPage from '~/components/ErrorPage'
import { isDev, isHome } from '~/config'

import { logout, redirectToLogin } from '~/modules/auth'

const UnauthorizedPage = () => {
  const appName = isHome ? 'Home' : 'Playground'

  const onLogout = () => {
    logout().then(redirectToLogin)
  }

  const onToggleApp = () => {
    const targetApp = isHome ? 'playground' : 'home'
    window.location.assign(`${window.location.origin}?app=${targetApp}`)
  }

  return (
    <ErrorPage
      heading={`No ${appName} Access`}
      message={`Your account does not have access to ${appName}. Try logging in with a
          different account or contact Xplor support to arrange access.`}>
      <Box width="200px">
        <MainButton onClick={onLogout}>Logout</MainButton>
        {isDev && (
          <SecondaryButton mt={2} onClick={onToggleApp}>
            Switch to {isHome ? 'Playground' : 'Home'}
          </SecondaryButton>
        )}
      </Box>
    </ErrorPage>
  )
}

UnauthorizedPage.displayName = 'UnauthorizedPage'

export default React.memo(UnauthorizedPage)
