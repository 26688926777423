import React from 'react'

import { Avatar, Box, Flex, Text, Truncate } from 'stardust'

import PrivacyAwareChildrenList from '~/components/PrivacyAwareChildrenList'
import * as profiles from '~/components/Profiles/core'

interface Props {
  taggedChildren: Playground.SimpleChild[]
}

const TaggedChildren = ({ taggedChildren }: Props) => {
  const renderChild = (child: Playground.SimpleChild) => (
    <Flex key={child.fkey} alignItems="center" mt={2} width={1}>
      <Box pr={2}>
        <Avatar alt={child.fullName} src={child.image} xxsmall text={profiles.initials(child.fullName)} />
      </Box>
      <Box width={1}>
        <Truncate caps fontSize={1} lineHeight={1.5}>
          {child.fullName}
        </Truncate>
      </Box>
    </Flex>
  )

  return (
    <Box data-test="document-children">
      <Text bold mb={3} fontSize={2}>
        Tagged Children
      </Text>
      <PrivacyAwareChildrenList childrenList={taggedChildren} renderChild={renderChild} />
    </Box>
  )
}

TaggedChildren.displayName = 'DocumentRendererTaggedChildren'

export default React.memo(TaggedChildren)
