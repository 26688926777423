import React, { useEffect, useRef, useState } from 'react'

import styled from 'styled-components'
import WaveSurfer, { WaveSurferOptions } from 'wavesurfer.js'

import AudioPauseButton from '~/ui-components/icons/AudioPauseButton'
import AudioPlayButton from '~/ui-components/icons/AudioPlayButton'

interface WaveformPlayerProps {
  audioFile: string
}

const WaveformPlayerContianer = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 100%;
`

const PlayButtonContainer = styled.div`
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 12px;
  width: 32px;
  height: 32px;
`

const Waveform = styled.div`
  width: 100%;
  height: 16px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 12px;
`

const MediaTimeText = styled.p`
  margin-top: auto;
  margin-bottom: auto;
  font-size: 14px;
  color: #686868;
`

const WSOptions: Partial<WaveSurferOptions> = {
  barRadius: 2,
  barWidth: 2,
  barGap: 6,
  cursorWidth: 1,
  normalize: true,
  backend: 'WebAudio',
  height: 16,
  progressColor: '#01A39D',
  waveColor: '#646464',
  cursorColor: 'transparent',
  fetchParams: { mode: 'cors' },
}

/**
 * Accepts a number for seconds and converts it into a string
 * with a minutes:seconds format.
 *
 * @param secondsToFormat The time we wish to format in seconds
 * @returns A string in the format of "00:00"
 */
const timeFormat = (secondsToFormat: number) => {
  let minutes: number = Math.floor(secondsToFormat / 60)
  let seconds: string = (secondsToFormat % 60).toFixed(0).padStart(2, '0')
  // Catching event that seconds will be shown 60
  if (seconds == '60') {
    minutes++
    seconds = '00'
  }

  return `0${minutes}:${seconds}`
}

/**
 * Triggers an event for play/pause on a WaveSurfer player and calls
 * the setPlaying callback to invert the play state; `playing`.
 *
 * @param waveformPlayer The current instantiated WaveSurfer instance
 * @param playing A boolean which should reflect the state of play/pause
 * @param setPlaying A callback function to set the state of play/pause
 */
const onPlay = (
  waveformPlayer: WaveSurfer | undefined,
  playing: boolean,
  setPlaying: (playing: boolean) => void
) => {
  if (waveformPlayer) {
    setPlaying(!playing)
    waveformPlayer.playPause()
  }
}

export const WaveformPlayer = ({ audioFile }: WaveformPlayerProps) => {
  const [playing, setPlaying] = useState(false)
  const waveformContainerRef = useRef(null)
  const waveformTimeTextRef = useRef<HTMLParagraphElement>(null)
  const waveformPlayerRef = useRef<WaveSurfer>()

  useEffect(() => {
    if (waveformContainerRef.current) {
      // Instantiating our WaveSurfer player and storing it in
      // our reference.
      waveformPlayerRef.current = WaveSurfer.create({
        container: waveformContainerRef.current,
        url: audioFile,
        ...WSOptions,
      })

      // Adding functionality for time tracking that is called on
      // every delta for the WaveSurfer player when a track is playing.
      waveformPlayerRef.current.on('audioprocess', () => {
        if (waveformPlayerRef?.current?.isPlaying()) {
          if (waveformTimeTextRef.current) {
            const currentTime = waveformPlayerRef?.current?.getCurrentTime()
            const formattedTime = timeFormat(currentTime)
            waveformTimeTextRef.current.innerText = formattedTime
          }
        }
      })

      // Setting the playing state on the 'finish' callback.
      waveformPlayerRef.current.on('finish', () => {
        setPlaying(false)
      })
    }

    // Waveform cleanup on unmount.
    return () => {
      waveformPlayerRef.current?.destroy()
    }
  }, [audioFile])

  return (
    <WaveformPlayerContianer>
      <PlayButtonContainer onClick={() => onPlay(waveformPlayerRef.current, playing, setPlaying)}>
        {playing ? <AudioPauseButton /> : <AudioPlayButton />}
      </PlayButtonContainer>
      <Waveform ref={waveformContainerRef} />
      <MediaTimeText ref={waveformTimeTextRef}>
        {timeFormat(waveformPlayerRef.current?.getDuration() || 0)}
      </MediaTimeText>
    </WaveformPlayerContianer>
  )
}
