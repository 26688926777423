import React from 'react'

import { useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'

import LoadingProfile from '~/components/Loading/Profile'

import ProfileSummary from '~/components/Profiles/Detail/Summary'
import RoomMetrics from '~/components/Profiles/Metrics/RoomMetrics'
import { startToEndMonth } from '~/utils/dateRange'

import { GET_ROOM_SUMMARY } from '../queries'

interface Props extends RouteComponentProps {
  roomFkey: string
  serviceFkey: string
}

const Summary = ({ roomFkey, serviceFkey }: Props) => {
  const { data, error, loading } = useQuery(GET_ROOM_SUMMARY, {
    variables: {
      serviceFkey,
      roomFkey,
      dateRange: startToEndMonth(new Date()),
    },
  })

  if (error) {
    return null
  }

  if (loading) {
    return <LoadingProfile />
  }

  const room = data.service.room as Playground.Room

  return (
    <ProfileSummary fullName={room.name} description={room.type}>
      <RoomMetrics room={room} />
    </ProfileSummary>
  )
}

Summary.displayName = 'Summary'

export default Summary
