const palette = {
  plasmaCyan0: '#E2FCFA',
  plasmaCyan1: '#B7F8F2',
  plasmaCyan2: '#86F4EC',
  plasmaCyan3: '#54EEE7',
  plasmaCyan4: '#32E7E3',
  plasmaCyan5: '#15E2E2',
  plasmaCyan6: '#12D0CF',
  plasmaCyan7: '#16B9B5',
  plasmaCyan8: '#18A49D',
  plasmaCyan9: '#1B7F72',
}

export default {
  ...palette,
  plasmaCyan: palette.plasmaCyan2,
}
