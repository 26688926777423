import React from 'react'

import { Box, Text } from 'stardust'

import styled from 'styled-components'

import { isHome, isWebView } from '~/config'
import colors from '~/ui-components/tokens/colors'

interface Props {
  fullName: string | undefined
  userType: string | undefined
}

const S = {
  Text: styled(Text)`
    color: ${isWebView && isHome ? colors.cosmicShade0 : colors.cosmicShade15};
  `,
}

const UserDetails = ({ fullName, userType }: Props) => {
  return (
    <Box>
      <S.Text mb={2} fontSize={'16px'} fontWeight={'bolder'}>
        {fullName}
      </S.Text>
      <S.Text mb={1} caps fontSize={1}>
        {userType == 'Educator' ? userType : userType?.replace('_admin', ' Admin')}
      </S.Text>
    </Box>
  )
}

UserDetails.displayName = 'UserDetails'

export default React.memo(UserDetails)
