import React, { useContext } from 'react'

import { Box, Icon, Flex, Text, SecondaryButton } from 'stardust'

import colors from '~/ui-components/tokens/colors'

import { PostContext } from '../PostProvider'

import DisplayTaggedChildrenInMoreDetails from './Children/DisplayTaggedChildrenInMoreDetails'
import DisplaySelectedOutcomes from './LearningOutcomes/DisplaySelectedOutcomes'
import DisplayLinksDocsInMoreDetails from './Links/DisplayLinksDocsInMoreDetails'

import PostToolTipMessage from './PostToolTipMessage'

interface TagComponentInSidebar {
  title: string
  helperTextTitle?: string
  addTagButton: string
  defaultMessage?: string
  availableRooms?: Playground.Room[]
  rooms?: Playground.ObservationRoom[]
  documentIds?: string[]
  taggedDocuments?: Playground.Document[]
  taggedChildren?: Playground.SimpleChild[]
  learningOutcomes?: Playground.LearningFrameworkOutcome[]
  isSaving?: boolean
  onOpenRoomModal?: () => void
  onOpenChildrenModal?: () => void
  onOpenLearningOutcomesModal?: () => void
  onOpenLinksModal?: () => void
}

const TagComponentInSidebar = ({
  isSaving,
  title,
  helperTextTitle,
  addTagButton,
  defaultMessage,
  availableRooms,
  rooms,
  taggedChildren,
  documentIds,
  taggedDocuments,
  learningOutcomes,
  onOpenRoomModal,
  onOpenChildrenModal,
  onOpenLearningOutcomesModal,
  onOpenLinksModal,
}: TagComponentInSidebar) => {
  const { handlers } = useContext(PostContext)

  const handleClick = (modalType: string) => {
    if (modalType === 'Room' && onOpenRoomModal) {
      onOpenRoomModal()
    }
    if (modalType === 'Children' && onOpenChildrenModal) {
      onOpenChildrenModal()
    }
    if (modalType === 'Learning Outcomes' && onOpenLearningOutcomesModal) {
      onOpenLearningOutcomesModal()
    }
    if (modalType === 'Links' && onOpenLinksModal) {
      onOpenLinksModal()
    }
  }

  const checkRoomValidOnSave = () => {
    if (isSaving && rooms) {
      return rooms?.length > 0 ? true : false
    } else {
      return true
    }
  }

  const validateRoom = !checkRoomValidOnSave() && title === 'Room'

  const roomName =
    rooms &&
    rooms
      .map((room) => {
        const matchingRoom =
          availableRooms && availableRooms.find((availRoom) => availRoom.fkey === room.roomFkey)
        return matchingRoom ? matchingRoom.name : defaultMessage
      })
      .join(', ')

  const DisplaySelected = () => {
    const checkIfChildIsTagged = taggedChildren && taggedChildren?.length > 0
    const checkIfOutcomesSelected = learningOutcomes && learningOutcomes?.length > 0
    const checkIfLinksDocsSelected = taggedDocuments && taggedDocuments?.length > 0

    return (
      <Flex flexDirection="column">
        <Box borderBottom="1px solid" borderColor={colors.cosmicShade6}></Box>
        {title === 'Room' && (
          <Flex height={'56px'} alignItems="center">
            <Text fontWeight={400} color="secondary" fontSize="16px" ml={3}>
              {rooms && rooms[0]?.roomFkey ? roomName : defaultMessage}
            </Text>
          </Flex>
        )}
        {title === 'Children' && (
          <Flex height={checkIfChildIsTagged ? '100%' : '56px'} alignItems="center">
            {checkIfChildIsTagged ? (
              <DisplayTaggedChildrenInMoreDetails
                taggedChildren={taggedChildren || []}
                onChange={handlers.onChange}
                isReadOnly={false}
              />
            ) : (
              <Flex height={'56px'} alignItems="center">
                <Text fontWeight={400} color="secondary" fontSize="16px" ml={3}>
                  {defaultMessage}
                </Text>
              </Flex>
            )}
          </Flex>
        )}
        {title === 'Links' && (
          <Flex height={checkIfLinksDocsSelected ? '100%' : '56px'} alignItems="center">
            {checkIfLinksDocsSelected ? (
              <DisplayLinksDocsInMoreDetails
                taggedDocuments={taggedDocuments || []}
                documentIds={documentIds}
                onChange={handlers.onChange}
                isReadOnly={false}
              />
            ) : (
              <Flex height={56} alignItems="center">
                <Text fontWeight={400} color="textPrimaryLow" fontSize="14px" ml={3}>
                  {defaultMessage}
                </Text>
              </Flex>
            )}
          </Flex>
        )}
        {title === 'Learning Outcomes' && (
          <Flex height={checkIfOutcomesSelected ? '100%' : '41px'} alignItems="center">
            {checkIfOutcomesSelected ? (
              <DisplaySelectedOutcomes outcomes={learningOutcomes} />
            ) : (
              <Text
                fontWeight={400}
                color="textPrimaryLow"
                fontSize="14px"
                ml={checkIfOutcomesSelected ? 0 : 3}>
                {defaultMessage}
              </Text>
            )}
          </Flex>
        )}
      </Flex>
    )
  }

  return (
    <>
      <Box
        flexDirection="column"
        border="1px solid"
        borderRadius={1}
        bg="white"
        borderColor={validateRoom ? colors.superGiantRed5 : colors.cosmicShade6}
        m={2}>
        <Flex justifyContent="space-between" alignItems="center" height="48px" mx={2}>
          <Flex alignItems="center">
            <Text fontWeight={700} fontSize="14px" caps ml={2}>
              {title}
            </Text>
            <Text fontWeight={400} fontSize="14px" ml={2}>
              {helperTextTitle}
            </Text>
            {title === 'Links' && (
              <PostToolTipMessage text="Include related documentation created within Playground linked to this post." />
            )}
            {title === 'Learning Outcomes' && (
              <PostToolTipMessage text="It is recommended to choose 5-8 Learning Outcome tags that align with your learning goals." />
            )}
          </Flex>
          <SecondaryButton width="120px" onClick={() => handleClick(title)}>
            {addTagButton}
          </SecondaryButton>
        </Flex>
        <DisplaySelected />
      </Box>
      {validateRoom && (
        <Flex alignItems="center" ml={4}>
          <Icon name="warning" color={colors.superGiantRed5} mr={1} />
          <Text fontSize="14px" color={colors.superGiantRed5}>
            Room is required
          </Text>
        </Flex>
      )}
    </>
  )
}

TagComponentInSidebar.displayName = 'TagComponentInSidebar'
export default React.memo(TagComponentInSidebar)
