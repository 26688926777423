import React, { ReactNode, useMemo } from 'react'

import { useQuery } from '@apollo/client'
import { DocumentNode } from 'graphql'
import RPath from 'ramda/src/path'

import { Link, Text } from 'stardust'

import Error404 from '~/components/404'
import DocumentPreviewCard from '~/components/DocumentPreviewCard'
import Loader from '~/components/Loading/Form'
import { isHome } from '~/config'
import { is404 } from '~/modules/api'

interface ChildProps {
  article: Playground.Article | Playground.ArticleTemplate
  articleContent: Playground.ArticleContent | Playground.ArticleTemplate
}

interface Props {
  children({ article, articleContent }: ChildProps): ReactNode
  embedded?: boolean
  isLayout?: boolean
  placeholder?(): any
  query: DocumentNode
  type: 'plan' | 'story' | 'table'
  variables: {
    id: number
    serviceFkey: string
    contentStatus: Playground.DocumentStatus
  }
}

const ArticleLoader = ({ children, embedded, placeholder, query, type, variables }: Props) => {
  const { data, error, loading } = useQuery(query, {
    variables,
    fetchPolicy: embedded ? 'cache-first' : 'network-only',
  })

  const [title, resource, url, graphKey] = useMemo(() => {
    return {
      plan: ['Plan', 'plans', '/planning', 'learningPlan'],
      story: ['Document', 'documents', '/stories', 'learningStory'],
      table: ['Table', 'tables', '/tables', 'learningTable'],
    }[type]
  }, [type])

  if (error) {
    if (is404(error)) {
      if (embedded && isHome) {
        return (
          <DocumentPreviewCard
            title={title}
            description={`You do not have permission to view this content. Your child is not tagged in the ${title.toLowerCase()}.`}
          />
        )
      }

      return (
        <Error404
          message={`${title} not found`}
          redirect={() => (
            <Link to={url}>
              <Text color="textLink">Back to {resource}</Text>
            </Link>
          )}
        />
      )
    }

    throw error
  }

  if (loading) {
    return placeholder ? placeholder() : <Loader />
  }

  const articlePath = ['context', graphKey]
  const article = RPath<Playground.Article>(articlePath, data)
  const articleContent = RPath<Playground.ArticleContent>(['content', 0], article)

  if (!article || !articleContent) {
    return (
      <Error404
        message={`Malformed ${title} data`}
        redirect={() => (
          <Link to={url}>
            <Text color="textLink">Back to {resource}</Text>
          </Link>
        )}
      />
    )
  }

  return <>{children({ article, articleContent })}</>
}

ArticleLoader.displayName = 'ArticleLoader'

export default React.memo(ArticleLoader)
