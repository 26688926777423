import React from 'react'

import { Box, Checkbox, Dropdown, Flex, Icon, Text, Truncate } from 'stardust'

interface DropdownSelectorOption {
  label: string
  value: number
}

interface Props {
  disabled?: boolean
  label: string
  options: DropdownSelectorOption[]
  portal?: boolean
  hasSelectAll: boolean
  selectAllStatus: boolean
  values: number[]
  onChange(values: number[]): void
}

const DropdownSelector = ({
  disabled,
  label,
  options,
  portal,
  hasSelectAll,
  selectAllStatus,
  values,
  onChange,
}: Props) => {
  const color = disabled ? 'textPrimaryLow' : 'textPrimaryMedium'
  const safeItems = disabled ? [] : options

  const handleChange = (value: number) => {
    if (values.includes(value)) {
      const updatedValues = values.filter((existing: number) => existing !== value)
      onChange(updatedValues)
    } else {
      const updatedValues = [...values, value]
      onChange(updatedValues)
    }
  }

  const handleSelectAll = (selectAllSelected: boolean) => {
    onChange(selectAllSelected ? options.map((option) => option.value) : [])
  }

  return (
    <Box cursor="pointer" width={1} data-test="dropdown-menu">
      <Dropdown
        portal={portal}
        renderButton={({ handleClose }) => (
          <Flex alignItems="center" width={1} onClick={handleClose}>
            <Flex flexGrow={1} width={0}>
              {typeof label === 'string' ? (
                <Truncate color={color} style={{ lineHeight: 1.2, maxWidth: '100%' }}>
                  <Text.span color={color}>{label}</Text.span>
                </Truncate>
              ) : (
                label
              )}
            </Flex>
            <Icon color={color} ml={1} name="chevronDown" />
          </Flex>
        )}
        render={() => (
          <React.Fragment>
            {hasSelectAll && (
              <Flex
                key={'Select All'}
                borderBottom="1px solid"
                borderColor="surfacePrimaryBorder"
                p={3}
                textAlign="center">
                <Checkbox checked={selectAllStatus} label={'Select All'} onChange={handleSelectAll} />
              </Flex>
            )}
            {safeItems.map((option) => (
              <Flex
                key={option.label}
                borderBottom="1px solid"
                borderColor="surfacePrimaryBorder"
                p={3}
                textAlign="center">
                <Checkbox
                  checked={values.includes(option.value)}
                  label={option.label}
                  onChange={() => handleChange(option.value)}
                />
              </Flex>
            ))}
          </React.Fragment>
        )}
      />
    </Box>
  )
}

DropdownSelector.displayName = 'DropdownSelector'

export default React.memo(DropdownSelector)
