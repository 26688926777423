import gql from 'graphql-tag'

export const GET_COHORT_TRACKING_DEPENDENCIES = gql`
  query GetCohortTrackingDependencies($serviceFkey: String!) {
    service(fkey: $serviceFkey) {
      fkey
      children {
        fkey
        dob
        fullName
        image
        room {
          fkey
        }
      }
      childGroups {
        id
        fullName
        shortName
        children {
          fkey
        }
      }
      learningFrameworks {
        id
        fullName
        shortName
        enableCohorts
      }
      personnel {
        fkey
        fullName
        image
        userReference
      }
      rooms {
        fkey
        name
      }
    }
  }
`

export const GET_LEARNING_FRAMEWORK = gql`
  query GetLearningFramework($serviceFkey: String!, $frameworkId: Int!) {
    service(fkey: $serviceFkey) {
      fkey
      learningFramework(id: $frameworkId) {
        id
        fullName
        shortName
        enableCohorts
        ageGroups {
          id
          name
          minMonths
        }
        categories {
          id
          name
          shortName
        }
        outcomes {
          id
          name
          primaryId
          secondaryId
          tertiaryId
        }
        ranks {
          id
          name
          value
        }
      }
    }
  }
`

export const GET_LEARNING_RECORDS = gql`
  query GetLearningRecords(
    $serviceFkey: String!
    $frameworkId: Int!
    $ageGroupId: Int
    $dateRange: DateRange
  ) {
    service(fkey: $serviceFkey) {
      fkey
      learningRecords(ageGroupId: $ageGroupId, dateRange: $dateRange, frameworkId: $frameworkId) {
        id
        entityId
        entityTitle
        entityType
        childFkey
        roomFkey
        ageGroupId
        outcomeId
        rankId
        contributorRef
        contributorType
        contributorFkey
        achievedAt
      }
    }
  }
`
