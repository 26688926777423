import React, { useEffect, useMemo, useState } from 'react'

import Chart from 'react-apexcharts'
import secondaryColors from 'stardust/tokens/colors/hullOrange'
import primaryColors from 'stardust/tokens/colors/nebulaBlue'
import styled from 'styled-components'

import { unique } from '~/utils'

export interface ColumnChartDatum {
  category: number | string
  values: {
    name: string
    value: number
  }[]
}

interface Props {
  data: ColumnChartDatum[]
  height?: string | number
  stack?: boolean
  theme?: 'primary' | 'secondary'
  width?: string | number
  onFormatX(category: number | string): string
  onTooltipFormatX?(category: number | string): string
}

const primaryTheme = [
  primaryColors.nebulaBlue1,
  primaryColors.nebulaBlue2,
  primaryColors.nebulaBlue5,
  primaryColors.nebulaBlue7,
]
const secondaryTheme = [
  secondaryColors.hullOrange1,
  secondaryColors.hullOrange3,
  secondaryColors.hullOrange6,
  secondaryColors.hullOrange9,
]

const StyledChart = styled(Chart)`
  .apexcharts-tooltip {
    white-space: pre-wrap !important;
    max-width: 250px;
  }
`

const ColumnChart = ({ data, height, stack, theme, width = '100%', onFormatX, onTooltipFormatX }: Props) => {
  const [stickyMax, setStickyMax] = useState(0)

  // Keepts track of the biggest value see, so that if we use filters the scale is stable
  useEffect(() => {
    const newMax = Math.max(...data.flatMap((x) => x.values).map((x) => x.value))
    if (newMax > stickyMax) setStickyMax(newMax)
  }, [data, stickyMax])

  const options = useMemo(() => {
    const colorTheme = theme === 'secondary' ? secondaryTheme : primaryTheme

    return {
      chart: {
        stacked: stack,
        stackType: '100%',
        toolbar: {
          show: false,
        },
      },
      colors: colorTheme,
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: false,
      },
      noData: {
        text: 'No data available for this range',
        align: 'center',
        verticalAlign: 'middle',
        style: {
          fontSize: '14px',
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '20%',
        },
      },
      tooltip: {
        x: {
          formatter: onTooltipFormatX,
        },
        y: {
          formatter: (value: number) => (stack ? `${(value * 100).toFixed(2)}%` : Math.round(value)),
        },
      },
      xaxis: {
        categories: data.map((datum) => datum.category),
        labels: {
          formatter: onFormatX,
          rotateAlways: true,
        },
      },
      yaxis: {
        forceNiceScale: stack ? undefined : true,
        min: 0,
        max: stack ? undefined : stickyMax,
        tickAmount: stack ? 2 : undefined,
        labels: {
          formatter: (value: number) => (stack ? `${value}%` : Math.round(value)),
        },
      },
    }
  }, [data, stack, stickyMax, theme, onFormatX, onTooltipFormatX])

  const series = useMemo(() => {
    const plots = unique(data.flatMap((datum) => datum.values.map((value) => value.name)))
    return plots.map((plot) => ({
      name: plot,
      data: data.map((datum) => {
        const plotData = datum.values.find((value) => value.name === plot)
        return plotData?.value || 0
      }),
    }))
  }, [data])

  return <StyledChart type="bar" options={options} series={series} height={height} width={width} />
}

export default React.memo(ColumnChart)
