import React, { useState } from 'react'

import { useQuery } from '@apollo/client'
import getYear from 'date-fns/get_year'

import { Box, Divider, Flex, Heading, SelectField, StencilImage, StencilLine } from 'stardust'
import styled from 'styled-components'

import Error404 from '~/components/404'

import { useSelectedServiceFkey } from '~/contexts/Service'

import { is404 } from '~/modules/api'

import { pickOrNull } from '~/utils'

import { DateRangePicker, defaultPresetDateRanges } from '../DatePicker'

import MediaList from './MediaList'
import MediaUploader from './MediaUploader'
import { GET_CHILDREN, GET_MEDIA_BY_TYPE, GET_ROOMS } from './queries'

type Type = 'all' | 'file' | 'image' | 'video'

interface Props {
  selectedType: Type
  onMediaSelect(media: Playground.MediaItem): void
}

const CollapsingHeader = styled(Flex)`
  @media (max-width: 1150px) {
    display: block;

    .select-filters {
      display: block;
    }

    .select-filter {
      width: 100%;
      padding-left: 0px;
    }
  }
`
const opacity = pickOrNull('opacity')
const OpaqueFlex = styled(Flex)`
  ${opacity};
`

const LoadingRow = () => (
  <OpaqueFlex opacity={6 / 10} mb={4}>
    <StencilImage mt={2} mr={2} width={200} height={200} color="grey" />
    <StencilImage mt={2} mr={2} width={200} height={200} color="grey" />
    <StencilImage mt={2} mr={2} width={200} height={200} color="grey" />
  </OpaqueFlex>
)

const LoadingPlaceholder = () => {
  return (
    <Box px={4}>
      <StencilLine width={1 / 5} mr={2} mb={2} />
      <LoadingRow />
      <StencilLine width={1 / 5} mr={2} mt={2} mb={2} />
      <LoadingRow />
    </Box>
  )
}

const Media = ({ selectedType = 'all', onMediaSelect }: Props) => {
  const [type, setType] = useState<Type>(selectedType)
  const [child, setChild] = useState<string>('all')
  const [room, setRoom] = useState<string>('all')
  const [dateRange, setDateRange] = useState<{ from: Date; to: Date } | undefined>(undefined)
  const serviceFkey = useSelectedServiceFkey()

  const typeOptions: { value: Type; title: string }[] = [
    { value: 'all', title: 'All Types' },
    { value: 'image', title: 'Image' },
    { value: 'file', title: 'File' },
    { value: 'video', title: 'Video' },
  ]

  const { data, error, loading } = useQuery(GET_ROOMS, {
    variables: { serviceFkey },
  })

  const {
    data: childrenData,
    error: childrenError,
    loading: childrenLoading,
  } = useQuery(GET_CHILDREN, {
    variables: { serviceFkey },
  })

  const {
    data: mediaData,
    error: mediaError,
    loading: mediaLoading,
    fetchMore: fetchMoreMedia,
    refetch: refetchMedia,
  } = useQuery(GET_MEDIA_BY_TYPE, {
    variables: {
      serviceFkey,
      type: type.toUpperCase(),
      child_fkey: child !== 'all' ? child : undefined,
      room_fkey: room !== 'all' ? room : undefined,
      dateRange: dateRange && {
        first: dateRange.from.toISOString(),
        last: dateRange.to.toISOString(),
      },
    },
  })

  if (error) {
    if (is404(error)) {
      return <Error404 message="Rooms not found" />
    }

    throw error
  }

  if (childrenError) {
    if (is404(childrenError)) {
      return <Error404 message="Children not found" />
    }

    throw error
  }

  if (mediaError) {
    if (is404(mediaError)) {
      return <Error404 message="Media not found" />
    }

    throw error
  }

  const rooms: { fkey: string; name: string }[] = loading ? [] : data.service.rooms
  const roomOptions = loading
    ? [{ value: 'all', title: 'All Rooms' }]
    : [{ value: 'all', title: 'All Rooms' }, ...rooms.map(({ fkey, name }) => ({ value: fkey, title: name }))]

  const children: { fkey: string; fullName: string }[] = childrenLoading ? [] : childrenData.service.children
  const childrenOptions = childrenLoading
    ? [{ value: 'all', title: 'All Children' }]
    : [
        { value: 'all', title: 'All Children' },
        ...children.map(({ fkey, fullName }) => ({ value: fkey, title: fullName })),
      ]

  const selectFields = [
    {
      label: 'Type',
      value: type,
      onChange: (e: any) => setType(e.target.value as Type),
      options: typeOptions,
    },
    {
      label: 'Child',
      value: child,
      onChange: (e: any) => setChild(e.target.value),
      options: childrenOptions,
    },
    {
      label: 'Room',
      value: room,
      onChange: (e: any) => setRoom(e.target.value),
      options: roomOptions,
    },
  ]

  return (
    <Flex bg="surfacePrimary" flexDirection="column" width={1}>
      <CollapsingHeader
        alignItems="center"
        justifyContent="space-between"
        p={4}
        pb={4}
        width={1}
        maxWidth="100%">
        <Heading.h3 minWidth="160px" bold={false} lineHeight={1} mb={0} mt={0}>
          Add Media
        </Heading.h3>
        <Flex className="select-filters">
          {selectFields.map(({ label, value, onChange, options }) => (
            <Flex
              onClick={(e: any) => e.stopPropagation()}
              className="select-filter"
              width={[1 / 3]}
              key={`${label}-select`}
              py={2}
              pl={3}>
              <SelectField label={label} value={value} onChange={onChange}>
                {options.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.title}
                  </option>
                ))}
              </SelectField>
            </Flex>
          ))}
        </Flex>
        <Flex>
          <Flex pr={3}>
            <DateRangePicker
              endYear={getYear(new Date())}
              label="Date Range"
              presetPeriods={defaultPresetDateRanges}
              selected={dateRange}
              setSelection={setDateRange}
            />
          </Flex>
          <Flex p={1} maxWidth="300px">
            <MediaUploader refetchMedia={refetchMedia} />
          </Flex>
        </Flex>
      </CollapsingHeader>

      <Divider borderColor="dividerBackground" m="-1px 0 0 0" mb="0px" width={1} />

      {mediaLoading ? (
        <LoadingPlaceholder />
      ) : (
        <MediaList
          type={type}
          data={mediaData}
          fetchMoreMedia={fetchMoreMedia}
          onMediaSelect={onMediaSelect}
        />
      )}
    </Flex>
  )
}

Media.displayName = 'Media'

export default React.memo(Media)
