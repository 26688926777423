import React from 'react'

import styled from 'styled-components'
import { AlignSelfProps, FlexBasisProps, alignSelf, flexBasis } from 'styled-system'

import Box from './Box'

type BaseProps = AlignSelfProps & FlexBasisProps

export interface FlexItemProps extends BaseProps {
  flexGrow?: number | string
}

const flexGrow = (props: FlexItemProps) => (props.flexGrow ? `flex-grow: ${props.flexGrow}` : null)

const FlexItem = styled(Box)<FlexItemProps>`
  ${alignSelf};
  ${flexBasis};
  ${flexGrow};
`

export default React.memo(FlexItem)
