import React from 'react'

import styled from 'styled-components'
import { height, width } from 'styled-system'

import ImageAvatar from './AvatarImage'
import Icon from './Icon'
import { large, small, xsmall, xxsmall } from './utils/avatarSize'

// Fix for the planner, remove when rewrite
const planner = (props) => {
  props.planner ? null : { position: 'relative' }
}

const Wrapper = styled.div`
  ${height} ${width};
  ${large} ${small} ${xsmall} ${xxsmall};
  display: flex;

  ${planner}

  overflow: hidden;
  font-size: 1.25rem;
  flex-shrink: 0;
  align-items: center;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
`

Wrapper.defaultProps = {
  height: '80px',
  width: '80px',
}

const Avatar = (props) => {
  const { icon, large, planner, small, xsmall, xxsmall, context } = props

  let textColor = 'avatarText'
  let borderColor = 'avatarBorder'

  if (context === 'Parent') {
    textColor = 'hullOrange9'
    borderColor = 'hullOrange'
  } else if (context === 'Educator') {
    textColor = 'nebulaBlue9'
    borderColor = 'nebulaBlue'
  }

  return (
    <Wrapper
      large={large}
      small={small}
      xsmall={xsmall}
      xxsmall={xxsmall}
      planner={planner}
      borderColor={borderColor}>
      {icon ? <Icon name={icon} fill={textColor} /> : <ImageAvatar {...props} />}
    </Wrapper>
  )
}

Avatar.displayName = 'Avatar'

export default Avatar
