import React from 'react'

import { toast } from 'react-toastify'

import CustomToast from './CustomToast'
import { CustomToastProps } from './CustomToast'

export const generateToastMessage = (toastId: string | number, customToastProps: CustomToastProps) => {
  return toast.info(<CustomToast {...customToastProps} />, {
    toastId: toastId,
  })
}
