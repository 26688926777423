import React, { ReactNode } from 'react'

import { Placement } from '@popperjs/core'

import { Box, Dropdown, Flex, Icon, Text } from 'stardust'

import styled from 'styled-components'

import colors from '~/ui-components/tokens/colors'

export interface DropdownMenuItemProps {
  label: string
  icon?: JSX.Element
  onClick(): unknown
}

interface Props {
  disabled?: boolean
  items: DropdownMenuItemProps[]
  label: ReactNode
  placement?: Placement
  portal?: boolean
  zIndex?: number
}

const HoverFlex = styled(Flex)`
  min-width: 200px;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.textPrimaryMedium};
  transition: background 0.25s ease;

  &:hover {
    background: ${colors.cosmicShade4};
  }

  text-decoration: none;

  &:last-child {
    border-bottom: 0;
  }
`

export const DropdownMenuItem = ({ label, icon, onClick }: DropdownMenuItemProps) => {
  const handleClick = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()

    onClick()
  }

  return (
    <HoverFlex
      cursor="pointer"
      justifyContent="center"
      alignItems="center"
      borderBottom="1px solid"
      borderColor="surfacePrimaryBorder"
      p={3}
      textAlign="center"
      onClick={handleClick}>
      {icon && (
        <Box width="25%" mr={2}>
          {icon}
        </Box>
      )}
      <Box width={icon ? '75%' : ''}>
        <Text.span fontSize={1}>{label}</Text.span>
      </Box>
    </HoverFlex>
  )
}

const DropdownMenu = ({ disabled, items, label, placement, portal, zIndex }: Props) => {
  const color = disabled ? 'textPrimaryLow' : 'textPrimaryMedium'
  const safeItems = disabled ? [] : items

  return (
    <Box cursor="pointer" width={1} data-test="dropdown-menu">
      <Dropdown
        portal={portal}
        renderButton={({ handleClose }) => (
          <Flex alignItems="center" width={1} onClick={handleClose}>
            <Flex flexGrow={1}>
              {typeof label === 'string' ? <Text.span color={color}>{label}</Text.span> : label}
            </Flex>
            <Icon color={color} ml={1} name="chevronDown" />
          </Flex>
        )}
        render={({ handleClose }) => (
          <Flex flexDirection="column" maxHeight={'75vh'} overflow={'auto'}>
            {safeItems.map((item) => {
              const onClick = () => {
                handleClose()
                item.onClick()
              }

              return <DropdownMenuItem key={item.label} label={item.label} onClick={onClick} />
            })}
          </Flex>
        )}
        zIndex={zIndex}
        placement={placement}
      />
    </Box>
  )
}

DropdownMenu.displayName = 'DropdownMenu'
DropdownMenuItem.displayName = 'DropdownMenuItem'

export default React.memo(DropdownMenu)
