import React, { useCallback, useState } from 'react'

import { navigate } from '@reach/router'

import { Box, Card, Flex } from 'stardust'

import DocumentPreviewCard from '~/components/DocumentPreviewCard'
import { isWebView } from '~/config'

import { useSelectedServiceFkey } from '~/contexts/Service'

import TableModal from '~/pages/Tables/Modal'
import { GET_LEARNING_TABLE_DETAIL } from '~/pages/Tables/queries'
import { ReadOnlyCell, Table, TableLoader } from '~/pages/Tables/Table'
import { TableState } from '~/pages/Tables/types'
import { getCellKey } from '~/pages/Tables/utils'

interface TableModalProps {
  article: Playground.Article
  table: TableState
}

interface LoaderProps {
  id: number
}

const TableCard = ({ article, table }: TableModalProps) => {
  const [open, setOpen] = useState(false)

  const [width, setWidth] = useState<Nullable<number>>(null)

  const measure = (node: HTMLDivElement) => {
    setWidth(node ? node.clientWidth : null)
  }

  const onCellRender = useCallback(
    ({ column, row }) => {
      const cellKey = getCellKey(column.id, row.id)
      return <ReadOnlyCell cell={table.cells[cellKey]} />
    },
    [table]
  )

  return !width || width < 500 ? (
    <>
      <DocumentPreviewCard
        description="Table"
        title={table.title}
        onOpen={() => (isWebView ? navigate(`/tables/${article.id}/webview`) : setOpen(true))}
      />

      {open ? (
        <TableModal contentStatus="PUBLISHED" id={article.id} show onClose={() => setOpen(false)} />
      ) : null}
    </>
  ) : (
    <Card ref={measure} overflow="auto">
      <Flex
        width={1}
        flexDirection={['column-reverse', 'row']}
        justifyContent={['flex-start', 'space-between']}
        bg="surfaceSecondary">
        <Box overflow="auto" bg="surfacePrimary">
          <Table cellRenderer={onCellRender} columns={table.columns} rows={table.rows} />
        </Box>
      </Flex>
    </Card>
  )
}

const Loader = ({ id }: LoaderProps) => {
  const serviceFkey = useSelectedServiceFkey()

  return (
    <TableLoader
      embedded
      query={GET_LEARNING_TABLE_DETAIL}
      variables={{
        id: Number(id),
        serviceFkey,
        contentStatus: 'PUBLISHED',
      }}>
      {(props) => <TableCard {...props} />}
    </TableLoader>
  )
}

TableCard.displayName = 'TableCard'

export default React.memo(Loader)
