import React from 'react'

const CheckCircleAlt = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.75 9.00002L10.044 13.611C10.3188 13.9957 10.7564 14.2314 11.2289 14.249C11.7014 14.2666 12.1553 14.0642 12.458 13.701L23.25 0.749023"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.783 3.8239C12.3619 1.08791 6.60374 2.00422 3.25032 5.97736C-0.103103 9.95049 -0.0391948 15.7808 3.40051 19.6795C6.84021 23.5782 12.6171 24.368 16.9771 21.5358C21.3371 18.7036 22.9638 13.1044 20.8 8.37689"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CheckCircleAlt
