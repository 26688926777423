import gql from 'graphql-tag'

export const ROOM_INFO_FRAGMENT = gql`
  fragment RoomInfo on Room {
    fkey
    name
    type
    startTime
    endTime
    ageGroup
  }
`

export const ROOM_METRICS_FRAGMENT = gql`
  fragment RoomMetrics on Room {
    metrics(dateRange: $dateRange) {
      observations
      healthEvents
    }
  }
`
