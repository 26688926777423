import React from 'react'

import { Box, Container, Flex, Interactive, hoverMixin } from 'stardust'
import styled from 'styled-components'

const InteractiveHover = styled(Interactive)`
  ${hoverMixin};
`

const ListItem = ({ children, graphic, metrics }) => {
  const GraphicComponent = graphic
  const MetricsComponent = metrics && metrics

  return (
    <Box borderBottom="1px solid" borderColor="surfacePrimaryBorder">
      <InteractiveHover>
        <Container>
          <Flex w={1} py={3} alignItems="center">
            <Box pr={3}>
              <GraphicComponent />
            </Box>
            <Flex flexDirection="column" flex="1 1 auto" data-test="list-item">
              {children}
            </Flex>
            {metrics && (
              <Flex>
                <MetricsComponent />
              </Flex>
            )}
          </Flex>
        </Container>
      </InteractiveHover>
    </Box>
  )
}

ListItem.displayName = 'ListItem'

export default ListItem
