import React from 'react'

const Collage = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 14.5C0.75 13.9477 1.19772 13.5 1.75 13.5H9.5C10.0523 13.5 10.5 13.9477 10.5 14.5V22.25C10.5 22.8023 10.0523 23.25 9.5 23.25H1.75C1.19772 23.25 0.75 22.8023 0.75 22.25V14.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.75 19.5H10.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 1.75C0.75 1.19772 1.19772 0.75 1.75 0.75H9.5C10.0523 0.75 10.5 1.19772 10.5 1.75V9.5C10.5 10.0523 10.0523 10.5 9.5 10.5H1.75C1.19772 10.5 0.75 10.0523 0.75 9.5V1.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.75 6.75H10.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 1.75C13.5 1.19772 13.9477 0.75 14.5 0.75H22.25C22.8023 0.75 23.25 1.19772 23.25 1.75V9.5C23.25 10.0523 22.8023 10.5 22.25 10.5H14.5C13.9477 10.5 13.5 10.0523 13.5 9.5V1.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 6.75H23.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 14.5C13.5 13.9477 13.9477 13.5 14.5 13.5H22.25C22.8023 13.5 23.25 13.9477 23.25 14.5V22.25C23.25 22.8023 22.8023 23.25 22.25 23.25H14.5C13.9477 23.25 13.5 22.8023 13.5 22.25V14.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 19.5H23.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Collage
