import React, { useCallback, useState } from 'react'

import format from 'date-fns/format'

import { FlexProps } from 'stardust/Flex'

import CalendarWithButtons from './CalendarWithButtons'
import { PresetPeriod } from './utils'

interface Props {
  emptyPresetPeriod: PresetPeriod<Date>
  endYear: number
  initialDisplayMonth?: Date
  label: string
  presetPeriods: PresetPeriod<Date>[]
  readOnly?: boolean
  showStyledCalander?: boolean
  selected?: Date
  showTextBox?: boolean
  startYear?: number
  setSelection: (newSelection?: Date) => void
  heightAdjust?: string | number
  br?: string | number
  borderWidth?: string | number
  pbottom?: string | number
}

const DatePicker = ({
  emptyPresetPeriod,
  endYear,
  initialDisplayMonth,
  label,
  presetPeriods: presetPeriods,
  readOnly,
  selected,
  showStyledCalander,
  showTextBox,
  startYear,
  setSelection,
  heightAdjust,
  br,
  borderWidth,
  pbottom,
  ...other
}: Props & FlexProps) => {
  const [hoveredValue, setHoveredValue] = useState<Undefinedable<Date>>(undefined)

  const onSelectDate = useCallback((date) => setSelection(date), [setSelection])

  const formatPeriod = useCallback(
    (period) => (period ? format(period, 'DD MMM YYYY') : emptyPresetPeriod.label),
    [emptyPresetPeriod]
  )

  const isPeriodSelected = useCallback(
    (period) => {
      if (period === undefined && selected === undefined) return true
      return period === selected
    },
    [selected]
  )

  return (
    <CalendarWithButtons
      endYear={endYear}
      hoveredValue={hoveredValue}
      initialDisplayMonth={initialDisplayMonth}
      label={label}
      presetPeriods={[emptyPresetPeriod, ...presetPeriods]}
      readOnly={readOnly}
      selected={selected}
      showStyledCalander={showStyledCalander}
      showTextBox={showTextBox}
      startYear={startYear}
      formatPeriod={formatPeriod}
      isPeriodSelected={isPeriodSelected}
      onSelectDate={onSelectDate}
      onSelectPeriod={onSelectDate}
      setHoveredValue={setHoveredValue}
      heightAdjust={heightAdjust}
      br={br}
      borderWidth={borderWidth}
      pbottom={pbottom}
      {...other}
    />
  )
}

export default DatePicker
