import React from 'react'

import { Box, Text } from 'stardust'

interface Props {
  location: string
}

const Location = ({ location }: Props) => {
  return (
    <Box data-test="document-location">
      <Text bold mb={3} fontSize={2}>
        Location
      </Text>
      <Text fontSize={2} medium>
        {location}
      </Text>
    </Box>
  )
}

Location.displayName = 'DocumentRendererLocation'

export default React.memo(Location)
