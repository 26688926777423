import gql from 'graphql-tag'

export default gql`
  fragment DraftPostLearningOutcomes on DraftPost {
    learningOutcomes {
      id
      name
      frameworkName
    }
  }
`
