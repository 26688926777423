import React, { ReactNode } from 'react'

import Box from './Box'
import Flex from './Flex'
import Icon from './Icon'
import Text from './Text'

export interface ErrorMessageProps {
  children: ReactNode
}

const ErrorMessage = ({ children, ...props }: ErrorMessageProps) => (
  <Box
    border={1}
    borderColor="negative"
    borderRadius={1}
    mb={2}
    p={3}
    style={{ boxSizing: 'border-box' }}
    width={1}
    {...props}>
    <Flex alignItems="center" justifyContent="flex-start" width={1}>
      <Box mr={2}>
        <Icon bg="negative" fill="surfacePrimary" name="error" />
      </Box>
      <Flex alignItems="center" justifyContent="space-between" width={1}>
        <Text.span color="negative" fontSize={0}>
          {children}
        </Text.span>
      </Flex>
    </Flex>
  </Box>
)

ErrorMessage.displayName = 'ErrorMessage'

export default React.memo(ErrorMessage)
