import React from 'react'

import * as R from 'ramda'

import FetchingMore from './FetchingMore'
import ListEnd from './ListEnd'

export default class InfiniteScroll extends React.Component {
  state = {
    isMakingRequest: false,
    noMoreData: false,
  }

  componentDidUpdate = () => this.onScroll(this.props.pageYOffset, this.props.innerHeight)

  hasReachedBottomOfPage = () => {
    const { data, fetchMore, path } = this.props

    if (!R.path([...path, 'pageInfo', 'hasNextPage'], data)) {
      return this.setState({ noMoreData: true })
    }

    this.setState({ isMakingRequest: true })

    fetchMore({
      variables: {
        cursor: R.path([...path, 'pageInfo', 'endCursor'], data),
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = R.path([...path, 'edges'], fetchMoreResult)

        if (!newEdges.length) return previousResult

        const pageInfo = R.path([...path, 'pageInfo'], fetchMoreResult)
        const edges = R.path([...path, 'edges'], previousResult)
        const edgesLens = R.lensPath([...path, 'edges'])
        const pageInfoLens = R.lensPath([...path, 'pageInfo'])

        return R.pipe(
          R.set(edgesLens, [...edges, ...newEdges]),
          R.set(pageInfoLens, pageInfo)
        )(previousResult)
      },
    }).then(() => this.setState({ isMakingRequest: false }))
  }

  onScroll = (pageYOffset, innerHeight) => {
    // ACTIVATION_OFFSET triggers data fetching 100px before reaching the bottom of the page
    const ACTIVATION_OFFSET = 100
    const scrollHeight = this.props.scrollHeight ? this.props.scrollHeight : document.body.scrollHeight

    if (this.props.pause) return
    if (this.state.isMakingRequest) return
    if (this.state.noMoreData) return

    if (innerHeight + pageYOffset + ACTIVATION_OFFSET >= scrollHeight) {
      return this.hasReachedBottomOfPage()
    }
  }

  render = () => (
    <>
      {this.props.children}
      {!this.props.hideListEnd && this.state.noMoreData && this.props.showMoreData}
      {this.state.isMakingRequest && this.props.showLoading}
    </>
  )
}

InfiniteScroll.defaultProps = {
  showMoreData: <ListEnd />,
  showLoading: <FetchingMore />,
}
