import React from 'react'

import { Flex, Text } from 'stardust'

import colors from '~/ui-components/tokens/colors'

interface PostModalDescriptionLengthValidation {
  description?: string | undefined
}

const PostModalDescriptionLengthValidation = ({ description }: PostModalDescriptionLengthValidation) => {
  const characterLimit = 15000
  const isDescriptionOverLimit = description && description.length === characterLimit
  const len = characterLimit - (description ? description.length : 0)

  const descriptionTextLengthValidation = (len: number) => {
    if (len >= characterLimit) {
      return { msg: `0/${characterLimit}` }
    } else {
      return { msg: `${description?.length ?? 0}/${characterLimit}` }
    }
  }
  const lengthValidation = descriptionTextLengthValidation(len)

  return (
    <Flex
      alignItems="center"
      justifyContent={isDescriptionOverLimit ? 'space-between' : 'right'}
      px={2}
      mb={1}>
      {isDescriptionOverLimit && (
        <Text color={colors.cosmicShade20} fontSize="14px" fontWeight={400} lineHeight="21px" px="16px">
          You have reached the maximum character limit of 15,000
        </Text>
      )}
      <Flex>
        <Text
          color={colors.cosmicShade20}
          fontSize="14px"
          fontWeight={400}
          lineHeight="21px">{`${lengthValidation.msg}`}</Text>
      </Flex>
    </Flex>
  )
}
PostModalDescriptionLengthValidation.displayName = 'PostModalDescriptionLengthValidation'

export default React.memo(PostModalDescriptionLengthValidation)
