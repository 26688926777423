import React from 'react'

import { Helmet } from 'react-helmet'

import { isHome } from '~/config'

import Application from './Application'
import Theme from './Theme'

const App = () => (
  <>
    <Helmet>
      <title>Xplor - {isHome ? 'Home' : 'Playground'}</title>
      <link
        rel="icon"
        href={`https://dizo3j8jnw9cn.cloudfront.net/playground/${isHome ? 'home' : 'playground'}.png`}
        sizes="32x32"
        type="image/png"
      />
    </Helmet>

    <Theme>
      <Application />
    </Theme>
  </>
)

App.displayName = 'App'

export default App
