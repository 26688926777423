import React from 'react'

import RIsNil from 'ramda/src/isNil'
import RWhen from 'ramda/src/when'
import { Block, Change } from 'slate'
import { RenderBlockProps } from 'slate-react'

import { Box } from 'stardust'

import DisplayMedia from '~/components/Media/DisplayMedia'
import MediaDropZone from '~/components/Media/MediaDropZone'
import { isWebView } from '~/config'

import { SerializeMediaProps } from '../../types'

interface MediaNodeData {
  id: number
  media: Playground.MediaItem
  type: string
}

const updateNode = (change: Change, node: Block, data: any) => {
  const updatedNode = node.update('data', () => data) as Block
  return change.setNodeByKey(updatedNode.key, updatedNode)
}

export default {
  serialize: ({ media, size }: SerializeMediaProps) => {
    return <DisplayMedia key={media.id} {...media} {...size} />
  },

  renderNode: ({ children, editor, node }: RenderBlockProps) => {
    const data = node.data.toJS() as MediaNodeData
    const media = data.media
    const mediaType = data.type
    const width = isWebView ? '100%' : '300px'

    return (
      <Box width={width} height="auto">
        <MediaDropZone
          media={media}
          mediaType={mediaType}
          openChooser={RIsNil(media)}
          onClose={RWhen(RIsNil, () =>
            editor.controller.change((change: Change) => change.removeNodeByKey(node.key))
          )}
          onMediaSelection={(m: any) => {
            editor.controller.change((c: Change) => updateNode(c, node, { ...data, media: m }))
          }}
        />
        {children}
      </Box>
    )
  },
}
