import React from 'react'

import format from 'date-fns/format'
import Truncate from 'react-truncate'

import { Card, Flex, Icon, Text } from 'stardust'
import styled from 'styled-components'

import Carousel from '~/components/Carousel'
import MediaPlayer from '~/components/MediaPlayer'

interface Props {
  post: Playground.Post
}

const S = {
  Container: styled.a`
    display: block;
    padding: 16px;
    text-decoration: none;
  `,
  Date: styled(Text)`
    color: ${(props) => props.theme.colors.cosmicShade1};
  `,
  Content: styled(Text)`
    color: ${(props) => props.theme.colors.textPrimaryMedium};
  `,
  Card: styled(Card)`
    background-color: ${(props) => props.theme.colors.surfaceSecondary};
    overflow: hidden;
  `,
  ImageCard: styled(Card)`
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  `,
}

const ObservationWebViewCard = ({ post }: Props) => {
  const comments = post.comments || []
  const media = post.media || []

  return (
    <>
      <S.Date mb={2} fontSize={1}>
        {format(post.updatedAt, 'ddd, D MMM YYYY')}
      </S.Date>
      <S.Card cursor="pointer">
        {media.length > 1 && (
          <Flex height="auto">
            <Carousel>
              {media.map((mediaItem) => {
                const { coverUrl, id, mimeType, url } = mediaItem
                return (
                  <Flex key={id} alignItems="center" height="auto" justifyContent="center" width="100%">
                    <MediaPlayer coverUrl={coverUrl} mimeType={mimeType} title={post.title} url={url} />
                  </Flex>
                )
              })}
            </Carousel>
          </Flex>
        )}

        {media.length === 1 && (
          <S.ImageCard>
            <MediaPlayer
              coverUrl={media[0].coverUrl}
              mimeType={media[0].mimeType}
              title={post.title}
              url={media[0].url}
            />
          </S.ImageCard>
        )}

        <S.Container href={`xplor://observations/${post.id}`}>
          <Text bold fontSize={3} mb={2}>
            {post.title || 'Document'}
          </Text>
          <S.Content fontSize={1} mb={3}>
            {post.description && <Truncate lines={3}>{post.description}</Truncate>}
          </S.Content>
          <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center">
              <Icon color="white" name="commentsAlt" size={16} />
              <Text.span fontSize={1} ml={2}>
                {comments.length} {comments.length === 1 ? 'comment' : 'comments'}
              </Text.span>
            </Flex>
          </Flex>
        </S.Container>
      </S.Card>
    </>
  )
}

ObservationWebViewCard.displayName = 'ObservationWebViewCard'

export default React.memo(ObservationWebViewCard)
