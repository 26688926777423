import React from 'react'

import { useQuery } from '@apollo/client'
import distanceInWords from 'date-fns/distance_in_words'
import * as R from 'ramda'

import { Box, Card, Flex, Icon, SecondaryButton, Text } from 'stardust'

import ContentPlaceholder from '~/components/ContentPlaceholder'
import DocumentPreviewCard from '~/components/DocumentPreviewCard'
import DocumentRenderer from '~/components/DocumentRenderer'
import MediaRenderer from '~/components/MediaRenderer'
import { useSelectedServiceFkey } from '~/contexts/Service'

import { is404 } from '~/modules/api'

import { GET_POST } from '~/pages/Observations/queries'
import { splitMediaTypes } from '~/utils/filesAndMedia'

const observedOnText = ({ postDate, postTime }: { postDate: string; postTime: string }) =>
  distanceInWords(new Date(`${postDate} ${R.pipe(R.split('.'), R.head)(postTime)}`), new Date())

interface LoaderProps {
  id: number
  stacked?: boolean
}

interface Props {
  post: Playground.Post
  stacked?: boolean
}

const ObservationDetailCard = ({ post, stacked }: Props) => {
  const creator = post.contributors?.find((x) => x.action === 'created')
  const [mediaAttachments] = splitMediaTypes(post?.media || [])

  return (
    <Card bg="surfaceSecondary" overflow="hidden">
      <DocumentRenderer
        caption={post.description}
        documentId={post.id}
        documentType="observation"
        footer={
          <Flex alignItems="center" justifyContent="space-between" width={1}>
            <Text.span fontSize={1} medium px={3}>
              {`Post created ${observedOnText(post)} ago`}
            </Text.span>
            <Flex alignItems="center" minWidth="40%" justifyContent="flex-end" screenOnly>
              <Box m={3}>
                <SecondaryButton
                  icon={<Icon name="fullscreen" fill="primary" />}
                  onClick={() => window.open(`/observations/${post.id}`)}>
                  Full Screen
                </SecondaryButton>
              </Box>
            </Flex>
          </Flex>
        }
        futurePlanning={post.futurePlanning}
        heading={post.title}
        learningOutcomes={post.learningOutcomes}
        overline={creator?.fullName}
        planningDate={post.planningDate}
        stacked={stacked}
        taggedChildren={post.children}
        learningContentAssets={post.learningContentAssets}>
        <MediaRenderer mediaItems={mediaAttachments!} title={post.title} />
      </DocumentRenderer>
    </Card>
  )
}

ObservationDetailCard.displayName = 'ObservationDetailCard'

const ObservationLoader = ({ id, stacked }: LoaderProps) => {
  const serviceFkey = useSelectedServiceFkey()

  const { data, error, loading } = useQuery(GET_POST, {
    variables: { id, serviceFkey },
    skip: !id,
  })

  if (error) {
    // 404 is expected behavior if we're embedding an observation, but the
    // parent doesn't have a child tagged in it
    if (is404(error)) {
      return (
        <DocumentPreviewCard
          title="Post"
          description="You do not have permission to view this content. Your child is not tagged in the post."
        />
      )
    }

    throw error
  }

  const post = data?.context?.post as Playground.Post | undefined

  if (loading || !post) return <ContentPlaceholder />
  if (post.status === 'DELETED') return null

  return <ObservationDetailCard post={post} stacked={stacked} />
}
ObservationLoader.displayName = 'ObservationLoader'

export default React.memo(ObservationLoader)
