import React from 'react'

import * as R from 'ramda'

import { Flex } from 'stardust'

import camera from '~/assets/images/camera.svg'
import swaddledBaby from '~/assets/images/swaddledBaby.svg'

import { shortAge } from '../core'

import MetricItem from './MetricItem'

const ChildMetrics = ({ child, ...props }) => (
  <Flex width={1}>
    {!props.inline && <MetricItem count={shortAge(child.dob)} icon={swaddledBaby} label="Age" {...props} />}

    <MetricItem
      count={R.path(['metrics', 'observations'], child)}
      icon={camera}
      label="Posts this Month"
      {...props}
    />
  </Flex>
)

ChildMetrics.displaName = 'ChildMetrics'
export default ChildMetrics
