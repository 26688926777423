import React, { useRef, useState } from 'react'

import { Box, Popover, Relative, Text } from 'stardust'
import styled from 'styled-components'

import PrivacyAwareChildrenList from '~/components/PrivacyAwareChildrenList'
import ProfileChip from '~/components/Profiles/Chip'

interface Props {
  attributes: any
  childList: Playground.SimpleChild[]
  title: string
  subtitle?: string
}

const InlineBox = styled(Box)`
  display: inline-flex;
`

const BulkChildrenTag = ({ title, subtitle, childList, attributes }: Props) => {
  const ref = useRef(null)
  const [hover, setHover] = useState(false)

  const renderChild = (child: Playground.SimpleChild) => (
    <Text key={child.fkey} p={2}>
      {child.fullName}
    </Text>
  )

  return (
    <InlineBox ref={ref} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <Relative>
        <ProfileChip xxsmall fullName={title} additionalText={subtitle} {...attributes} />
        <Popover
          anchor={ref}
          placement="bottom"
          show={hover && childList.length > 0}
          onToggle={() => setHover(false)}>
          <Box maxHeight="120px" overflowY="auto">
            <PrivacyAwareChildrenList childrenList={childList} renderChild={renderChild} />
          </Box>
        </Popover>
      </Relative>
    </InlineBox>
  )
}

export default BulkChildrenTag
