import colors from './colors'
import fonts from './fonts'
import indicies from './indicies'
import opacity from './opacity'
import radii from './radii'
import shadows from './shadows'
import space from './space'

export default {
  ...fonts,
  colors,
  maxWidths: ['1560px'],
  opacity,
  radii,
  shadows,
  space,
  zIndicies: indicies,
  breakpoints: ['64em', '1845px'],
}
