import gql from 'graphql-tag'

export default gql`
  fragment PostInfo on Post {
    title
    status
    planningDate
    postTime
    postDate
    publishedAt
    updatedAt
    futurePlanning
    externalId
    description
    bookmarked
  }
`
