import gql from 'graphql-tag'

export default gql`
  fragment PostTaggedChildrenWithInfo on Post {
    children {
      fkey
      fullName
      image
      otherDetails {
        consents
      }
    }
  }
`
