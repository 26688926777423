import persistLocally from '~/utils/persistLocally'

const VIEW_FROM_PLAYGROUND_APP_CONTEXT = 'view_from'
const VIEW_FROM_MOBILE_APP = 'mobile_app'

const isViewFromMobileApp = () => {
  const { persist, retrieve } = persistLocally(VIEW_FROM_PLAYGROUND_APP_CONTEXT)

  const isCallFromMobileApp = window.location.href.includes('inApp=true')
  if (isCallFromMobileApp) persist(VIEW_FROM_MOBILE_APP, 'true')

  const currentValue = retrieve(VIEW_FROM_MOBILE_APP)

  return currentValue === 'true'
}

export default isViewFromMobileApp
