import React from 'react'

const Tables = () => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M1.875 2.49915C1.875 2.15397 2.15482 1.87415 2.5 1.87415H9.375V9.37415H1.875V2.49915ZM0.625 9.99915V2.49915C0.625 1.46361 1.46446 0.624149 2.5 0.624149H10H17.5C18.5356 0.624149 19.375 1.46362 19.375 2.49915V9.99915V17.4991C19.375 18.5347 18.5356 19.3741 17.5 19.3741H10H2.5C1.46448 19.3741 0.625 18.5347 0.625 17.4991V9.99915ZM9.375 18.1241H2.5C2.1548 18.1241 1.875 17.8443 1.875 17.4991V10.6242H9.375V18.1241ZM10.625 18.1241H17.5C17.8452 18.1241 18.125 17.8443 18.125 17.4991V10.6242H10.625V18.1241ZM18.125 9.37415V2.49915C18.125 2.15395 17.8452 1.87415 17.5 1.87415H10.625V9.37415H18.125Z"
    />
  </svg>
)

export default Tables
