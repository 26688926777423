import React from 'react'

const HeadCountTick = () => (
  <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.1619,33.6407L3.8483,23.2714C2.0502,21.463 2.0506,18.5314 3.8493,16.7236C5.648,14.9157 8.5639,14.9162 10.362,16.7246L16.9243,23.3202L29.2115,6.8515C30.1988,5.5284 31.8132,4.8353 33.4465,5.0334C35.0798,5.2315 36.484,6.2906 37.13,7.8117C37.7761,9.3329 37.5659,11.0851 36.5786,12.4082L21.1043,33.1466C20.3035,34.2215 19.0778,34.8936 17.7453,34.9884C16.4129,35.0833 15.1052,34.5914 14.1619,33.6407Z"
      fill="#7BCAC5"
      fillRule="evenodd"
    />
    <path d="M30,15L22.5,25" fill="#000000" fillOpacity="0.0" stroke="#ffffff" strokeWidth="1" />
    <path d="M18.75,28.75m-1.25,0a1.25,1.25 0,1 1,2.5 0a1.25,1.25 0,1 1,-2.5 0" fill="#ffffff" />
    <path
      d="M14.1619,33.6407L3.8483,23.2714C2.0502,21.463 2.0506,18.5314 3.8493,16.7236C5.648,14.9157 8.5639,14.9162 10.362,16.7246L16.9243,23.3202L29.2115,6.8515C30.1988,5.5284 31.8132,4.8353 33.4465,5.0334C35.0798,5.2315 36.484,6.2906 37.13,7.8117C37.7761,9.3329 37.5659,11.0851 36.5786,12.4082L21.1043,33.1466C20.3035,34.2215 19.0778,34.8936 17.7453,34.9884C16.4129,35.0833 15.1052,34.5914 14.1619,33.6407Z"
      fill="#000000"
      fillOpacity="0.0"
      fillRule="evenodd"
      stroke="#444B54"
      strokeWidth="1"
    />
  </svg>
)

export default HeadCountTick
