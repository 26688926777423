import React from 'react'

const LearningContentPodcast = () => (
  <svg width="24" height="24" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 19.5V23.25"
      stroke="#007AFF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 3.75C7.5 2.09315 8.84315 0.75 10.5 0.75H13.5C15.1569 0.75 16.5 2.09315 16.5 3.75V13.5C16.5 15.1569 15.1569 16.5 13.5 16.5H10.5C8.84315 16.5 7.5 15.1569 7.5 13.5V3.75Z"
      stroke="#007AFF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5 9.75V12C19.5 16.1421 16.1421 19.5 12 19.5V19.5C7.85786 19.5 4.5 16.1421 4.5 12V9.75"
      stroke="#007AFF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default LearningContentPodcast
