import gql from 'graphql-tag'

export default gql`
  fragment PostMedia on Post {
    media {
      id
      coverUrl
      mimeType
      url
      metadata
    }
  }
`
