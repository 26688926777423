import React from 'react'

import { Link } from '@reach/router'
import styled from 'styled-components'
import { SpaceProps, space } from 'styled-system'

export type LinkProps = SpaceProps

const StyledLink = styled(Link)<LinkProps>`
  color: ${(props) => props.theme.colorPrimaryMedium};
  text-decoration: none;
  ${space}
`

StyledLink.displayName = 'Link'

export default React.memo(StyledLink)
