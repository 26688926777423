import React from 'react'

import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

import ChildMetrics from '~/components/Profiles/Metrics/ChildMetrics'
import { useSelectedServiceFkey } from '~/contexts/Service'
import { startToEndMonth } from '~/utils/dateRange'

import MentionedText from './MentionedText'
import MentionedTooltip from './MentionedTooltip'

interface GetChildren {
  context: {
    fkey: string
    children: Playground.SimpleChild[]
  }
}

const GET_CHILDREN = gql`
  query ChildrenWithMetrics($serviceFkey: String, $dateRange: DateRange!) {
    context(fkey: $serviceFkey) {
      fkey
      children {
        fkey
        fullName
        image
        metrics(dateRange: $dateRange) {
          observations
        }
      }
    }
  }
`

interface Props {
  childFkey: string
  displayName: string
  readOnly: boolean
}

const ChildSummary = ({ childFkey, displayName, readOnly }: Props) => {
  const serviceFkey = useSelectedServiceFkey()

  const { data, error, loading } = useQuery<GetChildren>(GET_CHILDREN, {
    variables: {
      serviceFkey,
      dateRange: startToEndMonth(new Date()),
    },
  })

  if (loading || error || !data) {
    return <MentionedText>{displayName.split(' ')[0]}</MentionedText>
  }

  const child = data.context.children.find((c) => c.fkey === childFkey)

  if (!child) return <MentionedText>{displayName.split(' ')[0]}</MentionedText>

  return (
    <MentionedTooltip
      title={child.fullName}
      image={child.image}
      link={`/children/${child.fkey}`}
      readOnly={readOnly}>
      <ChildMetrics child={child} small />
    </MentionedTooltip>
  )
}

ChildSummary.displayName = 'ChildSummary'

export default React.memo(ChildSummary)
