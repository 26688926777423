import * as R from 'ramda'

import * as factories from '../factories'
import * as legacySelectors from '../legacySelectors'

export default (node) => {
  const docId = R.path(['data', 'typeId'], node)
  const point = R.view(legacySelectors.layoutLens, node)
  const type = R.path(['data', 'type'], node)

  const data = { refId: docId, ref: type }

  return factories.documentFactory({ data, properties: { point } })
}
