import React, { useState, useRef } from 'react'

import distanceInWords from 'date-fns/distance_in_words'
import { Box, Card, Flex, Icon, Interactive, Popover, Text, TruncateMultiline } from 'stardust'

import styled from 'styled-components'

import Comment from '~/components/Comment/Comment'
import Modal from '~/components/Modal'

import colors from '~/ui-components/tokens/colors'
import { hasAtLeastOne } from '~/utils'

import { FieldProps } from '../../types'

const S = {
  Icon: styled(Icon)`
    border-radius: 50%;
    padding: 10px;
    background-color: ${(props) => props.backgroundColor};
    &:hover {
      background-color: ${(props) => props.backgroundColor};
      transition: background-color 0.2s;
    }
  `,

  Top: styled(Flex)`
    background-color: white;
    padding: 24px 24px 16px 24px;
    z-index: 100;
    position: sticky;
    top: 0;
    overflow-y: 0;
  `,
}

interface PostReflectionsModalProps extends FieldProps {
  reflections?: Playground.Reflection[]
  onCloseReflectionsModal: () => void
}

const PostReflectionsModal = ({ reflections, onCloseReflectionsModal }: PostReflectionsModalProps) => {
  const [selectionId, setSelectionId] = useState<Nullable<number>>(null)

  const listRef = useRef<HTMLElement>(null)
  const popperElementRef = useRef<null | HTMLElement>(null)

  return (
    <Box width="100%">
      <Modal open dontRenderCloseButton onClose={onCloseReflectionsModal} borderRadius="16px">
        <Card bg={colors.cosmicShade0} height="max-content" width="34vw" overflow-y="hidden" minWidth="0">
          <S.Top justifyContent="space-between" alignItems="center">
            <Flex>
              <Text ml={2} fontSize={4} fontWeight={600}>
                Internal notes
              </Text>
              <Text ml={2} fontSize={4}>
                (Reflections)
              </Text>
            </Flex>

            <S.Icon
              size={40}
              height={40}
              onClick={() => onCloseReflectionsModal()}
              name="close"
              backgroundColor={colors.cosmicShade4}
              hoverColor={colors.cosmicShade6}
            />
          </S.Top>
          <Box px={3} bg="surfacePrimary" borderTop={1} borderColor="surfacePrimaryBorder" ref={listRef}>
            {hasAtLeastOne(reflections) && (
              <Flex flexDirection="column" width={1}>
                {reflections?.map((reflection) => {
                  const postedAt = `${distanceInWords(reflection.insertedAt, new Date())} ago`
                  const replies = reflection.replies ? reflection.replies : []
                  const totalReplies = replies.length
                  return (
                    <Interactive
                      key={reflection.id}
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        setSelectionId(reflection.id)
                        popperElementRef.current = e.currentTarget
                      }}>
                      <Box>
                        <Flex alignItems="center" justifyContent="space-between" width={1}>
                          <Text mt={3} fontSize={0} bold>
                            {reflection.author.name}
                          </Text>
                          <Text mt={3} ml={2} fontSize={0}>
                            {postedAt}
                          </Text>
                        </Flex>
                        <Box borderBottom={1} borderColor="surfacePrimaryBorder" py={3}>
                          <TruncateMultiline fontSize={1} maxLines={4}>
                            {reflection.content}
                          </TruncateMultiline>
                          {totalReplies > 0 && (
                            <Flex pt={3} alignItems="center">
                              <Icon name="comments" fill="textPrimaryMedium" />
                              <Text.span fontSize={0} px={2} color="textPrimaryMedium">
                                {totalReplies}
                              </Text.span>
                            </Flex>
                          )}
                        </Box>
                      </Box>
                      <Popover
                        anchor={popperElementRef}
                        showArrow={false}
                        placement="bottom"
                        show={selectionId === reflection.id}
                        onToggle={() => {
                          popperElementRef.current = null
                          setSelectionId(null)
                        }}>
                        <Flex flexDirection="column" maxHeight="80vh" width="500px">
                          <Flex
                            minHeight={10}
                            ref={listRef}
                            borderColor="surfacePrimaryBorder"
                            flexDirection="column"
                            flexGrow={1}
                            p={3}
                            overflowY="auto">
                            <Comment comment={reflection} xsmall />
                            <Box mt={replies.length > 0 ? 3 : 0} pl={3}>
                              {replies.map((reply, idx) => (
                                <Box key={idx} mb={idx === replies.length - 1 ? 0 : 3}>
                                  <Comment comment={reply} xxsmall key={reply.id} />
                                </Box>
                              ))}
                            </Box>
                          </Flex>
                        </Flex>
                      </Popover>
                    </Interactive>
                  )
                })}
              </Flex>
            )}
          </Box>
        </Card>
      </Modal>
    </Box>
  )
}

PostReflectionsModal.displayName = 'PostReflectionsModal'
export default React.memo(PostReflectionsModal)
