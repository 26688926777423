import * as d3 from 'd3'
import * as R from 'ramda'

import { fromRef, transformToCssString } from '../utils'

export const scaleMin = 0.4
export const scaleMax = 2.0
export const scale = [scaleMin, scaleMax]

/**
 * bindZoomBehaviour
 *
 * Bind zoom events to the zoomRef and update the transformRef.
 *
 * Returns an object of actions to programatically zoom.
 */
export const bindZoomBehaviour = (onZoom, endZoom, zoomRef, transformRef, xmid, ymid, xdiff, ydiff) => {
  const zoomSelection = fromRef(zoomRef)
  const transformSelection = fromRef(transformRef)
  const xw = zoomRef.current.getBoundingClientRect().width
  const yw = zoomRef.current.getBoundingClientRect().height
  const initx = xw / 2 - xmid
  const inity = yw / 2 - ymid

  const initkx = xdiff === 0 ? 1 : R.min((xw / xdiff) * 0.9, 1)
  const initky = ydiff === 0 ? 1 : R.min((yw / ydiff) * 0.9, 1)

  const initk = R.min(initkx, initky).toFixed(2)

  const extent = [scaleMin * initk, scaleMax]

  const zoom = d3
    .zoom()
    .scaleExtent(extent)
    .on('start', () => {
      zoomSelection.style('cursor', 'grabbing')
    })
    .on('zoom', () => {
      onZoom(d3.event.transform)

      transformSelection.style('transform', transformToCssString(d3.event.transform))
    })
    .on('end', () => {
      zoomSelection.style('cursor', 'grab')
      const { k, x, y } = d3.event.transform
      endZoom({ k, x, y })
    })

  zoom.translateBy(zoomSelection, initx, inity)
  zoom.scaleBy(zoomSelection, Number(initk))

  zoomSelection.call(zoom)

  const scaleBy = (step) => zoom.scaleBy(fromRef(zoomRef), step)

  return {
    in() {
      scaleBy(1.3)
    },
    out() {
      scaleBy(1 / 1.3)
    },
    max() {
      scaleBy(Infinity)
    },
    min() {
      scaleBy(-Infinity)
    },
  }
}
