import React from 'react'

const Collapse = () => (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.499 3.5L1.49902 3.5"
      stroke="#252525"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.499 12.5L9.35236 7.35333C9.25869 7.25954 9.13158 7.20683 8.99902 7.20683C8.86647 7.20683 8.73935 7.25954 8.64569 7.35333L3.49902 12.5"
      stroke="#252525"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Collapse
