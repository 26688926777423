import React from 'react'

import styled from 'styled-components'

import Absolute from './Absolute'

const Sticky = styled(Absolute)`
  position: sticky;
`

Sticky.displayName = 'Sticky'

export default React.memo(Sticky)
