import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useQuery, useMutation } from '@apollo/client'

import { Button } from '@rmwc/button'
import { Anchor, Box, Flex, FlexItem, Heading, Icon, SearchField, Text, hoverMixin } from 'stardust'
import styled from 'styled-components'

import { useSelectedServiceFkey } from '~/contexts/Service'
import { GET_ACTIVE_DOCUMENT_FOLDERS } from '~/modules/documentFolders'
import { CREATE_FOLDER } from '~/modules/documentFolders'
import { InfiniteScroll, Scroll, sortObjectsBy } from '~/utils'

import EditFolderModal from './EditFolderModal'

interface Props {
  disabledFolders: number[]
  folderType: Playground.DocumentType
  onSelect: (folder: Playground.DocumentFolder) => void
}

const InteractiveHover = styled(({ disabled, ...props }) => <div {...props} />)`
  ${hoverMixin};
  background-color: ${(props: any) => (props.selected ? props.theme.colors.primaryLight : 'initial')};
  cursor: ${(props: any) => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${(props: any) =>
    props.disabled ? props.theme.colors.cosmicShade7 : props.theme.colors.textPrimary};
`

const FolderSelector = ({ disabledFolders, folderType, onSelect }: Props) => {
  const debounceRef = useRef<any>(null)
  const [query, setQuery] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [editing, setEditing] = useState(false)

  const serviceFkey = useSelectedServiceFkey()

  // Clear debounce timer on unmount
  useEffect(() => () => clearTimeout(debounceRef.current), [])

  const { data, error, loading, fetchMore, refetch } = useQuery(GET_ACTIVE_DOCUMENT_FOLDERS, {
    variables: { serviceFkey, folderTypes: [folderType] },
  })

  const folders: Playground.DocumentFolder[] = useMemo(
    () => data?.service.documentFolders.edges.map((edge: any) => edge.node) || [],
    [data]
  )

  const [createFolder] = useMutation(CREATE_FOLDER, {
    onCompleted: refetch,
  })
  const filteredFolders = useMemo(() => {
    const term = searchTerm.toLocaleLowerCase()
    const matched =
      searchTerm === '' ? folders : folders.filter((folder) => folder.name.toLowerCase().includes(term))
    return sortObjectsBy(matched, 'name')
  }, [folders, searchTerm])

  const onFilter = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value
    setQuery(value)
    clearTimeout(debounceRef.current)
    debounceRef.current = setTimeout(() => setSearchTerm(value), 500)
  }, [])

  const onNewFolder = useCallback(
    (folderName: string) =>
      createFolder({
        variables: { folderName, serviceFkey, documents: [], type: folderType },
      }),
    [createFolder, folderType, serviceFkey]
  )

  const onModalClose = () => {
    setEditing(false)
  }

  const OpenAddNewFolder = () => {
    setEditing(true)
  }

  if (error) return <Text>Error...</Text>

  if (loading) {
    return (
      <>
        <Heading.h4 lineHeight={1} mb={0} mt={0} pt={3} px={3}>
          Select a folder
        </Heading.h4>
        <Flex height="389px" p={3}>
          Fetching folder list...
        </Flex>
      </>
    )
  }

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Heading.h4 lineHeight={1} mb={0} mt={0} pt={3} px={3}>
          Select a folder
        </Heading.h4>

        <Button onClick={OpenAddNewFolder}>
          <Icon name="addCircle" color="primary" />
        </Button>

        {editing && (
          <EditFolderModal
            open={editing}
            location={folderType}
            title={'Create a folder'}
            name={''}
            onSubmit={onNewFolder}
            onClose={onModalClose}
            onError={() => 'Error creating a folder'}
          />
        )}
      </Flex>

      <Box borderBottom="1px solid" borderColor="surfacePrimaryBorder" p={3}>
        <SearchField label={'Type to search folders'} name="query" value={query} onChange={onFilter} />
      </Box>

      <Flex overflow="auto" height="300px" maxHeight="300px" pb={3}>
        <Scroll
          render={({ pageYOffset, innerHeight }) => (
            <InfiniteScroll
              data={data}
              fetchMore={fetchMore}
              innerHeight={innerHeight}
              pageYOffset={pageYOffset}
              hideListEnd={true}
              path={['service', 'documentFolders']}>
              <FlexItem mx={0} flexGrow={1}>
                {filteredFolders.map((folder) => {
                  const disabled = disabledFolders.includes(folder.id)
                  return (
                    <InteractiveHover
                      disabled={disabled}
                      key={folder.id}
                      onClick={() => (disabled ? null : onSelect(folder))}>
                      <Flex w={1} p={2} height="50px" alignItems="center">
                        <Box px={3}>
                          <span>{folder.name}</span>
                        </Box>
                      </Flex>
                    </InteractiveHover>
                  )
                })}
              </FlexItem>
            </InfiniteScroll>
          )}
        />
        {folders.length === 0 && (
          <Flex alignItems="center" flexDirection="column" height="100%" p={3}>
            <Text.p lineHeight="1.4" mb={2} mt={0}>
              Please create a folder in the Folders tab to add content
            </Text.p>
          </Flex>
        )}
        {filteredFolders.length === 0 && folders.length !== 0 && (
          <Flex alignItems="center" flexDirection="column" height="100%" p={3}>
            <Text.p lineHeight="1.4" mb={2} mt={0}>
              No results for <Text.span bold>{`"${query}"`}</Text.span>
            </Text.p>
            <Text.p lineHeight="1.4" mb={0} mt={0}>
              <Anchor onClick={() => setSearchTerm('')}>Clear your search</Anchor>
              <Text.span medium>&nbsp;to see all results or, try another search.</Text.span>
            </Text.p>
          </Flex>
        )}
      </Flex>
    </>
  )
}

export default React.memo(FolderSelector)
