import React from 'react'

const Video = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="transparent" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.25 9V21.75C23.25 22.5784 22.5784 23.25 21.75 23.25H2.25C1.42157 23.25 0.75 22.5784 0.75 21.75V9"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.25 6H0.75V2.25C0.75 1.42157 1.42157 0.75 2.25 0.75H21.75C22.5784 0.75 23.25 1.42157 23.25 2.25V6Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 6L9 0.75"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 6L16.5 0.75"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.488 18.66C9.22266 18.7923 8.90774 18.7779 8.6556 18.6219C8.40346 18.4658 8.25001 18.1905 8.25 17.894V12.106C8.25001 11.8095 8.40346 11.5341 8.6556 11.3781C8.90774 11.2221 9.22266 11.2076 9.488 11.34L15.277 14.235C15.567 14.3797 15.7502 14.6759 15.7502 15C15.7502 15.324 15.567 15.6202 15.277 15.765L9.488 18.66Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Video
