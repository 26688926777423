/* eslint react/display-name: 0 */
import React from 'react'

import * as R from 'ramda'
import { Block, Change } from 'slate'
import { RenderBlockProps } from 'slate-react'

import { Box } from 'stardust'

import Collage from '~/components/Media/Collage'
import DisplayMedia from '~/components/Media/DisplayMedia'
import MediaDropZone from '~/components/Media/MediaDropZone'

interface RenderNodeCollageData {
  id: string
  type: 'collage'
  mediaSection1?: Playground.MediaItem
  mediaSection2?: Playground.MediaItem
  mediaSection3?: Playground.MediaItem
  mediaSection4?: Playground.MediaItem
}

enum MediaLookup {
  mediaSection1 = 'mediaSection1',
  mediaSection2 = 'mediaSection2',
  mediaSection3 = 'mediaSection3',
  mediaSection4 = 'mediaSection4',
}

interface MediaSelection {
  mediaSection1?: Playground.MediaItem
  mediaSection2?: Playground.MediaItem
  mediaSection3?: Playground.MediaItem
  mediaSection4?: Playground.MediaItem
}

const size = {
  width: '90%',
  height: '470px',
}

const updateNode = (change: Change, node: Block, data: any) => {
  const updatedNode = node.update('data', () => data) as Block
  return change.setNodeByKey(updatedNode.key, updatedNode)
}

export default {
  serialize: (data: RenderNodeCollageData) => {
    return (
      <Collage data={{}} {...size}>
        {R.pipe(
          (data: RenderNodeCollageData) =>
            R.pick(['mediaSection1', 'mediaSection2', 'mediaSection3', 'mediaSection4'], data),
          (values: MediaSelection) => R.values(values),
          (media: (Playground.MediaItem | undefined)[]) =>
            R.map((mediaItem) => mediaItem && <DisplayMedia key={mediaItem.id} {...mediaItem} />, media)
        )(data)}
      </Collage>
    )
  },

  renderNode: ({ attributes, children, editor, node }: RenderBlockProps) => {
    const data = node.data.toJS() as RenderNodeCollageData

    return (
      <>
        <Collage {...size} {...attributes} data={data}>
          {[1, 2, 3, 4].map((idx) => {
            const key = `mediaSection${idx}` as keyof typeof MediaLookup

            return (
              <Box key={`${idx}`} height="100%" width="100%">
                <MediaDropZone
                  media={data[key]}
                  onMediaSelection={(media: Playground.MediaItem) => {
                    editor.controller.change((change) =>
                      updateNode(change, node, {
                        ...data,
                        [key]: media,
                      })
                    )
                  }}
                />
              </Box>
            )
          })}
        </Collage>

        {children}
      </>
    )
  },
}
