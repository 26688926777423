/* eslint react/display-name: 0 */
import React from 'react'

import { Change } from 'slate'
import { RenderBlockProps } from 'slate-react'

import { ToolbarButton } from '~/components/Editors/components/Toolbar'

import { Next, RenderToolbarItemProps } from '../../types'

import { handleChange, hasActiveBlocks, listTypes } from './list'

export default {
  serialize: (
    obj: { object: string; type: string },
    children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined
  ) => {
    if (obj.object === 'block' && obj.type === listTypes.BULLETED_LIST) {
      return <ul>{children}</ul>
    }
  },

  renderToolbarItem: ({ editor, disabled = false }: RenderToolbarItemProps) => {
    const toggleList = (e: { preventDefault: () => void }) => {
      e.preventDefault()
      if (editor) {
        editor.change((c: Change) => handleChange(c, listTypes.BULLETED_LIST))
      }
    }

    const isActive = editor && hasActiveBlocks(editor.value, listTypes.BULLETED_LIST)

    return <ToolbarButton active={isActive} action={toggleList} iconName="bullet" disabled={disabled} />
  },

  renderNode: ({ attributes, children, node }: RenderBlockProps, next: Next) => {
    if (node.type !== listTypes.BULLETED_LIST) return next()

    return <ul {...attributes}>{children}</ul>
  },
}
