import React from 'react'

const SelectRoom = () => (
  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.33939 11.4196C1.84452 11.4196 1.40951 11.0918 1.27307 10.6161C1.13663 10.1404 1.33176 9.63188 1.75139 9.36957L14.0734 1.66691C14.4469 1.43315 14.9234 1.44279 15.2872 1.69147C15.651 1.94016 15.8329 2.38066 15.7507 2.81357L13.3507 15.5956C13.2693 16.0276 12.9405 16.3709 12.5123 16.4706C12.0841 16.5704 11.6373 16.4079 11.3734 16.0562L7.90005 11.4196H2.33939Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.90007 11.4194H6.2334V14.8668C6.23337 15.2777 6.4607 15.655 6.82406 15.847C7.18742 16.0391 7.6272 16.0143 7.96673 15.7828L10.0854 14.3388L7.90007 11.4194Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.89941 11.4195L15.2861 1.69287"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SelectRoom
