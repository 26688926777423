import * as R from 'ramda'
import { Value } from 'slate'

import { isNilOrEmpty } from '~/utils'

const serialize = R.ifElse(isNilOrEmpty, R.always(null), (value) => JSON.stringify(value.toJS()))

const deserialize = R.ifElse(isNilOrEmpty, R.always(null), (value) => Value.fromJSON(JSON.parse(value)))

export default {
  serialize,
  deserialize,
}
