import React, { ReactNode } from 'react'

import rPartial from 'ramda/src/partial'
import { Value } from 'slate'
import { Editor } from 'slate-react'
import styled from 'styled-components'

import { plugins, schema } from '~/modules/textEditor/configs/advanced'
import textStyle from '~/modules/textEditor/style'

import SlimEditor from './SlimEditor'

const SlateEditor = styled(Editor)`
  min-height: 100%;
  padding: 10px;

  ${textStyle};

  @media print {
    h1 {
      margin-top: 0;
    }

    div {
      page-break-inside: avoid;
    }
  }
`

const onChange = (onDataChange: any, editor: Editor) => {
  if (!onDataChange) {
    return
  }

  onDataChange(editor)
}

interface Props {
  autoFocus?: boolean
  placeholder?: string
  readOnly: boolean
  renderEditor?(props: any, next: any): ReactNode
  value: Value
  onBlur?(): void
  onChange?({ value }: { value: Value }): void
  onDataChange?(value: any): void
  onFocus?(): void
}

const TextEditor = React.forwardRef(
  ({ readOnly, value, onDataChange, ...rest }: Props, ref: React.Ref<Editor>) => {
    if (readOnly) {
      return (
        <SlateEditor
          readOnly
          ref={ref}
          plugins={plugins as any}
          schema={schema as any}
          value={value}
          {...rest}
        />
      )
    }

    return (
      <SlateEditor
        autoFocus
        placeholder="At the beginning..."
        plugins={plugins as any}
        ref={ref}
        renderEditor={SlimEditor as any}
        schema={schema as any}
        spellCheck
        value={value}
        onChange={rPartial(onChange, [onDataChange]) as any}
        onDragOver={(e) => e.preventDefault()}
        {...rest}
      />
    )
  }
)

TextEditor.displayName = 'TextEditor'

export default TextEditor
