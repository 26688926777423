import React, { useContext, useMemo, useState } from 'react'

import { Avatar, Card, Flex, Text } from 'stardust'
import styled from 'styled-components'

import FilterDropdown, { FkeyOrId, Id, ProfileGroup, ProfileItem } from '~/components/FilterDropdown'
import { initials } from '~/components/Profiles/core'
import useLocalStorage from '~/hooks/useLocalStorage'
import { LAYERS } from '~/theme'
import { groupObjectsBy, sortObjectsBy } from '~/utils'

import CategoryFilterMenu from '../Common/CategoryFilterMenu'
import { LearningAnalysisContext } from '../Context'

import LimitedList from './LimitedList'

interface Props {
  filters: ProfileGroup<FkeyOrId>[]
  localStorageKey?: string
  records: Playground.LearningRecord[]
}

type DataRow = {
  id: number
  image: string | undefined
  name: string
  tags: number
}

const ScrollableFlex = styled(Flex)`
  overflow-y: auto;
`

const EvidenceRequiredTile = ({ filters, localStorageKey, records }: Props) => {
  const { children, filterChildren, filterRecords } = useContext(LearningAnalysisContext)

  const [categoryFilter, setCategoryFilter] = useState<Nullable<ProfileItem<Id>>>(null)
  const [childFilter, setChildFilter] = useLocalStorage<Nullable<ProfileItem<FkeyOrId>>>(
    null,
    localStorageKey
  )

  const data = useMemo((): DataRow[] => {
    const filteredChildren = filterChildren(children, childFilter)
    const filteredRecords = filterRecords(records, categoryFilter)
    const recordsByChild = groupObjectsBy(filteredRecords, 'childFkey')

    const childrenByName = sortObjectsBy(filteredChildren, 'fullName')
    const childrenWithTags = childrenByName.map((child) => {
      const childRecords = recordsByChild[child.fkey!] || []
      return {
        id: child.id!,
        image: child.image,
        name: child.fullName,
        tags: childRecords.length,
      }
    })

    return sortObjectsBy(childrenWithTags, 'tags')
  }, [categoryFilter, childFilter, children, filterChildren, filterRecords, records])

  const childFilters = useMemo(() => {
    const whitelist = ['group', 'room']
    return filters.filter((filter) => whitelist.includes(filter.type))
  }, [filters])

  return (
    <Card>
      <Flex flexDirection="column" height={340}>
        <Flex alignItems="center" p={3} width={1}>
          <Flex alignItems="center" justifyContent="space-between" width={1}>
            <Flex width="80%">
              <FilterDropdown
                filters={childFilters}
                portal
                selection={childFilter}
                onSelect={setChildFilter}
                zIndex={LAYERS.SectionHeader}
              />
            </Flex>
            <Flex justifyContent="flex-end">
              <Text.span fontSize={0}>Tags</Text.span>
            </Flex>
          </Flex>
        </Flex>

        <ScrollableFlex flexDirection="column" flexGrow={1} width={1}>
          <LimitedList<DataRow> data={data}>
            {(datum: DataRow) => (
              <Flex key={datum.id} alignItems="center" justifyContent="space-between" px={3} py={2}>
                <Flex alignItems="center">
                  <Avatar alt={datum.name} src={datum.image} xxsmall text={initials(datum.name)} />
                  <Text.span fontSize={0} ml={2}>
                    {datum.name}
                  </Text.span>
                </Flex>
                <Text.span fontSize={0}>{datum.tags}</Text.span>
              </Flex>
            )}
          </LimitedList>
        </ScrollableFlex>

        <Flex borderTop="1px solid" borderColor="surfacePrimaryBorder" mx={3} py={2}>
          <Flex flexDirection="column">
            <CategoryFilterMenu value={categoryFilter} onChange={setCategoryFilter} />
          </Flex>
        </Flex>
      </Flex>
    </Card>
  )
}

EvidenceRequiredTile.displayName = 'EvidenceRequiredTile'

export default React.memo(EvidenceRequiredTile)
