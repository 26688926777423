import React from 'react'

import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

import EducatorMetrics from '~/components/Profiles/Metrics/EducatorMetrics'
import { isHome } from '~/config'
import { useSelectedServiceFkey } from '~/contexts/Service'
import { EDUCATOR_METRICS_FRAGMENT } from '~/modules/educators'
import { startToEndMonth } from '~/utils/dateRange'

import MentionedText from './MentionedText'
import MentionedTooltip from './MentionedTooltip'

const GET_EDUCATOR_SUMMARY = gql`
  query EducatorWithMetrics($serviceFkey: String!, $educatorFkey: String!, $dateRange: DateRange!) {
    service(fkey: $serviceFkey) {
      person(fkey: $educatorFkey) {
        fkey
        image
        fullName
        ...EducatorMetrics
      }
    }
  }
  ${EDUCATOR_METRICS_FRAGMENT}
`

interface Props {
  displayName: string
  educatorFkey: string
  readOnly: boolean
}

const EducatorSummary = ({ educatorFkey, displayName, readOnly }: Props) => {
  const serviceFkey = useSelectedServiceFkey()
  const { data, error, loading } = useQuery(GET_EDUCATOR_SUMMARY, {
    skip: !serviceFkey || isHome,
    variables: {
      serviceFkey,
      educatorFkey,
      dateRange: startToEndMonth(new Date()),
    },
  })

  if (loading || error || !data) {
    return <MentionedText>{displayName}</MentionedText>
  }

  const educator = data.service.person as Playground.Educator

  return (
    <MentionedTooltip
      title={educator.fullName}
      image={educator.image}
      link={`/educators/${educator.fkey}`}
      readOnly={readOnly}>
      <EducatorMetrics educator={educator} small />
    </MentionedTooltip>
  )
}

EducatorSummary.displayName = 'EducatorSummary'

export default React.memo(EducatorSummary)
