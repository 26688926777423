import React, { useContext } from 'react'

import { Avatar, Box, Card, Flex, Icon, Text } from 'stardust'

import styled from 'styled-components'

import * as profiles from '~/components/Profiles/core'

import { UserContext } from '~/contexts/User'

import colors from '~/ui-components/tokens/colors'

import DateAndTime from '../View/DateAndTime'
import MediaView from '../View/MediaView'
import Room from '../View/Room'
import Tags from '../View/TagsView'
import UserDetails from '../View/UserDetails'

const S = {
  Flex: styled(Flex)`
    @media print {
      background: white;
    }
  `,
  Icon: styled(Icon)`
    padding: 10px 10px 10px 10px;
    border-radius: 50%;
    background-color: ${colors.cosmicShade3};
    &:hover {
      background-color: ${colors.cosmicShade6};
      transition: background-color 0.2s;
    }
  `,
  Text: styled(Text)`
    color: 'textPrimary';
    max-width: 100%;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  `,
  Card: styled(Card)`
    background-color: 'surfacePrimary';
  `,
}

interface Props {
  description?: string
  date?: string
  time?: string
  title?: string | undefined
  futurePlanning?: string
  planningDate?: string
  media?: Playground.MediaItem[]
  availableRooms: Playground.Room[]
  rooms?: Playground.ObservationRoom[]
  taggedChildren: Playground.SimpleChild[]
  taggedDocuments: Playground.Document[]
  learningOutcomes: Playground.LearningFrameworkOutcome[]
  isSaving?: boolean
}

const PostPreview = ({
  title,
  rooms,
  date,
  time,
  media,
  taggedDocuments,
  futurePlanning,
  planningDate,
  availableRooms,
  description,
  taggedChildren,
  learningOutcomes,
  isSaving,
}: Props) => {
  const { state: user } = useContext(UserContext)
  const roomFkey = rooms && rooms.length > 0 && rooms[0]?.roomFkey
  const roomName = availableRooms && availableRooms.find((r) => r.fkey === roomFkey)?.name

  return (
    <S.Flex alignItems="flex-start" justifyContent="center" bg="surfaceSecondary" p="50px 0 50px">
      <S.Card borderRadius={2} p="5mm 5mm" width="40vw" height="fit-content" minHeight="100vh" minWidth="0">
        <Flex>
          <Avatar
            alt={`${user?.displayName}'s Profile`}
            src={user?.image}
            text={profiles.initials(user?.displayName || '')}
            xsmall
          />
          <Box ml={2} width="100%">
            <Box mb={2}>
              <Flex justifyContent="space-between">
                <UserDetails fullName={user?.displayName} userType={user?.roles[0]} />
                <Flex alignItems="center"></Flex>
              </Flex>
            </Box>
            {date && time && <DateAndTime date={date} time={time} />}
            {roomName && <Room room={roomName} />}
          </Box>
        </Flex>
        <Text fontSize={'20px'} fontWeight={600} mt={3}>
          {title}
        </Text>
        <S.Text mt={3} fontSize={1} lineHeight="130%">
          {description}
        </S.Text>
        <Box maxWidth="100%">
          {media && media.length > 0 && <MediaView postMedia={media} title={title || ''} />}
        </Box>
        <Box maxWidth="100%">
          <Tags
            taggedChildren={taggedChildren}
            learningOutcomes={learningOutcomes}
            futurePlans={futurePlanning}
            planningDate={planningDate}
            links={taggedDocuments}
          />
        </Box>
        {isSaving && (
          <>
            {!roomFkey && (
              <Flex alignItems="center" ml={4}>
                <Icon name="warning" color={colors.superGiantRed5} mr={1} />
                <Text fontSize="14px" color={colors.superGiantRed5}>
                  Room is required
                </Text>
              </Flex>
            )}
            {!title && (
              <Flex alignItems="center" ml={4}>
                <Icon name="warning" color={colors.superGiantRed5} mr={1} />
                <Text fontSize="14px" color={colors.superGiantRed5}>
                  Title is required
                </Text>
              </Flex>
            )}
          </>
        )}
      </S.Card>
    </S.Flex>
  )
}

PostPreview.displayName = 'PostPreview'

export default React.memo(PostPreview)
