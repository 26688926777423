import { useEffect } from 'react'

import * as R from 'ramda'

const isOrContains = R.curry(
  (target, ref) => ref.current && (ref.current === target || ref.current.contains(target))
)

type Handler = (event?: any) => void

function useClickedOutside(handler: Handler, ...refs: any[]) {
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const listener = (event: any) => {
      // Do nothing if clicking ref's element or descendent elements
      if (R.any(isOrContains(event.target), refs)) {
        return
      }

      handler(event)
    }

    document.addEventListener('mousedown', listener, true)
    document.addEventListener('touchstart', listener, true)

    return () => {
      document.removeEventListener('mousedown', listener, true)
      document.removeEventListener('touchstart', listener, true)
    }
  }, [handler, ...refs])
  /* eslint-enable react-hooks/exhaustive-deps */
}

export default useClickedOutside
