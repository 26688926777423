import React from 'react'

import { Image } from 'stardust'

import styled from 'styled-components'

interface Props {
  postMedia: Playground.MediaItem[]
  isPrintPreview?: boolean
  onMediaClick: (id: number) => void
}

const S = {
  Image: styled(Image)`
    border-radius: ${(props) => props.borderRadius};
    aspect-ratio: 334/672;
    object-fit: cover;
    &:hover {
      filter: brightness(90%);
      transition: background-color 0.3s;
    }
  `,
}

const TwoMedia = ({ postMedia, isPrintPreview = false, onMediaClick }: Props) => {
  const getMediaCoverUrl = (media: Playground.MediaItem) =>
    media.mimeType?.includes('video') ? media.coverUrl : media.url

  return (
    <>
      <S.Image
        width="50%"
        pr="1px"
        borderRadius={!isPrintPreview ? '15px 0 0 15px' : '0'}
        src={getMediaCoverUrl(postMedia[0])}
        onClick={() => onMediaClick(postMedia[0]?.id)}
      />
      <S.Image
        width="50%"
        pl="1px"
        borderRadius={!isPrintPreview ? '0 15px 15px 0' : '0'}
        src={getMediaCoverUrl(postMedia[1])}
        onClick={() => onMediaClick(postMedia[1]?.id)}
      />
    </>
  )
}

TwoMedia.displayName = 'TwoMedia'

export default React.memo(TwoMedia)
