import React from 'react'
import { useState } from 'react'

import { useMutation } from '@apollo/client'

import { noop } from 'lodash'

import { TextField, MainButton, Heading, Text, Box } from 'stardust'

import styled from 'styled-components'

import { COPY_SERVICE_LAYOUT } from '~/components/Layouts/mutations'

import Modal from '~/components/Modal'

import { isValidServiceFkey } from '~/utils'

const EXAMPLE_INPUT_TEMPLATE =
  'Valid examples: xplor-46, qk1234-3a0d4b0a-b0a8-ec11-8112-00155d05d338, discover-f67f2eeb-731d-4787-99da-7122b5c70ba4'

const ModalContentStyled = styled.div`
  max-width: 60vw;
  padding: 20px;
`
const ModalInputStyled = styled.div`
  margin-bottom: 20px;
`

const onHandleCopyTemplateModal = {
  open: noop,
  close: noop,
}

const CopyTemplateModal = () => {
  const [openModal, setOpenModal] = useState(false)
  const [inputServiceFkey, setInputServiceFkey] = useState('')
  const [layout, setLayout] = useState<Playground.LearningTemplate>()
  const [copyServiceLayout] = useMutation(COPY_SERVICE_LAYOUT)
  const [submitting, setSubmitting] = useState(false)
  const [successServiceFkey, setSuccessServiceFkey] = useState('')

  const headingText = (templateTitle = '') => {
    if (templateTitle && templateTitle.length === 0) return
    return (
      <Box mb={3}>
        <Heading.h4 bold mb={0} mt={0}>
          {`Copying Template ${templateTitle}`}
        </Heading.h4>
        <Text.p>Note: Any media associated with this template will NOT work in the new service.</Text.p>
        <Text.p>Please paste the new service fkey to copy this template to another service</Text.p>
      </Box>
    )
  }

  const successCopiedTemplate = (serviceFkey: string) => (
    <Text.p bold>{`Template has been copied to service ${serviceFkey}`}</Text.p>
  )

  onHandleCopyTemplateModal.open = (layout: Playground.LearningTemplate) => {
    setOpenModal(true)
    setLayout(layout)
  }

  onHandleCopyTemplateModal.close = () => {
    setOpenModal(false)
  }

  const handleSetInputServiceFkey = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputServiceFkey(event.currentTarget.value)
  }

  const handleClick = async () => {
    if (isValidServiceFkey(inputServiceFkey)) {
      const params = {
        variables: { templateId: layout?.id, serviceFkey: inputServiceFkey },
      }
      setSubmitting(true)
      const { serviceFkey } = await (await copyServiceLayout(params))?.data?.copyServiceTemplate
      setSuccessServiceFkey(serviceFkey)
      setSubmitting(false)
      setInputServiceFkey('')
    }
  }

  return (
    <Modal
      open={openModal}
      onClose={() => {
        setOpenModal(false)
      }}>
      <ModalContentStyled>
        {headingText(layout?.title)}
        <ModalInputStyled>
          <TextField value={inputServiceFkey} onChange={handleSetInputServiceFkey} />
        </ModalInputStyled>
        <Box mb={3}>{EXAMPLE_INPUT_TEMPLATE}</Box>
        <Box mb={3}>
          <MainButton disabled={!isValidServiceFkey(inputServiceFkey)} onClick={handleClick}>
            {submitting ? 'Submitting' : 'Click to copy template'}
          </MainButton>
        </Box>
        {successServiceFkey.length > 0 && successCopiedTemplate(successServiceFkey)}
      </ModalContentStyled>
    </Modal>
  )
}

export { CopyTemplateModal as default, onHandleCopyTemplateModal }
