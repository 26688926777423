import * as R from 'ramda'

import { VALID_FILE_TYPES } from '~/modules/media'

const MAX_FILE_SIZE = 262144000 // 250MB
const MAX_DOCUMENT_SIZE = 5242880 // 5MB
const MAX_VIDEO_DURATION = 1800 // 30 minutes
const GENERIC_ERROR = {
  name: 'Error',
  type: 'Something went wrong. Try another file, or try refreshing your browser',
}

const isValidFileSize = ({ size }, maxSize) => size < maxSize
const isValidDocumentSize = ({ size }, maxSize) => size < maxSize
const isValidMIMEType = ({ type }) => VALID_FILE_TYPES.includes(type)

// Function to check if the video duration is within the allowed limit
const isVideoDurationValid = (file) => {
  return new Promise((resolve) => {
    const video = document.createElement('video')
    video.preload = 'metadata'

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src)
      resolve(video.duration <= MAX_VIDEO_DURATION)
    }

    video.src = URL.createObjectURL(file)
  })
}

// Validates and uploads a single file. This function must return a resolved
// promise, so that Promise.all can process it correctly (Promise.all cannot
// handle rejected promises effectively). This function returns a resolved
// "error" object on error, or a resolved "null" on success.
const handleFile = async (file, serviceFkey, uploadMutation, handleUploadedMedia) => {
  const metadataObject = {
    filesize: file.size,
    date: file.lastModified ? file.lastModified : new Date(),
  }
  const metadataJson = JSON.stringify(metadataObject)

  if (!isValidMIMEType(file)) {
    return Promise.resolve({
      name: file.name,
      type: 'Invalid file type (Only images/videos/audio, PDF, Word, Excel and txt accepted)',
    })
  }

  // Check for document size to be less than 5MB
  if (
    (file.type.startsWith('application/') || file.type.startsWith('text/')) &&
    !isValidDocumentSize(file, MAX_DOCUMENT_SIZE)
  ) {
    return Promise.resolve({
      name: file.name,
      type: 'Document size exceeds 5MB.',
      errorMessage: 'Please choose a smaller file and try again.',
    })
  }

  if (!isValidFileSize(file, MAX_FILE_SIZE)) {
    return Promise.resolve({
      name: file.name,
      type: 'File size exceeds 250MB.',
      errorMessage: 'Please choose a smaller file and try again.',
    })
  }

  // Check for video duration if it's a video file
  if (file.type.startsWith('video/')) {
    const isDurationValid = await isVideoDurationValid(file)
    if (!isDurationValid) {
      return Promise.resolve({
        name: file.name,
        type: 'Maximum 30 second video allowed.',
        errorMessage: 'Please select a shorter video.',
      })
    }
  }

  return uploadMutation({ variables: { file, serviceFkey, metadata: metadataJson } })
    .then((result) => {
      const { id, coverUrl, url, metadata } = R.path(['data', 'uploadMedia'], result)

      handleUploadedMedia && handleUploadedMedia({ coverUrl, url, id, metadata, mimeType: file.type })

      return Promise.resolve()
    })
    .catch(() => {
      return Promise.resolve({
        name: file.name,
        type: 'Media failed to upload',
      })
    })
}

// Fires off multiple `handleFile`s asynchronously inside a Promise.all -
// Promise.all only handles "resolved" promises well - as such all errors
// returned from `handleFile` are actually "resolved" promises. This function
// will return a list of errors, or an empty list
const uploadMultipleFiles = (files, serviceFkey, uploadMutation, handleUploadedMedia) => {
  // Promise.all ensures that all returned errors update state "errors" at
  // once. Else, async calls can race condition one another
  return Promise.all(files.map((file) => handleFile(file, serviceFkey, uploadMutation, handleUploadedMedia)))
    .then((uploadErrors) =>
      // Filter out "nulls" to only return errors
      uploadErrors.filter((err) => err)
    )
    .catch(() =>
      // Return generic error if critical error occurs
      [GENERIC_ERROR]
    )
}

export default uploadMultipleFiles
