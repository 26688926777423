import React, { useMemo, useState } from 'react'

import { Box, Text } from 'stardust'

import AssetViewerModal, { NO_ASSET_SELECTED } from '~/pages/Observations/components/AssetViewerModal'
import ContentAsset from '~/pages/Observations/components/ContentAsset'

import { sortObjectsBy } from '~/utils'

interface Props {
  learningContentAssets: Playground.LearningContentAsset[]
}

const LearningContentAssets = ({ learningContentAssets }: Props) => {
  const sorted = useMemo(() => sortObjectsBy(learningContentAssets, 'title'), [learningContentAssets])
  const [modalShowId, changeModalShowId] = useState(NO_ASSET_SELECTED)

  return (
    <Box data-test="document-learning-content-assets">
      <Text bold mb={3} fontSize={2}>
        Related Learning:
      </Text>
      {sorted.map((asset) => (
        <ContentAsset
          key={asset.id}
          asset={asset}
          expandToMaxWidth={true}
          small={true}
          onShowDetails={() => changeModalShowId(asset.id)}
        />
      ))}

      <AssetViewerModal id={modalShowId} changeId={changeModalShowId} />
    </Box>
  )
}

LearningContentAssets.displayName = 'DocumentRendererLearningContentAssets'

export default React.memo(LearningContentAssets)
