import React from 'react'

import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

import RoomMetrics from '~/components/Profiles/Metrics/RoomMetrics'
import { isHome } from '~/config'
import { useSelectedServiceFkey } from '~/contexts/Service'
import { ROOM_INFO_FRAGMENT, ROOM_METRICS_FRAGMENT } from '~/modules/rooms'

import { startToEndMonth } from '~/utils/dateRange'

import MentionedText from './MentionedText'
import MentionedTooltip from './MentionedTooltip'

const GET_ROOM_SUMMARY = gql`
  query RoomWithMetrics($serviceFkey: String!, $fkey: String!, $dateRange: DateRange!) {
    service(fkey: $serviceFkey) {
      room(fkey: $fkey) {
        ...RoomInfo
        ...RoomMetrics
      }
    }
  }
  ${ROOM_METRICS_FRAGMENT}
  ${ROOM_INFO_FRAGMENT}
`

interface Props {
  displayName: string
  readOnly: boolean
  roomFkey: string
}

const RoomSummary = ({ displayName, readOnly, roomFkey }: Props) => {
  const serviceFkey = useSelectedServiceFkey()

  const { data, error, loading } = useQuery(GET_ROOM_SUMMARY, {
    skip: !serviceFkey || isHome,
    variables: {
      serviceFkey: serviceFkey,
      fkey: roomFkey,
      dateRange: startToEndMonth(new Date()),
    },
  })

  if (loading || error || !data) {
    return <MentionedText>{displayName}</MentionedText>
  }

  const room = data.service.room as Playground.Room

  return (
    <MentionedTooltip title={displayName} link={`/rooms/${room.fkey}`} readOnly={readOnly}>
      <RoomMetrics room={room} />
    </MentionedTooltip>
  )
}

RoomSummary.displayName = 'RoomSummary'

export default React.memo(RoomSummary)
