import React from 'react'

const Archive = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.75 10.25V19.25C21.75 20.9069 20.4069 22.25 18.75 22.25H5.25C3.59315 22.25 2.25 20.9069 2.25 19.25V10.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.75 2.75H2.25C1.42157 2.75 0.75 3.42157 0.75 4.25V7.25H23.25V4.25C23.25 3.42157 22.5784 2.75 21.75 2.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 11.75C15 12.5784 14.3284 13.25 13.5 13.25H10.5C9.67157 13.25 9 12.5784 9 11.75C9 10.9216 9.67157 10.25 10.5 10.25H13.5C14.3284 10.25 15 10.9216 15 11.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Archive
