import React from 'react'

const ClipPaths = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="0" height="0">
    <defs>
      <clipPath id="leaf-clip-path--left" clipPathUnits="objectBoundingBox">
        <path
          transform="translate(1, 0) scale(-1 1)"
          d="m0.39529581999999996,0.033103560000000004c-0.03124627,0.005396809999999999 -0.12251177,0.046797639999999995 -0.15229423,0.06365021c-0.09818094000000001,0.07829162 -0.1253387,0.08439913 -0.17844215,0.22298003000000002c-0.06299745,0.19705815000000002 0.01574936,0.39411644 0.01574936,0.4120308c-0.06592519000000001,0.12540069 -0.07874681,0.23288702 -0.07874681,0.23288702c-0.00789986,0.04185888 0.04346227,0.04134258 0.04724808999999999,0.0179145s0.01574936,-0.08957195999999999 0.0787467,-0.19705828c0.0173394,0.01685257 0.11665616999999999,0.045979469999999995 0.1698608,0.048921229999999996a0.67722213,0.7703186399999999 0 0 0 0.25537164,-0.048921229999999996c0.34648574000000004,-0.14331519 0.44098191999999997,-0.59117485 0.44098191999999997,-0.60908922c0.0035335899999999996,-0.02012566 -0.06319932,-0.07298251 -0.0830879,-0.0832889s-0.21614976,-0.14959812 -0.51538741,-0.060026159999999995z"
        />
      </clipPath>
      <clipPath id="leaf-clip-path--right" clipPathUnits="objectBoundingBox">
        <path d="m0.39529581999999996,0.033103560000000004c-0.03124627,0.005396809999999999 -0.12251177,0.046797639999999995 -0.15229423,0.06365021c-0.09818094000000001,0.07829162 -0.1253387,0.08439913 -0.17844215,0.22298003000000002c-0.06299745,0.19705815000000002 0.01574936,0.39411644 0.01574936,0.4120308c-0.06592519000000001,0.12540069 -0.07874681,0.23288702 -0.07874681,0.23288702c-0.00789986,0.04185888 0.04346227,0.04134258 0.04724808999999999,0.0179145s0.01574936,-0.08957195999999999 0.0787467,-0.19705828c0.0173394,0.01685257 0.11665616999999999,0.045979469999999995 0.1698608,0.048921229999999996a0.67722213,0.7703186399999999 0 0 0 0.25537164,-0.048921229999999996c0.34648574000000004,-0.14331519 0.44098191999999997,-0.59117485 0.44098191999999997,-0.60908922c0.0035335899999999996,-0.02012566 -0.06319932,-0.07298251 -0.0830879,-0.0832889s-0.21614976,-0.14959812 -0.51538741,-0.060026159999999995z" />
      </clipPath>
      <clipPath id="heart-clip-path" clipPathUnits="objectBoundingBox">
        <path d="M0.5,1L0.48607337,0.9868164C0.459663717,0.9616211 0.423913043,0.93427735 0.382472826,0.902636725C0.221042804,0.779296875 0,0.610449225 0,0.3225586C0,0.144726575 0.126783283,0 0.282608696,0C0.367272413,0 0.446586283,0.0430664 0.5,0.116601575C0.5534137170000001,0.0430664 0.632727587,0 0.717391304,0C0.8732167169999999,0 1,0.144726575 1,0.3225586C1,0.610449225 0.778957196,0.779296875 0.617527174,0.902636725C0.576086957,0.93427735 0.5403362829999999,0.9616211 0.5139266299999999,0.9868164L0.5,1 Z" />
      </clipPath>
      <clipPath id="cog-clip-path" clipPathUnits="objectBoundingBox">
        <path d="m0.9953002999999999,0.4315092l-0.0858545,-0.0287a0.41866329999999996,0.4186638 0 0 0 -0.0511727,-0.12363640000000001l0.040381799999999995,-0.0809455a0.5031177,0.5031183 0 0 0 -0.0968908,-0.09688179999999999l-0.0809545,0.040381799999999995a0.4181087,0.4181092 0 0 0 -0.12361810000000001,-0.0511909l-0.0286999,-0.0858455a0.505145,0.5051456000000001 0 0 0 -0.06849999999999999,-0.0046909000000000005c-0.0232454,0 -0.046090900000000004,0.0016273000000000001 -0.0684999,0.0046909000000000005l-0.0287,0.0858455a0.41827240000000004,0.4182729 0 0 0 -0.12362719999999999,0.0511909l-0.08094530000000001,-0.040381799999999995a0.5022632,0.5022638 0 0 0 -0.09687269999999999,0.09688179999999999l0.040381799999999995,0.0809455a0.4179633,0.4179638 0 0 0 -0.0511818,0.12363640000000001l-0.0858544,0.0287a0.5035814,0.503582 0 0 0 0,0.1369819l0.0858544,0.0287a0.4184269,0.4184274 0 0 0 0.0511727,0.12363640000000001l-0.0403636,0.0809455a0.5022632,0.5022638 0 0 0 0.0968726,0.09688179999999999l0.0809454,-0.040381799999999995a0.41827240000000004,0.4182729 0 0 0 0.12362719999999999,0.0511909l0.0287,0.08583640000000001c0.0223999,0.0030727 0.0452545,0.004699999999999999 0.0684999,0.004699999999999999c0.0232454,0 0.046090900000000004,-0.0016273000000000001 0.0684999,-0.004699999999999999l0.0287,-0.08583640000000001a0.4183451,0.41834560000000004 0 0 0 0.12361810000000001,-0.0511818l0.0809545,0.040372700000000004a0.5025086000000001,0.5025092999999999 0 0 0 0.0968726,-0.09688179999999999l-0.0403636,-0.0809455a0.4186724,0.41867289999999996 0 0 0 0.0511727,-0.12363640000000001l0.0858545,-0.0287c0.0030636,-0.022390900000000002 0.0046909000000000005,-0.0452546 0.0046909000000000005,-0.06849090000000001s-0.0016273000000000001,-0.0461001 -0.004699999999999999,-0.068491z" />
      </clipPath>
    </defs>
  </svg>
)

ClipPaths.displayName = 'ClipPaths'
export default ClipPaths
