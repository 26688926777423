import gql from 'graphql-tag'

export default gql`
  fragment ObservationLearningContentAssets on Observation {
    learningContentAssets {
      id
      title
      type
      documentLength
    }
  }
`
