import React, { ReactElement } from 'react'

import { useQuery } from '@apollo/client'
import { navigate } from '@reach/router'

import { Box, Flex, Icon, SecondaryButton, Text } from 'stardust'

import Error404 from '~/components/404'
import DocumentModalPreview, {
  EXPANDED_PREVIEW_HEIGHT,
  STACKED_PREVIEW_HEIGHT,
} from '~/components/DocumentModalPreview'
import DocumentRenderer from '~/components/DocumentRenderer'
import MediaRenderer from '~/components/MediaRenderer'
import { useSelectedServiceFkey } from '~/contexts/Service'
import { sendAppSignalError } from '~/logging'
import { is404 } from '~/modules/api'
import { formatTimestamp } from '~/utils'

import { GET_MOMENT } from './queries'

interface MomentModalProps {
  id: number
  onLoadComponent: ReactElement
}

const MomentRenderer = ({ id, onLoadComponent }: MomentModalProps) => {
  const serviceFkey = useSelectedServiceFkey()

  const { data, error, loading } = useQuery(GET_MOMENT, {
    variables: { momentId: id, serviceFkey },
  })

  if (error) {
    if (is404(error)) {
      return <Error404 message="Moment not found" />
    }

    sendAppSignalError(error)

    throw error
  }

  const moment = data?.context?.moment as Playground.Moment | undefined

  if (loading || !moment) {
    return onLoadComponent
  }

  const parent = moment.contributors[0]
  const timestamp = formatTimestamp(moment.insertedAt)

  return (
    <DocumentRenderer
      caption={moment.caption}
      documentId={id}
      documentType="moment"
      footer={
        <Flex justifyContent="space-between" alignItems="center" width={1}>
          <Text.p fontSize={1} ml={3} medium>
            Edited at: {timestamp}
          </Text.p>
          <Box pr={1}>
            <SecondaryButton onClick={() => navigate(`/moments/${id}`)}>
              <Flex alignItems="center" justifyContent="center">
                <Icon name="fullscreen" mr={1} fill="primary" />
                Full Screen
              </Flex>
            </SecondaryButton>
          </Box>
        </Flex>
      }
      heading={`Moment ${timestamp}`}
      location={moment.location}
      overline={parent.fullName}
      taggedChildren={moment.children}>
      <DocumentModalPreview>
        <MediaRenderer
          height={[STACKED_PREVIEW_HEIGHT, EXPANDED_PREVIEW_HEIGHT]}
          mediaItems={moment.media}
          title={timestamp}
        />
      </DocumentModalPreview>
    </DocumentRenderer>
  )
}

MomentRenderer.displayName = 'MomentRenderer'

export default React.memo(MomentRenderer)
