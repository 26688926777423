import { Flex } from 'stardust'
import styled from 'styled-components'

import { LAYERS, ROOT_SIDEBAR_WIDTH } from '~/theme'

const Sidebar = styled(Flex)`
  flex-direction: column;
  flex: 0 0 246px;
  height: 100vh;
  max-width: ${ROOT_SIDEBAR_WIDTH};
  position: sticky;
  top: 0;
  width: 100%;
  z-index: ${LAYERS.Sidebar};
`

Sidebar.displayName = 'Content'

export default Sidebar
