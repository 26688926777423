import React from 'react'

const Clipboard = () => (
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.537 6.814h1.375v4.643H4.537z" />
    <path d="M2.984 8.428h4.469v1.43h-4.47z" />
    <path
      fillRule="nonzero"
      d="M8.566 15.357H1.884c-.99 0-1.788-.828-1.788-1.857V4.071c0-1.028.798-1.857 1.788-1.857H8.58c.99 0 1.787.829 1.787 1.857v9.443c-.013 1.015-.81 1.843-1.8 1.843zM1.884 3.071c-.536 0-.963.443-.963 1v9.443c0 .557.426 1 .963 1H8.58c.536 0 .963-.443.963-1V4.071c0-.557-.427-1-.963-1H1.884z"
    />
    <path d="M2.956 4.5a.413.413 0 0 1-.412-.429c0-.242.179-.428.412-.428h4.538c.234 0 .412.186.412.428a.413.413 0 0 1-.412.429H2.956z" />
    <path
      fillRule="nonzero"
      d="M5.225 2.929c-.756 0-1.375-.643-1.375-1.429S4.469.071 5.225.071 6.6.714 6.6 1.5s-.619 1.429-1.375 1.429zm0-2a.563.563 0 0 0-.55.571c0 .314.248.571.55.571.303 0 .55-.257.55-.571a.563.563 0 0 0-.55-.571z"
    />
  </svg>
)

export default Clipboard
