import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'

import config, { isDev } from '~/config'

export async function initialize() {
  ReactGA.initialize([
    {
      trackingId: config.GOOGLE_ANALYTICS_PROPERTY || '',
      gaOptions: {
        sampleRate: 100,
        siteSpeedSampleRate: 5,
        debug_mode: isDev,
      },
    },
  ])
}

const tagManagerArgs = {
  gtmId: config.GOOGLE_TAG_MANAGER_ID || '',
}

export async function initializeGoogleTagManager() {
  TagManager.initialize(tagManagerArgs)
}
