import gql from 'graphql-tag'

export const GET_MEDIA_BY_TYPE = gql`
  query Media(
    $cursor: String
    $serviceFkey: String!
    $type: MediaType
    $child_fkey: String
    $room_fkey: String
    $dateRange: UtcDatetimeRange
  ) {
    service(fkey: $serviceFkey) {
      fkey
      media(
        first: 25
        after: $cursor
        sortBy: { field: "inserted_at", order: "desc" }
        mediaType: $type
        child_fkey: $child_fkey
        room_fkey: $room_fkey
        datetimeRange: $dateRange
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            coverUrl
            id
            insertedAt
            mimeType
            path
            serviceFkey
            thumbUrl
            updatedAt
            url
            metadata
          }
        }
      }
    }
  }
`

export const GET_MEDIA_ITEM = gql`
  query MediaItem($id: Int!, $serviceFkey: String!) {
    context(fkey: $serviceFkey) {
      fkey
      mediaItem(id: $id) {
        coverUrl
        id
        mimeType
        thumbUrl
        url
        metadata
      }
    }
  }
`

export const GET_ROOMS = gql`
  query RoomsSummary($serviceFkey: String!) {
    service(fkey: $serviceFkey) {
      fkey
      rooms {
        fkey
        name
      }
    }
  }
`

export const GET_CHILDREN = gql`
  query ChildrenSummary($serviceFkey: String!, $roomFkey: String) {
    service(fkey: $serviceFkey) {
      fkey
      children(roomFkey: $roomFkey) {
        fullName
        fkey
      }
    }
  }
`
