import React from 'react'

import { Flex } from 'stardust'

import PostTextField from '~/ui-components/PostTextField'

import { FieldProps } from '../../types'

interface DescriptionProps extends FieldProps {
  description?: string
  borderRadius?: string | number
}

const ModalDescription = ({ onChange, description }: DescriptionProps) => {
  const characterLimit = 15000

  return (
    <Flex flex={1} mb={1}>
      <PostTextField
        borderRadius={1}
        valid
        placeholder="Description"
        name="description"
        maxLength={characterLimit}
        textarea
        value={description}
        onChange={(e) =>
          onChange({
            name: 'description',
            value: e.target.value.trimStart(),
          })
        }
      />
    </Flex>
  )
}
ModalDescription.displayName = 'ModalDescription'

export default React.memo(ModalDescription)
