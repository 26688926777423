import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import MediaAttachmentPlay from '~/ui-components/icons/MediaAttachmentPlay'
import colors from '~/ui-components/tokens/colors'
import { StylingUnit } from '~/utils/StylingUnit'

import { PostAttachmentFileProps } from '../attachmentTypes'

// From designs. No considerations given to scaling for this component,
// it's small enough that it will look appropriate on most screens.
const mediaSquareDimensions = new StylingUnit(71, 'px')
const mediaBorderRadius = new StylingUnit(16, 'px')

const MediaAttachmentContainer = styled.div`
  position: relative;
  width: ${mediaSquareDimensions.unit()};
  height: ${mediaSquareDimensions.unit()};
  border-radius: ${mediaBorderRadius.unit()};
  overflow: hidden;
  cursor: pointer;

  svg {
    transition: 0.2s ease-in-out;
  }

  &:hover svg {
    fill: ${colors.neptuneBlue4};
  }
`

const DarkenThumbnailOverlay = styled.div<{ forVideoThumbnail: boolean }>`
  position: absolute;
  width: ${mediaSquareDimensions.unit()};
  height: ${mediaSquareDimensions.unit()};
  border-radius: ${mediaBorderRadius.unit()};
  background-color: ${colors.nebulaBlue9};
  opacity: ${(props) => (props.forVideoThumbnail ? '0.5' : '0.1')};
`

const MediaThumbnailPreview = styled.img`
  border-radius: ${mediaBorderRadius.unit()};
  width: 100%;
  height: 100%;
`

const FailedToLoadMediaThumbnail = styled.div`
  background-color: ${colors.nebulaBlue9};
  width: 100px;
  height: 100px;
`

const MediaAttachmentIconContainer = styled.div`
  position: absolute;
  top: 23px;
  left: 27px;
`

export const MediaAttachmentPreview = ({ file: file }: PostAttachmentFileProps) => {
  const [validImage, setValidImage] = useState(true)
  const [isVideo, setIsVideo] = useState(false)

  useEffect(() => {
    setIsVideo(file.mimeType.includes('video'))
  }, [file])

  return (
    <MediaAttachmentContainer>
      {validImage ? (
        <>
          <DarkenThumbnailOverlay forVideoThumbnail={isVideo} />
          {isVideo && (
            <>
              <MediaAttachmentIconContainer>
                <MediaAttachmentPlay />
              </MediaAttachmentIconContainer>
            </>
          )}
          <MediaThumbnailPreview src={file.coverUrl} onError={() => setValidImage(false)} />
        </>
      ) : (
        <FailedToLoadMediaThumbnail>
          {/* TODO: Put a lil' broken image icon here. We'll need to grab one from the design team */}
        </FailedToLoadMediaThumbnail>
      )}
    </MediaAttachmentContainer>
  )
}
