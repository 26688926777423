import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import { FileAttachment } from './FileAttachment'

interface FileAttachmentsProps {
  files: Playground.MediaItem[]
  forPostCreation?: boolean
  onDetachClickCallback?: (id: number) => void
}

const FileAttachmentList = styled.div`
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 4px;
`

/**
 * Takes the most recent list of postAttachmentFile types and returns a list of
 * FileAttachment components
 * @param files
 * @returns A list of FileAttachment components
 */
const AssembleFileAttachmentsList = (
  files: Playground.MediaItem[],
  onDetachClickCallback?: (id: number) => void,
  forPostCreation?: boolean
): JSX.Element[] => {
  const newFileAttachments = []
  for (let i = 0; i < files.length; i++) {
    newFileAttachments.push(
      <FileAttachment
        file={files[i]}
        forPostCreation={forPostCreation}
        key={files[i].id}
        onDetachClickCallback={onDetachClickCallback ? onDetachClickCallback : (id: number) => null}
      />
    )
  }
  return newFileAttachments
}

export const FileAttachments = ({ files, forPostCreation, onDetachClickCallback }: FileAttachmentsProps) => {
  const [fileAttachments, setFileAttachments] = useState(
    AssembleFileAttachmentsList(files, onDetachClickCallback, forPostCreation)
  )

  useEffect(() => {
    setFileAttachments(AssembleFileAttachmentsList(files, onDetachClickCallback, forPostCreation))
  }, [files, forPostCreation, onDetachClickCallback])

  return <FileAttachmentList>{fileAttachments}</FileAttachmentList>
}
