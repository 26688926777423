import React from 'react'

import { Switch } from '@rmwc/switch'

interface ThemedSwitchProps {
  name?: string
  checked: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  children?: React.ReactNode
  disabled?: boolean
  id?: string
}

const ThemedSwitch = ({ children, ...props }: ThemedSwitchProps) => <Switch {...props}>{children}</Switch>

ThemedSwitch.displayName = 'Switch'
export default ThemedSwitch
