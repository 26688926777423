import React, { useCallback, useMemo, useState } from 'react'

import { RouteComponentProps, navigate } from '@reach/router'

import format from 'date-fns/format'

import { Box, Flex, Icon, SecondaryButton, Text } from 'stardust'
import styled from 'styled-components'

import ArticleStatusBanner from '~/components/ArticleStatusBanner'
import Can from '~/components/Authorisation/Can'
import DocumentModal from '~/components/DocumentModal'
import DocumentModalPlaceholder from '~/components/DocumentModalPlaceholder'
import DocumentModalPreview from '~/components/DocumentModalPreview'
import DocumentRenderer from '~/components/DocumentRenderer'
import ModalButton from '~/components/ModalButton'
import { isHome, isPlayground } from '~/config'
import { useSelectedServiceFkey } from '~/contexts/Service'
import { learningTables as tablePermissions } from '~/modules/permissions'

import { GET_LEARNING_TABLE_DETAIL } from './queries'
import { ReadOnlyCell, Table, TableLoader } from './Table'
import { TableState } from './types'
import { getCellKey } from './utils'

const TablePreview = styled.div<{ height: Nullable<number>; scale: number }>`
  height: ${(props) => (props.height ? `${props.height}px` : 'auto')};
  transform: scale(${(props) => props.scale});
  transform-origin: left top;
`

interface TableModalProps {
  contentStatus: Playground.DocumentStatus
  show: boolean
  onClose: () => void
  article: Playground.Article
  articleContent: Playground.ArticleContent
  table: TableState
}

interface LoaderProps extends RouteComponentProps<{ id: number }> {
  contentStatus: Playground.DocumentStatus
  show: boolean
  onClose: () => void
}

const TableModal = ({ article, articleContent, table, onClose, show }: TableModalProps) => {
  const [tableHeight, setTableHeight] = useState<Nullable<number>>(null)
  const [tableScale, setTableScale] = useState(1)

  const creator = article.contributors.find((x) => x.action === 'created')

  const calculateTableScale = (node: HTMLDivElement) => {
    if (node) {
      const scale = node.clientWidth / node.scrollWidth
      if (scale < 1 && !tableHeight) {
        setTableHeight(scale * node.scrollHeight)
        setTableScale(scale)
      }
    }
  }

  const modalHeaderButtons = () => (
    <Flex alignItems="center" flexDirection="row">
      <ModalButton
        onClick={() => {
          onClose()
          navigate(`/tables/${article.id}/print`)
        }}
        icon={'printer'}
        text={'Print'}
      />

      <Can
        perform={tablePermissions.write}
        yes={() => (
          <ModalButton
            onClick={() => {
              onClose()
              navigate(`/tables/${article.id}/edit`)
            }}
            icon={'editOutlined'}
            text={'Edit'}
          />
        )}
      />
    </Flex>
  )

  const onCellRender = useCallback(
    ({ column, row }) => {
      const cellKey = getCellKey(column.id, row.id)
      return <ReadOnlyCell cell={table.cells[cellKey]} />
    },
    [table.cells]
  )

  const onFullScreen = () => navigate(`/tables/${article.id}`)

  return (
    <DocumentModal open={show} onClose={onClose} renderHeader={modalHeaderButtons}>
      <DocumentRenderer
        documentId={article.id}
        documentType="learning_table"
        educators={article.personnel}
        footer={
          <Flex justifyContent="space-between" alignItems="center" p={3} width={1}>
            <Text fontSize={1}>{format(article.insertedAt, 'ddd, D MMMM YYYY')}</Text>
            <Box minWidth={160}>
              <SecondaryButton onClick={onFullScreen}>
                <Flex alignItems="center" justifyContent="center">
                  <Icon name="fullscreen" mr={1} fill="primary" />
                  Full Screen
                </Flex>
              </SecondaryButton>
            </Box>
          </Flex>
        }
        heading={articleContent.title || ''}
        learningOutcomes={article.learningOutcomes}
        overline={creator?.fullName || ''}
        statusMessage={isPlayground && <ArticleStatusBanner article={article} path="tables" />}
        taggedChildren={article.children}>
        <DocumentModalPreview background="surfaceSecondary">
          <TablePreview ref={calculateTableScale} height={tableHeight} scale={tableScale}>
            <Table cellRenderer={onCellRender} columns={table.columns} rows={table.rows} />
          </TablePreview>
        </DocumentModalPreview>
      </DocumentRenderer>
    </DocumentModal>
  )
}

TableModal.displayName = 'TableModal'

const Loader = ({ id, contentStatus, show, onClose }: LoaderProps) => {
  const serviceFkey = useSelectedServiceFkey()

  const variables = useMemo(() => {
    const defaultContentStatus = isHome ? 'PUBLISHED' : 'DRAFT'

    return {
      id: Number(id),
      serviceFkey,
      contentStatus: contentStatus ? contentStatus : defaultContentStatus,
    }
  }, [contentStatus, id, serviceFkey])

  const renderModal = useCallback(
    (props) => {
      return <TableModal {...props} onClose={onClose} show={show} />
    },
    [show, onClose]
  )

  const renderPlaceholder = useCallback(() => <DocumentModalPlaceholder onClose={onClose} />, [onClose])

  if (!id || !show) {
    return null
  }

  return (
    <TableLoader placeholder={renderPlaceholder} query={GET_LEARNING_TABLE_DETAIL} variables={variables}>
      {renderModal}
    </TableLoader>
  )
}

export default React.memo(Loader)
