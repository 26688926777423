type ComparisonObject = { [key: string]: any }
type Order = 'asc' | 'desc'

const compareObjectValues =
  (key: string, order: Order = 'asc') =>
  (a: ComparisonObject, b: ComparisonObject) => {
    // property doesn't exist on either object
    if (!(key in a) || !(key in b)) return 0

    const varA = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key]
    const varB = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key]

    let comparison = 0
    if (varA > varB) {
      comparison = 1
    } else if (varA < varB) {
      comparison = -1
    }
    return order === 'desc' ? comparison * -1 : comparison
  }

export default compareObjectValues
