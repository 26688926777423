import React from 'react'

import styled from 'styled-components'
import { BorderColorProps, SpaceProps, WidthProps, borderColor, space, width } from 'styled-system'

type BaseProps = BorderColorProps & SpaceProps & WidthProps

const Divider = styled.hr<BaseProps>`
  border: 0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  height: 1px;
  ${borderColor};
  ${space};
  ${width};
`

Divider.displayName = 'Divider'

export default React.memo(Divider)
