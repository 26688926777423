import ReactGA from 'react-ga4'

//Playground Web
export async function createObservation() {
  ReactGA.event({
    category: 'Observation',
    action: 'Create',
    label: 'Create New Observation',
  })
}

//Playground Web
export async function observationPublishToParent() {
  ReactGA.event({
    category: 'Observation',
    action: 'Publish',
    label: 'Publish to Parents',
  })
}

//Playground Web
export async function publishPhotoConsentNonConsent() {
  ReactGA.event({
    category: 'Observation',
    action: 'Publish',
    label: 'Published No Photograph Consent',
  })
}

//Playground Web
export async function untagChildrenPhotoNotPublished() {
  ReactGA.event({
    category: 'Observation',
    action: 'Publish',
    label: 'Untagged No Photograph Consent',
  })
}

//Playground Web
export async function observationTagAllBulkAction() {
  ReactGA.event({
    category: 'Observation',
    action: 'Bulk Action',
    label: 'Tag All',
  })
}

//Playground Web
export async function observationUntagAllBulkAction() {
  ReactGA.event({
    category: 'Observation',
    action: 'Bulk Action',
    label: 'Untag All',
  })
}

//Playground Web
export async function observationTagBookedBulkAction() {
  ReactGA.event({
    category: 'Observation',
    action: 'Bulk Action',
    label: 'Tag Booked',
  })
}

//Playground Web
export async function duplicateObservation() {
  ReactGA.event({
    category: 'Observation',
    action: 'Duplicate',
    label: 'Duplicate Observation',
  })
}

//Home Web
export async function openObservation() {
  ReactGA.event({
    category: 'Observation',
    action: 'Learning Click',
    label: 'Observation',
  })
}

//Home Web
export async function observationAddComment() {
  ReactGA.event({
    category: 'Observation',
    action: 'Learning Comment',
    label: 'Observation',
  })
}
