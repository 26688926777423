import React from 'react'

import styled from 'styled-components'
import { ColorProps, HeightProps, SpaceProps, WidthProps, color, height, space, width } from 'styled-system'

type Props = ColorProps & HeightProps & SpaceProps & WidthProps

const StencilCircle = styled.div<Props>`
  border-radius: 100%;
  background-color: rgb(225, 225, 225);
  ${space};
  ${height};
  ${width};
  ${color};
`

StencilCircle.displayName = 'StencilCircle'

export default React.memo(StencilCircle)
