import React from 'react'

const Wheelchair = () => (
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M11.667 1.513a1.333 1.333 0 1 0 0 2.667 1.333 1.333 0 0 0 0-2.667zm-2 9.64H8.333c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2V7.82a3.335 3.335 0 0 0 0 6.667 3.335 3.335 0 0 0 3.334-3.334zm2-2.333h-1.24l1.113-2.447c.407-.886-.247-1.886-1.233-1.886H6.84c-.54 0-1.027.313-1.247.8l-.446 1.2 1.28.353.433-1.02h1.473l-1.22 2.733c-.4.887.26 1.934 1.234 1.934h3.32v3.333H13v-3.667c0-.733-.6-1.333-1.333-1.333z"
    />
  </svg>
)

export default Wheelchair
