import React from 'react'

import { Box, Flex, Text } from 'stardust'
import styled from 'styled-components'

import colors from '~/ui-components/tokens/colors'

interface Props {
  status?: Playground.Status
  fontSize?: number | string
}

const ColouredCircle = styled(Flex)`
  border-radius: 10px;
  background-color: ${(props) => props.color};
`

const StatusPill = ({ status, fontSize }: Props) => {
  const getPillStyles = (status: Playground.Status | undefined) => {
    switch (status) {
      case 'DRAFT':
        return {
          title: 'Draft',
          textColor: colors.nebulaBlue9,
          bgColor: colors.nebulaBlue1,
          borderStyle: 'solid',
          borderColor: colors.nebulaBlue1,
        }
      case 'ARCHIVED':
        return {
          title: 'Archived',
          textColor: colors.cosmicShade13,
          bgColor: colors.cosmicShade14,
          borderStyle: 'solid',
          borderColor: colors.cosmicShade14,
        }
      case 'PUBLISH':
        return {
          title: 'Share with Families',
          textColor: colors.superGiantRed10,
          bgColor: colors.hullOrange1,
          borderStyle: 'solid',
          borderColor: colors.hullOrange1,
        }
      case 'PUBLISHED':
        return {
          title: 'Shared with Families',
          textColor: colors.superGiantRed10,
          bgColor: colors.hullOrange1,
          borderStyle: 'solid',
          borderColor: colors.hullOrange1,
        }
      case 'NEEDS_REVIEW':
        return {
          title: 'Needs Review',
          textColor: colors.pulsarPurple10,
          bgColor: colors.pulsarPurple11,
          borderStyle: 'solid',
          borderColor: colors.pulsarPurple11,
        }
      case 'UNSHARED_CHANGES':
        return {
          title: 'Unshared Changes',
          textColor: colors.nebulaBlue7,
          bgColor: 'none',
          borderStyle: 'dashed',
          borderColor: colors.nebulaBlue5,
        }
      default:
        return null
    }
  }

  const pillStyles = getPillStyles(status)

  return (
    pillStyles && (
      <Box
        bg={pillStyles?.bgColor}
        borderRadius="40px"
        border={`2px ${pillStyles?.borderStyle} ${pillStyles?.borderColor} `}
        p="3px 8px"
        width="max-content">
        <Flex alignItems="center">
          <ColouredCircle width="6px" height="6px" color={pillStyles?.textColor} mr={1} />
          <Text
            color={pillStyles?.textColor}
            fontSize={fontSize ?? 0}
            lineHeight="15px"
            letterSpacing="0.4px">
            {pillStyles?.title}
          </Text>
        </Flex>
      </Box>
    )
  )
}

StatusPill.displayName = 'StatusPill'

export default React.memo(StatusPill)
