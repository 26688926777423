import React from 'react'

const ClockAlt = () => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 7.5C0.5 3.35786 3.85786 0 8 0C12.1421 0 15.5 3.35786 15.5 7.5C15.5 11.6421 12.1421 15 8 15C3.85786 15 0.5 11.6421 0.5 7.5ZM8 1C4.41015 1 1.5 3.91015 1.5 7.5C1.5 11.0899 4.41015 14 8 14C11.5899 14 14.5 11.0899 14.5 7.5C14.5 3.91015 11.5899 1 8 1Z"
      fill="Black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 4.5C8.27614 4.5 8.5 4.72386 8.5 5V7.5C8.5 7.77614 8.27614 8 8 8C7.72386 8 7.5 7.77614 7.5 7.5V5C7.5 4.72386 7.72386 4.5 8 4.5Z"
      fill="Black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.64648 7.14641C7.84177 6.95117 8.15835 6.9512 8.35359 7.14648L11.4783 10.2718C11.6735 10.4671 11.6735 10.7837 11.4782 10.9789C11.2829 11.1742 10.9663 11.1741 10.7711 10.9788L7.64641 7.85352C7.45117 7.65823 7.4512 7.34165 7.64648 7.14641Z"
      fill="Black"
    />
  </svg>
)

export default ClockAlt
