import gql from 'graphql-tag'

export default gql`
  fragment PostRooms on Post {
    rooms {
      name
      roomId
      roomFkey
    }
  }
`
