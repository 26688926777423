import ReactGA from 'react-ga4'

//Playground web
export async function blankCanvasCreated() {
  ReactGA.event({
    category: 'Canvas',
    action: 'Create',
    label: 'Create Blank Canvas',
  })
}

//Playground web
export async function saveCanvasAsDraft() {
  ReactGA.event({
    category: 'Canvas',
    action: 'Save',
    label: 'Save Draft Canvas',
  })
}

//Playground web
export async function publishCanvasToParents() {
  ReactGA.event({
    category: 'Canvas',
    action: 'Publish',
    label: 'Canvas Publish to Parents',
  })
}

//Playground web
export async function editCanvasKebabMenu() {
  ReactGA.event({
    category: 'Canvas',
    action: 'Edit',
    label: 'Edit Canvas',
  })
}

//Playground web
export async function archiveCanvasKebabMenu() {
  ReactGA.event({
    category: 'Canvas',
    action: 'Archive',
    label: 'Archive Canvas',
  })
}

//Playground web
export async function canvasTagAllBulkAction() {
  ReactGA.event({
    category: 'Canvas',
    action: 'Bulk Action',
    label: 'Tag All',
  })
}

//Playground web
export async function canvasUntagAllBulkAction() {
  ReactGA.event({
    category: 'Canvas',
    action: 'Bulk Action',
    label: 'Untag All',
  })
}

//Playground web
export async function canvasTagBookedBulkAction() {
  ReactGA.event({
    category: 'Canvas',
    action: 'Bulk Action',
    label: 'Tag Booked',
  })
}

//Playground Web
export async function duplicateCanvas() {
  ReactGA.event({
    category: 'Canvas',
    action: 'Duplicate',
    label: 'Duplicate Canvas',
  })
}
