import React, { useState } from 'react'

import { navigate } from '@reach/router'
import * as R from 'ramda'

import { Box, Container } from 'stardust'

import styled from 'styled-components'

import DocumentCard from '~/components/DocumentCard'
import DocumentModalSelector from '~/components/DocumentModalSelector'
import { isWebView } from '~/config'

const GridContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
`

const findDocument = (documents, selectedDocId) => {
  return R.find(
    ({ id, type, typeId }) => id === selectedDocId || `${type}-${typeId}` === selectedDocId,
    documents
  )
}

const DocumentResults = ({ documents, selectedDocId }) => {
  const [selectedDoc, setSelectedDoc] = useState(() => findDocument(documents, selectedDocId))

  const isPost = (type) => (type === 'observation' ? true : false)
  const isMoment = (type) => (type === 'moment' ? true : false)

  const handleOnClick = (document) => {
    setSelectedDoc(document)

    if (isPost(document.type)) {
      isWebView
        ? navigate(`xplor://posts/view/${document.typeId}`)
        : navigate(`/observations/${document.typeId}`)
    }

    if (isMoment(document.type)) {
      isWebView && navigate(`xplor://moments/view/${document.typeId}`)
    }
  }

  return (
    <>
      <Container>
        <Box mt={3}>
          <GridContainer gap="16px" p={3}>
            {documents.map((doc) => (
              <DocumentCard
                key={doc.id}
                onClick={() => handleOnClick(doc)}
                author={doc.author}
                coverImage={doc.coverImage}
                id={doc.typeId}
                isPublished={doc.status === 'published'}
                thumbImage={doc.coverImage}
                title={doc.title}
                type={doc.type}
                updatedAt={doc.updatedAt}
                status={doc.status}
              />
            ))}
          </GridContainer>
        </Box>
      </Container>
      <DocumentModalSelector document={selectedDoc} onClose={() => setSelectedDoc(null)} />
    </>
  )
}

DocumentResults.displayName = 'DocumentResults'

export default DocumentResults
