import React, { useRef } from 'react'

import { Anchor } from 'stardust'

const LoadedLink = ({ href, selection }) => {
  const anchorRef = useRef(null)

  return (
    <>
      <Anchor href={href} ref={anchorRef}>
        {selection}
      </Anchor>
    </>
  )
}

LoadedLink.displayName = 'LoadedLink'
export default LoadedLink
