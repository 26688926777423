import React, { useCallback, useMemo, useRef, useState } from 'react'

import { useMutation } from '@apollo/client'

import { Absolute, Flex, Icon, Popover, Text } from 'stardust'

import { sortObjectsBy } from '~/utils'

import { CREATE_LEARNING_RECORD } from '../mutations'

import Evidence from './Evidence'

interface Props {
  addScoreChange(scoreChange: Playground.LearningRecord): void
  category: Playground.LearningFrameworkCategory
  child: Playground.SimpleChild
  cohortsEnabled: boolean
  records: Playground.LearningRecord[]
  serviceFkey: string
  onAgeGroupLookup(ageGroupId: number): Playground.LearningFrameworkAgeGroup | undefined
  onRankLookup(rankId: number): Playground.LearningFrameworkRank | undefined
}

export const CELL_COLORS = [
  'surfacePrimary',
  'nebulaBlue0',
  'nebulaBlue1',
  'nebulaBlue3',
  'nebulaBlue4',
  'nebulaBlue5',
]

const HeatmapCell = ({
  addScoreChange,
  category,
  child,
  cohortsEnabled,
  records,
  serviceFkey,
  onAgeGroupLookup,
  onRankLookup,
}: Props) => {
  const anchorRef = useRef(null)
  const [showEvidence, setShowEvidence] = useState(false)
  const [createLearningRecord] = useMutation(CREATE_LEARNING_RECORD)

  const onScoreChange = useCallback(
    async ({ ageGroupId, outcomeId, rankId }: Partial<Playground.LearningRecord>) => {
      const resp = await createLearningRecord({
        variables: {
          serviceFkey,
          childFkey: child.fkey!,
          outcomeId,
          ageGroupId,
          rankId,
        },
      })

      const scoreChange = resp.data.createLearningRecord as Playground.LearningRecord
      addScoreChange(scoreChange)
    },
    [createLearningRecord, child, serviceFkey, addScoreChange]
  )

  const onToggle = useCallback(() => setShowEvidence((value) => !value), [])

  const sortedRecords = useMemo(() => sortObjectsBy(records, 'achievedAt').reverse(), [records])

  const latestRecord = sortedRecords[0]

  const formatCell = (latestRecord: Playground.LearningRecord): { color: string; text: string | null } => {
    if (cohortsEnabled && latestRecord && (latestRecord.ageGroupId == null || latestRecord.rankId == null)) {
      return { color: 'hullOrange1', text: 'Latest evidence has invalid age group/rank' }
    }

    return cohortsEnabled
      ? { color: CELL_COLORS[onRankLookup(latestRecord?.rankId || -1)?.value || 0], text: null }
      : { color: 'surfacePrimary', text: null }
  }

  const label = cohortsEnabled
    ? onAgeGroupLookup(latestRecord?.ageGroupId || -1)?.name || '-'
    : records.length

  return (
    <>
      <Flex
        title={formatCell(latestRecord).text}
        ref={anchorRef}
        alignItems="center"
        bg={formatCell(latestRecord).color}
        borderTop="1px solid"
        borderColor="surfacePrimaryBorder"
        cursor="pointer"
        flexGrow={1}
        height={56}
        justifyContent="center"
        maxWidth={150}
        minWidth={150}
        onClick={onToggle}>
        <Text.span fontSize={0}>{label}</Text.span>
      </Flex>
      {showEvidence && (
        <Popover
          anchor={anchorRef}
          placement="right"
          show={showEvidence}
          showArrow={false}
          onToggle={onToggle}>
          <Absolute right={16} top={16}>
            <Icon color="cosmicShade7" name="close" size={24} onClick={onToggle} />
          </Absolute>

          <Evidence category={category} records={sortedRecords} onScoreChange={onScoreChange} />
        </Popover>
      )}
    </>
  )
}

HeatmapCell.displayName = 'HeatmapCell'

export default React.memo(HeatmapCell)
