import React, { useContext, useState } from 'react'

import { Avatar, Box, ErrorMessage, Flex, Heading, Interactive, Text, hoverMixin } from 'stardust'
import styled from 'styled-components'

import config, { homeUrl, isHome, playgroundUrl } from '~/config'

import { StrategyContext } from '~/contexts/Strategy'

import { getAuthToken } from '~/modules/auth'

const InteractiveHover = styled(Interactive)`
  ${hoverMixin};
  background-color: ${(props: any) => (props.selected ? props.theme.colors.primaryLight : 'initial')};
`

const ProfileSSOOverlay = styled(Box)`
  position: fixed;
  display: block;
  width: 500px;
  height: 60px;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(0.8px);
`
const SSONotice = styled(Text.span)`
  display: block;
  font-size: 0.75rem;
  font-weight: bold;
  margin-left: 238px;
  margin-top: 24px;
`

const authWithStrategy = async (token: string, strategy: Stargate.UserContext) => {
  return fetch(`${config.STARGATE_API}/api/v1/auth/context`, {
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      user_ref: strategy.user_ref,
    }),
    credentials: 'include',
    method: 'POST',
  })
}

const isXplorStrategy = (strategy: Stargate.UserContext) => {
  return strategy.strategy_id > 0
}

const filterEducatorStrategies = (strategies: Stargate.UserContext[]) => {
  return strategies.filter((strategy) => strategy.strategy_type.includes('Educator'))
}

const filterGuardianStrategies = (strategies: Stargate.UserContext[]) => {
  return strategies.filter(({ strategy_type: type }) => type === 'Parent' || type.includes('Guardian'))
}

const getServiceNames = (strategy: Stargate.UserContext) => {
  return strategy.services.join(', ')
}

const getDisplayName = (strategy: Stargate.UserContext) => {
  return isXplorStrategy(strategy)
    ? `${strategy.first_name} ${strategy.last_name}`
    : getServiceNames(strategy)
}

const getAvatarInitials = (strategy: Stargate.UserContext) => {
  const names = getDisplayName(strategy).split(' ')
  return names
    .map((name) => name[0])
    .join('')
    .replaceAll(/[^A-Za-z0-9]/g, '')
}

const UserStrategySelector = () => {
  const [errors, setErrors] = useState(false)

  const {
    selectStrategy,
    state: { strategies },
  } = useContext(StrategyContext)

  const redirectToApp = (strategy: Stargate.UserContext) => {
    const redirectUrl = strategy.strategy_type.includes('Educator') ? playgroundUrl : homeUrl
    window.location.assign(redirectUrl)
  }

  const onStrategySelect = async (strategy: Stargate.UserContext, selectStrategy: any) => {
    const response = await authWithStrategy(getAuthToken()!, strategy)

    if (response.ok) {
      selectStrategy(strategy.user_ref)
      redirectToApp(strategy)
    } else {
      setErrors(true)
    }
  }

  const parentStrategies = filterGuardianStrategies(strategies)
  const educatorStrategies = filterEducatorStrategies(strategies)

  const renderStrategies = (name: string, sectionStrategies: Stargate.UserContext[]) => {
    if (sectionStrategies.length === 0) return null
    return (
      <Box pb={3}>
        <Text.span color="black" fontSize={0} letterSpacing="3.2px" px={3} upper>
          {name} Profiles
        </Text.span>
        <Box pt={1}>
          {sectionStrategies.map((strategy) => (
            <>
              {strategy.auth_strategy_sso ? (
                () => (
                  <ProfileSSOOverlay>
                    <SSONotice>Must sign-in via SSO to access this profile.</SSONotice>
                  </ProfileSSOOverlay>
                )
              ) : (
                <></>
              )}
              <InteractiveHover
                key={strategy.user_ref}
                data-test={`${name.toLowerCase()}-profile`}
                onClick={() => onStrategySelect(strategy, selectStrategy)}>
                <Flex alignItems="center" px={3} py={2}>
                  <Avatar xsmall context={name} src={null} text={getAvatarInitials(strategy)} />
                  <Box pl={3}>
                    <Text lineHeight={1.4}>{getDisplayName(strategy)}</Text>
                    {isXplorStrategy(strategy) && (
                      <Text medium fontSize={0}>
                        {getServiceNames(strategy)}
                      </Text>
                    )}
                  </Box>
                </Flex>
              </InteractiveHover>
            </>
          ))}
        </Box>
      </Box>
    )
  }

  return (
    <>
      {errors && <ErrorMessage data-test="error">No Xplor profile found / Invalid credentials</ErrorMessage>}
      <Heading.h4 lineHeight={1} mb={0} mt={0} p={3} pb={3}>
        Choose profile:
      </Heading.h4>
      <Box maxHeight="400px" overflowY="auto">
        {isHome ? (
          <>
            {renderStrategies('Parent', parentStrategies)}
            {renderStrategies('Educator', educatorStrategies)}
          </>
        ) : (
          <>
            {renderStrategies('Educator', educatorStrategies)}
            {renderStrategies('Parent', parentStrategies)}
          </>
        )}
      </Box>
    </>
  )
}

UserStrategySelector.displayName = 'UserStrategySelector'

export default React.memo(UserStrategySelector)
