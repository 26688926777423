import RAnyPass from 'ramda/src/anyPass'

import { isHome, isPlayground } from '~/config'
import { toLowerAndTrim } from '~/utils'

const EDUCATOR = 'EDUCATOR'
const GROUP_ADMIN = 'GROUP_ADMIN'
const GUARDIAN = 'GUARDIAN'
const PARENT = 'PARENT'
const PROVIDER_ADMIN = 'PROVIDER_ADMIN'
const SERVICE_ADMIN = 'SERVICE_ADMIN'
const SUPER_ADMIN = 'SUPER_ADMIN'

const ROLES = {
  [EDUCATOR]: EDUCATOR,
  [GUARDIAN]: GUARDIAN,
  [PARENT]: PARENT,
  [SERVICE_ADMIN]: SERVICE_ADMIN,
  [PROVIDER_ADMIN]: PROVIDER_ADMIN,
  [GROUP_ADMIN]: GROUP_ADMIN,
  [SUPER_ADMIN]: SUPER_ADMIN,
}

const roleFromUser = (user: Playground.User) => toLowerAndTrim(user.roles[0])
const isRole = (role: string) => (user: Playground.User) => roleFromUser(user) === toLowerAndTrim(role)

const isFeatureEnabled = (features: Playground.ServiceFeature[], category: string, subcategory: string) => {
  return !!features.find(
    (feature) =>
      feature.category === category && feature.subcategory === subcategory && feature.assignment == '1'
  )
}

export const cohortTrackingEnabled = (features: Playground.ServiceFeature[]) => {
  return isFeatureEnabled(features, 'PLAYGROUND', 'COHORT_TRACKING')
}

export const educatorLearningContentEnabled = (features: Playground.ServiceFeature[]) => {
  return isFeatureEnabled(features, 'PLAYGROUND', 'CONTENT_LIBRARY_FOR_OBSERVATION')
}

export const parentLearningContentEnabled = (
  services: Playground.SimpleService[],
  documentServiceFkey: Nullable<string>
) => {
  const selectedService = services.find((x) => x.fkey === documentServiceFkey)
  return selectedService?.features
    ? isFeatureEnabled(selectedService.features, 'PARENT', 'PARENT_CONTENT')
    : false
}

export const showLearningContentAssets = (
  documentServiceFkey: Nullable<string>,
  selectedServiceFeatures: Playground.ServiceFeature[],
  services: Playground.SimpleService[]
) => {
  return (
    (isHome && parentLearningContentEnabled(services, documentServiceFkey)) ||
    (isPlayground && educatorLearningContentEnabled(selectedServiceFeatures))
  )
}

export const transportListsEnabled = (features: Playground.ServiceFeature[]) => {
  return isFeatureEnabled(features, 'PLAYGROUND', 'TRANSPORT_LISTS')
}

export const isEducator = isRole(ROLES.EDUCATOR)
export const isGuardian = isRole(ROLES.GUARDIAN)
export const isParent = isRole(ROLES.PARENT)
export const isServiceAdmin = isRole(ROLES.SERVICE_ADMIN)
export const isProviderAdmin = isRole(ROLES.PROVIDER_ADMIN)
export const isGroupAdmin = isRole(ROLES.GROUP_ADMIN)
export const isSuperAdmin = isRole(ROLES.SUPER_ADMIN)
export const isAdmin = RAnyPass([isServiceAdmin, isProviderAdmin, isGroupAdmin, isSuperAdmin])
export const isPersonnel = RAnyPass([isEducator, isAdmin])
export const isParentOrGuardian = RAnyPass([isParent, isGuardian])

export const childrenProfiles = {
  read: 'childrenProfiles:read',
  readDocuments: 'childrenProfiles:readDocuments',
  readHealth: 'childrenProfiles:readHealth',
  readAbout: 'childrenProfiles:readAbout',
  write: 'childrenProfiles:write',
  readCwa: 'childrenProfiles:readCwa',
}

export const childGroups = {
  read: 'childGroups:read',
  write: 'childGroups:write',
}

export const learningAnalysis = {
  read: 'learningAnalysis:read',
  write: 'learningAnalysis:write',
}

export const dashboard = {
  read: 'dashboard:read',
}

export const educatorProfiles = {
  read: 'educatorProfiles:read',
  readAbout: 'educatorProfiles:readAbout',
  readDocuments: 'educatorProfiles:readDocuments',
  readEmploymentInfo: 'educatorProfiles:readEmploymentInfo',
  readPersonalInfo: 'educatorProfiles:readPersonalInfo',
  readQualifications: 'educatorProfiles:readQualifications',
}

export const layouts = {
  read: 'layouts:read',
  readGlobal: 'layouts:readGlobal',
  write: 'layouts:write',
  writeGlobal: 'layouts:writeGlobal',
  writeProvider: 'layouts:writeProvider',
}

export const learningFrameworks = {
  read: 'learningFrameworks:read',
  readProvider: 'learningFrameworks:readProvider',
  write: 'learningFrameworks:write',
  writeProvider: 'learningFrameworks:writeProvider',
}

export const learningStories = {
  read: 'learningStories:read',
  readPublished: 'learningStories:readPublished',
  readDrafts: 'learningStories:readDrafts',
  readArchived: 'learningStories:readArchived',
  write: 'learningStories:write',
}

export const learningPlans = {
  read: 'learningPlans:read',
  readPublished: 'learningPlans:readPublished',
  readDrafts: 'learningPlans:readDrafts',
  readArchived: 'learningPlans:readArchived',
  write: 'learningPlans:write',
}

export const learningTables = {
  read: 'learningTables:read',
  readPublished: 'learningTables:readPublished',
  readDrafts: 'learningTables:readDrafts',
  readArchived: 'learningTables:readArchived',
  write: 'learningTables:write',
}

export const healthEvents = {
  readIncidentRecords: 'healthEvents:readIncidentRecords',
}

export const liveLearning = {
  read: 'liveLearning:read',
  write: 'liveLearning:write',
}

export const moments = {
  read: 'moments:read',
}

export const observations = {
  read: 'observations:read',
  readPublished: 'observations:readPublished',
  //The read Drafts refers to ability to access needs_review here still in permissions
  readDrafts: 'observations:readDrafts',
  readBookmarked: 'observations:readBookmarked',
  write: 'observations:write',
  approve: 'observations:approve',
}

export const parentFinance = {
  read: 'parentFinance:read',
}

export const roomProfiles = {
  read: 'roomProfiles:read',
  readDocuments: 'roomProfiles:readDocuments',
}

export const search = {
  read: 'search:read',
}

export const serviceLearningFrameworks = {
  read: 'serviceLearningFrameworks:read',
  write: 'serviceLearningFrameworks:write',
}

export const documentFolders = {
  read: 'documentFolders:read',
  write: 'documentFolders:write',
}

export const commsCenter = {
  read: 'commsCenter:read',
}
