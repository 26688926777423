import React from 'react'

import * as R from 'ramda'

import { Box, Container, StencilLine as Line, StencilListItem as ListItem } from 'stardust'

const Loading = () => (
  <Container mt={2}>
    <Box py={4}>
      <Line py={2} width={1 / 3} />
    </Box>
    {R.pipe(
      R.range(5),
      R.reverse,
      R.map((i) => <ListItem opacity={(i - 3) / 10} key={i} my={3} />)
    )(11)}
  </Container>
)

export default Loading
