import React from 'react'

import { Box, StencilLine as Line, StencilCircle as Circle, FlexItem, Flex } from 'stardust'

import styled from 'styled-components'

import colors from '~/ui-components/tokens/colors'

const Content = styled(Line)`
  height: ${(props) => props.height || '35vh'};
  padding: 16px;
  width: 100%;
  background-color: ${(props) => props.bg || colors.cosmicShade3};
`
const S = {
  NewPostModal: styled(FlexItem)`
    overflow: auto;
    width: 1290px;
    max-width: calc(100vw - 40px);
    align-items: stretch;
    max-height: 100%;
    padding: 16px;
    background-color: ${colors.cosmicShade4};
  `,
  VerticalLine: styled.div`
    border-left: 2px solid #d3d3d3;
    margin-left: 16px;
    margin-right: 16px;
  `,
  SideBar: styled(Flex)`
    margin-left: 16px;
    width: 5vw;
    border-radius: 8px;
  `,
  Bar: styled(Flex)`
    border-radius: 16px;
    width: 100%;
    background-color: ${colors.cosmicShade3};
  `,
  ContentStyle: styled(Content)`
    background-color: ${colors.cosmicShade6};
    height: 100%;
    border-radius: 16px;
  `,
  DescriptionTop: styled(Content)`
    background-color: ${colors.cosmicShade6};
    border-radius: 16px 16px 0 0;
  `,
  DescriptionBottom: styled(Content)`
    border-radius: 0 0 16px 16px;
  `,
}

const Icons = () => (
  <Flex flexDirection="column" alignItems="center">
    <Box width={40} mx={2}>
      <Circle width={40} height={40} />
      <Line mt={2} width={40} />
    </Box>
  </Flex>
)

const LoadingPostModal = () => (
  <S.NewPostModal>
    <Flex alignItems="center" justifyContent="space-between" height="7vh" mb={3}>
      <Flex alignItems="center" flexDirection="row" width="100%">
        <Circle ml={2} width={45} height={45} />
        <Box width={200}>
          <Content ml={2} bg={colors.cosmicShade6} height={6} />
        </Box>
      </Flex>
      <Flex alignItems="center">
        <Box width={100} mx={1}>
          <Content bg={colors.cosmicShade6} height={50} borderRadius={1} />
        </Box>
        <Box width={100} mx={1}>
          <Content bg={colors.cosmicShade6} height={50} borderRadius={1} />
        </Box>
        <Box width={100} mx={1}>
          <Content bg={colors.cosmicShade7} height={50} borderRadius={1} />
        </Box>
      </Flex>
    </Flex>
    <Flex>
      <Box width="100%">
        <Flex flexDirection="column">
          <S.Bar height="6vh" mb={2} />
          <S.DescriptionTop height="6vh" />
          <S.DescriptionBottom mb={2} />
          <Flex justifyContent="right">
            <Line width={64} mb={2} mt={1} py={1} />
          </Flex>
          <S.Bar height="10vh" alignItems="center" justifyContent="space-between">
            <Box width={150} mx={1}>
              <Line width={120} ml={3} />
            </Box>
            <Flex mr={2}>
              <Icons />
              <S.VerticalLine />
              <Icons />
              <Icons />
              <Icons />
              <Icons />
            </Flex>
          </S.Bar>
        </Flex>
      </Box>
      <S.SideBar>
        <S.ContentStyle />
      </S.SideBar>
    </Flex>
  </S.NewPostModal>
)

LoadingPostModal.displayName = 'LoadingPostModal'

export default LoadingPostModal
