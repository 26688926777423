import React from 'react'

import { Avatar, Box, Flex, Text } from 'stardust'
import styled from 'styled-components'

import * as profiles from '~/components/Profiles/core'
import { isHomeWebview } from '~/config'
import colors from '~/ui-components/tokens/colors'

interface Props {
  image: string | undefined
  fullName: string
}

const S = {
  Box: styled(Box)`
    background-color: ${isHomeWebview ? colors.cosmicShade11 : colors.cosmicShade4};
    border-radius: 25px;
    &:hover {
      background-color: ${colors.cosmicShade6};
      transition: background-color 0.2s;
    }
  `,
}

const ChildPill = ({ image, fullName }: Props) => {
  return (
    <S.Box pt={1} pb={1} pr={3} pl={1} width={'max-content'} mr={3} cursor="pointer">
      <Flex alignItems="center" flexGrow={1}>
        <Avatar xxsmall alt={`${fullName}'s Profile`} src={image} text={profiles.initials(fullName)} />
        <Text fontSize={1} ml={2} color="textPrimary">
          {fullName}
        </Text>
      </Flex>
    </S.Box>
  )
}

ChildPill.displayName = 'ChildPill'

export default React.memo(ChildPill)
