import * as Immutable from 'immutable'
import { Editor, Node, Range, Value } from 'slate'

import { LINK_NODE_TYPE } from './types'

const hasLinks = (editor: Editor) => {
  const { value } = editor
  return value.inlines.some((inline) => inline!.type === LINK_NODE_TYPE)
}

const insertLink = (editor: Editor, href: string) => {
  editor.change((change) => {
    const nodes = Immutable.List<Node>([
      {
        object: 'text',
        leaves: [
          {
            text: `${href}`,
          },
        ],
      },
    ])
    return change
      .insertInlineAtRange(new Range(change.value.selection), {
        data: { href: `${href}` },
        nodes: nodes,
        type: `${LINK_NODE_TYPE}`,
      })

      .focus()
  })
}

const removeLink = (editor: Editor, value: Value) => {
  editor.change((change) => {
    return change.removeNodeByKey(value.startInline.key)
  })
}

const wrapLink = (editor: Editor, href: string) => {
  editor.change((change) => {
    change.wrapInline({ type: LINK_NODE_TYPE, data: { href: `${href}` } })
    return change.moveToEnd()
  })
}

const unwrapLink = (editor: Editor) => {
  editor.change((change) => change.unwrapInline(LINK_NODE_TYPE))
}

export { hasLinks, insertLink, removeLink, wrapLink, unwrapLink }
