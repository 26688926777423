import React from 'react'

const AddOutcomes = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="24" fill="#FCDEB1" />
    <path
      d="M22.3743 33.375H18.0001C16.9649 33.3745 16.126 32.5352 16.126 31.5"
      stroke="#DB3B03"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.126 21.5V15.25C31.126 15.0841 31.06 14.925 30.9426 14.8078C30.8252 14.6905 30.666 14.6248 30.5001 14.625H18.626C17.2453 14.625 16.126 15.7443 16.126 17.125V31.5C16.126 30.4648 16.9649 29.6255 18.0001 29.625H23.0001"
      stroke="#DB3B03"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.3575 26.392L28 32.7503L24.875 33.3753L25.5 30.2503L31.8583 23.892C32.1888 23.561 32.6373 23.375 33.105 23.375C33.5727 23.375 34.0212 23.561 34.3517 23.892L34.3583 23.8986C35.0465 24.5874 35.0461 25.7036 34.3575 26.392Z"
      stroke="#DB3B03"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default AddOutcomes
