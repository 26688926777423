import React, { useMemo } from 'react'

import { Value } from 'slate'

import TextEditor from '~/components/Editors/TextEditor'

import { CELL_DEFAULT_BACKGROUND, CELL_DEFAULT_VALUE } from '../constants'
import { TableCell } from '../types'

import CellContent from './CellContent'

interface Props {
  cell: Nullable<TableCell>
}

const ReadOnlyCell = ({ cell }: Props) => {
  const background = cell ? cell.background : CELL_DEFAULT_BACKGROUND

  const value = useMemo(() => {
    return cell ? cell.value : Value.fromJSON(CELL_DEFAULT_VALUE)
  }, [cell])

  return (
    <CellContent background={background}>
      {cell ? <TextEditor autoFocus={false} readOnly={true} value={value} /> : null}
    </CellContent>
  )
}

ReadOnlyCell.displayName = 'ReadOnlyCell'

export default React.memo(ReadOnlyCell)
