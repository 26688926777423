import React, { ReactNode, useState, useEffect } from 'react'

interface State {
  innerHeight: number
  pageYOffset: number
  scrollHeight: number
}

interface Props {
  render(state: State): ReactNode
  scrollElementRef?: React.RefObject<HTMLDivElement>
}

const Scroll = ({ render, scrollElementRef }: Props) => {
  const [state, setState] = useState({
    innerHeight: 0, // Initialize to 0, will be set later
    pageYOffset: 0,
    scrollHeight: 0,
  })

  useEffect(() => {
    const scrollElement = scrollElementRef?.current

    // Handle scroll events within the element
    const onScroll = () => {
      if (scrollElement) {
        setState((prev) => ({
          ...prev,
          pageYOffset: scrollElement.scrollTop,
          innerHeight: scrollElement.clientHeight,
          scrollHeight: scrollElement.scrollHeight,
        }))
      } else {
        setState((prev) => ({
          ...prev,
          pageYOffset: window.scrollY,
          innerHeight: window.innerHeight,
        }))
      }
    }

    if (scrollElement) {
      // initial call
      onScroll()
      scrollElement.addEventListener('scroll', onScroll)
    } else {
      window.addEventListener('scroll', onScroll)
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('scroll', onScroll)
      } else {
        window.removeEventListener('scroll', onScroll)
      }
    }
  }, [scrollElementRef, render]) // Empty dependency array to run effect only once

  return <>{render(state)}</>
}

Scroll.dislplayName = 'Scroll'
export default React.memo(Scroll)
