import React from 'react'

import styled from 'styled-components'

import { AttachmentDocument } from '~/ui-components/icons/AttachmentDocument'

interface AttachmentDocumentIconWithTextProps {
  mimeType?: string
}

const AttachmentDocumentContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 26px;
  height: 28px;
`

const AttachmentDocumentText = styled.p<{ longName: boolean }>`
  position: absolute;
  top: 12px;
  left: 3px;
  width: 20px;
  height: 12px;
  text-align: center;
  line-height: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: ${(props) => (props.longName ? '6px' : '8px')};
  color: white;
`

// TODO: We need to get pill colors from design for what
// the different types of attachments.
/**
 * Returning document attachment attributes based on MIME file type
 *
 * @param mimeType a standardised MIME type for determining
 * behaviour.
 * @returns An array of attributes: [fileExtensionName, color, isTheFileExtensionNameLong]
 */
const getAttributes = (mimeType?: string): [string, string, boolean] => {
  // TODO: Return based on mimeType
  switch (mimeType) {
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return ['DOCX', '#1E90FF', true]
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return ['PPTX', '#FF8C00', true]
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || 'application/vnd.ms-excel':
      return ['XLSX', '#006400', true]
    case 'text/plain':
      return ['TXT', '#6B6B6B', false]
    default:
      return ['PDF', '#FF0000', false]
  }
}

export const AttachmentDocumentIconWithText = ({ mimeType }: AttachmentDocumentIconWithTextProps) => {
  const [text, pillColor, longName] = getAttributes(mimeType)

  return (
    <AttachmentDocumentContainer>
      <AttachmentDocument pillFill={pillColor} />
      <AttachmentDocumentText longName={longName}>{text}</AttachmentDocumentText>
    </AttachmentDocumentContainer>
  )
}
