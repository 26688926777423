import React from 'react'

const LearningFrameworks = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.25 9.938C23.2504 16.5259 18.4958 22.152 12 23.25C5.50424 22.152 0.7496 16.5259 0.75 9.938V0.75H23.25V9.938Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.75 5.25H23.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.53 8.79201L13.779 11.25H15.906C16.1456 11.2442 16.3643 11.3858 16.457 11.6069C16.5496 11.828 16.4972 12.0833 16.325 12.25L14.372 14.172L15.454 16.657C15.5511 16.8903 15.4886 17.1595 15.2986 17.3261C15.1087 17.4927 14.8336 17.5197 14.615 17.393L12 15.919L9.38497 17.389C9.16633 17.5157 8.89127 17.4887 8.70133 17.3221C8.51139 17.1555 8.44888 16.8863 8.54597 16.653L9.62797 14.168L7.67497 12.246C7.50273 12.0793 7.45034 11.824 7.54298 11.6029C7.63561 11.3818 7.85433 11.2402 8.09397 11.246H10.221L11.47 8.79201C11.5711 8.59218 11.776 8.46622 12 8.46622C12.2239 8.46622 12.4288 8.59218 12.53 8.79201Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default LearningFrameworks
