import React, { DragEvent } from 'react'

import { Block, Change } from 'slate'
import { getEventRange } from 'slate-react'

import ObservationResponsiveCard from '~/components/ObservationResponsiveCard'

import { Editor, Next, OnDropProps, Query, RenderNodeProps, SerializeProps } from '../types'

export default {
  onDrop: (e: DragEvent, { editor }: OnDropProps, next: Next) => {
    const droppedData = e.dataTransfer.getData('data')

    if (!droppedData || JSON.parse(droppedData).type !== 'document') {
      return next()
    }
    const eventRange = getEventRange(e, editor)
    if (!eventRange) {
      return next()
    }
    return editor.change((change: Change) =>
      change.insertBlockAtRange(
        eventRange,
        Block.create({
          type: 'document',
          data: JSON.parse(droppedData),
        })
      )
    )
  },

  // AP: This is actually intended to be a generic documentation call i.e. observation, moment, story, canvas.
  // It is getting all the nodes of type "document". That node would then have a data obj with a specific
  // document type (obs, moment, etc.)
  onQuery: ({ type }: Query, { value }: Editor, next: Next) => {
    if (type !== 'documents') {
      return next()
    }

    // TO.DO: AP - Create typing for filteredNodes. Probs have to type the node object that comes back
    const filteredNodes = value.document
      .filterDescendants((node) => 'type' in node && node.type === 'document')
      .toJS()

    const documentIds: number[] = filteredNodes.map(
      ({
        data: {
          data: { id },
        },
      }: {
        data: { data: { id: number } }
      }) => id
    )

    const uniqueIds = Array.from(new Set(documentIds))

    return uniqueIds.map((id) => ({
      documentId: id,
    }))
  },

  renderNode: ({ node }: RenderNodeProps, next: Next) => {
    if (node.type !== 'document') {
      return next()
    }

    const { data: data } = node.data.toJS()
    const observationId = data.typeId

    return <ObservationResponsiveCard id={observationId} />
  },

  serialize: (obj: SerializeProps) => {
    if (obj.object === 'block' && obj.type === 'document') {
      return <p>Observation needs more finnese</p>
    }
  },
}
