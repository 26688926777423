import React, { ReactNode, useCallback } from 'react'

import { DocumentNode } from 'graphql'

import { Link, Text } from 'stardust'

import Error404 from '~/components/404'
import ArticleLoader from '~/components/ArticleLoader'

import { TableState } from '../types'
import { deserializeTable } from '../utils'

interface ChildProps {
  article: Playground.Article
  articleContent: Playground.ArticleContent
  table: TableState
}

interface Props {
  children({ article, articleContent, table }: ChildProps): ReactNode
  embedded?: boolean
  placeholder?(): any
  query: DocumentNode
  variables: {
    id: number
    serviceFkey: string
    contentStatus: Playground.DocumentStatus
  }
}

const TableLoader = ({ children, embedded, placeholder, query, variables }: Props) => {
  const renderArticle = useCallback(
    ({ article, articleContent }) => {
      let deserializedTable
      try {
        deserializedTable = deserializeTable(articleContent.data, {
          children: article.children,
          learningOutcomes: article.learningOutcomes,
          personnel: article.personnel,
        })
      } catch (error) {
        // Bad data error
        return (
          <Error404
            message="Malformed table data"
            redirect={() => (
              <Link to="/tables">
                <Text color="textLink">Back to Tables</Text>
              </Link>
            )}
          />
        )
      }

      return <>{children({ article, articleContent, table: deserializedTable })}</>
    },
    [children]
  )

  return (
    <ArticleLoader
      embedded={embedded}
      placeholder={placeholder}
      query={query}
      type="table"
      variables={variables}>
      {renderArticle}
    </ArticleLoader>
  )
}

TableLoader.displayName = 'TableLoader'

export default React.memo(TableLoader)
