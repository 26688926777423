import gql from 'graphql-tag'

export default gql`
  fragment ObservationInfo on Observation {
    title
    status
    planningDate
    observationTime
    observationDate
    publishedAt
    updatedAt
    futurePlanning
    externalId
    description
    bookmarked
  }
`
