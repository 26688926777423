import gql from 'graphql-tag'

export const ARCHIVE_SERVICE_LAYOUT = gql`
  mutation deleteLayout($id: ID) {
    deleteTemplate(id: $id) {
      id
    }
  }
`

export const ARCHIVE_PROVIDER_LAYOUT = gql`
  mutation deleteLayout($id: ID) {
    deleteProviderTemplate(id: $id) {
      id
    }
  }
`

export const COPY_PROVIDER_LAYOUT_TO_SERVICES = gql`
  mutation copyProviderLayoutToServices($templateId: Int!, $serviceFkeys: [String!]!) {
    copyProviderTemplate(templateId: $templateId, serviceFkeys: $serviceFkeys) {
      serviceFkey
    }
  }
`

export const COPY_SERVICE_LAYOUT = gql`
  mutation copyServiceLayouts($templateId: Int!, $serviceFkey: String!) {
    copyServiceTemplate(templateId: $templateId, serviceFkey: $serviceFkey) {
      serviceFkey
    }
  }
`
