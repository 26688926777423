import gql from 'graphql-tag'

export default gql`
  fragment ObservationLearningOutcomes on Observation {
    learningOutcomes {
      id
      name
    }
  }
`
