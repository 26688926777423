import styled, { StyledComponent } from 'styled-components'
import {
  ColorProps,
  FontSizeProps,
  FontWeightProps,
  LetterSpacingProps,
  LineHeightProps,
  MaxWidthProps,
  MinWidthProps,
  SpaceProps,
  TextAlignProps,
  color,
  fontSize,
  fontWeight,
  letterSpacing,
  lineHeight,
  maxWidth,
  minWidth,
  space,
  textAlign,
} from 'styled-system'

export type BaseProps = ColorProps &
  FontSizeProps &
  FontWeightProps &
  LetterSpacingProps &
  LineHeightProps &
  MaxWidthProps &
  MinWidthProps &
  SpaceProps &
  TextAlignProps

export interface Props extends BaseProps {
  bold?: boolean
  caps?: boolean
  danger?: boolean
  disabled?: boolean
  italic?: boolean
  lower?: boolean
  medium?: boolean
  success?: boolean
  upper?: boolean
}

type SCProps = Props & { theme: any }

export const bold = (props: SCProps) => ({
  fontWeight: props.bold ? props.theme.fontWeights.bold : props.theme.fontWeights.regular,
})

export const caps = (props: SCProps) => (props.caps ? ({ textTransform: 'capitalize' } as any) : null)

export const lower = (props: SCProps) => (props.lower ? ({ textTransform: 'lowercase' } as any) : null)

export const upper = (props: SCProps) => (props.upper ? ({ textTransform: 'uppercase' } as any) : null)

export const danger = (props: SCProps) => (props.danger ? { color: props.theme.colors.negative } : null)

export const disabled = (props: SCProps) =>
  props.disabled ? { color: props.theme.colors.textPrimaryLow } : null

export const italic = (props: SCProps) => (props.italic ? { fontStyle: 'italic' } : null)

export const medium = (props: SCProps) =>
  props.medium ? { color: props.theme.colors.textPrimaryMedium } : null

export const success = (props: SCProps) => (props.success ? { color: props.theme.colors.positive } : null)

export const TextBase = styled.div<Props>`
  color: ${(props) => props.theme.colorPrimary};

  ${bold};
  ${caps};
  ${color};
  ${danger};
  ${disabled};
  ${fontSize};
  ${fontWeight};
  ${italic};
  ${letterSpacing};
  ${lineHeight};
  ${lower};
  ${maxWidth};
  ${minWidth};
  ${medium};
  ${space};
  ${success};
  ${textAlign};
  ${upper};
`

// Work around for static members on a SC object
const Text = TextBase as typeof TextBase & {
  p: StyledComponent<'p', any, Props, never>
  span: StyledComponent<'span', any, Props, never>
}

Text.defaultProps = {
  bold: false,
  caps: false,
  danger: false,
  disabled: false,
  italic: false,
  letterSpacing: 0,
  success: false,
}

Text.displayName = 'Text'
Text.defaultProps = {
  color: 'textPrimary',
}

Text.p = Text.withComponent('p')
Text.p.defaultProps = {
  color: 'textPrimary',
  maxWidth: '50em',
}

Text.span = Text.withComponent('span')
Text.span.defaultProps = {
  color: 'textPrimary',
}

export default Text
