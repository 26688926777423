import React from 'react'

import { useMutation } from '@apollo/client'

import { Box, Upload, Flex } from 'stardust'

import { useSelectedServiceFkey } from '~/contexts/Service'

import { sendAppSignalError } from '~/logging'

import { UPLOAD_MEDIA } from '~/pages/Observations/mutations'
import uploadMultipleFiles from '~/utils/uploadMultipleFiles'

import { FieldProps } from '../../types'

interface MediaProps extends FieldProps {
  button?: React.ReactNode
  loading: any
  media: Playground.MediaItem[]
  uploadedMedia: Playground.MediaItem[]
  isReadOnly: boolean
  handleUploadedMedia: (uploadedMedia: Playground.MediaItem) => void
  setUploadErrors: (error: Playground.Error[]) => void
}

const MAX_MEDIA_COUNT = 32
const MAX_VIDEO_COUNT = 1
const MAX_AUDIO_FILE_COUNT = 1
const MAX_DOCUMENT_FILE_COUNT = 5

const isVideoMediaAttached = (media: Playground.MediaItem) => media.mimeType.includes('video/')
const isImageMediaAttached = (media: Playground.MediaItem) => media.mimeType.includes('image/')
const isAudioMediaAttached = (media: Playground.MediaItem) => media.mimeType.includes('audio/')
const isDocumentMediaAttached = (media: Playground.MediaItem) =>
  media.mimeType.includes('application/') || media.mimeType.includes('text/')

const isVideoFile = (file: any) => file.type.includes('video/')
const isImageFile = (file: any) => file.type.includes('image/')
const isAudioFile = (file: any) => file.type.includes('audio/')
const isDocumentFile = (file: any) => file.type.includes('application/') || file.type.includes('text/')

const Media = ({ handleUploadedMedia, setUploadErrors, button, loading, media, isReadOnly }: MediaProps) => {
  const serviceFkey = useSelectedServiceFkey()
  const [isLoading, setIsLoading] = loading

  const [uploadMedia] = useMutation(UPLOAD_MEDIA)

  const existingMedia = () => [...media]
  const existingPhotoAndVideoMedia = () =>
    existingMedia().filter((media) => isVideoMediaAttached(media) || isImageMediaAttached(media))
  const existingVideoMedia = () => existingMedia().filter((media) => isVideoMediaAttached(media))
  const existingAudioMedia = () => existingMedia().filter((media) => isAudioMediaAttached(media))
  const existingDocumentMedia = () => existingMedia().filter((media) => isDocumentMediaAttached(media))

  const existingPhotoAndVideoCount = existingPhotoAndVideoMedia().length
  const existingDocumentMediaCount = existingDocumentMedia().length

  const remainingPhotoAndVideoCount = () => MAX_MEDIA_COUNT - existingPhotoAndVideoCount
  const remainingDocumentCount = () => MAX_DOCUMENT_FILE_COUNT - existingDocumentMediaCount

  const handleOnChange = ({ target: { validity, files: fileList } }: any) => {
    const files = [...fileList]

    const newVideoFiles = () => files.filter((file) => isVideoFile(file))
    const newPhotoFiles = () => files.filter((file) => isImageFile(file))
    const newPhotoAndVideoFiles = () => [...newVideoFiles(), ...newPhotoFiles()]
    const newAudioFiles = () => files.filter((file) => isAudioFile(file))
    const newDocumentFiles = () => files.filter((file) => isDocumentFile(file))

    const newVideoFileCount = newVideoFiles().length
    const totalNewPhotoAndVideoCount = newPhotoAndVideoFiles().length
    const newAudioFileCount = newAudioFiles().length
    const newDocumentFileCount = newDocumentFiles().length

    if (!validity.valid) {
      return setUploadErrors([{ name: 'Error', type: 'File validation failed' }])
    }

    if (totalNewPhotoAndVideoCount > remainingPhotoAndVideoCount()) {
      return setUploadErrors([
        {
          name: 'Error',
          type: `Maximum ${MAX_MEDIA_COUNT} media files allowed, which includes 1 video file.`,
          errorMessage: 'Please reduce the number of media attached',
        },
      ])
    }

    if (newDocumentFileCount > remainingDocumentCount()) {
      return setUploadErrors([
        {
          name: 'Error',
          type: `Maximum ${MAX_DOCUMENT_FILE_COUNT} files allowed.`,
          errorMessage: 'Please reduce the number of files attached.',
        },
      ])
    }

    if (newVideoFileCount > 0 && [...existingVideoMedia(), ...newVideoFiles()].length > MAX_VIDEO_COUNT) {
      return setUploadErrors([
        {
          name: 'Error',
          type: `Maximum ${MAX_VIDEO_COUNT} video allowed.`,
          errorMessage: 'Please reduce the number of videos attached.',
        },
      ])
    }

    if (
      newAudioFileCount > 0 &&
      [...existingAudioMedia(), ...newAudioFiles()].length > MAX_AUDIO_FILE_COUNT
    ) {
      return setUploadErrors([
        {
          name: 'Error',
          type: `Maximum ${MAX_AUDIO_FILE_COUNT} audio file allowed.`,
          errorMessage: 'Please reduce the number of audio files attached.',
        },
      ])
    }

    setIsLoading(true)

    uploadMultipleFiles(files, serviceFkey, uploadMedia, handleUploadedMedia)
      .then((uploadErrors: Playground.Error[]) => {
        setUploadErrors(uploadErrors)
        setIsLoading(false)
      })
      .catch((err: any) => {
        console.error(err)
        sendAppSignalError(err)
        setIsLoading(false)
        setUploadErrors([{ name: 'Error', type: 'Failed to upload media' }])
      })
  }

  return (
    <Flex>
      {!isReadOnly && (
        <Box screenOnly>
          <Upload
            button={button}
            text="hello"
            isLoading={isLoading}
            accept="image/*, video/*, audio/*, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt"
            onClick={() => {
              setUploadErrors([])
            }}
            style={{
              width: '150px',
              height: '150px',
              boxSizing: 'border-box',
              marginRight: '16px',
              marginTop: '4px',
            }}
            onChange={handleOnChange}
            multiple
          />
        </Box>
      )}
    </Flex>
  )
}

Media.displayName = 'Media'

export default Media
