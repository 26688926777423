import React from 'react'

import * as R from 'ramda'

import Node from './Node'

const Nodes = ({
  handleSelectObservation,
  handleSelectPlan,
  handleSelectStory,
  handleSelectTable,
  handleSelectMoment,
  nodeIds,
  nodes,
}) =>
  R.map((nodeId) => {
    const node = nodes[nodeId]

    return (
      <Node
        handleSelectObservation={handleSelectObservation}
        handleSelectPlan={handleSelectPlan}
        handleSelectStory={handleSelectStory}
        handleSelectMoment={handleSelectMoment}
        handleSelectTable={handleSelectTable}
        key={nodeId}
        {...node}
      />
    )
  }, nodeIds)

Nodes.displayName = 'Nodes'
export default Nodes
