import { startCase } from 'lodash'

const assetTypeToIcon: { [userId: string]: string } = {
  VIDEO: 'learningContentVideo',
  PODCAST: 'learningContentPodcast',
  ARTICLE: 'learningContentArticle',
}

enum LearningContentAssetType {
  VIDEO = 'VIDEO',
  ARTICLE = 'ARTICLE',
  PODCAST = 'PODCAST',
}

// 69 -> "1:09"
const humanizeAssetLength = (len: number): string => (len - (len %= 60)) / 60 + (9 < len ? ':' : ':0') + len
const humanizeType = (docType: Playground.LearningContentAssetType) => startCase(docType.toLowerCase())

export function humanizeAsset<T extends Playground.LearningContentAsset>(
  rawAsset: T
): T & Playground.LearningContentAssetHumanizedExtra {
  return {
    ...rawAsset,
    humanizedDocumentLength: humanizeAssetLength(rawAsset.documentLength),
    humanizedType: humanizeType(rawAsset.type),
    icon: assetTypeToIcon[rawAsset.type] || assetTypeToIcon['VIDEO'],
    isArticle: rawAsset.type === LearningContentAssetType.ARTICLE,
    isVideo: rawAsset.type === LearningContentAssetType.VIDEO,
    isPodcast: rawAsset.type === LearningContentAssetType.PODCAST,
  }
}

export const humanizeFullAsset = (
  rawAsset: Playground.LearningContentAssetFull
): Playground.LearningContentAssetFullHumanized => {
  return {
    ...humanizeAsset(rawAsset),
    firstCategory: rawAsset.categories[0]?.name || '',
  }
}
