import React from 'react'

import * as R from 'ramda'

import { Flex } from 'stardust'

import camera from '~/assets/images/camera.svg'

import healthEventGraph from '~/assets/images/chart.svg'
import parentGuardians from '~/assets/images/parents.svg'

import MetricItem from './MetricItem'

interface Props {
  room: Playground.Room
  inline?: boolean
}

const RoomMetrics = ({ room, inline = false }: Props) => (
  <Flex width={1} style={{ boxSizing: 'border-box' }}>
    <MetricItem count={R.path(['metrics', 'observations'], room)} icon={camera} label="Posts" />

    <MetricItem
      count={R.path(['metrics', 'healthEvents'], room)}
      icon={healthEventGraph}
      label="Health Events"
    />

    {!inline && <MetricItem count={room.ageGroup} icon={parentGuardians} label="Age Range" large />}
  </Flex>
)

RoomMetrics.displaName = 'RoomMetrics'
export default RoomMetrics
