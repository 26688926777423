import React from 'react'

const Hand = () => (
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M12.247 15.997H7.88c-.72 0-1.427-.3-1.927-.82l-4.866-5.074 1.38-1.22a1.335 1.335 0 0 1 1.506-.18l1.607.854V3.19a1.667 1.667 0 0 1 2.007-1.633c.06-.867.78-1.554 1.66-1.554.573 0 1.073.287 1.373.727a1.667 1.667 0 0 1 2.293 1.547v.186a1.667 1.667 0 0 1 2 1.633v9.234a2.666 2.666 0 0 1-2.666 2.667zm-9.24-5.814l3.906 4.067c.254.26.6.413.96.413h4.374c.733 0 1.333-.6 1.333-1.333V4.097a.33.33 0 0 0-.333-.334.33.33 0 0 0-.334.334v3.9H11.58v-5.72a.33.33 0 0 0-.333-.334.33.33 0 0 0-.334.334v5.72H9.58V1.67a.33.33 0 0 0-.333-.333.33.33 0 0 0-.334.333v6.327H7.58V3.19a.33.33 0 0 0-.333-.333.334.334 0 0 0-.334.333v8.58L3.347 9.883l-.34.3z"
    />
  </svg>
)

export default Hand
