import React, { ReactNode, createContext } from 'react'

export type HeaderTab = {
  key: string
  label: string
  path: string
  permission: {
    action: string
    unpermittedUserOrigins?: Playground.UserOrigin[]
  }
}

export type HeaderContextProps = {
  articleType: Playground.DocumentType
  title: string
  tabs: HeaderTab[]
}

const initialState = (): HeaderContextProps => {
  return { articleType: 'observation', title: '', tabs: [] }
}

export const HeaderContext = createContext<HeaderContextProps>(initialState())

interface Props {
  children: ReactNode
  stateFactory: () => HeaderContextProps
}

export const HeaderProvider = ({ children, stateFactory: initialState }: Props) => {
  return <HeaderContext.Provider value={initialState()}>{children}</HeaderContext.Provider>
}
