import { addDays, addYears, endOfDay, format, startOfDay } from 'date-fns'

import { DateRange } from '~/dateRange'

export type DateType = Date | DateRange

export interface PresetPeriod<T> {
  key: string
  label: string
  period: T | undefined
}

///// Date Ranges /////

const buildRange = (start: Date, end: Date) => ({ from: startOfDay(start), to: endOfDay(end) })

const today = new Date()

export const presetDateRanges: Record<string, PresetPeriod<DateRange>> = {
  today: {
    key: 'today',
    label: 'Today',
    period: buildRange(today, today),
  },
  yesterday: {
    key: 'yesterday',
    label: 'Yesterday',
    period: buildRange(addDays(today, -1), addDays(today, -1)),
  },
  last7Days: {
    key: 'last-7-days',
    label: 'Last 7 Days',
    period: buildRange(addDays(today, -6), today),
  },
  last14Days: {
    key: 'last-14-days',
    label: 'Last 14 Days',
    period: buildRange(addDays(today, -13), today),
  },
  last30Days: {
    key: 'last-30-days',
    label: 'Last 30 Days',
    period: buildRange(addDays(today, -29), today),
  },
  lastYear: {
    key: 'last-year',
    label: 'Last Year',
    period: buildRange(addYears(today, -1), today),
  },
}

export const presetEmptyDateRange = {
  key: 'all',
  label: 'All',
  period: undefined,
}

export const defaultPresetDateRanges = [
  presetDateRanges.today,
  presetDateRanges.yesterday,
  presetDateRanges.last7Days,
  presetDateRanges.last14Days,
  presetDateRanges.last30Days,
]

export const extractDatesFromDateRange = (dateRange: Nullable<string>) => {
  const parsedRange = parseDateRangeFromFilter(dateRange)
  return (
    parsedRange && {
      first: format(parsedRange.from, 'YYYY-MM-DD'),
      last: format(parsedRange.to, 'YYYY-MM-DD'),
    }
  )
}

export const parseDateRangeFromFilter = (range: Nullable<string>): Undefinedable<DateRange> => {
  if (range) {
    return JSON.parse(range, (k, v) => {
      switch (k) {
        case 'from':
          return startOfDay(new Date(v))
        case 'to':
          return endOfDay(new Date(v))
        default:
          return v
      }
    })
  }
}

///// Dates /////

export const presetDates: Record<string, PresetPeriod<Date>> = {
  today: {
    key: 'today',
    label: 'Today',
    period: today,
  },
  yesterday: {
    key: 'yesterday',
    label: 'Yesterday',
    period: addDays(today, -1),
  },
}

export const presetEmptyDate = { key: 'none', label: 'None', period: undefined }

export const defaultPresetDates = [presetDates.today, presetDates.yesterday]
