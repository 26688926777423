import React, { useEffect, useRef, useState } from 'react'

import { Box } from 'stardust'

import ZoomControls from '~/components/ZoomControls'
import MomentModal from '~/pages/Moments/Modal'

import PlanModal from '~/pages/Planning/Modal'
import StoryModal from '~/pages/Stories/Modal'
import TableModal from '~/pages/Tables/Modal'
import { hasAtLeastOne } from '~/utils'

import plannerPosition from '../core/plannerPosition'
import * as s from '../core/selectors'
import transformFromSave from '../core/transforms/transformFromSave'

import { bindZoomBehaviour, scaleMax } from '../core/zoom'
import ClipPaths from '../shapes/ClipPaths'
import TransformLayer from '../TransformLayer'

import { scaleToPercentageString } from '../utils'

import Container from './Container'
import Nodes from './Nodes'
import ZoomLayer from './Zoom'

const Canvas = (props) => {
  const transformRef = useRef(null)
  const zoomRef = useRef(null)

  const [zoomBehaviour, setZoomBehaviour] = useState(null)
  const [zoomDisplay, setZoomDisplay] = useState('Zoom')
  const [momentId, setMomentId] = useState(null)
  const [planId, setPlanId] = useState(null)
  const [storyId, setStoryId] = useState(null)
  const [tableId, setTableId] = useState(null)

  const canShowMoment = !!momentId
  const canShowPlan = !!planId
  const canShowStory = !!storyId
  const canShowTable = !!tableId

  const data = transformFromSave(props)
  const nodes = s.nodes.get(data)
  const nodeIds = s.nodeIds.get(data)
  const background = s.background.get(data)

  const [transform, setTransform] = useState({ x: 0, y: 0, k: 1 })

  const { xmid, ymid, xdiff, ydiff } = plannerPosition(nodes)
  /**
   * Governs zoom bindings
   */
  useEffect(() => {
    const zoom = bindZoomBehaviour(
      ({ k }) => setZoomDisplay(scaleToPercentageString(scaleMax, k)),
      (a) => setTransform(a),
      zoomRef,
      transformRef,
      xmid,
      ymid,
      xdiff,
      ydiff
    )
    setZoomBehaviour(zoom)
  }, [xdiff, xmid, ydiff, ymid, setTransform])

  const handleSelectObservation = (id) => {
    if (id) {
      window.open(`/observations/${id}`)
    }
  }

  return (
    <>
      <Container
        background={background}
        width="100%"
        modal={props.modal}
        className="Canvas"
        printPreviewOffset={props.printPreviewOffset}>
        <ZoomLayer ref={zoomRef} />

        {hasAtLeastOne(nodeIds) && (
          <TransformLayer ref={transformRef} transform={transform}>
            <Nodes
              handleSelectMoment={setMomentId}
              handleSelectObservation={handleSelectObservation}
              handleSelectPlan={setPlanId}
              handleSelectStory={setStoryId}
              handleSelectTable={setTableId}
              nodeIds={nodeIds}
              nodes={nodes}
            />
          </TransformLayer>
        )}

        {!props.modal && hasAtLeastOne(nodeIds) && zoomBehaviour && (
          <Box className="Canvas__ZoomControls">
            <ZoomControls
              scale={zoomDisplay}
              zoomIn={zoomBehaviour.in}
              zoomMax={zoomBehaviour.max}
              zoomMin={zoomBehaviour.min}
              zoomOut={zoomBehaviour.out}
            />
          </Box>
        )}
      </Container>

      <ClipPaths />

      <MomentModal id={momentId} show={canShowMoment} onClose={() => setMomentId(null)} />

      <PlanModal contentStatus={'PUBLISHED'} id={planId} show={canShowPlan} onClose={() => setPlanId(null)} />

      <StoryModal
        contentStatus="PUBLISHED"
        id={storyId}
        show={canShowStory}
        onClose={() => setStoryId(null)}
      />

      <TableModal
        contentStatus={'PUBLISHED'}
        id={tableId}
        show={canShowTable}
        onClose={() => setTableId(null)}
      />
    </>
  )
}

Canvas.displayName = 'Canvas'

export default Canvas
