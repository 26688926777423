import React from 'react'

import styled from 'styled-components'

import TextField, { TextFieldProps } from './TextField'
import colors from './tokens/colors'

interface StyledTextFieldProps extends TextFieldProps {
  borderRadius?: string | number
  height?: string | number
}

const StyledTextField = styled(TextField)`
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: white;
  }
  width: 100%;
  border-style: solid;
  border-width: 2px;
  background-color: white;
  height: ${(props) => props.height || 'auto'};

  .mdc-text-field--with-trailing-icon {
    padding-bottom: ${(props) => props.pb || ''};
  }
  height: ${(props) => props.height || 'auto'};

  .mdc-text-field--with-trailing-icon {
    padding-bottom: ${(props) => props.pb || ''};
  }
  .mdc-text-field--filled.mdc-text-field--no-label .mdc-text-field__input {
    height: ${(props) => props.height || '100%'};
  }

  .mdc-text-field__input {
    padding-top: 0px;
    background-color: white;
  }

  .mdc-text-field-helper-line {
    justify-content: flex-end;
  }
`

const InputField = React.forwardRef(({ borderRadius, height, ...props }: StyledTextFieldProps, ref) => (
  <StyledTextField
    borderRadius={borderRadius || 100}
    height={height}
    border="1px solid"
    borderColor={colors.nebulaBlue7}
    overflow="hidden"
    outlined={false}
    type="text"
    autoComplete="off"
    ref={ref}
    {...props}
  />
))

InputField.displayName = 'InputField'

export default React.memo(InputField)
