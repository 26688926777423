import React from 'react'

import * as R from 'ramda'

import { Flex } from 'stardust'

import camera from '~/assets/images/camera.svg'

import healthEventGraph from '~/assets/images/chart.svg'
import parentGuardians from '~/assets/images/parents.svg'

import MetricItem from './MetricItem'

const EducatorMetrics = ({ educator, ...props }) => (
  <Flex width={1}>
    <MetricItem
      count={R.path(['metrics', 'observations'], educator)}
      icon={camera}
      label="Posts"
      {...props}
    />

    <MetricItem
      count={R.path(['metrics', 'healthEvents'], educator)}
      icon={healthEventGraph}
      label="Health Events"
      {...props}
    />

    {!props.inline && (
      <MetricItem
        count={R.path(['metrics', 'parentEngagement'], educator) || 0}
        icon={parentGuardians}
        label="Parent Engagement"
        large
        {...props}
      />
    )}
  </Flex>
)

EducatorMetrics.displaName = 'EducatorMetrics'
export default EducatorMetrics
