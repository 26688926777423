const palette = {
  auroraGreen0: '#F0FCEA',
  auroraGreen1: '#DBF7CB',
  auroraGreen2: '#C2F1AA',
  auroraGreen3: '#A8EB85',
  auroraGreen4: '#92E567',
  auroraGreen5: '#80DF4E',
  auroraGreen6: '#70CE46',
  auroraGreen7: '#5AB93B',
  auroraGreen8: '#45A433',
  auroraGreen9: '#188022',
}

export default {
  ...palette,
  auroraGreen: palette.auroraGreen3,
}
