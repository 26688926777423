import React from 'react'

const CommentsAlt3 = () => (
  <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.0625 1.875C1.6828 1.875 1.375 2.1828 1.375 2.5625V16.3125C1.375 16.6922 1.6828 17 2.0625 17H4.8125C5.1922 17 5.5 17.3078 5.5 17.6875V20.4375L9.9 17.1375C10.019 17.0482 10.1637 17 10.3125 17H19.9375C20.3172 17 20.625 16.6922 20.625 16.3125V2.5625C20.625 2.1828 20.3172 1.875 19.9375 1.875H2.0625ZM0 2.5625C0 1.42341 0.923413 0.5 2.0625 0.5H19.9375C21.0766 0.5 22 1.42341 22 2.5625V16.3125C22 17.4516 21.0766 18.375 19.9375 18.375H10.5417L5.225 22.3625C5.01668 22.5187 4.73795 22.5439 4.50504 22.4274C4.27213 22.311 4.125 22.0729 4.125 21.8125V18.375H2.0625C0.923413 18.375 0 17.4516 0 16.3125V2.5625ZM4.125 6.6875C4.125 6.3078 4.4328 6 4.8125 6H17.1875C17.5672 6 17.875 6.3078 17.875 6.6875C17.875 7.0672 17.5672 7.375 17.1875 7.375H4.8125C4.4328 7.375 4.125 7.0672 4.125 6.6875ZM4.8125 10.125C4.4328 10.125 4.125 10.4328 4.125 10.8125C4.125 11.1922 4.4328 11.5 4.8125 11.5H14.4375C14.8172 11.5 15.125 11.1922 15.125 10.8125C15.125 10.4328 14.8172 10.125 14.4375 10.125H4.8125Z"
      fill="#00776B"
    />
  </svg>
)

export default CommentsAlt3
