import React from 'react'

import { navigate } from '@reach/router'

import { Flex } from 'stardust'

import ArticleLoader from '~/components/ArticleLoader'
import Can from '~/components/Authorisation/Can'
import DocumentModal from '~/components/DocumentModal'
import DocumentModalPlaceholder from '~/components/DocumentModalPlaceholder'
import ModalButton from '~/components/ModalButton'
import { isHome } from '~/config'
import { useSelectedServiceFkey } from '~/contexts/Service'
import { learningPlans as lPlan } from '~/modules/permissions'

import PlanView from './components/Plan/PlanView'
import { GET_PLAN_DETAILED } from './queries'

const defaultStatus = isHome ? 'PUBLISHED' : 'DRAFT'

interface Props {
  contentStatus: Playground.DocumentStatus
  id: number
  show: boolean
  onClose(): void
}

const PlanModal = ({ contentStatus, id, show, onClose }: Props) => {
  const serviceFkey = useSelectedServiceFkey()

  const variables = {
    id: Number(id),
    serviceFkey,
    contentStatus: contentStatus || defaultStatus,
  }

  const modalHeaderButtons = () => (
    <Flex alignItems="center" flexDirection="row">
      <ModalButton
        onClick={() => {
          // Close for when we're already on edit page (after Publish)
          onClose()
          navigate(`/planning/print/${id}`, { state: { status: contentStatus } })
        }}
        icon={'printer'}
        text={'Print'}
      />

      <Can
        perform={lPlan.write}
        yes={() => (
          <ModalButton
            onClick={() => {
              onClose()
              navigate(`/planning/${id}`)
            }}
            icon={'editOutlined'}
            text={'Edit'}
          />
        )}
      />
    </Flex>
  )

  if (!id || !show) {
    return null
  }

  return (
    <ArticleLoader
      placeholder={() => <DocumentModalPlaceholder onClose={onClose} />}
      query={GET_PLAN_DETAILED}
      type="plan"
      variables={variables}>
      {({ article, articleContent }) => (
        <DocumentModal open={show} onClose={onClose} renderHeader={modalHeaderButtons}>
          <PlanView article={article as Playground.Article} articleContent={articleContent} />
        </DocumentModal>
      )}
    </ArticleLoader>
  )
}

PlanModal.displayName = 'PlanModal'

export default React.memo(PlanModal)
