import React, { ReactNode } from 'react'

import { Flex, Text } from 'stardust'

interface Props {
  children: ReactNode
  icon: ReactNode
}

const Alert = ({ children, icon }: Props) => (
  <Flex alignItems="center" borderRadius={2} border="solid 1px" borderColor="nebulaBlue" p={2}>
    <Flex alignItems="center">
      {icon}
      <Text color="nebulaBlue" fontSize={0}>
        {children}
      </Text>
    </Flex>
  </Flex>
)

Alert.displayName = 'Alert'

export default React.memo(Alert)
