import isSameDay from 'date-fns/is_same_day'
import isSameMonth from 'date-fns/is_same_month'
import * as R from 'ramda'

import { filterBy } from '~/utils'

const groupWithProp = (prop, groupFn) =>
  R.pipe(
    R.sortWith([R.descend(R.prop(prop))]),
    R.groupWith((a, b) => {
      const [aProp, bProp] = [R.prop(prop, a), R.prop(prop, b)]
      return groupFn(aProp, bProp)
    })
  )

export const filterByContentTitle = filterBy(R.path(['content', 0, 'title']))
export const groupWithInsertedAt = groupWithProp('insertedAt', isSameDay)
export const groupWithInsertedAtMonth = groupWithProp('documentDate', isSameMonth)
export const groupWithUpdatedAt = groupWithProp('updatedAt', isSameDay)
export const groupWithObservationDate = groupWithProp('observationDate', isSameDay)

export const firstMedia = (props, doc) =>
  R.pipe(R.prop('media'), R.head, R.defaultTo({}), R.pickAll(props))(doc)
