import React from 'react'

const Notification = () => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.33331 17.5C8.55072 18.2406 9.23022 18.75 10.0021 18.75C10.7739 18.75 11.4534 18.2406 11.6708 17.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 2V0.125"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2C13.4518 2 16.25 4.79822 16.25 8.25C16.25 14.1217 17.5 15.125 17.5 15.125H2.5C2.5 15.125 3.75 13.5283 3.75 8.25C3.75 4.79822 6.54822 2 10 2Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Notification
