import React from 'react'

import { Box, Divider, Flex, StencilLine } from 'stardust'

import DocumentModal from '~/components/DocumentModal'

interface Props {
  loading?: boolean
  onClose(): void
}

const ArticleModalPlaceholder = ({ loading = true, onClose }: Props) => {
  return (
    <DocumentModal open={loading} onClose={onClose}>
      <Flex flexDirection={'column'} width={1}>
        <Flex
          borderColor="surfacePrimaryBorder"
          borderLeft="1px solid"
          flexDirection="column"
          pl={3}
          width={undefined}>
          <Box p={3} pl={0}>
            <StencilLine mb={2} py={1} width={1 / 5} />
            <StencilLine py={2} width={2 / 5} />
          </Box>

          <Divider borderColor="surfacePrimaryBorder" m={0} width={1} />

          <Flex flexDirection="column" flexGrow={1} overflowY="auto" width={1}>
            <div>
              <Box borderBottom={1} borderColor="surfacePrimaryBorder" pr={3} py={3}>
                <StencilLine mb={2} py={2} width={1 / 3} />
              </Box>
              <StencilLine mt={3} py={2} width={2 / 3} />
              <StencilLine mt={3} py={1} width={1} />
              <StencilLine mt={3} py={1} width={1} />
              <StencilLine mt={3} py={1} width={1} />
              <StencilLine mt={3} py={1} width={1} />
              <StencilLine mt={3} py={1} width={1} />
              <StencilLine mt={3} py={1} width={1} />
              <StencilLine mt={3} py={1} width={3 / 5} />
              <StencilLine mt={4} py={2} width={1 / 3} />
              <StencilLine mt={3} py={1} width={1} />
              <StencilLine mt={3} py={1} width={1} />
              <StencilLine mt={3} py={1} width={1} />
              <StencilLine mt={3} py={1} width={1} />
              <StencilLine mt={3} py={1} width={1} />
              <StencilLine mt={3} py={1} width={1} />
              <StencilLine mt={3} py={1} width={2 / 5} />
            </div>
          </Flex>
        </Flex>
      </Flex>
    </DocumentModal>
  )
}

ArticleModalPlaceholder.displayName = 'ArticleModalPlaceholder'

export default React.memo(ArticleModalPlaceholder)
