import React from 'react'

const Bookmark = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.33398 14V5C3.33398 3.34315 4.67713 2 6.33398 2H9.66732C11.3242 2 12.6673 3.34315 12.6673 5V14L8.00065 10.6667L3.33398 14Z"
      fill="#F69202"
    />
    <path
      d="M12.6673 14L8.00065 10.6667L3.33398 14V3.33333C3.33398 2.97971 3.47446 2.64057 3.72451 2.39052C3.97456 2.14048 4.3137 2 4.66732 2H11.334C11.6876 2 12.0267 2.14048 12.2768 2.39052C12.5268 2.64057 12.6673 2.97971 12.6673 3.33333V14Z"
      stroke="#F69202"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Bookmark
