import React from 'react'

import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import * as R from 'ramda'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

import Loading from '~/components/Loading/Mentions'
import { useSelectedServiceFkey } from '~/contexts/Service'
import useScroll from '~/hooks/useScroll'
import { LAYERS } from '~/theme'
import { keyGen } from '~/utils'

import SuggestionList from './SuggestionList'

const SEARCH_TAGGABLE_ENTITIES = gql`
  query SearchTaggableEntities($serviceFkey: String!, $query: String!, $types: TaggableEntityType) {
    service(fkey: $serviceFkey) {
      fkey
      taggableEntities(query: $query, types: $types) {
        displayText
        imageUrl
        type
        typeFkey
      }
    }
  }
`

const Container = styled.div`
  background: ${(props) => props.theme.colors.surfaceSecondary};
  border: 1px solid #ddd;
  left: -10000px;
  max-height: 425px;
  min-width: 200px;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  top: -10000px;
  z-index: ${LAYERS.Dropdown};
`

const Suggestions = React.memo(({ types, query, onSelect, anchor }) => {
  const root = window.document.getElementById('root')
  const containerRef = React.createRef()
  const serviceFkey = useSelectedServiceFkey()

  const getKey = R.pipe(R.props(['type', 'typeFkey']), keyGen)

  useScroll(anchor, ({ bottom, left }) => {
    if (containerRef.current) {
      containerRef.current.style.top = `${bottom + 5}px`
      containerRef.current.style.left = `${left + 5}px`
    }
  })

  const { data, loading, error } = useQuery(SEARCH_TAGGABLE_ENTITIES, {
    variables: { serviceFkey, query, types },
  })

  if (error) {
    return null
  }

  return ReactDOM.createPortal(
    <Container ref={containerRef} data-test="suggestions">
      {loading && <Loading />}
      {data && (
        <SuggestionList
          onSelect={onSelect}
          entities={loading ? [] : data.service.taggableEntities}
          getKey={getKey}
        />
      )}
    </Container>,
    root
  )
})

Suggestions.displayName = 'Suggestions'
export default Suggestions
