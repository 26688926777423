import React from 'react'

const ObservationGeneral = () => (
  <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.5,36.6667C31.2445,36.6667 38.3333,29.5778 38.3333,20.8333C38.3333,12.0888 31.2445,5 22.5,5C13.7555,5 6.6666,12.0888 6.6666,20.8333C6.6666,29.5778 13.7555,36.6667 22.5,36.6667Z"
      fill="#E3FDFF"
    />
    <path
      d="M30.4167,17.0834H16.25C15.9185,17.0834 15.6005,16.9517 15.3661,16.7173C15.1317,16.4828 15,16.1649 15,15.8334C15,15.5019 15.1317,15.1839 15.3661,14.9495C15.6005,14.7151 15.9185,14.5834 16.25,14.5834H30.4167C30.7482,14.5834 31.0661,14.7151 31.3005,14.9495C31.535,15.1839 31.6667,15.5019 31.6667,15.8334C31.6667,16.1649 31.535,16.4828 31.3005,16.7173C31.0661,16.9517 30.7482,17.0834 30.4167,17.0834Z"
      fill="#444B54"
    />
    <path
      d="M26.3667,22.0834H16.25C15.9185,22.0834 15.6005,21.9517 15.3661,21.7173C15.1317,21.4828 15,21.1649 15,20.8334C15,20.5019 15.1317,20.1839 15.3661,19.9495C15.6005,19.7151 15.9185,19.5834 16.25,19.5834H26.3667C26.6982,19.5834 27.0161,19.7151 27.2505,19.9495C27.485,20.1839 27.6167,20.5019 27.6167,20.8334C27.6167,21.1649 27.485,21.4828 27.2505,21.7173C27.0161,21.9517 26.6982,22.0834 26.3667,22.0834Z"
      fill="#444B54"
    />
    <path
      d="M28.3333,27.0834H16.25C15.9185,27.0834 15.6005,26.9517 15.3661,26.7173C15.1317,26.4828 15,26.1649 15,25.8334C15,25.5019 15.1317,25.1839 15.3661,24.9495C15.6005,24.7151 15.9185,24.5834 16.25,24.5834H28.3333C28.6649,24.5834 28.9828,24.7151 29.2172,24.9495C29.4516,25.1839 29.5833,25.5019 29.5833,25.8334C29.5833,26.1649 29.4516,26.4828 29.2172,26.7173C28.9828,26.9517 28.6649,27.0834 28.3333,27.0834Z"
      fill="#444B54"
    />
    <path
      d="M22.8666,36.9666H17.3833C17.0473,36.9494 16.7311,36.8026 16.5011,36.5571C16.2711,36.3115 16.1452,35.9864 16.15,35.65C16.1425,35.4897 16.169,35.3297 16.228,35.1804C16.2869,35.0312 16.3769,34.8962 16.4919,34.7844C16.6069,34.6725 16.7444,34.5863 16.8951,34.5316C17.0459,34.4768 17.2067,34.4547 17.3666,34.4666H22.8666C25.6843,34.4666 28.4387,33.6313 30.7818,32.0662C33.1248,30.5012 34.9513,28.2766 36.0303,25.6737C37.1094,23.0708 37.3925,20.2065 36.844,17.4427C36.2956,14.6789 34.94,12.1398 32.9488,10.1462C30.9576,8.1527 28.42,6.7942 25.6569,6.2425C22.8938,5.6908 20.0291,5.9706 17.4249,7.0466C14.8208,8.1226 12.5941,9.9464 11.0263,12.2876C9.4585,14.6288 8.6199,17.3823 8.6166,20.2V25.4C8.6203,26.2804 8.7493,27.1559 9,28C9.0728,28.2261 9.0768,28.4688 9.0115,28.6973C8.9462,28.9258 8.8146,29.1297 8.6333,29.2833L2.9333,34.4166H9.6C9.9315,34.4166 10.2494,34.5483 10.4839,34.7827C10.7183,35.0172 10.85,35.3351 10.85,35.6666C10.85,35.9981 10.7183,36.3161 10.4839,36.5505C10.2494,36.7849 9.9315,36.9166 9.6,36.9166H2.9333C2.4229,36.9161 1.9247,36.7604 1.5048,36.4701C1.085,36.1798 0.7633,35.7688 0.5825,35.2914C0.4017,34.8141 0.3703,34.2931 0.4925,33.7975C0.6147,33.3019 0.8847,32.8552 1.2666,32.5166L6.4166,27.8833C6.231,27.0516 6.136,26.2022 6.1333,25.35V20.2C6.1531,16.9108 7.1456,13.701 8.986,10.9748C10.8264,8.2486 13.4323,6.128 16.4756,4.8799C19.5188,3.6318 22.8633,3.3121 26.0879,3.961C29.3125,4.61 32.273,6.1986 34.5965,8.5267C36.92,10.8549 38.5027,13.8185 39.1453,17.0444C39.7878,20.2702 39.4614,23.6141 38.2073,26.6549C36.9532,29.6956 34.8273,32.2973 32.0975,34.1323C29.3676,35.9672 26.1559,36.9534 22.8666,36.9666Z"
      fill="#454B54"
    />
  </svg>
)

export default ObservationGeneral
