import gql from 'graphql-tag'

export default gql`
  fragment ObservationComments on Observation {
    comments {
      author {
        id
        image
        name
        type
      }
      content
      id
      insertedAt
      updatedAt
    }
  }
`
