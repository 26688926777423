import gql from 'graphql-tag'

export interface MarkNotificationsAsReadResponse {
  markNotificationsAsRead: {
    id: number
  }[]
}

export const MARK_NOTIFICATIONS_AS_READ = gql`
  mutation markNotificationsAsRead($ids: [Int]) {
    markNotificationsAsRead(ids: $ids) {
      id
    }
  }
`
