import { ValueJSON as SlateValueJSON } from 'slate'

export const CELL_DEFAULT_BACKGROUND = '#ffffff'

export const CELL_DEFAULT_VALUE: SlateValueJSON = {
  document: {
    nodes: [
      {
        object: 'block',
        type: 'paragraph',
        nodes: [
          {
            object: 'text',
            leaves: [{ text: '' }],
          },
        ],
      },
    ],
  },
}

export const CELL_MIN_HEIGHT = 40
export const CELL_MIN_WIDTH = 40

export const COLUMN_DEFAULT_WIDTH = 250

export const ROW_DEFAULT_HEIGHT = 40

export const TABLE_EDITOR_VERSION = 'table-editor-v-1.0.0,slate-v-0.42.6'
