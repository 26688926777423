import React from 'react'

import ChildSummary from './components/ChildSummary'
import EducatorSummary from './components/EducatorSummary'
import MentionedTooltip from './components/MentionedTooltip'
import RoomSummary from './components/RoomSummary'

interface Props {
  entity: Playground.TaggedEntity
  readOnly: boolean
}

const MentionedEntity = ({ entity, readOnly = false }: Props) => {
  const { displayText, type, typeFkey } = entity

  switch (type) {
    case 'educator':
      return <EducatorSummary educatorFkey={typeFkey} displayName={displayText} readOnly={readOnly} />
    case 'child':
      return <ChildSummary childFkey={typeFkey} displayName={displayText} readOnly={readOnly} />
    case 'room':
      return <RoomSummary roomFkey={typeFkey} displayName={displayText} readOnly={readOnly} />
    case 'learning_outcome':
      return <MentionedTooltip title={displayText} icon="learningFramework" readOnly={readOnly} />
    default:
      return <>{displayText}</>
  }
}

MentionedEntity.displayName = 'MentionedEntity'
export default MentionedEntity
