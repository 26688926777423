import React from 'react'

const MedicalReport = () => (
  <svg viewBox="0 0 41 40" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M33.2626,6.9075V35.3561C33.2626,37.4122 31.6435,39.0944 29.6645,39.0944H12.1776C10.1986,39.0944 8.5795,37.4122 8.5795,35.3561V6.9075"
      fill="#ffffff"
      fillRule="evenodd"
    />
    <path d="M19.122,17.8234h3.5981v12.1495h-3.5981z" fill="#ED97A0" />
    <path d="M15.0561,22.0477h11.6939v3.7383h-11.6939z" fill="#ED97A0" />
    <path
      d="M20.921,6.5337C22.3121,6.5337 23.4397,5.3621 23.4397,3.9169C23.4397,2.4716 22.3121,1.3001 20.921,1.3001C19.53,1.3001 18.4023,2.4716 18.4023,3.9169C18.4023,5.3621 19.53,6.5337 20.921,6.5337Z"
      fill="#ffffff"
      fillRule="evenodd"
    />
    <path
      d="M12.1776,40.1785H29.6645C32.2551,40.1785 34.3421,38.0103 34.378,35.3561V10.6458C34.378,7.9542 32.2911,5.786 29.7005,5.786H12.1776C9.5869,5.786 7.5,7.9542 7.5,10.6458V35.3187C7.5,38.0103 9.5869,40.1785 12.1776,40.1785ZM9.6589,10.6458C9.6589,9.1879 10.7743,8.029 12.1776,8.029H29.7005C31.1037,8.029 32.2192,9.1879 32.2192,10.6458V35.3561C32.2192,36.8141 31.1037,37.9729 29.7005,37.9729H12.1776C10.7743,37.9729 9.6589,36.8141 9.6589,35.3561V10.6458Z"
      fill="#454B54"
      fillRule="evenodd"
    />
    <path
      d="M14.9841,11.7673C14.3724,11.7673 13.9047,11.2814 13.9047,10.6458C13.9047,10.0103 14.3724,9.5244 14.9841,9.5244H26.8579C27.4696,9.5244 27.9374,10.0103 27.9374,10.6458C27.9374,11.2814 27.4696,11.7673 26.8579,11.7673H14.9841Z"
      fill="#454B54"
      fillRule="evenodd"
    />
    <path
      d="M17.3229,3.9169C17.3229,5.9729 18.942,7.6552 20.921,7.6552C22.9,7.6552 24.5191,5.9729 24.5191,3.9169C24.5191,1.8608 22.9,0.1786 20.921,0.1786C18.942,0.1786 17.3229,1.8608 17.3229,3.9169ZM19.4817,3.9169C19.4817,3.0945 20.1294,2.4216 20.921,2.4216C21.7126,2.4216 22.3602,3.0945 22.3602,3.9169C22.3602,4.7393 21.7126,5.4122 20.921,5.4122C20.1294,5.4122 19.4817,4.7393 19.4817,3.9169Z"
      fill="#454B54"
      fillRule="evenodd"
    />
  </svg>
)

export default MedicalReport
