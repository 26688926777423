import React, { ReactNode, useContext, useMemo } from 'react'

import RPath from 'ramda/src/path'

interface UserContextProps {
  currentRole: Nullable<string>
  state: Nullable<Playground.User>
}

export const UserContext = React.createContext<UserContextProps>({
  currentRole: null,
  state: null,
})

export const useHomeFeatures = (): Playground.ChildPermission[] => {
  const { state: user } = useContext(UserContext)
  return user?.homeFeatures || []
}

export const useUserOrigin = (): Nullable<Playground.UserOrigin> => {
  const { state: user } = useContext(UserContext)
  return user?.origin
}

interface Props {
  children: ReactNode
  user: Playground.User
}

const UserProvider = ({ children, user: initialUser }: Props) => {
  const providerValue = useMemo(() => {
    return {
      currentRole: RPath<Nullable<string>>(['roles', 0], initialUser),
      state: initialUser,
    }
  }, [initialUser])

  return <UserContext.Provider value={providerValue}>{children}</UserContext.Provider>
}

UserProvider.displayName = 'UserProvider'

export default UserProvider
