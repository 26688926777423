import React from 'react'

const Canvas = () => (
  <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.16663 19.375L14.0283 14.5133"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.315 9.21C18.6946 9.14326 18.0688 9.14326 17.4483 9.21L15.7817 11.86L14.7575 9.9525C11.0417 11.7767 9.59085 16.1842 10.965 17.5608C12.8083 19.4075 20.1067 16.1733 19.315 9.21Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.875 16.875H1.875C1.18464 16.875 0.625 16.3154 0.625 15.625V1.875C0.625 1.18464 1.18464 0.625 1.875 0.625H10.7325C11.0638 0.625071 11.3815 0.756651 11.6158 0.990833L14.0092 3.38417C14.2433 3.6185 14.3749 3.93621 14.375 4.2675V6.875"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Canvas
