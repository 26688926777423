import React, { ReactNode, useState } from 'react'

import { Flex, OutlineButton } from 'stardust'

/*
  LimitedList: 
    Returns only DATA_ROWS_SHOWN_INITIAL of data list, plus a button
    that will load more if the user clicks on the button.
  
  Note: Expects a function as children

  <LimitedList<DataRow> data={data}>
  {(datum: DataRow) => (
    <li>{datum.name}</li>
  )}
</LimitedList>

TODO: allow change of styling of "Load More" button

*/

const DATA_ROWS_SHOWN_INITIAL = 20
const DATA_ROWS_ADDED_PER_CLICK = 20

interface Props<T> {
  data: T[]
  children: (data: T) => ReactNode
}

const LimitedList = <T,>({ data, children }: Props<T>) => {
  const [dataRowsShown, setDataRowsShown] = useState<number>(DATA_ROWS_SHOWN_INITIAL)
  const loadMoreData = () => setDataRowsShown(dataRowsShown + DATA_ROWS_ADDED_PER_CLICK)

  return (
    <>
      {data.slice(0, dataRowsShown - 1).map((datum: T) => children(datum))}
      {dataRowsShown < data.length && (
        <Flex px={3} py={2}>
          <OutlineButton onClick={loadMoreData}>Load More</OutlineButton>
        </Flex>
      )}
    </>
  )
}

export default LimitedList
