import * as R from 'ramda'

import { isHome } from '~/config'

const resultConfig = (idOrFkey) => (type) =>
  R.cond([
    [R.equals('moment'), R.always({ link: `/moments/${idOrFkey}`, name: 'Moment' })],
    [R.equals('observation'), R.always({ link: `/observations/${idOrFkey}`, name: 'Post' })],
    [R.equals('learning_plan'), R.always({ link: `/planning/${idOrFkey}`, name: 'Plan' })],
    [R.equals('learning_story'), R.always({ link: `/stories/${idOrFkey}`, name: 'Documents' })],
    [R.equals('learning_table'), R.always({ link: `/tables/${idOrFkey}`, name: 'Table' })],
    [R.equals('child'), R.always({ link: `/children/${idOrFkey}`, name: 'Child' })],
    [R.equals('educator'), R.always({ link: `/educators/${idOrFkey}`, name: 'Educator' })],
    [R.equals('room'), R.always({ link: `/rooms/${idOrFkey}`, name: 'Room' })],
    [R.T, R.always({ link: '/', name: '' })],
  ])(type)

export const resultType = (type, idOrFkey) => R.pipe(resultConfig(idOrFkey), R.prop('name'))(type)

export const isProfile = ({ type }) => R.contains(type, ['child', 'educator', 'room'])
export const isDocument = ({ type }) =>
  R.contains(type, ['moment', 'observation', 'learning_story', 'learning_plan', 'learning_table'])

export const resultLink = (type, idOrFkey, query) =>
  R.ifElse(
    (home) => home && isDocument({ type }),
    () => `/search?query=${query}&documentId=${type}-${idOrFkey}`,
    () => R.pipe(resultConfig(idOrFkey), R.prop('link'))(type)
  )(isHome)
