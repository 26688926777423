import React from 'react'

const Shapes = () => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d=" M11.5 2L6 11h11l-5.5-9zm0 3.84L13.43 9H9.56l1.94-3.16zM17 13c-2.49 0-4.5 2.01-4.5 4.5S14.51 22 17 22s4.5-2.01 4.5-4.5S19.49 13 17 13zm0 7a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5zM2.5 21.5h8v-8h-8v8zm2-6h4v4h-4v-4z    "
    />
  </svg>
)

export default Shapes
