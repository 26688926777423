import { Text } from 'stardust'
import styled from 'styled-components'

const MentionedText = styled(Text.span)`
  background-color: ${(props) => props.theme.colors.mentionedText};
  border-radius: 8px;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: -8px;
  max-width: 200px;
  overflow: hidden;
  padding: 4px 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
`

MentionedText.displayName = 'MentionedText'

export default MentionedText
