import { isHome } from '~/config'

import { useHomeFeatures } from '~/contexts/User'

const useHomeFeature = (homeFeature: Playground.ChildPermission | undefined) => {
  const homeFeatures = useHomeFeatures()
  if (!homeFeature || !isHome) return true
  return homeFeatures.includes(homeFeature)
}

export default useHomeFeature
