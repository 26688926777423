import React from 'react'

import { Link, Location } from '@reach/router'
import * as R from 'ramda'
import { Box, Flex, Text } from 'stardust'
import styled from 'styled-components'
import { space, width } from 'styled-system'

const StyledNavigationItem = styled(Box)`
  border-radius: 0px;
  ${space};
  ${width};
`

const Hover = styled(Box)`
  &:hover {
    background: ${(props) => props.theme.colors.hoverBackground};
  }
  &:active {
    background: ${(props) => props.theme.colors.hoverBackground};
    transition: background 0.5s ease;
  }
`

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.textPrimary};
  text-decoration: none;
`

const NavigationItem = ({ children, location, ...props }) => {
  return (
    <Location>
      {({ location: l }) => {
        let match = false

        if (location !== '/') {
          if (R.contains(location, l.pathname)) {
            match = true
          }
        } else {
          if (R.all(R.equals('/'), [location, l.pathname])) {
            match = true
          }
        }

        return (
          <StyledLink to={location} {...props}>
            <Hover>
              <Flex>
                <Box
                  border="solid 2px"
                  borderColor={`${match ? 'borderColor' : 'transparent'}`}
                  borderRadius={'20px'}
                  my={2}
                />
                <Box width={1}>
                  <StyledNavigationItem my={2} px={3} py={1} selected={match}>
                    <Text.span
                      bold={match}
                      fontSize={1}
                      color={match ? 'textActive' : 'textPrimaryMedium'}
                      letterSpacing={1}
                      lineHeight={1.3}>
                      {children}
                    </Text.span>
                  </StyledNavigationItem>
                </Box>
              </Flex>
            </Hover>
          </StyledLink>
        )
      }}
    </Location>
  )
}

NavigationItem.displayName = 'NavigationItem'

export default NavigationItem
