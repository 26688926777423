import * as R from 'ramda'

import transformChild from './transformChild'
import transformContent from './transformContent'
import transformDocument from './transformDocument'
import transformShape from './transformShape'
import transformSticker from './transformSticker'

/* 
  There are plans in prod created with plans-editor-v-0,
  so we transform the data blob in order to consume it with what we do now in v-2
*/
export default R.pipe(
  R.map((node) => {
    const { type } = node
    const newNode = {}

    switch (type) {
      case 'child': {
        return transformChild(node)
      }

      case 'document': {
        return transformDocument(node)
      }

      case 'shape': {
        return transformShape(node)
      }

      case 'content': {
        return transformContent(node)
      }

      case 'sticker': {
        return transformSticker(node)
      }

      default: {
        return newNode
      }
    }
  }),
  R.reduce(
    (acc, node) =>
      R.mergeDeepRight(acc, {
        nodes: { [node.id]: node },
        nodeIds: R.pipe(R.append(acc.nodeIds), R.flatten)([node.id]),
      }),
    {
      nodes: {},
      nodeIds: [],
    }
  )
)
