import RIsNil from 'ramda/src/isNil'
import RLensPath from 'ramda/src/lensPath'
import RSet from 'ramda/src/set'
import RUniq from 'ramda/src/uniq'
import RValues from 'ramda/src/values'
import { Data, Value as SlateValue } from 'slate'

import { CELL_DEFAULT_VALUE, TABLE_EDITOR_VERSION } from './constants'
import { TableCell, TableCellJSON, TableColumn, TableRow, TableState } from './types'

type JSONParsedTableContent = {
  cells: TableCellJSON[]
  rows: TableRow[]
  columns: TableColumn[]
  title: string
}

type EmbeddedTableData = {
  children: Playground.SimpleChild[]
  learningOutcomes: Playground.LearningFrameworkOutcome[]
  personnel: Playground.Educator[]
}

type SerializedTableState = Pick<TableState, 'cells' | 'columns' | 'rows' | 'title'>

const jsonCompare = (val1: any, val2: any) => {
  return JSON.stringify(val1) === JSON.stringify(val2)
}

// Get rid of cells in columns/rows that have been removed
const scrubTable = (state: SerializedTableState) => {
  const columnIds = state.columns.map((column) => column.id)
  const rowIds = state.rows.map((row) => row.id)

  const deltaCells = Object.entries(state.cells).reduce((acc, [key, cell]) => {
    if (columnIds.includes(cell.columnId) && rowIds.includes(cell.rowId)) {
      acc[key] = cell
    }
    return acc
  }, {} as Record<string, TableCell>)

  return { ...state, cells: deltaCells }
}

const validateTableState = (state: Record<string, any> | TableState) => {
  if (RIsNil(state.cells) || RIsNil(state.columns) || RIsNil(state.rows)) {
    throw new Error('Malformed table data')
  }
}

export function createCellWithValue(data: string) {
  const lensPath = RLensPath(['document', 'nodes', 0, 'nodes', 0, 'text'])
  return RSet(lensPath, data, CELL_DEFAULT_VALUE)
}

/*
 * tableContent:JSON stringified representation of a table
 * data: API retrieved data to use instead of embedded values (e.g. use child name from
 *       the API instead of the one contained in the doc). This is a pattern taken from
 *       the implementation in stories so we can leverage the already existing behaviour
 */

export function deserializeTable(tableContent: string, data?: EmbeddedTableData): TableState {
  const parsedTable: JSONParsedTableContent = JSON.parse(tableContent)

  validateTableState(parsedTable)

  const tableData = Data.create(data!)

  const cells = parsedTable.cells.reduce((acc: Record<string, TableCell>, cell: TableCellJSON) => {
    const cellKey = getCellKey(cell.columnId, cell.rowId)
    const cellValue = SlateValue.fromJSON({ ...cell.value, data: tableData })

    acc[cellKey] = { ...cell, forcedUpdate: false, value: cellValue }
    return acc
  }, {})

  return { ...parsedTable, cells, pointer: null, previousState: null, nextState: null }
}

export function getCellKey(columnId: string, rowId: string) {
  return `${columnId}/${rowId}`
}

export function getTaggedEntities(cells: Record<string, TableCell>) {
  const result = Object.values(cells).reduce((acc, cell) => {
    return [...acc, ...cell.taggedEntities]
  }, [] as Playground.TaggedEntity[])

  return RUniq(result)
}

export function equalCells(cell: Nullable<TableCell>, deltaCell: TableCell) {
  return (
    cell &&
    cell.background === deltaCell.background &&
    cell.columnId === deltaCell.columnId &&
    cell.rowId === deltaCell.rowId &&
    jsonCompare(cell.taggedEntities, deltaCell.taggedEntities) &&
    jsonCompare(cell.value.toJSON(), deltaCell.value.toJSON())
  )
}

export function serializeTable(state: SerializedTableState): string {
  validateTableState(state)

  const cells = RValues(state.cells).map((cell) => ({ ...cell, value: cell.value.toJSON() }))
  return JSON.stringify({ cells, columns: state.columns, rows: state.rows, title: state.title })
}

export function transformToSave(state: SerializedTableState): Partial<Playground.ArticleContent> {
  const scrubbedTable = scrubTable(state)
  return {
    data: serializeTable(scrubbedTable),
    taggedEntities: getTaggedEntities(scrubbedTable.cells),
    title: scrubbedTable.title,
    generatedWith: TABLE_EDITOR_VERSION,
  }
}
