import React from 'react'

import { Box, Flex, StencilLine } from 'stardust'

const EmptyImage = () => (
  <Flex flexDirection="column" justifyContent="center">
    <Box p={3} minHeight="232px" minWidth="192px">
      <StencilLine my={3} pt={1} />

      <Box my={3}>
        <StencilLine my={2} width={1} />
        <StencilLine my={2} width={3 / 4} />
        <StencilLine my={2} width={1} />
      </Box>

      <Box my={3}>
        <StencilLine my={2} width={1} />
        <StencilLine my={2} width={3 / 4} />
        <StencilLine my={2} width={1} />
      </Box>

      <StencilLine />
    </Box>
  </Flex>
)

export default EmptyImage
