import React from 'react'

import * as R from 'ramda'

import types from '../core/nodeTypes'
import * as selectors from '../core/selectors'
import useHover from '../core/useHover'
import Content from '../Nodes/Content'

import Sticker from '../Nodes/Sticker'

import Media from './Media'
import Shape from './Shape'
import View from './View'

const kindStrategy = R.cond([
  [R.equals(types.shape), R.always(Shape)],
  [R.equals(types.media), R.always(Media)],
  [R.equals(types.sticker), R.always(Sticker)],
  [R.equals(types.content), R.always(Content)],
])

const Node = (props) => {
  const [ref, hover] = useHover()

  const dimensions = selectors.dimensions.get(props)
  const point = selectors.point.get(props)
  const kind = selectors.kind.get(props)
  const z = selectors.z.get(props)

  const Component = kindStrategy(kind)

  // canEdit prop is for the slate editor
  return (
    <View dimensions={dimensions} point={point} ref={ref} z={z}>
      <Component {...props} hover={hover} canEdit={false} />
    </View>
  )
}

Node.displayName = 'Node'
export default Node
