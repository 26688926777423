import format from 'date-fns/format'
import parse from 'date-fns/parse'

import { Notification } from '../../queries'

export const formatDate = (date: string) => {
  const epochs = parse(date)
  return format(epochs, 'D MMMM YYYY')
}

export const getPlaygroundNotificationLink = (notification: Notification) => {
  switch (notification.category) {
    case 'CHILD_EVENT':
      return null
    case 'EDUCATOR_OBSERVATION':
      return `/observations/?observationId=${notification.metadata.observation_id}`
    case 'EVACUATION_REPORT':
      return null
    case 'INCIDENT_REPORT':
      return null
    case 'MEDICAL_REPORT':
      return null
    case 'PARENT_OBSERVATION':
      return `/moments/?momentId=${notification.metadata.moment_id}`
    case 'HEAD_COUNT_REPORT':
      return null
    default:
      return null
  }
}

export const getPlaygroundIconName = (notification: Notification) => {
  switch (notification.category) {
    case 'CHILD_EVENT':
      return 'observationRemoved'
    case 'EDUCATOR_OBSERVATION':
      return getObservationIconName(notification.subcategory)
    case 'EVACUATION_REPORT':
      return 'evacuationReport'
    case 'INCIDENT_REPORT':
      return 'incidentReport'
    case 'MEDICAL_REPORT':
      return 'medicalReport'
    case 'PARENT_OBSERVATION':
      return 'momentCreated'
    case 'HEAD_COUNT_REPORT':
      return 'headCountTick'
    default:
      return 'observationGeneral'
  }
}

const getObservationIconName = (subcategory: string) => {
  switch (subcategory) {
    case 'APPROVED':
      return 'observationApproved'
    case 'DELETED':
      return 'observationRemoved'
    case 'MODIFIED':
      return 'observationModified'
    default:
      return 'observationGeneral'
  }
}
