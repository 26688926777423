import gql from 'graphql-tag'

export default gql`
  fragment DraftPostContributors on DraftPost {
    contributors {
      id
      action
      fullName
      userType
    }
  }
`
