import React from 'react'

import { Flex, FlexItem } from 'stardust'

import ChildPill from './ChildPill'

interface Props {
  taggedChildren?: Playground.SimpleChild[]
  tagType?: string
}

const ChildGrid = ({ taggedChildren, tagType }: Props) => {
  return tagType === 'Children' ? (
    <Flex mt="10px" ml={3} mr={3} flexWrap="wrap">
      {taggedChildren?.map((child) => (
        <FlexItem mt={2} key={child.id}>
          <ChildPill image={child.image} fullName={child.fullName} />
        </FlexItem>
      ))}
    </Flex>
  ) : null
}

ChildGrid.displayName = 'ChildGrid'

export default React.memo(ChildGrid)
