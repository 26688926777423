import React from 'react'

import { Box, Flex, Text, Truncate } from 'stardust'

interface Props {
  rooms: Playground.Room[]
}

const Rooms = ({ rooms }: Props) => {
  return (
    <Box data-test="document-rooms">
      <Text bold mb={3} fontSize={2}>
        Rooms
      </Text>

      {rooms.map((room) => (
        <Flex key={'room' + room.fkey} alignItems="center" width={1} mt={2}>
          <Truncate caps fontSize={1}>
            {room.name}
          </Truncate>
        </Flex>
      ))}
    </Box>
  )
}

Rooms.displayName = 'DocumentRendererRooms'

export default React.memo(Rooms)
