export const VIDEO_FILE_TYPES = [
  'video/mp4',
  'video/mov',
  'video/quicktime',
  'video/mkv',
  'video/avi',
  'video/3gp',
  'video/3gpp',
]

export const IMAGE_FILE_TYPES = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']

export const AUDIO_FILE_TYPES = [
  'audio/mpeg',
  'audio/aac',
  'audio/mp3',
  'audio/mp4',
  'audio/x-wav',
  'audio/wav',
  'audio/m4a',
  'audio/x-m4a',
  'audio/aac',
  'audio/x-flac',
  'audio/x-aiff ',
]

export const OTHER_FILE_TYPES = [
  'text/plain',
  'application/pdf',
  'application/msword', // .doc
  'application/vnd.oasis.opendocument.text', // .odt
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/vnd.ms-excel', // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'application/vnd.ms-powerpoint', // .ppt
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
]

export const VALID_FILE_TYPES = [
  ...VIDEO_FILE_TYPES,
  ...IMAGE_FILE_TYPES,
  ...OTHER_FILE_TYPES,
  ...AUDIO_FILE_TYPES,
]

export const determineFileType = (mimeType: string) => {
  if (mimeType && mimeType.includes('video') && VIDEO_FILE_TYPES.includes(mimeType)) return 'video'

  if (mimeType && mimeType.includes('image') && IMAGE_FILE_TYPES.includes(mimeType)) return 'image'

  if (mimeType && mimeType.includes('application') && OTHER_FILE_TYPES.includes(mimeType)) return 'file'

  if (mimeType && mimeType.includes('text') && OTHER_FILE_TYPES.includes(mimeType)) return 'file'

  return 'unknown'
}
