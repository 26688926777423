import React, { useContext, useEffect, useRef, useState } from 'react'

import { ApolloCache, StoreObject, useMutation, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import * as R from 'ramda'
import {
  Absolute,
  Box,
  Dropdown,
  Flex,
  Heading,
  Icon,
  Interactive,
  Relative,
  Switch,
  Text,
  TruncateMultiline,
} from 'stardust'
import styled from 'styled-components'

import { useSelectedServiceFkey } from '~/contexts/Service'
import { UserContext } from '~/contexts/User'
import useClickedOutside from '~/hooks/useClickedOutside'
import useLocalStorage from '~/hooks/useLocalStorage'
import { isEducator } from '~/modules/permissions'
import { HEADER_HEIGHT } from '~/theme'
import { pagedResults } from '~/utils/filterList'
import EncapsulatedInfiniteScroll from '~/utils/InfiniteScroll/EncapsulatedInfiniteScroll'

import { MARK_NOTIFICATIONS_AS_READ, MarkNotificationsAsReadResponse } from '../../mutations'
import {
  GET_NOTIFICATIONS,
  GET_UNREAD_NOTIFICATIONS,
  Notification,
  ServiceNotifications,
} from '../../queries'

import NotificationItem from './NotificationItem'

interface HeaderProps {
  onlyShowUnread: boolean
  onSwitch: () => void
}

interface SecondaryHeadingProps {
  onClick: () => void
}

interface NotificationEdge {
  node: Notification
}

const EXCLUDED_NOTIFICATION_TYPES = [
  'CHILD_EVENT/*',
  'EDUCATOR_OBSERVATION/CREATED',
  'EDUCATOR_REFLECTION/*',
  'EVACUATION_REPORT/*',
  'HEAD_COUNT_REPORT/*',
  'INCIDENT_REPORT/*',
  'MEDICAL_REPORT/*',
]

const UNREAD_NOTIFICATION_STATUS = 'unread'
const READ_NOTIFICATION_STATUS = 'read'
const NOTIFICATIONS_PATH = ['service', 'notifications']

const Header = ({ onlyShowUnread, onSwitch }: HeaderProps) => (
  <Flex bg="white" width={1} justifyContent="space-between" alignItems="center">
    <Heading.h4 my={0} pl={24} pt={1} bold lineHeight={2}>
      Notifications
    </Heading.h4>
    <Flex pr={3}>
      <Text fontSize={1} pr={2} textAlign="right">
        Unread only
      </Text>
      <Flex alignItems="center" justifyContent="center">
        <Switch checked={onlyShowUnread} onChange={onSwitch} />
      </Flex>
    </Flex>
  </Flex>
)

const MarkAllAsRead = ({ onClick }: SecondaryHeadingProps) => (
  <Flex bg="white" width={1} alignItems="center" pl={24} onClick={() => onClick()}>
    <Interactive>
      <Text color="nebulaBlue5" bold fontSize={1} pr={16} pt={1} pb={2}>
        Mark all as read
      </Text>
    </Interactive>
  </Flex>
)

const UnreadCountBackground = styled(Flex)`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.superGiantRed10};
`
const StyledIcon = styled(Icon)`
  padding: 14px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.cosmicShade4};

  &:hover {
    background-color: ${(props) => props.theme.colors.cosmicShade6};
    transition: background-color 0.2s;
  }
`

const NotificationsMenu = () => {
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [unreadNotifications, setUnreadNotifications] = useState(false)
  const [onlyShowUnread, setOnlyShowUnread] = useLocalStorage(false, 'notificationsUnreadOnly')

  const dropdownRef = useRef<HTMLElement>(null)
  const headingRef = useRef<HTMLElement>(null)

  const serviceFkey = useSelectedServiceFkey()
  const queryVariables = {
    serviceFkey,
    exclude: {
      types: EXCLUDED_NOTIFICATION_TYPES,
    },
  }

  const { state: user } = useContext(UserContext)
  const userIsEducator = user && isEducator(user)

  // We use separate queries for all notifications vs unread notifications
  // so the result sets are stored separately in the cache and don't interfere
  // with each other
  const getQuery = onlyShowUnread ? GET_UNREAD_NOTIFICATIONS : GET_NOTIFICATIONS
  const query = useQuery<ServiceNotifications>(getQuery, {
    fetchPolicy: 'network-only',
    skip: !serviceFkey || !userIsEducator,
    variables: queryVariables,
    pollInterval: 30000,
  })

  useEffect(() => {
    if (query.data) {
      const notifications = pagedResults(query.data.service.notifications) as Notification[]

      setNotifications(notifications)
      setUnreadNotifications(notifications.some((n) => n.status === UNREAD_NOTIFICATION_STATUS))
    }
  }, [query.data])

  useClickedOutside(() => {
    dropdownRef.current ? (dropdownRef.current.scrollTop = 0) : null
  }, dropdownRef)

  const [markAsRead] = useMutation<MarkNotificationsAsReadResponse>(MARK_NOTIFICATIONS_AS_READ, {
    update: (cache, { data }) => {
      if (data?.markNotificationsAsRead) {
        const readIds = data.markNotificationsAsRead.map(({ id }) => id)
        markCachedNotificationsAsRead(cache, readIds)
        if (onlyShowUnread) {
          updateCachedUnreadQuery(cache, readIds)
        }
      }
    },
  })

  const markCachedNotificationsAsRead = (
    cache: ApolloCache<MarkNotificationsAsReadResponse>,
    readIds: number[]
  ) => {
    notifications.forEach((n) => {
      if (readIds.includes(n.id)) {
        cache.writeFragment({
          id: cache.identify(n as unknown as StoreObject),
          fragment: gql`
            fragment updatedNotification on Notification {
              status
            }
          `,
          data: {
            status: READ_NOTIFICATION_STATUS,
          },
        })
      }
    })
  }

  const updateCachedUnreadQuery = (
    cache: ApolloCache<MarkNotificationsAsReadResponse>,
    readIds: number[]
  ) => {
    const cachedUnreadResult = cache.readQuery({
      query: GET_UNREAD_NOTIFICATIONS,
      variables: queryVariables,
    })

    const edgesLens = R.lensPath([...NOTIFICATIONS_PATH, 'edges'])
    const edges = R.view(edgesLens, cachedUnreadResult) as NotificationEdge[]

    cache.writeQuery({
      query: GET_UNREAD_NOTIFICATIONS,
      variables: queryVariables,
      data: R.set(
        edgesLens,
        edges.filter(({ node }) => !readIds.includes(node.id)),
        cachedUnreadResult
      ),
    })
  }

  const onOnlyShowUnread = () => {
    setOnlyShowUnread((prevSetting) => !prevSetting)
  }

  const markAllAsRead = () => {
    markAsRead({ variables: { ids: notifications.map((notification) => notification.id) } })
  }

  const markOneAsRead = (notification: Notification) => {
    if (notification.status === UNREAD_NOTIFICATION_STATUS) {
      markAsRead({ variables: { ids: notification.id } })
    }
  }

  const renderScrollContents = (handleClose: () => void) => {
    if (query.loading) {
      return (
        <Box p={3}>
          <TruncateMultiline textAlign="center" maxLines={2}>
            <Text.span fontSize={3} lineHeight={1.5}>
              Loading...
            </Text.span>
          </TruncateMultiline>
        </Box>
      )
    }
    return notifications.length > 0 ? (
      notifications.map((notification) => (
        <NotificationItem
          notification={notification}
          key={notification.id}
          isRead={notification.status === READ_NOTIFICATION_STATUS}
          onNavigate={handleClose}
          markAsRead={() => markOneAsRead(notification)}
        />
      ))
    ) : (
      <Box p={3}>
        <TruncateMultiline textAlign="center" maxLines={2}>
          <Text.span fontSize={3}>You have no {onlyShowUnread ? 'unread' : ''} notifications</Text.span>
        </TruncateMultiline>
      </Box>
    )
  }

  // Notifications should only be visible for educators for now
  if (!userIsEducator) {
    return null
  }

  return (
    <Dropdown
      renderButton={({ handleClose }) => (
        <Relative onClick={handleClose}>
          <Absolute left={26} top={18}>
            {unreadNotifications && <UnreadCountBackground />}
          </Absolute>
          <StyledIcon name="notification" size={`calc(${HEADER_HEIGHT} - 16px)`} id="notificationIcon" />
        </Relative>
      )}
      render={({ handleClose }) => (
        <Box width={[300, 500]} maxHeight="80vh" overflowX="hidden" overflowY="auto" ref={dropdownRef}>
          <Box ref={headingRef}>
            <Header onlyShowUnread={onlyShowUnread} onSwitch={onOnlyShowUnread} />
            {unreadNotifications && <MarkAllAsRead onClick={markAllAsRead} />}
          </Box>
          <EncapsulatedInfiniteScroll dataSource={query} initialLoad={false} path={NOTIFICATIONS_PATH}>
            {renderScrollContents(handleClose)}
          </EncapsulatedInfiniteScroll>
        </Box>
      )}></Dropdown>
  )
}

NotificationsMenu.displayName = 'NotificationsMenu'

export default React.memo(NotificationsMenu)
