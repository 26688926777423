import React from 'react'

const Print = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.25 17.748H2.25C1.42157 17.748 0.75 17.0765 0.75 16.248V8.74805C0.75 7.91962 1.42157 7.24805 2.25 7.24805H21.75C22.5784 7.24805 23.25 7.91962 23.25 8.74805V16.248C23.25 17.0765 22.5784 17.748 21.75 17.748H18.75"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.75 10.248H5.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25 13.248H18.75V23.748H5.25V13.248Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.75 7.24805H5.25V2.74805C5.25 1.91962 5.92157 1.24805 6.75 1.24805H17.25C18.0784 1.24805 18.75 1.91962 18.75 2.74805V7.24805Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 16.248H15.75"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 19.248H13.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Print
