import React from 'react'

import styled from 'styled-components'

import { StylingUnit } from '~/utils/StylingUnit'

import { PostAttachmentFileProps } from '../attachmentTypes'

import { WaveformPlayer } from './WaveformPlayer'

const attachmentBorderHeight = new StylingUnit(71, 'px')
const attachmentBorderWidth = new StylingUnit(270, 'px')
const attachmentBorderRadius = new StylingUnit(16, 'px')

const AudioAttachmentContainer = styled.div`
  position: relative;
  display: flex;
  width: ${attachmentBorderWidth.unit()};
  height: ${attachmentBorderHeight.unit()};
  border-radius: ${attachmentBorderRadius.unit()};
  border: solid 1px #e2e2e2;
  background-color: #f6f6f6;
  overflow: hidden;
`

const WaveformPlayerOuterContianer = styled.div`
  width: 198px;
  margin-left: 16px;
  margin-top: auto;
  margin-bottom: auto;
`

export const AudioAttachmentPreview = ({ file: file }: PostAttachmentFileProps) => {
  return (
    <AudioAttachmentContainer>
      <WaveformPlayerOuterContianer>
        <WaveformPlayer audioFile={file.url} />
      </WaveformPlayerOuterContianer>
    </AudioAttachmentContainer>
  )
}
