import * as R from 'ramda'

import { scaleToCssString } from './scale'
import { translateToCssString, translateToSvgString } from './translate'

const transform = R.curry((translateFn, val) => {
  const [scale, translate] = R.juxt([scaleToCssString, translateFn])(val)
  return `${translate} ${scale}`
})

export const transformToCssString = transform(translateToCssString)
export const transformToSvgString = transform(translateToSvgString)
