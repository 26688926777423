import React, { useRef, useState } from 'react'

import { useMutation } from '@apollo/client'
import { Box, Fab, Flex, Icon, Popover, Text } from 'stardust'

import CommentInput from '~/components/CommentInput'
import { ADD_OBSERVATION_COMMENT } from '~/components/Editors/components/Menu/components/Reflections/mutations'
import colors from '~/ui-components/tokens/colors'

interface Props {
  postId: Nullable<number>
}

const Reflections = ({ postId }: Props) => {
  const anchorRef = useRef(null)
  const [showCommentModal, setShowCommentModal] = useState(false)
  const [createReflection] = useMutation(ADD_OBSERVATION_COMMENT)

  const addReflection = async (content: string) => {
    const response = await createReflection({
      refetchQueries: ['Reflections'],
      awaitRefetchQueries: true,
      variables: {
        documentId: postId,
        content: content,
      },
    })

    setShowCommentModal(false)
    return response
  }

  return (
    <>
      <Flex
        alignItems="center"
        cursor="pointer"
        onClick={() => setShowCommentModal(true)}
        ref={anchorRef}
        width="fit-content"
        pr={2}>
        <Fab bg="primary">
          <Icon color="white" name="addAlt" size={24} />
        </Fab>
        <Text fontWeight={500} fontSize="16px" ml={2} lineHeight="24px" color={colors.nebulaBlue8}>
          New Reflection
        </Text>
      </Flex>

      <Popover
        anchor={anchorRef}
        placement="right-start"
        show={showCommentModal}
        onToggle={setShowCommentModal}>
        <Box minWidth={576} p={3}>
          <CommentInput addComment={addReflection} buttonText="Send" placeholder="Write a reflection..." />
        </Box>
      </Popover>
    </>
  )
}

Reflections.displayName = 'Reflections'

export default React.memo(Reflections)
