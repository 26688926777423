import React from 'react'

const LearningContent = () => (
  <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66663 1.66663H16.6666C17.5833 1.66663 18.3333 2.41663 18.3333 3.33329V13.3333C18.3333 14.25 17.5833 15 16.6666 15H6.66663C5.74996 15 4.99996 14.25 4.99996 13.3333V3.33329C4.99996 2.41663 5.74996 1.66663 6.66663 1.66663ZM1.66663 4.99996H3.33329V16.6666H15V18.3333H3.33329C2.41663 18.3333 1.66663 17.5833 1.66663 16.6666V4.99996ZM16.6666 13.3333H6.66663V3.33329H16.6666V13.3333ZM15 7.49996H8.33329V9.16663H15V7.49996ZM8.33329 9.99996H11.6666V11.6666H8.33329V9.99996ZM15 4.99996H8.33329V6.66663H15V4.99996Z"
      fill="currentColor"
    />
  </svg>
)

export default LearningContent
