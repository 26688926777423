import React from 'react'

const FuturePlans = ({ fill = '#424242' }) => (
  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.586 16.5H2C1.44772 16.5 1 16.0523 1 15.5V2.5C1 1.94772 1.44772 1.5 2 1.5H15C15.5523 1.5 16 1.94772 16 2.5V10.086C15.9999 10.351 15.8947 10.6052 15.7073 10.7927L10.2927 16.2073C10.1052 16.3947 9.85103 16.4999 9.586 16.5Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 16.41V11.5C10 10.9477 10.4477 10.5 11 10.5H15.91"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default FuturePlans
