import * as R from 'ramda'

import { filterBy } from '~/utils'

export const filterByTerm = R.curry((searchTerm, filterField, list) =>
  filterBy(R.prop(filterField))(searchTerm)(list)
)
export const filterByTagged = R.curry((taggedIds, list) =>
  R.filter(({ id }) => R.contains(id, taggedIds), list)
)
export const pagedResults = (data) => R.pipe(R.path(['edges']), R.map(R.prop('node')))(data)
