import differenceInCalendarMonths from 'date-fns/difference_in_calendar_months'
import parse from 'date-fns/parse'
import * as R from 'ramda'

import { isInMobileApp, isWebView } from '~/config'
import { caps, filterBy, isNilOrEmpty, toLowerAndTrim, trimProp } from '~/utils'

const initial = R.pipe(R.split('-'), R.map(R.pipe(R.trim, R.head)), R.join(''))

export const groupByInitial = R.curry((prop, data) =>
  R.groupBy(R.pipe(R.prop(prop), toLowerAndTrim, R.head), data)
)

export const age = (child) => {
  if (isNilOrEmpty(child)) {
    return 'N/A'
  }

  if (R.not(R.prop('dob', child))) {
    return 'N/A'
  }

  const dob = parse(child.dob)
  const inMonths = differenceInCalendarMonths(new Date(), dob)
  const inYears = Math.floor(inMonths / 12)
  const remMonths = inMonths % 12

  const yearText = R.cond([
    [R.equals(1), R.always('1 year')],
    [R.lt(1), (y) => `${y} years`],
    [R.T, R.always('')],
  ])(inYears)

  const monthText = R.cond([
    [R.equals(1), R.always('1 month')],
    [R.lt(1), (m) => `${m} months`],
    [R.T, R.always('')],
  ])(remMonths)

  return `${yearText} ${monthText}`
}

export const shortAge = (dob) => {
  if (!dob) return 'N/A'

  let yearText
  let monthText

  const rawDob = parse(dob)
  const inMonths = differenceInCalendarMonths(new Date(), rawDob)
  const inYears = Math.floor(inMonths / 12)
  const remMonths = inMonths % 12

  switch (true) {
    case inYears >= 1:
      yearText = `${inYears}y`
      break
    default:
      yearText = ''
      break
  }

  switch (true) {
    case remMonths >= 1:
      monthText = `${remMonths}m`
      break
    default:
      monthText = ''
  }
  return `${yearText} ${monthText}`
}

export const gender = (child) => {
  if (isNilOrEmpty(child)) return ''
  const value = child.gender
  if (!value || value === '0') return ''
  return caps(value)
}

export const fullName = R.pipe(
  R.juxt([trimProp('firstName'), trimProp('lastName')]),
  R.map(R.trim),
  R.join(' ')
)

export const initials = R.pipe(R.split(' '), R.map(initial), R.join(''))

export const filterByFullName = filterBy('fullName')
export const groupByFirstInitial = groupByInitial('firstName')
export const groupByLastInitial = groupByInitial('lastName')
export const groupByName = groupByInitial('name')

export const executeGroupByName = (data) => {
  const grouped = groupByName()(data)
  const keys = R.keys(grouped)
  return { keys, grouped }
}

export const featureFlagCacheKey = 'system-feature-flags'
export const activeServices = (services, systemFeatures) => {
  // get feature flag data from cache if not provided in params
  if (!systemFeatures) {
    const cacheData = sessionStorage.getItem(featureFlagCacheKey)
    systemFeatures = cacheData ? JSON.parse(cacheData) : null
  }

  return R.filter(
    ({ features }) =>
      R.find(
        (feature) =>
          // is WEB service enabled (browsers only)
          (!isInMobileApp &&
            !isWebView &&
            feature.category === 'PLAYGROUND' &&
            feature.subcategory === 'WEB' &&
            feature.assignment === '1') ||
          // is PLAYGROUND_WEB_ON_MOBILE service enabled (native app only)
          (isInMobileApp &&
            feature.category === 'PLAYGROUND' &&
            feature.subcategory === 'PLAYGROUND_WEB_ON_MOBILE' &&
            feature.assignment === '1') ||
          // is Feature Flag 'playground-web-ui' enabled (webview only)
          (isWebView &&
            systemFeatures &&
            R.any(R.propEq('category', 'playground-web-ui') && R.propEq('enabled', true))(systemFeatures)),
        features
      ),
    services
  )
}
