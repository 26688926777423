import React from 'react'

import { Box, Icon, Text, Flex } from 'stardust'

import styled from 'styled-components'

import { isHome, isWebView } from '~/config'

import colors from '~/ui-components/tokens/colors'
import { longDateFormat, to12hrTime } from '~/utils'

interface Props {
  date: string
  time: string
}

const S = {
  Text: styled(Text)`
    color: ${isWebView && isHome ? '#FFFFFF' : '#686868'};
  `,

  Icon: styled(Box)`
    color: ${(props) => props.theme.colors[props.borderColor]};
  `,
}

const DateAndTime = ({ date, time }: Props) => {
  const formattedDate = longDateFormat(date)
  const formattedTime = to12hrTime(time)
  return (
    <Flex alignItems="center" mt={2}>
      <Icon name="clock" mr={2} size={15} fill={isHome ? colors.hullOrange6 : colors.nebulaBlue7} />
      <S.Text fontSize={1} color={'mediumEmphasis'} minWidth={'max-content'} textAlign={'center'}>
        {`${formattedDate} at ${formattedTime}`}
      </S.Text>
    </Flex>
  )
}

DateAndTime.displayName = 'DateAndTime'

export default React.memo(DateAndTime)
