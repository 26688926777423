const palette = {
  neptuneBlue0: '#E3F3FF',
  neptuneBlue1: '#BBE1FF',
  neptuneBlue2: '#8CCFFF',
  neptuneBlue3: '#55BCFF',
  neptuneBlue4: '#1BACFF',
  neptuneBlue5: '#009DFF',
  neptuneBlue6: '#008DFF',
  neptuneBlue7: '#007AFF',
  neptuneBlue8: '#1368EC',
  neptuneBlue9: '#2045CD',
}

export default {
  ...palette,
  neptuneBlue: palette.neptuneBlue7,
}
