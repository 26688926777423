import React, { useCallback, useContext, useMemo, useState } from 'react'

import { Card, Flex } from 'stardust'

import ColumnChart, { ColumnChartDatum } from '~/components/Charts/ColumnChart'
import FilterDropdown, { FkeyOrId, Id, ProfileGroup, ProfileItem } from '~/components/FilterDropdown'
import { initials } from '~/components/Profiles/core'
import useLocalStorage from '~/hooks/useLocalStorage'
import { LAYERS } from '~/theme'
import { objectKeys } from '~/utils'

import CategoryFilterMenu from '../Common/CategoryFilterMenu'
import { LearningAnalysisContext } from '../Context'

interface Props {
  defaultLeftFilter?: ProfileItem<FkeyOrId> | null
  defaultRightFilter?: ProfileItem<FkeyOrId> | null
  filters: ProfileGroup<FkeyOrId>[]
  filterDropdownText?: string
  localStorageKeyLeft?: string
  localStorageKeyRight?: string
  records: Playground.LearningRecord[]
}

const getRankPercentage = (
  records: Playground.LearningRecord[],
  rank: Playground.LearningFrameworkRank | null
) => {
  const rankRecords = rank
    ? records.filter((record) => record.rankId === rank.id)
    : records.filter((record) => record.rankId === null)
  return records.length === 0 ? 0 : rankRecords.length / records.length
}

const createDatapoints = (
  cohortsEnabled: boolean,
  ranks: Playground.LearningFrameworkRank[],
  records: Playground.LearningRecord[]
) => {
  if (cohortsEnabled) {
    const rankedDatapoints = ranks.map((rank) => ({
      name: rank.name,
      value: getRankPercentage(records, rank),
    }))
    const unrankedDatapoints = { name: 'Unranked', value: getRankPercentage(records, null) }
    return [unrankedDatapoints, ...rankedDatapoints]
  } else {
    return [{ name: 'Total', value: records.length }]
  }
}

const ComparisonTile = ({
  defaultLeftFilter,
  defaultRightFilter,
  localStorageKeyLeft,
  localStorageKeyRight,
  filters,
  filterDropdownText,
  records,
}: Props) => {
  const { cohortsEnabled, frameworkDepth, ranks, filterRecords, findCategory, groupRecordsByCategory } =
    useContext(LearningAnalysisContext)

  const initialLeftFilter = defaultLeftFilter ? defaultLeftFilter : null
  const initialRightFilter = defaultRightFilter ? defaultRightFilter : null
  const [categoryFilter, setCategoryFilter] = useState<Nullable<ProfileItem<Id>>>(null)
  const [leftFilter, setLeftFilter] = useLocalStorage<Nullable<ProfileItem<FkeyOrId>>>(
    initialLeftFilter,
    localStorageKeyLeft
  )
  const [rightFilter, setRightFilter] = useLocalStorage<Nullable<ProfileItem<FkeyOrId>>>(
    initialRightFilter,
    localStorageKeyRight
  )

  const onFormatX = useCallback(
    (id: number) => {
      const category = findCategory(id)
      return category ? category.shortName || initials(category.name) : ' '
    },
    [findCategory]
  )

  const onTooltipFormatX = useCallback(
    (id: number) => {
      const category = findCategory(id)
      return category ? category.name : ' '
    },
    [findCategory]
  )

  const transformToChartData = useCallback(
    (records: Playground.LearningRecord[], filter: Nullable<ProfileItem<FkeyOrId>>) => {
      const baseRecords = filterRecords(records, categoryFilter)
      const filteredRecords = filterRecords(baseRecords, filter)
      const groupField = categoryFilter ? 'secondaryId' : 'primaryId'
      const groupedRecords = groupRecordsByCategory(filteredRecords, groupField)
      const chartData: ColumnChartDatum[] = []

      objectKeys(groupedRecords).map((categoryId) => {
        const category = findCategory(categoryId)!
        const categoryRecords = groupedRecords[categoryId]

        chartData.push({
          category: category.id,
          values: createDatapoints(cohortsEnabled, ranks, categoryRecords),
        })
      })

      return chartData
    },
    [categoryFilter, cohortsEnabled, ranks, filterRecords, findCategory, groupRecordsByCategory]
  )

  const leftData = useMemo(() => {
    return transformToChartData(records, leftFilter)
  }, [records, leftFilter, transformToChartData])

  const rightData = useMemo(() => {
    return transformToChartData(records, rightFilter)
  }, [records, rightFilter, transformToChartData])

  return (
    <Card>
      <Flex flexDirection="column" width={1}>
        <Flex flexGrow={1}>
          <Flex flexDirection="column" p={3} pb={0} width={1 / 2}>
            <Flex>
              <FilterDropdown
                buttonDefaultText={filterDropdownText}
                filters={filters}
                portal
                selection={leftFilter}
                onSelect={setLeftFilter}
                zIndex={LAYERS.SectionHeader}
              />
            </Flex>

            <ColumnChart
              data={leftData}
              height={280}
              stack={cohortsEnabled}
              theme="primary"
              onFormatX={onFormatX}
              onTooltipFormatX={onTooltipFormatX}
            />
          </Flex>
          <Flex flexDirection="column" p={3} pb={0} width={1 / 2}>
            <Flex>
              <FilterDropdown
                buttonDefaultText={filterDropdownText}
                filters={filters}
                portal
                selection={rightFilter}
                onSelect={setRightFilter}
                zIndex={LAYERS.SectionHeader}
              />
            </Flex>

            <ColumnChart
              data={rightData}
              height={280}
              stack={cohortsEnabled}
              theme="secondary"
              onFormatX={onFormatX}
              onTooltipFormatX={onTooltipFormatX}
            />
          </Flex>
        </Flex>

        {frameworkDepth > 1 && (
          <Flex borderTop="1px solid" borderColor="surfacePrimaryBorder" mx={3} py={2}>
            <Flex flexDirection="column">
              <CategoryFilterMenu value={categoryFilter} onChange={setCategoryFilter} />
            </Flex>
          </Flex>
        )}
      </Flex>
    </Card>
  )
}

ComparisonTile.displayName = 'ComparisonTile'

export default React.memo(ComparisonTile)
