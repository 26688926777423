import React from 'react'

import { useQuery } from '@apollo/client'

import Loading from '~/components/Loading/Mentions'
import { SEARCH_QUERY } from '~/pages/Search/queries'

interface Props {
  children: any
  numberResults: number
  query: string
  serviceFkey: string
  types: string[]
}

interface SearchQuery {
  context: {
    documents: RelayConnection<Playground.Document>
    pageInfo: {
      endCursor?: string | number
      hasNextPage: boolean
    }
    taggableEntities: Playground.TaggableEntity[]
  }
}

const Search = ({ children, numberResults, query, serviceFkey, types }: Props) => {
  const { data, error, loading, fetchMore } = useQuery<SearchQuery>(SEARCH_QUERY, {
    variables: {
      query,
      serviceFkey,
      first: numberResults,
      types: types,
    },
  })

  if (error) {
    return null
  }

  if (loading) {
    return <Loading />
  }

  return children(data, fetchMore)
}

Search.displayName = 'Search'

export default Search
