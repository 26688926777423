import gql from 'graphql-tag'

export const EDUCATOR_INFO_FRAGMENT = gql`
  fragment EducatorInfo on Person {
    firstName
    fullName
    image
    lastName
    educatorRole
  }
`

export const EDUCATOR_METRICS_FRAGMENT = gql`
  fragment EducatorMetrics on Person {
    metrics(dateRange: $dateRange) {
      observations
      healthEvents
      parentEngagement
    }
  }
`
