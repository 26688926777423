import React from 'react'

import { Flex, Text } from 'stardust'

const FetchingMore = () => (
  <Flex justifyContent="center" mt={4} mb={4}>
    <Text medium fontSize={4}>
      Loading more...
    </Text>
  </Flex>
)

FetchingMore.displayName = 'FetchingMore'

export default FetchingMore
