const palette = {
  solYellow0: '#FFFCE5',
  solYellow1: '#FFF7BF',
  solYellow2: '#FFF195',
  solYellow3: '#FEEB6B',
  solYellow4: '#FDE64A',
  solYellow5: '#FBE124',
  solYellow6: '#FCD123',
  solYellow7: '#FAB91D',
  solYellow8: '#F8A017',
  solYellow9: '#F4760C',
}

export default {
  ...palette,
  solYellow: palette.solYellow1,
}
