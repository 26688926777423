import gql from 'graphql-tag'

export default gql`
  fragment PostLearningOutcomes on Post {
    learningOutcomes {
      id
      name
      frameworkName
      categoryName
    }
  }
`
