import { Editor, Next, Query } from '../types'

export default {
  onQuery: ({ type }: Query, _: Editor, next: Next) => {
    if (type === 'version') {
      return 'slate-v-0.43.6'
    }

    return next()
  },
}
