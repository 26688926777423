import ReactGA from 'react-ga4'

//Home Web
export async function openMoment() {
  ReactGA.event({
    category: 'Moments',
    action: 'Learning Click',
    label: 'Moment',
  })
}

//Home Web
export async function momentAddComment() {
  ReactGA.event({
    category: 'Moments',
    action: 'Learning Comment',
    label: 'Moment',
  })
}
