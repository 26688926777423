import React from 'react'

import styled from 'styled-components'
import { ColorProps, FontSizeProps, WidthProps, color, fontSize, width } from 'styled-system'

export type BaseProps = ColorProps & FontSizeProps & WidthProps

interface AnchorProps extends BaseProps {
  textDecoration?: string
}

const Anchor = styled.a<AnchorProps>`
  cursor: pointer;
  text-decoration: ${(props) => props.textDecoration};

  ${color};
  ${fontSize};
  ${width};
`

Anchor.defaultProps = {
  color: 'textLink',
  fontSize: 2,
}

Anchor.displayName = 'Anchor'

export default React.memo(Anchor)
