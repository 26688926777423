import gql from 'graphql-tag'

export const GET_STORY = gql`
  query LearningStory($id: Int!, $serviceFkey: String!, $contentStatus: ArticleContentStatus) {
    context(fkey: $serviceFkey) {
      fkey
      learningStory(id: $id) {
        hasUnpublishedChanges
        id
        isPublished
        status
        updatedAt
        children {
          fkey
          fullName
          image
        }
        content(status: $contentStatus) {
          id
          title
          data
          status
          updatedAt
          generatedWith
        }
        contributors {
          id
          fullName
          userId
          userType
          at
          action
        }
        learningOutcomes {
          id
          name
        }
        personnel {
          id
          fullName
        }
        rooms {
          id
          name
          ageGroup
        }
      }
    }
  }
`

export const GET_STORY_STATUS = gql`
  query LearningStoryStatus($id: Int!, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      learningStory(id: $id) {
        isPublished
      }
    }
  }
`

export const GET_ARCHIVED = gql`
  query LearningStoriesArchived($cursor: String, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      learningStories(first: 20, after: $cursor, status: ARCHIVED) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            hasUnpublishedChanges
            isPublished
            status
            updatedAt
            title
            content(status: DRAFT) {
              id
              title
              status
            }
            contributors {
              fullName
              action
            }
          }
        }
      }
    }
  }
`

export const GET_PUBLISHED = gql`
  query LearningStoriesPublished($cursor: String, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      learningStories(first: 20, after: $cursor, status: PUBLISHED) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            hasUnpublishedChanges
            isPublished
            status
            updatedAt
            title
            content(status: PUBLISHED) {
              id
              title
              status
            }
            contributors {
              fullName
              action
            }
          }
        }
      }
    }
  }
`

export const GET_DRAFT = gql`
  query LearningStoriesDraft($cursor: String, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      learningStories(first: 20, after: $cursor, status: DRAFT_NOT_PUBLISHED) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            hasUnpublishedChanges
            isPublished
            status
            updatedAt
            title
            content(status: DRAFT) {
              id
              title
              status
            }
            contributors {
              fullName
              action
            }
          }
        }
      }
    }
  }
`

export const GET_LAYOUT = gql`
  query Layout($id: ID, $serviceFkey: String!) {
    service(fkey: $serviceFkey) {
      id
      template(id: $id) {
        id
        title
        data
        updatedAt
      }
    }
  }
`

export const GET_LAYOUTS = gql`
  query Layouts($serviceFkey: String!) {
    service(fkey: $serviceFkey) {
      id
      templates(type: LEARNING_STORY) {
        id
        title
        data
        global
        providerFkey
      }
    }
  }
`

// Doesn't explicitly request a type as the API will
// return published only for parents, but all for educators
export const GET_LEARNING_STORIES = gql`
  query LearningStories($cursor: String, $serviceFkey: String, $folderId: Int) {
    context(fkey: $serviceFkey) {
      fkey
      learningStories(first: 20, after: $cursor, folderId: $folderId) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            commentCount
            hasUnpublishedChanges
            isPublished
            status
            title
            updatedAt
            contributors {
              fullName
              action
            }
          }
        }
      }
    }
  }
`

export const GET_LEARNING_STORY = gql`
  query LearningStory($id: Int!, $serviceFkey: String, $contentStatus: ArticleContentStatus) {
    context(fkey: $serviceFkey) {
      fkey
      learningStory(id: $id) {
        id
        hasUnpublishedChanges
        isPublished
        status
        commentCount
        children {
          fkey
          fullName
          image
        }
        rooms {
          fkey
          name
          ageGroup
        }
        personnel {
          id
          fkey
          fullName
          image
        }
        learningOutcomes {
          id
          name
        }
        contributors {
          id
          fullName
          userId
          userType
          at
          action
        }
        content(status: $contentStatus) {
          data
          generatedWith
          id
          status
          title
          updatedAt
        }
        insertedAt
        updatedAt
      }
    }
  }
`

export const GET_LEARNING_STORY_SUMMARY = gql`
  query LearningStory($id: Int!, $serviceFkey: String!) {
    context(fkey: $serviceFkey) {
      fkey
      learningStory(id: $id) {
        id
        content(status: PUBLISHED) {
          id
          title
        }
      }
    }
  }
`

export const GET_STORY_CONTRIBUTORS = gql`
  query LearningStoryContributors($id: Int!, $serviceFkey: String, $contentStatus: ArticleContentStatus) {
    context(fkey: $serviceFkey) {
      fkey
      learningStory(id: $id) {
        content(status: $contentStatus) {
          id
        }
        contributors {
          id
          action
          at
          fullName
          userRef
        }
      }
    }
  }
`

export const GET_STORY_CANVAS = gql`
  query LearningStory($id: Int!, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      learningStory(id: $id) {
        id
        children {
          fullName
          fkey
        }
        learningOutcomes {
          name
          id
        }
        content(status: PUBLISHED) {
          id
          title
        }
      }
    }
  }
`
export const GET_REFLECTIONS = gql`
  query Reflections($articleId: Int!, $serviceFkey: String!) {
    service(fkey: $serviceFkey) {
      fkey
      learningStory(id: $articleId) {
        id
        content(status: DRAFT) {
          id
          reflections {
            id
            content
            insertedAt
            author {
              id
              type
              name
            }
            replies {
              id
              content
              insertedAt
              author {
                id
                type
                name
              }
            }
          }
        }
      }
    }
  }
`
