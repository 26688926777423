import React, { ReactNode } from 'react'

import * as R from 'ramda'

import { Flex } from 'stardust'
import styled from 'styled-components'

import Carousel from '~/components/Carousel'
import DisplayMedia from '~/components/Media/DisplayMedia'
import { isWebView } from '~/config'

interface Props {
  data: Record<string, any>
  children: ReactNode[]
}

const FEATURE_HEIGHT = '470px'
const THUMB_HEIGHT = '150px'

const S = {
  Grid: styled.div`
    display: ${isWebView ? 'block' : 'grid'};
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    max-width: 1000px;
    min-height: ${FEATURE_HEIGHT};
  `,
  Main: styled.div`
    align-self: stretch;
    background-color: ${(props) => props.theme.colors.surfaceSecondary};
    height: ${isWebView ? 'auto' : '100%'};
    grid-column: span 3;
    grid-row: span 3;
    overflow: hidden;
    margin-bottom: 10px;
    max-height: ${FEATURE_HEIGHT};
  `,
  Side: styled.div`
    align-self: stretch;
    height: ${isWebView ? 'auto' : '100%'};
    grid-column: span 1;
    grid-row: span 1;
    overflow: hidden;
    margin-bottom: 10px;
    max-height: ${THUMB_HEIGHT};
  `,
  Flex: styled(Flex)`
    height: ${isWebView ? 'auto' : '100%'};
  `,
}

const Collage = ({ children, data, ...props }: Props) => {
  if (isWebView) {
    const { id, type, ...media } = data
    return (
      <Flex bg="surfaceSecondary" borderRadius={1} height="auto">
        <Carousel>
          {Object.values(media).map((mediaItem: Playground.MediaItem) => (
            <DisplayMedia key={mediaItem.id} id={mediaItem.id} maxHeight="400px" />
          ))}
        </Carousel>
      </Flex>
    )
  }

  const mapWithIndex = R.addIndex(R.map)
  const [first, ...rest] = children || []

  return (
    <S.Grid {...props}>
      <S.Main>
        <S.Flex flexDirection="row" alignItems="center" justifyContent="center">
          {first || null}
        </S.Flex>
      </S.Main>

      {mapWithIndex(
        (item, idx) => (
          <S.Side key={`${idx + 1}`}>
            <S.Flex flexDirection="row" alignItems="center" justifyContent="center">
              {item}
            </S.Flex>
          </S.Side>
        ),
        rest.slice(0, 3)
      )}
    </S.Grid>
  )
}

Collage.displayName = 'Collage'

export default Collage
