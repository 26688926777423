import React from 'react'

import { Box, Flex, Icon, Text } from 'stardust'
import styled from 'styled-components'

import colors from '~/ui-components/tokens/colors'

interface Props {
  onCancelClick: () => void
}

const S = {
  Icon: styled(Icon)`
    border-radius: 50%;
    padding: 10px;
    background-color: ${(props) => props.backgroundColor};
    &:hover {
      background-color: ${(props) => props.hoverColor};
      transition: background-color 0.2s;
    }
  `,
  Top: styled(Box)`
    background-color: ${colors.cosmicShade0};
    z-index: 100;
    position: sticky;
    top: 0;
    overflow-y: 0;
  `,
}

const TaggedDocumentsHeaderMobile = ({ onCancelClick }: Props) => {
  return (
    <S.Top pt={3} pb={1}>
      <Flex justifyContent="space-between" bg="white" width="100vw" padding="24px 24px 20px 24px">
        <Flex alignItems="center">
          <Icon ml={2} size={24} onClick={onCancelClick} name="chevronLeft" />
          <S.Icon
            name="links"
            size={40}
            padding="10px"
            border-radius="50%"
            backgroundColor={colors.gaiaGreen10}
          />
          <Text ml={2} fontSize={3} fontWeight={600}>
            Link to Related Learning
          </Text>
        </Flex>
      </Flex>
    </S.Top>
  )
}

TaggedDocumentsHeaderMobile.displayName = 'TaggedDocumentsHeaderMobile'

export default TaggedDocumentsHeaderMobile
