import { useEffect, useState } from 'react'

import isResponseOk from '~/utils/isResponseOk'
import toJSON from '~/utils/toJSON'

type FetchFunc = ({ url, options }: { url: string; options: Record<any, any> }) => void

interface FetchRequest {
  options: Record<any, any>
  url: Nullable<string>
}

interface FetchResults {
  data: Nullable<Record<string, any>>
  error: Nullable<string>
  loading: boolean
}

const useFetch = () => {
  const [{ url, options }, setUrl] = useState<FetchRequest>({ url: null, options: {} })
  const [fetchResults, setFetchResults] = useState<FetchResults>({
    loading: true,
    error: null,
    data: null,
  })

  useEffect(() => {
    if (url) {
      fetch(url, options)
        .then(isResponseOk)
        .then(toJSON)
        .then((resp) => {
          setFetchResults({
            loading: false,
            error: null,
            data: resp,
          })
        })
        .catch((error) => {
          setFetchResults({
            loading: false,
            error: error,
            data: null,
          })
        })
    }
  }, [url, options])

  return [fetchResults, setUrl] as [FetchResults, FetchFunc]
}

export default useFetch
