import React from 'react'

import { Absolute, Flex, Relative, Text } from 'stardust'
import styled from 'styled-components'

import useHover from '~/hooks/useHover'

const StyledIndicator = styled(Absolute)`
  height: 8px;
  width: 8px;
  top: 8px;
  left: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.borderColor};
`

const StyledIndicatorBackground = styled(Relative)`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: ${(props) => (props.showBackground ? props.theme.colors.nebulaBlue2 : 'transparent')};
`

const TextPopover = styled(Absolute)`
  background-color: ${(props) => props.theme.colors.nebulaBlue2};
  border-radius: 4px;
  text-align: center;
  opacity: ${(props) => (props.show ? 1 : 0)};

  -webkit-transition: opacity 0.5s ease-out;
  -moz-transition: opacity 0.5s ease-out;
  -o-transition: opacity 0.5s ease-out;
  transition: opacity 0.5s ease-out;
`

interface Props {
  showBackground: boolean
  isRead: boolean
  onClick: (e: React.MouseEvent) => void
}

const ReadStateIndicator = ({ showBackground, isRead, onClick }: Props) => {
  const [hoverRef, showText] = useHover()

  return (
    <Relative>
      <Flex alignItems="center" justifyContent="center">
        <TextPopover show={showText} p={1} width="95px" right="30px">
          <Text.span fontWeight={500} fontSize={1}>
            Mark as read
          </Text.span>
        </TextPopover>

        <StyledIndicatorBackground ref={hoverRef} showBackground={showBackground} onClick={onClick}>
          {!isRead && <StyledIndicator />}
        </StyledIndicatorBackground>
      </Flex>
    </Relative>
  )
}

export default React.memo(ReadStateIndicator)
