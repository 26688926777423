import React, { ReactNode } from 'react'

import useHomeFeature from '~/hooks/useHomeFeature'
import usePermission from '~/hooks/usePermission'
import useUserNotFromUnpermittedOrigins from '~/hooks/useUserNotFromUnpermittedOrigins'

interface CanProps {
  perform: string
  permissions?: string[]
  requiredHomeFeature?: Playground.ChildPermission
  unpermittedUserOrigins?: Playground.UserOrigin[]
  children?(allowed: boolean): ReactNode
  no?(): ReactNode
  yes?(): ReactNode
}

const Can = ({
  yes = () => null,
  no = () => null,
  perform,
  permissions,
  requiredHomeFeature,
  unpermittedUserOrigins,
  children,
}: CanProps) => {
  const allowedHomeFeature = useHomeFeature(requiredHomeFeature)
  const allowedOrigin = useUserNotFromUnpermittedOrigins(unpermittedUserOrigins)
  const allowedPermission = usePermission(perform, permissions)
  const allowed = allowedPermission && allowedOrigin && allowedHomeFeature

  if (children) {
    return <>{children(allowed)}</>
  }

  return <>{allowed ? yes() : no()}</>
}

export default React.memo(Can)
