import React from 'react'

const Draft = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 1h8.5L14 4.5V15H2V1z"
      stroke="currentColor"
      strokeWidth="1.5"
      fill="none"
      fillRule="evenodd"
      strokeDasharray="1.5,2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Draft
