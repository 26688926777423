import React, { useRef, useState } from 'react'

import { Box, Calendar, Flex, Icon, IconButton, Popover, Text, TextField, hoverMixin } from 'stardust'
import { FlexProps } from 'stardust/Flex'
import styled from 'styled-components'

import { DateRange } from '~/dateRange'
import PostTextField from '~/ui-components/PostTextField'
import colors from '~/ui-components/tokens/colors'

import { PresetPeriod } from './utils'

const MenuItem = styled(Flex)`
  ${hoverMixin};
`

interface Props<D> {
  endYear: number
  hoveredValue?: D
  initialDisplayMonth?: Date
  label: string
  presetPeriods: PresetPeriod<D>[]
  readOnly?: boolean
  selected?: D
  showStyledCalander?: boolean
  showTextBox?: boolean
  startYear?: number
  heightAdjust?: string | number
  br?: string | number
  borderWidth?: string | number
  pbottom?: string | number
  formatPeriod: (period?: D) => string
  isPeriodSelected: (period?: D) => boolean
  onSelectDate: (date: Date) => void
  onSelectPeriod: (period?: D) => void
  setHoveredValue: (date: Date) => void
}

const CalendarWithButtons = ({
  endYear,
  hoveredValue,
  initialDisplayMonth,
  label,
  presetPeriods,
  readOnly,
  selected,
  showStyledCalander,
  showTextBox = false,
  startYear = 2000,
  heightAdjust,
  br,
  borderWidth,
  pbottom,
  formatPeriod,
  isPeriodSelected,
  onSelectDate,
  onSelectPeriod,
  setHoveredValue,
  ...other
}: Props<Date | DateRange> & FlexProps) => {
  const dateRangeRef = useRef(null)
  const [showCalendar, setShowCalendar] = useState(false)
  const toggleCalendar = () => !readOnly && setShowCalendar(!showCalendar)

  const renderButtons = () =>
    presetPeriods.map(({ key, label, period }) => (
      <MenuItem
        key={key}
        bg={isPeriodSelected(period) ? 'hoverBackground' : 'transparent'}
        borderBottom="1px solid"
        borderColor="surfacePrimaryBorder"
        cursor="pointer"
        width="150px"
        justifyContent="center"
        p={3}
        onClick={() => onSelectPeriod(period)}>
        <Text.span fontSize={1}>{label}</Text.span>
      </MenuItem>
    ))

  return (
    <Flex ref={dateRangeRef} {...other}>
      {showTextBox ? (
        showStyledCalander ? (
          <PostTextField
            borderRadius={br ? br : '16px'}
            borderWidth={borderWidth}
            heightAdjust={heightAdjust}
            pb={pbottom}
            borderColor={colors.cosmicShade6}
            label={label}
            readOnly
            value={formatPeriod(selected)}
            onClick={toggleCalendar}
            trailingIcon={<Icon name="calendarAlt2" fill="textPrimaryMedium" />}></PostTextField>
        ) : (
          <TextField
            label={label}
            readOnly
            value={formatPeriod(selected)}
            onClick={toggleCalendar}
            trailingIcon={<Icon name="calendar" fill="textPrimaryMedium" />}></TextField>
        )
      ) : (
        <Flex p={1} ref={dateRangeRef}>
          <Flex>
            <IconButton onClick={toggleCalendar} icon={<Icon name="calendar" fill="textPrimaryMedium" />} />
          </Flex>
        </Flex>
      )}

      {showCalendar && (
        <Popover anchor={dateRangeRef} placement="bottom" show={showCalendar} onToggle={toggleCalendar}>
          <Flex flexDirection="column">
            <Flex>
              <Box pt="10px">{renderButtons()}</Box>
              <Box>
                <Calendar
                  defaultSelectedToCurrentDate={false}
                  endYear={endYear}
                  hovered={hoveredValue}
                  initialDisplayMonth={initialDisplayMonth || new Date()}
                  onHoverChange={setHoveredValue}
                  onSelectedChange={onSelectDate}
                  selected={selected}
                  startYear={startYear}
                />
              </Box>
            </Flex>
            {selected && (
              <Flex alignItems="center" justifyContent="center">
                <Text m={2}>{formatPeriod(selected)}</Text>
              </Flex>
            )}
          </Flex>
        </Popover>
      )}
    </Flex>
  )
}

export default React.memo(CalendarWithButtons)
