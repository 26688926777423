import React, { forwardRef } from 'react'

import { Box } from 'stardust'

/**
 * Dimensions is the element that explicity sets the height and width as a px
 * value so that all child values can simply compute their dimensions using
 * a percentage value.
 *
 * A ref is forwarded so that the DOM element can be directly manipulated on
 * resize.
 */
const Dimensions = forwardRef(({ children, height, width }, ref) => (
  <Box height={height} ref={ref} width={width}>
    {children}
  </Box>
))

Dimensions.displayName = 'Dimensions'
export default Dimensions
