import React, { useEffect, useRef } from 'react'

import { format, getYear } from 'date-fns'
import { Flex, Box, Text } from 'stardust'
import styled from 'styled-components'

import { DatePicker, defaultPresetDates, presetEmptyDate } from '~/components/DatePicker'
import PostTextField from '~/ui-components/PostTextField'
import colors from '~/ui-components/tokens/colors'

import { FieldProps } from '../../types'

interface FuturePlanningProps extends FieldProps {
  futurePlanning?: string
  planningDate?: string
}

const DateContainer = styled(Box)`
  box-sizing: border-box;
`

const FuturePlanning = ({ futurePlanning, onChange, planningDate, isReadOnly }: FuturePlanningProps) => {
  const selectedPlanningDate = planningDate ? new Date(planningDate) : undefined
  const textAreaRef = useRef<null | HTMLInputElement | HTMLTextAreaElement>(null)

  const updateInputHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto' // Reset the height
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px` // Set the height to scrollHeight
    }
  }

  useEffect(() => {
    updateInputHeight()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textAreaRef.current])

  return (
    <Box>
      <Text ml="12px" mr="12px" fontWeight={700} fontSize="14px" bold mb={2}>
        Future Plan
      </Text>
      <Box mb={3} ml="9px" mr="9px">
        <PostTextField
          borderRadius="16px"
          borderWidth="2px"
          borderColor={colors.cosmicShade6}
          placeholder="Add note"
          valid={true}
          value={futurePlanning || ''}
          onChange={(e) => {
            onChange({
              name: 'futurePlanning',
              value: e.target.value,
            })
            updateInputHeight()
          }}
          textarea
          rows={4}
          fullwidth
          ref={textAreaRef}
        />
      </Box>

      <DateContainer flex={1}>
        <Flex flexDirection="column">
          <Text ml="12px" mr="12px" fontWeight={700} fontSize="14px" bold mb={2}>
            Future Plan Date
          </Text>
          <DatePicker
            ml="9px"
            mr="9px"
            alignItems="center"
            data-test="DatePicker"
            emptyPresetPeriod={presetEmptyDate}
            initialDisplayMonth={selectedPlanningDate}
            readOnly={isReadOnly}
            label=""
            showStyledCalander
            presetPeriods={defaultPresetDates}
            selected={selectedPlanningDate}
            setSelection={(date) => {
              const formattedDate = date ? format(date, 'YYYY-MM-DD') : undefined
              onChange({ name: 'planningDate', value: formattedDate })
            }}
            startYear={2000}
            endYear={getYear(new Date())}
            showTextBox={true}
          />
        </Flex>
      </DateContainer>
    </Box>
  )
}

FuturePlanning.displayName = 'FuturePlanning'

export default React.memo(FuturePlanning)
