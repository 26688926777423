import React, { memo } from 'react'

import * as R from 'ramda'

import { Absolute, Relative } from 'stardust'
import styled from 'styled-components'
import { color, height, width } from 'styled-system'

import { isNotNilOrEmpty } from '~/utils'

const containerHeight = R.cond([
  [
    R.pipe(R.prop('printPreviewOffset'), isNotNilOrEmpty),
    ({ printPreviewOffset }) => `calc(100vh - ${printPreviewOffset}px)`,
  ],
  [R.propEq('modal', false), () => 'calc(100vh - 81px)'],
  [R.T, () => 'calc(80vh - 81px)'],
])

const S = {
  Absolute: styled(Absolute)`
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;

    ${color}
    ${height}
  ${width}
  
  height: ${containerHeight};
  `,
}

const Container = memo(({ background, children, modal = false, printPreviewOffset = 0 }) => (
  <Relative top={0} left={0} className="Canvas__Relative">
    <S.Absolute
      bg={background}
      modal={modal}
      className="Canvas__Relative__Absolute"
      printPreviewOffset={printPreviewOffset}>
      {children}
    </S.Absolute>
  </Relative>
))

Container.displayName = 'Container'
export default Container
