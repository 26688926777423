import React from 'react'

const Restore = () => (
  <svg width="30" height="35" viewBox="0 0 30 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5 27.25C21.8848 27.25 26.25 22.8848 26.25 17.5C26.25 12.1152 21.8848 7.75 16.5 7.75C11.1152 7.75 6.75 12.1152 6.75 17.5V19"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.75 16L6.75 19L9.75 16"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.25 12C16.6642 12 17 12.3838 17 12.8571V17.1429C17 17.6162 16.6642 18 16.25 18C15.8358 18 15.5 17.6162 15.5 17.1429V12.8571C15.5 12.3838 15.8358 12 16.25 12Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0148 17.0454C16.2991 16.7442 16.8087 16.7634 17.1529 17.0883L21.3604 21.0597C21.7047 21.3846 21.7533 21.8922 21.4689 22.1935C21.1846 22.4947 20.6751 22.4755 20.3308 22.1505L16.1233 18.1792C15.7791 17.8542 15.7305 17.3466 16.0148 17.0454Z"
      fill="currentColor"
    />
  </svg>
)

export default Restore
