import React from 'react'

import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import * as R from 'ramda'
import { Absolute, BackgroundImage, Flex, Heading, Relative } from 'stardust'
import styled from 'styled-components'

import * as profiles from '~/components/Profiles/core'
import { useSelectedServiceFkey } from '~/contexts/Service'
import { toFkey } from '~/utils'

import { dataRefIdLens } from '../core/selectors'

const S = {
  Absolute: styled(Absolute)`
    user-select: none;
  `,
}

const GET_CHILDREN = gql`
  query ChildrenSummary($serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      children {
        fkey
        fullName
        image
      }
    }
  }
`

const ShapeChild = (props) => {
  const serviceFkey = useSelectedServiceFkey()
  /* 
    get refId from "data": {"ref": "child", refId: _id}. refId will be an id or an fkey 
  */
  const refId = R.view(dataRefIdLens, props)
  const childFkey = toFkey(refId)

  const { data, error, loading } = useQuery(GET_CHILDREN, {
    variables: { serviceFkey },
  })

  if (loading || !data) {
    return null
  }

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }

  const child = data.context.children.find((c) => c.fkey === childFkey)

  if (!child) return null

  const { image, fullName } = child

  return (
    <Relative height="100%" width={1}>
      {!image ? (
        <S.Absolute bottom={0} left={0} right={0} top={0}>
          <Flex
            alignItems="center"
            color="rgb(0,0,0,0.65)"
            height="100%"
            justifyContent="center"
            width={1}
            flexDirection="column">
            <Heading.h3>{fullName && profiles.initials(fullName)}</Heading.h3>
          </Flex>
        </S.Absolute>
      ) : (
        <S.Absolute bottom={0} left={0} right={0} top={0}>
          <BackgroundImage height="100%" image={image} width={1} opacity={0} />
        </S.Absolute>
      )}
    </Relative>
  )
}

export default ShapeChild
