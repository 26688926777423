import React from 'react'

import * as R from 'ramda'
import { Avatar, Box, Divider, Flex, Link, Text } from 'stardust'
import styled from 'styled-components'

import { colorOfType, iconOfType } from '~/components/DocumentIcon'
import { initials } from '~/components/Profiles/core'
import { resultType } from '~/components/Search/core'
import formatDate from '~/utils/formatDate'

const S = {
  Box: styled(Box)`
    background: ${(props) => (props.selected ? props.theme.colors.hoverBackground : null)};

    &:hover {
      background: ${(props) => props.theme.colors.hoverBackground};
    }
    &:active {
      background: ${(props) => props.theme.colors.hoverBackground};
      transition: background 0.5s ease;
    }
  `,
}

const highlightMatchedText = (text, query) => {
  const idx = R.toLower(text).indexOf(R.toLower(query))
  return idx >= 0 ? (
    <span>
      {text.substring(0, idx)}
      <strong>{text.substring(idx, idx + query.length)}</strong>
      {text.substring(idx + query.length)}
    </span>
  ) : (
    text
  )
}

const Result = ({
  author,
  date,
  displayText,
  clearSearch,
  imageUrl,
  isSelected,
  query,
  ref,
  resultLink,
  type,
  typeId,
}) => (
  <S.Box selected={isSelected} key={typeId}>
    <Link ref={ref} to={resultLink} onClick={clearSearch}>
      <Flex width="100%" alignItems="center">
        <Box px={3}>
          <Flex
            alignItems="center"
            bg={colorOfType({ type })}
            borderRadius={4}
            width="30px"
            height="30px"
            justifyContent="center">
            {R.contains(type, ['child', 'educator', 'room']) ? (
              <Avatar text={initials(displayText)} src={imageUrl} xxsmall />
            ) : (
              iconOfType({ type })
            )}
          </Flex>
        </Box>
        <Box width="100%">
          <Flex alignItems="center" justifyContent="space-between" my={3}>
            <Box height="100%">
              <Text mb={1}>{highlightMatchedText(displayText || '', query)}</Text>
              <Text caps fontSize={0}>
                {resultType(type)} {highlightMatchedText(author ? ' - ' + author : '', query)}
              </Text>
            </Box>
            <Box mr={3} height="100%">
              <Text fontSize={1} color="cosmicShade8">
                {date && formatDate(date)}
              </Text>
            </Box>
          </Flex>
          <Divider borderColor="surfacePrimaryBorder" mb={0} />
        </Box>
      </Flex>
    </Link>
  </S.Box>
)

Result.displayName = 'Result'

export default Result
