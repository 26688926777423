import React, { useState } from 'react'

import { useQuery } from '@apollo/client'
import * as R from 'ramda'

import DocumentPreviewCard from '~/components/DocumentPreviewCard'
import { isWebView } from '~/config'
import { useSelectedServiceFkey } from '~/contexts/Service'
import { is404 } from '~/modules/api'
import PlanModal from '~/pages/Planning/Modal'
import { GET_LEARNING_PLAN_SUMMARY } from '~/pages/Planning/queries'

interface Props {
  id: number
}

const PlanPreviewCard = ({ id }: Props) => {
  const serviceFkey = useSelectedServiceFkey()
  const [open, setOpen] = useState(false)
  const { data, error } = useQuery(GET_LEARNING_PLAN_SUMMARY, {
    variables: { id, serviceFkey },
    skip: !id,
  })

  if (error) {
    // 404 is expected behaviour if we're embedding an observation, but the
    // parent doesn't have a child tagged in it
    if (is404(error)) {
      return (
        <DocumentPreviewCard
          title="Canvas"
          description="You do not have permission to view this content. Your child is not tagged in the document."
        />
      )
    }

    throw error
  }

  const plan = R.path(['context', 'learningPlan'], data) as Playground.Article
  const content = plan?.content[0]

  return (
    <>
      <DocumentPreviewCard
        description="Canvas"
        title={content?.title}
        onOpen={() => (!isWebView ? setOpen(true) : null)}
      />

      {open ? <PlanModal contentStatus="PUBLISHED" id={plan.id} show onClose={() => setOpen(false)} /> : null}
    </>
  )
}

PlanPreviewCard.displayName = 'PlanPreviewCard'

export default React.memo(PlanPreviewCard)
