import React, { useState } from 'react'

import { useQuery } from '@apollo/client'
import * as R from 'ramda'

import { Box, Card } from 'stardust'

import DocumentPreviewCard from '~/components/DocumentPreviewCard'
import MomentDetailCard from '~/components/MomentDetailCard'
import { useSelectedServiceFkey } from '~/contexts/Service'

import { is401 } from '~/modules/api'

import MomentModal from '~/pages/Moments/Modal'

import { GET_MOMENT } from '~/pages/Moments/queries'

const SUMMARY_WIDTH = 400
interface Props {
  id: number
}

const MomentPreviewCard = ({ id }: Props) => {
  const serviceFkey = useSelectedServiceFkey()
  const [open, setOpen] = useState(false)
  const [width, setWidth] = useState<Nullable<number>>(null)

  const measure = (node: HTMLDivElement) => {
    setWidth(node ? node.clientWidth : null)
  }

  const { data, error } = useQuery(GET_MOMENT, {
    variables: { momentId: id, serviceFkey },
  })

  if (error) {
    // 401 is expected behaviour if we're embedding a moment, but
    // it doesn't include the parent's child
    if (is401(error)) {
      return (
        <DocumentPreviewCard
          title="Moment"
          description="You do not have permission to view this content. Your child is not tagged in the Moment."
        />
      )
    }

    throw error
  }

  const moment = R.path(['context', 'moment'], data) as Playground.Moment | undefined

  const renderMoment = () => {
    if (!width) return <Card p={3}>Loading...</Card>

    return width > SUMMARY_WIDTH ? (
      <MomentDetailCard id={moment?.id} />
    ) : (
      <>
        <DocumentPreviewCard description={'Moment'} title={moment?.caption} onOpen={() => setOpen(true)} />
        {open ? <MomentModal id={moment?.id} show onClose={() => setOpen(false)} /> : null}
      </>
    )
  }

  return <Box ref={measure}>{renderMoment()}</Box>
}

MomentPreviewCard.displayName = 'StoryPreviewCard'

export default React.memo(MomentPreviewCard)
