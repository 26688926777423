export default {
  'leaf-left': 'leaf-left',
  'leaf-right': 'leaf-right',
  arrowRight: 'arrowRight',
  arrowLeft: 'arrowLeft',
  bevel: 'bevel',
  chevronLeft: 'chevronLeft',
  chevronRight: 'chevronRight',
  circle: 'circle',
  cog: 'cog',
  decagon: 'decagon',
  diamond: 'diamond',
  heart: 'heart',
  heptagon: 'heptagon',
  hex: 'hex',
  leafLeft: 'leafLeft',
  leafRight: 'leafRight',
  messageLeft: 'messageLeft',
  messageRight: 'messageRight',
  nanogon: 'nanogon',
  octogon: 'octogon',
  parallelogram: 'parallelogram',
  pentagon: 'pentagon',
  pointLeft: 'pointLeft',
  pointRight: 'pointRight',
  rabbet: 'rabbet',
  square: 'square',
  star: 'star',
  trapezoid: 'trapezoid',
  triangle: 'triangle',
}
