import gql from 'graphql-tag'

import { isPlayground } from '~/config'

export const GET_USER_DETAILS = gql`
  query UserDetail {
    user {
      reference
      origin
      firstName
      displayName
      fkey
      displayName
      email
      emailHash
      featurePermissions
      firstName
      guidedTours
      homeFeatures
      image
      lastName
      origin
      reference
      roles
    }
  }
`

export const GET_USER_SERVICES = gql`
  query UserServices($search: String) {
    user {
      id
      fkey
      services(search: $search, only_active: ${isPlayground}) {
        id
        fkey
        name
        features {
          assignment
          category
          subcategory
        }
        providerId
        providerFkey
        timezone
        links {
          supportUrl
          feedbackUrl
          homeUrl
        }      
      }
    }
  }
`
