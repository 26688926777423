import React from 'react'

const Bookmark = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.33342 4H22.6667C24.1334 4 25.3334 5.2 25.3334 6.66667V28L16.0001 24L6.66675 28V6.66667C6.66675 5.2 7.86675 4 9.33342 4ZM16.0001 21.0933L22.6668 24V6.66668H9.33346V24L16.0001 21.0933Z"
      fill="currentColor"
    />
  </svg>
)

export default Bookmark
