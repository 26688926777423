import React from 'react'

const Image = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 1.5C0.75 1.08579 1.08579 0.75 1.5 0.75H15C15.4142 0.75 15.75 1.08579 15.75 1.5V18C15.75 18.4142 15.4142 18.75 15 18.75H1.5C1.08579 18.75 0.75 18.4142 0.75 18V1.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 14.25H0.75"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.755 6.23096L22.737 7.55796C22.9257 7.62093 23.0817 7.75629 23.1706 7.93427C23.2595 8.11225 23.274 8.31826 23.211 8.50696L18.468 22.737C18.405 22.9257 18.2697 23.0816 18.0917 23.1705C17.9137 23.2594 17.7077 23.274 17.519 23.211L11.25 21.122"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25 9C9.69975 9 10.875 7.82475 10.875 6.375C10.875 4.92525 9.69975 3.75 8.25 3.75C6.80025 3.75 5.625 4.92525 5.625 6.375C5.625 7.82475 6.80025 9 8.25 9Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 14.25C3.75 11.7647 5.76472 9.75 8.25 9.75C10.7353 9.75 12.75 11.7647 12.75 14.25H3.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Image
