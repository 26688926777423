import React, { ReactNode } from 'react'

import { FormattedOption, Select } from '@rmwc/select'
import styled from 'styled-components'
import { width } from 'styled-system'

interface Props {
  children?: ReactNode
  disabled?: boolean
  helpText?: string
  invalid?: boolean
  label?: string
  name?: string
  options?: FormattedOption[] | string[] | { [value: string]: string }
  placeholder?: string
  readOnly?: boolean
  required?: boolean
  value?: string
  width?: number | string
  onChange?(event: React.ChangeEvent<HTMLSelectElement>): void
}

const StyledSelect = styled(Select)`
  ${width};
  && {
    &.mdc-select--focused:not(.mdc-text-field--disabled) {
      .mdc-floating-label {
        color: ${(props) => `${props.theme.colors.primary} !important`};
      }

      .mdc-select__dropdown-icon {
        background: ${(props) =>
          `url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23${props.theme.colors.primary.replace(
            '#',
            ''
          )}%22%20fill-rule%3D%22evenodd%22%20opacity%3D%221.0%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E") no-repeat center`};
        pointer-events: none;
        transform-origin: center center;
        transform: rotate(180deg);
      }
    }
  }
`

const ThemedSelectField = ({ children, ...props }: Props) => {
  return (
    <StyledSelect outlined {...props}>
      {children}
    </StyledSelect>
  )
}

ThemedSelectField.displayName = 'SelectField'

ThemedSelectField.defaultProps = {
  width: '100%',
}

export default React.memo(ThemedSelectField)
