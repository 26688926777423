import React from 'react'

const IncidentReport = () => (
  <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.1905,3.1428L2.7143,23.619C-0.9048,27.2381 -0.9048,33.1905 2.7143,36.8095C6.3333,40.4286 12.2857,40.4286 15.9048,36.8095C15.9048,36.8095 23.1579,29.5564 26.7845,25.9298C29.9833,22.731 36.381,16.3333 36.381,16.3333C40,12.7143 40,6.7619 36.381,3.1428C32.7619,-0.4762 26.8095,-0.4762 23.1905,3.1428Z"
      fill="#E64766"
      fillRule="evenodd"
    />
    <path
      d="M35.3333,24.6667L14.8571,4.1905C11.7619,1.0952 6.7619,1.0952 3.6666,4.1905C0.5714,7.2857 0.5714,12.2857 3.6666,15.381L24.1428,35.8571C27.2381,38.9524 32.2381,38.9524 35.3333,35.8571C38.4285,32.7619 38.4285,27.7619 35.3333,24.6667Z"
      fill="#ffffff"
      fillRule="evenodd"
    />
    <path
      d="M18.5238,7.8095L31.7143,21L20.5238,32.1905L7.3333,19L18.5238,7.8095Z"
      fill="#E97087"
      fillRule="evenodd"
    />
    <path
      d="M19.381,23.5238C19,23.5238 18.6667,23.6667 18.381,23.9524C17.8095,24.5238 17.8572,25.4286 18.4286,26C18.7143,26.2857 19.0953,26.4286 19.4762,26.4286C19.8572,26.4286 20.1905,26.2857 20.4762,26C21.0476,25.4286 21,24.5238 20.4286,23.9524C20.1429,23.6667 19.7619,23.5238 19.381,23.5238Z"
      fill="#E3EBF1"
      fillRule="evenodd"
    />
    <path
      d="M14.4762,18.619C14.0952,18.619 13.7619,18.7619 13.4762,19.0476C12.9048,19.619 12.9524,20.5238 13.5238,21.0952C13.8095,21.3809 14.1905,21.5238 14.5714,21.5238C14.9524,21.5238 15.2857,21.3809 15.5714,21.0952C16.1429,20.5238 16.0952,19.619 15.5238,19.0476C15.2381,18.7619 14.8571,18.619 14.4762,18.619Z"
      fill="#E3EBF1"
      fillRule="evenodd"
    />
    <path
      d="M24.4762,18.4762C24.0952,18.4762 23.7619,18.619 23.4762,18.9047C22.9048,19.4762 22.9524,20.3809 23.5238,20.9524C23.8095,21.2381 24.1905,21.3809 24.5714,21.3809C24.9524,21.3809 25.2857,21.2381 25.5714,20.9524C26.1429,20.3809 26.0952,19.4762 25.5238,18.9047C25.2381,18.619 24.8571,18.4762 24.4762,18.4762Z"
      fill="#E3EBF1"
      fillRule="evenodd"
    />
    <path
      d="M19.5714,13.5714C19.1904,13.5714 18.8571,13.7143 18.5714,14C18,14.5714 18.0476,15.4762 18.619,16.0476C18.9047,16.3333 19.2857,16.4762 19.6666,16.4762C20.0476,16.4762 20.3809,16.3333 20.6666,16.0476C21.2381,15.4762 21.1904,14.5714 20.619,14C20.3333,13.7143 19.9523,13.5714 19.5714,13.5714Z"
      fill="#E3EBF1"
      fillRule="evenodd"
    />
    <path
      d="M23.1905,36.8571C24.9524,38.619 27.2857,39.5714 29.7619,39.5714C32.2381,39.5714 34.619,38.619 36.3333,36.8571C39.9524,33.2381 39.9524,27.2857 36.3333,23.6666L15.8571,3.1904C14.0952,1.4286 11.7619,0.4762 9.2857,0.4762C6.8095,0.4762 4.4762,1.4286 2.7143,3.1904C-0.9048,6.8095 -0.9048,12.7619 2.7143,16.3809L23.1905,36.8571ZM4.7143,5.1905C5.9524,3.9524 7.5714,3.2857 9.2857,3.2857C11,3.2857 12.619,3.9524 13.8571,5.1905L34.3333,25.6666C35.5714,26.9047 36.2381,28.5238 36.2381,30.2381C36.2381,31.9524 35.5714,33.5714 34.3333,34.8095C33.0952,36.0476 31.4762,36.7143 29.7619,36.7143C28.0476,36.7143 26.4286,36.0476 25.1905,34.8095L4.7143,14.3333C3.4762,13.0952 2.8095,11.4762 2.8095,9.7619C2.8095,8.0476 3.4762,6.4285 4.7143,5.1905Z"
      fill="#454B54"
      fillRule="evenodd"
    />
    <path
      d="M9.3378,9.8139C9.8956,9.256 9.8806,8.3364 9.3041,7.76C8.7276,7.1835 7.808,7.1684 7.2501,7.7263C6.6922,8.2842 6.7073,9.2037 7.2838,9.7802C7.8603,10.3567 8.7799,10.3718 9.3378,9.8139Z"
      fill="#C7D7E2"
      fillRule="evenodd"
    />
    <path
      d="M32.1326,32.6087C32.6904,32.0508 32.6754,31.1312 32.0989,30.5547C31.5224,29.9783 30.6028,29.9632 30.0449,30.5211C29.487,31.079 29.5021,31.9986 30.0786,32.575C30.6551,33.1515 31.5747,33.1666 32.1326,32.6087Z"
      fill="#C7D7E2"
      fillRule="evenodd"
    />
  </svg>
)

export default IncidentReport
