import React from 'react'

import { Avatar, Box, Truncate } from 'stardust'
import styled from 'styled-components'

import * as profiles from '~/components/Profiles/core'

const Profile = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.surfaceSecondary};
  border-radius: 40px;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 4px;
  padding-left: 4px;
`

const AdditionalText = styled(Truncate)`
  color: ${(props) => props.theme.colors.textPrimaryLow};
`

const ProfileChip = ({ fullName, additionalText, image, large, small, xsmall, xxsmall }) => (
  <Profile data-test="profile-chip">
    <Avatar
      large={large}
      small={small}
      xsmall={xsmall}
      xxsmall={xxsmall}
      alt={fullName}
      src={image}
      text={profiles.initials(fullName)}
    />
    <Box>
      <Truncate caps fontSize={1} mt={2} mb={additionalText ? 0 : 2} mx={3}>
        {fullName}
      </Truncate>
      {additionalText && (
        <AdditionalText caps fontSize={0} mb={2} mx={3}>
          {additionalText}
        </AdditionalText>
      )}
    </Box>
  </Profile>
)

ProfileChip.displayName = 'ProfileChip'
export default ProfileChip
