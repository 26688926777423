import React, { useCallback, useContext, useMemo, useState } from 'react'

import { Box, Flex, MainButton, SecondaryButton, SelectField } from 'stardust'

import { LearningAnalysisContext } from '../Context'

interface Props {
  category: Playground.LearningFrameworkCategory
  onCancel(): void
  onSave(outcomeId: number, ageGroupId: number, rankId: number): void
}

const valueAsNumber = (event: any) => {
  const value = event.target.value
  return value ? Number(value) : null
}

const ScoreChangeForm = ({ category, onCancel, onSave }: Props) => {
  const { ageGroups, outcomes, ranks } = useContext(LearningAnalysisContext)

  const [ageGroupId, setAgeGroupId] = useState<Nullable<number>>(null)
  const [outcomeId, setOutcomeId] = useState<Nullable<number>>(null)
  const [rankId, setRankId] = useState<Nullable<number>>(null)

  const onAgeGroupChange = useCallback((event: any) => setAgeGroupId(valueAsNumber(event)), [])
  const onOutcomeChange = useCallback((event: any) => setOutcomeId(valueAsNumber(event)), [])
  const onRankChange = useCallback((event: any) => setRankId(valueAsNumber(event)), [])

  const handleSave = useCallback(() => {
    if (ageGroupId && outcomeId && rankId) {
      onSave(outcomeId, ageGroupId, rankId)
    }
  }, [ageGroupId, outcomeId, rankId, onSave])

  const categoryOutcomes = useMemo(() => {
    return outcomes.filter((outcome) => outcome.primaryId === category.id)
  }, [category, outcomes])

  const isValid = ageGroupId && rankId

  return (
    <Flex flexDirection="column" flexGrow={1} px={4} py={3}>
      <Flex flexDirection="column" flexGrow={1}>
        <Box mb={3}>
          <SelectField label="Outcome" placeholder="-- Select" onChange={onOutcomeChange}>
            {categoryOutcomes.map((outcome) => (
              <option key={outcome.id} value={outcome.id}>
                {outcome.name}
              </option>
            ))}
          </SelectField>
        </Box>
        <Box mb={3}>
          <SelectField label="Age Group" placeholder="-- Select" onChange={onAgeGroupChange}>
            {ageGroups.map((ageGroup) => (
              <option key={ageGroup.id} value={ageGroup.id}>
                {ageGroup.name}
              </option>
            ))}
          </SelectField>
        </Box>
        <Box mb={3}>
          <SelectField label="Rank" placeholder="-- Select" onChange={onRankChange}>
            {ranks.map((rank) => (
              <option key={rank.id} value={rank.id}>
                {rank.name}
              </option>
            ))}
          </SelectField>
        </Box>
      </Flex>
      <Flex>
        <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
        <MainButton disabled={!isValid} onClick={handleSave}>
          Save
        </MainButton>
      </Flex>
    </Flex>
  )
}

ScoreChangeForm.displayName = 'ScoreChangeForm'

export default React.memo(ScoreChangeForm)
