/* eslint react/display-name: 0 */
import React from 'react'

import { Next, RenderNodeProps, SerializeProps } from '../types'

export default {
  serialize: (object: SerializeProps, children: any) => {
    if (object.object === 'block' && object.type === 'paragraph') {
      return <p>{children}</p>
    }
  },

  // TO.DO: AP - This causes the warning:
  // Warning: validateDOMNesting(...): <div> cannot appear as a descendant of <p>.
  renderNode: ({ attributes, children, node }: RenderNodeProps, next: Next) => {
    if (node.type !== 'paragraph') return next()

    return <p {...attributes}>{children}</p>
  },
}
