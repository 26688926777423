import React from 'react'

import { Box, Flex, Text } from 'stardust'

import styled from 'styled-components'

import colors from '~/ui-components/tokens/colors'

interface Props {
  outcomes?: Playground.LearningFrameworkOutcome[]
}

const StyledOutcomes = styled(Flex)`
  border: 2px solid;
  border-color: ${colors.nebulaBlue4};
  background-color: ${colors.nebulaBlue0};
  border-radius: 16px;
  padding: 10px;
`

const DisplaySelectedOutcomes = ({ outcomes }: Props) => {
  if (!outcomes) {
    return <Text>None</Text>
  }
  return (
    <Box mb={3} pl={3} pt={3} pr={3} width="100%">
      {outcomes.map((outcome) => (
        <StyledOutcomes
          alignItems="center"
          key={outcome.id}
          pl={3}
          pt={3}
          pb={outcomes.length > 1 ? 3 : 0}
          mb={outcomes.length > 1 ? 1 : 0}>
          <Text fontSize="14px">{outcome.name}</Text>
        </StyledOutcomes>
      ))}
    </Box>
  )
}
DisplaySelectedOutcomes.displayName = 'DisplaySelectedOutcomes'
export default React.memo(DisplaySelectedOutcomes)
