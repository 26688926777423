import React from 'react'

const FrameworkCategory = () => {
  return (
    <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.25 8.75V5.75C21.25 4.92157 20.5784 4.25 19.75 4.25H9.25V2.75C9.25 1.92157 8.57843 1.25 7.75 1.25H3.25C2.42157 1.25 1.75 1.92157 1.75 2.75V19.05C1.76167 19.8943 2.39131 20.6021 3.22854 20.712C4.06576 20.822 4.85677 20.3007 5.086 19.488L7.437 9.831C7.62351 9.18989 8.21131 8.74924 8.879 8.75H22.75C23.216 8.74984 23.6556 8.96629 23.9397 9.33574C24.2237 9.70519 24.3199 10.1857 24.2 10.636L22 19.636C21.825 20.2931 21.23 20.7502 20.55 20.75H3.447"
        stroke="#008480"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default FrameworkCategory
