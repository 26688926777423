import React, { useEffect, useState } from 'react'

import { useQuery, useLazyQuery } from '@apollo/client'
import { navigate } from '@reach/router'
import * as queryString from 'query-string'
import { Box, Flex, Text } from 'stardust'

import styled from 'styled-components'

import FullScreen from '~/components/Layouts/FullScreen'

import { isSmallScreen } from '~/config'
import { GetDocuments } from '~/pages/Dashboard/queries'

import LinksDocuments from '~/pages/Observations/components/Form/Links/LinksDocuments'
import LinksDocumentsFilterWeb from '~/pages/Observations/components/Form/Links/LinksDocumentsFilterWeb'

import { GET_DOCUMENTS_BY_QUERY_TYPES_AND_CHILDREN } from '~/pages/Observations/queries'
import { GET_DOCUMENTS_BY_IDS } from '~/pages/Observations/queries'
import colors from '~/ui-components/tokens/colors'

import TaggedDocumentsBottomMobile from './TaggedDocumentsBottomMobile'
import TaggedDocumentsBottomWeb from './TaggedDocumentsBottomWeb'
import TaggedDocumentsHeaderMobile from './TaggedDocumentsHeaderMobile'
import TaggedDocumentsHeaderWeb from './TaggedDocumentsHeaderWeb'

const StyledBox = styled(Box)`
  flex: 1;
  > div:first-child {
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 24px;
    background-color: ${colors.cosmicShade12};
    max-width: none;
    > div:first-child {
      margin-top: 0px;
    }
  }
  @media (min-width: 601px) {
    padding: 20px 24px 0;
    > div:first-child {
      border-radius: 16px 16px 0 0;
    }
  }
`

interface Props {
  serviceFkey: string
}

const TaggedDocumentsWebview = ({ serviceFkey }: Props) => {
  const types = ['learning_story', 'learning_table', 'observation']

  const [selectedTaggedDocs, setSelectedTaggedDocs] = useState<string[]>([])
  const [selectedChildren, setSelectedChildren] = useState<string[]>()
  const [isError, setIsError] = useState<boolean>(false)
  const [selectedSorting, setSelectedSorting] = useState<string>('Last modified')
  const [searchString, setSearchString] = useState<Nullable<string>>(null)
  const [isDropdownVisible, setDropdownVisible] = useState(false)

  useEffect(() => {
    const { data } = queryString.parse(location.search)
    if (data) {
      const decodeData = typeof data == 'string' && atob(data)
      const jsonData = decodeData && JSON.parse(decodeData)

      jsonData && setSelectedChildren(jsonData.childFkeys)
      jsonData && setSelectedTaggedDocs(jsonData.ids)
    }
  }, [])

  useEffect(() => {
    if (searchString == '') {
      setSearchString(null)
    }
  }, [searchString])

  const handleOnQueryChange = (query: string) => {
    setSearchString(query)
    refetch()
  }

  const { data, error, loading, fetchMore, refetch } = useQuery<GetDocuments>(
    GET_DOCUMENTS_BY_QUERY_TYPES_AND_CHILDREN,
    {
      variables: { serviceFkey, types: types, selectedChildren, query: searchString },
    }
  )

  const handleOnComplete = (data: any) => {
    const documents = data?.service.documents?.edges
    const formattedDocs = documents.map(
      (doc: any) => `{"id": "${doc.node.id}", "title": "${doc.node.title}", "type": "${doc.node.type}"}`
    )

    const taggedDocsStringEncoded = btoa(`[${formattedDocs.toString()}]`)
    navigate(`xplor://tagged-documents-confim?data=${taggedDocsStringEncoded}`)
  }

  const [getDocumentsByIds] = useLazyQuery<GetDocuments>(GET_DOCUMENTS_BY_IDS, {
    variables: { serviceFkey, ids: selectedTaggedDocs },
    onCompleted: (data) => {
      handleOnComplete(data)
    },
  })

  const handleSortingChange = (option: string) => {
    setSelectedSorting(option)
  }

  const handleCardClick = (doc: Playground.SimpleDocument) => {
    const currentSelectedDocs = [...selectedTaggedDocs]
    if (currentSelectedDocs.includes(doc.id)) {
      const filteredDocs = currentSelectedDocs.filter((docStringId) => doc.id !== docStringId)
      setSelectedTaggedDocs(filteredDocs)
      return
    }
    setSelectedTaggedDocs(currentSelectedDocs.concat([doc.id]))
  }

  const handleConfirmClick = () => {
    getDocumentsByIds()
  }

  const handleCancelClick = () => {
    navigate('xplor://tagged-documents-cancel')
  }

  useEffect(() => {
    error && setIsError(true)
  }, [error])

  const handleDropdownToggle = () => {
    setDropdownVisible(!isDropdownVisible)
  }

  return (
    <FullScreen background="inherit">
      <Flex flexDirection="column" bg="surfacePrimary">
        {isSmallScreen ? (
          <TaggedDocumentsHeaderMobile onCancelClick={handleCancelClick} />
        ) : (
          <TaggedDocumentsHeaderWeb onCancelClick={handleCancelClick} />
        )}
        <Box>
          <Text mx={24} fontSize={2} mt={2} lineHeight="24px">
            Include links to related Learning Documentation.
          </Text>
        </Box>
        <Box>
          <LinksDocumentsFilterWeb
            selectedSorting={selectedSorting}
            isDropdownVisible={isDropdownVisible}
            handleSortingChange={handleSortingChange}
            handleDropdownToggle={handleDropdownToggle}
            onQueryChange={(event) => handleOnQueryChange(event.currentTarget.value)}
          />
        </Box>
        <StyledBox>
          <LinksDocuments
            handleCardClick={(doc) => handleCardClick(doc)}
            refetch={refetch}
            fetchMore={fetchMore}
            selectedTaggedDocs={selectedTaggedDocs}
            selectedSorting={selectedSorting}
            data={data}
            isLoading={loading}
            isError={isError}
          />
        </StyledBox>
        {isSmallScreen ? (
          <TaggedDocumentsBottomMobile
            onConfirmClick={handleConfirmClick}
            onCancelClick={handleCancelClick}
            totalTaggedDocs={selectedTaggedDocs.length}
          />
        ) : (
          <TaggedDocumentsBottomWeb
            onConfirmClick={handleConfirmClick}
            onCancelClick={handleCancelClick}
            totalTaggedDocs={selectedTaggedDocs.length}
          />
        )}
      </Flex>
    </FullScreen>
  )
}

TaggedDocumentsWebview.displayName = 'TaggedDocumentsWebview'

export default TaggedDocumentsWebview
