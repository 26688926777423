import React from 'react'

import styled from 'styled-components'

import { Icon } from '~/ui-components'
import { StylingUnit } from '~/utils/StylingUnit'

import { AttachmentType } from '../attachmentTypes'

interface DetachButtonProps {
  file: Playground.MediaItem
  typeForPosition: AttachmentType
  onDetachClickCallback: (id: number) => void
}

const closeButtonMediaTopOffset = new StylingUnit(-5, 'px')
const closeButtonMediaRightOffset = new StylingUnit(-10, 'px')

const closeButtonAudioTopOffset = new StylingUnit(23, 'px')
const closeButtonAudioRightOffset = new StylingUnit(16, 'px')

const clostButtonDocumentTopOffset = new StylingUnit(16, 'px')
const clostButtonDocumentRightOffset = new StylingUnit(16, 'px')

const DetachButtonContainer = styled.button<{ typeForPosition: AttachmentType }>`
  position: absolute;
  ${(props) => {
    switch (props.typeForPosition) {
      case AttachmentType.MEDIA:
        return `top: ${closeButtonMediaTopOffset.unit()};
					right: ${closeButtonMediaRightOffset.unit()};`
      case AttachmentType.AUDIO:
        return `top: ${closeButtonAudioTopOffset.unit()};
					right: ${closeButtonAudioRightOffset.unit()};`
      case AttachmentType.DOCUMENT:
        return `top: ${clostButtonDocumentTopOffset.unit()};
					right: ${clostButtonDocumentRightOffset.unit()};`
    }
  }}
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
`

export const DetachButton = ({ file, typeForPosition, onDetachClickCallback }: DetachButtonProps) => (
  <DetachButtonContainer typeForPosition={typeForPosition}>
    <Icon name="detach" onClick={() => onDetachClickCallback(file.id)} />
  </DetachButtonContainer>
)
