import tokens from '../tokens'

import common from './common'
import { Theme } from './types'

const themeColors = {
  // Theme base
  primary: tokens.colors.nebulaBlue7,
  primaryDark: tokens.colors.nebulaBlue9,
  primaryLight: tokens.colors.nebulaBlue0,

  // Emphasis
  highestEmphasis: 'rgba(0, 0, 0, 1.0)',
  higherEmphasis: 'rgba(0, 0, 0, 0.92)',
  highEmphasis: 'rgba(0, 0, 0, 0.64)',
  mediumEmphasis: 'rgba(0, 0, 0, 0.52)',
  lowEmphasis: 'rgba(0, 0, 0, 0.4)',
  lowerEmphasis: 'rgba(0, 0, 0, 0.12)',
  lowestEmphasis: 'rgba(0, 0, 0, 0.04)',

  //Components
  avatarBackground: tokens.colors.nebulaBlue0,
  avatarBorder: tokens.colors.nebulaBlue,
  avatarText: tokens.colors.nebulaBlue9,
  borderColor: tokens.colors.nebulaBlue,
  dividerBackground: tokens.colors.nebulaBlue0,
  hoverBackground: tokens.colors.nebulaBlue0,
  selection: tokens.colors.hullOrange0,
  textActive: tokens.colors.nebulaBlue7,
  textLink: tokens.colors.nebulaBlue,
  iconText: tokens.colors.cosmicShade10,
}

const components = {
  calendar: {
    textColor: 'mediumEmphasis',
    todayTextColor: 'primaryDark',
    todayHoverShadowColor: 'primary',
    outsideTextColor: 'lowerEmphasis',
    hoverColor: 'lowerEmphasis',
    hoverShadowColor: 'highEmphasis',
    selectedColor: tokens.colors.nebulaBlue2,
    selectedTextColor: 'mediumEmphasis',
    selectedHoverShadowColor: 'highEmphasis',
    rangeCapTextColor: tokens.colors.nebulaBlue4,
    focusBorderColor: tokens.colors.nebulaBlue8,
  },
  button: {
    color: '#FFFFFF',
  },
  text: {
    color: 'mediumEmphasis',
  },
}

export default {
  name: Theme.playground,
  ...tokens,
  colors: {
    ...tokens.colors,
    ...common.colors,
    ...themeColors,
  },
  components,
}
