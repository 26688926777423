export default {
  '1dp': `0px 0.815184px 4.25707px rgba(20, 20, 21, 0.08),
          0px 2.25388px 11.7703px rgba(20, 20, 21, 0.0521271), 
          0px 5.42647px 28.3382px rgba(20, 20, 21, 0.04), 
          0px 16px 94px rgba(20, 20, 21, 0.0278729)`,
  '2dp': `0px 2.30969px 4.25707px rgba(20, 20, 21, 0.28), 
          0px 6.38599px 11.7703px rgba(20, 20, 21, 0.0521271), 
          0px 15.375px 28.3382px rgba(20, 20, 21, 0.04), 
          0px 32px 94px rgba(20, 20, 21, 0.0278729)`,
  '4dp': `0px 4.12121px 4.25707px rgba(20, 20, 21, 0.32), 
          0px 11.3946px 11.7703px rgba(20, 20, 21, 0.058643), 
          0px 27.4338px 28.3382px rgba(20, 20, 21, 0.045), 
          0px 48px 94px rgba(20, 20, 21, 0.031357)`,
  '8dp': `0px 5.07226px 3.94006px rgba(20, 20, 21, 0.36), 
          0px 14.0241px 10.8938px rgba(20, 20, 21, 0.0781907), 
          0px 33.7647px 26.228px rgba(20, 20, 21, 0.06), 
          0px 60px 87px rgba(20, 20, 21, 0.0418093)`,
  '12dp': `0px 6.06859px 4.1665px rgba(20, 20, 21, 0.16), 
           0px 16.7789px 11.5198px rgba(20, 20, 21, 0.104254), 
           0px 40.3971px 27.7353px rgba(20, 20, 21, 0.08), 
           0px 72px 92px rgba(20, 20, 21, 0.0557458)`,
  '16dp': `0px 7.29137px 5.57042px rgba(20, 20, 21, 0.25), 
           0px 20.1597px 15.4015px rgba(20, 20, 21, 0.162897), 
           0px 48.5368px 37.0809px rgba(20, 20, 21, 0.125), 
           0px 84px 123px rgba(20, 20, 21, 0.0871028)`,
  '24dp': `0px 7.29137px 9.60106px rgba(20, 20, 21, 0.24), 
           0px 20.1597px 26.5457px rgba(20, 20, 21, 0.260636), 
           0px 48.5368px 63.9118px rgba(20, 20, 21, 0.2), 
           0px 96px 212px rgba(20, 20, 21, 0.139364)`,
}
