import React, { useEffect, useMemo, useState } from 'react'

import Chart from 'react-apexcharts'
import styled from 'styled-components'

import { unique } from '~/utils'

export interface LineChartDatum {
  category: string
  values: {
    name: string
    value: Nullable<number>
  }[]
}

const StyledChart = styled(Chart)`
  .apexcharts-tooltip {
    white-space: pre-wrap !important;
    max-width: 250px;
  }
`

interface Props {
  colors: string[]
  data: LineChartDatum[]
  height?: number | string
  max?: number
  min?: number
  width?: number | string
  onFormatY?(value: number): string | number
}

const LineChart = ({ colors, data, height, max, min, width = '100%', onFormatY }: Props) => {
  const [stickyMax, setStickyMax] = useState(0)

  // Keepts track of the biggest value see, so that if we use filters the scale is stable
  useEffect(() => {
    const newMax = Math.max(...data.flatMap((x) => x.values).map((x) => x.value || 0))
    if (newMax > stickyMax) setStickyMax(newMax)
  }, [data, stickyMax])

  const options = useMemo(() => {
    return {
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: colors,
      legend: {
        show: false,
      },
      markers: {
        colors: 'white',
        size: 5,
        strokeColors: colors,
      },
      noData: {
        text: 'No data available for this range',
        align: 'center',
        verticalAlign: 'middle',
        style: {
          fontSize: '14px',
        },
      },
      stroke: {
        colors: colors,
        width: 2,
      },
      xaxis: {
        categories: data.map((datum) => datum.category),
        type: 'datetime',
      },
      yaxis: {
        max: max || stickyMax,
        min,
        tickAmount: max && min ? max - min : undefined,
        labels: {
          formatter: onFormatY,
          offsetX: -8,
        },
      },
    }
  }, [colors, data, max, min, stickyMax, onFormatY])

  const series = useMemo(() => {
    const plots = unique(data.flatMap((datum) => datum.values.map((value) => value.name)))
    return plots.map((plot) => ({
      name: plot,
      data: data.map((datum) => {
        const plotData = datum.values.find((value) => value.name === plot)
        return plotData?.value || 0
      }),
    }))
  }, [data])

  return <StyledChart type="line" options={options} series={series} height={height} width={width} />
}

export default LineChart
