import React from 'react'

import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import * as R from 'ramda'
import { Flex, Image, Video } from 'stardust'
import styled from 'styled-components'

import EmptyImage from '~/components/Loading/EmptyImage'
import { useSelectedServiceFkey } from '~/contexts/Service'

import * as selectors from '../core/selectors'

const S = {
  Image: styled(Image)`
    cursor: initial;
    height: 100%;
    object-fit: contain;
    width: 100%;
  `,
  Video: styled(Video)`
    cursor: initial;
    object-fit: contain;
  `,
}

const GET_MEDIA = gql`
  query MediaItem($id: Int, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      mediaItem(id: $id) {
        id
        url
        coverUrl
      }
    }
  }
`

const Legacy = ({ url }) => (
  <Flex alignItems="center" justifyContent="center" style={{ height: '100%', width: '100%' }}>
    <S.Image src={url} />
  </Flex>
)

const Media = (props) => {
  const url = selectors.url.get(props)

  return url ? <Legacy url={url} /> : <New {...props} />
}

const New = (props) => {
  const serviceFkey = useSelectedServiceFkey()

  const refId = selectors.refId.get(props)
  const ref = selectors.ref.get(props)
  const { width, height } = selectors.dimensions.get(props)

  const { data, loading, error } = useQuery(GET_MEDIA, {
    variables: { id: refId, serviceFkey },
  })

  if (loading) {
    return <EmptyImage height={200} width={200} />
  }

  if (error) {
    return null
  }

  const coverUrl = R.path(['context', 'mediaItem', 'coverUrl'], data)
  const url = R.path(['context', 'mediaItem', 'url'], data)
  const isVideo = ref.split('/')[0] === 'video'

  return (
    <Flex justifyContent="center" alignItems="center" style={{ width: '100%', height: '100%' }}>
      {isVideo ? (
        <>
          <S.Video
            autoPlay={false}
            height={height}
            poster={coverUrl}
            preload="none"
            src={url}
            width={width}
          />
        </>
      ) : (
        <S.Image src={url} />
      )}
    </Flex>
  )
}

Media.displayName = 'Media'
export default Media
