import React, { ReactNode } from 'react'

import { Flex } from 'stardust'
import styled from 'styled-components'
import { color } from 'styled-system'

import Text, { Props as TextProps } from './Text'

interface TruncateProps extends TextProps {
  append?: string
  children: ReactNode | ReactNode[]
  onClick?(): any
  style?: any
  id?: any
}

const StyledText = styled(Text)`
  ${color};
`

const StyledTruncate = styled(Text)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Truncate = ({ append, children, style = {}, onClick = () => {}, ...props }: TruncateProps) => {
  const widthStyles = { width: style.maxWidth || '120px', maxWidth: '100%' }
  const appliedStyles = { ...style, ...widthStyles }
  if (!append)
    return (
      <StyledTruncate style={appliedStyles} onClick={onClick} {...props}>
        {children}
      </StyledTruncate>
    )

  return (
    <Flex alignContent="center" onClick={onClick} style={widthStyles}>
      <StyledTruncate style={appliedStyles} onClick={onClick} {...props}>
        {children}
      </StyledTruncate>
      <StyledText style={appliedStyles} {...props}>
        {append}
      </StyledText>
    </Flex>
  )
}

Truncate.displayName = 'Truncate'

export default Truncate
