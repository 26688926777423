import React from 'react'

const TablesNav = () => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.25 2.49916C1.25 1.80881 1.80964 1.24916 2.5 1.24916H17.5C18.1904 1.24916 18.75 1.8088 18.75 2.49916V17.4992C18.75 18.1895 18.1904 18.7492 17.5 18.7492H2.5C1.80964 18.7492 1.25 18.1895 1.25 17.4992V2.49916Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 1.24916V18.7492"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.25 9.99916H18.75"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default TablesNav
