import React, { useState, useCallback } from 'react'

import { useMutation, useQuery } from '@apollo/client'

import { ConfirmModal, Flex, Text } from 'stardust'

import CommentInput from '~/components/CommentInput'
import * as DocumentAnalytics from '~/modules/analytics/documents'
import * as MomentAnalytics from '~/modules/analytics/moments'
import * as ObservationAnalytics from '~/modules/analytics/observations'
import * as TableAnalytics from '~/modules/analytics/tables'

import Comments from './Comments'

import {
  ARCHIVE_COMMENT,
  CREATE_LEARNING_PLAN_COMMENT,
  CREATE_LEARNING_STORY_COMMENT,
  CREATE_LEARNING_TABLE_COMMENT,
  CREATE_MOMENT_COMMENT,
  CREATE_OBSERVATION_COMMENT,
} from './mutations'
import {
  GET_LEARNING_PLAN_COMMENTS,
  GET_LEARNING_STORY_COMMENTS,
  GET_LEARNING_TABLE_COMMENTS,
  GET_MOMENT_COMMENTS,
  GET_OBSERVATION_COMMENTS,
} from './queries'

interface Props {
  documentId: Nullable<number>
  documentType: Playground.CommentableDocumentType
  serviceFkey: Nullable<string>
}

interface Comments {
  id: number
  comments: Playground.Comment[]
}

const queries: Record<Playground.CommentableDocumentType, any> = {
  learning_plan: GET_LEARNING_PLAN_COMMENTS,
  learning_story: GET_LEARNING_STORY_COMMENTS,
  learning_table: GET_LEARNING_TABLE_COMMENTS,
  moment: GET_MOMENT_COMMENTS,
  observation: GET_OBSERVATION_COMMENTS,
}

const createComments: Record<Playground.CommentableDocumentType, any> = {
  learning_plan: CREATE_LEARNING_PLAN_COMMENT,
  learning_story: CREATE_LEARNING_STORY_COMMENT,
  learning_table: CREATE_LEARNING_TABLE_COMMENT,
  moment: CREATE_MOMENT_COMMENT,
  observation: CREATE_OBSERVATION_COMMENT,
}

const commentAnalytics: Record<Playground.CommentableDocumentType, () => void> = {
  learning_story: DocumentAnalytics.documentAddComment,
  learning_table: TableAnalytics.tableAddComment,
  moment: MomentAnalytics.momentAddComment,
  observation: ObservationAnalytics.observationAddComment,
}

const responseType: Record<Playground.CommentableDocumentType, any> = {
  learning_plan: 'learningPlan',
  learning_story: 'learningStory',
  learning_table: 'learningTable',
  moment: 'moment',
  observation: 'observation',
}

const CommentPanel = ({ documentId, documentType, serviceFkey }: Props) => {
  const { data, error, loading, refetch } = useQuery(queries[documentType], {
    variables: { serviceFkey, documentId },
  })

  const [addComment] = useMutation(createComments[documentType])
  const [deleteCommentId, setDeleteCommentId] = useState<Nullable<number>>(null)

  const onComment = useCallback(
    async (content: string) => {
      const response: any = await addComment({
        variables: { id: documentId, content },
      })
      if (commentAnalytics[documentType]) {
        commentAnalytics[documentType]()
      }

      await refetch()

      return response
    },
    [documentId, documentType, addComment, refetch]
  )

  const [archiveComment] = useMutation(ARCHIVE_COMMENT)

  if (error) {
    return <p>Error</p>
  }
  if (loading) {
    return <p>Loading</p>
  }

  const comments = data.context[responseType[documentType]].comments as Playground.Comment[]

  const onConfirmCommentDelete = (id: number) => {
    archiveComment({
      variables: {
        id,
      },
    })
      .then(() => {
        setDeleteCommentId(null)
      })
      .then(() => refetch())
  }

  const ConfirmDeleteModal = () => (
    <ConfirmModal
      headerText="Delete comment"
      isOpen={deleteCommentId != null}
      onCancel={() => setDeleteCommentId(null)}
      onClose={() => setDeleteCommentId(null)}
      onConfirm={() => onConfirmCommentDelete(deleteCommentId as number)}
      cancelText="Cancel"
      confirmText="Delete"
      fullViewPort={false}>
      <Text lineHeight={1.4}>{'Are you sure you want to delete this comment?'}</Text>
    </ConfirmModal>
  )

  return (
    <>
      <ConfirmDeleteModal />
      <Flex flexDirection="column" flexGrow={1} width={1}>
        <Comments comments={comments} onDeleteComment={(id) => setDeleteCommentId(id)} />
      </Flex>

      <CommentInput inline addComment={onComment} buttonText="Post" placeholder="Write a comment..." />
    </>
  )
}

export default CommentPanel
