import React, { forwardRef } from 'react'

import { Absolute } from 'stardust'
import styled from 'styled-components'

import Dimensions from '../Nodes/Dimensions'
import { pointToPosition } from '../utils'

const S = {
  Absolute: styled(Absolute)`
    cursor: not-allowed;
    ${({ zIndex }) => `z-index: ${zIndex}`};
  `,
}

const View = forwardRef(({ z, dimensions, point, children }, ref) => (
  <S.Absolute {...pointToPosition(point)} ref={ref} zIndex={z}>
    <Dimensions {...dimensions}>{children}</Dimensions>
  </S.Absolute>
))

View.displayName = 'View'

export default View
