import React from 'react'

import styled from 'styled-components'

import { AudioAttachmentPreview } from '../atoms/AudioAttachmentPreview'
import { DetachButton } from '../atoms/DetachButton'
import { DocumentAttachmentPreview } from '../atoms/DocumentAttachmentPreview'
import { MediaAttachmentPreview } from '../atoms/MediaAttachmentPreview'
import {
  AttachmentType,
  PostAttachmentFileProps,
  PostAttachmentFileWithDetachProps,
} from '../attachmentTypes'

// TODO: Fill out and utilise this list for better attachment identification.
// const TYPES_OF_MEDIA: string[] = [
// ];
//
// const TYPES_OF_AUDIO: string[] = [
// ];
//
// const TYPES_OF_DOCUMENT: string[] = [
// ];

const FileAttachmentOuterContainer = styled.div`
  position: relative;
`

/**
 * Assesses file type for a file and returns an attachment component based on the type.
 * Currently, only 3 types are possible returns (media, audio or document). Will also return
 * the attachment type for usage in determining detach button position
 *
 * @param file The file which the media attachment should use for its render/behaviour
 * @returns An array of a file attachment component based on what the file type is;
 * media, audio or document as well as the attachment file type.
 */
const getFileAttachmentComponent = ({
  file,
  file: { mimeType },
}: PostAttachmentFileProps): [JSX.Element, AttachmentType] => {
  let discernedAttachment
  let fileAttachmentType

  if (mimeType.includes('image') || mimeType.includes('video')) {
    discernedAttachment = <MediaAttachmentPreview file={file} />
    fileAttachmentType = AttachmentType.MEDIA
  } else if (mimeType.includes('text') || mimeType.includes('application')) {
    discernedAttachment = <DocumentAttachmentPreview file={file} />
    fileAttachmentType = AttachmentType.DOCUMENT
  } else if (mimeType.includes('audio')) {
    discernedAttachment = <AudioAttachmentPreview file={file} />
    fileAttachmentType = AttachmentType.AUDIO
  } else {
    // TODO: Fallback, we should consider a '?' square in the style
    // of MediaAttachmentPreview.
    // An invalid type would be advised: AttachmentType.INVALID
    fileAttachmentType = AttachmentType.MEDIA
    discernedAttachment = <></>
  }

  return [discernedAttachment, fileAttachmentType]
}

export const FileAttachment = ({
  file,
  forPostCreation,
  onDetachClickCallback,
}: PostAttachmentFileWithDetachProps) => {
  const [fileAttachment, fileAttachmentType]: [JSX.Element, AttachmentType] = getFileAttachmentComponent({
    file,
  })
  return (
    <FileAttachmentOuterContainer>
      {fileAttachment}
      {forPostCreation && (
        <DetachButton
          file={file}
          onDetachClickCallback={onDetachClickCallback}
          typeForPosition={fileAttachmentType}
        />
      )}
    </FileAttachmentOuterContainer>
  )
}
