import gql from 'graphql-tag'

export const GET_DOCUMENT_FOLDER = gql`
  query DocumentFolders($folderId: Int!, $serviceFkey: String!) {
    service(fkey: $serviceFkey) {
      fkey
      documentFolder(id: $folderId) {
        id
        name
        type
        updatedAt
      }
    }
  }
`

export const GET_ACTIVE_DOCUMENT_FOLDERS = gql`
  query ActiveDocumentFolders($cursor: String, $serviceFkey: String!, $folderTypes: [String]) {
    service(fkey: $serviceFkey) {
      fkey
      documentFolders(first: 25, after: $cursor, types: $folderTypes, status: ACTIVE) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            bookmarked
            name
            status
            type
            updatedAt
            documents {
              type
              typeId
            }
          }
        }
      }
    }
  }
`

export const GET_ARCHIVED_DOCUMENT_FOLDERS = gql`
  query ArchivedDocumentFolders($cursor: String, $serviceFkey: String!, $folderTypes: [String]) {
    service(fkey: $serviceFkey) {
      fkey
      documentFolders(first: 10, after: $cursor, types: $folderTypes, status: ARCHIVED) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            bookmarked
            name
            status
            type
            updatedAt
            documents {
              type
              typeId
            }
          }
        }
      }
    }
  }
`
