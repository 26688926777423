import * as R from 'ramda'

/**
 * This is used in conjunction with the `strategies` file.
 *
 * It's purpose is for legacy field look ups. This should be removed
 * when the strategies file is removed when there is a data migration.
 */

export const dataLens = R.lensProp('data')
export const layoutLens = R.lensProp('layout')
export const typeLens = R.lensProp('type')

const dataFieldLens = (prop) => R.compose(dataLens, R.lensProp(prop))

export const idLens = dataFieldLens('id')
export const shapeTypeLens = dataFieldLens('type')
export const stickerTypeLens = dataFieldLens('type')
