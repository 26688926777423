import React, { ReactNode } from 'react'

import * as R from 'ramda'

import { Container } from 'stardust'

import { InfiniteScroll, Scroll, hasAtLeastOne } from '~/utils'

interface Props {
  data: any
  empty?: ReactNode
  heading?: ReactNode
  path: string[]
  pause?: boolean
  children({ documents, groupIndex }: { documents: NonEmptyArray<unknown>; groupIndex?: number }): ReactNode
  fetchMore(props: any): void
  groupWith?(props: any): void
  scrollElementRef?: React.RefObject<HTMLDivElement>
}

const List = ({
  children,
  data,
  fetchMore,
  groupWith,
  heading,
  path,
  pause,
  empty,
  scrollElementRef,
}: Props) => {
  const result = R.path([...path, 'edges'], data) as Undefinedable<Array<any>>
  const extractedNodes = R.map(R.prop('node'), result)
  const grouped = groupWith
    ? R.pipe<any, any, any>(R.map(R.prop('node')), groupWith)(result)
    : [extractedNodes]

  return hasAtLeastOne(grouped) ? (
    <Scroll
      render={({ pageYOffset, innerHeight, scrollHeight }) => (
        <InfiniteScroll
          data={data}
          fetchMore={fetchMore}
          innerHeight={innerHeight}
          pageYOffset={pageYOffset}
          scrollHeight={scrollHeight}
          path={path}
          pause={pause}>
          <Container data-test="documents-list" data-test-list-is-empty="false">
            {heading}
            {grouped.map((documents: NonEmptyArray<unknown>, groupIndex: number) =>
              children({ documents, groupIndex })
            )}
          </Container>
        </InfiniteScroll>
      )}
      scrollElementRef={scrollElementRef}
    />
  ) : (
    <Container data-test="documents-list" data-test-list-is-empty="true">
      {empty}
    </Container>
  )
}

List.displayName = 'List'

export default React.memo(List)
