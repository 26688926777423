import React, { ReactNode, useCallback, useEffect, useState } from 'react'

const LOCAL_STORAGE_KEY = 'userStrategyRef'

export const getLocalStrategyRef = () => localStorage.getItem(LOCAL_STORAGE_KEY)
const setLocalStrategyRef = (strategyRef: string) => localStorage.setItem(LOCAL_STORAGE_KEY, strategyRef)
const removeLocalStrategyRef = () => localStorage.removeItem(LOCAL_STORAGE_KEY)

const canUseStrategy = (strategyRef: Nullable<string>, strategies: Stargate.UserContext[]) =>
  !!strategies.find((x) => x.user_ref === strategyRef)

interface State {
  selectedStrategyRef: Nullable<string>
  showStrategyPrompt: boolean
  strategies: Stargate.UserContext[]
}

interface StrategyContextProps {
  clearStrategy(): void
  selectStrategy(strategyRef: string): void
  setStrategies(strategies: Stargate.UserContext[]): void
  showStrategyPrompt(): void
  toggleStrategyPrompt(): void
  state: State
}

export const StrategyContext = React.createContext<StrategyContextProps>({
  clearStrategy: () => {},
  selectStrategy: (strategyRef: string) => {},
  setStrategies: (strategies: Stargate.UserContext[]) => {},
  showStrategyPrompt: () => {},
  toggleStrategyPrompt: () => {},
  state: {
    showStrategyPrompt: false,
    selectedStrategyRef: null,
    strategies: [],
  },
})

interface Props {
  children: ReactNode
  strategies: Stargate.UserContext[]
}

const StrategyProvider = ({ children, strategies: initialStrategies }: Props) => {
  const [state, setState] = useState<State>({
    showStrategyPrompt: false,
    selectedStrategyRef: null,
    strategies: [],
  })

  const clearStrategy = useCallback(() => {
    setState((prevState) => {
      removeLocalStrategyRef()
      return { ...prevState, selectedStrategyRef: null }
    })
  }, [])

  const selectStrategy = useCallback((strategyRef: string) => {
    setState((prevState) => {
      setLocalStrategyRef(strategyRef)
      return {
        ...prevState,
        selectedStrategyRef: strategyRef,
        showStrategyPrompt: false,
      }
    })
  }, [])

  const setStrategies = useCallback((strategies: Stargate.UserContext[]) => {
    setState((prevState) => ({ ...prevState, strategies }))
  }, [])

  const showStrategyPrompt = useCallback(() => {
    setState((prevState) => ({ ...prevState, showStrategyPrompt: true }))
  }, [])

  const toggleStrategyPrompt = useCallback(() => {
    setState((prevState) => ({ ...prevState, showStrategyPrompt: !prevState.showStrategyPrompt }))
  }, [])

  useEffect(() => {
    const localStrategyRef = getLocalStrategyRef()
    const queryParams = new URLSearchParams(window.location.search)
    const queryStrategyRef = queryParams.get('strategy_ref')

    setStrategies(initialStrategies)

    // Figure out the starting strategy following these rules:
    // 1. If only one strategy, use it
    // 2. Try to use query string
    // 3. Try to use local storage
    // 4. Show prompt
    if (initialStrategies.length === 1) {
      selectStrategy(initialStrategies[0].user_ref)
    } else if (canUseStrategy(queryStrategyRef, initialStrategies)) {
      selectStrategy(queryStrategyRef!)
    } else if (canUseStrategy(localStrategyRef, initialStrategies)) {
      selectStrategy(localStrategyRef!)
    } else {
      showStrategyPrompt()
    }
  }, [initialStrategies, selectStrategy, setStrategies, showStrategyPrompt])

  return (
    <StrategyContext.Provider
      value={{
        clearStrategy,
        selectStrategy,
        setStrategies,
        showStrategyPrompt,
        toggleStrategyPrompt,
        state,
      }}>
      {children}
    </StrategyContext.Provider>
  )
}

StrategyProvider.displayName = 'StrategyProvider'

export default StrategyProvider
