import React, { useState } from 'react'

import { Box, Flex, Icon, Link } from 'stardust'
import homeTheme from 'stardust/theme/home'
import playgroundTheme from 'stardust/theme/playground'
import styled from 'styled-components'

import { isHome } from '~/config'

const theme = isHome ? homeTheme : playgroundTheme

export const PopupLink = styled(Link)`
  font-weight: bold;
  color: ${theme.colors.primary};
  :visited {
    color: ${theme.colors.primary};
  }
  :hover {
    color: ${theme.colors.primary};
  }
`

const Fixed = styled(Flex)`
  position: fixed;
  bottom: 25px;
  right: 0%;
  width: 100%;
  z-index: 1;
`

const NotificationPopup = ({ children, ...props }) => {
  const [open, setOpen] = useState(true)

  return open ? (
    <Fixed justifyContent="center" alignItems="center" {...props}>
      <Flex
        width={[3 / 4, 1 / 2]}
        borderRadius={1}
        bg="cosmicShade"
        p={3}
        alignItems="center"
        style={{
          boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12)',
        }}>
        {children}
        <Box ml="auto">
          <Icon name="close" mr={2} fill="white" onClick={() => setOpen(false)} />
        </Box>
      </Flex>
    </Fixed>
  ) : null
}

NotificationPopup.displayName = 'NotificationPopup'
export default NotificationPopup
