import * as R from 'ramda'

import * as factories from '../factories'

export default (node) => {
  const type = R.path(['data', 'type'], node)
  const point = R.prop('layout', node)

  switch (type) {
    case 'image': {
      const url = R.path(['data', 'url'], node)
      return factories.mediaFactory({ data: { url }, properties: { point } })
    }

    case 'text': {
      const content = R.path(['data'], node)

      return factories.shapeFactory({
        data: {
          ref: 'content',
          refData: content.content.data,
          refId: null,
        },
        properties: { point },
      })
    }
    default: {
      return {}
    }
  }
}
