import React, { useEffect, useRef } from 'react'

import { useMutation } from '@apollo/client'

import { Box, Flex, Popover } from 'stardust'

import Comment from '~/components/Comment/Comment'
import CommentInput from '~/components/CommentInput'
import { ADD_OBSERVATION_COMMENT_WITH_REPLY } from '~/components/Editors/components/Menu/components/Reflections/mutations'

interface Props {
  postId: Nullable<number>
  reflection: Playground.Reflection
  anchorRef: HTMLDivElement
  closePopup: () => void
}

const ReflectionList = ({ postId, reflection, anchorRef, closePopup }: Props) => {
  const anchor = useRef(anchorRef)
  const repliesRef = useRef(null)
  const [addReflectionOnObservation] = useMutation(ADD_OBSERVATION_COMMENT_WITH_REPLY)

  const replies = reflection?.replies || []

  const addReply = async (content: string) => {
    const response = await addReflectionOnObservation({
      refetchQueries: ['Reflections'],
      awaitRefetchQueries: true,
      variables: {
        documentId: postId,
        replyParentId: reflection.id,
        content: content,
      },
    })
    scrollToBottom(repliesRef.current)
    return response
  }

  const scrollToBottom = (element: HTMLDivElement | null) => {
    if (element) element.scrollTop = element.scrollHeight - element.clientHeight
  }

  useEffect(() => {
    scrollToBottom(repliesRef.current)
  }, [])

  return (
    <Popover anchor={anchor} placement="right-start" show onToggle={closePopup}>
      <Flex flexDirection="column" maxHeight="80vh" width="576px">
        <Flex
          minHeight={10}
          ref={repliesRef}
          borderBottom="1px solid"
          borderColor="surfacePrimaryBorder"
          flexDirection="column"
          flexGrow={1}
          p={3}
          overflowY="auto">
          <Comment comment={reflection} xsmall />
          <Box mt={replies.length > 0 ? 3 : 0} pl={3}>
            {replies.map((reply, idx) => (
              <Box key={idx} mb={idx === replies.length - 1 ? 0 : 3}>
                <Comment comment={reply} xxsmall key={reply.id} />
              </Box>
            ))}
          </Box>
        </Flex>
        <Box p={3} pt={0}>
          <CommentInput addComment={addReply} buttonText="Send" placeholder="Write a reflection..." />
        </Box>
      </Flex>
    </Popover>
  )
}

ReflectionList.displayName = 'ReflectionList'

export default React.memo(ReflectionList)
