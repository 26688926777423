import React, { useRef, useState } from 'react'

import { Box, Icon, Text, Flex } from 'stardust'

import ToolTip from '~/ui-components/ToolTip'

interface PostToolTipMessageProps {
  text: string
}

const PostToolTipMessage = ({ text }: PostToolTipMessageProps) => {
  const [showPopover, setShowPopover] = useState(false)
  const shortcutRef = useRef(null)

  return (
    <>
      <Flex ref={shortcutRef} height="55px" width="55px" borderRadius="27.5px" overflow="hidden">
        <Flex minWidth="55px" width="55px" height="55px" justifyContent="center" mr={30}>
          <Icon
            name="questionMark"
            size={20}
            onMouseEnter={() => setShowPopover(true)}
            onMouseLeave={() => setShowPopover(false)}
          />

          {showPopover && (
            <ToolTip
              anchor={shortcutRef}
              placement="bottom"
              show={showPopover}
              onToggle={() => setShowPopover(true)}>
              <Box p={3}>
                <Text fontSize={1} color="white">
                  {text}
                </Text>
              </Box>
            </ToolTip>
          )}
        </Flex>
      </Flex>
    </>
  )
}

PostToolTipMessage.displayName = 'PostToolTipMessage'
export default React.memo(PostToolTipMessage)
