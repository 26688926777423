import React, { ReactNode } from 'react'

export function filterReactChildren(children: ReactNode, func: (child: ReactNode) => boolean) {
  return React.Children.toArray(children).filter(func)
}

export function isFunction(obj: any) {
  return obj && obj.constructor && obj.call && obj.apply
}

export function isNotNull(value: any) {
  return value !== null && value !== undefined
}

export function isObject(obj: any) {
  return typeof obj == 'object' && obj.constructor == Object
}

export function objectValues(obj: Record<string, any>) {
  return Object.keys(obj).map((key) => obj[key])
}
