import React from 'react'

const Room = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.54181 0.598765H11.8322C12.1719 0.598765 12.4804 0.734222 12.7039 0.952888C12.9274 1.17155 13.0663 1.47343 13.0663 1.80578V15.6606H13.5637C13.7728 15.6606 13.9424 15.8265 13.9424 16.0311C13.9424 16.2358 13.7728 16.4012 13.5637 16.4012H1.81085C1.60171 16.4012 1.43212 16.2353 1.43212 16.0311C1.43212 15.8265 1.60171 15.6606 1.81085 15.6606H2.30873V1.80578C2.30873 1.47343 2.44717 1.17155 2.67064 0.952888C2.67905 0.944663 2.68696 0.937407 2.69586 0.93015C2.91785 0.72503 3.21599 0.598765 3.54181 0.598765ZM10.7677 7.07359C10.7677 6.86944 10.9373 6.70351 11.1465 6.70351C11.3556 6.70351 11.5252 6.86944 11.5252 7.07359V7.99276C11.5252 8.1974 11.3556 8.36334 11.1465 8.36334C10.9373 8.36334 10.7677 8.1974 10.7677 7.99276V7.07359ZM3.54181 1.33942C3.41771 1.33942 3.30449 1.38587 3.22044 1.46133L3.2056 1.47633C3.11908 1.56099 3.06568 1.67758 3.06568 1.8053V15.6601H12.3088V1.8053C12.3088 1.67758 12.255 1.56099 12.1689 1.47633C12.0824 1.39167 11.9633 1.33942 11.8327 1.33942H3.54181Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.27469 1.54692C3.206 1.61414 3.16447 1.70573 3.16447 1.8053V15.5613H12.2101V1.8053C12.2101 1.70599 12.1683 1.61427 12.0997 1.5468C12.031 1.47962 11.9365 1.43819 11.8327 1.43819H3.54183C3.44363 1.43819 3.35461 1.47451 3.28849 1.53299L3.27469 1.54692ZM3.22044 1.46133C3.30449 1.38587 3.41771 1.33942 3.54181 1.33942H11.8327C11.9633 1.33942 12.0824 1.39167 12.1689 1.47633C12.255 1.56099 12.3088 1.67758 12.3088 1.8053V15.6601H3.06568V1.8053C3.06568 1.67758 3.11908 1.56099 3.2056 1.47633L3.22044 1.46133ZM3.54183 0.5H11.8322C12.1986 0.5 12.5317 0.646168 12.773 0.882294C13.0143 1.11838 13.1651 1.44525 13.1651 1.80578V15.5618H13.5637C13.8254 15.5618 14.0412 15.77 14.0412 16.0311C14.0412 16.2925 13.8252 16.5 13.5637 16.5H1.81086C1.5493 16.5 1.33337 16.2919 1.33337 16.0311C1.33337 15.77 1.54922 15.5618 1.81086 15.5618H2.20998V1.80578C2.20998 1.44535 2.36023 1.11845 2.60159 0.882294C2.61076 0.873313 2.6202 0.864573 2.63098 0.855641C2.87021 0.635553 3.19114 0.5 3.54183 0.5ZM2.69586 0.93015C2.68696 0.937407 2.67905 0.944663 2.67064 0.952888C2.44717 1.17155 2.30873 1.47343 2.30873 1.80578V15.6606H1.81085C1.60171 15.6606 1.43212 15.8265 1.43212 16.0311C1.43212 16.2353 1.60171 16.4012 1.81085 16.4012H13.5637C13.7728 16.4012 13.9424 16.2358 13.9424 16.0311C13.9424 15.8265 13.7728 15.6606 13.5637 15.6606H13.0663V1.80578C13.0663 1.47343 12.9274 1.17155 12.7039 0.952888C12.4804 0.734222 12.1719 0.598765 11.8322 0.598765H3.54181C3.21599 0.598765 2.91785 0.72503 2.69586 0.93015ZM10.669 7.07359C10.669 6.8128 10.8849 6.60474 11.1465 6.60474C11.4081 6.60474 11.624 6.8128 11.624 7.07359V7.99276C11.624 8.25396 11.4081 8.4621 11.1465 8.4621C10.8848 8.4621 10.669 8.25396 10.669 7.99276V7.07359ZM11.1465 6.70351C10.9373 6.70351 10.7677 6.86944 10.7677 7.07359V7.99276C10.7677 8.1974 10.9373 8.36334 11.1465 8.36334C11.3556 8.36334 11.5252 8.1974 11.5252 7.99276V7.07359C11.5252 6.86944 11.3556 6.70351 11.1465 6.70351Z"
      fill="currentColor"
    />
  </svg>
)

export default Room
