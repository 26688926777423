import React from 'react'

import * as R from 'ramda'

import { Avatar, Box, Flex, Text, Truncate } from 'stardust'

import * as profiles from '~/components/Profiles/core'
import { caps } from '~/utils'

const typeDisplayText = R.ifElse(R.equals('learning_outcome'), R.always('Learning Outcome'), caps)

const typeAvatar = R.ifElse(
  R.propEq('type', 'learning_outcome'),
  R.always(<Avatar icon="learningFramework" xsmall />),
  (entity) => (
    <Avatar
      alt={entity.displayText}
      xsmall
      src={entity.imageUrl}
      text={profiles.initials(entity.displayText)}
    />
  )
)

const Suggestion = ({ entity }) => (
  <Flex alignItems="center">
    <Box px={3}>{typeAvatar(entity)}</Box>
    <Box borderBottom={1} borderColor="surfacePrimaryBorder" py={2} width={1}>
      <Text caps fontSize={1} mb={2}>
        {entity.displayText}
      </Text>
      <Truncate caps fontSize={0} mb={1}>
        {typeDisplayText(entity.type)}
      </Truncate>
    </Box>
  </Flex>
)

Suggestion.displayName = 'Suggestion'
export default Suggestion
