import * as R from 'ramda'

const get = R.curry((lens, a) => R.view(lens, a))
const set = R.curry((lens, a, b) => R.set(lens, a, b))

/**
 * Create an object with get, set fns that act on the given
 * lensProp.
 */
const make = (lensProp) => {
  const lens = R.lensPath(R.flatten([lensProp]))

  return {
    get: get(lens),
    set: set(lens),
  }
}

/**
 * Planner
 */
export const editId = make(['edit', 'id'])
export const editValue = make(['edit', 'value'])

export const isCloning = make('isCloning')
export const isDirty = make('isDirty')
export const isDragging = make('isDragging')
export const isPreview = make('isPreview')
export const isResizing = make('isResizing')
export const title = make('title')
export const transform = make('transform')

export const nodeIdsLens = R.lensProp('nodeIds')
export const nodeIds = {
  get(a) {
    return R.view(nodeIdsLens, a)
  },
  append: R.curry((a, b) => R.over(nodeIdsLens, R.append(a), b)),
  set: R.curry((a, b) => R.set(nodeIdsLens, a, b)),
  remove: R.curry((a, b) => R.over(nodeIdsLens, R.without(a), b)),
}

export const nodesLens = R.lensProp('nodes')
export const nodes = {
  get(a) {
    return R.view(nodesLens, a)
  },
  set: R.curry((a, b) => R.over(nodesLens, R.mergeDeepLeft(a), b)),
  remove: R.curry((a, b) => R.over(nodesLens, R.omit(a), b)),
}

export const nodeLens = (id) => R.compose(nodesLens, R.lensProp(id))
export const node = {
  get: R.curry((a, b) => R.view(nodeLens(a), b)),
}

export const plannerPositions = make('plannerPositions')

export const selectionLens = R.lensProp('selection')
export const selection = {
  get: R.view(selectionLens),
  append: R.curry((a, b) => R.over(selectionLens, R.append(a), b)),
  set: R.curry((a, b) => R.set(selectionLens, a, b)),
  remove: R.curry((a, b) => R.over(selectionLens, R.without(a), b)),
}

export const updatedAtLens = R.lensProp('updatedAt')
export const updatedAt = {
  get: R.view(updatedAtLens),
  set: R.curry((a, b) => R.set(updatedAtLens, a, b)),
}

/**
 * Node
 */
export const tagged = make('tagged')

export const idLens = R.lensProp('id')
export const id = {
  get: R.view(idLens),
  set: R.curry((a, b) => R.set(idLens, a, b)),
}

export const kind = make('kind')
export const propKind = make(['properties', 'kind'])

/**
 * Properties
 */
export const propertiesLens = R.lensProp('properties')
export const properties = {
  get: R.view(propertiesLens),
  set: R.curry((a, b) => R.over(propertiesLens, R.mergeDeepLeft(a), b)),
}

const propertiesFieldLens = (prop) => R.compose(propertiesLens, R.lensProp(prop))

export const background = make(['properties', 'background'])

export const dimensionsLens = propertiesFieldLens('dimensions')
export const dimensions = {
  get: R.view(dimensionsLens),
  set: R.curry((a, b) => R.set(dimensionsLens, a, b)),
}
export const pointLens = propertiesFieldLens('point')
export const point = {
  get: R.view(pointLens),
  set: R.curry((a, b) => R.set(pointLens, a, b)),
}

export const shapeKindLens = propertiesFieldLens('kind')
export const stickerKindLens = propertiesFieldLens('kind')
export const zLens = propertiesFieldLens('z')
export const z = {
  get(a) {
    return R.view(zLens, a)
  },
  set(b, a) {
    return R.set(zLens, b, a)
  },
}

/**
 * Data
 */
export const dataLens = R.lensProp('data')

export const data = {
  get(a) {
    return R.view(dataLens, a)
  },
  set: R.curry((a, b) => R.over(dataLens, R.mergeDeepLeft(a), b)),
}

const dataFieldLens = (prop) => R.compose(dataLens, R.lensProp(prop))

export const refData = make(['data', 'refData'])
export const dataKindLens = dataFieldLens('kind')
export const dataRefIdLens = dataFieldLens('refId')
export const dataRefLens = dataFieldLens('ref')
export const dataUrlLens = dataFieldLens('url')
export const dataDropLens = dataFieldLens('isDropped')

export const refId = {
  get: R.view(dataRefIdLens),
  set: R.curry((a, b) => R.over(dataRefIdLens, R.mergeDeepLeft(a), b)),
}

export const ref = make(['data', 'ref'])

export const url = make(['data', 'url'])
