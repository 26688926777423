import '@material/button/dist/mdc.button.css'
import '@material/checkbox/dist/mdc.checkbox.css'
import '@material/floating-label/dist/mdc.floating-label.css'
import '@material/form-field/dist/mdc.form-field.css'
import '@material/icon-button/dist/mdc.icon-button.css'
import '@material/line-ripple/dist/mdc.line-ripple.css'
import '@material/notched-outline/dist/mdc.notched-outline.css'
import '@material/ripple/dist/mdc.ripple.css'
import '@material/select/dist/mdc.select.css'
import '@material/switch/dist/mdc.switch.css'
import '@material/textfield/dist/mdc.textfield.css'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

import React from 'react'

import ReactDOM from 'react-dom'

import App from './App'
import initLogging from './logging'

initLogging()

ReactDOM.render(<App />, document.getElementById('root'))
