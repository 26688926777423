import React from 'react'

const MultiplePeopleTime = () => (
  <svg viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 6C7.88072 6 9 4.88072 9 3.49999C9 2.11929 7.88072 1 6.5 1C5.11928 1 4 2.11929 4 3.49999C4 4.88072 5.11928 6 6.5 6Z"
      stroke="#595959"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10.4616C8.91302 8.5361 6.68895 7.59411 4.57915 8.1656C2.46939 8.73712 1.00092 10.6794 1 12.8996V14.9997H3.06527L3.75368 22H7.88426L8.18163 18.9824"
      stroke="#595959"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 22C19.5376 22 22 19.5376 22 16.5C22 13.4624 19.5376 11 16.5 11C13.4624 11 11 13.4624 11 16.5C11 19.5376 13.4624 22 16.5 22Z"
      stroke="#008E86"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M20 17H17V14" stroke="#008E86" strokeLinecap="round" strokeLinejoin="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 6C16.8807 6 18 4.8807 18 3.5C18 2.11929 16.8807 1 15.5 1C14.1193 1 13 2.11929 13 3.5C13 4.8807 14.1193 6 15.5 6Z"
      stroke="#595959"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 9C19.302 7.32283 18.064 6.21626 16.6754 6.02835C15.2867 5.84048 13.9181 6.59437 13 8.05291"
      stroke="#595959"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default MultiplePeopleTime
