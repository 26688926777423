import React from 'react'

import { Flex, Text } from 'stardust'

import { isPlayground } from '~/config'

const Placeholder = () => {
  if (!isPlayground) return null
  return (
    <Flex height="100%" width="100%" flexDirection="row" alignItems="center" justifyContent="center">
      <Text color="textPrimaryMedium" textAlign="center">
        Please drop media here
      </Text>
    </Flex>
  )
}

Placeholder.displayName = 'Placeholder'

export default Placeholder
