import React, { useCallback } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { navigate } from '@reach/router'
import gql from 'graphql-tag'

import { Box, Card, Flex, Heading, SecondaryButton } from 'stardust'

import Modal from '~/components/Modal'
import { useSelectedServiceFkey } from '~/contexts/Service'
import { ADD_DOCUMENT_TO_FOLDER, REMOVE_DOCUMENT_FROM_FOLDER } from '~/modules/documentFolders'

import { generateToastMessage } from '../ToastMessages/GenerateToastMessage'

import FolderSelector from './FolderSelector'
import { generateHeader } from './utils'

const TYPE_MAPPING = {
  learning_plan: 'learningPlan',
  learning_story: 'learningStory',
  learning_table: 'learningTable',
  observation: 'observation',
} as Record<string, string>

const buildQuery = (documentType: string) => {
  return gql`
    query DocumentWithFolders($documentId: Int!, $serviceFkey: String!) {
      service(fkey: $serviceFkey) {
        fkey
        ${TYPE_MAPPING[documentType]}(id: $documentId) {
          id
          folders {
            id
            name
          }
        }
      }
    }
  `
}

const MODAL_OUTER_SPACING = '40px'

interface Props {
  documentId: Nullable<number>
  documentType: Playground.DocumentType
  onClose(): void
  onRefetch(): void
}

const CopyToFolderModal = ({ documentId, documentType, onClose, onRefetch }: Props) => {
  const serviceFkey = useSelectedServiceFkey()
  const { data, loading } = useQuery(buildQuery(documentType), {
    variables: { documentType, documentId, serviceFkey },
    fetchPolicy: 'network-only',
    skip: !documentId,
  })

  const document = data?.service[TYPE_MAPPING[documentType]]
  const folders = document?.folders as Nullable<Playground.DocumentFolder[]>

  const [addDocument] = useMutation(ADD_DOCUMENT_TO_FOLDER, {
    onCompleted: (data) => {
      onRefetch
      const { id, name, type } = data.addDocumentToFolder

      generateToastMessage(id, {
        message: `Moved to "${name}"`,
        buttonText: 'View',
        buttonAction: () => navigate(`/${generateHeader(type)}/folders/${id}`),
      })
    },
  })
  const [removeDocument] = useMutation(REMOVE_DOCUMENT_FROM_FOLDER, {
    onCompleted: onRefetch,
  })

  const onFolderSelect = useCallback(
    (folder: Playground.DocumentFolder) => {
      if (!folders) return
      if (folders.length !== 0) {
        // Remove the content from its current folders
        folders.forEach((folderDetails) =>
          removeDocument({
            variables: {
              folderId: folderDetails.id,
              document: { type: documentType, typeId: documentId },
            },
          })
        )
      }
      addDocument({
        variables: {
          folderId: folder.id,
          document: { type: documentType, typeId: documentId },
        },
      })
      onClose()
    },
    [documentId, documentType, folders, onClose, addDocument, removeDocument]
  )

  const onModalClose = useCallback(() => {
    onClose()
  }, [onClose])

  const copyContentFolders = folders ? folders.map((folder) => folder.id) : []

  return (
    <Modal open={!!documentId} onClose={onModalClose}>
      <Card width="500px" maxWidth={`calc(100vw - ${MODAL_OUTER_SPACING})`}>
        {loading ? (
          <>
            <Heading.h4 lineHeight={1} mb={0} mt={0} pt={3} px={3}>
              Select a folder
            </Heading.h4>
            <Flex height="389px" p={3}>
              Fetching folder list...
            </Flex>
          </>
        ) : (
          <FolderSelector
            folderType={documentType}
            disabledFolders={copyContentFolders}
            onSelect={onFolderSelect}
          />
        )}
        <Box borderColor="surfacePrimaryBorder" borderTop="1px solid" px={3} py={2} pt={1}>
          <SecondaryButton onClick={onModalClose}>Cancel</SecondaryButton>
        </Box>
      </Card>
    </Modal>
  )
}

export default React.memo(CopyToFolderModal)
