import { useEffect, useRef } from 'react'

import usePersistLocallyWithUser from '~/hooks/usePersistLocallyWithUser'

// Also change in cypress/integration/observations.ts
export const AUTO_SAVE_ENABLE_DELAY = 2000

function useAutosave<T>(type: string, id: Nullable<number | string>, state: T) {
  const autosaveRef = useRef(false)
  const { persist } = usePersistLocallyWithUser(type)

  useEffect(() => {
    const enableAutosave = setTimeout(() => {
      autosaveRef.current = true
      console.info('Autosaving now enabled')
    }, AUTO_SAVE_ENABLE_DELAY)
    return () => clearTimeout(enableAutosave)
  }, [])

  // Watch for changes and trigger autosave. Will only enable
  // after a delay so that bootstrapping events are ignored.
  // Any events after that should be user-triggered
  useEffect(() => {
    if (autosaveRef.current) {
      const persistor = persist
      persistor(id || 'new', JSON.stringify(state))
    }
  }, [id, persist, state])
}

export default useAutosave
