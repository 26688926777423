import React from 'react'

const PostsNav = () => (
  <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M2.18829 1.44116C2.02821 1.44116 1.89843 1.5878 1.89843 1.76869V16.2799C1.89843 16.5354 2.14592 16.6925 2.33908 16.5596L7.08369 13.2941C7.329 13.1252 7.61069 13.0359 7.89792 13.0359H15.0581C15.2182 13.0359 15.348 12.8893 15.348 12.7084V1.76869C15.348 1.5878 15.2182 1.44116 15.0581 1.44116H2.18829ZM0.623047 1.76869C0.623047 0.791871 1.32383 0 2.18829 0H15.0581C15.9226 0 16.6234 0.791871 16.6234 1.76869V12.7084C16.6234 13.6852 15.9226 14.4771 15.0581 14.4771H7.89792C7.84473 14.4771 7.79257 14.4936 7.74714 14.5249L3.00253 17.7904C1.95946 18.5083 0.623047 17.6599 0.623047 16.2799V1.76869Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M7.89792 14.4771L7.89812 16.6571C7.89812 16.838 8.0279 16.9846 8.18798 16.9846H15.3482C15.6354 16.9846 15.9171 17.0739 16.1624 17.2428L20.907 20.5083C21.1002 20.6412 21.3477 20.4841 21.3477 20.2285V5.71738C21.3477 5.53648 21.2179 5.38984 21.0578 5.38984H16.6239V3.94868H21.0578C21.9223 3.94868 22.623 4.74056 22.623 5.71738V20.2285C22.623 21.6086 21.2866 22.457 20.2436 21.7391L15.499 18.4736C15.4535 18.4423 15.4014 18.4258 15.3482 18.4258H8.18798C7.32352 18.4258 6.62273 17.6339 6.62273 16.6571V15.2991L7.74714 14.5249C7.79257 14.4936 7.84473 14.4771 7.89792 14.4771Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M9.12297 8.46156C9.12297 8.15001 9.38414 7.89746 9.70631 7.89746H12.0397C12.3619 7.89746 12.623 8.15001 12.623 8.46156C12.623 8.7731 12.3619 9.02566 12.0397 9.02566H9.70631C9.38414 9.02566 9.12297 8.7731 9.12297 8.46156Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M9.12297 5.35898C9.12297 4.89166 9.39324 4.51283 9.72664 4.51283L13.0194 4.51282C13.3528 4.51282 13.6231 4.89166 13.6231 5.35898C13.6231 5.8263 13.3528 6.20514 13.0194 6.20514L9.72664 6.20514C9.39324 6.20514 9.12297 5.8263 9.12297 5.35898Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M4.3926 5.94478V8.1578H6.35417V5.94478H4.3926ZM4.04644 4.51283C3.53662 4.51283 3.12334 4.97908 3.12334 5.55425V8.54834C3.12334 9.1235 3.53662 9.58976 4.04644 9.58976H6.70033C7.21014 9.58976 7.62343 9.1235 7.62343 8.54834V5.55425C7.62343 4.97909 7.21014 4.51283 6.70033 4.51283H4.04644Z"
    />
  </svg>
)

export default PostsNav
