import React from 'react'

const NoChildrenFound = () => (
  <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.5" y="1.5" width="85" height="85" rx="42.5" fill="#FCE6EC" />
    <rect x="1.5" y="1.5" width="85" height="85" rx="42.5" stroke="#686868" strokeWidth="3" />
    <g clipPath="url(#clip0_30839_133520)">
      <path
        d="M63.2537 60.3227C64.3651 60.3227 65.2661 59.4103 65.2661 58.2849C65.2661 57.1594 64.3651 56.2471 63.2537 56.2471C62.1422 56.2471 61.2412 57.1594 61.2412 58.2849C61.2412 59.4103 62.1422 60.3227 63.2537 60.3227Z"
        fill="#686868"
      />
      <path
        d="M39.5742 74.121C49.9091 74.121 58.2872 65.6374 58.2872 55.1723C58.2872 44.7072 49.9091 36.2236 39.5742 36.2236C29.2394 36.2236 20.8613 44.7072 20.8613 55.1723C20.8613 65.6374 29.2394 74.121 39.5742 74.121Z"
        fill="white"
      />
      <path
        d="M37.0119 67.5007C40.0808 67.5007 42.5687 64.9815 42.5687 61.8739C42.5687 58.7663 40.0808 56.2471 37.0119 56.2471C33.9429 56.2471 31.4551 58.7663 31.4551 61.8739C31.4551 64.9815 33.9429 67.5007 37.0119 67.5007Z"
        fill="#FAC87F"
      />
      <path
        d="M40.7062 47.8931C42.155 47.8931 43.3294 46.7038 43.3294 45.2368C43.3294 43.7698 42.155 42.5806 40.7062 42.5806C39.2575 42.5806 38.083 43.7698 38.083 45.2368C38.083 46.7038 39.2575 47.8931 40.7062 47.8931Z"
        fill="#FAC87F"
      />
      <path
        d="M58.5483 59.9984C59.1029 57.7422 59.2081 55.3969 58.858 53.0991C58.5078 50.8013 57.7092 48.5971 56.5087 46.6153C55.3083 44.6334 53.73 42.9134 51.8661 41.5557C50.0022 40.1981 47.8899 39.2299 45.6525 38.7078C44.7735 38.508 43.8791 38.3859 42.9792 38.3428C46.1612 39.6599 48.8845 41.9055 50.8029 44.794C52.7212 47.6824 53.7479 51.0833 53.7524 54.5642C53.7321 54.5543 53.7099 54.5492 53.6874 54.5492C53.6648 54.5492 53.6426 54.5543 53.6223 54.5642C53.2993 54.4882 52.9646 54.4781 52.6378 54.5347C52.3109 54.5912 51.9985 54.7133 51.7188 54.8936C51.4391 55.0739 51.1977 55.3089 51.0088 55.5849C50.8199 55.8608 50.6872 56.1721 50.6186 56.5006C50.5357 56.8245 50.5185 57.1619 50.5681 57.4927C50.6177 57.8234 50.7331 58.1405 50.9072 58.4248C51.0812 58.7091 51.3104 58.9547 51.5809 59.1468C51.8514 59.3388 52.1576 59.4733 52.4809 59.5422C52.6887 59.5877 52.9038 59.5877 53.1117 59.5422C52.0632 63.507 49.7114 66.9915 46.4441 69.4207C43.1769 71.8499 39.1894 73.0788 35.1396 72.9046C35.9786 73.2471 36.8466 73.5118 37.7328 73.6954C46.9841 75.8143 56.3256 69.6197 58.5483 59.9984Z"
        fill="#FAC87F"
      />
      <path
        d="M39.5744 75.4999C35.599 75.5119 31.7096 74.3291 28.3986 72.1013C25.0876 69.8734 22.504 66.7008 20.9749 62.985C19.4458 59.2693 19.0401 55.1777 19.809 51.2283C20.578 47.2789 22.4871 43.6494 25.2946 40.7994C28.1021 37.9495 31.6817 36.0073 35.58 35.2187C39.4783 34.4302 43.52 34.8308 47.1933 36.3699C50.8666 37.9089 54.0063 40.5172 56.2147 43.8644C58.4231 47.2115 59.6008 51.147 59.5989 55.1724C59.5936 60.5532 57.4838 65.7128 53.731 69.5223C49.9783 73.3318 44.8882 75.4811 39.5744 75.4999ZM39.5744 37.5924C36.1402 37.5904 32.7826 38.6197 29.9262 40.5502C27.0699 42.4807 24.8431 45.2256 23.5275 48.4378C22.212 51.6499 21.8667 55.185 22.5355 58.5959C23.2042 62.0067 24.8569 65.1401 27.2845 67.5997C29.7122 70.0593 32.8056 71.7347 36.1737 72.4138C39.5417 73.093 43.033 72.7454 46.2059 71.4151C49.3788 70.0849 52.0909 67.8316 53.999 64.9404C55.9071 62.0491 56.9256 58.6498 56.9256 55.1724C56.923 50.5125 55.0944 46.044 51.8412 42.748C48.5881 39.452 44.1763 37.5978 39.5744 37.5924Z"
        fill="#686868"
      />
      <path
        d="M33.9779 13.8789H30.6338C29.9758 13.8789 29.4424 14.4191 29.4424 15.0854V48.532C29.4424 49.1983 29.9758 49.7384 30.6338 49.7384H33.9779C34.636 49.7384 35.1694 49.1983 35.1694 48.532V15.0854C35.1694 14.4191 34.636 13.8789 33.9779 13.8789Z"
        fill="#F28602"
      />
      <path
        d="M33.0061 51.1173H31.6044C30.6705 51.1146 29.7756 50.7377 29.1152 50.069C28.4549 49.4004 28.0827 48.4942 28.0801 47.5485V16.1195C28.0827 15.1738 28.4549 14.2677 29.1152 13.599C29.7756 12.9303 30.6705 12.5535 31.6044 12.5508H33.0061C33.9297 12.5559 34.8146 12.927 35.4714 13.5845C36.1282 14.242 36.5047 15.1337 36.5204 16.0688V47.5485C36.5178 48.4925 36.147 49.3971 35.4888 50.0655C34.8306 50.7339 33.9383 51.1119 33.0061 51.1173ZM31.6044 15.2577C31.392 15.2577 31.1882 15.3432 31.038 15.4953C30.8878 15.6474 30.8034 15.8537 30.8034 16.0688V47.5485C30.806 47.7628 30.8912 47.9676 31.0409 48.1191C31.1905 48.2707 31.3928 48.357 31.6044 48.3596H33.0061C33.2169 48.3545 33.4177 48.2674 33.5669 48.1164C33.716 47.9653 33.802 47.762 33.8071 47.5485V16.1195C33.8045 15.9052 33.7193 15.7004 33.5696 15.5489C33.42 15.3974 33.2177 15.3111 33.0061 15.3084L31.6044 15.2577Z"
        fill="#545554"
      />
      <path
        d="M62.6126 37.4204H35.1689V15.7344H62.6126C63.1203 15.7348 63.618 15.878 64.0502 16.1479C64.4824 16.4178 64.8321 16.8039 65.0605 17.2632C65.2888 17.7224 65.3868 18.2369 65.3435 18.7492C65.3002 19.2615 65.1173 19.7516 64.8152 20.1649L60.1295 26.5723L64.8152 32.9798C65.1202 33.3931 65.3055 33.8842 65.3503 34.3981C65.3951 34.912 65.2977 35.4283 65.0691 35.8893C64.8404 36.3504 64.4894 36.7378 64.0556 37.0082C63.6217 37.2786 63.122 37.4213 62.6126 37.4204Z"
        fill="#EF214D"
      />
      <path
        d="M62.6129 38.789H33.8076V14.3555H62.6129C63.3677 14.3605 64.1065 14.5761 64.7481 14.9787C65.3897 15.3812 65.9093 15.9551 66.2497 16.6372C66.5902 17.3194 66.7383 18.0834 66.6778 18.8452C66.6173 19.607 66.3506 20.3373 65.9069 20.9556L61.8219 26.5418L65.9069 32.1281C66.3591 32.7464 66.6329 33.4796 66.6979 34.2462C66.763 35.0128 66.6166 35.7826 66.2751 36.4699C65.9337 37.1572 65.4105 37.7351 64.7639 38.1392C64.1174 38.5432 63.3727 38.7577 62.6129 38.7586V38.789ZM36.581 36.0415H62.6629C62.9201 36.0424 63.1722 35.9695 63.3901 35.8314C63.608 35.6932 63.7829 35.4954 63.8945 35.2608C64.0155 35.0317 64.0686 34.7719 64.0473 34.5129C64.0259 34.2539 63.9312 34.0067 63.7743 33.8009L58.4778 26.5722L63.7743 19.3436C63.9323 19.1384 64.0278 18.891 64.0492 18.6317C64.0705 18.3724 64.0168 18.1125 63.8945 17.8836C63.7829 17.649 63.608 17.4512 63.3901 17.3131C63.1722 17.1749 62.9201 17.1021 62.6629 17.103H36.5209L36.581 36.0415Z"
        fill="#686868"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.0661 45.5108L64.125 49.9007L63.2539 45.5108L64.125 41.1006L65.0661 45.5108Z"
        fill="#686868"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.1747 46.4332L59.8193 45.5715L64.1446 44.5576L68.5 45.3788L64.1747 46.4332Z"
        fill="#686868"
      />
      <path
        d="M48.8197 34.3075C49.9288 34.3075 50.8279 33.4035 50.8279 32.2883C50.8279 31.1731 49.9288 30.269 48.8197 30.269C47.7106 30.269 46.8115 31.1731 46.8115 32.2883C46.8115 33.4035 47.7106 34.3075 48.8197 34.3075Z"
        fill="white"
      />
      <rect x="46.8115" y="18.9614" width="4.0164" height="10.5" rx="2.0082" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_30839_133520">
        <rect width="49" height="63" fill="white" transform="translate(19.5 12.5)" />
      </clipPath>
    </defs>
  </svg>
)

export default NoChildrenFound
