import gql from 'graphql-tag'

export default gql`
  fragment PostLearningRecords on Post {
    learningRecords {
      ageGroupId
      childFkey
      outcomeId
      rankId
    }
  }
`
