import React, { ReactNode } from 'react'

import styled from 'styled-components'

import { LAYERS } from '~/theme'

const Container = styled.div<{ background?: string }>`
  background: ${(props) => props.background || 'white'};
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: ${LAYERS.Fullscreen};
`

interface Props {
  background?: string
  children: ReactNode
}

const FullScreen = ({ background, children }: Props) => (
  <Container background={background}> {children} </Container>
)

FullScreen.displayName = 'FullScreen'

export default FullScreen
