const language = navigator.languages[0] || navigator.language

function isNumberComparison(a: any, b: any) {
  return typeof a === 'number' && typeof b === 'number'
}

function numberComparison(a: number, b: number) {
  if (a < b) return -1
  if (a > b) return 1
  return 0
}

function stringComparison(a: any, b: any) {
  const safeA = (a || '').toString()
  const safeB = (b || '').toString()
  return safeA.localeCompare(safeB, language, {
    numeric: true,
    ignorePunctuation: true,
    sensitivity: 'base',
  })
}

function sortObjectsBy<T>(collection: T[], key: string): T[] {
  return [...collection].sort((a, b) => {
    const aVal = (a as any)[key]
    const bVal = (b as any)[key]

    return isNumberComparison(aVal, bVal) ? numberComparison(aVal, bVal) : stringComparison(aVal, bVal)
  })
}

export default sortObjectsBy
