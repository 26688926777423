import gql from 'graphql-tag'

export const GET_LEARNING_PLAN_COMMENTS = gql`
  query LearningPlan($documentId: Int!, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      learningPlan(id: $documentId) {
        id
        comments {
          content
          id
          insertedAt
          author {
            fkey
            name
            type
            userRef
          }
        }
      }
    }
  }
`

export const GET_LEARNING_STORY_COMMENTS = gql`
  query LearningStory($documentId: Int!, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      learningStory(id: $documentId) {
        id
        comments {
          content
          id
          insertedAt
          author {
            fkey
            name
            type
            userRef
          }
        }
      }
    }
  }
`

export const GET_LEARNING_TABLE_COMMENTS = gql`
  query LearningTable($documentId: Int!, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      learningTable(id: $documentId) {
        id
        comments {
          content
          id
          insertedAt
          author {
            fkey
            name
            type
            userRef
          }
        }
      }
    }
  }
`

export const GET_MOMENT_COMMENTS = gql`
  query MomentComments($documentId: Int!, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      moment(id: $documentId) {
        id
        comments {
          content
          id
          insertedAt
          author {
            fkey
            name
            type
            userRef
          }
        }
      }
    }
  }
`

export const GET_OBSERVATION_COMMENTS = gql`
  query ObservationComments($documentId: Int!, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      observation(id: $documentId) {
        id
        comments {
          content
          id
          insertedAt
          author {
            fkey
            name
            type
            userRef
          }
        }
      }
    }
  }
`

export const GET_POST_COMMENTS = gql`
  query PostComments($postId: Int!, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      post(id: $postId) {
        id
        viewCount
        likeCount
        isLikedByCurrentUser
        updatedAt
        status
        comments {
          content
          id
          replyToCommentId
          likeCount
          isLikedByCurrentUser
          isHidden
          insertedAt
          updatedAt
          author {
            fkey
            name
            type
            userRef
          }
        }
      }
    }
  }
`
