import React from 'react'

const ObservationModified = () => (
  <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32.459,0.9836H14.7541C12.9508,0.9836 11.4754,2.459 11.4754,4.2623V33.7705C11.4754,35.5738 12.9508,37.0492 14.7541,37.0492H35.7377C37.541,37.0492 39.0164,35.5738 39.0164,33.7705V7.541C39.0164,3.9344 36.0656,0.9836 32.459,0.9836Z"
      fill="#ffffff"
    />
    <path
      d="M26.9508,24.918H17.4426C16.8852,24.918 16.459,24.4918 16.459,23.9344C16.459,23.3771 16.8852,22.9508 17.4426,22.9508H26.9508C27.5082,22.9508 27.9344,23.3771 27.9344,23.9344C27.9344,24.4918 27.5082,24.918 26.9508,24.918Z"
      fill="#7BCAC5"
    />
    <path
      d="M31.8033,15.082H17.377C16.8197,15.082 16.3934,14.6557 16.3934,14.0984C16.3934,13.541 16.8197,13.1148 17.377,13.1148H31.8033C32.3606,13.1148 32.7869,13.541 32.7869,14.0984C32.7869,14.6557 32.3606,15.082 31.8033,15.082Z"
      fill="#7BCAC5"
    />
    <path
      d="M31.8033,20H17.377C16.8197,20 16.3934,19.5738 16.3934,19.0164C16.3934,18.459 16.8197,18.0328 17.377,18.0328H31.8033C32.3606,18.0328 32.7869,18.459 32.7869,19.0164C32.7869,19.5738 32.3606,20 31.8033,20Z"
      fill="#7BCAC5"
    />
    <path
      d="M31.8688,24.918H31.8032C31.2459,24.918 30.8196,24.4918 30.8196,23.9344C30.8196,23.3771 31.2459,22.9508 31.8032,22.9508H31.8688C32.4262,22.9508 32.8524,23.3771 32.8524,23.9344C32.8524,24.4918 32.4262,24.918 31.8688,24.918Z"
      fill="#7BCAC5"
    />
    <path
      d="M11.4754,38.0328C11.4098,38.0328 11.3442,38.0328 11.2787,38C11.2131,38 11.1475,37.9672 11.0819,37.9344C11.0164,37.9016 10.9508,37.8688 10.918,37.8361C10.8524,37.8033 10.8197,37.7705 10.7541,37.7049C10.7213,37.6721 10.6557,37.6066 10.6229,37.5738C10.5901,37.5082 10.5574,37.4754 10.5246,37.4098C10.4918,37.3443 10.4918,37.2787 10.459,37.2131C10.459,37.1475 10.4262,37.082 10.4262,37.0164C10.4262,36.7541 10.5246,36.4918 10.7213,36.3279C10.9508,36.0984 11.2787,36 11.6065,36.0656C11.6721,36.0656 11.7377,36.0984 11.8033,36.1311C11.8688,36.1639 11.9344,36.1967 11.9672,36.2295C12.0328,36.2623 12.0656,36.2951 12.1311,36.3607C12.3278,36.5574 12.4262,36.7869 12.4262,37.0492C12.4262,37.3115 12.3278,37.5738 12.1311,37.7377C12.0983,37.7705 12.0328,37.8361 11.9672,37.8688C11.9016,37.9016 11.8688,37.9344 11.8033,37.9672C11.7377,38 11.6721,38 11.6065,38.0328C11.6065,38.0328 11.541,38.0328 11.4754,38.0328Z"
      fill="#444B54"
    />
    <path
      d="M16.2623,38.0328C16.0328,38.0328 15.8033,37.9672 15.6393,37.8033C15.2131,37.4426 15.1803,36.8197 15.541,36.4262L16.4918,35.3115C16.918,34.6557 17.6393,34.2623 18.3934,34.2951C19.1475,34.3279 19.8033,34.7541 20.1639,35.4426L20.459,36C20.459,36.0328 20.4918,36.0656 20.5574,36.0656C20.6229,36.0656 20.6557,36.0328 20.6557,36L21.3443,33.8033C21.6393,32.8852 22.4262,32.2295 23.377,32.1639C24.3279,32.0656 25.2459,32.5574 25.6721,33.4098L26.6557,35.2787C26.8525,35.6393 27.1803,35.8689 27.5738,35.9344C27.9672,36 28.3607,35.8689 28.6557,35.5738C29.0492,35.1803 29.6721,35.1803 30.0328,35.5738C30.3934,35.9672 30.4262,36.5902 30.0328,36.9508C29.3115,37.6721 28.2951,38 27.2787,37.8689C26.2623,37.7049 25.4098,37.1148 24.918,36.1967L23.9344,34.3279C23.8361,34.1311 23.6393,34.0984 23.541,34.1311C23.4426,34.1311 23.2787,34.1967 23.2131,34.3934L22.5246,36.5902C22.2623,37.4098 21.5738,37.9672 20.7213,38.0328C19.8688,38.0984 19.082,37.6393 18.6885,36.8852L18.3934,36.3279C18.3607,36.2623 18.3279,36.2623 18.3279,36.2623C18.2951,36.2623 18.1967,36.2951 18.1311,36.3934C18.0984,36.4262 18.0656,36.4918 18.0328,36.5246L17.0492,37.6721C16.8197,37.9016 16.5574,38.0328 16.2623,38.0328Z"
      fill="#444B54"
    />
    <path
      d="M39.0164,7.541H35.7377C33.9344,7.541 32.459,6.0656 32.459,4.2623V0.9836C36.0655,0.9836 39.0164,3.9344 39.0164,7.541Z"
      fill="#ffffff"
    />
    <path
      d="M39.0164,8.5246C38.459,8.5246 38.0328,8.0984 38.0328,7.541C38.0328,6.0656 37.4426,4.6557 36.3934,3.6066C35.3443,2.5574 33.9344,1.9672 32.459,1.9672C31.9016,1.9672 31.4754,1.541 31.4754,0.9836C31.4754,0.4262 31.9016,0 32.459,0C34.4918,0 36.3606,0.7869 37.8033,2.2295C39.2131,3.6721 40,5.541 40,7.5738C40,8.0984 39.541,8.5246 39.0164,8.5246Z"
      fill="#7BCAC5"
    />
    <path
      d="M35.0164,38.0328C34.459,38.0328 34.0328,37.6066 34.0328,37.0492C34.0328,36.4918 34.459,36.0656 35.0164,36.0656C36.7869,36.0656 37.9672,35.1475 37.9672,33.7705L38,11.8033C38,11.2459 38.4262,10.8197 38.9836,10.8197C39.541,10.8197 39.9672,11.2459 39.9672,11.8033L39.9344,33.7705C39.9344,36.2295 37.8688,38.0328 35.0164,38.0328Z"
      fill="#7BCAC5"
    />
    <path
      d="M39.0164,8.5246H35.7377C33.3771,8.5246 31.4754,6.623 31.4754,4.2623V1.9672H14.7541C14.1967,1.9672 13.7705,1.541 13.7705,0.9836C13.7705,0.4262 14.1967,0 14.7541,0H32.459C33.0164,0 33.4426,0.4262 33.4426,0.9836V4.2623C33.4426,5.541 34.459,6.5574 35.7377,6.5574H39.0164C39.5738,6.5574 40,6.9836 40,7.541C40,8.0984 39.5738,8.5246 39.0164,8.5246Z"
      fill="#7BCAC5"
    />
    <path
      d="M6.1639,33.2459L4.2623,37.0492L2.3606,33.2459C1.4426,31.4426 0.9836,29.4426 0.9836,27.4098V4.2623C0.9836,2.459 2.459,0.9836 4.2623,0.9836C6.0655,0.9836 7.541,2.459 7.541,4.2623V27.4098C7.541,29.4426 7.0819,31.4426 6.1639,33.2459Z"
      fill="#ffffff"
    />
    <path
      d="M5.5738,25.1148C5.0164,25.1148 4.5901,24.6885 4.5901,24.1312V5.9016C4.5901,5.3443 5.0164,4.918 5.5738,4.918C6.1311,4.918 6.5574,5.3443 6.5574,5.9016V24.1312C6.5574,24.6557 6.1311,25.1148 5.5738,25.1148Z"
      fill="#7BCAC5"
    />
    <path
      d="M8.5246,4.2623V4.623C10.7213,4.7869 12.459,6.623 12.459,8.8525V17.7049C12.459,18.2623 12.0328,18.6885 11.4754,18.6885C10.918,18.6885 10.4918,18.2623 10.4918,17.7049V8.8525C10.4918,7.7049 9.6393,6.7541 8.5246,6.5902V27.4098C8.5246,29.5738 8.0328,31.7705 7.0492,33.7049L5.1475,37.5082C4.9836,37.8689 4.6557,38.0656 4.2623,38.0656C3.8688,38.0656 3.541,37.8361 3.377,37.5082L1.4754,33.7049C0.5246,31.7705 0,29.5738 0,27.4098V4.2623C0,1.9016 1.9016,0 4.2623,0C6.623,0 8.5246,1.9016 8.5246,4.2623ZM5.2787,32.8197C6.0984,31.1475 6.5574,29.2787 6.5574,27.4098V4.2623C6.5574,2.9836 5.541,1.9672 4.2623,1.9672C2.9836,1.9672 1.9672,2.9836 1.9672,4.2623V27.4098C1.9672,29.2787 2.4262,31.1475 3.2459,32.8197L4.2623,34.8525L5.2787,32.8197Z"
      fill="#444B54"
      fillRule="evenodd"
    />
  </svg>
)

export default ObservationModified
