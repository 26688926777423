import * as R from 'ramda'

export const child = 'child'
export const children = 'children'
export const content = 'content'
export const learningPlan = 'learning_plan'
export const learningStory = 'learning_story'
export const learningTable = 'learning_table'
export const moment = 'moment'
export const media = 'media'
export const observation = 'observation'
export const shape = 'shape'
export const sticker = 'sticker'
export const learningOutcome = 'learning_outcome'

export const isLearningDocument = (kind) =>
  R.contains(kind, [learningStory, observation, moment, learningTable, learningPlan])

export const isChild = R.equals(child)
export const isChildren = R.equals(children)
export const isContent = R.equals(content)
export const isMedia = R.equals(media)
export const isSticker = R.equals(sticker)

export default {
  child,
  content,
  media,
  shape,
  sticker,
  learningOutcome,
}
