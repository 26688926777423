import { style } from 'styled-system'

export const topRight = style({
  prop: 'brTopRight',
  cssProperty: 'borderTopRightRadius',
  key: 'radii',
  transformValue: (n) => n,
  scale: [2, 4, 6, 8, 12, 24],
})

export const bottomRight = style({
  prop: 'brBottomRight',
  cssProperty: 'borderBottomRightRadius',
  key: 'radii',
  transformValue: (n) => n,
  scale: [2, 4, 6, 8, 12, 24],
})

export const bottomLeft = style({
  prop: 'brBottomLeft',
  cssProperty: 'borderBottomLeftRadius',
  key: 'radii',
  transformValue: (n) => n,
  scale: [2, 4, 6, 8, 12, 24],
})

export const topLeft = style({
  prop: 'brTopLeft',
  cssProperty: 'borderTopLeftRadius',
  key: 'radii',
  transformValue: (n) => n,
  scale: [2, 4, 6, 8, 12, 24],
})
