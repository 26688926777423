import React from 'react'

const AlignLeft = () => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M15 15H3v2h12v-2zm0-8H3v2h12V7zM3 13h18v-2H3v2zm0 8h18v-2H3v2zM3 3v2h18V3H3z"
    />
  </svg>
)

export default AlignLeft
