import React, { ReactNode, useContext } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { navigate } from '@reach/router'
import RPathOr from 'ramda/src/pathOr'

import { Box, Card, Flex, Heading, Text } from 'stardust'
import styled from 'styled-components'

import Can from '~/components/Authorisation/Can'
import { onHandleCopyTemplateModal } from '~/components/Layouts/CopyTemplateModal'
import LayoutAdd from '~/components/Layouts/LayoutAdd'
import LayoutTile from '~/components/Layouts/LayoutTile'
import { ServiceContext, useSelectedServiceFkey } from '~/contexts/Service'
import { UserContext } from '~/contexts/User'

import { isSuperAdmin, layouts } from '~/modules/permissions'

import { compareObjectValues } from '~/utils'

import { LearningTemplateTypes } from './enums'
import { ARCHIVE_PROVIDER_LAYOUT, ARCHIVE_SERVICE_LAYOUT, COPY_SERVICE_LAYOUT } from './mutations'
import { GET_LAYOUTS } from './queries'

interface LayoutSelectorProps {
  type: keyof typeof LearningTemplateTypes
  fromHome?: boolean
  onCopyToService: (layoutId: number) => void
  onTileClick: () => void
}

interface GroupedTemplates {
  providerLayouts: Playground.LearningTemplate[]
  serviceLayouts: Playground.LearningTemplate[]
  globalLayouts: Playground.LearningTemplate[]
}

type TemplateType = 'providerLayouts' | 'serviceLayouts' | 'globalLayouts'

const StyledLayoutBox = styled(Box)`
  margin: 0 32px 32px 0;
`

const StyledCard = styled(Card)`
  overflow-y: auto;
`

const PlaceholderTile = () => (
  <StyledLayoutBox>
    <Box bg="cosmicShade3" borderRadius="16px" height="140px" width="140px" />
  </StyledLayoutBox>
)

const optionMenuFor = (
  layout: Playground.LearningTemplate,
  type: keyof typeof LearningTemplateTypes,
  level: Playground.LearningTemplateLevels,
  deleteFn: () => void,
  copyLayoutFn: (layoutId: number) => void,
  user: Nullable<Playground.User>
) => {
  const urlPrefix = LearningTemplateTypes[type]
  const editLayout =
    level == 'provider'
      ? {
          label: 'Edit Layout',
          onClick: () => navigate(`/${urlPrefix}/layouts/provider/${layout.id}`),
        }
      : {
          label: 'Edit Layout',
          onClick: () => navigate(`/${urlPrefix}/layouts/${layout.id}`),
        }

  const deleteLayout = {
    label: 'Delete',
    onClick: deleteFn,
  }

  const copyLayout = {
    label: 'Copy Layout',
    onClick: () => copyLayoutFn(layout.id),
  }

  const copyTemplateToServices = {
    label: 'Copy Template to Services',
    onClick: () => onHandleCopyTemplateModal.open(layout),
  }

  let options = [editLayout, copyLayout, deleteLayout]
  if (user && isSuperAdmin(user)) {
    options = [...options, copyTemplateToServices]
  }
  return options
}

const isServiceTemplate = (template: Playground.LearningTemplate) =>
  template.serviceFkey && !template.global && template.title !== 'Blank'
const isProviderTemplate = (template: Playground.LearningTemplate) =>
  template.providerFkey && !template.global && template.title !== 'Blank'
const isGlobalTemplate = (template: Playground.LearningTemplate) =>
  template.global && template.title !== 'Blank'

function getTemplatesByType(data: any) {
  const templates = RPathOr<Playground.LearningTemplate[]>([], ['service', 'templates'], data)
  return templates.reduce(
    (acc, template) => {
      let type: TemplateType | undefined
      switch (true) {
        case isServiceTemplate(template):
          type = 'serviceLayouts'
          break
        case isProviderTemplate(template):
          type = 'providerLayouts'
          break
        case isGlobalTemplate(template):
          type = 'globalLayouts'
          break
      }

      if (!type) return acc
      const templates = [...acc[type], template]
      templates.sort(compareObjectValues('title', 'asc'))
      return { ...acc, [type]: templates }
    },
    { providerLayouts: [], serviceLayouts: [], globalLayouts: [] } as GroupedTemplates
  )
}

function findServiceName(fkey: Nullable<string>, list: Playground.SimpleService[], or: any) {
  const service = list.find((service) => service.fkey === fkey)
  return service ? service.name : or
}

const Overline = ({ children }: { children: ReactNode }) => (
  <Box mb={3}>
    <Text.span color="black" fontSize={0} letterSpacing="3.2px" upper>
      {children}
    </Text.span>
  </Box>
)

const LayoutSelector = ({ type, fromHome, onCopyToService, onTileClick }: LayoutSelectorProps) => {
  const { state: serviceContext } = useContext(ServiceContext)
  const serviceFkey = useSelectedServiceFkey()
  const serviceName = findServiceName(serviceFkey, serviceContext.services, '')

  const [archiveServiceLayout] = useMutation(ARCHIVE_SERVICE_LAYOUT)
  const [archiveProviderLayout] = useMutation(ARCHIVE_PROVIDER_LAYOUT)
  const [copyServiceLayout] = useMutation(COPY_SERVICE_LAYOUT)
  const { state: user } = useContext(UserContext)

  const onArchive = (id: number, level: Playground.LearningTemplateLevels) => {
    const inputs = {
      refetchQueries: [{ query: GET_LAYOUTS, variables: { serviceFkey, type } }],
      awaitRefetchQueries: true,
      variables: { id },
    }

    level == 'provider' ? archiveProviderLayout(inputs) : archiveServiceLayout(inputs)
  }

  const onCopyServiceLayout = (id: number) => {
    const inputs = {
      refetchQueries: [{ query: GET_LAYOUTS, variables: { serviceFkey, type } }],
      awaitRefetchQueries: true,
      variables: { templateId: id, serviceFkey },
    }

    copyServiceLayout(inputs)
  }

  const typeOfDocument = (type: string) => {
    switch (type) {
      case 'LEARNING_PLAN':
        return 'planning'
      case 'LEARNING_STORY':
        return 'stories'
      case 'LEARNING_TABLE':
        return 'tables'
    }
  }

  const urlForDocType = (layoutId: number): string => {
    return fromHome ? `${typeOfDocument(type)}/new/${layoutId}` : `new/${layoutId}`
  }

  const { data, error, loading } = useQuery(GET_LAYOUTS, {
    variables: {
      type,
      serviceFkey,
    },
  })

  if (error) {
    throw error
  }

  if (loading) {
    return (
      <Card height={['80vh']} width={['90vw', '80vw', 1200]} p={4} data-test="layout-selector">
        <Heading.h3 bold={false} lineHeight={1} mb={4} mt={0}>
          Select Layout
        </Heading.h3>

        <Flex>
          <PlaceholderTile />
          <PlaceholderTile />
          <PlaceholderTile />
        </Flex>
      </Card>
    )
  }

  const { providerLayouts, serviceLayouts, globalLayouts } = getTemplatesByType(data)

  return (
    <StyledCard height={['80vh']} width={['90vw', '80vw', 1200]} p={4} data-test="layout-selector">
      <Heading.h3 bold={false} lineHeight={1} mb={4} mt={0}>
        Select Layout
      </Heading.h3>

      <Box>
        <Flex flexDirection="column">
          <Box mb={4} data-test="layouts-global">
            <Overline>XPLOR</Overline>
            <Flex flexDirection="row" flexWrap="wrap">
              <StyledLayoutBox>
                <LayoutAdd
                  to={`/${typeOfDocument(type)}/new`}
                  addText="Blank"
                  type={type}
                  onTileClick={onTileClick}
                />
              </StyledLayoutBox>
              {globalLayouts.map((layout) => (
                <StyledLayoutBox key={layout.id}>
                  <LayoutTile
                    isGlobal={true}
                    title={layout.title}
                    type={type}
                    to={urlForDocType(layout.id)}
                    onTileClick={onTileClick}
                  />
                </StyledLayoutBox>
              ))}
            </Flex>
          </Box>

          <Box mb={4} data-test="layouts-provider">
            <Overline>Provider</Overline>
            <Flex flexDirection="row" flexWrap="wrap">
              <Can perform={layouts.writeProvider}>
                {(canWrite) => (
                  <>
                    {canWrite && (
                      <StyledLayoutBox>
                        <LayoutAdd to="layouts/provider/new" addText="New Layout" type="" />
                      </StyledLayoutBox>
                    )}

                    <>
                      {providerLayouts.map((layout) => (
                        <StyledLayoutBox key={layout.id}>
                          <LayoutTile
                            title={layout.title}
                            type={type}
                            to={urlForDocType(layout.id)}
                            menuItems={
                              canWrite
                                ? optionMenuFor(
                                    layout,
                                    type,
                                    'provider',
                                    () => onArchive(layout.id, 'provider'),
                                    onCopyToService,
                                    user
                                  )
                                : undefined
                            }
                          />
                        </StyledLayoutBox>
                      ))}
                    </>
                  </>
                )}
              </Can>
            </Flex>
          </Box>

          <Box data-test="layouts-service">
            <Overline>{serviceName}</Overline>
            <Flex flexDirection="row" flexWrap="wrap">
              <Can perform={layouts.write}>
                {(canWrite) => (
                  <>
                    {canWrite && (
                      <StyledLayoutBox>
                        <LayoutAdd to="layouts/new" addText="New Layout" type="" />
                      </StyledLayoutBox>
                    )}

                    {serviceLayouts.map((layout) => (
                      <StyledLayoutBox key={layout.id}>
                        <LayoutTile
                          title={layout.title}
                          type={type}
                          to={urlForDocType(layout.id)}
                          menuItems={
                            canWrite
                              ? optionMenuFor(
                                  layout,
                                  type,
                                  'service',
                                  () => onArchive(layout.id, 'service'),
                                  () => onCopyServiceLayout(layout.id),
                                  user
                                )
                              : undefined
                          }
                        />
                      </StyledLayoutBox>
                    ))}
                  </>
                )}
              </Can>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </StyledCard>
  )
}

LayoutSelector.displayName = 'LayoutSelector'

export default LayoutSelector
