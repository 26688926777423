import isUrl from 'is-url'

import { LINK_NODE_TYPE } from './types'

export default {
  inlines: {
    [LINK_NODE_TYPE]: {
      data: {
        href: isUrl,
      },
      isVoid: true,
    },
  },
}
