import React, { useCallback, useContext } from 'react'

import { Box, Icon, IconButton, Interactive, Text } from 'stardust'

import useHover from '~/hooks/useHover'
import { humanizeAsset } from '~/modules/learningContent/humanizeAsset'

import ListItem from './ListItem'
import { PostContext } from './PostProvider'

interface ContentAssetProps {
  asset: Playground.LearningContentAsset
  expandToMaxWidth?: boolean
  inSidebar?: boolean
  small?: boolean
  selected?: boolean
  onShowDetails: () => void
}

const ContentAsset = ({
  asset: rawAsset,
  selected = false,
  expandToMaxWidth = false,
  inSidebar = false,
  small = false,
  onShowDetails,
}: ContentAssetProps) => {
  const asset = humanizeAsset(rawAsset)

  const {
    handlers: { toggleLearningContent },
    state: { canAddLearningContentAssets },
  } = useContext(PostContext)

  const [hoverRef, hover] = useHover()

  const renderIcon = () => (
    <Interactive id={`asset-${asset.id}`} onClick={onShowDetails}>
      <Icon name={asset.icon} my={3} ml={small ? 0 : 2} mr={2} fill="textPrimaryMedium" />
    </Interactive>
  )

  const renderText = () => (
    <Box mr={!inSidebar && hover ? null : 48}>
      <Interactive onClick={onShowDetails}>
        <Text color="textPrimaryMedium" fontSize={1} lineHeight={1.3}>
          {asset.humanizedType} - {asset.title} - {asset.humanizedDocumentLength}
        </Text>
      </Interactive>
    </Box>
  )

  const disableAdd = !(selected || canAddLearningContentAssets)

  const sidebarItemTail = useCallback(
    () => (
      <IconButton
        onClick={() => toggleLearningContent(asset)}
        data-test="content-list-item-icon"
        icon={
          <Icon
            name={selected ? 'check' : 'AddCircleGreyable'}
            borderRadius="50%"
            disabled={disableAdd}
            title={
              disableAdd &&
              "Can't add more content to this observation. The maximum number of content items are already tagged."
            }
          />
        }
        width={32}
      />
    ),
    [asset, disableAdd, selected, toggleLearningContent]
  )

  const formItemTail = useCallback(
    () =>
      hover && (
        <IconButton
          icon={<Icon name={'close'} fill="textPrimaryMedium" />}
          onClick={() => toggleLearningContent(asset)}
          width={32}
        />
      ),
    [asset, hover, toggleLearningContent]
  )

  const renderTail = inSidebar ? sidebarItemTail : formItemTail

  return (
    <Box hover={hover} ref={hoverRef}>
      <ListItem
        dataTest="content-list-item"
        max={expandToMaxWidth}
        small={small}
        renderIcon={renderIcon}
        renderText={renderText}
        renderTail={renderTail}
      />
    </Box>
  )
}

ContentAsset.displayName = 'Content Asset'

export default ContentAsset
