import config, { isHome } from '~/config'

import isResponseOk from '~/utils/isResponseOk'
import toJSON from '~/utils/toJSON'

const sharedConfig: any = {
  credentials: 'include',
  headers: { 'Content-Type': 'application/json' },
  method: 'POST',
}

const AUTH_TOKEN_KEY = 'hodorAuthToken'

export const clearAuthToken = () => localStorage.removeItem(AUTH_TOKEN_KEY)
export const getAuthToken = () => localStorage.getItem(AUTH_TOKEN_KEY)
export const setAuthToken = (token: string) => localStorage.setItem(AUTH_TOKEN_KEY, token)

export const fetchWithAuth = (uri: RequestInfo, options: RequestInit) => {
  const token = getAuthToken()
  return fetch(uri, {
    ...options,
    headers: new Headers({
      // Allow the auth header to be overridden if needed
      Authorization: `Bearer ${token}`,
      ...(options.headers || {}),
    }),
  })
}

export const logout = () => {
  return fetch(`${config.HODOR_API}/logout`, {
    credentials: 'include',
    method: 'POST',
  })
}

export const redirectToLogin = () => {
  isHome
    ? window.location.assign(`${config.STARGATE_URL}/?app=home-web`)
    : window.location.assign(`${config.STARGATE_URL}/?app=playground`)
}

export default (uri: string) => {
  return {
    refresh() {
      return fetch(`${uri}/refresh`, sharedConfig)
        .then(isResponseOk)
        .then(toJSON)
        .then((data) => data.data.token)
    },
  }
}
