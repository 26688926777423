import React from 'react'

const Finance = () => (
  <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.625 4.625C0.625 3.79657 1.29657 3.125 2.125 3.125H17.875C18.7034 3.125 19.375 3.79657 19.375 4.625V17.875C19.375 18.7034 18.7034 19.375 17.875 19.375H2.125C1.29657 19.375 0.625 18.7034 0.625 17.875V4.625Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path d="M0.625 8.125H19.375" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
    <path
      d="M5.625 5V0.625"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.375 5V0.625"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.2499 11.25H9.55578C9.01945 11.2494 8.55819 11.6296 8.45645 12.1562C8.35471 12.6828 8.64114 13.2075 9.13911 13.4067L10.8591 14.0942C11.3559 14.2941 11.6413 14.8181 11.5397 15.3439C11.4381 15.8697 10.978 16.2496 10.4424 16.25H8.74994"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 11.25V10.625"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 16.875V16.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Finance
