import React from 'react'

import format from 'date-fns/format'
import TruncateMarkup from 'react-truncate-markup'

import { Box, Flex, Icon, SecondaryButton, Divider, Text, BackgroundImage } from 'stardust'

import * as borderRadius from 'stardust/utils/borderRadius'
import styled from 'styled-components'

import Placeholder from '~/components/DocumentCardPlaceholder'

import colors from '~/ui-components/tokens/colors'
import { formatDate } from '~/utils'

const S = {
  PreviewCard: styled(Flex)`
    z-index: 100;
    position: sticky;
    top: 0;
    overflow-y: auto;
    height: 100vh;
  `,
  FuturePlanNote: styled(Text)`
    white-space: pre-wrap;
    overflow-wrap: anywhere;
  `,
}

const HiddenBox = styled(Box)`
  ${borderRadius.topLeft}
  ${borderRadius.topRight}
   overflow: hidden;
`

interface LinksSelectedDocumentPreviewProps {
  selectedDocument: Nullable<Playground.SimpleDocument>
  showMorePreviewCard: boolean
  setShowMorePreviewCard: React.Dispatch<React.SetStateAction<boolean>>
}

const LinksSelectedDocumentPreview: React.FC<LinksSelectedDocumentPreviewProps> = ({
  selectedDocument,
  showMorePreviewCard,
  setShowMorePreviewCard,
}) => {
  return (
    <>
      {selectedDocument && (
        <>
          <S.PreviewCard py={3} pl={3} pr={4} width="416px" flexDirection="column" bg="white">
            {selectedDocument.coverImage ? (
              <HiddenBox brTopLeft={16} brTopRight={16}>
                <BackgroundImage image={selectedDocument.coverImage} width={1} height="25vh" />
              </HiddenBox>
            ) : (
              <Placeholder type={selectedDocument.type} height="25vh" />
            )}
            <Text.span fontSize={2} lineHeight="24px" mt="15px">
              <TruncateMarkup lines={3} ellipsis="...">
                <strong>{selectedDocument.title}</strong>
              </TruncateMarkup>
            </Text.span>
            <Text.span fontSize={12} lineHeight="16px" mt="12px">
              <TruncateMarkup lines={3} ellipsis="...">
                <Text>
                  {selectedDocument && selectedDocument.previewText !== ''
                    ? selectedDocument.previewText
                    : 'None'}
                </Text>
              </TruncateMarkup>
            </Text.span>
            <Flex alignItems="center" justifyContent="space-between" height={16} mt={3}>
              <Flex>
                <Icon name="clock" fill={colors.cosmicShade11} size={15} />
                <Text fontSize={14} lineHeight="14px" ml={2} color={colors.cosmicShade11}>
                  Last modified {formatDate(selectedDocument.updatedAt, 'YY')}
                </Text>
              </Flex>
              <SecondaryButton width="120px" onClick={() => setShowMorePreviewCard(!showMorePreviewCard)}>
                {showMorePreviewCard ? 'Show less' : 'Show more'}
              </SecondaryButton>
            </Flex>
            <Text mt={20} fontSize={14} lineHeight="21px">
              <strong>About this file</strong>
            </Text>
            <Flex mt={2} alignItems="center">
              <Box width={125} minWidth={125} maxWidth={125}>
                <Text fontSize={12} lineHeight="16px">
                  Owner
                </Text>
              </Box>
              <Text fontSize={12} lineHeight="16px">
                <strong>{selectedDocument.author}</strong>
              </Text>
            </Flex>
            <Flex mt={2} alignItems="center">
              <Box width={125} minWidth={125} maxWidth={125}>
                <Text fontSize={12} lineHeight="16px">
                  Date created
                </Text>
              </Box>
              <Text fontSize={12} lineHeight="16px">
                <strong>{format(selectedDocument.insertedAt, 'D MMM YYYY')}</strong>
              </Text>
            </Flex>
            <Flex mt={2} alignItems="center">
              <Box width={125} minWidth={125} maxWidth={125}>
                <Text fontSize={12} lineHeight="16px">
                  Time created
                </Text>
              </Box>
              <Text fontSize={12} lineHeight="16px">
                <strong>{format(selectedDocument.insertedAt, 'h:mm a')}</strong>
              </Text>
            </Flex>
            <Flex mt={2} alignItems="center">
              <Box width={125} minWidth={125} maxWidth={125}>
                <Text fontSize={12} lineHeight="16px" maxWidth={40}>
                  Room
                </Text>
              </Box>
              <Text fontSize={12} lineHeight="16px">
                <strong>{selectedDocument.taggedRooms[0]?.name}</strong>
              </Text>
            </Flex>
            {showMorePreviewCard && (
              <Flex flexDirection="column">
                <Divider borderColor={colors.cosmicShade14} my={2} width="100%" />
                <Text fontSize={14} lineHeight="21px">
                  <strong>Tags</strong>
                </Text>
                <Flex mt={2} alignItems="center">
                  <Box width={125} minWidth={125} maxWidth={125}>
                    <Text fontSize={12} lineHeight="16px">
                      Children
                    </Text>
                  </Box>
                  <Text fontSize={12} lineHeight="16px">
                    <strong>{selectedDocument.taggedChildren.length} Children</strong>
                  </Text>
                </Flex>
                <Flex mt={2} alignItems="center">
                  <Box width={125} minWidth={125} maxWidth={125}>
                    <Text fontSize={12} lineHeight="16px">
                      Learning Outcomes
                    </Text>
                  </Box>
                  <Text fontSize={12} lineHeight="16px">
                    <strong>{selectedDocument.linkedLearningOutcomes.length} Tags</strong>
                  </Text>
                </Flex>
                <Flex mt={2} alignItems="top">
                  <Box width={125} minWidth={125} maxWidth={125}>
                    <Text fontSize={12} lineHeight="16px">
                      Link Learning Documentations{' '}
                    </Text>
                  </Box>
                  <Text fontSize={12} lineHeight="16px">
                    <strong>{selectedDocument.linkLearningDocuments.length} links</strong>
                  </Text>
                </Flex>
                <Flex mt={2} alignItems="center">
                  <Box width={125} minWidth={125} maxWidth={125}>
                    <Text fontSize={12} lineHeight="16px">
                      Future Plan date
                    </Text>
                  </Box>
                  <Text fontSize={12} lineHeight="16px">
                    <strong>{selectedDocument?.future_plan?.future_plan_date || 'None'}</strong>
                  </Text>
                </Flex>
                <Flex mt={2} alignItems="top">
                  <Box width={125} minWidth={125} maxWidth={125}>
                    <Text fontSize={12} lineHeight="16px">
                      Future Plan note
                    </Text>
                  </Box>
                  <Box width="calc(100% - 125px)">
                    <S.FuturePlanNote fontSize={12} lineHeight="16px">
                      <strong>{selectedDocument?.future_plan?.future_plan_note || 'None'}</strong>
                    </S.FuturePlanNote>
                  </Box>
                </Flex>
              </Flex>
            )}
          </S.PreviewCard>
        </>
      )}
    </>
  )
}

LinksSelectedDocumentPreview.displayName = 'LinksSelectedDocumentPreview'
export default React.memo(LinksSelectedDocumentPreview)
