import persistLocally from '~/utils/persistLocally'

const VIEW_FROM_PLAYGROUND_APP_CONTEXT = 'view_from'
const VIEW_FROM_PLAYGROUND_APP_KEY = 'playground_app'

const isViewFromPlaygroundApp = () => {
  const { persist, retrieve } = persistLocally(VIEW_FROM_PLAYGROUND_APP_CONTEXT)

  const isPlaygroundAppUrl = window.location.href.includes('inApp=true')
  if (isPlaygroundAppUrl) {
    // If we ever see a URL with value set, set immediately
    persist(VIEW_FROM_PLAYGROUND_APP_KEY, 'true')
  }

  const currentValue = retrieve(VIEW_FROM_PLAYGROUND_APP_KEY)

  if (!currentValue) {
    // not yet stored, so set according to url
    persist(VIEW_FROM_PLAYGROUND_APP_KEY, isPlaygroundAppUrl ? 'true' : 'false')
    return isPlaygroundAppUrl
  }

  // Stored already so return value stored
  return currentValue == 'true'
}

export default isViewFromPlaygroundApp
