import gql from 'graphql-tag'

import {
  draftPostContributors,
  draftPostDocuments,
  draftPostInfo,
  draftPostLearningOutcomes,
  draftPostMedia,
  draftPostRooms,
  draftPostTaggedChildrenWithInfo,
} from '~/modules/draftPosts'

import {
  observationComments,
  observationContributors,
  observationInfo,
  observationLearningContentAssets,
  observationLearningOutcomes,
  observationLearningRecords,
  observationMedia,
  observationRooms,
  observationTaggedChildrenWithInfo,
} from '~/modules/observations'

import {
  postComments,
  postContributors,
  PostDocuments,
  postInfo,
  postLearningContentAssets,
  postLearningOutcomes,
  postLearningRecords,
  postMedia,
  postRooms,
  postReflections,
  postTaggedChildrenWithInfo,
} from '~/modules/posts'

export const GET_NEEDS_REVIEW_POSTS_COUNT = gql`
  query Posts($serviceFkey: String, $first: Int) {
    context(fkey: $serviceFkey) {
      fkey
      posts(first: $first, sortBy: { field: "updated_at", order: "desc" }, status: NEEDS_REVIEW) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            status
            updatedAt
          }
        }
      }
    }
  }
`

export const GET_OBSERVATIONS = gql`
  query Observations(
    $bookmarked: Boolean
    $cursor: String
    $status: String
    $serviceFkey: String
    $folderId: Int
  ) {
    context(fkey: $serviceFkey) {
      fkey
      observations(
        first: 20
        after: $cursor
        sortBy: { field: "updated_at", order: "desc" }
        status: $status
        bookmarked: $bookmarked
        folderId: $folderId
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            bookmarked
            externalId
            media {
              id
              coverUrl
              mimeType
              thumbUrl
            }
            commentCount
            observationDate
            publishedAt
            updatedAt
            status
            title
            serviceFkey
          }
        }
      }
    }
  }
`

export const GET_ALL_POSTS = gql`
  query Posts(
    $bookmarked: Boolean
    $cursor: String
    $serviceFkey: String
    $folderId: Int
    $roomFkey: String
    $userRef: String
  ) {
    context(fkey: $serviceFkey) {
      fkey
      posts(
        first: 20
        after: $cursor
        sortBy: { field: "updated_at", order: "desc" }
        bookmarked: $bookmarked
        folderId: $folderId
        roomFkey: $roomFkey
        createdBy: { userRef: $userRef }
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            bookmarked
            externalId
            media {
              id
              coverUrl
              mimeType
              thumbUrl
            }
            commentCount
            contributors {
              fullName
              action
            }
            postDate
            publishedAt
            status
            serviceFkey
            status
            title
            updatedAt
            viewCount
            likeCount
          }
        }
      }
    }
  }
`

export const GET_PUBLISHED_POSTS = gql`
  query Posts($bookmarked: Boolean, $cursor: String, $serviceFkey: String, $folderId: Int) {
    context(fkey: $serviceFkey) {
      fkey
      posts(
        first: 20
        after: $cursor
        status: PUBLISHED
        sortBy: { field: "updated_at", order: "desc" }
        bookmarked: $bookmarked
        folderId: $folderId
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            bookmarked
            externalId
            media {
              id
              coverUrl
              mimeType
              thumbUrl
            }
            commentCount
            comments {
              id
              content
            }
            contributors {
              fullName
              action
            }
            postDate
            publishedAt
            status
            serviceFkey
            status
            title
            updatedAt
            viewCount
            likeCount
          }
        }
      }
    }
  }
`

export const GET_NEEDS_REVIEW_POSTS = gql`
  query Posts($bookmarked: Boolean, $cursor: String, $serviceFkey: String, $folderId: Int) {
    context(fkey: $serviceFkey) {
      fkey
      posts(
        first: 20
        after: $cursor
        status: NEEDS_REVIEW
        sortBy: { field: "updated_at", order: "desc" }
        bookmarked: $bookmarked
        folderId: $folderId
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            bookmarked
            externalId
            media {
              id
              coverUrl
              mimeType
              thumbUrl
            }
            commentCount
            comments {
              id
              content
            }
            contributors {
              fullName
              action
            }
            postDate
            publishedAt
            status
            serviceFkey
            status
            title
            updatedAt
            viewCount
            likeCount
          }
        }
      }
    }
  }
`

export const GET_DRAFT_POSTS = gql`
  query Posts(
    $bookmarked: Boolean
    $cursor: String
    $serviceFkey: String
    $folderId: Int
    $status: PostStatuses
  ) {
    context(fkey: $serviceFkey) {
      fkey
      posts(
        first: 20
        after: $cursor
        status: $status
        sortBy: { field: "updated_at", order: "desc" }
        bookmarked: $bookmarked
        folderId: $folderId
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            bookmarked
            externalId
            media {
              id
              coverUrl
              mimeType
              thumbUrl
            }
            commentCount
            comments {
              id
              content
            }
            contributors {
              fullName
              action
            }
            postDate
            publishedAt
            status
            serviceFkey
            status
            title
            updatedAt
            viewCount
            likeCount
          }
        }
      }
    }
  }
`

export const GET_ARCHIVED_POSTS = gql`
  query Posts($bookmarked: Boolean, $cursor: String, $serviceFkey: String, $folderId: Int) {
    context(fkey: $serviceFkey) {
      fkey
      posts(
        first: 20
        after: $cursor
        status: ARCHIVED
        sortBy: { field: "updated_at", order: "desc" }
        bookmarked: $bookmarked
        folderId: $folderId
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            bookmarked
            externalId
            media {
              id
              coverUrl
              mimeType
              thumbUrl
            }
            commentCount
            comments {
              id
              content
            }
            contributors {
              fullName
              action
            }
            postDate
            publishedAt
            status
            serviceFkey
            status
            title
            updatedAt
            viewCount
            likeCount
          }
        }
      }
    }
  }
`

export const GET_BOOKMARKED_POSTS = gql`
  query Posts($bookmarked: Boolean, $cursor: String, $serviceFkey: String, $folderId: Int) {
    context(fkey: $serviceFkey) {
      fkey
      posts(
        first: 20
        after: $cursor
        sortBy: { field: "updated_at", order: "desc" }
        bookmarked: $bookmarked
        folderId: $folderId
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            bookmarked
            externalId
            media {
              id
              coverUrl
              mimeType
              thumbUrl
            }
            commentCount
            comments {
              id
              content
            }
            contributors {
              fullName
              action
            }
            postDate
            publishedAt
            status
            serviceFkey
            status
            title
            updatedAt
            viewCount
            likeCount
          }
        }
      }
    }
  }
`

export const GET_POSTS_FOLDERS = gql`
  query Posts($bookmarked: Boolean, $cursor: String, $serviceFkey: String, $folderId: Int) {
    context(fkey: $serviceFkey) {
      fkey
      posts(
        first: 20
        after: $cursor
        sortBy: { field: "updated_at", order: "desc" }
        bookmarked: $bookmarked
        folderId: $folderId
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            bookmarked
            externalId
            media {
              id
              coverUrl
              mimeType
              thumbUrl
            }
            commentCount
            comments {
              id
              content
            }
            contributors {
              fullName
              action
            }
            postDate
            publishedAt
            status
            serviceFkey
            status
            title
            updatedAt
          }
        }
      }
    }
  }
`

export const GET_OBSERVATION = gql`
  query ObservationDetail($id: Int!, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      observation(id: $id) {
        id
        serviceFkey
        ...ObservationComments
        ...ObservationContributors
        ...ObservationInfo
        ...ObservationLearningOutcomes
        ...ObservationLearningRecords
        ...ObservationMedia
        ...ObservationRooms
        ...ObservationTaggedChildrenWithInfo
        ...ObservationLearningContentAssets
      }
    }
  }
  ${observationComments}
  ${observationContributors}
  ${observationInfo}
  ${observationLearningOutcomes}
  ${observationLearningRecords}
  ${observationMedia}
  ${observationRooms}
  ${observationTaggedChildrenWithInfo}
  ${observationLearningContentAssets}
`

export const GET_POST = gql`
  query PostDetail($id: Int!, $serviceFkey: String, $isUnsharedChanges: Boolean) {
    context(fkey: $serviceFkey) {
      fkey
      post(id: $id, isUnsharedChanges: $isUnsharedChanges) {
        id
        serviceFkey
        allowComments
        status
        likeCount
        viewCount
        updatedAt
        ...PostComments
        ...PostContributors
        ...PostDocuments
        ...PostInfo
        ...PostLearningOutcomes
        ...PostLearningRecords
        ...PostMedia
        ...PostRooms
        ...PostTaggedChildrenWithInfo
        ...PostLearningContentAssets
        ...PostReflections
      }
    }
  }
  ${postComments}
  ${postContributors}
  ${PostDocuments}
  ${postInfo}
  ${postLearningOutcomes}
  ${postLearningRecords}
  ${postMedia}
  ${postRooms}
  ${postTaggedChildrenWithInfo}
  ${postLearningContentAssets}
  ${postReflections}
`

export const GET_OBSERVATION_CANVAS = gql`
  query Observation($id: Int!, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      observation(id: $id) {
        id
        title
        children {
          id
          fullName
          fkey
        }
        learningOutcomes {
          name
          id
        }
        media {
          coverUrl
        }
      }
    }
  }
`

export const GET_OBSERVATION_EDIT = gql`
  query ObservationAndCohorts($id: Int!, $serviceFkey: String) {
    service(fkey: $serviceFkey) {
      fkey
      learningFrameworks {
        id
        fullName
        enableCohorts
        ageGroups {
          id
          name
          minMonths
        }
        ranks {
          id
          name
          value
        }
        categories {
          id
          name
          description
        }
        outcomes {
          id
          name
          primaryId
        }
      }
      observation(id: $id) {
        id
        ...ObservationContributors
        ...ObservationInfo
        ...ObservationLearningOutcomes
        ...ObservationLearningRecords
        ...ObservationMedia
        ...ObservationRooms
        ...ObservationTaggedChildrenWithInfo
        ...ObservationLearningContentAssets

        planningDate
      }
      rooms {
        fkey
        name
      }
    }
  }
  ${observationContributors}
  ${observationInfo}
  ${observationLearningOutcomes}
  ${observationLearningRecords}
  ${observationMedia}
  ${observationRooms}
  ${observationTaggedChildrenWithInfo}
  ${observationLearningContentAssets}
`

export const GET_POST_EDIT = gql`
  query PostAndCohorts($id: Int!, $serviceFkey: String, $isUnsharedChanges: Boolean) {
    service(fkey: $serviceFkey) {
      fkey
      learningFrameworks {
        id
        fullName
        enableCohorts
        ageGroups {
          id
          name
          minMonths
        }
        ranks {
          id
          name
          value
        }
        categories {
          id
          name
          description
        }
        outcomes {
          id
          name
          primaryId
        }
      }
      post(id: $id, isUnsharedChanges: $isUnsharedChanges) {
        id
        allowComments
        ...PostContributors
        ...PostDocuments
        ...PostInfo
        ...PostLearningOutcomes
        ...PostLearningRecords
        ...PostMedia
        ...PostRooms
        ...PostTaggedChildrenWithInfo
        ...PostLearningContentAssets
        ...PostReflections
        planningDate
      }
      rooms {
        fkey
        name
      }
    }
  }
  ${postContributors}
  ${PostDocuments}
  ${postInfo}
  ${postLearningOutcomes}
  ${postLearningRecords}
  ${postMedia}
  ${postRooms}
  ${postTaggedChildrenWithInfo}
  ${postLearningContentAssets}
  ${postReflections}
`

export const GET_POST_UPDATED_AT = gql`
  query PostUpdatedAt($id: Int!, $serviceFkey: String, $isUnsharedChanges: Boolean) {
    service(fkey: $serviceFkey) {
      post(id: $id, isUnsharedChanges: $isUnsharedChanges) {
        id
        updatedAt
      }
    }
  }
`

export const GET_DRAFT_POST_EDIT = gql`
  query DraftPostAndCohorts($id: Int!, $serviceFkey: String) {
    service(fkey: $serviceFkey) {
      fkey
      learningFrameworks {
        id
        fullName
        enableCohorts
        ageGroups {
          id
          name
          minMonths
        }
        ranks {
          id
          name
          value
        }
        categories {
          id
          name
          description
        }
        outcomes {
          id
          name
          primaryId
        }
      }
      draftPost(observationId: $id) {
        id
        ...DraftPostContributors
        ...DraftPostDocuments
        ...DraftPostInfo
        ...DraftPostLearningOutcomes
        ...DraftPostMedia
        ...DraftPostRooms
        ...DraftPostTaggedChildrenWithInfo

        planningDate
      }
      rooms {
        fkey
        name
      }
    }
  }
  ${draftPostContributors}
  ${draftPostDocuments}
  ${draftPostInfo}
  ${draftPostLearningOutcomes}
  ${draftPostMedia}
  ${draftPostRooms}
  ${draftPostTaggedChildrenWithInfo}
`

export const GET_OBSERVATION_TEXT = gql`
  query ObservationText($id: Int!, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      observation(id: $id) {
        id
        title
        description
        status
      }
    }
  }
`

export const GET_POST_TEXT = gql`
  query PostText($id: Int!, $serviceFkey: String) {
    context(fkey: $serviceFkey) {
      fkey
      post(id: $id) {
        id
        title
        description
        status
        updatedAt
      }
    }
  }
`

export const GET_CHILDREN_WITH_INFO = gql`
  query ChildrenSummary($serviceFkey: String!, $roomFkey: String) {
    service(fkey: $serviceFkey) {
      fkey
      children(roomFkey: $roomFkey) {
        fullName
        fkey
        id
        image
        isBookedInToday
        otherDetails {
          consents
        }
        room {
          fkey
          name
          roomId
        }
      }
    }
  }
`

export const GET_CHILDREN_BOOKING_AND_SIGNIN_DETAILS = gql`
  query ChildrenBookingSummary($serviceFkey: String!, $dateFrom: UtcDatetime, $dateTo: UtcDatetime) {
    service(fkey: $serviceFkey) {
      bookings(from: $dateFrom, to: $dateTo) {
        childName
        childFkey
        roomFkey
        attendances {
          signedInAt
        }
      }
    }
  }
`

export const GET_FRAMEWORKS = gql`
  query LearningFrameworks($serviceFkey: String!) {
    service(fkey: $serviceFkey) {
      fkey
      learningFrameworks {
        id
        fullName
        enableCohorts
        archivedAt
        ageGroups {
          id
          name
          minMonths
        }
        ranks {
          id
          name
          value
        }
        categories {
          id
          name
          description
        }
        outcomes {
          id
          name
          primaryId
        }
      }
    }
  }
`

export const GET_ROOMS = gql`
  query RoomsSummary($serviceFkey: String!) {
    service(fkey: $serviceFkey) {
      fkey
      rooms {
        fkey
        name
      }
    }
  }
`

export const GET_SERVICE_DETAILS = gql`
  query ServiceDetails($serviceFkey: String) {
    service(fkey: $serviceFkey) {
      fkey
      learningFrameworks {
        ageGroups {
          id
          name
          minMonths
        }
        ranks {
          id
          name
          value
        }
        id
        fullName
        enableCohorts
        shortName
        outcomes {
          id
          name
          primaryId
        }
      }
      rooms {
        fkey
        name
      }
    }
  }
`

export const GET_LEARNING_CONTENT_CATEGORIES = gql`
  query LearningContentCategories($serviceFkey: String!) {
    context(fkey: $serviceFkey) {
      fkey
      learningContentCategories {
        id
        name
      }
    }
  }
`

export const GET_LEARNING_CONTENT_ASSETS_FOR_CATEGORY = gql`
  query LearningContentAssets(
    $serviceFkey: String!
    $categoryId: Int
    $cursor: String
    $frameworkOutcomeIds: [Int]
  ) {
    context(fkey: $serviceFkey) {
      fkey
      learningContentCategory(id: $categoryId) {
        id
        assets(first: 10, after: $cursor, frameworkOutcomeIds: $frameworkOutcomeIds) {
          edges {
            node {
              id
              title
              type
              documentLength
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    }
  }
`

export const GET_LEARNING_CONTENT_SEARCH_ASSETS = gql`
  query LearningContentAssetSearch($serviceFkey: String!, $searchTerm: String, $cursor: String) {
    context(fkey: $serviceFkey) {
      fkey
      learningContentAssets(search: $searchTerm, first: 100, after: $cursor) {
        edges {
          node {
            id
            title
            type
            documentLength
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

export const GET_LEARNING_CONTENT_ASSET = gql`
  query LearningContentAsset($serviceFkey: String!, $id: Int!) {
    context(fkey: $serviceFkey) {
      fkey
      learningContentAsset(id: $id) {
        id
        title
        type
        documentLength
        thumbnailUrl
        documentUrl
        thumbnailMimeType
        documentMimeType
        documentHtml
        categories {
          name
        }
      }
    }
  }
`

export const GET_DOCUMENTS_BY_QUERY_TYPES_AND_CHILDREN = gql`
  query GetDocumentsFiltered(
    $serviceFkey: String
    $cursor: String
    $types: [String]
    $childFkeys: [String]
    $query: String
  ) {
    context(fkey: $serviceFkey) {
      fkey
      documents(
        first: 20
        after: $cursor
        types: $types
        includeNoTaggedChildren: true
        childFkeys: $childFkeys
        query: $query
        status: PUBLISHED
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            commentCount
            author
            coverImage
            insertedAt
            thumbImage
            title
            type
            typeId
            status
            hasUnpublishedChanges
            updatedAt
            previewText
            linkLearningDocuments {
              title
              author
              type
              type_id
            }
            media {
              mimeType
              coverUrl
            }
            future_plan {
              future_plan_date
              future_plan_note
            }
            linkedLearningOutcomes {
              name
              code
            }
            taggedChildren {
              fullName
              fkey
            }
            taggedRooms {
              fkey
              name
            }
          }
        }
      }
    }
  }
`

export const GET_DOCUMENTS_BY_IDS = gql`
  query GetDocumentsFiltered($serviceFkey: String, $ids: [String]) {
    service(fkey: $serviceFkey) {
      fkey
      documents(ids: $ids, first: 20) {
        edges {
          node {
            id
            commentCount
            author
            coverImage
            insertedAt
            thumbImage
            title
            type
            typeId
            status
            hasUnpublishedChanges
            updatedAt
          }
        }
      }
    }
  }
`

export const GET_POST_VIEWS = gql`
  query GetPostViews($serviceFkey: String, $postId: Int) {
    service(fkey: $serviceFkey) {
      viewPostUsers(entityId: $postId, entityType: "post") {
        deletedAt
        entityId
        entityType
        type
        updatedAt
        userDisplayName
        userType
      }
    }
  }
`

export const GET_POST_LIKES = gql`
  query GetPostLikes($serviceFkey: String, $postId: Int) {
    service(fkey: $serviceFkey) {
      likePostUsers(entityId: $postId, entityType: "post") {
        deletedAt
        entityId
        entityType
        type
        updatedAt
        userDisplayName
        userType
      }
    }
  }
`

export const LIKE_POST_USERS = gql`
  query likePostUsers($id: Int, $serviceFkey: String, $entityType: String) {
    service(fkey: $serviceFkey) {
      likePostUsers(entityType: $entityType, entityId: $id) {
        entityId
        entityType
        type
        updatedAt
        userDisplayName
        userType
      }
    }
  }
`

export const VIEW_POST_USERS = gql`
  query viewPostUsers($id: Int, $serviceFkey: String, $entityType: String) {
    service(fkey: $serviceFkey) {
      viewPostUsers(entityType: $entityType, entityId: $id) {
        entityId
        entityType
        type
        updatedAt
        userDisplayName
        userType
      }
    }
  }
`
