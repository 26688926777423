type ID = number | string

const key = (type: string, id: ID, context: string) => {
  const firstDelim = context == '' ? '' : '_'
  return `${context}${firstDelim}${type}_${id}`
}
export default (type: string, context = '') => ({
  persist: (id: ID, toPersist: string) => {
    localStorage.setItem(key(type, id, context), toPersist)
    return toPersist
  },

  discard: (id: ID) => {
    localStorage.removeItem(key(type, id, context))
    localStorage.removeItem(key(type, id, '')) // remove legacy storage without context
  },
  retrieve: (id: ID) =>
    localStorage.getItem(key(type, id, context)) || localStorage.getItem(key(type, id, '')), // retrieve legacy storage without context
})
