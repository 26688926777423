/* eslint-disable react/display-name */
import React from 'react'

import { Box, Flex, Image, Text } from 'stardust'
import styled from 'styled-components'

import InlineMetricItem from './InlineMetricItem'

interface Props {
  count: Nullable<number> | string
  icon: string
  label: string
  inline?: boolean
  large?: boolean
}

const S = {
  Image: styled(Image)`
    align-self: flex-start;
    min-width: 24px;
    min-height: 24px;
  `,
}

const MetricItem = ({ icon, label, count, large = false, inline = false }: Props) => {
  return inline ? (
    <InlineMetricItem icon={icon} count={count} />
  ) : (
    <Flex>
      <Box style={{ minWidth: !large ? '150px' : '200px' }} mr={3}>
        <Text fontSize={0} mb={2}>
          {label}
        </Text>
        <Flex alignItems="center">
          <S.Image height="auto" width={24} role="presentation" src={icon} mr={2} />
          <Text fontSize={[2, 3]}>{count}</Text>
        </Flex>
      </Box>
    </Flex>
  )
}

MetricItem.displayName = 'MetricItem'

export default MetricItem
