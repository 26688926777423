import React from 'react'

const Detach = () => (
  <svg width="24" height="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="24" height="24" rx="12" fill="#F1F1F1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.04936 7.55025C6.83907 7.76053 6.83907 8.10147 7.04936 8.31175L11.2376 12.5L7.04936 16.6882C6.83908 16.8985 6.83907 17.2395 7.04936 17.4497C7.25964 17.66 7.60057 17.66 7.81086 17.4497L11.9991 13.2615L16.1874 17.4497C16.3976 17.66 16.7386 17.66 16.9489 17.4497C17.1591 17.2395 17.1591 16.8985 16.9489 16.6882L12.7606 12.5L16.9489 8.31175C17.1591 8.10147 17.1591 7.76053 16.9489 7.55025C16.7386 7.33997 16.3976 7.33997 16.1874 7.55025L11.9991 11.7385L7.81086 7.55025C7.60057 7.33997 7.25964 7.33997 7.04936 7.55025Z"
      fill="#252525"
    />
  </svg>
)

export default Detach
