const convertToXplorFkey = (id: number | string) => `xplor-${id}`

const toFkey = (id: number | string) => {
  if (typeof id === 'number') {
    return convertToXplorFkey(id)
  }

  if (typeof id === 'string' && id.split('-').length === 1) {
    return convertToXplorFkey(id)
  }

  return id
}

export default toFkey
