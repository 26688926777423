/*
This is a bit ugly context, but it exists mostly for performance reasons.
There is a lot of filtering/grouping logic required for cohort tracking,
and the aim of this context is to make sure it only has to happen when it
really needs to, and to prevent having to pass dependencies all over the
place to child components
*/

/* ===============================================================

  Welcome to junior's rabbit hole.

=================================================================*/

import React, { ReactNode, useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'
import { subDays, subMonths } from 'date-fns'
import differenceInCalendarMonths from 'date-fns/difference_in_calendar_months'
import parse from 'date-fns/parse'

import { DropdownMenuItemProps } from '~/components/DropdownMenu'
import { Fkey, FkeyOrId, Id, ProfileGroup, ProfileItem } from '~/components/FilterDropdown'
import { createLookup, getChildAgesLabel, getFkeyFromObject, sortObjectsBy, toISODate, unique } from '~/utils'

import { GET_COHORT_TRACKING_DEPENDENCIES, GET_LEARNING_FRAMEWORK } from './queries'

type CategoryField = 'primaryId' | 'secondaryId' | 'tertiaryId'

interface DateRange {
  label: string
  from: string
  to: string
}

export interface ChildAgeRange {
  label: string
  lower: number
  upper: number
  value: number
}

interface LearningAnalysisContextState {
  ageGroup: Nullable<Playground.LearningFrameworkAgeGroup>
  ageGroups: Playground.LearningFrameworkAgeGroup[]
  categories: {
    primary: Playground.LearningFrameworkCategory[]
    secondary: Playground.LearningFrameworkCategory[]
    tertiary: Playground.LearningFrameworkCategory[]
  }
  childAgeRanges: ChildAgeRange[]
  childAgeRangeLabel: string
  childGroups: Playground.ChildGroup[]
  children: Playground.Child[]
  cohortsEnabled: boolean
  dateRange: DateRange
  educators: Playground.Educator[]
  filters: ProfileGroup<FkeyOrId>[]
  framework: Nullable<Playground.LearningFramework>
  frameworkId: Nullable<number>
  frameworkDepth: number
  menus: {
    ageGroups: DropdownMenuItemProps[]
    childAgeRanges: ChildAgeRange[]
    dateRanges: DropdownMenuItemProps[]
    frameworks: DropdownMenuItemProps[]
  }
  outcomes: Playground.LearningFrameworkOutcome[]
  ranks: Playground.LearningFrameworkRank[]
  scoreChanges: Playground.LearningRecord[]
  addScoreChange(scoreChange: Playground.LearningRecord): void
  filterChildren(children: Playground.Child[], filter: Nullable<ProfileItem<FkeyOrId>>): Playground.Child[]
  filterRecords(
    records: Playground.LearningRecord[],
    filter: Nullable<ProfileItem<FkeyOrId>>
  ): Playground.LearningRecord[]
  findAgeGroup(ageGroupId: number): Playground.LearningFrameworkAgeGroup | undefined
  findCategory(categoryId: number): Playground.LearningFrameworkCategory | undefined
  findOutcome(outcomeId: number): Playground.LearningFrameworkOutcome | undefined
  findRank(rankId: number): Playground.LearningFrameworkRank | undefined
  groupRecordsByCategory(
    records: Playground.LearningRecord[],
    key?: string
  ): Record<number, Playground.LearningRecord[]>
  onChildAgeRangeChange(values: number[]): void
}

interface Props {
  children: ReactNode
  serviceFkey: string
}

const TODAY = new Date()

const DATE_RANGES: DateRange[] = [
  { label: 'Last Week', from: toISODate(subDays(TODAY, 7)), to: toISODate(TODAY) },
  { label: 'Last Fortnight', from: toISODate(subDays(TODAY, 14)), to: toISODate(TODAY) },
  { label: 'Last Month', from: toISODate(subMonths(TODAY, 1)), to: toISODate(TODAY) },
  { label: 'Last 3 Months', from: toISODate(subMonths(TODAY, 3)), to: toISODate(TODAY) },
]
const LOCAL_STORAGE_FILTERS = {
  ageGroup: 'cohortAgeGroupFilter',
  childAgeRanges: 'cohortChildAgeRangeFilter',
  dateRange: 'cohortDateRangeFilter',
  framework: 'cohortFrameworkFilter',
}

const ALL_CHILD_AGE_RANGES: ChildAgeRange[] = [
  { label: '0 - 1y', lower: 0, upper: 1, value: 2 },
  { label: '1 - 2y', lower: 1, upper: 2, value: 3 },
  { label: '2 - 3y', lower: 2, upper: 3, value: 4 },
  { label: '3 - 4y', lower: 3, upper: 4, value: 5 },
  { label: '4 - 5y', lower: 4, upper: 5, value: 6 },
  { label: '5+ years', lower: 5, upper: Infinity, value: 7 },
]

const DEFAULT_VALUE = {
  ageGroup: null,
  ageGroups: [],
  categories: {
    primary: [],
    secondary: [],
    tertiary: [],
  },
  childAgeRanges: [],
  childAgeRangeLabel: '',
  childGroups: [],
  children: [],
  cohortsEnabled: false,
  dateRange: DATE_RANGES[1],
  educators: [],
  filters: [],
  framework: null,
  frameworkId: null,
  frameworkDepth: 1,
  menus: {
    ageGroups: [],
    childAgeRanges: ALL_CHILD_AGE_RANGES,
    dateRanges: [],
    frameworks: [],
  },
  outcomes: [],
  ranks: [],
  scoreChanges: [],
  addScoreChange: (scoreChange: Playground.LearningRecord) => null,
  filterChildren: (children: Playground.Child[]) => children,
  filterRecords: (records: Playground.LearningRecord[]) => records,
  filterRecordsByCategory: (records: Playground.LearningRecord[]) => records,
  findAgeGroup: () => undefined,
  findCategory: () => undefined,
  findOutcome: () => undefined,
  findRank: () => undefined,
  groupRecordsByCategory: () => ({}),
  onChildAgeRangeChange: () => undefined,
}

const getChildAge = (dob: string | undefined): number | null => {
  if (!dob) {
    return null
  }

  const rawDob = parse(dob)
  const ageInMonths = differenceInCalendarMonths(new Date(), rawDob)
  const ageInYears = ageInMonths / 12

  return ageInYears
}

const getDateRange = () => {
  const savedDateRange = localStorage.getItem(LOCAL_STORAGE_FILTERS.dateRange)

  if (savedDateRange) {
    const range = JSON.parse(savedDateRange)
    const match = DATE_RANGES.find((dr) => dr.label == range.label)
    return match || DATE_RANGES[1]
  }

  return DATE_RANGES[1]
}

export const LearningAnalysisContext = React.createContext<LearningAnalysisContextState>(DEFAULT_VALUE)

const LearningAnalysisProvider = ({ children, serviceFkey }: Props) => {
  const [value, setValue] = useState<LearningAnalysisContextState>(DEFAULT_VALUE)

  const dependencyQuery = useQuery(GET_COHORT_TRACKING_DEPENDENCIES, {
    variables: { serviceFkey },
  })

  const frameworkQuery = useQuery(GET_LEARNING_FRAMEWORK, {
    skip: !value.frameworkId,
    variables: { frameworkId: value.frameworkId, serviceFkey },
  })

  useEffect(() => {
    if (!dependencyQuery.data) return

    const {
      children: allServiceChildren,
      childGroups,
      learningFrameworks,
      rooms,
      personnel: educators,
    }: {
      children: Playground.Child[]
      childGroups: Playground.ChildGroup[]
      learningFrameworks: Playground.LearningFramework[]
      rooms: Playground.Room[]
      personnel: Playground.Educator[]
    } = dependencyQuery.data.service

    const savedFramework = localStorage.getItem(LOCAL_STORAGE_FILTERS.framework)
    const savedFrameworkParsed = savedFramework ? JSON.parse(savedFramework) : null
    const defaultFramework =
      learningFrameworks.find((framework) => framework.id === savedFrameworkParsed?.id) ||
      learningFrameworks[0]

    const defaultDateRange = getDateRange()

    const savedChildAgeRanges = JSON.parse(localStorage.getItem(LOCAL_STORAGE_FILTERS.childAgeRanges) || '[]')
    const defaultChildAgeRanges =
      savedChildAgeRanges && savedChildAgeRanges.length > 0 ? savedChildAgeRanges : ALL_CHILD_AGE_RANGES

    const childAgeLum = allServiceChildren.reduce(
      (acc, child) => ({ ...acc, [child.fkey]: getChildAge(child?.dob) }),
      {} as Record<string, number | null>
    )

    const checkAgeInRange = (age: number | null, range: ChildAgeRange): boolean => {
      if (age === null) {
        return false
      }
      return range.lower <= age && age <= range.upper
    }

    const filterChildrenByAge = (
      children: Playground.Child[],
      childAgeRanges: ChildAgeRange[]
    ): Playground.Child[] => {
      return children.filter((child) => {
        const age = childAgeLum[child.fkey]
        if (age === null) {
          return childAgeRanges.length == ALL_CHILD_AGE_RANGES.length
        }
        return childAgeRanges.some((childAgeRange) => checkAgeInRange(age, childAgeRange))
      })
    }

    const initialChildren = filterChildrenByAge(allServiceChildren, DEFAULT_VALUE.childAgeRanges)

    const getChildAgeRanges = (values: number[]): ChildAgeRange[] => {
      return ALL_CHILD_AGE_RANGES.filter((range) => values.includes(range.value))
    }

    const onChildAgeRangeChange = (childAgeRangeValues: number[]): void => {
      const childAgeRanges = getChildAgeRanges(childAgeRangeValues)
      localStorage.setItem(LOCAL_STORAGE_FILTERS.childAgeRanges, JSON.stringify(childAgeRanges))
      const updatedChildren = filterChildrenByAge(allServiceChildren, childAgeRanges)

      setValue((value) => ({
        ...value,
        childAgeRanges: childAgeRanges,
        childAgeRangeLabel: getChildAgesLabel(childAgeRanges, ALL_CHILD_AGE_RANGES),
        children: updatedChildren,
        filters: updateChildrenFilter(updatedChildren),
      }))
    }

    const filters: [ProfileGroup<Fkey>, ProfileGroup<Fkey>, ProfileGroup<Fkey>, ProfileGroup<Id>] = [
      {
        label: 'Children',
        type: 'child',
        items: allServiceChildren.map((child) => ({
          type: 'child',
          fkey: child.fkey,
          name: child.fullName,
          image: child.image,
        })),
      },
      {
        label: 'Rooms',
        type: 'room',
        items: rooms.map((room) => ({ fkey: room.fkey, name: room.name, type: 'room' })),
      },
      {
        label: 'Educators',
        type: 'educator',
        items: educators.map((educator) => ({
          fkey: educator.fkey,
          name: educator.fullName,
          image: educator.image,
          type: 'educator',
        })),
      },
      {
        label: 'Groups',
        type: 'group',
        items: childGroups.map((group) => ({ id: group.id, name: group.fullName, type: 'group' })),
      },
    ]

    const updateChildrenFilter = (children: Playground.Child[]) => {
      const childrenItems: ProfileItem<Fkey>[] = children.map((child) => ({
        fkey: child.fkey,
        name: child.fullName,
        image: child.image,
        type: 'child',
      }))

      for (const filter of filters) {
        if (filter.label === 'Children') {
          filter['items'] = childrenItems
        }
      }

      return filters
    }

    const onDateRangeChange = (dateRange: DateRange) => {
      setValue((value) => {
        localStorage.setItem(LOCAL_STORAGE_FILTERS.dateRange, JSON.stringify(dateRange))
        return { ...value, dateRange }
      })
    }

    const onFrameworkChange = (framework: Playground.LearningFramework) => {
      setValue((value) => {
        if (framework.id === value.framework?.id) return value

        localStorage.setItem(LOCAL_STORAGE_FILTERS.framework, JSON.stringify(framework))
        return { ...value, frameworkId: framework.id, framework: null }
      })
    }

    const dateRangeMenuOptions = DATE_RANGES.map((dateRange) => ({
      label: dateRange.label,
      onClick: () => onDateRangeChange(dateRange),
    }))

    const frameworkMenuOptions = sortObjectsBy(learningFrameworks, 'fullName').map((framework) => ({
      label: framework.fullName,
      onClick: () => onFrameworkChange(framework),
    }))

    const addScoreChange = (scoreChange: Playground.LearningRecord) => {
      setValue((value) => ({
        ...value,
        scoreChanges: [scoreChange, ...value.scoreChanges],
      }))
    }

    const filterChildren = (children: Playground.Child[], selectedProfileItem: ProfileItem<FkeyOrId>) => {
      switch (selectedProfileItem?.type) {
        case 'child': {
          return children.filter((child) => child.fkey === selectedProfileItem.fkey)
        }
        case 'group': {
          const group = childGroups.find((group) => group.id === selectedProfileItem.id)
          const childFkeys = group?.children.map((child) => child.fkey) || []
          return children.filter((child) => childFkeys.includes(child.fkey))
        }
        case 'room': {
          return children.filter((child) => child.room?.fkey === selectedProfileItem.fkey)
        }
        default: {
          return children
        }
      }
    }

    setValue((value) => ({
      ...value,
      addScoreChange,
      childGroups,
      children: initialChildren,
      childAgeRangeLabel: getChildAgesLabel(ALL_CHILD_AGE_RANGES, ALL_CHILD_AGE_RANGES),
      educators,
      filters,
      menus: {
        ...value.menus,
        frameworks: frameworkMenuOptions,
        dateRanges: dateRangeMenuOptions,
      },
      filterChildren,
      onChildAgeRangeChange,
    }))
    onFrameworkChange(defaultFramework)
    onDateRangeChange(defaultDateRange)
    onChildAgeRangeChange(defaultChildAgeRanges.map((range: ChildAgeRange) => range.value))
  }, [dependencyQuery.data])

  useEffect(() => {
    if (!frameworkQuery.data) return

    const framework = frameworkQuery.data.service.learningFramework as Playground.LearningFramework
    const ageGroupLookup = createLookup(framework.ageGroups!)
    const categoryLookup = createLookup(framework.categories!)
    const outcomeLookup = createLookup(framework.outcomes!)
    const rankLookup = createLookup(framework.ranks!)

    let depth = 1
    if (framework.outcomes!.find((x) => !!x.secondaryId)) depth = 2
    if (framework.outcomes!.find((x) => !!x.tertiaryId)) depth = 3

    const primaryCategoryIds = unique(framework.outcomes!.map((outcome) => outcome.primaryId))
    const secondaryCategoryIds = unique(framework.outcomes!.map((outcome) => outcome.primaryId))
    const tertiaryCategoryIds = unique(framework.outcomes!.map((outcome) => outcome.primaryId))

    const ageGroups = sortObjectsBy(framework.ageGroups || [], 'minMonths')
    const outcomes = sortObjectsBy(framework.outcomes!, 'name')
    const ranks = sortObjectsBy(framework.ranks || [], 'value')

    const getCategories = (ids: number[]) => {
      const categories = ids.map((id) => categoryLookup[id]).filter((x) => !!x)
      return sortObjectsBy(categories, 'name')
    }

    const onAgeGroupChange = (ageGroup: Nullable<Playground.LearningFrameworkAgeGroup>) => {
      localStorage.setItem(LOCAL_STORAGE_FILTERS.ageGroup, JSON.stringify(ageGroup))
      setValue((value) => ({ ...value, ageGroup }))
    }
    const savedAgeGroup = localStorage.getItem(LOCAL_STORAGE_FILTERS.ageGroup)
    const defaultAgeGroup = savedAgeGroup ? JSON.parse(savedAgeGroup) : null

    const ageGroupMenuOptions = ageGroups.map((ageGroup) => ({
      label: ageGroup.name,
      onClick: () => onAgeGroupChange(ageGroup),
    }))

    const groupRecordsByCategory = (
      records: Playground.LearningRecord[],
      categoryField: CategoryField = 'primaryId'
    ) => {
      return records.reduce((acc, record) => {
        const outcome = outcomeLookup[record.outcomeId]
        if (!outcome) return acc
        const categoryId = outcome[categoryField]
        if (!categoryId) return acc
        const existing = acc[categoryId] || []
        return { ...acc, [categoryId]: [...existing, record] }
      }, {} as Record<number, Playground.LearningRecord[]>)
    }

    setValue((value) => {
      const filterRecords = (
        records: Playground.LearningRecord[],
        selectedProfileItem: ProfileItem<FkeyOrId>
      ) => {
        // using getFkeyFromObject on selectedProfileItem because a selectedProfileItem might actually be an unmigrated
        // record if being loaded from localStorage
        switch (selectedProfileItem?.type) {
          case 'child': {
            return records.filter((record) => record.childFkey === getFkeyFromObject(selectedProfileItem))
          }
          case 'educator': {
            return records.filter((record) => {
              return (
                record.contributorType === 'educator' &&
                record.contributorFkey === getFkeyFromObject(selectedProfileItem)
              )
            })
          }
          case 'group': {
            const group = value.childGroups.find((group) => group.id === selectedProfileItem.id)
            const childFkeys = group?.children.map((child) => child.fkey) || []
            return records.filter((record) => childFkeys.includes(record.childFkey))
          }
          case 'primaryCategory': {
            return records.filter((record) => {
              const outcome = outcomeLookup[record.outcomeId]
              return outcome && outcome.primaryId === selectedProfileItem.id
            })
          }
          case 'room': {
            return records.filter((record) => record.roomFkey === getFkeyFromObject(selectedProfileItem))
          }
          default: {
            return records
          }
        }
      }
      onAgeGroupChange(defaultAgeGroup)
      return {
        ...value,
        ageGroup: null,
        ageGroups,
        categories: {
          primary: getCategories(primaryCategoryIds),
          secondary: getCategories(secondaryCategoryIds),
          tertiary: getCategories(tertiaryCategoryIds),
        },
        cohortsEnabled: framework.enableCohorts,
        framework,
        frameworkDepth: depth,
        menus: {
          ...value.menus,
          ageGroups: [
            { label: 'All Outcome Ages', onClick: () => onAgeGroupChange(null) },
            ...ageGroupMenuOptions,
          ],
        },
        outcomes,
        ranks,
        filterRecords,
        findAgeGroup: (ageGroupId: number) => ageGroupLookup[ageGroupId],
        findCategory: (categoryId: number) => categoryLookup[categoryId],
        findOutcome: (outcomeId: number) => outcomeLookup[outcomeId],
        findRank: (rankId: number) => rankLookup[rankId],
        groupRecordsByCategory,
      }
    })
  }, [frameworkQuery.data])

  return <LearningAnalysisContext.Provider value={value}>{children}</LearningAnalysisContext.Provider>
}

LearningAnalysisProvider.displayName = 'LearningAnalysisProvider'

export default LearningAnalysisProvider
