import React from 'react'

import { navigate } from '@reach/router'
import styled from 'styled-components'

import { isHomeWebview, isWebView } from '~/config'
import { bytesToKilobytes, bytesToMegabytes } from '~/utils/filesAndMedia'
import formatDate from '~/utils/longDateFormat'
import { StylingUnit } from '~/utils/StylingUnit'

import { PostAttachmentFileProps } from '../attachmentTypes'

import { AttachmentDocumentIconWithText } from './AttachmentDocumentIconWithText'

const attachmentBorderHeight = new StylingUnit(71, 'px')
const attachmentBorderWidth = new StylingUnit(270, 'px')
const attachmentBorderRadius = new StylingUnit(16, 'px')

const DocumentAttachmentContainer = styled.div`
  position: relative;
  display: flex;
  width: ${attachmentBorderWidth.unit()};
  height: ${attachmentBorderHeight.unit()};
  border-radius: ${attachmentBorderRadius.unit()};
  border: solid 1px #e2e2e2;
  overflow: hidden;
  cursor: pointer;
`

const IconContainer = styled.div`
  width: fit-contents;
  height: fit-contents;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 16px;
`

const TextContainer = styled.div`
  width: fit-contents;
  height: fit-contents;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 12px;
`

const TitleText = styled.p<{ darkMode: boolean }>`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 165px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  color: ${(props) => (props.darkMode ? ' white' : 'rgba(0, 0, 0, 0.8)')};
`

const MetaText = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: #838383;
`

const convertBytestoKbOrMb = (bytes: number): string => {
  if (bytes >= 1048576) {
    return `${bytesToMegabytes(bytes)} MB`
  }
  return `${bytesToKilobytes(bytes)} KB`
}

const formatMetaForText = (fileMetadata: Playground.Metadata): string => {
  const formattedSize = convertBytestoKbOrMb(fileMetadata?.filesize ? fileMetadata.filesize : 0)
  const formattedDate = fileMetadata?.date ? formatDate(new Date(fileMetadata.date)) : 'Unknown date'
  return `${formattedSize}. ${formattedDate}`
}

export const DocumentAttachmentPreview = ({ file: file }: PostAttachmentFileProps) => {
  const openAttachment = () => {
    if (isWebView) navigate('xplor://external/' + file.url)
    else {
      const newWindow = window.open(file.url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null // Prevents pop-up blocker warnings
    }
  }

  return (
    <DocumentAttachmentContainer onClick={openAttachment}>
      <IconContainer>
        <AttachmentDocumentIconWithText mimeType={file.mimeType} />
      </IconContainer>
      <TextContainer>
        <TitleText darkMode={isHomeWebview}>
          {file.metadata?.filename ? file.metadata.filename : 'Unknown title'}
        </TitleText>
        <MetaText>{formatMetaForText(file.metadata)}</MetaText>
      </TextContainer>
    </DocumentAttachmentContainer>
  )
}
