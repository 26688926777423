import * as R from 'ramda'

import * as factories from '../factories'
import * as legacySelectors from '../legacySelectors'

export default (node) => {
  const childId = R.path(['data', 'typeId'], node)
  const point = R.view(legacySelectors.layoutLens, node)
  const displayText = R.path(['data', 'fullName'], node)

  return factories.childFactory({
    data: { refId: childId, refData: displayText },
    properties: { point },
  })
}
