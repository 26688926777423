import React from 'react'

const AudioPlayButton = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="16" fill="#01A39D" />
    <path
      d="M14.0105 9.82974C13.0106 9.24153 11.75 9.96251 11.75 11.1226V20.8774C11.75 22.0375 13.0106 22.7585 14.0105 22.1703L22.3021 17.2929C23.288 16.7129 23.288 15.2871 22.3021 14.7071L14.0105 9.82974Z"
      fill="white"
    />
  </svg>
)

export default AudioPlayButton
