import React from 'react'

import styled from 'styled-components'
import {
  AlignItemsProps,
  AlignSelfProps,
  BackgroundProps,
  BorderColorProps,
  BorderRadiusProps,
  BordersProps,
  BoxShadowProps,
  ColorProps,
  FlexDirectionProps,
  FlexProps,
  FlexWrapProps,
  HeightProps,
  JustifyContentProps,
  LineHeightProps,
  MaxHeightProps,
  MaxWidthProps,
  MinHeightProps,
  MinWidthProps,
  OpacityProps,
  OverflowProps,
  PositionProps,
  SpaceProps,
  WidthProps,
  ZIndexProps,
  alignItems,
  alignSelf,
  background,
  borderColor,
  borderRadius,
  borders,
  boxShadow,
  color,
  flex,
  flexDirection,
  flexWrap,
  height,
  justifyContent,
  lineHeight,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  opacity,
  overflow,
  position,
  space,
  width,
  zIndex,
} from 'styled-system'

type BaseProps = AlignItemsProps &
  AlignSelfProps &
  BackgroundProps &
  BordersProps &
  BorderColorProps &
  BorderRadiusProps &
  BoxShadowProps &
  ColorProps &
  FlexProps &
  FlexDirectionProps &
  FlexWrapProps &
  HeightProps &
  JustifyContentProps &
  LineHeightProps &
  MaxHeightProps &
  MaxWidthProps &
  MinHeightProps &
  MinWidthProps &
  OpacityProps &
  OverflowProps &
  PositionProps &
  SpaceProps &
  WidthProps &
  ZIndexProps

export interface BoxProps extends BaseProps {
  border?: string | number
  cursor?: string
  flexGrow?: number
  flexShrink?: number
  flexBasis?: string
  outline?: string
  outlineColor?: string
  overflowY?: string
  overflowX?: string
  printOnly?: boolean
  screenOnly?: boolean
  gap?: string
}

const cursor = (props: BoxProps) => (props.cursor ? `cursor: ${props.cursor}}` : null)
const flexGrow = (props: BoxProps) => (props.flexGrow ? `flex-grow: ${props.flexGrow}` : null)
const flexShrink = (props: BoxProps) => (props.flexShrink ? `flex-shrink: ${props.flexShrink}` : null)
const flexBasis = (props: BoxProps) => (props.flexBasis ? `flex-basis: ${props.flexBasis}` : null)
const outline = (props: BoxProps) => (props.outline ? `outline: ${props.outline}` : null)
const overflowY = (props: BoxProps) => (props.overflowY ? `overflow-y: ${props.overflowY}` : null)
const overflowX = (props: BoxProps) => (props.overflowX ? `overflow-x: ${props.overflowX}` : null)
const printOnly = (props: BoxProps) => (props.printOnly ? '@media screen {display: none;}' : null)
const screenOnly = (props: BoxProps) => (props.screenOnly ? '@media print {display: none;}' : null)
const gap = (props: BoxProps) => (props.gap ? `gap: ${props.gap}` : null)

const Box = styled.div<BoxProps>`
  ${flexGrow};
  ${flexShrink};
  ${flexBasis};
  ${gap};
  ${overflowY};
  ${overflowX};
  ${screenOnly};
  ${printOnly};

  ${alignItems};
  ${alignSelf};
  ${background};
  ${borders};
  ${borderColor};
  ${borderRadius};
  ${boxShadow};
  ${color};
  ${cursor};
  ${flex};
  ${flexDirection};
  ${flexWrap};
  ${height};
  ${justifyContent};
  ${lineHeight};
  ${maxHeight};
  ${maxWidth};
  ${minHeight};
  ${minWidth};
  ${opacity};
  ${outline};
  outline-color: ${(props) =>
    props.outlineColor ? props.theme.colors[props.outlineColor] : props.theme.colors.surfacePrimaryBorder};
  ${overflow};
  ${position};
  ${space};
  ${width};
  ${zIndex};
`

Box.displayName = 'Box'

export default React.memo(Box)
