import ReactGA from 'react-ga4'

//Playground Web
export async function createNewDocument() {
  ReactGA.event({
    category: 'Documentation',
    action: 'Create',
    label: 'Create Blank Document',
  })
}

//Playground Web
export async function saveDocAsDraft() {
  ReactGA.event({
    category: 'Documentation',
    action: 'Save',
    label: 'Save Draft Document',
  })
}

//Playground Web
export async function publishDocToParent() {
  ReactGA.event({
    category: 'Documentation',
    action: 'Publish',
    label: 'Publish Document to Parents',
  })
}

//Playground Web
export async function editDocKebabMenu() {
  ReactGA.event({
    category: 'Documentation',
    action: 'Edit',
    label: 'Edit Document',
  })
}

//Playground Web
export async function archiveDocKebabMenu() {
  ReactGA.event({
    category: 'Documentation',
    action: 'Archive',
    label: 'Archive Document',
  })
}

//Playground Web
export async function documentTagAllBulkAction() {
  ReactGA.event({
    category: 'Documentation',
    action: 'Bulk Action',
    label: 'Tag All',
  })
}

//Playground Web
export async function documentUntagAllBulkAction() {
  ReactGA.event({
    category: 'Documentation',
    action: 'Bulk Action',
    label: 'Untag All',
  })
}

//Playground Web
export async function documentTagBookedBulkAction() {
  ReactGA.event({
    category: 'Documentation',
    action: 'Bulk Action',
    label: 'Tag Booked',
  })
}

//Playground Web
export async function duplicateDocument() {
  ReactGA.event({
    category: 'Documentation',
    action: 'Duplicate',
    label: 'Duplicate Document',
  })
}

//Home Web
export async function openDocument() {
  ReactGA.event({
    category: 'Documentation',
    action: 'Learning Click',
    label: 'Doc',
  })
}

//Home Web
export async function documentAddComment() {
  ReactGA.event({
    category: 'Documentation',
    action: 'Learning Comment',
    label: 'Doc',
  })
}
