import React from 'react'

import Icon from './Icon'
import TextField, { TextFieldProps } from './TextField'

interface SearchFieldProps extends TextFieldProps {
  borderRadius?: string | number
}

const SearchField = React.forwardRef(({ borderRadius, ...props }: SearchFieldProps, ref) => (
  <TextField
    type="search"
    borderRadius={borderRadius || 100}
    overflow="hidden"
    outlined={false}
    autoComplete="off"
    icon={<Icon name="search" />}
    ref={ref}
    {...props}
  />
))

SearchField.displayName = 'SearchField'

export default React.memo(SearchField)
