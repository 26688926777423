import { useContext } from 'react'

import { UserContext } from '~/contexts/User'
import { persistLocally } from '~/utils'

function usePersistLocallyWithUser(type: string) {
  const { state: user } = useContext(UserContext)

  const context = user?.fkey

  return persistLocally(type, context)
}

export default usePersistLocallyWithUser
