import React from 'react'

const Duplicate = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.25 23.75C20.5637 23.75 23.25 21.0637 23.25 17.75C23.25 14.4363 20.5637 11.75 17.25 11.75C13.9363 11.75 11.25 14.4363 11.25 17.75C11.25 21.0637 13.9363 23.75 17.25 23.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.25 14.75V20.75"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.25 17.75H20.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.75 7.25H14.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.75 11.75H9.75"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.75 16.25H7.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 20.75H2.25C1.42157 20.75 0.75 20.0784 0.75 19.25V2.75C0.75 1.92157 1.42157 1.25 2.25 1.25H12.879C13.2765 1.25008 13.6578 1.40798 13.939 1.689L16.811 4.561C17.092 4.8422 17.2499 5.22345 17.25 5.621V8"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Duplicate
