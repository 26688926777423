import React, { useState } from 'react'

import { SecondaryButton, MainButton } from 'stardust'

interface SaveButtonsProps {
  validateChildren?: boolean
  statusToSave: string
  onClose: () => void
  setIsPreviewOpen: (isOpen: boolean) => void
  onSubmit: (statusToSave: string, setLoadingFalse: () => void) => void
}

const SaveButtons = ({
  validateChildren,
  statusToSave,
  setIsPreviewOpen,
  onClose,
  onSubmit,
}: SaveButtonsProps) => {
  const [saving, setSaving] = useState(false)

  const handleOnSaveClick = () => {
    setSaving(true)
    onSubmit(statusToSave, () => setSaving(false))
  }

  const handlePreview = () => {
    setIsPreviewOpen(true)
    onClose()
  }

  return (
    <>
      {validateChildren || statusToSave === 'DRAFT' ? (
        <>
          <SecondaryButton mt={1} outline onClick={onClose} disabled={saving}>
            Cancel
          </SecondaryButton>
          <SecondaryButton mt={1} outline disabled={saving} onClick={handlePreview}>
            Preview
          </SecondaryButton>
          <MainButton mt={1} onClick={handleOnSaveClick} disabled={saving}>
            {saving ? 'Saving' : 'Save'}
          </MainButton>
        </>
      ) : (
        <MainButton mt={1} onClick={onClose}>
          Go back
        </MainButton>
      )}
    </>
  )
}

export default SaveButtons
