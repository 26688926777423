import React, { useCallback } from 'react'

import { Checkbox as LibCheckbox } from '@rmwc/checkbox'

import styled from 'styled-components'

interface ContainerProps {
  indeterminate?: boolean
  CheckBoxBgColor?: string
}

interface Props extends ContainerProps {
  checked: boolean
  label?: string
  onChange(checked: boolean): void
}

const Container = styled.div<ContainerProps>`
  .mdc-checkbox {
    padding: 0;
  }

  .mdc-checkbox .mdc-checkbox__background {
    left: 0;
    top: 0;
  }

  .mdc-checkbox__native-control[aria-checked='mixed'] ~ .mdc-checkbox__background {
    background-color: ${(props) => props.CheckBoxBgColor};
    border-color: ${(props) => props.CheckBoxBgColor};
  }
`

const Checkbox = ({ checked, label, indeterminate, CheckBoxBgColor, onChange }: Props) => {
  const onToggle = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => onChange(!!event.currentTarget.checked),
    [onChange]
  )
  return (
    <Container indeterminate={indeterminate} CheckBoxBgColor={CheckBoxBgColor}>
      <LibCheckbox
        onClick={(event) => event.stopPropagation()}
        label={label}
        checked={checked}
        indeterminate={indeterminate}
        onChange={onToggle}
      />
    </Container>
  )
}

Checkbox.displayName = 'Checkbox'

export default React.memo(Checkbox)
