import { Value } from 'slate'

import defaultValue from './defaultValue.json'
import jsonSerializer from './serializers/json'

export * from './serializers'
export const initialValue = Value.fromJSON(defaultValue)

export const getEditorValue = (content) => {
  if (!content || !content.data) {
    return initialValue
  }

  const { data, ...rest } = content
  const value = jsonSerializer.deserialize(data)

  return Value.fromJSON({
    document: value.document,
    data: rest,
  })
}
