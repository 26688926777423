import React, { useRef, useState } from 'react'

import { Box, Flex, Text, Icon } from 'stardust'
import styled from 'styled-components'

import colors from '~/ui-components/tokens/colors'

import CommentsDateAndTime from '../../Comments/CommentsDateAndTime'

import ReflectionPopup from './ReflectionPopup'

interface Props {
  postId: Nullable<number>
  reflections?: Playground.Reflection[]
}

const S = {
  ReflectionBox: styled(Box)`
    border-bottom: 1px solid ${colors.cosmicShade14};
    &:last-child {
      border-bottom: none;
    }
  `,
  Content: styled(Text)`
    margin-top: 8px;
    align-self: stretch;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
  `,
}

const ReflectionList = ({ postId, reflections }: Props) => {
  const anchorRefs = useRef<HTMLDivElement[]>([])
  const [selectedReflection, setSelectedReflection] = useState<
    { reflection: Playground.Reflection; index: number } | undefined
  >(undefined)

  return (
    <>
      <Box maxHeight="464px" overflowY="auto" pt={2}>
        {reflections?.map((reflection, index) => {
          const totalReplies = reflection.replies?.length
          return (
            <S.ReflectionBox
              key={reflection.id}
              p={3}
              ref={(element: HTMLDivElement) => (anchorRefs.current[index] = element)}
              onClick={() => setSelectedReflection({ reflection, index })}>
              <Box>
                <Flex justifyContent="space-between" alignItems="center">
                  <Text fontSize="16px" fontWeight={700} lineHeight="24px">
                    {reflection.author.name}
                  </Text>
                  <Text fontSize="16px" fontWeight={500} lineHeight="24px" color={colors.cosmicShade11}>
                    <CommentsDateAndTime updatedAt={reflection.insertedAt} />
                  </Text>
                </Flex>
                <S.Content>{reflection.content}</S.Content>
                {totalReplies ? <Icon name="comments" fill="textPrimaryMedium" mt="8px" /> : null}
              </Box>
            </S.ReflectionBox>
          )
        })}
      </Box>
      {selectedReflection ? (
        <ReflectionPopup
          postId={postId}
          reflection={selectedReflection.reflection}
          anchorRef={anchorRefs.current[selectedReflection.index]}
          closePopup={() => setSelectedReflection(undefined)}
        />
      ) : null}
    </>
  )
}

ReflectionList.displayName = 'ReflectionList'

export default React.memo(ReflectionList)
