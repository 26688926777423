import React from 'react'

import styled from 'styled-components'

interface MediaAttachmentPlayProps {
  fill?: string
}

const PlaySvg = styled.svg<{ fill?: string }>`
  fill: ${(props) => props.fill || 'white'};
  width: 20px;
  height: 22px;
`

const MediaAttachmentPlay = (props: MediaAttachmentPlayProps | null) => (
  <PlaySvg>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.338 2.3116V19.8016C0.337952 20.3291 0.615037 20.8179 1.06771 21.0888C1.52039 21.3598 2.0821 21.3729 2.547 21.1236L18.87 12.3786C19.3572 12.1174 19.6613 11.6094 19.6613 11.0566C19.6613 10.5038 19.3572 9.99579 18.87 9.7346L2.547 0.989596C2.0821 0.740241 1.52039 0.753436 1.06771 1.02435C0.615037 1.29525 0.337952 1.78405 0.338 2.3116Z"
    />
    <path d="M17.9611 9.32011L2.99228 0.766469C1.65896 0.00457555 0 0.96731 0 2.50296V19.6102C0 21.1459 1.65897 22.1086 2.99228 21.3467L17.9611 12.7931C19.3048 12.0253 19.3048 10.0879 17.9611 9.32011Z" />
  </PlaySvg>
)

export default MediaAttachmentPlay
