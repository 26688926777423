import { useUserOrigin } from '~/contexts/User'

const useUserNotFromUnpermittedOrigin = (unPermittedOrigins: Playground.UserOrigin[] = []): boolean => {
  const userOrigin = useUserOrigin()

  if (!userOrigin) return false
  return !unPermittedOrigins.includes(userOrigin)
}

export default useUserNotFromUnpermittedOrigin
