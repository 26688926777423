import React, { DragEvent } from 'react'

import RPath from 'ramda/src/path'
import { Block, Change } from 'slate'
import { getEventRange } from 'slate-react'

import PlanPreviewCard from '~/components/PlanPreviewCard'

import { Next, OnDropProps, RenderNodeProps, SerializeProps } from '../types'

export default {
  onDrop: (e: DragEvent, { editor }: OnDropProps, next: Next) => {
    const droppedData = e.dataTransfer.getData('data')

    if (!droppedData || JSON.parse(droppedData).type !== 'plan') {
      return next()
    }

    const range = getEventRange(e, editor)
    if (!range) return next()

    return editor.change((change: Change) =>
      change.insertBlockAtRange(
        range,
        Block.create({
          type: 'plan',
          data: JSON.parse(droppedData),
        })
      )
    )
  },

  renderNode: ({ node }: RenderNodeProps, next: Next) => {
    if (node.type !== 'plan') return next()

    const data = node.data.toJS()
    const planId = RPath<number>(['data', 'typeId'], data)
    if (!planId) return next()

    return <PlanPreviewCard id={planId} />
  },

  serialize: (obj: SerializeProps) => {
    if (obj.object === 'block' && obj.type === 'plan') {
      return <p>Plan needs more finnese</p>
    }
  },
}
