import React from 'react'

const Frameworks = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.24898 24.25H3.99998C2.75773 24.2494 1.75098 23.2423 1.75098 22"
      stroke="#00776B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.751 10V2.5C19.751 2.30091 19.6718 2.11 19.531 1.96932C19.3901 1.82864 19.1991 1.74974 19 1.75H4.75098C3.09412 1.75 1.75098 3.09315 1.75098 4.75V22C1.75098 20.7577 2.75773 19.7506 3.99998 19.75H9.99998"
      stroke="#00776B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.629 15.8704L16 23.5004L12.25 24.2504L13 20.5004L20.63 12.8704C21.0265 12.4732 21.5648 12.25 22.126 12.25C22.6872 12.25 23.2255 12.4732 23.622 12.8704L23.63 12.8784C24.4558 13.7049 24.4553 15.0443 23.629 15.8704Z"
      stroke="#00776B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Frameworks
