import React, { forwardRef } from 'react'

import styled from 'styled-components'
import { height, width } from 'styled-system'

import { transformToCssString } from './utils'

const transform = ({ transform }) => (transform ? { transform: transformToCssString(transform) } : null)

const Transform = styled.div`
  transform-origin: 0 0;
  z-index: 0;
  ${height};
  ${width};
  ${transform};
`
const TransformLayer = forwardRef(({ children, transform }, ref) => (
  <Transform ref={ref} transform={transform}>
    {children}
  </Transform>
))

TransformLayer.defaultProps = {
  transform: {
    x: 0,
    y: 0,
    k: 1,
  },
}

TransformLayer.displayName = 'TransformLayer'
export default TransformLayer
