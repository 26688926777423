import React from 'react'

const AudioPauseButton = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="16" fill="#01A39D" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.875 9.25244H14.125C14.5375 9.25244 14.875 9.58994 14.875 10.0024V22.0025C14.875 22.415 14.5375 22.7525 14.125 22.7525H11.875C11.4625 22.7525 11.125 22.415 11.125 22.0025V10.0024C11.125 9.58994 11.4625 9.25244 11.875 9.25244ZM17.875 9.25244H20.125C20.5375 9.25244 20.875 9.58994 20.875 10.0024V22.0025C20.875 22.415 20.5375 22.7525 20.125 22.7525H17.875C17.4625 22.7525 17.125 22.415 17.125 22.0025V10.0024C17.125 9.58994 17.4625 9.25244 17.875 9.25244Z"
      fill="#F6F6F6"
    />
  </svg>
)

export default AudioPauseButton
