/* eslint react/display-name: 0 */
import React from 'react'

import * as R from 'ramda'
import { Block, Change, Editor, Value } from 'slate'
import { RenderBlockProps, getEventRange } from 'slate-react'

import { Flex, Icon, Text } from 'stardust'

import { toFkey } from '~/utils'

import { Next, OnDropProps, Query, ValueData } from '../../types'

import { OUTCOME_NODE_TYPE } from './types'

type DragEvent = React.DragEvent<HTMLElement>

// hydrating entity with extra props from matching resource in request
const hydrateOutcome = (
  { learningOutcomes = [] }: ValueData,
  outcome: Playground.LearningFrameworkOutcome
) => {
  return learningOutcomes.find((lo) => lo.id === outcome.id) || outcome
}

/*
  Data coming in is Playground.LearningOutcome. We are saving a typeFkey as we want all article tagged entities to use typeFkeys
*/
const getOutcomeData = (value: Value) => {
  const allOutcomeNodes = value.document
    .filterDescendants((node) => 'type' in node && node.type === OUTCOME_NODE_TYPE)
    .toJS()
  const outcomeNodeData = allOutcomeNodes.map(({ data }: { data: Playground.LearningFrameworkOutcome }) => {
    return {
      type: 'learning_outcome',
      typeFkey: toFkey(data.id),
      displayText: data.name,
    }
  })

  return R.uniq(outcomeNodeData)
}

export default {
  serialize: (node: any) => {
    if (node.type === OUTCOME_NODE_TYPE) {
      const outcome = node.data.toJS()
      return (
        <Flex alignItems="flex-start" bg="surfaceSecondary" p={2}>
          <Icon name="outcome" fill="textPrimaryMedium" mr={2} />
          <Text>{outcome.name}</Text>
        </Flex>
      )
    }
  },

  onQuery: ({ type }: Query, { value }: Editor, next: Next) => {
    if (type === 'learning-outcomes') {
      return getOutcomeData(value)
    }

    return next()
  },

  onDrop: (e: DragEvent, { editor }: OnDropProps, next: Next) => {
    const droppedData = e.dataTransfer?.getData('data')

    if (!droppedData) {
      return
    }

    const data = JSON.parse(droppedData)

    if (data.type !== OUTCOME_NODE_TYPE) {
      return next()
    }

    const eventRange = getEventRange(e, editor)
    if (!eventRange) return next()

    return editor.change((change: Change) => {
      return change.insertBlockAtRange(eventRange, Block.create(data))
    })
  },

  renderNode: ({ attributes, children, node, editor }: RenderBlockProps, next: Next) => {
    if (node.type !== OUTCOME_NODE_TYPE) {
      return next()
    }

    const valueData = R.pipe(
      R.path(['value', 'data']),
      R.ifElse(R.isNil, R.always({}), (x) => x.toJS())
    )(editor)

    const outcome = hydrateOutcome(valueData, node.data.toJS())

    return (
      <Flex alignItems="flex-start" {...attributes} bg="surfaceSecondary" p={2}>
        <Icon name="outcome" fill="textPrimaryMedium" mr={2} />
        <Text>{outcome.name}</Text>
        {children}
      </Flex>
    )
  },
}
