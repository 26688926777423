import React from 'react'

import { QueryResult } from '@apollo/client/react'
import { Query } from '@apollo/client/react/components'

import { Box, File, Flex, Icon, Image, Text, Video } from 'stardust'
import { BoxProps } from 'stardust/Box'
import { Theme } from 'stardust/theme/types'
import styled from 'styled-components'

import EmptyImage from '~/components/Loading/EmptyImage'
import { isHome, isWebView } from '~/config'
import { useSelectedServiceFkey } from '~/contexts/Service'
import { determineFileType } from '~/modules/media'

import { GET_MEDIA_ITEM } from './queries'

interface DisplayMediaProps extends BoxProps {
  id?: number
  media?: Playground.MediaItem
}

interface QueryData {
  context: {
    mediaItem: Playground.MediaItem
  }
}

interface VideoPlayerProps extends BoxProps {
  coverUrl: string
  url: string
}

const S = {
  Box: styled(Box)`
    border-radius: ${(props) => (props.theme.name === Theme.webview ? props.theme.radii[1] : '0')};
    overflow: hidden;
    border-radius: ${(props) => (isWebView ? props.theme.radii[1] : '0')};
    overflow: hidden;
  `,
  Image: styled(Image)`
    border-radius: ${(props) => (isWebView ? props.theme.radii[1] : '0')};
    overflow: hidden;
  `,
  VideoImage: styled(Image)`
    display: none;
    @media print {
      display: block !important;
    }
  `,
  Video: styled(Video)`
    @media print {
      display: none !important;
    }
  `,
}

const VideoPlayer = ({ coverUrl, url, ...attrs }: VideoPlayerProps) => {
  return (
    <S.Box {...attrs}>
      <S.Video
        autoPlay={false}
        maxWidth="100%"
        preload="metadata"
        src={url}
        controlsList={isWebView ? 'nodownload' : ''}
      />
      <S.VideoImage src={coverUrl} maxWidth="100%" height="auto" />
    </S.Box>
  )
}

const Media = ({ mimeType, url, metadata, coverUrl, ...attrs }: Playground.MediaItem) => {
  const type = determineFileType(mimeType)
  if (type === 'image') return <S.Image alt="" src={url} {...attrs} />
  if (type === 'video') return <VideoPlayer coverUrl={coverUrl} url={url} {...attrs} />
  const src: string = metadata?.filename || url
  return <File src={src} url={url} />
}

const DisplayMedia = ({ id, media, ...attrs }: DisplayMediaProps) => {
  const serviceFkey = useSelectedServiceFkey()
  if (media) {
    return <Media {...media} {...attrs} />
  }

  if (id && serviceFkey) {
    return (
      <Query variables={{ id: Number(id), serviceFkey }} query={GET_MEDIA_ITEM}>
        {({ data, error, loading }: QueryResult<QueryData>) => {
          if (loading) {
            return <EmptyImage />
          }

          if (error) {
            return isHome ? null : (
              <Flex
                alignItems="center"
                bg="negativeLight"
                border="1px solid"
                borderColor="negative"
                borderRadius={1}
                p={3}>
                <Icon color="negative" name="alert" size={16} />
                <Text.span color="negative" fontSize={0} lineHeight={1.3} ml={2}>
                  {"This media has been deleted. Parents won't see this warning."}
                </Text.span>
              </Flex>
            )
          }

          const mediaData = data!.context.mediaItem
          return <Media {...attrs} {...mediaData} />
        }}
      </Query>
    )
  }

  return null
}

DisplayMedia.displayName = 'DisplayMedia'

export default React.memo(DisplayMedia)
