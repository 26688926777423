import React, { useContext } from 'react'

import { useQuery } from '@apollo/client'
import { useMatch } from '@reach/router'
import gql from 'graphql-tag'

import { Box, Flex } from 'stardust'

import Splash from '~/components/Loading/Splash'
import ServiceSelectPrompt from '~/components/ServiceSelectPrompt'
import { isPlayground, isWebView } from '~/config'
import { useSelectedServiceFkey } from '~/contexts/Service'
import { UserContext } from '~/contexts/User'

import useAddAskNicelySurvey from '~/hooks/useAddAskNicelySurvey'
import * as perms from '~/modules/permissions'

import { ROOT_SIDEBAR_WIDTH } from '~/theme'

import Header from './components/Header'
import Logo from './components/Logo'
import Navigation from './components/Navigation'
import Sidebar from './components/Sidebar'
import Router from './Router'

const GET_FRAMEWORKS = gql`
  query LearningFrameworks($serviceFkey: String!) {
    service(fkey: $serviceFkey) {
      fkey
      learningFrameworks {
        id
      }
    }
  }
`

const Root = () => {
  useAddAskNicelySurvey()
  const { state: user } = useContext(UserContext)
  const serviceFkey = useSelectedServiceFkey()
  const isPostViewPage = !isNaN(Number(useMatch('/observations/:id/*')?.id))
  const hideNavbar = Boolean(isPostViewPage) // Include all the pages where we need to hide navbar. For eg: (page1 || page2 ...)

  const hasReports = isPlayground.toString() as 'true' | 'false'
  const isEducator = perms.isEducator(user!)
  const isSuperAdmin = perms.isSuperAdmin(user!)
  const isGroupAdmin = perms.isGroupAdmin(user!)
  const isProviderAdmin = perms.isProviderAdmin(user!)

  const { data, loading, error } = useQuery(GET_FRAMEWORKS, {
    skip: !serviceFkey || !isPlayground,
    variables: { serviceFkey },
  })

  if (loading) return <Splash />
  if (error) throw error

  const hasFrameworks = data?.service.learningFrameworks.length > 0

  if (isWebView)
    return (
      <Box bg={isPlayground ? 'surfaceSecondary' : 'surfacePrimary'}>
        <Router hasFrameworks={hasFrameworks} hasReports={hasReports} />
      </Box>
    )

  return (
    <Flex>
      {!hideNavbar && (
        <Sidebar screenOnly>
          <Logo />
          <Navigation hasFrameworks={hasFrameworks} hasReports={hasReports} />
        </Sidebar>
      )}

      <Flex
        maxWidth={hideNavbar ? '100%' : `calc(100vw - ${ROOT_SIDEBAR_WIDTH})`}
        bg="surfaceSecondary"
        flexDirection="column"
        flex="1 1 auto">
        <Header showLogo={hideNavbar} />
        <Router hasFrameworks={hasFrameworks} hasReports={hasReports} />
      </Flex>

      <ServiceSelectPrompt enableSearch={isSuperAdmin || isGroupAdmin || isProviderAdmin || isEducator} />
    </Flex>
  )
}

Root.displayName = 'Root'

export default Root
