import React from 'react'

import Box from './Box'
import Flex from './Flex'
import Line from './StencilLine'

export const StencilCard = () => (
  <Box border={0} borderColor="rgb(245, 245, 245)" borderRadius="6px" m={3} width="220px">
    <Flex flexDirection="column" height="282px" justifyContent="space-between" width={1}>
      <Line m={2} py={112} borderRadius="6px" />
      <Flex justifyContent="space-between" alignItems="center">
        <Box width={1}>
          <Line width={2 / 5} m={2} />
          <Line width={3 / 5} m={2} />
        </Box>
        <Flex width={1 / 5} justifyContent="center">
          <Line width={1 / 4} />
        </Flex>
      </Flex>
    </Flex>
  </Box>
)

StencilCard.displayName = 'StencilCard'

export default React.memo(StencilCard)
