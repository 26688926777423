import React, { ReactNode } from 'react'

import { Box, Flex, FlexItem, Icon, MainButton, OutlineButton, Text } from 'stardust'
import styled from 'styled-components'

import { LAYERS } from '~/theme'

type ChildrenFunc = ({ onClose }: { onClose: () => void }) => ReactNode

interface Props {
  children: ReactNode | ChildrenFunc
  isOpen?: boolean
  cancelText?: string
  confirmText?: string
  headerText?: string
  fullViewPort?: boolean
  onCancel?(): void
  onClose?(): void
  onConfirm?(): void
}

interface HeaderProps {
  heading?: string
  onClose(): void
}

const Container = styled(({ fullViewPort, children, ...props }) => <Box {...props}>{children}</Box>)`
  align-items: self-start;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  height: ${(props) => (props.fullViewPort ? '100vh' : '100%')};
  justify-content: center;
  padding-top: 10vh;
  position: ${(props) => (props.fullViewPort ? 'fixed' : 'absolute')};
  top: ${(props) => (props.isOpen ? '0' : '-9999px')};
  left: ${(props) => (props.isOpen ? '0' : '-9999px')};
  transition: top 0s linear 0.3s;
  width: ${(props) => (props.fullViewPort ? '100vw' : '100%')};
  z-index: ${LAYERS.Modal};
`

const Modal = styled(Box)`
  position: relative;
  box-shadow: ${(props) => props.theme.shadows['1dp']};
`

const Actions = styled(Box)`
  text-align: right;
`

const Header = ({ heading, onClose }: HeaderProps) => (
  <Flex flexDirection={'row'} justifyContent={'flex-end'} mb={4}>
    {heading && (
      <FlexItem flex={1}>
        <Text fontSize={4}>{heading}</Text>
      </FlexItem>
    )}
    <FlexItem>
      <Icon name="close" onClick={onClose} fill="textPrimaryMedium" />
    </FlexItem>
  </Flex>
)

const ConfirmModal = ({
  children,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  headerText,
  isOpen,
  fullViewPort = true,
  onCancel,
  onClose = () => {},
  onConfirm,
}: Props) => (
  <Container isOpen={isOpen} onClick={(event: any) => onClose()} fullViewPort={fullViewPort}>
    <Modal
      onClick={(event: any) => event.stopPropagation()}
      bg="white"
      p={3}
      borderRadius={8}
      width="400px"
      opacity={isOpen ? 1 : 0}
      transition="opacity 0.3s"
      zIndex={106}>
      {onClose && <Header heading={headerText} onClose={() => onClose()} />}
      {children}
      <Actions mt={5}>
        <OutlineButton width="150px" onClick={onCancel} mr={2}>
          {cancelText}
        </OutlineButton>
        <MainButton width="150px" onClick={onConfirm}>
          {confirmText}
        </MainButton>
      </Actions>
    </Modal>
  </Container>
)

ConfirmModal.displayName = 'ConfirmModal'

export default React.memo(ConfirmModal)
