import React, { ReactNode } from 'react'

import { useQuery } from '@apollo/client'

import { Box, Divider, Flex, Text } from 'stardust'
import styled from 'styled-components'

import Error404 from '~/components/404'
import DocumentModal from '~/components/DocumentModal'
import MediaPlayer from '~/components/MediaPlayer'

import { useSelectedServiceFkey } from '~/contexts/Service'
import { is404 } from '~/modules/api'
import { humanizeFullAsset } from '~/modules/learningContent/humanizeAsset'

import { GET_LEARNING_CONTENT_ASSET } from '../../queries'

import ArticleModalPlaceholder from './ArticleModalPlaceholder'

const BoxFloatRight = styled(Box)`
  float: right;
`

const ArticleText = styled(Box)`
  padding: 3;
  padding-left: 0;
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
  li {
    margin-left: 20px;
    list-style-type: circle;
  }
`
const ThumbnailImage = ({ children }: { children: ReactNode }) => (
  <BoxFloatRight pl={10} pb={10} width={2 / 5}>
    {children}
  </BoxFloatRight>
)

interface Props {
  id: number
  changeId(newId: number): void
}

export const NO_ASSET_SELECTED = 0

const AssetViewerModal = ({ id, changeId }: Props) => {
  const serviceFkey = useSelectedServiceFkey()
  const { data, error, loading } = useQuery(GET_LEARNING_CONTENT_ASSET, {
    variables: { id, serviceFkey },
    skip: id === NO_ASSET_SELECTED,
  })
  const handleHideModal = () => changeId(NO_ASSET_SELECTED)

  if (!id) {
    return null
  }

  if (error) {
    if (is404(error)) {
      return <Error404 message="Content not found" />
    }

    throw error
  }

  const rawAsset = data?.context?.learningContentAsset as Playground.LearningContentAssetFull | undefined

  if (loading || !rawAsset) {
    return <ArticleModalPlaceholder onClose={handleHideModal} />
  }

  const asset = humanizeFullAsset(rawAsset)

  const articleView = asset.isArticle && (
    <Flex flexDirection={['column', 'row']} width={1}>
      <Flex
        borderColor="surfacePrimaryBorder"
        borderLeft="1px solid"
        flexDirection="column"
        pl={3}
        pr={3}
        flexGrow={1}
        width={undefined}>
        <Box p={3} pl={0}>
          <Text fontSize={0} medium mb={1} upper data-test="document-overline">
            {asset.type}
          </Text>
          <Text fontSize={3} bold data-test="document-heading">
            {asset.title}
          </Text>
        </Box>

        <Divider borderColor="surfacePrimaryBorder" m={0} width={1} />

        <Flex flexDirection="column" flexGrow={1} overflowY="auto" width={1}>
          <div>
            <Box
              borderBottom={1}
              borderColor="surfacePrimaryBorder"
              pr={3}
              py={3}
              data-test="document-caption">
              <Text medium>
                Category: {asset.firstCategory}, Length: {asset.humanizedDocumentLength}
              </Text>
            </Box>
            <ThumbnailImage>
              <MediaPlayer title={asset.title} url={asset.thumbnailUrl} mimeType={asset.thumbnailMimeType} />
            </ThumbnailImage>
            <ArticleText dangerouslySetInnerHTML={{ __html: asset.documentHtml }}></ArticleText>
          </div>
        </Flex>
      </Flex>
    </Flex>
  )

  const videoView = (asset.isVideo || asset.isPodcast) && (
    <MediaPlayer
      title={asset.title}
      coverUrl={asset.thumbnailUrl}
      url={asset.documentUrl}
      mimeType={asset.documentMimeType}
    />
  )

  return (
    <DocumentModal open={!!id} onClose={handleHideModal}>
      {articleView}
      {videoView}
    </DocumentModal>
  )
}

AssetViewerModal.displayName = 'AssetViewerModal'

export default AssetViewerModal
