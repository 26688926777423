import React from 'react'

import { Box, Container, Flex, FlexItem, Heading } from 'stardust'
import styled from 'styled-components'

import { LAYERS } from '~/theme'

const Sticky = styled(Box)`
  position: sticky;
  top: 164px;
  z-index: ${LAYERS.SectionHeader};
`

const ListGroup = ({ children, group, renderGroupHeaderColumn = () => {} }) => (
  <Flex flexDirection="column">
    <Sticky bg="surfaceSecondary" width={1}>
      <Container>
        <Flex alignItems="center" justifyContent="space-between">
          <FlexItem flex={1}>
            <Heading.h6 upper medium mt={0} mb={0}>
              {group}.
            </Heading.h6>
          </FlexItem>
          {renderGroupHeaderColumn && renderGroupHeaderColumn()}
        </Flex>
      </Container>
    </Sticky>
    <Box bg="surfacePrimary">{children}</Box>
  </Flex>
)

ListGroup.displayName = 'ListGroup'

export default ListGroup
