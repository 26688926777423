import React from 'react'

import { Box, Flex, Avatar, Text, Checkbox, Icon } from 'stardust'

import * as profiles from '~/components/Profiles/core'

import { CONSENTS } from '~/modules/children'

import colors from '~/ui-components/tokens/colors'

interface Props {
  child: Playground.SimpleChild
  border: string
  background: string
  handleChildClicked: (childFkey: string | undefined) => void
  isChecked: (fkey: string | undefined) => boolean
}

const ChildBox = ({ child, border, background, isChecked, handleChildClicked }: Props) => {
  return (
    <Box
      key={child.fkey}
      border={border}
      borderRadius="16px"
      px={2}
      py={2}
      mb={2}
      bg={background}
      onClick={() => handleChildClicked(child.fkey)}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        pr={2}
        pl={1}
        onClick={(e: any) => {
          e.stopPropagation()
          handleChildClicked(child.fkey)
        }}>
        <Flex alignItems="center">
          <Avatar
            src={child.image}
            alt={child.fullName}
            text={profiles.initials(child.fullName)}
            xsmall
            pl={1}
          />
          <Text
            ml={2}
            fontSize="14px"
            minWidth={150}
            maxWidth={!child.otherDetails?.consents?.includes(CONSENTS.activitiesAndExcursions) ? 150 : 250}>
            {child.fullName}
          </Text>
          {!child.otherDetails?.consents?.includes(CONSENTS.activitiesAndExcursions) && (
            <Flex
              ml="16px"
              pr="10px"
              pt="5px"
              pb="5px"
              pl="6px"
              alignItems="center"
              bg={colors.superGiantRed0}
              borderRadius="40px">
              <Icon color="" name="noPhotosAlt" mr={1} size={24} />
              <Text fontWeight={400} fontSize={2}>
                = No photo consent
              </Text>
            </Flex>
          )}
        </Flex>
        <Checkbox
          checked={isChecked(child.fkey) as boolean}
          onChange={() => handleChildClicked(child.fkey)}
        />
      </Flex>
    </Box>
  )
}

export default ChildBox
