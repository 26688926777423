import { format } from 'date-fns'

const to12hrTime = (inputTime: string): string => {
  const [hour, minute, second] = inputTime.split(':')

  const parsedTime = new Date()
  parsedTime.setHours(parseInt(hour, 10), parseInt(minute, 10), parseInt(second, 10))

  return format(parsedTime, 'h:mm a')
}
export default to12hrTime
