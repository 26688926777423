import * as R from 'ramda'

export default function plannerPosition(nodes) {
  let xdiff = 0
  let ydiff = 0
  let xmid = 0
  let ymid = 0

  const nodesLength = R.length(Object.keys(nodes))

  if (nodesLength > 0) {
    const nodesVals = Object.values(nodes)

    const xs = R.map((node) => node.properties.point.x, nodesVals)
    let xmax = 0
    R.forEach((node) => {
      const temp = node.properties.point.x + node.properties.dimensions.width
      if (temp > xmax) {
        xmax = temp
      }
    }, nodesVals)
    const x = R.reduce(
      R.minBy((n) => n),
      Infinity,
      xs
    )
    xmid = R.mean([xmax, x])

    const ys = R.map((node) => node.properties.point.y, nodesVals)
    let ymax = 0
    R.forEach((node) => {
      const temp = node.properties.point.y + node.properties.dimensions.height
      if (temp > ymax) {
        ymax = temp
      }
    }, nodesVals)
    const y = R.reduce(
      R.minBy((n) => n),
      Infinity,
      ys
    )
    ymid = R.mean([ymax, y])

    xdiff = xmax - x
    ydiff = ymax - y
  }

  return { xdiff, ydiff, xmid, ymid }
}
