import { MENTION_NODE_TYPE } from './types'

export default {
  inlines: {
    [MENTION_NODE_TYPE]: {
      // It's important that we mark the mentions as void nodes so that entities
      // can't edit the text of the mention.
      isVoid: true,
    },
  },
}
