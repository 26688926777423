import React, { useRef } from 'react'

import { useQuery } from '@apollo/client'

import * as R from 'ramda'

import { Box, Container, Empty, Flex, Heading, Text } from 'stardust'
import styled from 'styled-components'

import empty from '~/assets/images/empty.svg'

import Loading from '~/components/Loading/List'
import { HEADER_HEIGHT, LAYERS } from '~/theme'

const ListHeader = styled(Box)`
  align-items: center;
  background: ${(props) => props.theme.colors.surfacePrimary};
  display: flex;
  position: sticky;
  top: ${HEADER_HEIGHT};
  z-index: ${LAYERS.PageHeader};
`

const Error = () => (
  <div>
    <p>Error...</p>
  </div>
)

const ListView = (props) => {
  const headerRef = useRef()

  const { query, variables, heading, headerActions, path, render } = props
  const { data, loading, error } = useQuery(query, {
    variables,
  })

  const header = (
    <ListHeader boxShadow="1dp" height="100px" data-test="list-heading">
      <Container width={1}>
        <Flex alignItems="center" justifyContent="space-between">
          <Heading.h5 bold caps mb={0} mt={0} ref={headerRef} data-test="page-title">
            {heading}
          </Heading.h5>
          {headerActions && headerActions()}
        </Flex>
      </Container>
    </ListHeader>
  )

  if (error) {
    return <Error />
  }

  if (loading) {
    return (
      <Box data-test="profile-list">
        {header}
        <Loading />
      </Box>
    )
  }

  if (R.lt(R.length(R.path(path, data)), 0)) {
    return (
      <ListHeader boxShadow="1dp" height="72px">
        <Flex flexDirection="column" alignItems="center" justifyContent="center">
          {<Empty heading={heading} image={empty} />}
          <Text.p>{`There are no ${heading} in this service`}</Text.p>
        </Flex>
      </ListHeader>
    )
  }
  if (data) {
    return (
      <Box data-test="profile-list">
        {header}
        {render({ data })}
      </Box>
    )
  }
}

ListView.displayName = 'ListView'

export default React.memo(ListView)
