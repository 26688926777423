import React from 'react'

import { Box, StencilLine as Line, StencilCircle as Circle, FlexItem, Flex, Divider } from 'stardust'

import styled from 'styled-components'

import Modal from '~/components/Modal'

import colors from '~/ui-components/tokens/colors'

const Content = styled(Line)`
  height: ${(props) => props.height || '35vh'};
  padding: 16px;
  width: 100%;
  background-color: ${(props) => props.bg || colors.cosmicShade3};
`

const ChildrenLoading = () => (
  <Modal open dontRenderCloseButton onClose={() => {}}>
    <FlexItem
      height="80vh"
      width="52vw"
      alignitems="stretch"
      max-height="100%"
      p="16px"
      bg={colors.cosmicShade4}>
      <Flex alignItems="center" justifyContent="space-between" height="6vh" mb={3}>
        <Flex alignItems="center" flexDirection="row" width="100%">
          <Circle ml={2} width={45} height={45} />
          <Box width={200}>
            <Content ml={2} bg={colors.cosmicShade6} height={6} />
          </Box>
        </Flex>
        <Flex alignItems="center">
          <Circle mr={2} width={45} height={45} />
        </Flex>
      </Flex>
      <Flex flexDirection="column">
        <Box width={150} mx={1}>
          <Line width={120} mb={2} />
        </Box>
        <Flex alignItems="center" width="100%" mb={3}>
          <Flex mx={1} width="100%">
            <Content bg={colors.cosmicShade6} height={50} borderRadius={1} />
          </Flex>
          <Flex mx={1} width="100%">
            <Content bg={colors.cosmicShade6} height={50} borderRadius={1} />
          </Flex>
          <Flex mx={1} width="100%">
            <Content bg={colors.cosmicShade6} height={50} borderRadius={1} />
          </Flex>
        </Flex>
        <Box width="100%">
          <Flex flexDirection="column">
            <Flex height="5vh" mb={2} bg={colors.cosmicShade3} width="100%" borderRadius="16px" />

            <Flex alignItems="center" justifyContent="space-between" height="6vh" mb={2}>
              <Box width={200}>
                <Content ml={2} bg={colors.cosmicShade6} height={6} />
              </Box>
              <Flex alignItems="center">
                <Box width={100} mx={1}>
                  <Content bg={colors.cosmicShade6} height={48} borderRadius={1} />
                </Box>
                <Box width={100} mx={1}>
                  <Content bg={colors.cosmicShade6} height={48} borderRadius={1} />
                </Box>
                <Box width={100} mx={1}>
                  <Content bg={colors.cosmicShade6} height={48} borderRadius={1} />
                </Box>
              </Flex>
            </Flex>

            <Content bg={colors.cosmicShade3} height="45vh" borderRadius="16px" />
          </Flex>
        </Box>
      </Flex>
    </FlexItem>
    <Divider borderColor="lightGrey" mb={1} />
    <Flex flexDirection="column" alignItems="center" width="100%" mt={1} mb={1}>
      <Flex justifyContent="right" width="100%">
        <Line width={84} mb={2} mt={1} py={1} mr={4} />
      </Flex>
      <Flex width="100%" mb={1}>
        <Flex mx={2} width="100%">
          <Content bg={colors.cosmicShade4} height={50} borderRadius={1} />
        </Flex>
        <Flex mx={2} width="100%">
          <Content bg={colors.cosmicShade4} height={50} borderRadius={1} />
        </Flex>
      </Flex>
    </Flex>
  </Modal>
)

ChildrenLoading.displayName = 'ChildrenLoading'

export default ChildrenLoading
