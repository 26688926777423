import React, { useContext } from 'react'

import { navigate } from '@reach/router'
import * as R from 'ramda'

import {
  Avatar,
  Box,
  Divider,
  Dropdown,
  Flex,
  FlexItem,
  Icon,
  Text,
  Truncate,
  hoverMixin,
  themeMixin,
} from 'stardust'
import styled from 'styled-components'

import ListItem from '~/components/ListItem'
import * as profiles from '~/components/Profiles/core'
import { isHome, isInMobileApp, isPlayground } from '~/config'
import { ServiceContext } from '~/contexts/Service'
import { StrategyContext } from '~/contexts/Strategy'
import { UserContext } from '~/contexts/User'

import { clearAuthToken, logout, redirectToLogin } from '~/modules/auth'
import { isGroupAdmin, isProviderAdmin, isSuperAdmin } from '~/modules/permissions'

import NotificationsMenu from '~/pages/Root/components/Notifications/NotificationsMenu'
import { HEADER_HEIGHT, LAYERS, ROOT_SIDEBAR_WIDTH } from '~/theme'

import CreateButton from './CreateButton'
import Logo from './Logo'
import Search from './Search'

const StyledFlex = styled(Flex)`
  ${themeMixin};
  height: ${HEADER_HEIGHT};
  position: sticky;
  top: 0;
  z-index: ${LAYERS.Header};
  padding: 8px 20px 7px 0px;
`

const ProfileButton = styled(Flex)`
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
  white-space: nowrap;
  background-color: ${(props) => props.theme.colors.cosmicShade4};

  &:hover {
    background-color: ${(props) => props.theme.colors.cosmicShade6};
  }
`

const HoverFlex = styled(Flex)`
  cursor: pointer;
  ${hoverMixin};
`

interface Props {
  showLogo?: boolean
}

const Header = ({ showLogo }: Props) => {
  const { state: user } = useContext(UserContext)
  const {
    clearService,
    toggleServicePrompt,
    state: { services, selectedServiceName, selectedServiceLinks },
  } = useContext(ServiceContext)

  const {
    clearStrategy,
    toggleStrategyPrompt,
    state: { strategies },
  } = useContext(StrategyContext)

  const onLogout = async () => {
    clearAuthToken()
    clearService()
    clearStrategy()

    await logout()

    redirectToLogin()
  }

  if (!user) {
    return null
  }

  const allowServiceSelection =
    isGroupAdmin(user) || isProviderAdmin(user) || isSuperAdmin(user) || services.length > 1

  return (
    <StyledFlex screenOnly alignItems="center" borderBottom={1}>
      {showLogo && (
        <Box width="100%" maxWidth={ROOT_SIDEBAR_WIDTH}>
          <Logo />
        </Box>
      )}
      <Flex
        pr={3}
        width={1}
        data-tour="nav-search-bar"
        justifyContent={isPlayground ? 'flex-start' : 'center'}>
        {isPlayground && (
          <FlexItem mx={3}>
            <CreateButton />
          </FlexItem>
        )}
        <Search />
      </Flex>
      {isPlayground && (
        <Box>
          <NotificationsMenu />
        </Box>
      )}
      <Box data-tour="nav-profile">
        <Flex alignItems="center" justifyContent="flex-end">
          <Box pl={2}>
            <Dropdown
              renderButton={({ handleClose }) => (
                <ProfileButton p={1} onClick={handleClose} data-test="profile-menu">
                  <Avatar
                    alt={`${user.displayName}'s Profile`}
                    src={user.image}
                    text={profiles.initials(user.displayName)}
                    xsmall
                  />
                  <Text ml={3} mr={1} style={{ whiteSpace: 'nowrap' }}>
                    {user.displayName || 'Profile'}
                  </Text>
                  <Icon name="chevronDown" />
                </ProfileButton>
              )}
              render={({ handleClose }) => (
                <Box data-tour="nav-profile-dropdown" width={300}>
                  <Flex alignItems="center" flexGrow={1} p={3} pb={2}>
                    <Avatar
                      alt={`${user.displayName}'s Profile`}
                      src={user.image}
                      text={profiles.initials(user.displayName)}
                    />
                    <Flex flexDirection="column" flexGrow={1} pl={3}>
                      <Text mb={1}>{user.displayName || 'Profile'}</Text>
                      <Text.span medium fontSize={0}>
                        <Truncate id="user-id" style={{ maxWidth: '170px' }}>
                          {user.displayName}
                        </Truncate>
                      </Text.span>
                    </Flex>
                  </Flex>
                  <Divider borderColor="surfacePrimaryBorder" mb={0} />
                  {allowServiceSelection && (
                    <HoverFlex alignItems="center" onClick={R.pipe(toggleServicePrompt, handleClose)}>
                      <ListItem
                        renderIcon={() => <Icon name="service" fill="textPrimary" />}
                        renderText={() => (
                          <Box>
                            <Text bold mb={1} fontSize={1}>
                              Change Service
                            </Text>
                            <Text.span medium fontSize={0}>
                              {selectedServiceName}
                            </Text.span>
                          </Box>
                        )}
                      />
                    </HoverFlex>
                  )}
                  {strategies.length > 1 && !isInMobileApp && (
                    <HoverFlex alignItems="center" onClick={R.pipe(toggleStrategyPrompt, handleClose)}>
                      <ListItem
                        renderIcon={() => <Icon name="user" fill="textPrimary" fillRule="evenodd" />}
                        renderText={() => (
                          <Box>
                            <Text bold mb={1} fontSize={1}>
                              Change Profile
                            </Text>
                          </Box>
                        )}
                      />
                    </HoverFlex>
                  )}
                  <HoverFlex
                    alignItems="center"
                    onClick={() => {
                      const supportUrl = isHome
                        ? 'https://support.ourxplor.com/hc/en-us/categories/203542608-Parents'
                        : selectedServiceLinks?.supportUrl || ''
                      navigate(supportUrl)
                    }}>
                    <ListItem
                      renderIcon={() => <Icon name="help" fill="textPrimary" />}
                      renderText={() => (
                        <Box>
                          <Text bold mb={1} fontSize={1}>
                            Help & Support
                          </Text>
                        </Box>
                      )}
                    />
                  </HoverFlex>
                  <HoverFlex
                    alignItems="center"
                    onClick={() => {
                      const feedbackUrl = isHome
                        ? 'https://parent-feedback.myxplor.com'
                        : selectedServiceLinks?.feedbackUrl || ''
                      navigate(feedbackUrl)
                    }}>
                    <ListItem
                      renderIcon={() => <Icon name="feedback" fill="textPrimary" />}
                      renderText={() => (
                        <Box>
                          <Text bold mb={1} fontSize={1}>
                            Feedback
                          </Text>
                        </Box>
                      )}
                    />
                  </HoverFlex>
                  <HoverFlex alignItems="center" data-test="logout" onClick={onLogout}>
                    <ListItem
                      renderIcon={() => <Icon name="removeCircle" fill="none" stroke="#000" />}
                      renderText={() => (
                        <Box>
                          <Text bold mb={1} fontSize={1}>
                            Log out
                          </Text>
                        </Box>
                      )}
                    />
                  </HoverFlex>
                </Box>
              )}
            />
          </Box>
        </Flex>
      </Box>
    </StyledFlex>
  )
}

Header.displayName = 'Header'

export default Header
