import auroraGreen from './auroraGreen'
import cosmicShade from './cosmicShade'
import gaiaGreen from './gaiaGreen'
import hullOrange from './hullOrange'
import nebulaBlue from './nebulaBlue'
import neonPink from './neonPink'
import neptuneBlue from './neptuneBlue'
import plasmaCyan from './plasmaCyan'
import pulsarPurple from './pulsarPurple'
import solYellow from './solYellow'
import superGiantRed from './superGiantRed'

export default {
  ...auroraGreen,
  ...cosmicShade,
  ...gaiaGreen,
  ...hullOrange,
  ...nebulaBlue,
  ...neonPink,
  ...neptuneBlue,
  ...plasmaCyan,
  ...pulsarPurple,
  ...solYellow,
  ...superGiantRed,
}
