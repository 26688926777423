import React, { ReactNode } from 'react'

/*
This theme provider is to allow components to access
the theme outside of using styled-components
*/
interface Props {
  children: ReactNode
  theme: any
}

export const ThemeContext = React.createContext({})

const ThemeProvider = ({ children, theme }: Props) => {
  const value = {
    state: { appTheme: theme },
  }
  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}

ThemeProvider.displayName = 'ThemeProvider'

export default ThemeProvider
