import React, { useState } from 'react'

import * as R from 'ramda'
import styled from 'styled-components'

import TextAvatar from './AvatarText'

const Image = styled.img`
  width: 100%;
  height: 100%;
  text-align: center;
  object-fit: cover;
`

const AvatarImage = (props) => {
  const { alt, large, small, xsmall, xxsmall, src, text, context } = props
  const [error, setError] = useState(false)

  if (error || R.isNil(src)) {
    return (
      <TextAvatar
        large={large}
        small={small}
        xsmall={xsmall}
        xxsmall={xxsmall}
        text={text}
        context={context}
      />
    )
  }

  return <Image alt={alt} src={src} onError={() => setError(true)} />
}

AvatarImage.displayName = 'AvatarImage'

export default AvatarImage
