import React from 'react'

import { navigate } from '@reach/router'
import { Box, Flex, Icon, Text } from 'stardust'

import DocumentIcon from '~/components/DocumentIcon'
import { isWebView } from '~/config'
import { FieldProps } from '~/pages/Observations/types'
import colors from '~/ui-components/tokens/colors'

interface Props extends FieldProps {
  documentIds?: string[]
  taggedDocuments: Playground.Document[]
}

const DisplayLinksDocsInMoreDetails = ({ documentIds, taggedDocuments, onChange }: Props) => {
  const removeLinksDoc = (linkedDocsID: string | undefined) => {
    const updatedListOfLinksDocIDs = documentIds && documentIds.filter((DocId) => DocId !== linkedDocsID)
    const updatedListOfLinksDocs = taggedDocuments && taggedDocuments.filter((Doc) => Doc.id !== linkedDocsID)

    onChange({
      name: 'documentIds',
      value: updatedListOfLinksDocIDs,
    })
    onChange({
      name: 'documents',
      value: updatedListOfLinksDocs,
    })
  }

  const handleTitleClick = (link: Playground.Document) => {
    let url
    switch (link.type) {
      case 'learning_table':
        url = `/tables/${link.typeId}/webview`
        break
      case 'learning_story':
        url = `/stories/${isWebView ? '' : 'display/'}${link.typeId}/webview`
        break
      case 'observation':
        url = `/observations/${link.typeId}/webview`
        break
      default:
        url = '/'
    }

    // if not inside webview then open tagged doc in a new tab without /webview appended in the URL
    if (!isWebView) {
      url = url.replace('/webview', '')
      window.open(url, '_blank')
    } else {
      navigate(url)
    }
  }

  return (
    <Box mb={3} pl={3} pt={3} pr={3} width="100%">
      {taggedDocuments.map((Doc) => (
        <Flex
          height="48px"
          bg={colors.cosmicShade4}
          border="1px solid"
          borderColor={colors.cosmicShade6}
          borderRadius="16px"
          alignItems="center"
          justifyContent="space-between"
          p="10px"
          key={Doc.id}
          mb={taggedDocuments.length > 1 ? 1 : 0}>
          <Flex alignItems="center" cursor="pointer" onClick={() => handleTitleClick(Doc)}>
            <DocumentIcon type={Doc.type} />
            <Text ml={2} fontSize="14px" fontWeight={400} color={colors.nebulaBlue4}>
              {Doc.title}
            </Text>
          </Flex>
          <Icon
            cursor="pointer"
            size={24}
            onClick={() => removeLinksDoc(Doc.id)}
            name="closeAlt"
            bg={colors.cosmicShade4}
            hoverColor={colors.cosmicShade5}
          />
        </Flex>
      ))}
    </Box>
  )
}
DisplayLinksDocsInMoreDetails.displayName = 'DisplayLinksDocsInMoreDetails'

export default React.memo(DisplayLinksDocsInMoreDetails)
