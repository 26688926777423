import React from 'react'

import { Icon, Text, Flex } from 'stardust'

import styled from 'styled-components'

import { isHome, isWebView } from '~/config'

import colors from '~/ui-components/tokens/colors'

interface Props {
  room: string | undefined
}

const S = {
  Text: styled(Text)`
    color: ${isWebView && isHome ? '#FFFFFF' : '#686868'};
  `,
}

const Room = ({ room }: Props) => {
  return (
    <Flex alignItems="center" mt={'10px'}>
      <Icon name="room" mr={1} size={15} color={isHome ? colors.hullOrange6 : colors.nebulaBlue7} />
      <S.Text ml={1} fontSize={1} fontWeight={400} color={'mediumEmphasis'}>
        {room}
      </S.Text>
    </Flex>
  )
}

Room.displayName = 'Room'

export default React.memo(Room)
