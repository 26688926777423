import React from 'react'

import { Box, Flex, MainButton, SecondaryButton, Divider, Text } from 'stardust'
import styled from 'styled-components'

import colors from '~/ui-components/tokens/colors'

const S = {
  MainButton: styled(MainButton)`
    height: 48px;
    padding: 12px 24px 12px 24px;
  `,
  SecondaryButton: styled(SecondaryButton)`
    height: 48px;
    padding: 12px 24px 12px 24px;
  `,
  Bottom: styled(Flex)`
    background-color: white;
    z-index: 100;
    position: sticky;
    bottom: 0;
    overflow-y: 0;
  `,
}

interface Props {
  onCancelClick: () => void
  onConfirmClick: () => void
  totalTaggedDocs: number
}

const TaggedDocumentsBottomMobile = ({ onCancelClick, onConfirmClick, totalTaggedDocs }: Props) => {
  const hasDocs = totalTaggedDocs > 0 ? true : false
  return (
    <S.Bottom pb={2} bg="white">
      <Box width="100%">
        <Divider borderColor="lightGrey" />
        <Flex alignItems="center" flexDirection="column" width="100%">
          {hasDocs ? (
            <Text color={colors.nebulaBlue4} my={3}>
              <strong>{`Total ${totalTaggedDocs} selected`}</strong>
            </Text>
          ) : (
            <Text color={colors.nebulaBlue4} my={3}>
              <strong>Select to link</strong>
            </Text>
          )}
          <Box width="90vw">
            <S.SecondaryButton mb={2} outline onClick={onCancelClick}>
              Cancel
            </S.SecondaryButton>
            <S.MainButton outline onClick={onConfirmClick}>
              Link
            </S.MainButton>
          </Box>
        </Flex>
      </Box>
    </S.Bottom>
  )
}

TaggedDocumentsBottomMobile.displayName = 'TaggedDocumentsBottomMobile'

export default TaggedDocumentsBottomMobile
