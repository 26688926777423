import React, { useEffect, useState } from 'react'

import { format, getYear } from 'date-fns'

import { Box, Flex, Icon, Text } from 'stardust'
import styled from 'styled-components'

import { DatePicker, defaultPresetDates, presetEmptyDate } from '~/components/DatePicker'

import PostTextField from '~/ui-components/PostTextField'
import colors from '~/ui-components/tokens/colors'

import { toTimeHHMM } from '~/utils/toTimeString12hr'

import { FieldProps } from '../../types'

const S = {
  Box: styled(Box)`
    box-sizing: border-box;
  `,
}

interface addNewPostDatingProps extends FieldProps {
  date?: string
  time?: string
  isReadOnly: boolean
}

const Backdating = ({ date, time, isReadOnly, onChange }: addNewPostDatingProps) => {
  const initialDate = date ? new Date(date) : new Date()
  const initialTime = time ? time : format(new Date(), 'HH:mm:ss')
  const [displayTime, setDisplayTime] = useState(toTimeHHMM(initialTime))

  useEffect(() => {
    if (!date) {
      const currentDate = new Date()
      onChange({ name: 'postDate', value: format(currentDate, 'YYYY-MM-DD') })
    }
    if (!time) {
      const currentTime = format(new Date(), 'HH:mm:ss')
      onChange({ name: 'postTime', value: currentTime })
    }
  }, [date, time, onChange])

  return (
    <Flex mt={3} alignItems="center">
      <S.Box flex={1}>
        <Flex flexDirection="column">
          <Text mb={2} ml={1} fontWeight={700} fontSize={2}>
            Post date
          </Text>
          <DatePicker
            alignItems="center"
            data-test="DatePicker"
            emptyPresetPeriod={presetEmptyDate}
            initialDisplayMonth={initialDate}
            readOnly={isReadOnly}
            label=""
            showStyledCalander
            presetPeriods={defaultPresetDates}
            selected={initialDate}
            setSelection={(date) => {
              const formattedDate = date ? format(date, 'YYYY-MM-DD') : undefined
              onChange({ name: 'postDate', value: formattedDate })
            }}
            startYear={2000}
            endYear={getYear(new Date())}
            showTextBox={true}
          />
        </Flex>
      </S.Box>
      <S.Box flex={1} ml={2}>
        <Flex flexDirection="column">
          <Text mb={2} ml={1} fontWeight={700} fontSize={2}>
            Post time
          </Text>
          <PostTextField
            type="time"
            borderRadius={1}
            border="1px solid"
            borderColor={colors.cosmicShade6}
            readOnly={isReadOnly}
            required
            value={displayTime}
            bg="white"
            onChange={(e) => {
              const inputTime = e.target.value.trim() + ':00'
              onChange({
                name: 'postTime',
                value: inputTime,
              })
              setDisplayTime(inputTime)
            }}
            trailingIcon={<Icon name="clockAlt" fill="textPrimaryMedium" />}
          />
        </Flex>
      </S.Box>
    </Flex>
  )
}

Backdating.displayName = 'Backdating'

export default React.memo(Backdating)
