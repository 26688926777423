import React from 'react'

import { Box, Flex, Icon, Text } from 'stardust'

import colors from '~/ui-components/tokens/colors'

import NewReflection from './ReflectionComponents/NewReflection'
import ReflectionList from './ReflectionComponents/ReflectionList'

interface Props {
  reflections?: Playground.Reflection[]
  tagType: string
  postId: Nullable<number>
}

const Reflections = ({ tagType, reflections, postId }: Props) => {
  return tagType === 'Reflections' ? (
    <Box p={3} maxHeight="464px" overflowY="auto">
      {/* add new reflection button */}
      <NewReflection postId={postId} />

      {/* info banner */}
      <Flex
        border={1}
        borderColor={colors.pulsarPurple12}
        bg={colors.pulsarPurple13}
        borderRadius="16px"
        p={3}
        mt="12px"
        width="fit-content"
        alignItems="flex-start">
        <Icon color={colors.pulsarPurple10} name="warning" />
        <Text fontWeight={700} fontSize="14px" ml={2} lineHeight="24px">
          Reflections are only visible for educators in the service.
        </Text>
      </Flex>

      {/* list of reflections */}
      {reflections?.length ? <ReflectionList postId={postId} reflections={reflections} /> : null}
    </Box>
  ) : null
}

Reflections.displayName = 'Reflections'

export default React.memo(Reflections)
