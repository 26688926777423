import React, { ReactNode } from 'react'

import { QueryResult } from '@apollo/client'
import * as R from 'ramda'
import InfiniteScroll from 'react-infinite-scroller'

import { Box } from 'stardust'
import styled from 'styled-components'

import FetchingMore from './FetchingMore'

const ScrollContainer = styled(Box)`
  overflow: auto;
  flex-grow: 1;
`

interface EncapsulatedInfiniteScrollProps {
  children: ReactNode
  dataSource?: QueryResult
  path: string[]
  initialLoad?: boolean
}

const EncapsulatedInfiniteScroll = ({
  children,
  dataSource,
  path,
  initialLoad = true,
}: EncapsulatedInfiniteScrollProps) => {
  if (!dataSource) {
    return <ScrollContainer>{children}</ScrollContainer>
  }

  const loadFunc = (_page: number) => {
    dataSource.fetchMore({
      variables: {
        cursor: R.path([...path, 'pageInfo', 'endCursor'], dataSource.data),
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = R.path([...path, 'edges'], fetchMoreResult) as any[]

        if (!newEdges.length) return previousResult

        const pageInfo = R.path([...path, 'pageInfo'], fetchMoreResult)
        const edges = R.path([...path, 'edges'], previousResult) as any
        const edgesLens = R.lensPath([...path, 'edges'])
        const pageInfoLens = R.lensPath([...path, 'pageInfo'])

        return R.pipe(
          R.set(edgesLens, [...edges, ...newEdges]),
          R.set(pageInfoLens, pageInfo)
        )(previousResult)
      },
    })
  }

  return (
    <InfiniteScroll
      pageStart={0}
      initialLoad={initialLoad}
      loadMore={loadFunc}
      hasMore={R.path([...path, 'pageInfo', 'hasNextPage'], dataSource.data)}
      loader={<FetchingMore />}
      useWindow={false}>
      {children}
    </InfiniteScroll>
  )
}

EncapsulatedInfiniteScroll.displayName = 'EncapsulatedInfiniteScroll'

export default EncapsulatedInfiniteScroll
