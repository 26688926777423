import React, { ReactNode } from 'react'

import { Link } from 'stardust'
import styled from 'styled-components'

import MentionedText from './MentionedText'

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.textPrimary};
`

interface Props {
  children: ReactNode
  link: string
}

const MentionedLink = ({ children, link }: Props) => (
  <MentionedText>
    <StyledLink to={link} target="_blank">
      {children}
    </StyledLink>
  </MentionedText>
)

MentionedLink.displayName = 'MentionedLink'

export default React.memo(MentionedLink)
