import React from 'react'

const UserAlt = () => (
  <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.72827 2.76535C6.72827 3.22383 6.54613 3.66352 6.22195 3.9877C5.89776 4.3119 5.45807 4.49403 4.9996 4.49403C4.54112 4.49403 4.10143 4.3119 3.77725 3.9877C3.45305 3.66352 3.27092 3.22383 3.27092 2.76535C3.27092 2.30688 3.45305 1.86719 3.77725 1.543C4.10143 1.21881 4.54112 1.03668 4.9996 1.03668C5.45807 1.03668 5.89776 1.21881 6.22195 1.543C6.54613 1.86719 6.72827 2.30688 6.72827 2.76535ZM6.77045 4.89024C7.20697 4.52628 7.52076 4.03667 7.66922 3.48804C7.81761 2.93939 7.79333 2.35836 7.59979 1.82398C7.40625 1.2896 7.05277 0.827812 6.58748 0.501446C6.12216 0.175087 5.5676 0 4.99925 0C4.4309 0 3.87634 0.175087 3.41103 0.501446C2.94572 0.827812 2.59224 1.2896 2.3987 1.82398C2.20515 2.35836 2.18092 2.93939 2.32932 3.48804C2.47771 4.03667 2.79152 4.52628 3.22805 4.89024C2.54737 5.21205 1.967 5.71291 1.5491 6.33921C1.1312 6.96554 0.891485 7.69366 0.855626 8.44577C0.852293 8.5813 0.902148 8.71274 0.994528 8.8119C1.0869 8.91113 1.21445 8.97025 1.34987 8.97654C1.48528 8.9829 1.61778 8.93595 1.71901 8.84578C1.82023 8.75561 1.88213 8.62942 1.89145 8.49417C1.92914 7.69518 2.27307 6.94141 2.85183 6.38934C3.43059 5.83722 4.19973 5.5292 4.9996 5.5292C5.79946 5.5292 6.56861 5.83722 7.14737 6.38934C7.72612 6.94141 8.07006 7.69518 8.10775 8.49417C8.10947 8.56325 8.12489 8.63122 8.15324 8.69421C8.18159 8.75721 8.22225 8.81391 8.2728 8.86093C8.32334 8.90801 8.38281 8.94452 8.4476 8.96831C8.51246 8.9921 8.5814 9.00268 8.65041 8.99943C8.71942 8.99625 8.78704 8.97924 8.84941 8.9495C8.91172 8.91977 8.96752 8.87794 9.01343 8.82635C9.05941 8.77477 9.09454 8.71454 9.11687 8.6492C9.13928 8.58385 9.14834 8.51464 9.14357 8.44577C9.10761 7.69359 8.86781 6.9654 8.44981 6.33907C8.03175 5.71277 7.45127 5.21195 6.77045 4.89024Z"
      fill="white"
    />
  </svg>
)

export default UserAlt
