import React from 'react'

const Lock = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 9.375C2.5 8.33947 3.33947 7.5 4.375 7.5H15.625C16.6605 7.5 17.5 8.33947 17.5 9.375V18.125C17.5 19.1605 16.6605 20 15.625 20H4.375C3.33947 20 2.5 19.1605 2.5 18.125V9.375ZM4.375 8.75C4.02982 8.75 3.75 9.02982 3.75 9.375V18.125C3.75 18.4702 4.02982 18.75 4.375 18.75H15.625C15.9702 18.75 16.25 18.4702 16.25 18.125V9.375C16.25 9.02982 15.9702 8.75 15.625 8.75H4.375Z"
      fill="#252525"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1.25C7.92893 1.25 6.25 2.92893 6.25 5V8.125C6.25 8.47018 5.97018 8.75 5.625 8.75C5.27982 8.75 5 8.47018 5 8.125V5C5 2.23858 7.23858 0 10 0C12.7614 0 15 2.23858 15 5V8.125C15 8.47018 14.7202 8.75 14.375 8.75C14.0298 8.75 13.75 8.47018 13.75 8.125V5C13.75 2.92893 12.0711 1.25 10 1.25Z"
      fill="#252525"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 11.875C10.3452 11.875 10.625 12.1548 10.625 12.5V15C10.625 15.3452 10.3452 15.625 10 15.625C9.65482 15.625 9.375 15.3452 9.375 15V12.5C9.375 12.1548 9.65482 11.875 10 11.875Z"
      fill="#252525"
    />
  </svg>
)

export default Lock
