import React, { ReactNode } from 'react'

import Box from './Box'
import useSpace from './hooks/useSpace'
import { AlignX, AlignY, SpaceDescriptor } from './theme/types'

import { filterReactChildren, isNotNull } from './utils'

const alignXMapping = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
}

const alignYMapping = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
}

const getMarginLeft = (values: string | string[], align: AlignX) => {
  if (align === 'center' && typeof values === 'string') {
    return `calc(${values} / 2)`
  }

  if (align === 'center' && Array.isArray(values)) {
    return values.map((x: string) => `calc(${x} / 2)`)
  }

  return align === 'left' ? values : undefined
}

const invert = (space: string | string[]) => (Array.isArray(space) ? space.map((x) => `-${x}`) : `-${space}`)

export interface InlineProps {
  align: AlignX
  alignY: AlignY
  children: ReactNode
  space: SpaceDescriptor | SpaceDescriptor[]
  wrap: boolean
}

export const Inline = ({ align, alignY, children, space, wrap }: InlineProps) => {
  const spaceValues = useSpace(space)
  const negativeSpaceValues = spaceValues ? invert(spaceValues) : 'none'

  const marginLeft = getMarginLeft(negativeSpaceValues, align)
  const marginRight = align === 'right' ? negativeSpaceValues : undefined

  const nonNullChildren = filterReactChildren(children, isNotNull)

  return (
    <Box
      alignItems={alignYMapping[alignY]}
      display="flex"
      flexDirection="row"
      flexWrap={wrap ? 'wrap' : 'nowrap'}
      justifyContent={alignXMapping[align]}
      marginLeft={marginLeft}
      marginRight={marginRight}
      marginTop={negativeSpaceValues}
      width={1}>
      {React.Children.map(nonNullChildren, (child: ReactNode, index: number) => (
        <Box key={index} marginLeft={spaceValues} marginTop={spaceValues}>
          {child}
        </Box>
      ))}
    </Box>
  )
}

Inline.defaultProps = {
  align: 'left',
  alignY: 'top',
  space: 'none',
  wrap: true,
}

Inline.displayName = 'Inline'

export default React.memo(Inline)
