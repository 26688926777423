import React from 'react'

const Comments = () => (
  <svg
    cursor="auto"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.99999 14.6667C12.4978 14.6667 15.3333 11.8311 15.3333 8.33333C15.3333 4.83553 12.4978 2 8.99999 2C5.50219 2 2.66666 4.83553 2.66666 8.33333C2.66666 11.8311 5.50219 14.6667 8.99999 14.6667Z"
      fill="#E3FDFF"
    />
    <path
      d="M12.1667 6.83337H6.5C6.36739 6.83337 6.24022 6.7807 6.14645 6.68693C6.05268 6.59316 6 6.46598 6 6.33337C6 6.20077 6.05268 6.07359 6.14645 5.97982C6.24022 5.88605 6.36739 5.83337 6.5 5.83337H12.1667C12.2993 5.83337 12.4265 5.88605 12.5202 5.97982C12.614 6.07359 12.6667 6.20077 12.6667 6.33337C12.6667 6.46598 12.614 6.59316 12.5202 6.68693C12.4265 6.7807 12.2993 6.83337 12.1667 6.83337Z"
      fill="#444B54"
    />
    <path
      d="M10.5467 8.83337H6.5C6.36739 8.83337 6.24022 8.7807 6.14645 8.68693C6.05268 8.59316 6 8.46598 6 8.33337C6 8.20077 6.05268 8.07359 6.14645 7.97982C6.24022 7.88605 6.36739 7.83337 6.5 7.83337H10.5467C10.6793 7.83337 10.8065 7.88605 10.9002 7.97982C10.994 8.07359 11.0467 8.20077 11.0467 8.33337C11.0467 8.46598 10.994 8.59316 10.9002 8.68693C10.8065 8.7807 10.6793 8.83337 10.5467 8.83337Z"
      fill="#444B54"
    />
    <path
      d="M11.3333 10.8334H6.5C6.36739 10.8334 6.24022 10.7807 6.14645 10.6869C6.05268 10.5932 6 10.466 6 10.3334C6 10.2008 6.05268 10.0736 6.14645 9.97982C6.24022 9.88605 6.36739 9.83337 6.5 9.83337H11.3333C11.4659 9.83337 11.5931 9.88605 11.6869 9.97982C11.7807 10.0736 11.8333 10.2008 11.8333 10.3334C11.8333 10.466 11.7807 10.5932 11.6869 10.6869C11.5931 10.7807 11.4659 10.8334 11.3333 10.8334Z"
      fill="#444B54"
    />
    <path
      d="M9.14666 14.7867H6.95333C6.81893 14.7798 6.69243 14.721 6.60043 14.6228C6.50843 14.5246 6.45809 14.3945 6.45999 14.26C6.45698 14.1959 6.46762 14.1319 6.49119 14.0722C6.51477 14.0125 6.55075 13.9585 6.59675 13.9137C6.64276 13.869 6.69774 13.8345 6.75806 13.8126C6.81838 13.7907 6.88266 13.7819 6.94666 13.7867H9.14666C10.2737 13.7867 11.3755 13.4525 12.3127 12.8265C13.2499 12.2005 13.9805 11.3106 14.4121 10.2695C14.8437 9.22833 14.957 8.0826 14.7376 6.97709C14.5182 5.87158 13.976 4.85592 13.1795 4.0585C12.383 3.26108 11.368 2.71768 10.2628 2.49699C9.1575 2.2763 8.01163 2.38823 6.96998 2.81863C5.92833 3.24902 5.03765 3.97857 4.41052 4.91505C3.7834 5.85154 3.44798 6.95292 3.44666 8.07998V10.16C3.4481 10.5122 3.49973 10.8624 3.59999 11.2C3.62911 11.2905 3.63072 11.3875 3.60461 11.4789C3.5785 11.5703 3.52585 11.6519 3.45333 11.7133L1.17333 13.7667H3.83999C3.9726 13.7667 4.09978 13.8193 4.19355 13.9131C4.28731 14.0069 4.33999 14.134 4.33999 14.2667C4.33999 14.3993 4.28731 14.5264 4.19355 14.6202C4.09978 14.714 3.9726 14.7667 3.83999 14.7667H1.17333C0.969156 14.7664 0.769878 14.7041 0.601934 14.588C0.43399 14.4719 0.30532 14.3075 0.232998 14.1166C0.160675 13.9256 0.148119 13.7172 0.196995 13.519C0.245872 13.3208 0.35387 13.1421 0.50666 13.0067L2.56666 11.1533C2.4924 10.8206 2.4544 10.4809 2.45333 10.14V8.07998C2.46123 6.76431 2.85826 5.4804 3.59442 4.38992C4.33057 3.29945 5.37293 2.45118 6.59023 1.95195C7.80753 1.45271 9.14534 1.32483 10.4352 1.58441C11.725 1.84399 12.9092 2.47942 13.8386 3.41069C14.768 4.34195 15.4011 5.5274 15.6581 6.81775C15.9151 8.1081 15.7846 9.44564 15.2829 10.6619C14.7813 11.8783 13.9309 12.9189 12.839 13.6529C11.747 14.3869 10.4623 14.7814 9.14666 14.7867Z"
      fill="#454B54"
    />
  </svg>
)

export default Comments
