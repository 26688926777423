import React from 'react'

import { List as ImmutableList } from 'immutable'
import * as R from 'ramda'
import { Change, Decoration, Editor, Inline, Value } from 'slate'
import { RenderInlineProps, RenderMarkProps } from 'slate-react'
import { Text } from 'stardust'

import MentionedEntity from '~/components/Mentions/MentionedEntity'
import Suggestions from '~/components/Suggestions'
import { toFkey } from '~/utils'

import { Next, Query, SlateReactEditor } from '../../types'

import { createMarkDecoration, getInput, hasValidAncestors, insertMention } from './mention'
import { CONTEXT_MARK_TYPE, MENTION_NODE_TYPE } from './types'

const getMentionData = R.pipe(
  (v: Value) =>
    v.document.filterDescendants((node) => 'type' in node && node.type === MENTION_NODE_TYPE).toJS(),
  R.map(({ data }: { data: Playground.TaggedEntity }) => ({
    type: data.type,
    typeFkey: data.typeFkey ? data.typeFkey : toFkey(data.typeId!),
    displayText: data.displayText,
  })),
  R.uniq
)

export default {
  serialize: (node: Inline) => {
    if (node.type === MENTION_NODE_TYPE) {
      const entity = node.data.toJS()
      return <MentionedEntity entity={entity} readOnly={false} />
    }
  },

  onQuery: ({ type }: Query, { value }: Editor, next: Next) => {
    if (type === 'mentions') {
      const mentionData = getMentionData(value)
      return mentionData
    }

    return next()
  },

  renderMark: ({ attributes, children, editor, mark }: RenderMarkProps, next: Next) => {
    if (mark.type === CONTEXT_MARK_TYPE) {
      if (!hasValidAncestors(editor.value)) {
        return next()
      }
      const { types, query } = mark.data.toJS()
      const anchorRef = React.createRef<any>()

      const suggestionProps = {
        anchor: anchorRef,
        types: types,
        query: query,
        onSelect: R.partial(insertMention, [editor, 'mention']),
      }

      return (
        <>
          <Text.span ref={anchorRef} {...attributes}>
            {children}
          </Text.span>
          <Suggestions {...suggestionProps} />
        </>
      )
    }

    return next()
  },

  renderNode: ({ attributes, children, editor, node }: RenderInlineProps, next: Next) => {
    if (node.type === MENTION_NODE_TYPE) {
      const entity = node.data.toJS()

      return (
        <>
          <MentionedEntity {...attributes} entity={entity} readOnly={editor.readOnly} />
          {children}
        </>
      )
    }

    return next()
  },

  onKeyUp: (_event: Event, slateEditor: SlateReactEditor, next: Next) => {
    const { value } = slateEditor
    const { token, terms } = getInput(value)

    if (!terms) {
      return next()
    }

    const { selection } = value

    const existingDecorations = value.decorations.filterNot(
      (decoration: Nullable<Decoration>) => decoration?.mark?.type === CONTEXT_MARK_TYPE
    )

    const newDecoration = createMarkDecoration('mentionContext', token, terms, selection)

    const concat = existingDecorations.toArray().concat(newDecoration)

    // AP: Update the decorations without saving, so this change doesn't log in the history. I.e. pressing ctrl+z wont undo the decoration
    slateEditor.withoutSaving(() =>
      slateEditor.editor.change((change: Change) => change.setValue({ decorations: ImmutableList(concat) }))
    )
  },
}
