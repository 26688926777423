import React from 'react'

import { navigate } from '@reach/router'
import { Box, Flex, FlexItem, Icon, Interactive, Text, TruncateMultiline } from 'stardust'
import styled from 'styled-components'

import ReadStateIndicator from '~/components/ReadStateIndicator'
import useHover from '~/hooks/useHover'

import { Notification } from '../../queries'

import { formatDate, getPlaygroundIconName, getPlaygroundNotificationLink } from './utils'

interface Props {
  notification: Notification
  isRead: boolean
  markAsRead: () => void
  onNavigate: () => void
}

const StyledItem = styled(Box)`
  background-color: ${({ read, theme }) => (read ? null : theme.colors.nebulaBlue0)};
  -webkit-transition: background-color 0.5s ease-out;
  -moz-transition: background-color 0.5s ease-out;
  -o-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out;

  :hover {
    background-color: ${({ read, theme }) => (read ? 'whitesmoke' : theme.colors.nebulaBlue1)};
  }
`

const NotificationItem = ({ notification, isRead, onNavigate, markAsRead }: Props) => {
  const [hoverRef, hover] = useHover()

  const handleClick = () => {
    const notificationLink = getPlaygroundNotificationLink(notification)
    if (notificationLink) {
      navigate(notificationLink)
      onNavigate()
    }
    markAsRead()
  }

  const onClickUnreadIndicator = (e: React.MouseEvent) => {
    markAsRead()
    e.stopPropagation()
  }

  const iconName = getPlaygroundIconName(notification)

  return (
    <Interactive onClick={() => handleClick()}>
      <StyledItem read={isRead} ref={hoverRef}>
        <Flex width={1} justifyContent="center">
          <Flex alignItems="center" px={3}>
            <Icon name={iconName} fill="textPrimary" fillRule="evenodd" size={40} pt={1} />
          </Flex>
          <FlexItem flexGrow={1}>
            <Flex py={1} width={1} flexDirection="column" justifyContent="center">
              <Flex width="90%">
                <Flex justifyContent="center" flexDirection="column">
                  <TruncateMultiline maxLines={1}>
                    <Text bold fontSize={1}>
                      {`${notification.title}`}
                    </Text>
                  </TruncateMultiline>
                  <TruncateMultiline maxLines={2}>
                    <Text.span fontSize={1}>{`${notification.body}`}</Text.span>
                  </TruncateMultiline>
                </Flex>
              </Flex>
              <FlexItem alignSelf="flex-end" width={1}>
                <Text fontSize={0} pt={1} color="highEmphasis">
                  {formatDate(notification.insertedAt)}
                </Text>
              </FlexItem>
            </Flex>
          </FlexItem>
          <FlexItem>
            {!isRead && (
              <Box mr={3} mt={2}>
                <ReadStateIndicator
                  showBackground={hover as boolean}
                  isRead={isRead}
                  onClick={onClickUnreadIndicator}
                />
              </Box>
            )}
          </FlexItem>
        </Flex>
      </StyledItem>
    </Interactive>
  )
}

export default React.memo(NotificationItem)
