import gql from 'graphql-tag'

export default gql`
  fragment PostDocuments on Post {
    documents {
      title
      type
      typeId
      id
    }
  }
`
